import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class FilterService {

    @Output() filtersChanged: Params = new EventEmitter<Params>();

    // Get the current url string & split it into 2 part by '?'
    // Get only the first part which is the path
    // eg. 'identity?xyx=abc' in url will return only 'identity'
    get currentPath (): string { return this.router.url.split( '?' )[ 0 ]; }

    get filters (): Params { return this.route.snapshot.queryParams; }

    get httpParams (): HttpParams {

        return new HttpParams( { fromObject: this.filters } );

    }

    constructor (
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    updateFormFilters ( form: FormGroup, convertIds = [ 'company_id' ] ): void {

        Object.keys( this.filters ).forEach( filterKey => {

            const formControl: AbstractControl | any = form.get( filterKey );

            if ( formControl ) {

                if ( convertIds.includes( filterKey ) ) {

                    const value = this.filters[ filterKey ];

                    // 1st scenario -> if company ID = NaN

                    // 2nd scenario -> if company ID is in array format -> ['12','34']

                    // 3rd scenario -> for single company ID

                    if ( isNaN( value ) && !Array.isArray( value ) ) {

                        form.get( filterKey )?.setValue( [] );

                    } else if ( Array.isArray( value ) ) {

                        const updatedValue = value.map( v => ( +v ) );

                        form.get( filterKey )?.setValue( updatedValue );

                    } else {

                        form.get( filterKey )?.setValue( [ +value ] );

                    }

                } else {

                    form.get( filterKey )?.setValue( this.filters[ filterKey ] );

                }

            }

        } );

    }

    updateFormFiltersv2 ( form: FormGroup, convertIds = [ 'company_id', 'lot_id', 'invite_status' ] ): void {

        this.updateFormFilters( form, convertIds );

    }

    filter (): void {

        // TODO: Check for any change before firing the event
        this.filtersChanged[ 'emit' ]( this.filters );

    }

    filterByFormGroup ( formGroup: FormGroup ): void {

        this.filterByParams( formGroup.value );

    }

    filterByPage ( page: string | number ): void {

        this.filterByParams( { page } );

    }

    filterByParams ( params: Params ): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: params,
            queryParamsHandling: 'merge',
        };

        // Navigate to current path along with our set query string
        // This will add query params to the urls
        this.router.navigate( [ this.currentPath ], navigationExtras ).then( success => {

            this.filter();

        } );

    }
}
