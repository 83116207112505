<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-8">
        <h1 class="m-n font-thin h3">UIDAI Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-aadhaar-stats></app-aadhaar-stats>

<app-uidai-details-modal #uidaiDetailsModal></app-uidai-details-modal>
<app-uidai-html-response-modal #uidaiHtmlResponseModal></app-uidai-html-response-modal>

<app-loading-component [fetchingInProgress]="fetchingInProgress"></app-loading-component>

<app-uidai-filters [filterParams]="filterParams"></app-uidai-filters>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 25%">Aadhaar Number</th>
                        <th style="width: 25%">Status</th>
                        <th style="width: 25%">Timeline</th>
                        <th style="width: 25%">Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">
                        <td>
                            <strong><a (click)="uidaiDetailsModal.show(log.id)">{{log.aadhaar_number}}</a></strong>
                            <br>
                            {{log.company_name}} <br>
                            {{log.id}}
                            <br>
                        </td>
                        <td>


                            <div *ngIf="log.status_id; else failure">
                                <div *ngIf="log.has_details == 0; else found">
                                    <div *ngIf="log.failure_reason; else notFound">
                                        <div *ngIf="log.failure_reason == 3; else invalidCaptcha">
                                            <a (click)="uidaiHtmlResponseModal.show(log.id)"
                                                class="btn label-danger">Discrepancy Found</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-template #found>
                                <a class="btn label-success" (click)="uidaiHtmlResponseModal.show(log.id)">
                                    Found</a>
                            </ng-template>

                            <ng-template #notFound>
                                <a class="btn label-danger" (click)="uidaiHtmlResponseModal.show(log.id)">Not
                                    Found</a>
                            </ng-template>

                            <ng-template #invalidCaptcha>
                                <a class="btn label-warning" (click)="uidaiHtmlResponseModal.show(log.id)">Invalid
                                    Captcha</a>
                            </ng-template>

                            <ng-template #failure>
                                <a class="btn label-danger" (click)="uidaiHtmlResponseModal.show(log.id)">Failure</a>
                            </ng-template>


                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>Created at: {{log.created_at}}</li>
                                <li>Updated at: {{log.updated_at}}</li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">

                                <a class="btn btn-primary btn-md" (click)="resend(log)">
                                    <i class="fa fa-refresh"></i>
                                    Resend
                                </a>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination?.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>