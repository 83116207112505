<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                        <div class="form-group">
                            <input type="file" (change)="onFileChange($event)" [accept]="acceptedFileType"
                                class="form-control" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="wrapper-md">

    <div class="panel">
        <div class="panel-heading">
            <h3 class="panel-title">Important points to note: </h3>
        </div>
        <div class="row">

            <div class="col-md-3">
                <div class="panel-body">
                    Please select type of Document as follows:
                    <ul class="unstyled">
                        <li>AADHAAR CARD</li>
                        <li>BIRTH CERTIFICATE</li>
                        <li>DRIVING LICENCE</li>
                        <li>ELECTRICITY BILL</li>
                        <li>GOVERNMENT ID CARD</li>
                        <li>PAN CARD</li>
                        <li>PASSPORT</li>
                        <li>RATION BILL</li>
                        <li>RENT AGREEMENT</li>
                        <li>SCHOOL BOARD CERTIFICATE</li>
                        <li>SCHOOL LEAVING CERTIFICATE</li>
                        <li>TELEPHONE BILL</li>
                        <li>VOTER ID CARD</li>
                        <li>WATER BILL</li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3">
                <div class="panel-body">
                    Please select Status as follows:
                    <ul class="unstyled">
                        <li>0 : Under Process</li>
                        <li>1 : Applied</li>
                        <li>2 : Document Required</li>
                        <li>3 : No Record Found</li>
                        <li>4 : Record Found</li>
                        <li>5 : Discrepancy Found</li>
                        <li>6 : Verification Stopped</li>
                        <li>7 : Ineligible</li>
                        <li>8 : Rejected</li>
                        <li>9 : Account Created</li>
                        <li>10 : Not Applicable</li>
                        <li>11 : Client Terminated</li>

                    </ul>
                </div>
            </div>
            <div class="col-md-3">
                <div class="panel-body">
                    Please select Eligible Field as follows:
                    <ul class="unstyled">
                        <li>0 : Not Eligible</li>
                        <li>1 : Eligible</li>

                    </ul>
                </div>
            </div>
            <div class="col-md-3">
                <div class="panel-body">
                    Please select Eligible Field as follows:
                    <ul class="unstyled">
                        <li>1 : Cash</li>
                        <li>2 : DEMAND DRAFT</li>
                        <li>3 : INDIAN POSTAL ORDER</li>
                        <li>4 : CHALLAN</li>
                        <li>5 : ONLINE</li>
                        <li>6 : NOT APPLICABLE</li>
                        <li>7 : e-GRAS CHALLAN</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>


    <div class="panel">

        <div class="panel-heading">
            <h3 class="panel-title">Excel Heading </h3>
        </div>
        <div class="panel-body" style="display:block;width: 1120px; overflow-x: auto;">
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th>Track Id</th>
                        <th>Address Type</th>
                        <th>Address</th>
                        <th>State</th>
                        <th>District</th>
                        <th>Landmark</th>
                        <th>Pincode</th>
                        <th>Status</th>
                        <th>Payment Reference number</th>
                        <th>Pcc Application number</th>
                        <th>Duration Of Stay</th>
                        <th>Caste</th>
                        <th>Email Address</th>
                        <th>Password</th>
                        <th>Login Id</th>
                        <th>Eligible</th>
                        <th>Police Station</th>
                        <th>Applied At</th>
                        <th>Completed At</th>
                        <th>Identification Mark</th>
                        <th>India Post ID Application</th>
                        <th>India Post ID Report</th>
                        <th>Document Types</th>
                        <th>Client Remarks</th>
                        <th>Official Fees</th>
                        <th>Payment mode</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>
<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-10 panel-title">
                    Imported Rows are as follows
                </div>
                <div class="col-md-2 text-right text-center-xs">
                    <button type="button" (click)="importPcc()" [disabled]="!excelForm.valid"
                        class="btn btn-primary">Import
                        Excel
                    </button>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <form [formGroup]="excelForm" (keydown.enter)="$event.preventDefault()">
                <fieldset [disabled]="isSubmitting">
                    <div style="max-width: 100%;overflow-x: scroll">
                        <table class="table table-bordered" formArrayName="excelFormArray">
                            <tr>
                                <th *ngFor="let title of originalHeaders">
                                    {{title}}
                                </th>
                            </tr>
                            <tr *ngFor="let row of excelFormArray.controls;let i=index" [formGroupName]="i">
                                <td *ngFor="let header of headerRaw;let k=index">
                                    <div>
                                        <input formControlName="{{header}}"><br>
                                        <small class="text-danger" [hidden]="row.get(header)?.valid">
                                            {{validationMessages[header]}}
                                        </small>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>