<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                        <div class="form-group">
                            <input type="file" (change)="onFileChange($event)" [accept]="acceptedFileType"
                                class="form-control" />
                        </div>
                    </form>
                </div>
            </div>

            <button type="button" class="btn btn-primary" (click)="staticModal.show()">View Sample Excel</button>

            <div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
                role="dialog" aria-labelledby="dialog-static-name">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 id="dialog-static-name" class="modal-title pull-left">View Sample Excel</h4>
                            <button type="button" class="close pull-right" aria-label="Close"
                                (click)="staticModal.hide()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1OfqpS0aa7PX-5Jn8Y4vVcoW6wfi2hGQ6th1iESAiIFA/edit?usp=sharing">Current
                                            Address Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1BMeEqeyzqqBsmp72VVpYpTitDzs_6HSWWQay03kt8rI/edit?usp=sharing">Current
                                            Address Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1jdN3-ojVidGWzbvkvq5DZly2X_YvKrEa_FN0oXQyVkg/edit?usp=sharing">Permanenet
                                            Address Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/19gn-ajjAVpxouK8Bjp3k5RFrhr7V3jLqK_ZT8MJ_dCE/edit?usp=sharing">Permanent
                                            Address Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1QSRmDqUJzzuz1r8VtpOJXIXnPLLfrz2RsgJ_Fke7enk/edit?usp=sharing">Identity
                                            Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1hHgRaHvENStWS_av-9ZMVy-Jpy3Y1RVcR0bOVRpP2fs/edit?usp=sharing">Identity
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1vYOGv0_C-8-b2dDlx7w52WOEN73JH1R1L9sJdQ3kdLw/edit?usp=sharing">
                                            Icr Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1T5i-b4GeMahmdOPxF84P4g1PaPBbn9Sv8G3xnbH8oag/edit?usp=sharing">Icr
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1cCdRlDAwWGdScZgWY2BTZHFP4vuVvVClCu0wwZBTNlI/edit?usp=sharing">
                                            Icr Case Update Excel</a>
                                    </button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/17krNdqp2E9UBOduRkC2aX3TK9t31Je0_u5_vUfzakbg/edit?usp=sharing">Education
                                            Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1sLXyAIWWL1_salQJR8CWjNEdTcBh2P6zxnCl_93pbLc/edit?usp=sharing">Education
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1ERkggZ2huOUCyFFcbftLEv3Qcvw6w55VLAq6cDPWmyA/edit?usp=sharing">Employment
                                            Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1DBaFxRdop_QCwtrvdwNaqkZb2_3gQkvuMp9aIB-XBGs/edit?usp=sharing">Employment
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1WeM6kmlbLrKyhd8TkhMQ2lL-8taWBdk4Zw6M75agVvE/edit?usp=sharing">Pcc
                                            Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1ijmagk5UlF_xz7JjhjVVXCo8_l2cCoRpeUU0ia5rWyY/edit?usp=sharing">Pcc
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1AX6SzElDd-90lsRUok5AegSYalMwhKhsm54smlXl9Kg/edit?usp=sharing">Reference
                                            Update Excel</a></button>
                                </div>


                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1SpT82NJ2v4xPHXCxI_CA_BWW-Ynz0J6vRww9idVsBoU/edit?usp=sharing">Reference
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1OyFlDc-jssVwFrjh2T5DOhALZsvVPxMNxTeTFSVSM5M/edit?usp=sharing">Document
                                            Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1YvGDrpd_j7X7OXcBJE06cISNVrsvXT5LeIuG4pwxCy0/edit?usp=sharing">Document
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1bfPBxvWWgZ4nsq9qurQuWpKqLDERgmxQB5fwjTi1_ag/edit?usp=sharing">GlobalDb
                                            Update Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1THDmDyrpxyDw5Cx-bNruAu9ZPk5ov61uzR2xpJpj_kk/edit?usp=sharing">GlobalDb
                                            Add Excel</a></button>
                                </div>

                                <div class="col-md-12">
                                    <button class="btn "><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1C9atIQwWjGAAbTnSPILCw3JHy2aAZebTFrfH2JcQLzc/edit#gid=0">Employee
                                            Basic Details Update Excel</a></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>


<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-8 panel-title">
                    Imported Rows are as follows
                </div>
                <div class="col-md-2 text-left text-center-xs">
                    <button type="button" [disabled]="!excelForm.valid" class="btn btn-primary"
                        (click)="onImportExcelButtonClicked('update')">
                        Update
                        Existing
                    </button>
                </div>
                <div class="col-md-2 text-right text-center-xs">
                    <button type="button" [disabled]="!excelForm.valid" class="btn btn-primary"
                        (click)="onImportExcelButtonClicked('add')">Add
                        Verification
                    </button>
                </div>
            </div>
        </div>


        <div class="panel-body">
            <form [formGroup]="excelForm" (keydown.enter)="$event.preventDefault()">
                <div class="row">
                    <div class="form-group col-md-3">
                        <label class="center-block">Verification</label>
                        <select class="form-control" formControlName="verification">
                            <option *ngFor="let verification of verifications" [value]="verification">
                                {{verification | uppercase }}
                            </option>
                        </select>
                    </div>
                </div>

                <fieldset class="row" [disabled]="isSubmitting">
                    <div id="content">
                        <table class="table table-responsive" formArrayName="excelFormArray">
                            <thead>
                                <tr>
                                    <th style="min-width:22rem" *ngFor="let title of originalHeaders">
                                        {{title}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of excelFormArray.controls;let i=index" [formGroupName]="i">
                                    <td *ngFor="let header of headerRaw;let k=index">
                                        <input class="form-control" type="text" formControlName="{{header}}">
                                        <div class="invalid-feedback" [hidden]="row.get(header)?.valid">
                                            {{validationMessages[header]}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>

            </form>
        </div>
    </div>
</div>