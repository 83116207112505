import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VcourtJob } from '../../core/models/vcourt-job.model';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Pagination } from '../../core/models/pagination.model';
import { VcourtService } from '../../core/services/vcourt.service';
import { FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-vcourt-job-show-company-search-results',
    templateUrl: './vcourt-job-show-company-search-results.component.html',
    styleUrls: [ './vcourt-job-show-company-search-results.component.css' ],
} )
export class VcourtJobShowCompanySearchResultsComponent implements OnInit {

    @Input()
    vcourtJob!: VcourtJob;

    @Input()
    pagination!: Pagination;

    loading!: boolean;
    jobId!: number;
    filterParams: HttpParams = new HttpParams();
    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    constructor
        (
            private route: ActivatedRoute,
            protected router: Router,
            private vcourtService: VcourtService,
            private commonService: CommonService
        ) {

    }


    ngOnInit () {

    }

    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Mark the Case status.
    markCaseStatusAs ( status: string, result: any ) {

        this.vcourtService.markCaseStatusAs( [ result.id, status ] ).subscribe(
            ( response: any ) => {
                if ( response[ 'id' ] == 200 ) {
                    this.commonService.notifications.success( 'Successfully', response[ 'message' ] );

                }
                else {
                    this.commonService.notifications.error( 'Error', response[ 'message' ] );
                }

                this.getCasesByJobId();
            } );

    }

    // Get Cases by Job Id.
    getCasesByJobId () {
        this.route.params.subscribe( params => {
            this.jobId = params[ 'id' ];
            this.vcourtService.getCasesByJobId( this.jobId, this.filterParams ).subscribe(
                pagination => {
                    this.pagination = pagination;
                } )
        } );
    }

}
