import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { CommonService } from '../../core/services/common.service';
import { ContactService } from '../../core/services/contact.service';

@Component( {
    selector: 'app-contact-create',
    templateUrl: './contact-create.component.html',
    styleUrls: [ './contact-create.component.css' ],
    providers: [ ContactService ],
} )
export class ContactCreateComponent implements OnInit {
    isSubmitting: boolean = false;
    contactCreateForm!: FormGroup;
    type!: string;
    typeId!: number;

    @ViewChild( 'contactCreateModal', { static: false } )
    public contactCreateModal!: ModalDirective;

    @Output()
    onContactCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private contactService: ContactService,
        private commonService: CommonService,

        private fb: FormBuilder
    ) { }

    ngOnInit (): void {

        this.initForm();
    }


    initForm (): void {

        this.contactCreateForm = this.fb.group( {
            name: [ '', [ Validators.required ] ],
            primary_email: [ '', validateEmail() ],
            secondary_email: [ '', validateEmail() ],
            object_id: [ '' ],
            designation: [ '' ],
            phone_number: [ '', [] ],
            remarks: [ '' ],
            location: [ '' ],
            division: [ '' ],
            reference_name: [ '' ],
            active: [ '' ],
            helpful: [ '' ],
            centralised: [ '' ],
            type: [ '' ],
            type_id: [ '' ],
        } );
    }


    show ( type: string, typeId: number ): void {

        this.type = type;
        this.typeId = typeId;
        this.contactCreateModal.show();

    }

    hide (): void {
        this.initForm();
        this.contactCreateModal.hide();
    }

    /**
     * Common function to assign work to the selected user.
     */
    create (): void {
        this.isSubmitting = true;
        this.contactCreateForm.patchValue( { type: this.type, type_id: this.typeId } );
        this.contactService.create( this.contactCreateForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( response[ 'message' ] );
                this.isSubmitting = false;
                this.hide();
                this.onContactCreated.emit( true );
            },
            error => {
                this.commonService.notifications.error( 'Something went wrong' );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }
}
