import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';

import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Pagination } from 'src/app/core/models/pagination.model';
import { CommonService } from 'src/app/core/services/common.service';
import { FaceRecognitionService } from 'src/app/core/services/face-recognition.service';


@Component( {
    selector: 'app-face-recognition-log-list',
    templateUrl: './face-recognition-log-list.component.html',
} )
export class FaceRecognitionLogListComponent extends ExportExcelComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress = false;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients!: any[];


    constructor (
        private router: Router,
        private route: ActivatedRoute,

        private commonService: CommonService,
        private faceRecognitionService: FaceRecognitionService,
    ) {
        super();
    }

    ngOnInit () {
        this.route.queryParams.subscribe( ( params ) => {
            Object.keys( params ).forEach( ( key ) => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    filterResults (): void {
        this.faceRecognitionService.filter( this.filterParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );
    }

    showPage ( page: number ) {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    exportLogsExcel () {
        this.filterParams = this.filterParams.set( 'unique', 'no' );
        this.getData();
    }

    getData () {
        this.fetchingInProgress = true;
        this.faceRecognitionService.getDataForExcel( this.filterParams ).subscribe(
            ( response ) => {
                this.downloadExcel( response, 'Face Recognition Logs Excel' );
                this.fetchingInProgress = false;
            },
            ( error ) => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }

    onSearch () {
        this.filterResults();
    }

    keys ( response: { data: {}; } ): Array<string> {
        return Object.keys( response.data );
    }

    showFirstImage ( imagerUrl: string ) {
        window.open( imagerUrl, '_blank' );
    }

    showSecondImage ( imagerUrl: string ) {
        window.open( imagerUrl, '_blank' );
    }

    ImageAdded ( value: boolean ) {
        if ( value ) {
            this.filterResults();
        }
    }

}
