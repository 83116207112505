import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import { Permission } from '../models/permission.model';



@Injectable( {
    providedIn: 'root',
} )
export class PermissionService {

    constructor ( private api: ApiService ) { }

    getAll (): Observable<Permission[]> {
        return this.api.get( 'permissions' );
    }

    find ( id: number ): Observable<Permission> {
        return this.api.get( 'permissions/' + id );
    }

    create ( values: Object ) {
        return this.api.post( 'permissions', values );
    }

    update ( id: number, values: Object ) {
        return this.api.put( 'permissions/' + id, values );
    }

    delete ( id: number ): Observable<any> {
        return this.api.delete( 'permissions/' + id );
    }

    getAllDeleted (): Observable<any> {
        return this.api.get( 'permission/softDeletes' );
    }

    restore ( id: number ): Observable<any> {
        return this.api.put( 'permission/restore/' + id );
    }

    permanentDelete ( id: number ): Observable<any> {
        return this.api.delete( 'permission/permanentDelete/' + id );
    }

}
