import { ConfirmationModalOptions } from './../../core/services/confirmation-modal.service';
import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Permission } from '../models/permission.model';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-permissions-list',
    templateUrl: './permissions-list.component.html',
    styleUrls: [ './permissions-list.component.css' ],
} )
export class PermissionsListComponent implements OnInit {

    permissions!: Permission[];

    constructor (
        private permissionService: PermissionService,
        private common: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) { }

    ngOnInit () {
        this.getAllPermissions();
    }

    getAllPermissions () {
        this.permissionService.getAll().subscribe(
            response => this.permissions = response
        );
    }

    onModalUpdated () {
        this.getAllPermissions();
    }

    deletePermission ( id: number ): void {

        this.permissionService.delete( id ).subscribe( {
            next: ( response: any ) => {

                this.common.notifications.success( 'Permission removed!', 'Success!' );

                this.getAllPermissions();
            },
            error: ( error: any ) => {
                this.common.notifications.error( error );
            }
        } );
    }

    ondeletePermissionButtonClicked ( id: number ) {

        const handleEvent = ( event: { preventDefault: () => any; } ) => event && event.preventDefault();

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.deletePermission( id ) );

    }

}
