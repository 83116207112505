import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';

@Injectable( {
    providedIn: 'root'
} )
export class GstService {

    constructor (
        private api: ApiService
    ) {
    }

    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'gst', params );
    }

    show ( param: string | number ): Observable<any> {
        return this.api.get( 'gst/' + param );
    }

    importExcel ( values: Object ): Observable<any> {
        return this.api.post( 'gst/import-excel', values );
    }

    getResponse ( id: string, source: string ): Observable<any> {
        return this.api.get( 'gst/' + id + '/response?source=' + source );
    }

    getDataForExcel ( params: string | HttpParams ): Observable<Pagination> {
        return this.api.get( 'gst/export-excel?' + params );
    }

    search ( values: Object ): Observable<any> {
        return this.api.post( 'gst/search', values );
    }

    getStats ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'gst/stats', params );
    }

    resendAll ( params: Params | HttpParams | undefined, type: string ) {
        return this.api.get( 'gst/resend-all?type=' + type, params );
    }

    resend ( id: string, type: string ) {
        return this.api.get( 'gst/' + id + '/resend?type=' + type );
    }

}
