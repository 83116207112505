import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { EducationVerification } from '../../core/models/education-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EducationService } from '../../core/services/education.service';
import { User } from '@sentry/browser';
import { validateLength } from 'src/app/common/validators/length-validator.directive';
import { InstituteService } from 'src/app/core/services/institute.service';

@Component( {
    selector: 'app-education-edit-modal',
    templateUrl: './education-edit-modal.component.html',
    styleUrls: [ './education-edit-modal.component.css' ],
} )
export class EducationEditModalComponent implements OnInit {

    isSubmitting: boolean = false;
    editForm!: FormGroup;
    vendors!: User[];
    uploader: FileUploader = new FileUploader( { url: '' } );

    verification: EducationVerification = new EducationVerification();
    current_date!: string;
    date: Date = new Date();
    created_at!: string;

    @ViewChild( 'editModal', { static: false } )
    editModal!: ModalDirective;

    @Output()
    onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private educationService: EducationService,
        private commonService: CommonService,

        private fb: FormBuilder,
        protected el: ElementRef,
        protected http: HttpClient,
        protected instituteService: InstituteService,
    ) { }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {

        this.editForm = this.fb.group( {
            id: [ '' ],
            employee_id: [ '' ],
            completed_at: [ '' ],
            employee_name: [ '' ],
            father_name: [ '' ],
            mobile_number: [ '' ],
            status_name: [ '' ],
            status_id: [ '' ],
            institute_id: [ '' ],
            institute_name: [ '' ],
            sub_institute_id: [ '' ],
            sub_institute_name: [ '' ],
            college_name: [ '', [ Validators.required ] ],
            country_name: [ '' ],
            country_id: [ '' ],
            sent_to_vendor_on: [ '' ],
            method: [ '' ],
            client_remarks: [ '' ],
            payment_reference_number: [ '' ],
            enrollment_number: [ '', [ Validators.required ] ],
            passing_year: [ '', [ Validators.required ] ],
            course_name: [ '', [ Validators.required ] ],
            cgpa: [ '' ],
            india_post_id: [ '', [ validateLength( '13' ) ] ],
            vendor_id: [ '' ],
            vendor_name: [ '' ],
            discipline: [ '' ],
            insuff_raised_at: [ '' ],
            insuff_cleared_at: [ '' ],
        } );
        this.current_date = new Date( this.date.getTime() - ( this.date.getTimezoneOffset() * 60000 ) ).toJSON().slice( 0, 10 );
    }

    show ( employeeId: number, verificationId: number ): void {

        this.educationService.findByEmployeeId( employeeId ).subscribe(
            response => {
                response.education_verifications.filter( item => {
                    if ( item.id === verificationId ) {
                        this.verification = item;
                    }
                } );

                this.created_at = this.verification.created_at;

                this.editForm.patchValue( this.verification );

                if ( this.editForm.get( 'completed_at' )?.value && this.commonService.userLevelId() < 4 ) {

                    this.editForm.disable();

                } else {
                    this.editForm.enable();
                }

                this.editModal.show();
            }
        );

        this.commonService.getVendors( 'education' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
    }

    hide (): void {
        this.editModal.hide();
    }

    // Call back from the modal to update the list of all institutes.
    onInstituteCreated (): void {
        this.instituteService.populateCache( true );
    }

    update (): void {

        this.educationService.update( this.editForm.value.id, this.editForm.value, '' ).subscribe(
            () => {
                this.hide();
                this.commonService.notifications.success( 'Verification Updated', 'Verification Updated' );
                this.isSubmitting = false;
                this.onVerificationUpdated.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
            }
        );
    }

    // Call back from the modal to update details
    onModalUpdated (): void {
        this.onVerificationUpdated.emit( true );
    }
}
