<div bsModal #workTypeCreate="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Work Type</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <form [formGroup]="workTypeFormGroup" (ngSubmit)="submitForm()"
                        (keydown.enter)="$event.preventDefault()">
                        <fieldset [disabled]="submitted">
                            <fieldset class="form-group">
                                <label class="center-block">Select Verification Type</label>
                                <select class="form-control" formControlName="work_type" required #mySelect
                                    (change)='onOptionsSelected($event)'>
                                    <option *ngFor="let work_type of work_types | keyvalue" [value]="work_type.value">{{
                                        work_type.value }}</option>
                                </select>
                            </fieldset>
                            <fieldset class="form-group">
                                <label class="center-block">Select Completion Statuses</label>
                                <app-multi-select [options]="statuses[verifications]" [placeholder]="'Select Company'"
                                    [controlName]="'completion_statuses'"
                                    [formGName]="workTypeFormGroup"></app-multi-select>
                            </fieldset>
                            <fieldset class="form-group">
                                <label>Title </label>
                                <input formControlName="title" class="form-control form-control-lg" type="text" />
                            </fieldset>
                            <fieldset class="form-group">
                                <label>Description</label>
                                <textarea class="form-control form-control-lg" resize="none" name="description"
                                    id="description" formControlName="description" rows="6"
                                    placeholder="Add Description here."></textarea>
                            </fieldset>
                            <button class="btn btn-lg btn-primary btn-block" type="submit"
                                [disabled]="!workTypeFormGroup.valid">
                                Create
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>