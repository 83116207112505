import { Component, OnInit } from '@angular/core';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VehicleService } from '../../core/services/vehicle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-stolen-vehicle-excel-search',
    templateUrl: './stolen-vehicle-excel-search.component.html',
    styleUrls: [ './stolen-vehicle-excel-search.component.css' ],
} )
export class StolenVehicleExcelSearchComponent extends ImportExcelComponent implements OnInit {

    override isSubmitting = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    uploadForm: FormGroup;
    validationMessages: ValidationMessages = {};

    constructor (
        private vehicleService: VehicleService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            'excel': [ '', Validators.required ],
        } );

    }

    ngOnInit () {
    }

    // Initialize the excelForm.
    initExcelForm () {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages () {
        this.validationMessages[ 'rc_number' ] = 'Required';
    }

    applyValidations () {
    }

    importExcel () {

        this.vehicleService.importStolenVehicles( this.excelForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Success', 'Excel will be downloaded' );
                this.isSubmitting = false;
                this.downloadExcel( response, 'Stolen Searched Vehicles' );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }


    // Generate the workbook and write the data to it.
    generateExcelFile ( data: any ): any {

        const sheetName = 'Sheet1';

        const workBook: any = { SheetNames: [], Sheets: {} };

        const ws: any = utils.json_to_sheet( data );

        workBook.SheetNames.push( sheetName );
        workBook.Sheets[ sheetName ] = ws;

        return write( workBook, { bookType: 'xlsx', type: 'binary' } );
    }

    // Add the sheet data to array buffer.
    s2ab ( s: any ): ArrayBuffer {
        const buf = new ArrayBuffer( s.length );
        const view = new Uint8Array( buf );
        for ( let i = 0; i != s.length; ++i ) { view[ i ] = s.charCodeAt( i ) & 0xFF; }

        return buf;
    }

    // Download the excel
    downloadExcel ( data: any[], excelName: string ): void {

        saveAs( new Blob( [ this.s2ab( this.generateExcelFile( data ) ) ], { type: 'application/octet-stream' } ), excelName + '.xlsx' );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
