<div class="panel panel-default" [formGroup]="pccForm">
    <div class="panel-heading">
        Pcc Verification

        <h4 class="panel-title pull-right">

            <div class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">
                <span class="glyphicon">
                    <input type="checkbox" (click)="sectionToggle($event)" [checked]="showSection"></span>

            </div>
        </h4>
    </div>
    <div id="collapse-pcc" [className]="showSection ? 'panel-collapse collapse in' : 'panel-collapse collapse'">
        <div class="panel-body">
            <div class="col-md-12 margin-bottom-10">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"></textarea>
            </div>

            <div class="col-md-7 margin-bottom-10 padding-top-0-6 text text-danger"
                *ngIf="pccVerifications?.length == 0">
                Please select atleast one verification
            </div>

            <ng-container *ngFor="let verification of pccVerifications?.controls; let i=index">

                <app-pcc-form-item [formGroup]="verification" [index]="i"></app-pcc-form-item>

            </ng-container>

        </div>
    </div>
</div>