<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)="$event.preventDefault()">
                <div class="row">

                    <div *ngIf="companies" class="form-group col-md-2">
                        <label class="center-block">Company</label>
                        <app-multi-select [options]="companies" [placeholder]="'Select Company'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"
                            (onDropDownClosed)="onCompanyDropDownClosed()"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>

                    </div>

                    <div class="form-group col-md-2" *ngIf="formStatuses && isFormStatusFilterRequired">
                        <label class="center-block"> Form Status</label>
                        <app-multi-select [options]="formStatuses" [placeholder]="'Select Form Status'"
                            [controlName]="'form_status'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Create New Form</label>
                        <a class="btn btn-primary btn-addon" routerLink="/forms/create">
                            <i class="fa fa-plus"></i>
                            Create New Form
                        </a>
                    </div>
                    <div class="form-group col-md-2" [appCan]="'admin'">
                        <label class="center-block">Deleted Forms</label>
                        <a class="btn btn-primary btn-addon" routerLink="/forms/deleted">
                            <i class="fa fa-list"></i>
                            Deleted Forms
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>