import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmployeeAdditionalDetails } from '../../core/models/employee-additional-details.model';
import { Employee } from '../../core/models/employee.model';
import { CommonService } from '../../core/services/common.service';
import { EmployeeAdditionalDataService } from '../../core/services/employee-additional-data.service';

@Component( {
    selector: 'app-employee-additional-details-modal',
    templateUrl: './employee-additional-details-modal.component.html',
    styleUrls: [ './employee-additional-details-modal.component.css' ],
} )
export class EmployeeAdditionalDetailsModalComponent implements OnInit {

    isSubmitting: boolean = false;
    editForm!: FormGroup;
    employeeId!: number;
    additionalDetails: EmployeeAdditionalDetails = new EmployeeAdditionalDetails();
    employee: Employee = new Employee();
    depositArray!: any[];
    numberArray!: any[];
    referenceSourceArray!: any[];
    houseStatusArray!: any[];
    educationArray!: any[];
    referenceRelationArray!: any[];
    residingSinceArray!: any[];
    martialStatusArray!: any[];
    currentWorkArray!: any[];

    @ViewChild( 'detailsModal', { static: false } )
    public detailsModal!: ModalDirective;

    constructor (

        private fb: FormBuilder,
        protected el: ElementRef,
        private employeeService: EmployeeAdditionalDataService,
        private commonService: CommonService,
    ) { }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {

        this.editForm = this.fb.group( {
            id: [ '' ],
            employee_id: [ '' ],
            zone: [ '' ],
            city: [ '' ],
            current_work_name: [ '' ],
            current_work_id: [ '' ],
            current_work_description: [ '' ],
            driving_experience: [ '' ],
            monthly_income: [ '' ],
            commercial_licence_issue_date: [ '' ],
            martial_status: [ '' ],
            number_of_children: [ '' ],
            number_of_dependents: [ '' ],
            referred_by: [ '' ],
            current_address_status: [ '' ],
            residing_since: [ '' ],
            permanent_address_status: [ '' ],
            reference_relation: [ '' ],
            education_qualification: [ '' ],
            source_of_deposit: [ '' ],
            age: [ '' ],
        } );
    }

    show ( employeeId: number ): void {

        this.employeeService.getDepositArray().then( response => this.depositArray = response );
        this.employeeService.getEducationArray().then( response => this.educationArray = response );
        this.employeeService.getReferenceRelationArray().then( response => this.referenceRelationArray = response );
        this.employeeService.getReferenceSourceArray().then( response => this.referenceSourceArray = response );
        this.employeeService.getHouseStatusArray().then( response => this.houseStatusArray = response );
        this.employeeService.getNumberArray().then( response => this.numberArray = response );
        this.employeeService.getResidingSinceArray().then( response => this.residingSinceArray = response );
        this.employeeService.getMartialStatusArray().then( response => this.martialStatusArray = response );
        this.employeeService.getCurrentWorkArray().then( response => this.currentWorkArray = response );

        this.employeeId = employeeId;

        this.employeeService.findAdditionalDetails( employeeId ).subscribe(
            response => {

                this.employee = response;

                this.additionalDetails = response.addition_details;

                this.editForm.patchValue( response.addition_details );

                this.detailsModal.show();
            }
        );
    }

    hide (): void {
        this.detailsModal.hide();
    }

    update (): void {
        this.employeeService.updateAdditionalDetails( this.employeeId, this.editForm.value ).subscribe(
            response => {
                this.hide();
                this.commonService.notifications.success( 'Verification Updated', 'Verification Updated' );
                this.isSubmitting = false;
            },
            error => {
                this.commonService.displayValidationErrors( error );
            }
        );
    }

    // Set the value of the form control district_id
    OnTypeheadSelect ( event: any, name: string ): void {

        const controlId: string = name + '_id';
        this.editForm.get( controlId )?.setValue( event.item.id );
    }
}
