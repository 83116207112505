import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { validateDate } from 'src/app/common/validators/date-validator.directive';
import { validateEmail } from 'src/app/common/validators/email-validator.directive';
import { validateLength } from 'src/app/common/validators/length-validator.directive';
import { validateNumericCharacters } from 'src/app/common/validators/numeric-validator.directive';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { EmployeeService } from 'src/app/core/services/employee.service';


@Component( {
    selector: 'app-employee-bulk-update',
    templateUrl: './employee-bulk-update.component.html',
    styleUrls: [ './employee-bulk-update.component.css' ],
} )
export class EmployeeBulkUpdateComponent extends ImportExcelComponent
    implements OnInit {
    override excelForm!: FormGroup;
    uploadForm!: FormGroup;
    validationMessages: ValidationMessages | any = [];
    verifications!: any[];
    showExcel: boolean = false;
    sampleExcelModal!: ModalDirective;
    requiredMessage: string = 'required';
    pincodeLengthMessage: string = 'Must be of length 6 digits';
    dateMessage: string = 'Allowed: "dd-mm-yyyy" ';
    mobileNumberLengthMessage: string = 'Must be of length 10 digits';
    dobMessage: string = 'Allowed: "dd-mm-yyyy;yyyy" ';
    nameMessage: string = 'Only alphabets are allowed';
    emailMessage: string = 'Invalid email address';
    recruiterNumberMessage: string = 'Invalid mobile number';
    override acceptedFileType = AcceptedFileType.EXCEL;

    // for returning unformatted text while parsing excel sheet
    override rawInput: boolean = true;

    constructor (
        protected override fb: FormBuilder,
        private employeeService: EmployeeService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) {
        super( fb );
    }

    ngOnInit (): void {

        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );

        this.verifications = [
            'identity',
            'current_address',
            'permanent_address',
            'icr',
            'icrcase',
            'education',
            'employment',
            'reference',
            'document',
            'globaldb',
            'employee',
            'pcc',
        ];

    }

    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
            verification: [ '', Validators.required ],
            action: [ '' ],
        } );
    }

    // Type blank for values we want to set to null
    applyValidations (): void {

        this.excelFormArray.controls.forEach( form => {

            for ( const title of this.titles ) {

                if ( form.get( title ) ) {

                    form.get( title )?.setValidators( [ Validators.required ] );
                    form.get( title )?.updateValueAndValidity();

                }
            }

            if ( form.get( 'name' ) ) {

                form.get( 'name' )?.setValidators( [
                    Validators.required,
                    validateAllowedCharacters( '' ),
                ] );

                form.get( 'name' )?.updateValueAndValidity();
            }

            if ( form.get( 'father_name' ) ) {

                form.get( 'father_name' )?.setValidators( [
                    Validators.required,
                    validateAllowedCharacters( '()/,' ),
                ] );

                form.get( 'father_name' )?.updateValueAndValidity();
            }

            if ( form.get( 'pincode' ) ) {

                form.get( 'pincode' )?.setValidators( [
                    Validators.required,
                    validateNumericCharacters( '', true ),
                    validateLength( '6', true ),
                ] );

                form.get( 'pincode' )?.updateValueAndValidity();
            }

            if ( form.get( 'dob' ) ) {

                form.get( 'dob' )?.setValidators( [
                    Validators.required,
                    validateDate( 'dd-mm-yyyy,yyyy', true ),
                ] );

                form.get( 'dob' )?.updateValueAndValidity();
            }

            if ( form.get( 'completed_at' ) ) {

                form.get( 'completed_at' )?.setValidators( [
                    Validators.required,
                    validateDate( 'dd-mm-yyyy', true ),
                ] );

                form.get( 'completed_at' )?.updateValueAndValidity();
            }

            if ( form.get( 'reference_email' ) ) {

                form.get( 'reference_email' )?.setValidators( [
                    Validators.required,
                    validateEmail( true ),
                ] );

                form.get( 'reference_email' )?.updateValueAndValidity();
            }

            if ( form.get( 'hr_email' ) ) {

                form.get( 'hr_email' )?.setValidators( [
                    Validators.required,
                    validateEmail( true ),
                ] );

                form.get( 'hr_email' )?.updateValueAndValidity();
            }

            if ( form.get( 'joining_date' ) ) {

                form.get( 'joining_date' )?.setValidators( [
                    Validators.required,
                    validateDate( 'dd-mm-yyyy', true ),
                ] );

                form.get( 'joining_date' )?.updateValueAndValidity();
            }

            if ( form.get( 'end_date' ) ) {

                form.get( 'end_date' )?.setValidators( [
                    Validators.required,
                    validateDate( 'dd-mm-yyyy', true ),
                ] );

                form.get( 'end_date' )?.updateValueAndValidity();
            }

            if ( form.get( 'issue_date' ) ) {

                form.get( 'issue_date' )?.setValidators( [
                    Validators.required,
                    validateDate( 'dd-mm-yyyy', true ),
                ] );

                form.get( 'issue_date' )?.updateValueAndValidity();
            }

            if ( form.get( 'sent_to_vendor_on' ) ) {

                form.get( 'sent_to_vendor_on' )?.setValidators( [
                    Validators.required,
                    validateDate( 'dd-mm-yyyy', true ),
                ] );

                form.get( 'sent_to_vendor_on' )?.updateValueAndValidity();
            }


            // TODO:: removed validation
            // if ( form.get( 'hr_number' ) ) {
            //   form
            //     .get( 'hr_number' )
            //     .setValidators( [
            //       validateNumericCharacters( '', true ),
            //       validateLength( '10', true )
            //     ] );
            //   form.get( 'hr_number' ).updateValueAndValidity();
            // }

            if ( form.get( 'reference_number' ) ) {

                form.get( 'reference_number' )?.setValidators( [
                    validateNumericCharacters( '', true ),
                    validateLength( '10', true ),
                ] );

                form.get( 'reference_number' )?.updateValueAndValidity();
            }

            if ( form.get( 'recruiter_name' ) ) {
                form.get( 'recruiter_name' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
                form.get( 'recruiter_name' )?.updateValueAndValidity();
            }

            if ( form.get( 'recruiter_email' ) ) {
                form.get( 'recruiter_email' )?.setValidators( [ validateEmail() ] );
                form.get( 'recruiter_email' )?.updateValueAndValidity();
            }

            if ( form.get( 'recruiter_number' ) ) {

                form.get( 'recruiter_number' )?.setValidators( [
                    Validators.pattern( /^(\+|\d)[0-9 ,+-]*$/ )
                ] );

                form.get( 'recruiter_number' )?.updateValueAndValidity();
            }

            this.setValidationMessages();
        } );
    }

    setValidationMessages (): void {
        this.validationMessages[ 'name' ] = this.requiredMessage;
        this.validationMessages[ 'pincode' ] = this.pincodeLengthMessage;
        this.validationMessages[ 'reference_number' ] = this.mobileNumberLengthMessage;
        this.validationMessages[ 'dob' ] = this.dobMessage;
        this.validationMessages[ 'completed_at' ] = this.dateMessage;
        this.validationMessages[ 'date_of_issue' ] = this.dateMessage;
        this.validationMessages[ 'date_of_expiry' ] = this.dateMessage;
        this.validationMessages[ 'joining_date' ] = this.dateMessage;
        this.validationMessages[ 'end_date' ] = this.dateMessage;
        this.validationMessages[ 'sent_to_vendor_on' ] = this.dateMessage;
        this.validationMessages[ 'recruiter_name' ] = this.nameMessage;
        this.validationMessages[ 'recruiter_email' ] = this.emailMessage;
        this.validationMessages[ 'recruiter_number' ] = this.recruiterNumberMessage;
    }

    importExcel (): void {

        this.employeeService
            .bulkUpdateEmployeesFromExcel( this.excelForm.value )
            .subscribe( {
                next: ( response: any ) => {

                    if ( response == 'updated' ) {

                        this.commonService.notifications.success( 'Successful', 'Submission Successful' );
                    }
                },
                error: ( errors: any ) => {

                    this.commonService.notifications.error( 'Error', 'Something went wrong!' );

                }
            } );

    }

    onImportExcelButtonClicked ( action: string ): void {

        this.addMetaFieldsInForm( this.excelFormArray, false );

        this.excelForm.get( 'action' )?.setValue( action );

        const options: ConfirmationModalOptions = { title: 'Are you sure you want to ' + action + ' this verification ?' };

        this.confirmationModalService.confirm( options, () => this.importExcel() );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }

}
