import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerificationList } from '../../core/components/verification-list.component';
import { EducationVerification } from '../../core/models/education-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EducationService } from '../../core/services/education.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-education-list',
    templateUrl: './education-list.component.html',
    styleUrls: [ './education-list.component.css' ],
} )
export class EducationListComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications: EducationVerification[] = [];
    isSubmitting: boolean = false;
    attachments: any[] = [];
    statsVisible: boolean = false;

    constructor (
        protected override verificationService: EducationService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        private bsModalService: BsModalService,
        public override confirmationModalService: ConfirmationModalService,

    ) {
        super( confirmationModalService );
    }

    get users () {
        return this.global.fetchUsers();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {

        this.verification = 'education';
        this.subscribeToQueryChanges();
        this.getVendorsList();

    }

    filterResults (): void {

        this.verificationService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    showEducation ( education: EducationVerification ): void {

        this.router.navigate( [ 'education', education.employee_id, 'edit' ], this.navigationExtras );
    }

    populateExtraDetails ( educationVerifications: EducationVerification[] ): void {

        for ( const index in educationVerifications ) {

            const verification: EducationVerification = educationVerifications[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    showStats (): void {

        this.statsVisible = !this.statsVisible;
    }


    // overriding super class method
    // TODO:: move to super class after implementing this in all verificaions
    override exportExcel () {

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/' + this.verification + '/downloadExcel',
            fileName: this.verification,
            method: 'get',
            postParams: null,
        };

        this.bsModalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )
    }
}
