import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { Employee } from '../../core/models/employee.model';
import { CommonService } from '../../core/services/common.service';
import { IcrService } from '../../core/services/icr.service';
import { IcrVcourtComponent } from '../icr-vcourt.component';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-submit-to-vcourt',
    templateUrl: './submit-to-vcourt.component.html',
    styleUrls: [ './submit-to-vcourt.component.css' ],
    providers: [ IcrService ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class SubmitToVcourtComponent extends IcrVcourtComponent implements OnInit {


    constructor (
        public override icrService: IcrService,
        public override route: ActivatedRoute,
        public override router: Router,
        public override fb: FormBuilder,
        public override global: GlobalService,
        public override commonService: CommonService,
        public override changeDetect: ChangeDetectorRef,
        public override confirmationModalService: ConfirmationModalService
    ) {
        super( icrService, commonService, route, router, global, fb, changeDetect, confirmationModalService );

        // this.initForm();
    }

    override ngOnInit (): void {
        this.checkedIds = [];
        this.verification = 'icr';
        this.subscribeToQueryChanges();
    }

    get states () { return this.global.fetchStates(); }

    get statuses () { return this.global.fetchStatuses(); }

    // initialize the form values
    override get districts () { return this.global.fetchDistricts(); }

    populateForm ( employees: Employee[] ): void {
        this.changeDetect.markForCheck();
        for ( const employee of employees ) {
            if ( employee.address_verifications && employee.icr_verifications ) {
                for ( let i: number = 0; i < employee.address_verifications.length; i++ ) {
                    if (
                        employee.icr_verifications[ i ] &&
                        employee.address_verifications[ i ]
                    ) {
                        if ( employee.icr_verifications[ i ].address == null ) {
                            employee.icr_verifications[ i ].address =
                                employee.address_verifications[ i ].address;
                            employee.icr_verifications[ i ].landmark =
                                employee.address_verifications[ i ].landmark;
                            employee.icr_verifications[ i ].pincode =
                                employee.address_verifications[ i ].pincode;
                            employee.icr_verifications[ i ].state_id =
                                employee.address_verifications[ i ].state_id;
                            employee.icr_verifications[ i ].district_id =
                                employee.address_verifications[ i ].district_id;
                            employee.icr_verifications[ i ].district_name =
                                employee.address_verifications[ i ].district_name;
                            employee.icr_verifications[ i ].state_name =
                                employee.address_verifications[ i ].state_name;
                        }
                    }
                }
            }
        }
        const verificationFormGroup: any = employees.map( employee =>
            this.fb.group( {
                name: [ employee.name ],
                employee_id: [ employee.id ],
                track_id: [ employee.track_id ],
                created_at: [ employee.created_at ],
                is_checked: [ false ],
                father_name: [ employee.father_name ],
                dob: [ employee.dob ],
                icr_verifications_form_array: this.setIcrVerification( employee ),
                address_verifications: [ employee.address_verifications ],
                via: '',
            } )
        );

        const verificationGroupArray: FormArray = this.fb.array( verificationFormGroup );

        this.icrFormGroup.setControl( 'formArray', verificationGroupArray );
    }


    filterResults (): void {
        this.icrService.getEmployees( this.filterParams ).subscribe( response => {
            this.populateForm( response.data );
            this.pagination = response;
            this.employees = response.data;
            this.checkedIds = [];
        } );
    }

    showIcr ( employee: FormGroup ): void {
        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            fragment: this.filterParams.toString(),
            queryParamsHandling: 'merge',
        };

        this.router.navigate(
            [ 'icr', employee.get( 'employee_id' )?.value, 'edit' ],
            navigationExtras
        );
    }


    OnTypeheadSelect ( event: any, name: string, icr: FormGroup ): void {

        const controlId: string = name + '_id';

        if ( name == 'state' ) {
            icr.get( 'district_name' )?.setValue( '' );
            icr.get( controlId )?.setValue( event.item.id );
        } else if ( name == 'district' ) {
            icr.get( 'district_id' )?.setValue( event.item.id );
        } else {
            // this.verificationFormGroup.get( controlId ).setValue( event.item.id );
        }
    }

    trackByIcrVerification ( index: number, icrVerification: { id: any; } ): number {
        return icrVerification.id
    }
}
