import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Pagination } from '../models/pagination.model';
@Injectable( {
    providedIn: 'root'
} )
export class GlobalDbLogService {

    constructor ( private apiService: ApiService ) { }

    filter ( params: Params ): Observable<Pagination> {
        return this.apiService.get( 'global-db-log', params );
    }

    resend ( id: number ) {
        return this.apiService.get( 'global-db-log/' + id + '/resend' );
    }

    getStats ( params: Params ) {
        return this.apiService.get( 'global-db-log/stats', params );
    }

    search ( params: object ) {
        return this.apiService.post( 'global-db-log/search', params );
    }

    getDataForExcel ( params: Params ) {
        return this.apiService.get( 'global-db-log/export-excel', params );
    }

    resendAll ( params: Params ) {
        return this.apiService.get( 'global-db-log/resend-all', params )
    }

    importExcel ( params: object ) {
        return this.apiService.post( 'global-db-log/import-excel', params );
    }
}
