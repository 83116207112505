<div bsModal #companyEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="companyEditForm" (ngSubmit)="updateEmploymentCompany()"
        (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Company</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <input formControlName="id" class="form-control form-control-lg" type="hidden"
                                        ngModel="{{company.id}}" />
                                    <label class="center-block"> Name</label>
                                    <input formControlName="company_name" class="form-control form-control-lg"
                                        ngModel="{{company.name}}" />

                                </fieldset>
                            </div>
                            <div class="col-md-9">
                                <fieldset class="form-group">
                                    <label>Address </label>
                                    <input formControlName="address" class="form-control form-control-lg"
                                        ngModel="{{company.address}}" />
                                </fieldset>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Contact Number </label>
                                    <input formControlName="contact_number" class="form-control form-control-lg"
                                        ngModel="{{company.contact_number}}" />

                                </fieldset>

                            </div>
                            <div class="col-md-3 form-group">
                                <label> Select State</label>
                                <select class="form-control" formControlName="state_id" [(ngModel)]="company.state_id">
                                    <option *ngFor="let state of states" [value]="state.id">
                                        {{state.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-3 form-group">
                                <label>Select District</label>
                                <select class="form-control" formControlName="district_id"
                                    [(ngModel)]="company.district_id">
                                    <option *ngFor="let district of districts |filterByStateId:company.state_id"
                                        [value]="district.id">
                                        {{district.name}}
                                    </option>
                                </select>
                            </div>


                            <div class="col-md-3 form-group">
                                <label>Pincode </label>
                                <input formControlName="pincode" class="form-control form-control-lg"
                                    ngModel="{{company.pincode}}" />
                            </div>

                        </div>

                        <div class="row">

                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Email </label>
                                    <input formControlName="email" class="form-control form-control-lg"
                                        ngModel="{{company.email}}" />

                                </fieldset>

                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Website </label>
                                    <input formControlName="website" class="form-control form-control-lg"
                                        ngModel="{{company.website}}" />

                                </fieldset>
                            </div>

                            <fieldset class="form-group col-md-6">
                                <label>Remarks </label>
                                <textarea rows="1" formControlName="company_remarks"
                                    class="form-control form-control-lg" ngModel="{{company.remarks}}"></textarea>

                            </fieldset>
                        </div>


                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" (click)="updateEmploymentCompany()"
                            [disabled]="!companyEditForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>