import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { PermitService } from '../../core/services/permit.service';

@Component( {
    selector: 'app-permit-response-modal',
    templateUrl: './permit-response-modal.component.html',
    styleUrls: [ './permit-response-modal.component.css' ],
} )
export class PermitResponseModalComponent {

    apiResponse!: any;

    @ViewChild( 'permitApiResponse', { static: false } )
    public permitApiResponse!: ModalDirective

    constructor ( private permitService: PermitService,
        private commonService: CommonService, ) {
    }

    show ( log: { id: string; } ) {

        this.permitService.getApiResponse( log.id ).subscribe(
            response => {

                this.apiResponse = response;

                this.permitApiResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    hide () {
        this.permitApiResponse.hide();
    }

}
