<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Pan Home Page</h1>
    </div>
    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>



<app-pan-stats [filterParams]="filterParams"></app-pan-stats>

<app-pan-filters [filterParams]="filterParams"></app-pan-filters>

<app-pan-response-modal #panResponseModal></app-pan-response-modal>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 20%">Pan Number</th>
                        <th style="width: 10%">Status</th>
                        <th style="width: 10%">Source</th>
                        <th style="width: 10%">Data Type</th>
                        <th style="width: 20%">Response</th>
                        <th style="width: 10%">TimeLine</th>
                        <th style="width: 20%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    {{log.id}}
                                </li>
                                <li>
                                    <strong>{{log.pan_number}}</strong>
                                </li>
                                <li>
                                    <a class="btn-link text-main text-semibold"
                                        href="/#/employee/{{log.employee_id}}/edit" target="_blank">{{ log.track_id }}
                                    </a>
                                </li>
                                <li>
                                    <strong>{{log.client}}</strong>
                                </li>
                            </ul>
                        </td>

                        <td>
                            <div>
                                <a [class]="log.class">{{log.status}}</a>
                            </div>


                        </td>
                        <td>
                            {{log.driver | uppercase}}
                        </td>
                        <td>
                            {{log.data_type | titlecase}}
                        </td>
                        <td>
                            {{log.name}}
                            <br>
                            {{log.assessee_type}}
                            <br>
                            <a class="btn btn-primary btn-addon btn-xs" *ngIf="(log.response )"
                                (click)="panResponseModal.show(log.driver, log.response)">View detailed response</a>
                        </td>
                        <td>
                            <a class="btn btn-primary btn-xs" (click)="resend(log)">
                                <i class="fa fa-refresh"></i>
                                Resend
                            </a>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Created At: {{log.created_at}}</li>
                                <li>Completed At: {{log.completed_at}}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>