import { NgModule } from '@angular/core';
import { CompanyListComponent } from './company-list/company-list.component';
import { CommonModule } from '@angular/common';
import { CompanyEditComponent } from './company-edit/company-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyCreateComponent } from './company-create/company-create.component';
import { CompanyShowComponent } from './company-show/company-show.component';
import { LotListComponent } from '../lot/lot-list/lot-list.component';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../core/shared.module';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { CompanyExcelComponent } from './company-excel/company-excel.component';
import { CompanyService } from '../core/services/company.service';
import { LotService } from '../core/services/lot.service';
import { MetafieldService } from '../core/services/metafield.service'
import { CompanyFiltersComponent } from './company-filters/company-filters.component';
import { CompanyMetafieldsComponent } from './company-metafields/company-metafields.component';

import { RoleCreateModalComponent } from './role/role-create-modal/role-create-modal.component';
import { RoleListComponent } from './role/role-list/role-list.component';
import { RoleEditModalComponent } from './role/role-edit/role-edit-modal.component';
import { RoleDeletedComponent } from './role/role-deleted/role-deleted.component';
import { CompanyUpdateRoleComponent } from './company-update-role/company-update-role.component';
import { CompanyAssignRoleModalComponent } from './company-assign-role-modal/company-assign-role-modal.component';
import { ClientCrmListComponent } from './client-crm/client-crm-list/client-crm-list.component';
import { ClientCrmCreateComponent } from './client-crm/client-crm-create/client-crm-create.component';
import { ClientCrmEditComponent } from './client-crm/client-crm-edit/client-crm-edit.component';
import { ClientCrmHeadersModalComponent } from './client-crm/client-crm-headers-modal/client-crm-headers-modal.component'
import { ClientCrmParamsModalComponent } from './client-crm/client-crm-params-modal/client-crm-params-modal.component';
import { ClientCrmVerificationSettingModalComponent } from './client-crm/client-crm-verification-setting-modal/client-crm-verification-setting-modal.component';
import { ClientCrmFiltersComponent } from './client-crm/client-crm-filters/client-crm-filters.component';
import { CompanyListItemComponent } from './company-list-item/company-list-item.component';
import { CompanyHistoryComponent } from './company-history/company-history.component'
@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPaginationModule,
        SharedModule,
        TrimValueAccessorModule,

    ],
    declarations: [
        CompanyListComponent,
        CompanyEditComponent,
        CompanyCreateComponent,
        CompanyShowComponent,
        LotListComponent,
        CompanyExcelComponent,
        CompanyFiltersComponent,
        CompanyMetafieldsComponent,
        RoleCreateModalComponent,
        RoleListComponent,
        RoleEditModalComponent,
        RoleDeletedComponent,
        CompanyUpdateRoleComponent,
        CompanyAssignRoleModalComponent,
        ClientCrmListComponent,
        ClientCrmCreateComponent,
        ClientCrmEditComponent,
        ClientCrmHeadersModalComponent,
        ClientCrmParamsModalComponent,
        ClientCrmVerificationSettingModalComponent,
        ClientCrmFiltersComponent,
        CompanyListItemComponent,
        CompanyHistoryComponent,
    ],
    providers: [ CompanyService, LotService, MetafieldService ],
} )
export class CompanyModule {
}
