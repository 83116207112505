import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/**
 * Check if email is valid or not.
 *
 * @returns {ValidatorFn}
 */
export function validateEmail ( blank = false ): ValidatorFn {

    const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Create Regex Pattern
    const regexPattern = new RegExp( expression );

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        // for bulk update
        if ( blank ) {
            if ( control.value == 'blank' ) {
                return {};
            }
        }

        if ( !control.value ) {

            return {};
        }

        if ( regexPattern.test( control.value ) ) {

            return {};
        }

        return { 'validateEmail': { value: control.value } };
    };

}


@Directive( {
    selector: '[validateEmail]',
    providers: [ { provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true } ],
} )
export class EmailValidatorDirective implements Validator {
    @Input()
    length!: string;


    validate ( control: AbstractControl ): { [ key: string ]: any } {

        return validateEmail()( control ) || {};
    }
}
