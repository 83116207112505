<div [formGroup]="formGroup">
    <label class="center-block">{{label}}</label>
    <input [typeahead]="options" [formControlName]="statusName" [typeaheadMinLength]="0" typeaheadOptionField="name"
        placeholder="Select {{label}}" [typeaheadItemTemplate]="customItemTemplate"
        (typeaheadOnSelect)="onSelect($event)" (typeaheadNoResults)="onNoResults($event)" class="form-control"
        autocomplete="off" [required]="required">
    <small class="text-danger" *ngIf="required && formGroup.get(statusId)?.errors">
        Required
    </small>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>