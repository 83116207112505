import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationStatusListComponent } from './verification-status-list/verification-status-list.component';
import { SharedModule } from '../core/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { VerificationStatusEditComponent } from './verification-status-edit/verification-status-edit.component';



@NgModule( {
    declarations: [
        VerificationStatusListComponent,
        VerificationStatusEditComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        RouterModule,
    ],
} )

export class VerificationStatusModule { }
