import { HttpClient } from '@angular/common/http';
import {
    Component,
    ElementRef,
    OnChanges,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';

import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { ReferenceVerification } from '../../core/models/reference-verification.model';
import { ReferenceService } from '../../core/services/reference.service';
import { Employee } from 'src/app/core/models/employee.model';
import Status from 'src/app/core/models/status.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-reference-edit',
    templateUrl: './reference-edit.component.html',
    styleUrls: [ './reference-edit.component.css' ],
} )
export class ReferenceEditComponent extends VerificationEditComponent implements OnInit, OnChanges {

    verifications: ReferenceVerification[] = [];
    vendors!: any[];
    statusChanged: boolean = false;
    override attachments: any[] = [];
    languages!: any[];
    ratings = [ 1, 2, 3, 4, 5 ];
    override verification = 'reference';
    statusType!: string;
    //1:Awaiting Details,2:Switch Off/Unreachable,4:Reference not Responding,29:Language Problem
    validStatusesForWorkCompleted: number[] = [ 1, 2, 4, 29 ];

    constructor (
        protected override verificationService: ReferenceService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override common: CommonService,
        public global: GlobalService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected override confirmationModalService: ConfirmationModalService
    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );

        // Initialise the identity verification form
        // with the required form controls
        this.initForm();

    }

    initForm (): void {
        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            name: [ null ],
            client_employee_id: [ null ],
            mobile_number: [ null ],
            father_name: [ null ],
            dob: [ null ],
            remarks: [ null ],
            id: [ null ],
            formArray: this.fb.array( [] ),
            work_completed: [ null ],
        } );
    }

    override ngOnInit (): void {

        this.verification = 'reference';
        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                employee => {
                    this.employee = employee;
                    this.populateForm( employee );
                    this.getAttachments( employee );
                    this.isSubmitting = false;
                    this.ngOnChanges();
                }
            );
        } );

        // Subscribe to changes in the form.
        this.subscribeToQueryChanges();

        // Vendor list is similar to that of address.
        this.common.getVendors( 'address' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
    }

    populateForm ( employee: Employee ): void {

        this.verificationFormGroup.patchValue( employee );

        const verifications = employee.reference_verifications as ReferenceVerification[];

        const verificationFormGroups = verifications.map( verification => this.fb.group( verification ) );

        const verificationGroupArray = this.fb.array( verificationFormGroups );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );

    }

    ngOnChanges (): void {

        this.formArray.controls.forEach( ( form: any ) => {

            this.disableFields( form );

            // this.applyDefaultValidations( form );

            // Subscribe to any Change in Verification Status
            form.get( 'status_id' )?.valueChanges.subscribe(
                ( statusId: any ) => {
                    this.statusChanged = true;
                    // this.applyValidationsForStatusChange( statusId, form );

                    if ( this.validStatusesForWorkCompleted.indexOf( statusId ) != -1 ) {

                        form.get( 'work_completed' )?.setValidators( [ Validators.required ] );
                    } else {
                        form.get( 'work_completed' )?.setValidators( [] );
                    }
                }
            );

            form.get( 'type' )?.valueChanges.subscribe(
                ( statusId: any ) => {
                    this.statusChanged = true;
                    // this.applyValidationsForStatusChange( form.get( 'status_id' )?.value, form );
                }
            );

        } );
    }


    get statuses (): Status[] | any {
        return this.global.fetchStatuses()[ 'reference' ];
    }

    onStatusChanged ( $statusId: number ) {

        const status = this.statuses.find( ( status: any ) => status.id == $statusId );
        this.statusType = status.type;
        // console.log( this.statusType );

    }


    createReference () {
        this.verificationService.create( this.employee.id ).subscribe(
            response => {
                this.populateForm( response );
            }
        );

        this.common.notifications.success( ' Verification added' );
    }

    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.cdr.detectChanges();
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                () => {
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }

    isDeleted ( formGroup: any ): boolean {

        return formGroup.get( 'is_deleted' ) ? formGroup.get( 'is_deleted' )?.value : false;

    }

    onUploaded ( $event: any ) {

        this.upload( $event.verification, $event.url );

    }

}
