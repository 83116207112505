<div bsModal #insuranceApiResponse="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Api Response</h4>

            </div>
            <div class="modal-body">
                <div *ngIf="apiResponse">
                    <p>Status:{{apiResponse.status}}</p>
                    <p>TimeStamp:{{apiResponse.timeStamp}}</p>
                    <p>Error Code: {{apiResponse.erorCode}}</p>
                    <p>Description: {{apiResponse.description}}</p>
                    <p>Rc Number: {{apiResponse.rc_number}}</p>
                    <div *ngIf="apiResponse.data">
                        <ul class="list-unstyled" *ngFor="let data of apiResponse.data">

                            <li>
                                {{data.owners_name}}
                            </li>
                            <li>
                                {{data.insurance_company}}
                            </li>
                            <li>
                                {{data.insurance_policy_number}}
                            </li>
                            <li>
                                {{data.insurance_upto}}
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>