import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { PanService } from '../core/services/pan.service';
import { SharedModule } from '../core/shared.module';
import { PanExcelImportComponent } from './pan-excel-import/pan-excel-import.component';
import { PanFiltersComponent } from './pan-filters/pan-filters.component';
import { PanListComponent } from './pan-list/pan-list.component';
import { PanSearchModalComponent } from './pan-search-modal/pan-search-modal.component';
import { PanStatsComponent } from './pan-stats/pan-stats.component';
import { PanResponseModalComponent } from './pan-response-modal/pan-response-modal.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        PanListComponent,
        PanFiltersComponent,
        PanExcelImportComponent,
        PanSearchModalComponent,
        PanStatsComponent,
        PanResponseModalComponent,
    ],
    providers: [ PanService ],
} )
export class PanModule {
}
