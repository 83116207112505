import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VehicleService } from '../../core/services/vehicle.service';
import { CommonService } from '../../core/services/common.service';
import { VehiclercOwner } from '../../core/models/vehiclerc-owner.model';

@Component( {
    selector: 'app-vehicle-details',
    templateUrl: './vehicle-details.component.html',
    styleUrls: [ './vehicle-details.component.css' ],
} )
export class VehicleDetailsComponent {

    vehiclerc!: VehiclercOwner[];
    id!: string;
    rc_number!: string;

    @ViewChild( 'vehicleDetails', { static: false } )
    public vehicleDetails!: ModalDirective;

    constructor (
        private vehicleService: VehicleService,
        private commonService: CommonService,
    ) { }

    show ( id: string, rcNumber: string ) {

        this.id = id;
        this.rc_number = rcNumber;

        this.vehicleService.getDetails( id ).subscribe(
            response => {
                this.vehiclerc = response;
                this.vehicleDetails.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );

    }

    hide () {
        this.vehicleDetails.hide();
    }
}
