<div class="app-header navbar" style="">
    <!-- navbar header -->

    <div class="navbar-header bg-black">
        <button class="pull-right visible-xs dk" ui-toggle-class="show" data-target=".navbar-collapse">
            <i class="glyphicon glyphicon-cog"></i>
        </button>
        <button class="pull-right visible-xs" ui-toggle-class="off-screen" data-target=".app-aside" ui-scroll-to="app">
            <i class="glyphicon glyphicon-align-justify"></i>
        </button>
        <!-- brand -->
        <a href="#/" class="navbar-brand text-lt">
            <!--<img src="img/logo.png" alt="." class="hide">-->
            <span class="hidden-folded m-l-xs ng-binding">Instaveritas</span>
        </a>
        <!-- / brand -->
    </div>
    <!-- / navbar header -->

    <!-- navbar collapse -->
    <div class="collapse pos-rlt navbar-collapse box-shadow bg-white-only">


        <!-- link and dropdown -->
        <ul class="nav navbar-nav hidden-sm" *ngIf="user">

            <li class="dropdown" dropdown>
                <a class="dropdown-toggle" dropdownToggle>
                    <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
                    <span>Verifications</span>
                    <span class="caret"></span>
                </a>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li><a role="menuitem" routerLink="identity">Identity Verification</a></li>
                    <li><a role="menuitem" routerLink="address">Address Verification</a></li>
                    <li><a role="menuitem" routerLink="icr">Icr Verification</a></li>
                    <li><a role="menuitem" routerLink="education">Education Verification</a></li>
                    <li><a role="menuitem" routerLink="employment">Employment Verification</a></li>
                    <li><a role="menuitem" routerLink="reference">Reference Verification</a></li>
                    <li><a role="menuitem" routerLink="pcc">Pcc Verification</a></li>
                    <li><a role="menuitem" routerLink="document">Document Verification</a></li>
                    <li><a role="menuitem" routerLink="global-db">Global Database Verification</a></li>
                </ul>
            </li>

            <li class="dropdown" dropdown [appCan]="'admin'">
                <a class="dropdown-toggle" dropdownToggle>
                    <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
                    <span>Assigned Work List</span>
                    <span class="caret"></span>
                </a>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li><a role="menuitem" routerLink="identity/assigned-work">Identity</a></li>
                    <li><a role="menuitem" routerLink="address/assigned-work">Address</a></li>
                    <li><a role="menuitem" routerLink="icr/assigned-work">Icr</a></li>
                    <li><a role="menuitem" routerLink="education/assigned-work">Education</a></li>
                    <li><a role="menuitem" routerLink="employment/assigned-work">Employment</a></li>
                    <li><a role="menuitem" routerLink="reference/assigned-work">Reference</a></li>
                    <li><a role="menuitem" routerLink="pcc/assigned-work">Pcc</a></li>
                    <li><a role="menuitem" routerLink="document/assigned-work">Document</a></li>
                    <li><a role="menuitem" routerLink="global-db/assigned-work">Global Database</a></li>
                </ul>
            </li>

            <li><app-search-form></app-search-form></li>
        </ul>

        <!-- / link and dropdown -->

        <ul class="nav navbar-nav navbar-right" *ngIf="user">

            <li class="dropdown" dropdown [appCan]="'superadmin'">
                <a class="dropdown-toggle" dropdownToggle>
                    <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
                    <span>API</span>
                    <span class="caret"></span>
                </a>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li><a routerLink="/vehiclerc" class="auto">VehicleRc Logs</a></li>
                    <li><a routerLink="/sms" class="auto">Sms Logs</a></li>
                    <li><a routerLink="/licence" class="auto">Licence Logs</a></li>
                    <li><a routerLink="/aadhaar" class="auto">Aadhaar Logs</a></li>
                    <li><a routerLink="/webhooks" class="auto">Webhook Logs</a></li>
                    <li><a routerLink="/incoming-webhooks" class="auto">Incoming Webhook Logs</a></li>
                    <li><a routerLink="/pan" class="auto">Pan Logs</a></li>
                    <li><a routerLink="/voter" class="auto">Voter Logs</a></li>
                    <li><a routerLink="/gst" class="auto">Gst Logs</a></li>
                    <li><a routerLink="/permit" class="auto">Permit Logs</a></li>
                    <li><a routerLink="/blacklisted" class="auto">Blacklisted Logs</a></li>
                    <li><a routerLink="/insurance" class="auto">Insurance Logs</a></li>
                    <li><a routerLink="/api-request-logs" class="auto">Api Request Logs</a></li>
                    <li><a routerLink="/ocr" class="auto">Ocr Logs</a></li>
                    <li><a routerLink="/face-recognition" class="auto">Face Recognition Logs</a></li>
                    <li><a routerLink="/epfo" class="auto">Epfo Logs</a></li>
                    <li><a routerLink="/global-db-log" class="auto">GlobalDB Logs</a></li>
                    <li><a routerLink="/credit-report" class="auto">Credit Report Logs</a></li>
                </ul>
            </li>

            <li class="dropdown" dropdown [appCan]="'superadmin'">
                <a class="dropdown-toggle" dropdownToggle>
                    <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
                    <span>Add-ons</span>
                    <span class="caret"></span>
                </a>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li><a routerLink="/remarks" class="auto">Remark Logs</a></li>
                    <li><a routerLink="/work/bulk-assign" class="auto">Work Bulk Assign</a></li>
                    <li><a routerLink="/history" class="auto">History Log</a></li>
                    <li><a routerLink="/employment/analysis" class="auto">Employment Analysis</a></li>
                    <li><a routerLink="/users" class="auto">Users List</a></li>
                    <li><a routerLink="/permissions/list" class="auto">Permissions List</a></li>
                    <li><a routerLink="/pending-jobs" class="auto">Pending Jobs</a></li>
                    <li><a routerLink="/statuses/create" class="auto">Add Status</a></li>
                    <li><a routerLink="/vendors/create" class="auto">Add Vendor</a></li>
                    <li><a routerLink="/districts/create" class="auto">Add District</a></li>
                    <li><a routerLink="/client-remarks/create" class="auto">Add Client Remark(Testing)</a></li>
                    <li><a routerLink="/work/work-type" class="auto">Add Work Type</a></li>
                    <li><a routerLink="/company-roles/list">Add Company Role</a></li>
                    <li><a routerLink="/statuses" class="auto">Verification Statuses</a></li>
                    <li><a routerLink="/client-crm" class="auto">Client CRMs </a></li>
                </ul>
            </li>

            <li class="dropdown" dropdown [appCan]="'superadmin'">
                <a class="dropdown-toggle" dropdownToggle>
                    <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
                    <span>Client</span>
                    <span class="caret"></span>
                </a>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li><a routerLink="/clients" class="auto">All Clients</a></li>
                    <li><a routerLink="/forms" class="auto">Client Forms</a></li>
                    <li><a routerLink="/clients/api" class="auto">Clients Api</a></li>
                    <li><a routerLink="/form-invites" class="auto">Form Invites</a></li>
                    <li><a routerLink="/digital-address-invites" class="auto">Digital Address Invites</a></li>
                </ul>
            </li>


            <li class="dropdown" dropdown>
                <a class="dropdown-toggle" dropdownToggle>
                    <i class="fa fa-fw fa-plus visible-xs-inline-block"></i>
                    <span>{{user.name}}</span>
                    <span class="caret"></span>
                </a>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li><a routerLink="/users/history/{{user.id}}" class="auto">My Work
                            History</a></li>
                    <li><a routerLink="users/downloads" class="auto">My Downloads</a></li>
                    <li><a [routerLink]="['/users/my-work']" [queryParams]="{ user_id: user.id }" class="auto">My
                            Assigned Work</a></li>
                    <li><a [routerLink]="['users/change-password']" [queryParams]="{ user_id: user.id }" class="auto">
                            Change Password</a></li>
                    <li><a class="auto" (click)="logout()">Logout</a></li>

                </ul>
            </li>
        </ul>

    </div>
    <!-- / navbar collapse -->
</div>
