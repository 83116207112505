import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { Pagination } from '../../core/models/pagination.model';
import { RemarkService } from '../../core/services/remark.service';

@Component( {
    selector: 'app-remarks-list',
    templateUrl: './remarks-list.component.html',
    styleUrls: [ './remarks-list.component.css' ],
} )
export class RemarksListComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;

    constructor ( private remarkService: RemarkService,
        private route: ActivatedRoute,
        private router: Router,

    ) {
    }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    // Filter results
    filterResults (): void {

        this.remarkService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );

    }

    showPage ( page: number ) {
        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

}
