import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { CommonService } from '../../core/services/common.service';
import { CriminalService } from 'src/app/core/services/criminal.service';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-criminal-excel-import',
    templateUrl: './criminal-excel-import.component.html',
    styleUrls: [ './criminal-excel-import.component.css' ],
} )
export class CriminalExcelImportComponent extends ImportExcelComponent implements OnInit {

    uploadForm!: FormGroup;
    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    validationMessages: ValidationMessages = {};

    constructor (
        private criminalService: CriminalService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
    ) {
        super( fb );
        // initialize the upload excel form.


    }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {

        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );
    }


    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void {
        this.validationMessages[ 'name' ] = 'Required';
    }

    applyValidations (): void {
        this.excelFormArray.controls.forEach( form => {
            form.get( 'name' )?.setValidators( [ Validators.required, validateAllowedCharacters( '-' ) ] );
            form.get( 'name' )?.updateValueAndValidity();
            this.setValidationMessages();
        } );
    }

    importExcel (): void {

        this.criminalService.importExcel( this.excelForm.value ).subscribe(
            response => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.notifications.error( 'Error', 'Something went wrong' );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }

}
