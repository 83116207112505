<div class="wrapper-md">
    <div class="row">


        <div class="col-sm-3" *ngIf="vahanStats">
            <div class="panel panel-default">
                <div class="panel-heading">Vahan Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Vahan Details Found
                        <span class="pull-right text-bold"> {{ vahanStats['vahan_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Details Not Found
                        <span class="pull-right text-bold"> {{ vahanStats['vahan_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Failure
                        <span class="pull-right text-bold">{{ vahanStats['vahan_failure'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Invalid Captcha
                        <span class="pull-right text-bold">{{ vahanStats['vahan_invalid_captcha'] }}</span>
                    </p>

                    <p class="mar-no">
                        Vahan Not Initiated
                        <span class="pull-right text-bold">{{ vahanStats['vahan_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>


    </div>
</div>