import { WorkService } from 'src/app/core/services/work.service';
import { AuthService } from './../../core/services/auth.service';
import { CommonService } from './../../core/services/common.service';
import { Component, Input, OnInit } from '@angular/core';

export interface WorkAssigned {
    id: string;
    assigned_to: string;
    assigned_to_user_id: number;
    completed_at: string;
    title: string;
}

@Component( {
    selector: 'app-task-completed',
    templateUrl: './task-completed.component.html',
    styleUrls: [],
} )
export class TaskCompletedComponent implements OnInit {

    @Input() verification!: string;
    @Input() workAssigned!: WorkAssigned[];

    public currentWork!: WorkAssigned;


    private allowedTypes: any = {
        address: [ 'QA Calling' ],
    };

    constructor (
        private commonService: CommonService,
        private authService: AuthService,
        private workService: WorkService,
    ) { }

    ngOnInit () {
        this.workAssigned = this.workAssigned.filter( work => {
            if (
                this.allowedTypes[ this.verification ].includes( work.title )
                && !work.completed_at
                && this.authService.user.id == work.assigned_to_user_id
            ) {
                return true;
            }

            return false;
        } );

        if ( this.workAssigned.length > 0 ) {
            this.currentWork = this.workAssigned[ 0 ];
        }
    }

    markCompleted (): void {

        this.workService.markWorkAsCompleted( { work_assigned_id: this.currentWork.id, work_assigned_type: this.currentWork.title } ).subscribe(
            response => {
                this.commonService.notifications.success( 'Work Completed!' );
                this.currentWork.completed_at = response.completed_at;
            },
            error => {
                this.commonService.notifications.error( 'Error', 'Some Error Occured' );
            }
        );
    }

}
