import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AddressType } from '../client-forms-v2.enum';

@Component( {
    selector: 'app-pcc-form',
    templateUrl: './pcc-form.component.html',
} )
export class PccFormComponent implements OnInit {

    @Input() settingFormGroup: FormGroup = this.fb.group( {} );

    isSectionChecked: boolean = false;

    readonly MAX_VERIFICATION: number = 1;

    pccForm = this.fb.group( {

        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] )

    } )

    get showSection (): boolean {
        return this.isSectionChecked || this.pccVerifications.length > 0;
    }

    get pccVerifications (): FormArray | any {
        return this.pccForm.get( 'verifications' ) as FormArray;
    }

    get pccFormGroup (): FormGroup {

        return this.fb.group( {

            address_type: this.fb.control( AddressType.Current ),
            address_required: this.fb.control( true ),
            documents: this.fb.array( [] ),
            required: this.fb.control( true )

        } )
    }

    constructor ( private fb: FormBuilder ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'pcc', this.pccForm );
    }

    initForm (): void {

        if ( this.pccVerifications.length == 0 ) {
            this.pccVerifications.push( this.pccFormGroup );
        }
    }

    sectionToggle ( $event: any ): void {

        this.isSectionChecked = $event.target.checked;

        if ( this.isSectionChecked ) {

            this.initForm();

        } else {

            this.pccVerifications.clear();

        }
    }
}
