<div bsModal #submitToAppModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="submitToAppForm" (ngSubmit)="search()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Please enter your Address App Credentials</h4>
                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset class="form-group">
                                    <label class="center-block">Email
                                        <input required type="email" formControlName="email"
                                            class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                                <fieldset class="form-group">
                                    <label class="center-block">Password
                                        <input required type="password" formControlName="password"
                                            class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!submitToAppForm.valid">
                            Submit
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>