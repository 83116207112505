import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { InsuranceService } from 'src/app/core/services/insurance.service';

@Component( {
    selector: 'app-insurance-details',
    templateUrl: './insurance-details.component.html',
    styleUrls: [ './insurance-details.component.css' ],
} )
export class InsuranceDetailsComponent {

    vehiclerc: any;
    rc_number!: string;

    @ViewChild( 'insuranceDetails', { static: false } )
    public vehicleDetails!: ModalDirective;

    constructor (
        private commonService: CommonService,
        private insuranceService: InsuranceService,
    ) { }

    show ( rcNumber: string ): void {

        this.rc_number = rcNumber;

        this.insuranceService.getDetails( rcNumber ).subscribe(
            response => {
                this.vehiclerc = response;
                this.vehicleDetails.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );

    }

    hide (): void {
        this.vehicleDetails.hide();
    }

}
