import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { CreditReportSources } from 'src/app/core/enums/credit-report-sources.enum';
import { Client } from 'src/app/core/models/client.model';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { CommonService } from 'src/app/core/services/common.service';
import { CreditReportService } from 'src/app/core/services/credit-report.service';
import { FilterService } from 'src/app/core/services/filter.service';

@Component( {
    selector: 'app-credit-report-filters',
    templateUrl: './credit-report-filters.component.html',
    styleUrls: [ './credit-report-filters.component.css' ]
} )
export class CreditReportFiltersComponent extends ExportExcelComponent implements OnInit {

    @Input() filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients!: Client | any;
    creditReportSources!: { key: string, value: string }[];

    constructor (
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService,
        private creditReportService: CreditReportService,
        private filterService: FilterService
    ) {
        super();
        this.initForm();
    }

    ngOnInit () {

        this.creditReportSources = Object.entries( CreditReportSources ).map( ( [ key, value ] ) => ( { key, value } ) );

        this.setDefault();

        this.updateFormFilter();

        this.getClientApis();

        this.filterResults();
    }

    getClientApis (): void {
        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe( {
            next: ( response ) => {
                this.clients = response;

            },
            error: () => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            },
        } );
    }

    private setDefault (): void {

        this.filterParams.keys().forEach( key => {

            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            name: 'all',
            mobile_number: 'all',
            pan_number: 'all',
            created_before: 'all',
            created_after: 'all',
            page: 1,
            client: 'all',
            via: 'all',
            status: 'all',
            driver: 'all',
        } );
    }

    updateFormFilter (): void {
        this.filterService.updateFormFilters( this.filterFormGroup );
    }

    filterResults (): void {
        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();

        this.filterService.filter();
    }

    generateExcel (): void {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'credit-report/export-excel',
            fileName: 'Credit Report Logs ',
            method: 'get',
            postParams: null,
        };

        this.creditReportService.showDownloadModal( initialState );
    }

    resendAll (): void {
        this.creditReportService.resendAll( this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( ' Credit reports resent' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }


    onSearch (): void {
        this.filterService.filter();
    }

}
