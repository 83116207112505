<div class="wrapper-md">
    <app-uidai-new-request #uidaiSearch (onSearch)="onSearch()"></app-uidai-new-request>

    <app-aadhaar-new-request #aadhaarSearch (onSearch)="onSearch()"></app-aadhaar-new-request>

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block">Aadhaar Number</label>
                        <input class="form-control" formControlName="aadhaar_number" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Status</label>

                        <select class="form-control" formControlName="uidai_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="3">Discrepancy Found</option>
                            <option value="2">Invalid Captcha</option>
                            <option value="4">Failure</option>
                        </select>

                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Via</label>
                        <select class="form-control" formControlName="via">
                            <option value="all">All</option>
                            <option value="api">API</option>
                            <option value="crm">CRM</option>
                            <option value="dexter">Dexter</option>
                            <option value="excel">Excel</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Filter by Client</label>
                        <select class="form-control" formControlName="client_api_id">
                            <option value="all">All</option>
                            <option *ngFor="let client_api of clients" [value]="client_api.id">
                                {{ client_api.owner_name }}
                            </option>
                        </select>
                    </div>


                    <div class="col-md-2 form-group">
                        <label class="center-block">Created After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>


                    <div class="col-md-2 form-group">
                        <label class="center-block">Created Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>

                    <div class="form-group col-md-2" *ngIf="currentUrl=='/uidai'">
                        <label class="center-block">Click here to</label>
                        <button (click)="uidaiSearch.show()" class="btn btn-primary btn-block btn-addon">
                            <i class="fa fa-search"></i> Search
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button routerLink="/uidai/import" class="btn btn-primary btn-block btn-addon">
                            <i class="fa fa-upload"></i>
                            Import
                        </button>
                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="exportLogsExcel()" class="btn btn-primary btn-block btn-addon">
                            <i class="fa fa-download"></i>
                            Download Logs
                        </button>
                    </div>

                    <div class="form-group col-md-2" *ngIf="currentUrl=='/uidai'">
                        <label class="center-block">Click here to</label>
                        <button (click)="resendAll()" class="btn btn-primary btn-block btn-addon">
                            <i class="fa fa-send"></i>
                            Resend All
                        </button>
                    </div>

                </div>

            </form>
        </div>
    </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>