import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Employee } from 'src/app/core/models/employee.model';
import { CommonService } from 'src/app/core/services/common.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DocumentsUploadService } from 'src/app/core/services/documents-upload.service';
import { EmploymentService } from 'src/app/core/services/employment.service';
import { isUndefined } from 'src/app/core/unit';


@Component( {
    selector: 'app-employee-consent-form',
    templateUrl: './employee-consent-form.component.html',
    styleUrls: [ './employee-consent-form.component.css' ],
} )
export class EmployeeConsentFormComponent implements OnInit {

    employeeId!: number;
    employee: Employee = new Employee();
    consent: boolean = false;
    documents!: any;

    @Input() employeeDocuments!: Document[] | any;

    @Output()
    onConsentUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    filterParams: URLSearchParams = new URLSearchParams();
    isSubmitting: boolean = false;

    constructor (
        protected route: ActivatedRoute,
        protected router: Router,
        protected common: CommonService,
        private dialogService: DialogService,
        private documentService: DocumentsUploadService,
        private notificationService: ToastrService,
        private employmentService: EmploymentService,
    ) {
    }

    ngOnInit (): void {

        // When page loads for the first time
        this.route.params.subscribe( params => {
            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];
        } );

        if ( !isUndefined( this.employeeDocuments ) ) {
            this.documents = this.employeeDocuments;
        }

    }

    getEmployeeDetails ( employeeId: number ): void {

        this.employmentService.findByEmployeeId( employeeId ).subscribe(

            employee => {

                if ( !isUndefined( this.employeeDocuments ) ) {

                    this.documents = employee.documents;

                }

            },

        );
    }

    onFileUploaded ( $e: any, employeeId: number ): void {

        this.getEmployeeDetails( employeeId );

        this.onConsentUpdated.emit( true );
    }

    get consentForms (): Document[] | any {

        return this.documents.filter( ( item: any ) => item.name === 'consent_form' );

    }

    deleteEmploymentDocument ( document: any, employeeId: number ): void {

        const title: string = `Are you sure you want to delete ${ document.filename }?`;

        this.dialogService.confirm( 'Are you sure?', title ).then( () => {

            this.documentService.delete( document ).subscribe( () => {

                this.notificationService.success( 'File deleted' );

                this.getEmployeeDetails( employeeId );

                this.onConsentUpdated.emit( true );

            } );

        }, () => {

            // do nothing in case of no

        } );

    }


}
