import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FormV2 } from 'src/app/core/models/client-forms.model';
import { ClientFormsService } from 'src/app/core/services/client-forms.service';
import { HistoryService } from 'src/app/core/services/history.service';


@Component( {
    selector: 'app-client-forms-history',
    templateUrl: './client-forms-history.component.html',
    styleUrls: [ './client-forms-history.component.css' ],
} )
export class ClientFormsHistoryComponent implements OnInit {

    formHistory: FormV2 | any = new FormV2();
    form: FormV2 = new FormV2();
    id!: number;
    path!: string;
    flag: boolean = false;


    constructor (
        private activatedRoute: ActivatedRoute,
        private historyService: HistoryService,
        private clientFormsService: ClientFormsService
    ) {
        this.activatedRoute.params.subscribe( ( params: any ) => this.id = params.id );
    }

    ngOnInit (): void {

        this.activatedRoute.paramMap.pipe( switchMap( ( params: ParamMap ) => this.clientFormsService.find( +params.get( 'id' )! ) ) )
            .subscribe(
                form => {
                    this.form = form;
                }
            );

        this.flag = false;
        this.getFormHistory();
    }

    getFormHistory (): void {
        this.path = 'forms/' + this.id + '/history';
        this.historyService.getHistory( this.path ).subscribe( ( response ) => {
            this.formHistory = response;
            this.flag = true;
        } );
    }
}
