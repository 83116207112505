import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { FilterService } from 'src/app/core/services/filter.service';
import { OcrService } from 'src/app/core/services/ocr.service';
import { OcrSignzyStats } from 'src/app/core/interfaces/ocr-logs/singzy-stats';
import { OcrTotalKYCStats } from 'src/app/core/interfaces/ocr-logs/total-kyc-stats';


@Component( {
    selector: 'app-ocr-stats',
    templateUrl: './ocr-stats.component.html',
} )
export class OcrStatsComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();

    signzyStats!: OcrSignzyStats;

    totalkycStats!: OcrTotalKYCStats;

    constructor ( private route: ActivatedRoute, private ocrService: OcrService, private filterService: FilterService ) { }

    ngOnInit () {
        this.route.queryParams.subscribe( ( params ) => {
            this.filterParams = this.filterService.httpParams;
            this.filterStats();
        } );
    }

    filterStats (): void {
        this.ocrService.getStats( this.filterParams ).subscribe( ( response ) => {
            this.signzyStats = response[ 'signzy' ];
            this.totalkycStats = response[ 'totalkyc' ];
        } );
    }
}
