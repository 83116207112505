import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CommonService } from '../../core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-verification-status',
    templateUrl: './verification-status.component.html',
    styleUrls: [ './verification-status.component.css' ],
} )
export class VerificationStatusComponent implements OnInit {

    // Id of the verification
    // will be used for upadting status & other actions
    @Input()
    verificationId!: number;

    // Which verification is in use here
    @Input() verification!: string;

    // We need the status_id of the verification
    // to show it as the default selected in dropdown
    @Input() statusId!: number;

    // We need the status_id of the verification
    // to show it as the default selected in dropdown
    @Input() verificationDate!: string;

    @Input() className!: string;
    // Variable to enable/Disable Drop Down
    disabled!: boolean;

    @Output() onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();


    constructor (
        public global: GlobalService,
        public common: CommonService,
    ) { }

    get statuses () { return this.global.fetchStatuses(); }
    ngOnInit (): void {

        if ( this.verificationDate != null && this.common.userLevelId() < 4 ) {
            this.disabled = true;
        } else {
            this.disabled = false;
        }
    }

    onStatusSelected ( selectedStatusId: number ): void {

        this.common.changeVerificationStatus( this.verification, [ this.verificationId ], selectedStatusId ).subscribe(
            ( response: any ) => {
                this.onVerificationUpdated.emit( true );
                this.common.notifications.success( 'Status Changed', response[ 'message' ] );
            }
        );
    }
}
