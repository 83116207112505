<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)="$event.preventDefault()">
                <div class="row">

                    <div *ngIf="companies" class="form-group col-md-2">
                        <label class="center-block">Company</label>
                        <app-multi-select [options]="companies" [placeholder]="'Select Company'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lot'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block"> Select CRM Client</label>
                        <select class="form-control" formControlName="type">
                            <option *ngFor="let clientCrm of ClientCrms | keyvalue" [value]="clientCrm.value">
                                {{clientCrm.key}}
                            </option>
                        </select>
                    </div>

                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>