export class VcourtJob {
    id!: number;
    father_name!: string;
    track_id!: string;
    name!: string;
    address!: string;
    district!: string;
    pincode!: string;
    cases_count!: number;
    status!: string;
    via!: string;
    created_at!: string;
    updated_at!: string;
    lot_name!: string;
    lot_id!: number;
    dob!: string;
    job_id!: string;
    employee_id!: number;
    icr_status!: string;
    company_name!: string;
    company_id!: number;
    type!: string;
    duplicate_case_id!: string;
    work_assigned!: any[];
}
