import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, from } from 'rxjs';

import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { IWorkTypeComponent } from '../../core/interfaces/work-type-interface'
import { IdentityValidationData } from 'src/app/core/interfaces/identity-validation-data';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { IdentityVerificationStatusType } from '../../core/enums/identity-verification-status-type.enum';

@Component( {
    selector: 'app-identity-work-validation',
    templateUrl: './identity-work-validation.component.html',
    styleUrls: [ './identity-work-validation.component.css' ],
} )
export class IdentityWorkValidationComponent implements OnInit, OnChanges, IWorkTypeComponent {

    statuses: any[] = [
        {
            id: IdentityVerificationStatusType.Valid,
            name: 'Valid'
        },
        {
            id: IdentityVerificationStatusType.DiscrepancyFound,
            name: 'Discrepancy Found'
        },
        {
            id: IdentityVerificationStatusType.UnableToValidate,
            name: 'Unable To Validate'
        }
    ];

    identityVerification!: IdentityValidationData;

    filterParams: HttpParams = new HttpParams();

    validationForm!: FormGroup;

    data: any;

    showHide!: boolean;
    verificationId: number | any;

    croppedFrontUrl: string | null = null;
    croppedBackUrl: string | null = null;

    formSubmitting!: boolean;

    filtered: EventEmitter<string> = new EventEmitter();

    filtersSubscription!: Subscription;

    validStatusesForClientRemarks: number[] = [ 2, 3, 5, 9, 11, 12, 14, 17, 18, 19, 21, 23, 24 ];


    constructor (
        private identityService: IdentityService,
        private route: ActivatedRoute,
        private fb: FormBuilder,

        private router: Router,
        private filterService: FilterService,
        private commonService: CommonService
    ) { }

    ngOnInit (): void {


        this.route.queryParams.subscribe( params => {


            this.formSubmitting = false;
            this.initForm();
            this.verificationId = this.route.snapshot.params[ 'id' ];

            this.subscribeToQueryParams( params );

            this.identityService.getValidationWork( this.filterParams, this.verificationId ).subscribe( ( response: any ) => {
                this.identityVerification = response;
                this.validationForm.patchValue( response );
            } );

        } );


        this.ngOnChanges();

    }

    subscribeToQueryParams ( params: any ): void {

        this.filterParams = new HttpParams();

        Object.keys( params ).forEach( key => {
            if ( key !== 'params' && ( params[ key ] !== '' && params[ key ] !== 'all' ) ) {
                this.filterParams = this.filterParams.set( key, params[ key ] );
            }
        } );

    }

    ngOnChanges (): void {

        this.validationForm.get( 'verification_status' )?.valueChanges.subscribe( ( statusId ) => {

            if ( this.validStatusesForClientRemarks.includes( parseInt( statusId ) ) ) {

                this.validationForm.get( 'client_remarks' )?.setValidators( [ Validators.required ] );

            } else {
                this.validationForm.get( 'client_remarks' )?.setValidators( [] );
            }

            this.validationForm.get( 'client_remarks' )?.updateValueAndValidity();

        } );
    }


    initForm (): void {

        this.validationForm = this.fb.group( {
            verification_status: [ '', Validators.required ],
            client_remarks: [ '' ],
        } );

    }

    onDoneClicked (): void {
        this.formSubmitting = true;

        const data: any = {
            data: this.validationForm.value,
        };

        this.identityService.postValidationWork( this.filterParams, this.verificationId, data ).subscribe( ( response: any ) => {


            const params: HttpParams = this.filterParams;
            // Set our navigation extras object
            // that contains our global query params and fragment

            const navigationExtras: NavigationExtras = {
                queryParams: {
                    params,
                },
                queryParamsHandling: 'merge',
            };

            this.router.navigate( [ 'work/identity/validation', response.id ], navigationExtras );


        }, ( error: any ) => {

            this.formSubmitting = false;

            if ( error.status == 404 ) {

                this.commonService.notifications.warning( 'No Case Found' );

                const navigationExtras: NavigationExtras = {
                    queryParams: this.filterParams,
                    queryParamsHandling: 'merge',
                };

                this.router.navigate( [ 'work/identity' ], navigationExtras );

            } else if ( error.error.code == 422 ) {
                this.commonService.notifications.error( error.error );
            } else {

                this.commonService.notifications.error( error.error );

                const navigationExtras: NavigationExtras = {
                    queryParams: this.filterParams,
                    queryParamsHandling: 'merge',
                };

                this.router.navigate( [ 'work/identity' ], navigationExtras );
            }
        } );
    }

    onExitClicked (): void {

        this.formSubmitting = true;

        this.identityService.cancelValidationWork( this.filterParams, this.verificationId ).subscribe( () => {

            alert( 'Work Cancelled' );

            const navigationExtras: NavigationExtras = {
                queryParams: this.filterParams,
                queryParamsHandling: 'merge',
            };

            this.router.navigate( [ 'work/identity' ], navigationExtras );

        } );


    }


}
