import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { VcourtService } from '../../core/services/vcourt.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Errors } from '../../core/models/errors.module';
import { CommonService } from '../../core/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component( {
    selector: 'app-vcourt-job-create',
    templateUrl: './vcourt-job-create.component.html',
    styleUrls: [ './vcourt-job-create.component.css' ],
    providers: [ VcourtService ],
    inputs: [ 'jobs_list' ],
} )
export class VcourtJobCreateComponent {

    errorMessage = 'Required';
    createFormGroup!: FormGroup;
    isSubmitting = false;
    errors: Errors = new Errors();

    @ViewChild( 'submitNewVcourtRequest', { static: false } )
    public submitNewVcourtRequest!: ModalDirective;

    @Output()
    onSubmit = new EventEmitter<boolean>();

    constructor (
        private vcourtService: VcourtService,
        private fb: FormBuilder,
        private commonService: CommonService
    ) {
        this.initForm();
        this.applyDefaultValidations();

    }

    initForm () {
        this.createFormGroup = this.fb.group( {
            track_id: [ '', [ Validators.required, Validators.pattern( '^[0-9A-Za-z]+$' ) ] ],
            name: [ '', [ Validators.required, Validators.pattern( '^[A-Za-z\\s.]*$' ) ] ],
            address: [ null, Validators.required ],
            landmark: [ null ],
            district: [ null, Validators.required ],
            state: [ null, Validators.required ],
            pincode: [ '', Validators.pattern( '(^[0-9]{6}$)' ) ],
            father_name: [ '', Validators.pattern( '^[A-Za-z\\s.]*$' ) ],
            via: [ 'verify24*7', Validators.required ], // Default set to "verify24*7"
            type: [ 'candidate', Validators.required ], // Default set to "candidate"
        } );
    }

    /**
     * Create a new job with the request and handles the validation errors.
     */
    createJob () {

        this.applyDefaultValidations();
        this.isSubmitting = true;
        const data = this.createFormGroup.value;
        this.vcourtService.create( data ).subscribe( response => {
            this.checkResponse( response )
        },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            } );

    }

    // Check the response when the status and updated and give the alert accordingly.
    checkResponse ( response: any ) {

        if ( response[ 'success' ] ) {

            this.commonService.notifications.success( 'Successfully', response[ 'message' ] );
            this.hide();
            this.onSubmit.emit( true );
        }
        else {
            this.commonService.notifications.error( 'Error', response[ 'message' ] );
        }
    }

    show () {
        this.submitNewVcourtRequest.show();
    }

    hide () {
        this.initForm();
        this.submitNewVcourtRequest.hide();
    }

    applyDefaultValidations () {
        // Subscribe to any Change in Verification Status
        this.createFormGroup.get( 'type' )?.valueChanges.subscribe(
            type => {
                this.applyValidationsForTypeChange( type );
            }
        );
    }

    applyValidationsForTypeChange ( type: any ) {
        if ( type == 'candidate' ) {
            this.createFormGroup.get( 'address' )?.setValidators( [ Validators.required ] );
            this.createFormGroup.get( 'state' )?.setValidators( [ Validators.required ] );
            this.createFormGroup.get( 'district' )?.setValidators( [ Validators.required ] );
        }
        else {
            this.createFormGroup.get( 'address' )?.setValidators( [] );
            this.createFormGroup.get( 'state' )?.setValidators( [] );
            this.createFormGroup.get( 'district' )?.setValidators( [] );
            this.errorMessage = '';
        }

        this.createFormGroup.get( 'address' )?.updateValueAndValidity();
        this.createFormGroup.get( 'state' )?.updateValueAndValidity();
        this.createFormGroup.get( 'district' )?.updateValueAndValidity();
    }
}
