<div bsModal #webhookSettingsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="webhookSettingsForm" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Settings</h4>

                </div>
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-offset-8 col-md-4">
                            <label class="center-block"> Add Events </label>
                        </div>
                        <div class="col-md-offset-6 col-md-6 text-right">
                            <a (click)="addNewRow()" class="btn btn-sm btn-addon">
                                <i class="fa fa-plus"></i>
                            </a>
                        </div>
                    </div>

                    <fieldset [disabled]="isSubmitting">

                        <div formArrayName="settings">
                            <div class="row" *ngFor="let item of formArray.controls; let i=index" [formGroupName]="i">
                                <div class="col-md-12 form-group">
                                    <label class="center-block"> Event </label>
                                    <input class="form-control" formControlName="event">

                                </div>

                            </div>
                        </div>

                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" (click)="updateWebhookSettings()"
                            [disabled]="!webhookSettingsForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>