import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { booleanValues, categories, natureOfCases, riskOptions } from '../../core/models/common.model';
import { Employee } from '../../core/models/employee.model';
import { IcrVerification } from '../../core/models/icr-verification.model';
import { CommonService } from '../../core/services/common.service';
import { IcrService } from '../../core/services/icr.service';
import { ShortcutService } from '../../core/services/shortcut.service';
import { CasesCreateComponent } from '../cases-create/cases-create.component';
import { CasesEditComponent } from '../cases-edit/cases-edit.component';
import { IcrCase } from 'src/app/core/models/icr-case.model';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-icr-edit',
    templateUrl: './icr-edit.component.html',
    styleUrls: [ './icr-edit.component.css' ],
    providers: [ IcrService ],
} )
export class IcrEditComponent extends VerificationEditComponent
    implements OnInit, OnChanges, OnDestroy {
    verifications: IcrVerification[] = [];
    categories: any = categories;
    natureOfCases: any = natureOfCases;
    booleanValues: any = booleanValues;
    acts!: any[];
    sections: any[] = [];
    allSections: any[] = [];
    override crmUrl!: string;
    override verificationFormGroup!: FormGroup;
    showcnrDetails: boolean = false;
    caseResults: any;
    riskOptions = riskOptions;
    bsModalRef!: BsModalRef;
    override verification: string = 'icr';

    @ViewChild( CasesCreateComponent, { static: false } ) casesCreateComponent!: CasesCreateComponent;
    @ViewChild( CasesEditComponent, { static: false } ) caseEditComponent!: CasesEditComponent;



    constructor (
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override verificationService: IcrService,
        public global: GlobalService,
        protected override common: CommonService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected override confirmationModalService: ConfirmationModalService

    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );
        this.initForm();
    }

    get states () {
        return this.global.fetchStates();
    }

    get districts () { return this.global.fetchDistricts(); }

    get statuses () { return this.global.fetchStatuses(); }

    override ngOnInit (): void {
        // this.date = new Date().toJSON().slice(0, 10);

        this.verification = 'icr';

        this.crmUrl = environment.crm_url;

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {
            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService
                .findByEmployeeId( this.employeeId )
                .subscribe( employee => {
                    this.verifications = employee.icr_verifications;
                    this.employee = employee;
                    this.populateForm( employee );
                    this.getAttachments( employee );
                    this.isSubmitting = false;
                    this.ngOnChanges();
                } );
        } );

        this.subscribeToQueryChanges();

        this.verificationService
            .getAllActs()
            .subscribe(
                response => ( this.acts = response ),
                err =>
                    this.common.notifications.error( 'Error occurred while getting Acts' )
            );

        this.verificationService.getAllSections().subscribe(
            response => {
                this.allSections = response;
            },
            err =>
                this.common.notifications.error( 'Error occurred while getting Sections' )
        );

    }

    initForm (): void {

        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            formArray: this.fb.array( [] ),
        } );

    }

    populateForm ( employee: Employee ): void {

        const verifications = employee.icr_verifications as IcrVerification[];

        const verificationFormGroup = verifications.map( verification =>
            this.fb.group( {
                ...verification,
            } )
        );

        this.verificationFormGroup.patchValue( employee );

        const verificationGroupArray = this.fb.array( verificationFormGroup );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );
    }


    ngOnChanges (): void {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );

            // not required anymore as cnr is in case edit now
            // this.applyDefaultValidations( form );

            this.applyValidations( form.get( 'status_id' )?.value, form );

            form.get( 'status_id' )?.valueChanges.subscribe( ( statusId: any ) => {
                this.applyValidations( statusId, form );
            } );

        } );
    }

    // Apply Validations on edit Page.
    applyValidations ( statusId: any, form: any ): void {
        // List of statuses for which validation is required.
        const validStatusesForCaseDetails: number[] = [ 2, 3, 12, 13, 15, 16, 17, 21, 22 ];

        const validStatusesCompletionDate: number[] = [
            1,
            2,
            3,
            9,
            10,
            11,
            12,
            13,
            15,
            16,
            17,
            18,
            21,
            22,
        ];

        // List of statuses for which validations is required on client remarks.
        const validStatusesForClientRemarks: number[] = [ 9, 10, 11, 18 ];

        // List of statuses for which validations is required on address, state, district.
        const validStatusesForAddressRelatedFields: number[] = [
            1,
            2,
            3,
            8,
            12,
            13,
            14,
            15,
            16,
            17,
            19,
            20,
            21,
            22,
        ];
        const validStatusesForDistrictCourt: number[] = [ 1, 2, 3, 12, 13, 15, 16, 17, 21, 22 ];

        // If selected status is present in the list of statuses defined, apply validations.
        if ( validStatusesCompletionDate.indexOf( statusId ) != -1 ) {

            form
                .get( 'completed_at' )
                .setValidators( [
                    Validators.required,
                    validateCompletionDate( this.current_date, this.current_date ),
                ] );

        } else {

            form
                .get( 'completed_at' )
                .setValidators( [
                    validateCompletionDate( this.current_date, this.current_date ),
                ] );

        }

        // // Check if changed status is in our list.
        if ( validStatusesForClientRemarks.indexOf( statusId ) != -1 ) {

            form.get( 'client_remarks' ).setValidators( [ Validators.required ] );

        } else {

            form.get( 'client_remarks' ).setValidators( [] );

        }

        // Check if changed status is in our list.
        if ( validStatusesForAddressRelatedFields.indexOf( statusId ) != -1 ) {

            form.get( 'address' ).setValidators( [ Validators.required ] );
            form.get( 'state_id' ).setValidators( [ Validators.required ] );
            form.get( 'district_id' ).setValidators( [ Validators.required ] );

        } else {

            form.get( 'address' ).setValidators( [] );
            form.get( 'state_id' ).setValidators( [] );
            form.get( 'district_id' ).setValidators( [] );

        }

        // Update Fields
        form.get( 'address' ).updateValueAndValidity();
        form.get( 'state_id' ).updateValueAndValidity();
        form.get( 'district_id' ).updateValueAndValidity();
        form.get( 'completed_at' ).updateValueAndValidity();
        form.get( 'client_remarks' ).updateValueAndValidity();

    }


    createIcr (): void {
        this.verificationService.create( this.employee.id ).subscribe( response => {
            this.ngOnInit();
        } );

        this.common.notifications.success( 'Icr Verification added' );
    }

    onCaseCreated ( $case: IcrCase, index: number ): void {

        this.updateCompletedAtDate( index );

    }

    onCaseUpdated ( $case: IcrCase, index: number ): void {

        this.updateCompletedAtDate( index );

    }

    onCaseDeleted ( $case: IcrCase, index: number ): void {

        this.updateCompletedAtDate( index );

    }

    updateCompletedAtDate ( index: number ): void {

        const form: FormGroup = this.formArray.controls[ index ] as FormGroup;

        form.get( 'completed_at' )?.setValue( this.current_date );

        form.get( 'completed_at' )?.setValidators( [ Validators.required ] );
        form.get( 'completed_at' )?.updateValueAndValidity();

    }

    createCaseDetails ( verification: FormGroup ): void {

        this.casesCreateComponent.show( verification.value.id, this.acts, this.sections, this.allSections );

    }

    updateCaseDetails ( caseData: any ): void {

        const params = {
            case: caseData,
            acts: this.acts,
            allSections: this.allSections,
            sections: this.sections
        };

        // this.caseEditComponent.show( params );

    }

    deleteCase ( caseData: { id: number; } ): void {

        this.verificationService.deleteCaseDetails( caseData.id ).subscribe( ( response ) => {

            this.common.notifications.success( 'Case Deleted' );
            this.ngOnInit();

        } );

    }


    getSelectedSections ( statusId: any ): string[] {
        const names: any[] = [];

        statusId.forEach( ( value: any ) => {
            this.allSections.filter( item => {
                if ( item.id == value ) {
                    names.push( item.name );
                }
            } );
        } );

        return names;
    }

    showCnrDetails (): void {
        this.showcnrDetails = true;
    }

    hideCnrDetails (): void {
        this.showcnrDetails = false;
    }

    // Get Court Search Result Status
    caseResultDetails ( vcourtCaseId: number ): void {
        this.verificationService
            .getVcourtCaseResultsDetails( vcourtCaseId )
            .subscribe( response => {
                this.caseResults = response;
            } );
    }

    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.cdr.detectChanges();
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                () => {
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }

    /**
     * Set Default Validations to be applied on Page Load
     *
     * @param {AbstractControl} form
     */
    private applyDefaultValidations ( form: AbstractControl ): void {
        form.get( 'cnr_number' )?.setValidators( [ validateAllowedCharacters( '[0-9]*' ), validateLength( '16' ) ] );
        form.get( 'cnr_number' )?.updateValueAndValidity();
    }


    override ngOnDestroy (): void {

    }


}
