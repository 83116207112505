import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InsuranceService } from 'src/app/core/services/insurance.service';

@Component( {
    selector: 'app-insurance-stats',
    templateUrl: './insurance-stats.component.html',
    styleUrls: [ './insurance-stats.component.css' ],
} )
export class InsuranceStatsComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();
    smsStats!: any[];
    vahanStats: any;
    stateStats!: any[];
    parivahanStats!: any[];

    constructor (
        private route: ActivatedRoute,
        private insuranceService: InsuranceService
    ) { }

    ngOnInit (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterStats();
        } );
    }

    filterStats (): void {
        // if ( this.filterParams?.getAll( 'params' )?.length ) {
        this.insuranceService.getStats( this.filterParams ).subscribe(
            response => {
                this.smsStats = response[ 'sms' ];
                this.vahanStats = response[ 'vahan' ];
                this.stateStats = response[ 'state' ];
                this.parivahanStats = response[ 'parivahan' ];
            }

        );
        // }
    }

}
