import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/**
 * Check if email is valid or not.
 *
 * @returns {ValidatorFn}
 */
export function validateEmailByDomain ( blank = false, validDomains: string[] = [] ): ValidatorFn {

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        const domain = control.value?.split( '@' )[ 1 ];

        if ( blank && control.value == 'blank' ) return {};

        if ( !control.value ) return {};

        if ( validDomains.includes( domain ) ) return {};

        return { 'validateEmailByDomain': { value: control.value } };
    };

}


@Directive( {
    selector: '[validateEmailByDomain]',
    providers: [ { provide: NG_VALIDATORS, useExisting: EmailValidatorByDomainDirective, multi: true } ],
} )
export class EmailValidatorByDomainDirective implements Validator {

    @Input() length!: string;

    validate ( control: AbstractControl ): { [ key: string ]: any } {

        return validateEmailByDomain()( control ) || {};
    }
}
