import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { CommonService } from '../../core/services/common.service';
import { HttpParams } from '@angular/common/http';
import { Pagination } from '../../core/models/pagination.model';
import { PanService } from '../../core/services/pan.service';
import { VoterService } from '../../core/services/voter.service';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';

@Component( {
    selector: 'app-voter-list',
    templateUrl: './voter-list.component.html',
    styleUrls: [ './voter-list.component.css' ],
} )
export class VoterListComponent extends ExportExcelComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;
    fetchingInProgress!: boolean;
    data: any[] = [];

    constructor (
        private voterService: VoterService,
        private route: ActivatedRoute,
        private router: Router,

        private commonService: CommonService
    ) {
        super();
    }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    filterResults (): void {
        this.voterService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );
    }

    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    resend ( log: any ) {
        this.voterService.resend( log.id ).subscribe( response => {
            this.commonService.notifications.success( 'Success', 'Voter ID Request Sent successfully' );
            this.filterResults();
        } );
    }

    onSearch () {
        this.filterResults();
    }

    exportLogsExcel () {
        this.fetchingInProgress = true;
        this.voterService.getDataForExcel( this.filterParams ).subscribe(
            ( response ) => {

                this.data = this.data.concat( response.data );

                if ( response[ 'next_page_url' ] ) {

                    this.filterParams = this.filterParams.set( 'page', response[ 'next_page_url' ].split( '=' )[ 1 ] );

                    this.exportLogsExcel();
                } else {
                    this.commonService.notifications.success( 'All Data Fetched' );
                    this.downloadExcel( this.data, 'Voter Logs Excel' );
                    this.fetchingInProgress = false;
                    this.data = [];
                }
            } );
    }

    resendAll () {
        this.voterService.resendAll( this.filterParams ).subscribe( response => {
            this.commonService.notifications.success( ' Request Resent' );
        },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }


}
