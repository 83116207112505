import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';

@Injectable( {
    providedIn: 'root',
} )

export class ClientCrmService {

    constructor ( private api: ApiService ) { }

    filter ( params: any ): Observable<Pagination> {
        return this.api.get( 'client-crm', params );
    }

    getAll (): Observable<Pagination> {
        return this.api.get( 'client-crm' );
    }

    find ( id: number ) {
        return this.api.get( 'client-crm/' + id );
    }

    create ( values: Object ) {
        return this.api.post( 'client-crm', values );
    }

    update ( id: number, values: Object ) {
        return this.api.put( 'client-crm/' + id, values );
    }

    delete ( id: number ) {
        return this.api.delete( 'client-crm/' + id );
    }

    parseData ( formData: FormGroup ) {

        const data: any = { ...formData.value };

        const mapArrayToObject = ( array: any, keyName: any ) =>
            array.reduce( ( obj: any, item: any ) => {
                obj[ item[ keyName ] ] = item.value;
                return obj;
            }, {} );

        data.headers = mapArrayToObject( data.headers, 'header' );
        data.params = mapArrayToObject( data.params, 'param' );
        data.verifications = mapArrayToObject( data.verifications, 'verification_name' );

        return data;
    }

}
