import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../core/shared.module';
import { GlobalDbLogListComponent } from './global-db-log-list/global-db-log-list.component';
import { GlobalDbLogExcelImportComponent } from './global-db-log-excel-import/global-db-log-excel-import.component';
import { GlobalDbLogFiltersComponent } from './global-db-log-filters/global-db-log-filters.component';
import { GlobalDbLogSearchModalComponent } from './global-db-log-search-modal/global-db-log-search-modal.component';
import { GlobalDbLogResponseModalComponent } from './global-db-log-response-modal/global-db-log-response-modal.component';
import { GlobalDbLogStatsComponent } from './global-db-log-stats/global-db-log-stats.component';
import { GlobalDbLogService } from '../core/services/global-db-log.service';
@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        GlobalDbLogListComponent,
        GlobalDbLogExcelImportComponent,
        GlobalDbLogFiltersComponent,
        GlobalDbLogSearchModalComponent,
        GlobalDbLogResponseModalComponent,
        GlobalDbLogStatsComponent ],
    providers: [ GlobalDbLogService ],
} )
export class GlobalDbLogModule {
}
