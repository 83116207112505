export class Client {
    id!: number;
    name!: string;
    mobile_number!: string;
    designation!: string;
    email!: string;
    company_name!: string;
    status!: number;
    status_name!: string;
    created_at!: string;
    updated_at!: string;
    lots!: any[];

}
