import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BlacklistedService } from 'src/app/core/services/blacklisted.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component( {
    selector: 'app-blacklisted-search-modal',
    templateUrl: './blacklisted-search-modal.component.html',
    styleUrls: [ './blacklisted-search-modal.component.css' ],
} )
export class BlacklistedSearchModalComponent implements OnInit {
    isSubmitting: boolean = false;
    searchBlacklistedForm!: FormGroup;


    @ViewChild( 'vehicleSearch', { static: false } )
    public vehicleSearch!: ModalDirective;

    @Output()
    onSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private blacklistedService: BlacklistedService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) { }

    ngOnInit (): void {

        this.initForm();
    }


    initForm (): void {

        this.searchBlacklistedForm = this.fb.group( {
            rc_number: [ '', Validators.required ],
            sources: [ [] ],
        } );

    }

    show (): void {

        this.vehicleSearch.show();
    }

    onCheckboxChange ( event: any, value: string ): void {

        const list: Array<{ index: string }> = this.searchBlacklistedForm.value.sources;

        const data: { index: string } = {
            index: value,
        };

        if ( event.target.checked === true ) {

            list.push( data );
        } else {

            for ( let i: number = 0; i < list.length; i++ ) {

                if ( list[ i ].index === value ) {

                    list.splice( i, 1 );

                    break;
                }
            }
        }

        this.searchBlacklistedForm.value.sources = list;
    }

    hide (): void {

        this.initForm();
        this.vehicleSearch.hide();
    }


    search (): void {
        this.isSubmitting = true;

        this.blacklistedService.search( this.searchBlacklistedForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Request Sent Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onSearch.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }
}
