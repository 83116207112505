<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>

<div *ngIf="verificationFormGroup;then editMultiForm else editForm">
</div>
<ng-template #editMultiForm>
    <div [formGroup]="verificationFormGroup">
        <div formArrayName="formArray">
            <div [formGroupName]="formGName">
                <div class="panel-body">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">Qa Required: </label>
                            <select class="form-control" formControlName="qa_required">
                                <option [value]="0"> NO </option>
                                <option [value]="1"> YES </option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Qa Done: </label>
                            <select class="form-control" formControlName="qa_done">
                                <option [value]="0"> NO </option>
                                <option [value]="1"> YES </option>
                            </select>
                        </div>
                    </div>
                    <div class="row">

                        <div *ngIf="statuses" class="col-md-3 form-group">
                            <label class="center-block"> Status </label>

                            <input formControlName="status_name" [typeahead]="statuses['education']"
                                [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select Status"
                                [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                (typeaheadOnSelect)="onStatuschanged($event)">

                        </div>

                        <div class="form-group col-md-3">
                            <label class="center-block">Enrollment number: </label>
                            <input class="form-control" formControlName="enrollment_number">
                            <small class="text-danger" *ngIf="editFormGroup.get('enrollment_number').errors">
                                Required
                            </small>
                        </div>

                        <div class="form-group col-md-3">
                            <app-institute-typeahead [formGroup]="editFormGroup">
                            </app-institute-typeahead>
                        </div>

                        <div class="form-group col-md-3">
                            <app-sub-institute-typeahead [formGroup]="editFormGroup">
                            </app-sub-institute-typeahead>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">Course: </label>
                            <input class="form-control" formControlName="course_name">
                            <small class="text-danger" *ngIf="editFormGroup.get('course_name').errors">
                                Required
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">CGPA: </label>
                            <input class="form-control" formControlName="cgpa">
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Name Of Institution: </label>
                            <input class="form-control" formControlName="college_name">
                            <small class="text-danger" *ngIf="editFormGroup.get('college_name').errors">
                                Required
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Passing Year: </label>
                            <input class="form-control" formControlName="passing_year">
                            <small class="text-danger" *ngIf="editFormGroup.get('passing_year').errors">
                                Required
                            </small>

                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">Methodology:</label>
                            <select class="form-control" formControlName="method">
                                <option *ngFor="let method of methodology" [value]="method.id">
                                    {{method.name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block"> Country </label>
                            <input formControlName="country_name" [typeahead]="countries" [typeaheadMinLength]="0"
                                typeaheadOptionField="name" placeholder="Select Country"
                                [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                (typeaheadOnSelect)="onCountryChanged($event)">
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Client Remarks: </label>
                            <input class="form-control" formControlName="client_remarks"
                                [required]="clientRemarksValidation">
                            <small class="text-danger" *ngIf="editFormGroup.get('client_remarks').errors">
                                Required
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Payment Reference Number: </label>
                            <input class="form-control" formControlName="payment_reference_number">

                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">India Post Id: </label>
                            <input class="form-control" formControlName="india_post_id">
                            <small class="text-danger" *ngIf="editFormGroup.get('india_post_id').errors">
                                Must be 13 digits long
                            </small>

                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Select Vendor</label>
                            <input formControlName="vendor_name" [typeahead]="vendors" [typeaheadMinLength]="0"
                                typeaheadOptionField="name" placeholder="Select Vendor"
                                [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                (typeaheadOnSelect)="onVendorChanged($event)">
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Sent On:</label>
                            <input type="date" class="form-control" formControlName="sent_to_vendor_on"
                                [required]="sentOn" min="{{created_at}}"
                                max="{{editFormGroup.get('completed_at').value}}">
                            <small class="text-danger" *ngIf="editFormGroup.get('sent_to_vendor_on').errors">
                                Required
                            </small>

                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Completed At</label>
                            <input type="date" class="form-control" formControlName="completed_at" min="{{created_at}}"
                                max="{{current_date}}" [required]="completedAtValidation">
                            <small class="text-danger" *ngIf="editFormGroup.get('completed_at').errors">
                                Required
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">Education Discipline</label>
                            <select class="form-control" formControlName="discipline">
                                <option value="0">Full time</option>
                                <option value="1">Part time</option>
                                <option value="2">Distance learning</option>
                            </select>
                            <small class="text-danger" *ngIf="editFormGroup.get('discipline').errors">
                                Required
                            </small>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3 form-group">
                            <label class="center-block">Insufficiency Raised On</label>
                            <small class="text-danger" *ngIf="editFormGroup.get('insuff_raised_at').errors">
                                Invalid
                            </small>
                            <input readonly type="date" class="form-control" formControlName="insuff_raised_at"
                                max="{{current_date}}">
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Insufficiency Cleared On</label>
                            <small class="text-danger" *ngIf="editFormGroup.get('insuff_cleared_at').errors">
                                Invalid
                            </small>
                            <input readonly type="date" class="form-control" formControlName="insuff_cleared_at"
                                max="{{current_date}}">
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-3">

                            <label>Payment Reference Proof</label>

                            <input type="file" id="payment_url_{{editFormGroup.get('id').value}}" ng2FileSelect
                                [uploader]="uploader" class="form-control" (change)="upload('payment_url')" />
                        </div>

                        <div class="col-md-3">

                            <label>Proof</label>

                            <input type="file" id="proof_url_{{editFormGroup.get('id').value}}" ng2FileSelect
                                accept="image/*" [uploader]="uploader" class="form-control"
                                (change)="upload('proof_url')" />
                        </div>

                        <div class="col-md-3">

                            <label>Degree</label>

                            <input type="file" id="degree_{{editFormGroup.get('id').value}}" multiple
                                class="form-control" (change)="upload('degree')" />
                        </div>

                        <div class="col-md-3">

                            <label>Marksheet</label>

                            <input type="file" id="marksheet_{{editFormGroup.get('id').value}}" multiple
                                class="form-control" (change)="upload('marksheet')" />
                        </div>
                    </div>

                    <div class="row" *ngIf="attachments">

                        <div class="col-md-3" *ngIf="attachments && attachments['payment_urls']; else elseDiv">
                            <div class="row" *ngFor="let attachment of attachments['payment_urls']">
                                <div class="col-md-12">
                                    <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" *ngIf="editFormGroup.get('proof_url').value; else elseDiv">
                            <div class="row">

                                <div class="col-md-12">
                                    <a target="_blank" [href]="editFormGroup.get('proof_url').value" class="btn-link">
                                        Proof Url
                                    </a>
                                </div>
                            </div>
                        </div>

                        <ng-template #elseDiv>
                            <div class="col-md-3">
                                No File Attached
                            </div>
                        </ng-template>

                        <div class="col-md-3" *ngIf="attachments && attachments['degrees']; else elseDiv">
                            <div class="row" *ngFor="let attachment of attachments['degrees']">
                                <div class="col-md-12">
                                    <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-3" *ngIf="attachments && attachments['marksheets']; else elseDiv">
                            <div class="row" *ngFor="let attachment of attachments['marksheets']">
                                <div target="_blank" class="col-md-12">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3">

                            <label>Documents</label>

                            <input type="file" id="document_{{editFormGroup.get('id').value}}" multiple
                                class="form-control" (change)="upload('document')" />
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-md-3" *ngIf="attachments && attachments['documents'];">

                            <div class="row" *ngFor="let attachment of attachments['documents']">
                                <div class="col-md-12">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #editForm>
    <div [formGroup]="editFormGroup">
        <div class="panel-body">
            <div class="row">

                <div *ngIf="statuses" class="col-md-3 form-group">
                    <label class="center-block"> Status </label>

                    <input formControlName="status_name" [typeahead]="statuses['education']" [typeaheadMinLength]="0"
                        typeaheadOptionField="name" placeholder="Select Status"
                        [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                        (typeaheadOnSelect)="onStatuschanged($event)">

                </div>

                <div class="form-group col-md-3">
                    <label class="center-block">Enrollment number: </label>
                    <input class="form-control" formControlName="enrollment_number">
                    <small class="text-danger" *ngIf="editFormGroup.get('enrollment_number').errors">
                        Required
                    </small>
                </div>

                <div class="form-group col-md-3">
                    <app-institute-typeahead [formGroup]="editFormGroup"></app-institute-typeahead>
                </div>

                <div class="form-group col-md-3">
                    <app-sub-institute-typeahead [formGroup]="editFormGroup"></app-sub-institute-typeahead>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-3">
                    <label class="center-block">Course: </label>
                    <input class="form-control" formControlName="course_name">
                    <small class="text-danger" *ngIf="editFormGroup.get('course_name').errors">
                        Required
                    </small>
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">CGPA: </label>
                    <input class="form-control" formControlName="cgpa">
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Name Of Institution: </label>
                    <input class="form-control" formControlName="college_name">
                    <small class="text-danger" *ngIf="editFormGroup.get('college_name').errors">
                        Required
                    </small>
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Passing Year: </label>
                    <input class="form-control" formControlName="passing_year">
                    <small class="text-danger" *ngIf="editFormGroup.get('passing_year').errors">
                        Required
                    </small>

                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-3">
                    <label class="center-block">Methodology:</label>
                    <select class="form-control" formControlName="method">
                        <option *ngFor="let method of methodology" [value]="method.id">
                            {{method.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block"> Country </label>
                    <input formControlName="country_name" [typeahead]="countries" [typeaheadMinLength]="0"
                        typeaheadOptionField="name" placeholder="Select Country"
                        [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                        (typeaheadOnSelect)="onCountryChanged($event)">
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Client Remarks: </label>
                    <input class="form-control" formControlName="client_remarks" [required]="clientRemarksValidation">

                    <small class="text-danger" *ngIf="editFormGroup.get('client_remarks').errors">
                        Required
                    </small>
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Payment Reference Number: </label>
                    <input class="form-control" formControlName="payment_reference_number">

                </div>
            </div>

            <div class="row">
                <div class="form-group col-md-3">
                    <label class="center-block">India Post Id: </label>
                    <input class="form-control" formControlName="india_post_id">
                    <small class="text-danger" *ngIf="editFormGroup.get('india_post_id').errors">
                        Must be 13 digits long
                    </small>

                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Select Vendor</label>
                    <input formControlName="vendor_name" [typeahead]="vendors" [typeaheadMinLength]="0"
                        typeaheadOptionField="name" placeholder="Select Vendor"
                        [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                        (typeaheadOnSelect)="onVendorChanged($event)">
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Sent On:</label>
                    <input type="date" class="form-control" formControlName="sent_to_vendor_on" [required]="sentOn">
                    <small class="text-danger" *ngIf="editFormGroup.get('sent_to_vendor_on').errors">
                        Required
                    </small>

                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Completed At</label>
                    <input type="date" class="form-control" min="{{created_at}}" max="{{current_date}}"
                        formControlName="completed_at" [required]="completedAtValidation">
                    <small class="text-danger" *ngIf="editFormGroup.get('completed_at').errors">
                        Required
                    </small>
                </div>

                <div class="form-group col-md-3">
                    <label class="center-block">Education Discipline</label>
                    <select class="form-control" formControlName="discipline">
                        <option value="0">Full time</option>
                        <option value="1">Part time</option>
                        <option value="2">Distance learning</option>
                    </select>
                    <small class="text-danger" *ngIf="editFormGroup.get('discipline').errors">
                        Required
                    </small>
                </div>
            </div>

            <div class="row">

                <div class="col-md-3 form-group">
                    <label class="center-block">Insufficiency Raised On</label>
                    <small class="text-danger" *ngIf="editFormGroup.get('insuff_raised_at').errors">
                        Invalid
                    </small>
                    <input readonly type="date" class="form-control" formControlName="insuff_raised_at"
                        max="{{current_date}}">
                </div>

                <div class="col-md-3 form-group">
                    <label class="center-block">Insufficiency Cleared On</label>
                    <small class="text-danger" *ngIf="editFormGroup.get('insuff_cleared_at').errors">
                        Invalid
                    </small>
                    <input readonly type="date" class="form-control" formControlName="insuff_cleared_at"
                        max="{{current_date}}">
                </div>
            </div>


            <div class="row">
                <div class="col-md-3">

                    <label>Payment Reference Proof</label>

                    <input type="file" id="payment_url_{{verification.id}}" class="form-control"
                        (change)="upload('payment_url')" />
                </div>

                <div class="col-md-3">

                    <label>Proof</label>

                    <input type="file" id="proof_url_{{verification.id}}" ng2FileSelect accept="image/*"
                        [uploader]="uploader" class="form-control" (change)="upload('proof_url')" />
                </div>

                <div class="col-md-3">

                    <label>Degree</label>

                    <input type="file" id="degree_{{verification.id}}" multiple class="form-control"
                        (change)="upload('degree')" />
                </div>

                <div class="col-md-3">

                    <label>Marksheet</label>

                    <input type="file" id="marksheet_{{verification.id}}" multiple class="form-control"
                        (change)="upload('marksheet')" />
                </div>
            </div>

            <div class="row" *ngIf="verification.attachments">

                <div class="col-md-3"
                    *ngIf="verification.attachments && verification.attachments['payment_urls']; else elseDiv">
                    <div class="row" *ngFor="let attachment of verification.attachments['payment_urls']">
                        <div class="col-md-12">
                            <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                {{attachment['file_name']}}
                            </a>
                        </div>
                    </div>
                </div>


                <div class="col-md-3" *ngIf="verification.proof_url; else elseDiv">
                    <div class="row">

                        <div class="col-md-12">
                            <a target="_blank" href="{{verification.proof_url}}" class="btn-link">
                                Proof Url
                            </a>
                        </div>
                    </div>
                </div>

                <ng-template #elseDiv>
                    <div class="col-md-3">
                        No File Attached
                    </div>
                </ng-template>


                <div class="col-md-3"
                    *ngIf="verification.attachments && verification.attachments['degrees']; else elseDiv">
                    <div class="row" *ngFor="let attachment of verification.attachments['degrees']">
                        <div class="col-md-12">
                            <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                {{attachment['file_name']}}
                            </a>
                        </div>
                    </div>

                </div>

                <div class="col-md-3"
                    *ngIf="verification.attachments && verification.attachments['marksheets']; else elseDiv">
                    <div class="row" *ngFor="let attachment of verification.attachments['marksheets']">
                        <div class="col-md-12">
                            <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                {{attachment['file_name']}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="col-md-3">

                    <label>Documents</label>

                    <input type="file" id="document_{{verification.id}}" multiple class="form-control"
                        (change)="upload('document')" />
                </div>

            </div>

            <div class="row">

                <div class="col-md-3" *ngIf="verification.attachments && verification.attachments['documents'];">

                    <div class="row" *ngFor="let attachment of verification.attachments['documents']">
                        <div class="col-md-12">
                            <a href="{{attachment['full_path']}}" class="btn-link">
                                {{attachment['file_name']}}
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</ng-template>