<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h1">Email Logs</h1>
    </div>
    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="refresh()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-email-logs-filters [filterParams]="filterParams"></app-email-logs-filters>

<app-email-details #emailDetailsModal></app-email-details>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 20%">Email Details</th>
                        <th style="width: 10%">Email Type</th>
                        <th style="width: 10%">Sent To</th>
                        <th style="width: 10%">Sent By</th>
                        <th style="width: 10%">Status</th>
                        <th style="width: 40%">Timeline</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let log of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    }
            ">
                        <td>
                            <ul class="list-unstyled">
                                <li>{{ log.id }}</li>
                                <li>Subject {{ log.subject }}</li>
                                <strong><a (click)="emailDetailsModal.show(log.body)">View Email</a></strong>
                            </ul>
                        </td>

                        <td>
                            {{ log.mailable_type }}
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li *ngFor="let receiver of log.receivers">{{ receiver }}</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>{{ log.sender.name }}</li>
                                <li>{{ log.sender.email }}</li>
                            </ul>
                        </td>
                        <td>
                            <button class="btn btn-block {{getStatusColor(log.delivery_status)}} btn-status p-1"
                                (click)="showEmailLogStatusTimeLineModal(log.log_statuses)">
                                {{log.delivery_status | uppercase}}
                            </button>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>Sent At: {{ log.sent_at }}</li>
                                <li>Updated At: {{ log.updated_at }}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>