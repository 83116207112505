import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Errors } from 'src/app/core/models/errors.module';
import { CommonService } from 'src/app/core/services/common.service';
import { WorkService } from 'src/app/core/services/work.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-work-type-list-create',
    templateUrl: './work-type-create.component.html',
    styleUrls: [ './work-type-create.component.css' ],
} )
export class WorkTypeCreateComponent implements OnInit {
    @ViewChild( 'workTypeCreate', { static: false } )
    public workTypeCreate!: ModalDirective;

    workTypeFormGroup!: FormGroup;
    submitted!: boolean;
    errors!: Errors;
    completion_statuses: any[] = [];
    work_types: object[] = [];
    verifications: any;

    @Output() onWorkTypeCreate = new EventEmitter<boolean>();

    constructor (
        protected route: ActivatedRoute,
        protected router: Router,
        protected fb: FormBuilder,
        protected commonService: CommonService,
        protected http: HttpClient,
        private workService: WorkService,
        public global: GlobalService
    ) { }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit () {
        this.initForm();
    }

    initForm (): void {
        this.workTypeFormGroup = this.fb.group( {
            work_type: [ '', Validators.required ],
            title: [ '', Validators.required ],
            description: [ '' ],
            completion_statuses: [] = [],
        } );
        this.getWorkType();
        this.verifications = '';
    }

    submitForm (): void {
        this.submitted = true;
        this.errors = new Errors();

        const params = this.workTypeFormGroup.value;
        this.createWorkType( params );
    }

    show () {
        this.workTypeCreate.show();
    }

    hide () {
        this.workTypeCreate.hide();
    }

    onOptionsSelected ( event: any ): void {
        this.verifications = event.target.value;
    }

    getWorkType (): void {
        this.workService.getWorkType().subscribe(
            ( response ) => {
                this.work_types = response;
            },
            ( error ) => {
                console.log( error );
            }
        );
    }

    createWorkType ( params: any ): void {
        this.submitted = false;
        this.workService.createWorkType( params ).subscribe(
            ( response ) => {
                if ( response.success === true ) {
                    this.submitted = false;
                    this.onWorkTypeCreate.emit( true );
                    this.initForm();
                    this.hide();
                    this.commonService.notifications.success(
                        'Success',
                        'Created Successfully'
                    );
                }
            },
            ( error ) => {
                this.submitted = false;
                this.commonService.notifications.error( 'Error', 'Unable to Create' );
            }
        );
    }
}
