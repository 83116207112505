import { Injectable } from '@angular/core';

declare const swal: any;

export interface ConfirmationModalOptions {
    title?: string;
    text?: string;
    type?: string;
    showCancelButton?: boolean;
    confirmButtonColor?: string;
    cancelButtonColor?: string;
    confirmButtonText?: string;
    showCancelMessage?: string;
}

@Injectable( {
    providedIn: 'root'
} )
export class ConfirmationModalService {

    constructor () { }

    // only for yes/no confirmation
    confirm ( options: ConfirmationModalOptions, callback?: ( result: boolean ) => void, cancelCallback?: () => void ) {

        const opts = {
            title: options.title || 'Are you sure?',
            text: options.text || 'You won\'t be able to revert this!',
            type: options.type || 'warning',
            showCancelButton: options.showCancelButton || true,
            confirmButtonColor: options.confirmButtonColor || '#3085d6',
            cancelButtonColor: options.cancelButtonColor || '#d33',
            confirmButtonText: options.confirmButtonText || 'Yes',
            showCancelMessage: options.showCancelMessage || 'Cancelled',
        };

        swal( opts ).then( ( result: boolean ) => {

            if ( callback ) {

                callback( result );
            }

        }, ( dismiss: any ) => {

            if ( opts.showCancelButton ) {
                swal( 'Cancelled', '', 'error' );
            }

            if ( cancelCallback ) {
                cancelCallback();
            }

        } );

    }

    simpleConfirm ( options: ConfirmationModalOptions, callback?: ( result: boolean ) => void, cancelCallback?: () => void ) {

        return this.confirm( options, callback, cancelCallback );

    }


    confirmDelete ( options: ConfirmationModalOptions, callback: ( result: boolean ) => void, cancelCallback?: () => void ) {

        options.title = options.title || 'Are you sure you want to delete this?';

        return this.confirm( options, callback, cancelCallback );

    }


}
