import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { AadhaarService } from 'src/app/core/services/aadhaar.service';
import { CommonService } from 'src/app/core/services/common.service';
import { VcourtService } from 'src/app/core/services/vcourt.service';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-vcourt-update-cases',
    templateUrl: './vcourt-update-cases.component.html',
} )
export class VcourtUpdateCasesComponent extends ImportExcelComponent implements OnInit {

    uploadForm: FormGroup;

    override isSubmitting: boolean = false;

    override excelForm!: FormGroup;

    cases: { case_id: any, status: string, is_updated: boolean, response: string, color: string }[] = [];

    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor (
        private aadhaarService: AadhaarService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected vcourtService: VcourtService,
    ) {

        super( fb );

        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );

    }

    ngOnInit () { }

    applyValidations (): void {

        // throw new Error( 'Method not implemented.' );

    }

    override onFileChange ( evt: any ): void {

        super.onFileChange( evt );

        this.cases = this.cases.splice( 0, this.cases.length );

    }

    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );

        this.cases = this.jsonData;

    }

    setValidationMessages (): void {

        // throw new Error( 'Method not implemented.' );

    }

    submitAndUpdate ( nextIndex?: number ) {

        // if no cases then exit
        if ( this.cases.length == 0 ) return;

        let index = nextIndex || 0;

        // if index is greater then case count then exit
        if ( index > this.cases.length - 1 ) return;

        const item = this.cases[ index ];

        const status = ( item.status as string ).toLocaleLowerCase();

        this.isSubmitting = true;

        this.vcourtService.markCaseStatusAs( [ item.case_id, status ] ).subscribe( response => {

            item.is_updated = true;

            item.color = 'success';

            item.response = 'successfully updated';

            this.isSubmitting = false;

            index++;

            this.submitAndUpdate( index );

        }, ( error: HttpErrorResponse ) => {

            item.is_updated = false;

            item.color = 'danger';

            item.response = 'error in updating';

            if ( error.error.message ) {

                item.response = error.error.message;

            }

            this.isSubmitting = false;

            index++;

            this.submitAndUpdate( index );

        } );


    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
