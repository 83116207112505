import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';

import { CommonService } from 'src/app/core/services/common.service';
import { FilterConfigService } from 'src/app/core/services/filter-config.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { State } from 'src/app/core/models/state.model';
import { District } from 'src/app/core/models/district.model';
import { Company } from 'src/app/core/models/company.model';

@Component( {
    selector: 'app-auto-assign-filters',
    templateUrl: './auto-assign-filters.component.html',
    styleUrls: [ './auto-assign-filters.component.css' ],
} )
export class AutoAssignFiltersComponent implements OnInit {

    @Input()
    verification!: string;

    @Input() filterBy: any;

    @Input()
    filterParams!: HttpParams;

    @Input() sendRequest: boolean = true;

    @Output() filtered: EventEmitter<string> = new EventEmitter();

    @Output() filtersChanged: EventEmitter<any> = new EventEmitter();

    filterFormGroup!: FormGroup;

    filters: any[] = [];

    addFilters: any[] = [];

    statuses: any[] = [];

    lots: any[] = [];

    types: any[] = [];

    employmentCompanies!: any[];

    institutes!: any[];

    updatedFilters: string[] = [];

    @Input()
    vendors!: any[];

    constructor (
        private router: Router,
        public global: GlobalService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private filterConfigService: FilterConfigService
    ) {

        this.initForm();
    }

    get states (): State[] { return this.global.fetchStates(); }

    get districts (): District[] { return this.global.fetchDistricts(); }

    get companies (): Company[] { return this.global.fetchCompanies(); }

    get workTypes () { return this.global.fetchWorkTypes(); }

    get identityTypes () { return this.global.fetchIdentityDocumentTypes(); }

    ngOnInit (): void {

    }


    initForm (): void {

        this.filterFormGroup = this.fb.group( {
            company_id: 'all',
            lot_id: [ [] ],
            state_id: 'all',
            state_name: '',
            district_id: 'all',
            district_name: '',
            status_id: [ [] ],
            created_before: 'all',
            created_after: 'all',
            type_of_doc: 'all',
            qa_present: 'all',
            qa_required: 'all',
            qa_done: 'all',
            assigned_after: 'all',
            assigned_before: 'all',
            work_type: 'all',
            assignment_count: 'all',
        } );
    }

    filterResults (): void {

        const appliedFilters: any = {};

        Object.keys( this.filterFormGroup.value ).forEach( key => {

            if ( this.filterFormGroup.get( key )?.value != '' && this.filterFormGroup.get( key )?.value != 'all' ) {

                appliedFilters[ key ] = this.filterFormGroup.get( key )?.value;

            }

        } );

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: appliedFilters,
            queryParamsHandling: 'merge',
        };


        console.log( this.filterFormGroup.value );

        console.log( appliedFilters );

        // Get the current url string & split it into 2 part by '?'
        // Get only the first part which is the path
        // eg. 'identity?xyx=abc' in url will return only 'identity'
        const currentUrlPath: string = this.router.url.split( '?' )[ 0 ];

        // Navigate to current path along with our set query string
        // This will add query params to the urls
        if ( this.sendRequest ) {
            this.router.navigate( [ currentUrlPath ], navigationExtras ).then( () => {

                this.filtersChanged.emit( navigationExtras );

            } );
        }

    }

    onCompanySelected (): void {

        const companyId: number = this.filterFormGroup.controls[ 'company_id' ].value;

        this.commonService.getLotsByCompanyId( companyId ).subscribe( response => {

            response.push( {
                id: 'all',
                name: 'All',
            } );

            this.lots = response;

        } );

        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        this.filterFormGroup.controls[ 'lot_id' ].setValue( 'all' );
    }

    onLotSelected (): void { }

    resetForm (): void {

        this.initForm();

        this.filterResults();

    }

    // Set the value of the form control state_id
    OnStateSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'state_id' ].setValue( event.item.id );
        // Setting the default value on any change is selection of state.
        // Setting the default value on any change is selection of state.
        this.filterFormGroup.controls[ 'district_name' ].setValue( '' );
    }

    // Set the value of the form control district_id
    OnDistrictSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'district_id' ].setValue( event.item.id );
    }

    // Set the value of the form control vendor_id
    OnVendorSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'vendor_id' ].setValue( event.item.id );
    }

    // Set the value of the form control employment_company_id
    OnEmploymentCompanySelect ( event: any ): void {

        this.filterFormGroup.controls[ 'employment_company_id' ].setValue( event.item.id );
    }

    // Set the value of the form control district_id
    OnInstituteSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'institute_id' ].setValue( event.item.id );
    }

    // Set the value of the form control district_id
    OnMethodologySelect ( event: any ): void {

        this.filterFormGroup.controls[ 'method_id' ].setValue( event.item.id );
    }

    filterUpdate ( event: string[] ): void {
        if ( event.length > 0 ) {
            this.filters = event;
        } else {
            this.filters = this.updatedFilters;
        }
    }

    private setDefault (): void {

        this.filterParams.keys().forEach( key => {

            const value: any = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {

                if ( key === 'company_id' ) {
                    this.filterFormGroup.get( key )?.setValue( parseInt( value ) );
                } else if ( ( key === 'status_id' || key === 'lot_id' || key === 'vendor_id' ) && Array.isArray( value ) ) {


                    const status: number[] = [];
                    if ( value[ 0 ] !== 'all' ) {

                        value.forEach( test => {
                            status.push( parseInt( test ) );
                        } );


                    } else {

                        if ( Array.isArray( value[ 0 ] ) ) {
                            value[ 0 ].forEach( test => {
                                status.push( parseInt( test ) );
                            } );
                        }

                    }

                    this.filterFormGroup.get( key )?.setValue( status );
                } else if ( ( key === 'status_id' || key === 'lot_id' || key === 'vendor_id' ) ) {
                    this.filterFormGroup.get( key )?.setValue( [ parseInt( value ) ] );

                } else if ( key === 'page' ) {
                    this.filterFormGroup.patchValue( { key: value } );
                } else {
                    this.filterFormGroup.get( key )?.setValue( value );
                }
            }
        } );
    }

}
