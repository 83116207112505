<div class="wrapper-md">

    <div class="row">
        <div class="col-md-6">

            <div class="panel panel-default">
                <div class="panel-heading">
                    Fill the details to create a new employee
                </div>
                <div class="panel-body">
                    <app-errors [errors]="errors"></app-errors>
                    <form [formGroup]="createForm" (ngSubmit)="submitForm()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Name</label>
                                <input formControlName="name" class="form-control form-control-lg" />

                            </fieldset>
                            <fieldset class="form-group">
                                <label>Number</label>

                                <input formControlName="mobile_number" class="form-control form-control-lg"
                                    placeholder="Add multiple numbers comma separated with space after comma" />
                                <small class="text-danger"
                                    [hidden]="createForm.get('mobile_number')?.valid || createForm.get('mobile_number')?.pristine">
                                </small>
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Father name</label>
                                <input formControlName="father_name" class="form-control form-control-lg" />
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Date Of Birth </label>
                                <input formControlName="dob" class="form-control form-control-lg" type="date" />
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Email ID </label>
                                <input formControlName="email" class="form-control form-control-lg" type="email" />
                                <small class="text-danger"
                                    [hidden]="createForm.get('email')?.valid || createForm.get('email')?.pristine">
                                </small>
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Official Email ID </label>
                                <input formControlName="official_email" class="form-control form-control-lg"
                                    type="email" />
                                <small class="text-danger"
                                    [hidden]="createForm.get('official_email')?.valid || createForm.get('official_email')?.pristine">
                                </small>
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Client Id</label>
                                <input formControlName="client_id" class="form-control form-control-lg" />
                            </fieldset>


                            <fieldset class="form-group">
                                <div appCan='admin' style="margin-top: 20px; padding-top: 6px;"
                                    formGroupName="settings">
                                    <div class="form-group col-md-3">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" formControlName="email_visibility">
                                            Email Visibility
                                        </label>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" formControlName="mobile_visibility">
                                            Mobile Visibility
                                        </label>
                                    </div>
                                </div>
                            </fieldset>

                            <button class="btn btn-lg btn-primary btn-block" [disabled]="!createForm.valid">
                                {{ title }}
                            </button>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>