import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { PermitService } from '../../core/services/permit.service';


@Component( {
    selector: 'app-permit-list',
    templateUrl: './permit-list.component.html',
    styleUrls: [ './permit-list.component.css' ],
} )
export class PermitListComponent extends ExportExcelComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress = false;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients!: any[];

    constructor ( private permitService: PermitService,
        private route: ActivatedRoute,
        private router: Router,
        private commonService: CommonService ) {
        super();
    }

    ngOnInit () {

        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }


    filterResults (): void {


        this.permitService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );

    }

    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    resend ( source: string, log: { id: any; } ) {

        const data = {
            'type': source,
            'log_id': log.id,
        };

        this.permitService.resend( data ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }

    resendAll ( source: string ) {

        this.permitService.resendAll( source, this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }

    exportLogsExcel () {
        this.filterParams = this.filterParams.set( 'unique', 'no' );
        this.getData();
    }

    exportUniqueLogsExcel () {
        this.filterParams = this.filterParams.set( 'unique', 'yes' );
        this.getData();
    }


    exportDailyUniqueLogsExcel () {
        this.filterParams = this.filterParams.set( 'unique', 'daily' );
        this.getData();
    }

    getData () {

        this.fetchingInProgress = true;
        this.permitService.getDataForExcel( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Permit Logs Excel' );
                this.fetchingInProgress = false;
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    onSearch () {
        this.filterResults();
    }

}
