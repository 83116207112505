<div bsModal #authorityCreateContactModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Create Contact</h4>

            </div>
            <form [formGroup]="contactForm" (ngSubmit)="createContact()" (keydown.enter)=" $event.preventDefault()">
                <div class="modal-body">
                    <div class="row">
                        <input class="form-control form-control-lg" type="hidden" formControlName="id" />

                        <div class="col-md-6 form-group">
                            <label class="center-block"> Name
                                <input formControlName="name" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="center-block"> Designation
                                <input formControlName="designation" class="form-control">
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block"> Email
                                <input formControlName="email" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="center-block"> Phone Number
                                <input formControlName="phone_number" class="form-control">
                            </label>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-12 form-group">
                            <label class="center-block"> Remarks
                                <textarea formControlName="remarks" class="form-control" rows="2"></textarea>
                            </label>
                        </div>
                    </div>
                </div>


                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary">Create</button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>