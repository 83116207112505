import { Subscription } from 'rxjs';
import { Component, OnInit, ViewContainerRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { VerificationList } from 'src/app/core/components/verification-list.component';
import { IdentityVerification } from 'src/app/core/models/identity-verification.model';
import { CommonService } from 'src/app/core/services/common.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { environment } from 'src/environments/environment';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-auto-assign-identity',
    templateUrl: './auto-assign-identity.component.html',
} )
export class AutoAssignIdentityComponent extends VerificationList implements OnInit, OnDestroy {

    loading!: boolean;
    override verifications: IdentityVerification[] = [];
    isSubmitting: boolean = false;
    crmUrl!: string;
    attachments: any[] = [];
    statsVisible: boolean = false;
    override workTypes: any;
    workSubscription!: Subscription;

    constructor (
        protected override verificationService: IdentityService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        public override confirmationModalService: ConfirmationModalService,

    ) {
        super( confirmationModalService );
    }

    ngOnInit () {

        this.crmUrl = environment.crm_url;

        this.verification = 'identity';

        this.getVendorsList();

        this.commonService.getVerificationWorkTypes().subscribe( ( response: { [ x: string ]: any; } ) => {

            this.workTypes = response[ 'identity' ];

            // subscribe after work types have been fetched
            this.subscribeToQueryChanges();

        } );

    }

    filterResults (): void {

        const workTypeId = this.route.snapshot.queryParams[ 'work_type' ];

        if ( !workTypeId || workTypeId == 'all' || workTypeId == '' ) return;

        const workTypeName = this.getWorkName( workTypeId );

        if ( !workTypeName ) return;


    }

    getWorkName ( workTypeId: string | number ): string {

        let workTypeName = null;

        Object.keys( this.workTypes ).forEach( key => {

            if ( this.workTypes[ key ].id === ( +workTypeId ) ) {

                workTypeName = this.workTypes[ key ].name;

            }

        } );

        workTypeName = this.convertStringToKebabCase( workTypeName );

        return workTypeName;
    }

    onFiltersChanged ( $e: NavigationExtras ): void {

        const workTypeId = this.route.snapshot.queryParams[ 'work_type' ];

        if ( !workTypeId || workTypeId == 'all' || workTypeId == '' ) return;

        const workTypeName = this.getWorkName( workTypeId );

        if ( !workTypeName ) return;

        const navigationExtras: NavigationExtras = {
            queryParams: this.route.snapshot.queryParams,
            queryParamsHandling: 'merge',
        };

        this.verificationService.getWork( this.filterParams ).subscribe( ( verification: { id: any; } ) => {

            this.router.navigate( [ 'work/identity/' + workTypeName, verification.id ], navigationExtras );

        }, ( error: any ) => {

            if ( error.status == 404 ) {

                this.commonService.notifications.warning( 'No Case Available' );

            } else {

                this.commonService.notifications.error( error.error );
            }

        } );
    }

    convertStringToKebabCase ( string: string | null ): string | any {

        return string?.replace( /\s+/g, '-' ).toLowerCase();

    }


    ngOnDestroy (): void {

        if ( this.workSubscription ) this.workSubscription.unsubscribe();

    }

}
