import { Component, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { IdentityVerificationStatusType } from '../../core/enums/identity-verification-status-type.enum';
import { validateDate } from 'src/app/common/validators/date-validator.directive';
import { validateLength } from 'src/app/common/validators/length-validator.directive';
import { validateNumericCharacters } from 'src/app/common/validators/numeric-validator.directive';
import { IdentityDataEntryData } from 'src/app/core/interfaces/identity-data-entry-data';
import { IWorkTypeComponent } from 'src/app/core/interfaces/work-type-interface';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { GlobalService } from 'src/app/core/services/global.service';
import { State } from 'src/app/core/models/state.model';
import { District } from 'src/app/core/models/district.model';
@Component( {
    selector: 'app-identity-work-data-entry-qa',
    templateUrl: './identity-work-data-entry-qa.component.html',
    styleUrls: [ './identity-work-data-entry-qa.component.css' ],
} )
export class IdentityWorkDataEntryQaComponent implements OnInit, OnChanges, IWorkTypeComponent {


    verification!: IdentityDataEntryData | any;

    verificationFormGroup!: FormGroup;

    isSubmitting!: boolean;
    filterParams: HttpParams = new HttpParams();
    verificationId!: number | any;
    docNumberErrorMessage: string = 'Required';

    //title not working for typeahead
    statuses: any = [
        {
            id: IdentityVerificationStatusType.DataEntryDone,
            name: 'Data Entry Done'
        },
        {
            id: IdentityVerificationStatusType.DeCorrect,
            name: 'Data Entry Correct'
        },
        {
            id: IdentityVerificationStatusType.DocumentRequired,
            name: 'Document Required'
        }
    ];

    formSubmitting: boolean = false;

    validStatusesForClientRemarks: number[] = [ 2, 3, 5, 9, 11, 12, 14, 17, 18, 19, 21, 23, 24 ];


    constructor (
        private fb: FormBuilder,
        public global: GlobalService,
        private identityService: IdentityService,
        private route: ActivatedRoute,
        private filterService: FilterService,
        private router: Router,
        private commonService: CommonService ) { }

    get states (): State[] { return this.global.fetchStates(); }

    get districts (): District[] { return this.global.fetchDistricts(); }

    get documentTypeList () { return this.global.fetchIdentityDocumentTypes(); }

    ngOnInit (): void {


        this.route.queryParams.subscribe( params => {

            this.formSubmitting = false;

            this.initForm();

            this.verificationId = this.route.snapshot.params[ 'id' ];

            this.subscribeToQueryParams( params );

            this.identityService.getDataEntryQaWork( this.filterParams, this.verificationId ).subscribe( ( response: any ) => {

                this.verification = response;
                this.verificationFormGroup.patchValue( response );

            } );

        } );


        this.ngOnChanges();
    }

    subscribeToQueryParams ( params: any ): void {

        this.filterParams = new HttpParams();

        Object.keys( params ).forEach( key => {
            if ( key !== 'params' && ( params[ key ] !== '' && params[ key ] !== 'all' ) ) {
                this.filterParams = this.filterParams.set( key, params[ key ] );
            }
        } );

    }

    initForm (): void {
        this.verificationFormGroup = this.fb.group( {
            name: [ '' ],
            client_employee_id: [ '' ],
            mobile_number: [ '' ],
            father_name: [ '' ],
            dob: [ '' ],
            age: [ '' ],
            type_id: [],
            type_name: [],
            status_name: [],
            status_id: [],
            number: [],
            file_number: [],
            name_as_per_id: [],
            father_name_as_per_id: [],
            dob_as_per_id: [],
            address_as_per_id: [],
            state_id: [],
            state_name: [],
            district_id: [],
            district_name: [],
            pincode: [],
            date_of_issue: [],
            date_of_expiry: [],
            lmv_first_issue_date: [],
            lmv_last_renewal_date: [],
            tr_first_issue_date: [],
            tr_last_renewal_date: [],
            client_remarks: [],
        } );
    }

    onDoneClicked (): void {

        this.formSubmitting = true;

        const data = {
            data: this.verificationFormGroup.value,
        }

        this.identityService.postDataEntryQaWork( this.filterParams, this.verificationId, data ).subscribe( ( response: any ) => {


            const params = this.filterParams;
            // Set our navigation extras object
            // that contains our global query params and fragment

            const navigationExtras: NavigationExtras = {
                queryParams: {
                    params,
                },
                queryParamsHandling: 'merge',
            };

            this.router.navigate( [ 'work/identity/data-entry-qa', response.id ], navigationExtras );


        }, ( error: any ) => {

            this.formSubmitting = false;

            if ( error.status == 404 ) {

                this.commonService.notifications.warning( 'No Case Found' );

                const navigationExtras: NavigationExtras = {
                    queryParams: this.filterParams,
                    queryParamsHandling: 'merge',
                };

                this.router.navigate( [ 'work/identity' ], navigationExtras );

            } else if ( error.error.code == 422 ) {
                this.commonService.notifications.error( error.error );
            } else {

                this.commonService.notifications.error( error.error );

                const navigationExtras: NavigationExtras = {
                    queryParams: this.filterParams,
                    queryParamsHandling: 'merge',
                };

                this.router.navigate( [ 'work/identity' ], navigationExtras );
            }
        } );

    }

    onExitClicked (): void {

        this.formSubmitting = true;

        this.identityService.cancelDataEntryQaWork( this.filterParams, this.verificationId ).subscribe( () => {

            alert( 'Work Cancelled' );


            const navigationExtras: NavigationExtras = {
                queryParams: this.filterParams,
                queryParamsHandling: 'merge',
            };

            this.router.navigate( [ 'work/identity' ], navigationExtras );

        } );


    }


    /**
     * Zoom In Button
     *
     * @param imageId
     */
    zoomInImage ( imageId: string ): void {

        const element = document.getElementById( imageId );

        if ( element ) {

            const currentWidth: number = parseInt( ( element.style.width.replace( '%', '' ) ), 10 );
            const currentHeight: number = parseInt( ( element.style.height.replace( '%', '' ) ), 10 );

            const newWidth: number = currentWidth + currentWidth / 5;
            const newHeight: number = currentHeight + currentHeight / 5;

            if ( newWidth < 250 ) {
                element.style.width = newWidth.toString() + '%';
                element.style.height = newHeight.toString() + '%';
            }
        }
    }

    /**
     * Zoom Out Button
     *
     * @param imageId
     */
    zoomOutImage ( imageId: string ): void {

        const element = document.getElementById( imageId );

        if ( element ) {

            const currentWidth = parseInt( ( element.style.width.replace( '%', '' ) ), 10 );
            const currentHeight = parseInt( ( element.style.height.replace( '%', '' ) ), 10 );

            const newWidth = currentWidth - currentWidth / 5;
            const newHeight = currentHeight - currentHeight / 5;

            if ( newWidth > 50 ) {
                element.style.width = newWidth.toString() + '%';
                element.style.height = newHeight.toString() + '%';
            }
        }
    }


    /**
     * Get the max Height for image panel
     *
     * @param verification
     * @returns {any}
     */
    getMaxHeight ( verification: any ): string {

        // Set max height on page Load

        const front_url: string = verification.front_url;
        const back_url: string = verification.back_url;

        if ( front_url && !back_url || !front_url && back_url ) {

            return '700px';
        } else {
            return '360px';
        }

    }

    // Set the value of the form control state_id
    OnStateSelect ( event: any ): void {

        this.verificationFormGroup.controls[ 'state_id' ].setValue( event.item.id );
        // Setting the default value on any change is selection of state.
        // Setting the default value on any change is selection of state.
        this.verificationFormGroup.controls[ 'district_name' ].setValue( '' );
    }

    // Set the value of the form control district_id
    OnDistrictSelect ( event: any ): void {
        this.verificationFormGroup.controls[ 'district_id' ].setValue( event.item.id );
    }

    OnTypeSelected ( event: any ): void {
        this.verificationFormGroup.controls[ 'type_id' ].setValue( event.item.id );
        this.applyValidationsForTypeChange( event.item.id );
    }


    OnStatusSelected ( event: any ): void {

        this.verificationFormGroup.controls[ 'status_id' ].setValue( event.item.id );

        if ( this.validStatusesForClientRemarks.includes( parseInt( event.item.id ) ) ) {

            this.verificationFormGroup.get( 'client_remarks' )?.setValidators( [ Validators.required ] );

        } else {
            this.verificationFormGroup.get( 'client_remarks' )?.setValidators( [] );
        }

        this.verificationFormGroup.get( 'client_remarks' )?.updateValueAndValidity();


    }

    ngOnChanges (): void {


        // Subscribe to any change in Doc Type
        this.verificationFormGroup.get( 'type_id' )?.valueChanges.subscribe(
            typeId => {
                this.applyValidationsForTypeChange( typeId );

            }
        );

        this.applyDefaultValidations();

    }

    /**
     * Apply Validations when Doc Type is changed
     *
     * @param type_id
     * @param {AbstractControl} form
     */
    protected applyValidationsForTypeChange ( type_id: number ): void {


        // Pan Card Validation
        if ( type_id == 2 ) {
            this.verificationFormGroup.get( 'number' )?.setValidators( [ Validators.required, Validators.pattern( '^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$' ) ] );
            this.docNumberErrorMessage = 'Incorrect Format';
        }
        // Aadhaar Card Validation
        else if ( type_id == 3 ) {

            this.verificationFormGroup.get( 'number' )?.setValidators( [ Validators.required, validateLength( '12' ) ] );
            this.docNumberErrorMessage = 'Must be 12 digits long';
        }
        else if ( type_id != 0 ) {
            this.verificationFormGroup.get( 'number' )?.setValidators( [ Validators.required ] );
            this.docNumberErrorMessage = 'Required';
        }
        else {
            this.verificationFormGroup.get( 'number' )?.setValidators( [] );
            this.docNumberErrorMessage = '';
        }

        this.verificationFormGroup.get( 'number' )?.updateValueAndValidity();
    }

    /*
       * Set Default Validations to be applied on Page Load
       *
       *
       */
    private applyDefaultValidations (): void {

        this.verificationFormGroup.get( 'name_as_per_id' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
        this.verificationFormGroup.get( 'name_as_per_id' )?.updateValueAndValidity();
        this.verificationFormGroup.get( 'father_name_as_per_id' )?.setValidators( [ validateAllowedCharacters( '()/' ) ] );
        this.verificationFormGroup.get( 'father_name_as_per_id' )?.updateValueAndValidity();
        this.verificationFormGroup.get( 'dob_as_per_id' )?.setValidators( [ validateDate( 'dd-mm-yyyy,yyyy' ) ] );
        this.verificationFormGroup.get( 'dob_as_per_id' )?.updateValueAndValidity();
        this.verificationFormGroup.get( 'pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
        this.verificationFormGroup.get( 'pincode' )?.updateValueAndValidity();

        this.applyValidationsForTypeChange( this.verificationFormGroup.get( 'type_id' )?.value );
    }


}
