<div bsModal #voterDetailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" style="max-height: 100%; overflow-y: auto;">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Voter Id details</h4>

            </div>
            <div class="modal-body">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 25%">
                                Personal Details
                            </th>
                            <th style="width: 25%">
                                Address and Part Details
                            </th>
                            <th style="width: 25%">
                                Name Details
                            </th>
                            <th style="width: 25%">
                                Other Details
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let info of details">
                            <td>
                                <ul class="list-unstyled">
                                    <li>Name : {{info.name}}</li>
                                    <li>Father's Name : {{info.father_name}}</li>
                                    <li>Ac Name : {{info.ac_name}}</li>

                                    <li>Relation Type : {{info.relation_type}}</li>
                                    <li>DOB: {{info.dob}}</li>
                                    <li>Age: {{info.age}}</li>
                                    <li>Gender : {{info.gender}}</li>
                                </ul>
                            </td>

                            <td>
                                <ul class="list-unstyled">
                                    <li>House No. : {{info.house_number}}</li>
                                    <li>Part No.: {{info.part_number}}</li>
                                    <li>Part Name : {{info.part_name}}</li>
                                    <li>Section No. : {{info.section_number}}</li>
                                    <li>District : {{info.district}}</li>
                                    <li>State : {{info.state}}</li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li>Name V1 : {{info.name_v1}}</li>
                                    <li>Name V2 : {{info.name_v2}}</li>
                                    <li>Name V3 : {{info.name_v3}}</li>
                                    <li>RLN Name V1 : {{info.rln_name_v1}}</li>
                                    <li>RLN Name V2 : {{info.rln_name_v2}}</li>
                                    <li>RLN Name V3 : {{info.rln_name_v3}}</li>

                                </ul>
                            </td>

                            <td>
                                <ul class="list-unstyled">
                                    <li>PS LAT LONG : {{info.ps_lat_long}}</li>
                                    <li>Id Number : {{info.id_number}}</li>
                                    <li>st_code: {{info.st_code}}</li>
                                    <li>PS name: {{info.ps_name}}</li>
                                    <li>Sino Inpart : {{info.sino_inpart}}</li>
                                    <li>Last Update : {{info.last_update}}</li>


                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>