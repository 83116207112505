import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { InviteStatusType } from '../models/invite.model';
import { ModalService } from './modal.service';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';

@Injectable( {
    providedIn: 'root',
} )
export class InviteService {

    public readonly maxFollowUpallowed: number = 15;

    constructor (
        private api: ApiService,
        private modalService: ModalService
    ) { }

    filter ( params: Params | undefined ) {
        return this.api.get( 'invites', params );
    }

    sendFollowUpMail ( id: string ) {
        return this.api.post( 'forms-invite/send-follow-up-mail/' + id, {} );
    }

    getFollowUpDetails ( id: string ) {
        return this.api.get( 'invite/follow-up-details/' + id );
    }

    getFormsList ( id: Params | undefined ): Observable<any[]> {
        return this.api.get( 'forms-list', id );
    }

    sendMail ( data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'forms-invite', data );
    }

    getDataForExcel ( param: Params ): Observable<any[]> {
        return this.api.get( 'form-invites/download-excel', param );
    }

    toggleInviteStatus ( id: number ): Observable<{ 'success': boolean }> {
        return this.api.post( 'invites/' + id + '/toggle-status', {} );
    }

    getStatuses () {
        return Object.keys( InviteStatusType ).map( ( key: any ) => {

            const value: number = this.getStatusValue( InviteStatusType[ key as keyof typeof InviteStatusType ] );

            return {
                id: value,
                name: key,
            }

        } );
    }


    getStatusValue ( status: InviteStatusType ): number {

        switch ( status ) {

            case InviteStatusType.CANCELLED:
                return 0;

            case InviteStatusType.PENDING:
                return 1;


            case InviteStatusType.COMPLETED:
                return 2;
        }

    }

    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }

}
