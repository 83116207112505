<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-6">
        <h3 class="m-n h3">Webhook Logs</h3>

        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a routerLink="/webhooks">Webhook</a></li>
            <li>Logs</li>
        </ul>

    </div>
</div>

<app-webhook-log-payload #getPayloadModal></app-webhook-log-payload>

<div class="wrapper-md" [appCan]="'superadmin'">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Select Created After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Select Created Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block"> Event</label>
                        <select class="form-control" formControlName="event">
                            <option *ngFor="let setting of settings" [value]="setting.event">{{setting.event}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">

                        <ng-template #popTemplate>
                            <ul class="list-unstyled" style="max-height: 250px;overflow-y: scroll">
                                <li>Enter '200' for response status 200.</li>
                                <li>Enter '!200' for response status except 200.</li>
                                <li>Enter HTTP Status code for specific response status.</li>
                            </ul>
                        </ng-template>

                        <label class="center-block"> Status:
                            <a class="btn btn-xs" [outsideClick]="true" [popover]="popTemplate"
                                popoverTitle="Status options">?</a>
                        </label>
                        <input class="form-control" formControlName="response_status">

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block"> Object Type</label>
                        <select class="form-control" formControlName="object_type">
                            <option *ngFor="let object of objects" [value]="object">{{object}}</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>

<div class="row" style="padding: 0px 0px 0px 35px">

    <a class="btn btn-primary btn-addon" (click)="retryQueriedWebhook()">
        <i class="fa fa-send"></i>
        Retry All
    </a>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">

            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>

                        <th style="width: 15%">ID</th>
                        <th style="width: 25;">Response</th>
                        <th style="width: 15%">Event</th>
                        <th style="width: 15%">Payload</th>
                        <th style="width: 15%;">Retry</th>
                        <th style="text-align:right">Created At</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>{{log.id}}</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Status: {{log.response_status}}</li>
                                <li>Response: {{ log.response }}</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">

                                <li>Event: {{log.event}}</li>
                                <li> Object Id: <a routerLink="/employees/{{log.object_id}}/edit"> {{log.object_id}}</a>
                                </li>
                                <li>Object Type : {{log.object_type}}</li>
                            </ul>
                        </td>

                        <td>
                            <br>
                            <button class="btn btn-success btn-sm" (click)="getPayloadModal.show(log.id)">
                                <i class="fa fa-eye"></i> Show</button>
                        </td>

                        <td>
                            <br>
                            <button class="btn btn-info btn-sm" (click)="retrySendWebhook($event, log.id)">
                                <i class="fa fa-repeat"></i> Retry</button>
                        </td>

                        <td style="text-align: right;">
                            {{ log.created_at | date:'short' }}
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>