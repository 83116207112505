<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">


    <ng-template #customItemTemplate let-model="item" let-index="index">
        {{model.name}}
    </ng-template>

    <form [formGroup]="editForm" (ngSubmit)="update()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">{{verification.employee_name}} {{verification.mobile_number}}</h4>

                    <h5 class="modal-title pull-right">
                        <app-employment-create-company #companyCreateModal (onCompanyCreated)="onCompanyCreated()">
                        </app-employment-create-company>

                        <app-employment-revert-form-modal #revertForm></app-employment-revert-form-modal>

                        <a class="btn m-b-xs btn-sm btn-primary" *ngIf="editForm.get('employment_company_id')?.value"
                            (click)="sendEmail(editForm)">
                            Send Mail
                        </a>

                        <a class="btn m-b-xs btn-sm btn-primary" *ngIf="editForm.get('employment_company_id')?.value"
                            (click)="sendFollowUpMail(editForm)">
                            Send Follow Up Mail
                        </a>


                        <a class="btn m-b-xs btn-sm btn-primary" *ngIf="verification.revert_form_id"
                            (click)="revertForm.show(verification)">
                            Revert Form</a>

                        <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="companyCreateModal.show()">
                            <i class="fa fa-plus"></i>Create Company</a>
                    </h5>
                </div>

                <app-employment-form-item *ngIf="verification" (onVerificationUpdated)="onModalUpdated()"
                    [editFormGroup]="editForm" [employmentCompanies]="employmentCompanies" [isSubmitting]="isSubmitting"
                    [verification]="verification"></app-employment-form-item>


                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                            Update
                        </button>
                    </div>

                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>