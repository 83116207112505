import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive'
import { Company, MetaField } from '../../core/models/company.model';
import { MetafieldService } from '../../core/services/metafield.service'
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-company-metafields',
    templateUrl: './company-metafields.component.html',
    styleUrls: [ './company-metafields.component.css' ],
} )
export class CompanyMetafieldsComponent implements OnInit {

    modalRef!: BsModalRef;

    @Input()
    company!: Company;

    form!: FormGroup;

    newMetafield!: string;

    companyId!: number;

    uniqueFields: MetaField[] = [];

    companyFields: MetaField[] = [];

    creating = false;

    loadingAttachedFields: boolean = false;

    loadingAvailableFields: boolean = false;

    constructor (
        private metafield: MetafieldService,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private fb: FormBuilder,
        private notifications: ToastrService
    ) { }

    get name () { return this.form.get( 'name' ) as FormControl }

    ngOnInit (): void {

        this.companyId = +this.route.snapshot.params[ 'id' ];

        this.fetchUniqueFields();

        this.fetchCompanyFields();
    }

    presentInCompany ( field: MetaField ): boolean {

        return this.companyFields.find( i => i.name == field.name ) ? true : false;

    }

    fetchCompanyFields (): void {

        this.loadingAttachedFields = true;

        this.metafield.getCompanyMetafields( this.companyId ).subscribe( ( fields: MetaField[] ) => {

            this.companyFields = fields;

            // sort alphabetically
            this.companyFields.sort( ( a, b ) => a.name.localeCompare( b.name ) );

            this.loadingAttachedFields = false;

        }, ( err: any ) => {

            this.loadingAttachedFields = false;

            this.notifications.error( 'Error', 'Error in loading attached fields' );

        } );

    }

    fetchUniqueFields (): void {

        this.loadingAvailableFields = true;

        this.metafield.getUniqueMetafields().subscribe( ( fields: MetaField[] | undefined ) => {

            this.formatUniqueFields( fields );

            this.loadingAvailableFields = false;

        }, ( err: any ) => {

            this.loadingAvailableFields = false;

            this.notifications.error( 'Error', 'Error in loading available fields' );

        } );

    }

    formatUniqueFields ( fields: MetaField[] = [] ): void {

        const basicFields = [
            'de_type',
            'de_mode_of_transport',
            'alternate_employee_id',
            'sub_entity_name',
            'designation',
            'department',
            'grade',
        ];

        for ( let i = 0; i < basicFields.length; i++ ) {

            const field = basicFields[ i ];

            // add if the field is not already on the list
            if ( !fields.find( i => i.name == field ) ) {

                const metafield = new MetaField();

                metafield.name = field;

                fields.push( metafield );

            }
        }

        // sort alphabetically
        fields.sort( ( a, b ) => a.name.localeCompare( b.name ) );

        this.uniqueFields = fields;

    }

    remove ( field: MetaField ): void {

        this.metafield.deleteField( this.companyId, field.id ).subscribe( ( response: any ) => {

            this.fetchCompanyFields();

        } );

    }

    attach ( field: MetaField ): void {

        this.metafield.addField( this.companyId, field.name ).subscribe( ( field: any ) => {

            this.fetchCompanyFields();

        } );

    }

    openModal ( template: TemplateRef<any> ): void {

        this.form = this.fb.group( {
            name: [ '', [ validateAllowedCharacters( '_' ) ] ],
        } );

        this.modalRef = this.modalService.show( template );

    }

    toSnakeCase ( inputString: string ): string {

        const strArr = inputString.split( ' ' );

        const snakeArr = strArr.reduce( ( acc, val: any ) => {

            return acc.concat( val.toLowerCase() );

        }, [] );

        return snakeArr.join( '_' );
    }

    onNameChanged (): void {

        let name = this.name.value;

        name = name.replace( /[^a-zA-Z\s_]/g, '' );

        name = this.toSnakeCase( name );

        // disable modelview change event to prevent recursive loop
        this.name.setValue( name, { emitViewToModelChange: false } );
    }

    createNewField ( name: FormControl ): void {

        this.creating = true;

        this.metafield.addField( this.companyId, name.value ).subscribe( ( field: any ) => {

            this.creating = false;

            this.modalRef.hide();

            this.uniqueFields.push( field );

            this.companyFields.push( field );

        }, ( err: any ) => {

            this.creating = false;

            this.modalRef.hide();

            this.notifications.error( 'Error', 'Error in creating field' );

        } );
    }
}
