import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { ModalService } from './modal.service';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { DigitalAddressStatusType } from '../models/digital-address-status.model';

@Injectable( {
    providedIn: 'root',
} )
export class DigitalAddressService {

    constructor (
        private api: ApiService,
        private modalService: ModalService
    ) { }

    filter ( params: Params | undefined ) {
        return this.api.get( 'digital-address-invites', params );
    }

    sendFollowUp ( id: number ) {
        return this.api.post( 'digital-address-invite/send-follow-up/' + id, {} );
    }

    getFollowUpDetails ( id: string ) {
        return this.api.get( 'digital-address-invite/follow-up-details/' + id );
    }

    sendInvite ( data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'digital-address-invite', data );
    }

    getDataForExcel ( param: Params ): Observable<any[]> {
        return this.api.get( 'digital-address-invites/download-excel', param );
    }

    toggleDigitalAddressStatus ( id: number ): Observable<{ 'success': boolean }> {
        return this.api.put( 'digital-address-invites/' + id + '/toggle-status', {} );
    }

    getStatuses () {
        return Object.keys( DigitalAddressStatusType ).map( ( key: any ) => {

            const value: string = DigitalAddressStatusType[ key as keyof typeof DigitalAddressStatusType ];

            return {
                id: value,
                name: key,
            }

        } );
    }

    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }
}
