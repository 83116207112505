import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component( {
    selector: 'app-employment-follow-up-mail',
    templateUrl: './employment-follow-up-mail.component.html',
    styleUrls: [ './employment-follow-up-mail.component.css' ],
} )
export class EmploymentFollowUpMailComponent implements OnInit {

    @Input() verification!: FormGroup | any;
    @Input() attachments!: any;
    @Input() employeeDocuments !: any;

    companyId!: number;
    isReadOnly: boolean = false;
    verificationId!: number;
    data!: FormGroup;
    contacts!: any[];
    to!: string;
    cc!: string;
    bcc!: string;
    contents!: string;
    checkedAttachments: any[] = [];
    allAttachments!: any[];

    @Output()
    onEmailSent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private employmentService: EmploymentService,
        private commonService: CommonService,
        private fb: FormBuilder,
        public bsModalRef: BsModalRef,
    ) {
    }

    ngOnInit (): void {

        this.verificationId = this.verification.get( 'id' )?.value;
        this.companyId = this.verification.get( 'employment_company_id' )?.value;

        this.initForm();
        this.fetchContacts();
        this.fetchHtml();
        this.show();
    }

    // Making the field values blank.
    initForm (): void {
        this.data = this.fb.group( {
            contents: [],
            to: [],
            cc: [],
            bcc: [],
            employmentId: [],
            email_attachments: [],
        } );

    }

    show (): void {

        this.data.get( 'employmentId' )?.setValue( this.verificationId );
        // TODO:: need improvement remove consent form from documents as consent is already present in employeeDocument
        this.attachments[ 'documents' ].forEach( ( element: { file_name: string; }, index: any ) => {
            if ( element.file_name === 'consent_form' ) {
                this.attachments[ 'documents' ].splice( index, 1 );
            }
        } );

        this.allAttachments = this.attachments[ 'offers' ].concat(
            this.attachments[ 'documents' ],
            this.attachments[ 'payslips' ],
            this.attachments[ 'relievings' ] );

    }

    fetchContacts () {

        this.employmentService.getContacts( this.verificationId, this.companyId ).subscribe(
            response => {
                this.contacts = response;
            }
        );
    }

    fetchHtml () {
        this.employmentService.getFollowUpMailHtml( this.verificationId ).subscribe(
            ( response: any ) => {
                this.contents = response[ 'content' ];
                this.data.get( 'contents' )?.setValue( response[ 'content' ] );
            } );
    }

    hide (): void {
        this.bsModalRef.hide();
    }

    checkAll ( ev: any ): void {
        if ( ev.target.checked ) {
            for ( let i: number = 0; i < this.allAttachments.length; i++ ) {
                ( document.getElementsByClassName( 'checkbox-all' )[ i ] as HTMLInputElement ).checked = true;
                this.checkedAttachments.push( this.allAttachments[ i ].id );
            }
        } else {
            for ( let i: number = 0; i < this.allAttachments.length; i++ ) {
                ( document.getElementsByClassName( 'checkbox-all' )[ i ] as HTMLInputElement ).checked = false;
                this.checkedAttachments = [];
            }
        }
    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( option: any, data: any ): void {
        if ( data.target.checked ) {
            this.checkedAttachments.push( option );
        } else {
            const index: number = this.checkedAttachments.indexOf( option );
            if ( index !== -1 ) {
                this.checkedAttachments.splice( index, 1 );
            }
        }
        this.data.get( 'email_attachments' )?.setValue( this.checkedAttachments );
    }

    sendMailRequest (): void {

        this.employmentService.sendFollowUpMail( this.data.value ).subscribe(
            ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] );

                this.onEmailSent.emit( true );

                this.hide();
            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to send mail' );

                this.hide();
            }
        );
    }

}
