import { Directive, Input } from '@angular/core';
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from '@angular/forms';

/**
 * Check if length of input is same as provided
 *
 * @param {string} length
 * @returns {ValidatorFn}
 */
export function validateLength ( length: string, blank = false ): ValidatorFn {
    return ( control: AbstractControl ): ValidationErrors | null => {
        const value = control.value;

        if ( value === '0' || value === 0 ) {
            return { validateLength: { value: control.value } };
        }

        if ( !value ) {
            return {};
        }

        if ( blank ) {
            // Checking if length of input is same as provided length
            if (
                ( value && value.toString().length == length ) ||
                control.value == 'blank'
            ) {
                return {};
            }
        }

        // Checking if length of input is same as provided length
        if ( value && value.toString().length == length ) {
            return {};
        }

        return { validateLength: { value: control.value } };
    };
}

@Directive( {
    selector: '[validateLength]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: LengthValidatorDirective,
            multi: true,
        },
    ],
} )
export class LengthValidatorDirective implements Validator {
    @Input()
    length!: string;

    validate ( control: AbstractControl ): { [ key: string ]: any } {
        return validateLength( this.length )( control ) || {};
    }
}
