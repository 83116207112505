import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { ModalService } from './modal.service';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
@Injectable( {
    providedIn: 'root'
} )
export class EpfoService {

    constructor ( private apiService: ApiService,
        private modalService: ModalService ) { }

    filter ( params: Params ): Observable<Pagination> {
        return this.apiService.get( 'epfo', params );
    }

    resend ( id: number ) {
        return this.apiService.get( 'epfo/' + id + '/resend' );
    }

    getStats ( params: Params ) {
        return this.apiService.get( 'epfo/stats', params );
    }

    search ( params: object ) {
        return this.apiService.post( 'epfo/search', params );
    }

    getDataForExcel ( params: Params ) {
        return this.apiService.get( 'epfo/export-excel', params );
    }

    resendAll ( params: Params ) {
        return this.apiService.get( 'epfo/resend-all', params )
    }

    importExcel ( params: object ) {
        return this.apiService.post( 'epfo/import-excel', params );
    }

    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }

}
