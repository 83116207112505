import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { Observable, throwError, of } from 'rxjs';
import { mergeMap, catchError, filter } from 'rxjs/operators'
import { Employee } from '../core/models/employee.model';
import { SearchFormService } from '../core/services/search-form.service';

@Component( {
    selector: 'app-search-form',
    templateUrl: './search-form.component.html',
    styleUrls: [ './search-form.component.css' ],
    providers: [ SearchFormService ],
} )
export class SearchFormComponent {

    employees: Employee[] = [];
    public asyncSelected!: string;
    public typeaheadLoading!: boolean;
    public typeaheadNoResults!: boolean;
    public dataSource: Observable<any>;

    constructor (
        private searchFormService: SearchFormService,
        private router: Router,
    ) {

        this.dataSource = Observable
            .create( ( observer: any ) => {
                // Runs on every search
                observer.next( this.asyncSelected );
            } ).pipe(
                mergeMap( ( query: string ) => this.getEmployeesAsObservable( query ) )
                ,
                catchError( ( error ) => {

                    return this.searchError( error );
                } )
            );
    }

    public getEmployeesAsObservable ( query: string ): Observable<any[]> {

        if ( query ) {
            this.employees = [];

            return this.searchFormService.searchEmployee( query );
        }

        return of();
    }

    public changeTypeaheadLoading ( e: boolean ): void {
        this.typeaheadLoading = e;
    }

    public changeTypeaheadNoResults ( e: boolean ): void {
        this.typeaheadNoResults = e;
    }

    public typeaheadOnSelect ( e: TypeaheadMatch ): void {
        const link = [ 'employees', e.item.id, 'edit' ];
        this.router.navigate( link );
    }

    searchError ( error: any ): Observable<any> {
        if ( error.status !== 401 || error.status !== 403 ) {
            return throwError( error );
        }
        return of();
    }

}
