import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';


import { HttpParams } from '@angular/common/http';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';


@Component( {
    selector: 'app-ocr-filters',
    templateUrl: './ocr-filters.component.html',
} )
export class OcrFiltersComponent implements OnInit {
    @Input() filterParams!: HttpParams;

    all: string = 'all';
    filterFormGroup!: FormGroup; // Form group associated with the filters
    clients!: any[];

    constructor (

        private router: Router,

        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService

    ) {

        this.initForm();

    }

    ngOnInit () {

        this.setDefault();
        this.getAllClientApis();
        this.filterResults();

    }

    initForm (): void {

        this.filterFormGroup = this.fb.group( {
            client_api_id: 'all',
            via: 'all',
            signzy: 'all',
            totalkyc: 'all',
            created_before: 'all',
            created_after: 'all',
            page: 1,
            document_type: 'all',
            id: 'all',
        } );

    }

    setDefault (): void {

        this.filterParams.keys().forEach( ( key ) => {
            const value = this.filterParams.get( key );
            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );

    }

    filterResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    resetForm (): void {

        this.initForm();
        this.filterResults();

    }

    getAllClientApis (): void {

        let params = new HttpParams();
        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe( ( response ) => {
            this.clients = response;
        },
            ( error ) => {
                this.commonService.notifications.error( 'Some Error Occured' );
            } );

    }

}
