import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../core/shared.module';
import { EmployeeListComponent } from '../employee/employee-list/employee-list.component';
import { LotAddMissingVerificationComponent } from './lot-add-missing-verification/lot-add-missing-verification.component';
import { LotCreateComponent } from './lot-create/lot-create.component';
import { LotEditComponent } from './lot-edit/lot-edit.component';
import { LotMergeComponent } from './lot-merge/lot-merge.component';
import { LotShowComponent } from './lot-show/lot-show.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { LotDeletedComponent } from './lot-deleted/lot-deleted.component';
import { LotHistoryComponent } from './lot-history/lot-history.component';

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgxPaginationModule,
        TrimValueAccessorModule,
        MatExpansionModule,
        ReactiveFormsModule,
    ],
    declarations: [
        LotShowComponent,
        LotEditComponent,
        LotCreateComponent,
        EmployeeListComponent,
        LotMergeComponent,
        LotAddMissingVerificationComponent,
        LotDeletedComponent,
        LotHistoryComponent,
    ],

} )
export class LotModule {
}
