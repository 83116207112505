<div class="wrapper-md">
    <div class="panel panel-default">
        <app-pan-search-modal #panSearchModal (onSearch)="onSearch()"></app-pan-search-modal>
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div class="col-md-2">
                        <label class="center-block">Pan Number</label>
                        <input class="form-control" formControlName="pan_number" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Filter by Client</label>
                        <select class="form-control" formControlName="client">
                            <option *ngFor="let client_api of clients" [value]="client_api.id">
                                {{ client_api.owner_name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Sent Via</label>
                        <select class="form-control" formControlName="via">
                            <option value="all">All</option>
                            <option value="crm">CRM</option>
                            <option value="api">API</option>
                            <option value="excel">Excel</option>
                            <option value="dexter">Dexter</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Select Source</label>
                        <select class="form-control" formControlName="driver">
                            <option value="all">All</option>
                            <option *ngFor="let source of panSources" [value]="source.value">
                                {{ source.key }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Status</label>
                        <select class="form-control" formControlName="status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Pending</option>
                            <option value="3">Failure</option>
                        </select>
                    </div>


                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>

                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="generateExcel()" class="btn btn-primary btn-block">
                            Download Excel
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <a [routerLink]="['import-excel']"><button class="btn btn-primary btn-block">
                                Import Excel
                            </button></a>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <a (click)="resendAll()" class="btn btn-primary btn-block">
                            Resend All
                        </a>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Search Pan</label>
                        <a class="btn btn-primary btn-addon" (click)="panSearchModal.show()">
                            <i class="fa fa-search"></i>
                            Search Pan
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>