<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">All Institutes</h1>
</div>

<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Name</label>
                        <input class="form-control" formControlName="name">

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <a class="btn btn-primary btn-addon" (click)="instituteCreateModal.show()">
                            <i class="fa fa-plus"></i>
                            Create Institute
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>
<div class="wrapper-md">
    <div class="row">
        <div class="col-md-12">
            <a class="btn btn-primary btn-addon" (click)="exportExcel()">
                <i class="fa fa-edit"></i>
                Download Excel
            </a>
        </div>
    </div>
</div>
<app-education-create-institute #instituteCreateModal (onInstituteCreated)="onModalUpdated()">
</app-education-create-institute>

<app-education-institutes-edit #editInstitute (onInstituteEdited)="onModalUpdated()"></app-education-institutes-edit>

<app-education-sub-institute-edit (onSubInstituteEdited)="onModalUpdated()" #editSubInstitute>
</app-education-sub-institute-edit>

<app-education-institutes-create-sub-institute #createSubInstitute (onSubInstituteCreated)="onModalUpdated()">
</app-education-institutes-create-sub-institute>

<app-contact-create #contactCreateModal (onContactCreated)="onModalUpdated()"></app-contact-create>
<app-contact-edit #contactEditModal (onContactEdited)="onModalUpdated()"></app-contact-edit>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th style="width:10%;">Institute Details</th>
                        <th style="width:20%;">Contacts</th>
                        <th style="width:30%;">Sub Institutes</th>
                        <th style="width:30%;">Sub Institutes Contacts</th>
                        <th style="width:10%;">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let institute of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i=index">

                        <td>
                            <a class="btn-link" (click)="editInstitute.show(institute)">
                                <strong>{{ institute.name }}</strong>
                            </a>
                            <ul class="list-unstyled">
                                <li *ngIf="institute.website">{{ institute.website }}</li>
                                <li *ngIf="institute.phone_number"><i class="fa fa-phone"></i>
                                    {{ institute.phone_number}}
                                </li>
                                <li *ngIf="institute.email"><i class="fa fa-envelope"></i> {{ institute.email}}</li>
                                <li *ngIf="institute.beneficiary_name">{{ institute.beneficiary_name}}</li>
                                <li>{{ institute.recipient_name}}</li>
                                <li *ngIf="institute.full_address"><i class="fa fa-home"></i>
                                    {{ institute.full_address}}
                                </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled" *ngFor="let contact of institute.contacts">
                                <li>
                                    <a class="btn-link" (click)="contactEditModal.show(contact)">
                                        <strong>{{ contact.name }}</strong>
                                    </a>
                                </li>
                                <li *ngIf="contact.designation"> {{ contact.designation }}</li>
                                <li *ngIf="contact.phone_number"><i class="fa fa-phone"></i> {{ contact.phone_number }}
                                </li>
                                <li *ngIf="contact.primary_email"><i class="fa fa-envelope"></i>
                                    {{ contact.primary_email }}
                                </li>
                                <li *ngIf="contact.secondary_email"><i class="fa fa-envelope"></i> {{
                                    contact.secondary_email }}
                                </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled" *ngFor="let subInstitute of institute.sub_institutes">
                                <li><a class="btn-link" (click)="editSubInstitute.show(subInstitute)">
                                        <strong>{{ subInstitute.name }}</strong></a>
                                </li>
                                <li *ngIf="subInstitute.website">{{ subInstitute.website }}</li>
                                <li *ngIf="subInstitute.email"><i class="fa fa-envelope"></i> {{ subInstitute.email }}
                                </li>
                                <li *ngIf="subInstitute.phone_number"><i class="fa fa-phone"></i> {{
                                    subInstitute.phone_number}}
                                </li>
                                <li *ngIf="subInstitute.beneficiary_name">{{ subInstitute.beneficiary_name}}</li>
                                <li *ngIf="subInstitute.recipient_name">{{ subInstitute.recipient_name}}</li>
                                <li *ngIf="subInstitute.address"><i class="fa fa-home"></i> {{ subInstitute.address}}
                                </li>

                                <li>
                                    <a class="text-main"
                                        (click)="contactCreateModal.show('subInstitute',subInstitute.id)">
                                        <i class="fa fa-plus"></i> Create New Contact</a>
                                </li>
                            </ul>
                        </td>

                        <td>
                            <div *ngFor="let subInstitute of institute.sub_institutes">
                                <ul class="list-unstyled" *ngFor="let contact of subInstitute.contacts">
                                    <li><a class="btn-link" (click)="contactEditModal.show(contact)">
                                            <strong> {{contact.name}}</strong></a>
                                    </li>
                                    <li *ngIf="contact.designation"> {{ contact.designation }}</li>
                                    <li *ngIf="contact.phone_number"><i class="fa fa-phone"></i>
                                        {{ contact.phone_number }}
                                    </li>
                                    <li *ngIf="contact.primary_email"><i class="fa fa-envelope"></i> {{
                                        contact.primary_email }}
                                    </li>
                                    <li *ngIf="contact.secondary_email"><i class="fa fa-envelope"></i> {{
                                        contact.secondary_email }}
                                    </li>
                                </ul>
                            </div>

                        </td>

                        <td>
                            <a class="btn btn-sm btn-block btn-default"
                                (click)="contactCreateModal.show('institute',institute.id)">Create New Contact</a>
                            <a class="btn btn-sm btn-block btn-default" (click)="createSubInstitute.show(institute)">
                                Create SubInstitute
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>