import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { VehicleService } from '../../core/services/vehicle.service';
import { ActivatedRoute } from '@angular/router';
import { KarzaStats } from 'src/app/core/interfaces/ vehicle-rc-logs/karza-stats';
import { ParivahanStats } from 'src/app/core/interfaces/ vehicle-rc-logs/parivan-stats';
import { SignzyStats } from 'src/app/core/interfaces/ vehicle-rc-logs/singz-stats';
import { SmsStats } from 'src/app/core/interfaces/ vehicle-rc-logs/sms-stats';
import { StateStats } from 'src/app/core/interfaces/ vehicle-rc-logs/state-stats';
import { VahanCitizenStats } from 'src/app/core/interfaces/ vehicle-rc-logs/vahan-citizen';
import { VahanStats } from 'src/app/core/interfaces/ vehicle-rc-logs/vahan-stats';

@Component( {
    selector: 'app-vehicle-stats',
    templateUrl: './vehicle-stats.component.html',
    styleUrls: [ './vehicle-stats.component.css' ],
} )
export class VehicleStatsComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    smsStats!: SmsStats;
    vahanStats!: VahanStats;
    stateStats!: StateStats;
    parivahanStats!: ParivahanStats;
    vahanCitizenStats!: VahanCitizenStats;
    karzaStats!: KarzaStats;
    signzyStats!: SignzyStats;

    constructor (
        private route: ActivatedRoute,
        private vehicleService: VehicleService
    ) { }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterStats();
        } );


    }

    filterStats (): void {
        if ( this.filterParams.keys().length ) {
            this.vehicleService.getStats( this.filterParams ).subscribe(
                {
                    next: ( response: any ) => {

                        this.smsStats = response[ 'sms' ];
                        this.vahanStats = response[ 'vahan' ];
                        this.stateStats = response[ 'state' ];
                        this.parivahanStats = response[ 'parivahan' ];
                        this.vahanCitizenStats = response[ 'vahan_citizen' ];
                        this.karzaStats = response[ 'karza' ];
                        this.signzyStats = response[ 'signzy' ];
                    },
                }

            );
        }
    }


}
