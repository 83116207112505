<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">User Roles Overview and Management</h1>
</div>

<div class="wrapper-md">
    <div class="row">
        <div class="col-lg-3">
            <a class="btn btn-primary btn-addon" (click)="companyAssignRoleModal.show()">
                <i class="fa fa-plus"></i>
                Assign New Role
            </a>
        </div>
    </div>
</div>

<app-company-assign-role #companyAssignRoleModal (onRoleAssign)="onModalUpdated()"></app-company-assign-role>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">

        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 15%;">Name</th>
                        <th style="width: 20%;">Email</th>
                        <th style="width: 15%;">Mobile Number</th>
                        <th style="width: 5%;">Level Id</th>
                        <th style="width: 20%;">Designation</th>
                        <th>Role</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>
                            {{user.name}}
                        </td>
                        <td>
                            {{user.email}}
                        </td>
                        <td>
                            {{user.mobile_number}}
                        </td>
                        <td>
                            {{user.level_id}}
                        </td>
                        <td>
                            {{user.designation}}
                        </td>
                        <td>
                            <div class="row" style="margin-top: 8px;" *ngFor="let role of user.roles">
                                <div class="col-md-7">
                                    {{role.name}}
                                </div>
                                <div class="col-md-3">
                                    <button (click)="removeRole(role.id,user.id)" class="btn btn-danger btn-xs"><i
                                            class="fa fa-times"></i> Remove
                                    </button>
                                </div>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>