import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VoterListComponent } from './voter-list/voter-list.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { VoterFiltersComponent } from './voter-filters/voter-filters.component';
import { VoterService } from '../core/services/voter.service';
import { VoterStatsComponent } from './voter-stats/voter-stats.component';
import { VoterResponseComponent } from './voter-response/voter-response.component';
import { VoterSearchModalComponent } from './voter-search-modal/voter-search-modal.component';
import { VoterExcelImportComponent } from './voter-excel-import/voter-excel-import.component';
import { VoterDetailsModalComponent } from './voter-details-modal/voter-details-modal.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        VoterListComponent,
        VoterFiltersComponent,
        VoterStatsComponent,
        VoterResponseComponent,
        VoterSearchModalComponent,
        VoterExcelImportComponent,
        VoterDetailsModalComponent,
    ],
    providers: [
        VoterService,
    ],
} )
export class VoterModule { }
