<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Address Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="address"></app-verification-stats>
</ng-container>

<app-verification-filters verification="address" [filterParams]="filterParams" [vendors]="vendors"
    filterBy="company,lot,verification_status,state,district,pincode,employee_name,created_after,created_before,completion_date_from,completion_date_to,assigned_to,vendor,sent_to_vendor,india_post_id,type_of_address,sent_to_vendor_date_present,work_completed,work_assignment,proof_url,attachments,qa_required,qa_done,track_id,dov_present,verification_mode">
</app-verification-filters>

<app-work-assign-modal #workAssignModal [verification]="'address'" [checkedIds]="ids"
    (onWorkAssigment)="onModalUpdated()"></app-work-assign-modal>

<app-send-to-vendor #sendToVendorModal [verification]="'address'" [checkedIds]="ids"
    (onSentToVendor)="onModalUpdated()"></app-send-to-vendor>

<app-address-edit-modal #editModal (onVerificationUpdated)="onModalUpdated()">
</app-address-edit-modal>

<app-address-submit-cases-modal #submitToAppModal [checkedIds]="ids" (onSubmitToApp)="onModalUpdated()">
</app-address-submit-cases-modal>

<div class="wrapper-md">
    <div class="row">

        <div class="col-md-12">
            <a [appCan]="'editor'" class="btn btn-primary btn-addon" (click)="exportExcel()"
                [appHide]="'address,download.excel'">
                <i class="fa fa-edit"></i>
                Download Excel
            </a>
        </div>
    </div>

</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="row wrapper">
            <div class="col-sm-12" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses"
                            (click)="onChangeStatusButtonClicked(status.id,status.title)">
                            <a class="dropdown-item">{{status.title}}</a>
                        </li>
                    </ul>
                </div>

                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('address')"
                    class="btn btn-danger btn-addon"><i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{user.name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" dropdown [appCan]="'admin'">
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Vendor <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let vendor of vendors"
                            (click)="onChangeVendorButtonClicked(vendor.id)">
                            <a class="dropdown-item">{{vendor.name}}</a>
                        </li>
                    </ul>
                </div>

                <a class="btn btn-primary btn-addon" target="_blank" [href]="[letterUrl]">
                    Print Letters </a>
                <a class="btn btn-primary btn-addon" target="_blank" [href]="[envelopeUrl]">
                    Print Envelopes </a>
                <a class="btn btn-primary btn-addon" target="_blank" (click)="submitToAppModal.show()">
                    Submit to App </a>
                <button class="btn btn-primary" (click)="sendToVendorModal.show()">Send via API</button>

                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa required <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa Done <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)">
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:20%;">Sent Via</th>
                        <th style="width:30%;">Address Type</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let verification of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">
                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{verification.id}}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id,$event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>

                            <a class="btn-link text-main" (click)="showAddress(verification)" target="_blank">
                                <strong>{{ verification.employee_name }}</strong>
                            </a>
                            <br>
                            <a class="btn-link text-main text-semibold"
                                (click)="editModal.show(verification.employee_id,verification.id)">
                                <strong> {{ verification.track_id }}</strong>
                            </a>
                            <br>
                            <a class="btn-link" routerLink="/lots/{{verification.lot_id}}">
                                {{ verification.lot_name }}
                            </a>
                            <br>
                            <a class="btn-link" routerLink="/companies/{{verification.company_id}}">
                                {{ verification.company_name }}
                            </a>
                            <br>
                            <small class="text-muted">{{ verification.created_at }}</small>
                        </td>

                        <td class="{{ verification.color }}">
                            <app-verification-status verification="address" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at"
                                (onVerificationUpdated)="onModalUpdated()">
                            </app-verification-status>
                            <div class="row">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['attachments']">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                            <div class="row" *ngIf="verification.proof_url">
                                <div class="col-md-12">
                                    <a target="_blank" href="{{verification.proof_url}}" class="btn-link">
                                        Proof_url
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td>
                            <app-vendor-dropdown verification="address" [verificationId]="verification.id"
                                [vendorId]="verification.vendor_id" [verificationDate]="verification.completed_at"
                                [sentToVendorDate]="verification.sent_to_vendor_on" [vendorList]="vendors"
                                (onVerificationUpdated)="onModalUpdated()">
                            </app-vendor-dropdown>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong>{{verification.type}}</strong>
                                </li>
                                <li>
                                    {{verification.full_address}}
                                </li>

                                <li>
                                    <strong>{{verification.india_post_id}}</strong>
                                </li>

                                <li *ngIf="verification.digital_address_form">
                                    <strong>Digital Address Invite: </strong>

                                    {{verification.digital_address_form.status | uppercase}}
                                </li>

                                <li *ngFor="let work of verification.work_assigned[0]">

                                    <strong>{{work.title}} is assigned to {{work.assigned_to}}</strong>
                                </li>
                            </ul>
                        </td>
                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id,i)">
                                Delete
                            </button>

                            <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                                <div class="col-md-12">
                                    <button
                                        (click)="onDeleteAttachmentButtonClicked(verification.id,attachment['id'],'delete_attachment_'+attachment['id'])"
                                        class="btn btn-danger btn-xs" id="delete_attachment_{{attachment['id']}}">
                                        Delete {{attachment['file_name']}}
                                    </button>
                                </div>
                            </div>
                            <div class="row" *ngIf="verification.proof_url">
                                <div class="col-md-12">
                                    <button
                                        (click)="onDeleteFileButtonClicked(verification.id,'proof_url','delete_proof_url_'+i)"
                                        class="btn btn-danger btn-xs" id="delete_proof_url_{{i}}">
                                        Delete Proof
                                    </button>
                                </div>
                            </div>

                            <div class="row" style="padding-top:4px" *ngIf="isSendInviteAllowed(verification)">
                                <div class="col-md-12">
                                    <button (click)="onSendInviteButtonClicked(verification)"
                                        class="btn btn-primary btn-xs">
                                        Send Digital Address Invite
                                    </button>
                                </div>
                            </div>

                            <div class="row" style="padding-top:4px" *ngIf="isFollowUpAllowed(verification)">
                                <div class="col-md-12">
                                    <button (click)="onFollowUpButtonClicked(verification)"
                                        class="btn btn-primary btn-xs">
                                        Follow Up Digital Address
                                    </button>
                                </div>
                            </div>

                            <!-- <div class="row" style="padding-top:4px"
                                *ngIf="isChangeDigitalAddressStatusAllowed(verification)">
                                <div class="col-md-12">
                                    <button (click)="changeDigitalAddressStatus(verification)" class="btn btn-xs"
                                        [ngClass]="{ 'btn-danger': verification.digital_address_form.status == 'pending', 'btn-primary': verification.digital_address_form.status != 'pending'}">
                                        Mark Digital Address {{changedStatusText(verification.digital_address_form)}}
                                    </button>
                                </div>
                            </div> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>
