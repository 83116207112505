import { Institute } from './../../../core/models/institute.model';
import { Component, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SubInstitute } from '../../../core/models/sub-institute.model';
import { CommonService } from '../../../core/services/common.service';
import { EducationService } from '../../../core/services/education.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-education-sub-institute-edit',
    templateUrl: './education-sub-institute-edit.component.html',
    styleUrls: [ './education-sub-institute-edit.component.css' ],
} )
export class EducationSubInstituteEditComponent implements OnInit {

    subInstituteId: number = 0;
    subInstitute: SubInstitute = new SubInstitute();
    editForm!: FormGroup;
    isSubmitting: boolean = false;

    @ViewChild( 'subInstituteEditModal', { static: false } ) subInstituteEditModal!: ModalDirective;

    @Output()
    onSubInstituteEdited: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private educationService: EducationService,
        private commonService: CommonService,
        public global: GlobalService,
        private fb: FormBuilder
    ) { }

    ngOnInit (): void {

        this.initForm();
    }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    initForm (): void {
        this.editForm = this.fb.group( {
            id: [],
            name: [ '' ],
            address: [ '' ],
            state_id: [ '' ],
            state_name: [ '' ],
            district_id: [ '' ],
            district_name: [ '' ],
            pincode: [ '' ],
            instruction: [ '' ],
            remarks: [ '' ],
            website: [ '' ],
            type: [ '' ],
            email: [ '' ],
            recipient_name: [ '' ],
            beneficiary_name: [ '' ],
            phone_number: [ '' ],
        } );
    }

    show ( institute: Institute ): void {
        this.subInstituteId = institute.id;
        this.educationService.findSubInstitute( this.subInstituteId )
            .subscribe( response => {
                this.subInstitute = response;
                this.editForm.patchValue( this.subInstitute );
            } );
        this.subInstituteEditModal.show();
    }

    hide (): void {
        this.initForm();
        this.subInstituteEditModal.hide();
    }

    updateSubInstitute (): void {

        this.educationService.updateSubInstitute( this.subInstituteId, this.editForm.value ).subscribe(
            response => {

                this.commonService.notifications.success( 'Success', 'Sub Institute Updated' );
                this.initForm();
                this.hide();
                this.onSubInstituteEdited.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error', 'Unable to update Sub Institute' );
                this.hide();
            }
        );
    }
}
