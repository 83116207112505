<ng-container>

    <app-consent-form [settingFormGroup]="settingFormGroup"></app-consent-form>

    <app-basic-form [settingFormGroup]="settingFormGroup" [hidden]="isDigitalAddress"></app-basic-form>

    <app-identity-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('identity') || isDigitalAddress"></app-identity-form>

    <app-address-form [settingFormGroup]="settingFormGroup" [hidden]="!hasAddress"
        [formType]="formType"></app-address-form>

    <app-education-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('education') || isDigitalAddress"></app-education-form>

    <app-employment-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('employment') || isDigitalAddress"></app-employment-form>

    <app-icr-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('icr') || isDigitalAddress"></app-icr-form>

    <app-pcc-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('pcc') || isDigitalAddress"></app-pcc-form>

    <app-global-db-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('globaldb') || isDigitalAddress"></app-global-db-form>

    <app-document-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('document') || isDigitalAddress"></app-document-form>

    <app-reference-form [settingFormGroup]="settingFormGroup"
        [hidden]="!hasVerification('reference') || isDigitalAddress"></app-reference-form>
</ng-container>