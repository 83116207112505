export class ClientApi {
    id!: number;
    company_name!: string;
    company_id!: number;
    api_status!: number;
    status_name!: string;
    lot_id!: number;
    lot_name!: string;
    owner_id!: number;
    owner_type!: string;
}
