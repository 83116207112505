<div class="panel panel-default">
    <div class="panel-heading">
        Basic Form
    </div>
    <div id="collapse-basic" class="panel-collapse collapse in">
        <div class="panel-body" [formGroup]="basicForm">
            <div class="col-md-12 margin-bottom-10">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"></textarea>
            </div>

            <div class="col-md-12" formGroupName="client_employee_id">

                <div class="col-md-4 padding-left-none">
                    <label><input type="checkbox" id="client_employee_id" formControlName="visible"><span
                            for="client_employee_id"> Client
                            Employee ID </span></label>
                </div>
                <div *ngIf="isClientEmployeeIdChecked">
                    <div class="col-md-offset-2 col-md-3">
                        <input class="" type="radio" formControlName="required" [value]="true">
                        Mandatory
                    </div>

                    <div class="col-md-3">
                        <input class="" type="radio" formControlName="required" [value]="false">
                        Optional
                    </div>
                </div>

            </div>

            <div class="col-md-12" formGroupName="expected_joining_date">

                <div class="col-md-4 padding-left-none">
                    <label><input type="checkbox" id="expected_joining_date" formControlName="visible"><span
                            for="expected_joining_date">
                            Expected Joining Date </span></label>
                </div>
                <div *ngIf="isJoiningDateChecked">
                    <div class="col-md-offset-2 col-md-3">
                        <input class="" type="radio" formControlName="required" [value]="true">
                        <span>Mandatory</span>
                    </div>

                    <div class="col-md-3">
                        <input class="" type="radio" formControlName="required" [value]="false">
                        Optional
                    </div>
                </div>

            </div>

            <div class="col-md-12" formGroupName="resume">

                <div class="col-md-4 padding-left-none">
                    <label><input type="checkbox" id="resume" formControlName="visible" name="resume_visible"
                            [value]="true"><span for="resume"> Resume
                        </span></label>
                </div>
                <div *ngIf="isResumeChecked">
                    <div class="col-md-offset-2 col-md-3">
                        <input class="" type="radio" formControlName="required" [value]="true">
                        Mandatory
                    </div>

                    <div class="col-md-3">
                        <input class="" type="radio" formControlName="required" [value]="false">
                        Optional
                    </div>

                </div>

            </div>

            <div class="col-md-12">
                <h5 class="modal-title pull-left">
                    <a type="button" (click)="addNewRow()" class="btn m-b-xs btn-sm btn-primary btn-addon">
                        <i class="fa fa-plus"></i> Add Field
                    </a>
                </h5>
            </div>

            <br>
        </div>
        <div class="panel-body" [formGroup]="parentForm">
            <div class="col-md-12" *ngIf="fieldsArray.controls.length > 0">

                <div class="panel panel-default">

                    <div class="panel-heading">
                        Form Fields
                    </div>

                    <div class="panel-body">

                        <fieldset formArrayName="fields">
                            <div *ngFor="let itemrow of fieldsArray.controls; let i=index" [formGroupName]="i">


                                <div class="row">
                                    <fieldset class="col-md-6 form-group">
                                        <label class="center-block"> <strong>Field Name </strong>
                                            <span class="required-asterik">*</span></label>
                                        <small class="text-left" style="color: red"
                                            [hidden]="itemrow.get('label')?.valid || itemrow.get('label')?.pristine"
                                            id="label-validator">
                                            Only alphabets allowed
                                        </small>
                                    </fieldset>
                                    <div class="col-md-6 form-group">
                                        <input formControlName="label" class="form-control" id="label">
                                    </div>
                                </div>



                                <div class="row">
                                    <fieldset class="col-md-6 form-group">
                                        <label class="center-block"> <strong>Type </strong> <span
                                                class="required-asterik">*</span></label>
                                        <!-- <small class="text-left" style="color: red"
                                            [hidden]="itemrow.get('placeholder')?.valid || itemrow.get('placeholder')?.pristine"
                                            id="placeholder-validator">
                                            only alphabet allowed
                                        </small> -->
                                    </fieldset>
                                    <div class="col-md-6 form-group">
                                        <!-- <input formControlName="placeholder" class="form-control" id="placeholder"> -->

                                        <select required formControlName="type" class="form-control" id="type"
                                            (click)="onTypeSelect(itemrow)">
                                            <option value="text">Text</option>
                                            <option value="radio">Radio Buttom</option>
                                            <option value="drop_down">Drop Down</option>
                                            <option value="file">File</option>
                                            <option value="date">Date</option>
                                        </select>

                                    </div>
                                </div>

                                <div class="row" *ngIf="itemrow.get('type')?.value == 'text'">
                                    <fieldset class="col-md-6 form-group">
                                        <label class="center-block"> <strong>Placeholder </strong>
                                            <span class="required-asterik">*</span></label>
                                        <small class="text-left" style="color: red"
                                            [hidden]="itemrow.get('placeholder')?.valid || itemrow.get('placeholder')?.pristine"
                                            id="placeholder-validator">
                                            only alphabet allowed
                                        </small>
                                    </fieldset>
                                    <div class="col-md-6 form-group">
                                        <input formControlName="placeholder" class="form-control" id="placeholder"
                                            placeholder="Enter message/ instructions">

                                    </div>
                                </div>

                                <div class="row" *ngIf="itemrow.get('type')?.value == 'file'">
                                    <fieldset class="col-md-6 form-group">
                                        <label class="center-block"> <strong>File Types </strong>
                                            <span class="required-asterik">*</span></label>
                                        <small class="text-left" style="color: red"
                                            [hidden]="itemrow.get('file_types')?.valid || itemrow.get('file_types')?.pristine"
                                            id="file-validator">
                                            please select
                                        </small>
                                    </fieldset>
                                    <div class="col-md-6 form-group">
                                        <app-multi-select [options]="fileTypes" [placeholder]="'Select File Type'"
                                            [controlName]="'file_types'" [formGName]="itemrow"></app-multi-select>
                                    </div>
                                </div>

                                <div class="row"
                                    *ngIf="itemrow.get('type')?.value == 'drop_down' || itemrow.get('type')?.value == 'radio'">
                                    <fieldset class="col-md-6 form-group">
                                        <label class="center-block"> <strong>Select Options (Wirte
                                                options
                                                comma(,) sepereated) </strong> <span
                                                class="required-asterik">*</span></label>
                                        <small class="text-left" style="color: red"
                                            [hidden]="itemrow.get('select_option')?.valid || itemrow.get('select_option')?.pristine"
                                            id="select_option-validator">

                                        </small>
                                    </fieldset>
                                    <div class="col-md-6 form-group">
                                        <input formControlName="select_option" class="form-control" id="select_option"
                                            placeholder="Wirte options comma(,) sepereated">
                                    </div>
                                </div>

                                <div class="row">
                                    <fieldset class="col-md-6 form-group">
                                        <label class="center-block"> <strong>Is Field Required?
                                            </strong></label>
                                        <small class="text-left" style="color: red"
                                            [hidden]="itemrow.get('required')?.valid || itemrow.get('required')?.pristine"
                                            id="mobile-number-validator">
                                            Please select one
                                        </small>
                                    </fieldset>
                                    <div class="col-md-6 form-group">
                                        <div class="col-md-offset-2 col-md-3">
                                            <input class="" type="radio" formControlName="required" [value]="true"
                                                checked> Yes
                                        </div>

                                        <div class="col-md-3">
                                            <input class="" type="radio" formControlName="required" [value]="false"> No
                                            <br />
                                        </div>
                                        <a (click)="deleteRow(i)" class="text-danger">Remove field
                                        </a>
                                    </div>

                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                </div>

                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
