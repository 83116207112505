<div *ngIf="flag" class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">{{ employee.employee_name}} History</h1>
        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a [routerLink]="['/companies', employee.company_id]">{{ employee.company_name }}</a></li>
            <li><a [routerLink]="['/lots', employee.lot_id]">{{ employee.lot_name }}</a></li>
            <li><a [routerLink]="['/employees', employee.id,'edit']"> {{ employee.track_id }}</a></li>
            <li>Created on : {{ employee.created_at }}</li>
        </ul>
    </div>
</div>

<div class="wrapper-md">

    <!-- <div id="page-content"> -->
    <!--<div class="row">-->
    <div class="col-md-6">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header class="header-color" style="background-color: #36486b;">
                    <p class="font">Candidate History</p>
                </mat-expansion-panel-header>

                <div class="stats-collapse-panel">
                    <div class="history">
                        <table class="table table-condensed body-color" *ngIf="flag">

                            <thead>
                                <tr>
                                    <th class="col-md-3">Updated By</th>
                                    <th class="col-md-9">What was changed?</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr *ngFor="let candidate of employee.candidate">
                                    <td>
                                        <b>{{candidate.user}}</b> on

                                        <br /> {{candidate.updated_at}}
                                    </td>
                                    <td>
                                        <p *ngFor="let updated of candidate.updated_item" style="margin: 0 0 5px;">
                                            <b>{{updated.key}}</b> : {{updated.value}} <br />
                                        </p>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="col-md-6">
        <mat-accordion>

            <mat-expansion-panel>
                <mat-expansion-panel-header class="header-color" style="background-color: #36486b;">
                    <p class="font"> Additional Details History</p>
                </mat-expansion-panel-header>

                <div class="stats-collapse-panel">
                    <div class="history">
                        <table class="table table-condensed body-color" *ngIf="flag">

                            <thead>
                                <tr>
                                    <th class="col-md-3">Updated By</th>
                                    <th class="col-md-9">What was changed?</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr *ngFor="let additional of employee.additional_details">
                                    <td>
                                        <b>{{additional.user}}</b> on

                                        <br /> {{additional.updated_at}}
                                    </td>
                                    <td>
                                        <p *ngFor="let updated of additional.updated_item" style="margin: 0 0 5px;">
                                            <b>{{updated.key}}</b> : {{updated.value}} <br />
                                        </p>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <ng-container *ngIf="flag">
        <div *ngFor="let verification of employee.verifications" class="col-md-6">
            <mat-accordion>

                <mat-expansion-panel>
                    <mat-expansion-panel-header class="header-color" style="background-color: #36486b;">
                        <p class="font">{{verification.title}}
                        </p>
                    </mat-expansion-panel-header>
                    <div class="stats-collapse-panel">
                        <div *ngFor="let verificationHistory of verification.histories" class="history">
                            <h4>Verification #{{verificationHistory.key+1}}</h4>
                            <table class="table table-condensed body-color">

                                <thead>
                                    <tr>
                                        <th class="col-md-3">Updated By</th>
                                        <th class="col-md-9">What was changed?</th>
                                    </tr>
                                </thead>

                                <tbody>


                                    <tr *ngFor="let history of verificationHistory.history">
                                        <td>
                                            <b>{{history.user}}</b> on

                                            <br /> {{history.updated_at}}
                                        </td>
                                        <td>

                                            <p *ngFor="let updated of history.updated_item" style="margin: 0 0 5px;">

                                                <span *ngIf="isArray(updated.value); else elseSpan">
                                                    <b>{{updated.key}}</b> : <br>
                                                    <span *ngFor="let value of updated.value">
                                                        <b>{{value.key }} </b> : {{value.value }} <br>
                                                    </span>
                                                    <br />
                                                </span>

                                                <ng-template #elseSpan>

                                                    <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                </ng-template>

                                            </p>

                                        </td>
                                    </tr>

                                </tbody>
                            </table>





                            <div *ngIf="verification.shortTitle == 'icr'">
                                <table class="table table-condensed body-color">
                                    <thead class="icr">
                                        <tr>
                                            <th class="col-md-3"></th>
                                            <th class="col-md-9">
                                                ICR case details history
                                            </th>


                                        </tr>

                                    </thead>
                                    <tbody>

                                        <tr style="border: 3px #333;" class="row"
                                            *ngFor="let cases of verificationHistory.icr_case_details">
                                            <table class="table table-striped">
                                                <tr *ngFor="let history of cases">
                                                    <td class="col-md-3">
                                                        <b>{{history.user}}</b> on

                                                        <br /> {{history.updated_at}}
                                                    </td>
                                                    <td class="col-md-9">
                                                        <p *ngFor="let updated of history.updated_item"
                                                            style="margin: 0 0 5px;">

                                                            <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                        </p>
                                                    </td>
                                                </tr>
                                                <br>
                                                <br>
                                                <hr style="color: burlywood;">
                                            </table>
                                        </tr>

                                    </tbody>
                                </table>

                                <table class="table table-condensed body-color">


                                    <thead class="pcc">
                                        <tr>
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9"><b>VCourt Changes history types</b></div>

                                        </tr>
                                    </thead>


                                    <tbody>
                                        <tr>
                                            <div *ngFor="let history of verificationHistory.court_changes"
                                                class="width">
                                                <div class="divide-line"></div>

                                                <td class="col-md-3">
                                                    <b>{{history.user}}</b> on

                                                    <br /> {{history.updated_at}}
                                                </td>
                                                <td class="col-md-9">
                                                    <p *ngFor="let updated of history.updated_item"
                                                        style="margin: 0 0 5px;">

                                                        <b>Case_ID</b> : {{updated.case_id}} <br />
                                                        <b>Status</b> : {{updated.status}} <br />
                                                    </p>
                                                </td>

                                            </div>
                                        </tr>
                                    </tbody>
                                </table>



                            </div>




                            <div *ngIf="verification.shortTitle == 'pcc'">
                                <table class="table table-condensed body-color">


                                    <thead class="pcc">
                                        <tr>
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9"><b>PCC document history types</b></div>

                                        </tr>
                                    </thead>





                                    <tbody>
                                        <tr *ngFor="let documents of verificationHistory.pcc_documents">
                                            <div *ngFor="let history of documents" class="width">
                                                <div class="divide-line"></div>
                                                <td class="col-md-3">
                                                    <b>{{history.user}}</b> on

                                                    <br /> {{history.updated_at}}
                                                </td>
                                                <td class="col-md-9">
                                                    <p *ngFor="let updated of history.updated_item"
                                                        style="margin: 0 0 5px;">

                                                        <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                    </p>
                                                </td>

                                            </div>

                                        </tr>
                                    </tbody>
                                </table>

                                <table class="table table-condensed body-color">
                                    <thead>
                                        <tr>
                                            <th class="col-md-3"></th>
                                            <th class="col-md-9">
                                                Address Changes History
                                            </th>


                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let history of verificationHistory.address_change">
                                            <td>
                                                <b>{{history.user}}</b> on

                                                <br /> {{history.updated_at}}
                                            </td>
                                            <td>
                                                <p *ngFor="let updated of history.updated_item"
                                                    style="margin: 0 0 5px;">

                                                    <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                </p>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="verification.shortTitle == 'globaldb'">
                                <table class="table table-condensed body-color">
                                    <thead>
                                        <tr>
                                            <th class="col-md-3"></th>
                                            <th class="col-md-9">
                                                Address Changes History
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let history of verificationHistory.address_change">
                                            <td>
                                                <b>{{history.user}}</b> on

                                                <br /> {{history.updated_at}}
                                            </td>
                                            <td>
                                                <p *ngFor="let updated of history.updated_item"
                                                    style="margin: 0 0 5px;">

                                                    <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div *ngIf="verification.shortTitle == 'employment'">
                                <table class="table table-condensed body-color">
                                    <thead>
                                        <tr>
                                            <th class="col-md-3"></th>
                                            <th class="col-md-9">
                                                Revert Form History
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let history of verificationHistory.revert_form">
                                            <td>
                                                <b>{{history.user}}</b> on

                                                <br /> {{history.updated_at}}
                                            </td>
                                            <td>
                                                <p *ngFor="let updated of history.updated_item" class="history-value">

                                                    <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <table class="table table-condensed body-color">
                                <thead>
                                    <tr>
                                        <th class="col-md-9">
                                            Work Assigned History
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let work_assigned of verificationHistory.work_assigned">
                                        <table class="table table-condensed body-color">
                                            <tr *ngFor="let history  of work_assigned">
                                                <td class="col-md-3">
                                                    <b>{{history.user}}</b> on

                                                    <br /> {{history.updated_at}}
                                                </td>
                                                <td class="col-md-9">
                                                    <p *ngFor="let updated of history.updated_item">

                                                        <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </tr>
                                </tbody>
                            </table>

                            <div *ngIf="verification.shortTitle | inArray: ['icr','address','employment']">
                                <table class="table table-condensed body-color">


                                    <thead class="icr">
                                        <tr>
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9"><b>Verification QA</b></div>

                                        </tr>
                                    </thead>


                                    <tbody>
                                        <tr>
                                            <div *ngFor="let history of verificationHistory.verification_qa"
                                                class="width">
                                                <div class="divide-line"></div>

                                                <td class="col-md-3">
                                                    <b>{{history.user}}</b> on

                                                    <br /> {{history.updated_at}}
                                                </td>
                                                <td class="col-md-9">
                                                    <p *ngFor="let updated of history.updated_item"
                                                        style="margin: 0 0 5px;">

                                                        <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                    </p>
                                                </td>

                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="verification.shortTitle == 'identity'">

                                <table class="table table-condensed body-color">
                                    <thead class="pcc">
                                        <tr>
                                            <div class="col-md-3"></div>
                                            <div class="col-md-9"><b>Identity Verification QA</b></div>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <div *ngFor="let history of verificationHistory.verification_qa"
                                                class="width">
                                                <div class="divide-line"></div>

                                                <td class="col-md-3">
                                                    <b>{{history.user}}</b> on

                                                    <br /> {{history.updated_at}}
                                                </td>
                                                <td class="col-md-9">
                                                    <p *ngFor="let updated of history.updated_item">

                                                        <b>{{updated.key}}</b> : {{updated.value}} <br />
                                                    </p>
                                                </td>

                                            </div>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </ng-container>

    <!--</div>-->
</div>