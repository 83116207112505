import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Errors } from '../../core/models/errors.module';
import { CommonService } from '../../core/services/common.service';
import { CompanyService } from '../../core/services/company.service';

@Component( {
    selector: 'app-company-create',
    templateUrl: './company-create.component.html',
} )
export class CompanyCreateComponent implements OnInit {
    createForm!: FormGroup;
    isSubmitting: boolean = false;
    errors: Errors = new Errors();

    constructor (
        private router: Router,
        private companyService: CompanyService,
        private fb: FormBuilder,
        private commonService: CommonService
    ) { }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {

        this.createForm = this.fb.group( {
            company_name: [ '', Validators.required ], company_official_name: [ '', Validators.required ],
            company_city: [ '', Validators.required ], company_prefix: [ '', Validators.required ],
            status: [ 1 ]
        } );
    }

    createCompany (): void {
        this.isSubmitting = true;
        this.companyService.create( this.createForm.value ).subscribe( {

            next: ( data: any ) => {
                this.commonService.notifications.success( 'Company Created Successfully' );
                this.router.navigateByUrl( '/companies' );
            },
            error: ( errors ) => {
                this.errors = errors;
                this.isSubmitting = false;
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        } );
    }
}
