import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { PermitService } from '../core/services/permit.service';
import { SharedModule } from '../core/shared.module';
import { PermitDetailsComponent } from './permit-details/permit-details.component';
import { PermitExcelImportComponent } from './permit-excel-import/permit-excel-import.component';
import { PermitFiltersComponent } from './permit-filters/permit-filters.component';
import { PermitHtmlResponseModalComponent } from './permit-html-response-modal/permit-html-response-modal.component';
import { PermitListComponent } from './permit-list/permit-list.component';
import { PermitResponseModalComponent } from './permit-response-modal/permit-response-modal.component';
import { PermitSearchModalComponent } from './permit-search-modal/permit-search-modal.component';
import { PermitStatsComponent } from './permit-stats/permit-stats.component';

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
    ],
    declarations: [
        PermitListComponent,
        PermitDetailsComponent,
        PermitExcelImportComponent,
        PermitFiltersComponent, PermitResponseModalComponent,
        PermitSearchModalComponent,
        PermitStatsComponent,
        PermitHtmlResponseModalComponent,
    ],
    providers: [
        PermitService,
    ],
} )
export class PermitModule {
}
