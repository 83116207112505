import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { CommonService } from 'src/app/core/services/common.service';
import { FaceRecognitionService } from 'src/app/core/services/face-recognition.service';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';


@Component( {
    selector: 'app-face-recognition-excel-import',
    templateUrl: './face-recognition-excel-import.component.html',
    styleUrls: [ './face-recognition-excel-import.component.css' ],
} )
export class FaceRecognitionExcelImportComponent extends ImportExcelComponent implements OnInit {

    uploadForm: FormGroup;
    override isSubmitting = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    validationMessages: ValidationMessages = {};
    clients!: any[];


    constructor (
        private faceRecognitionService: FaceRecognitionService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected commonService: CommonService,
        protected override fb: FormBuilder
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            'excel': [ '', Validators.required ],
        } );

    }

    setValidationMessages (): void {
        this.validationMessages[ 'first_url' ] = 'Front Url is required';
        this.validationMessages[ 'second_url' ] = 'Second Url is required';
    }

    ngOnInit (): void {
        let params: HttpParams = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

    }

    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {

            excelFormArray: this.fb.array( [] ),
            client_api_id: [ '', Validators.required ],

        } );
    }

    applyValidations (): void {

        this.excelFormArray.controls.forEach( form => {
            form.get( 'first_url' )?.setValidators( [ Validators.required ] );
            form.get( 'first_url' )?.updateValueAndValidity();
            form.get( 'second_url' )?.setValidators( [ Validators.required ] );
            form.get( 'second_url' )?.updateValueAndValidity();
            this.setValidationMessages();
        } );
    }

    importExcel (): void {

        this.faceRecognitionService.importExcel( this.excelForm.value ).subscribe(

            response => {

                if ( response[ 'success' ] ) {

                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ '/face-recognition' ] );

                } else {

                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;

                }

            },
            error => {

                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;

            },
            () => {

                this.isSubmitting = false;

            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }

}
