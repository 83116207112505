export enum verificationTypes {
    address_verifications = 'Address Verification',
    icr_verifications = 'Icr Verification',
    identity_verifications = 'Identity Verification',
    education_verifications = 'Education Verification',
    pcc_verifications = 'Pcc Verification',
    reference_verifications = 'Reference Verification',
    document_verifications = 'Document Verification',
    employment_verifications = 'Employment Verification',
    global_db_verifications = 'Global Database Verification',
}
