import { LotService } from './../../core/services/lot.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Errors } from '../../core/models/errors.module';
import { Verification } from '../../core/models/verification.model';
import { ClientFormsService } from '../../core/services/client-forms.service';
import { CommonService } from '../../core/services/common.service';
import { VerificationService } from '../../core/services/verification.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { Company } from 'src/app/core/models/company.model';
import { ClientFormType } from 'src/app/core/enums/client-form-type.enum';


@Component( {
    selector: 'app-client-forms-create',
    templateUrl: './client-forms-create.component.html',
    styleUrls: [ './client-forms-create.component.css' ],
} )

export class ClientFormsCreateComponent implements OnInit {

    createForm!: FormGroup;
    isSubmitting: boolean = false;
    errors: Errors = new Errors();
    lots: any[] = [];
    verifications: Verification[] = [];
    status: number = 0;

    get companies (): Company[] {
        return this.global.fetchCompanies();
    }

    get formType () {
        return this.createForm.get( 'type' ) as FormControl;
    }

    get consentForm () {
        return this.formSetting.get( 'consent' ) as FormGroup;
    }

    get basicForm () {
        return this.formSetting.get( 'basic' ) as FormGroup;
    }

    get formSetting (): FormGroup {
        return this.createForm.get( 'settings' ) as FormGroup;
    }

    get identityVerificationItem (): FormArray {
        return this.formSetting.get( 'identity.verifications' ) as FormArray;
    }

    get addressVerificationItem (): FormArray {
        return this.formSetting.get( 'address.verifications' ) as FormArray;
    }

    get educationVerificationItem (): FormArray {
        return this.formSetting.get( 'education.verifications' ) as FormArray;
    }

    get employmentVerificationItem (): FormArray {
        return this.formSetting.get( 'employment.verifications' ) as FormArray;
    }

    get icrVerificationItem (): FormArray {
        return this.formSetting.get( 'icr.verifications' ) as FormArray;
    }

    get globalVerificationItem (): FormArray {
        return this.formSetting.get( 'global_db.verifications' ) as FormArray;
    }

    get documentVerificationItem (): FormArray {
        return this.formSetting.get( 'document.verifications' ) as FormArray;
    }

    get referenceVerificationItem (): FormArray {
        return this.formSetting.get( 'reference.verifications' ) as FormArray;
    }

    get pccVerificationItem (): FormArray {
        return this.formSetting.get( 'pcc.verifications' ) as FormArray;
    }

    get fieldsArray (): FormArray {
        return this.createForm.get( 'fields' ) as FormArray;
    }

    get companyId (): FormControl {
        return this.createForm.get( 'company_id' ) as FormControl;
    }

    get emailBody (): FormControl {
        return this.createForm.get( 'email_body' ) as FormControl;
    }

    get lotId (): FormControl {
        return this.createForm.get( 'lot_id' ) as FormControl;
    }

    constructor (
        private router: Router,
        private formService: ClientFormsService,
        private fb: FormBuilder,
        public global: GlobalService,
        private commonService: CommonService,
        private verificationService: VerificationService,
        private lotService: LotService,
    ) { }

    ngOnInit (): void {
        this.initForm();
    }

    initForm (): void {

        this.createForm = this.fb.group( {
            name: [ '', Validators.required ],
            type: [ null, Validators.required ],
            email_subject: [ '' ],
            email_body: [ '' ],
            company_id: [ [], Validators.required ],
            lot_id: [ [], Validators.required ],
            fields: this.fb.array( [] ),
            settings: this.fb.group( {} ),
        } );
    }

    onSubmit (): void {

        this.isSubmitting = true;

        this.formService.create( this.createForm.value ).subscribe( response => {

            this.commonService.notifications.success( 'Form Created' );

            this.router.navigate( [ '/forms' ] );

        }, error => {

            this.commonService.displayValidationErrors( error.errors );

            this.isSubmitting = false;

        } );
    }

    onCompanySelected (): void {

        this.lotId.setValue( null );
        this.lots = [];

        const companyId = this.companyId.value;

        if ( !companyId || companyId == '' ) return;

        this.commonService.getLotsByCompanyId( +companyId ).subscribe(
            response => {
                this.lots = response;
            }
        );
    }

    onCheckboxChange ( event: any, index: number, verificationData: any ): void {

        const list: any[] = this.createForm.value.verificationList;

        if ( event.target.checked === true ) {
            verificationData.index = index;
            list.push( verificationData );
        } else {
            if ( list.length > 1 ) {
                for ( let i: number = 0; i < list.length; i++ ) {
                    if ( list[ i ].index === index ) {
                        list.splice( i, 1 );
                        break;
                    }
                }
            } else {
                list.splice( 0, 1 );
            }

        }
        this.createForm.value.verificationList = list;
    }

    onLotChanged (): void {

        this.verifications = []

        const lotId = this.lotId.value;

        if ( !lotId || lotId == '' ) return;

        this.lotService.find( +lotId ).subscribe(
            response => {

                // get all verificaions object
                this.verificationService.getAllVerifications().then( verifications => {

                    // filter verificaions as per lot verifications
                    this.verifications = verifications.filter(

                        verification => {

                            return response.verifications.includes( verification.lotColumnName );
                        }
                    )

                } );

            }
        );

    }

    onFormTypeChanged () {

        this.addressVerificationItem.clear()

        if ( this.formType?.value == ClientFormType.Invite ) {

            this.addressVerificationItem?.removeValidators( Validators.required )

            this.addressVerificationItem?.updateValueAndValidity();

            return;

        } else if ( this.formType?.value == ClientFormType.DigitalAddress ) {

            this.addressVerificationItem?.addValidators( Validators.required )

            this.addressVerificationItem?.updateValueAndValidity();
        }

        this.fieldsArray.clear();
        this.identityVerificationItem.clear()
        this.educationVerificationItem.clear()
        this.employmentVerificationItem.clear()
        this.icrVerificationItem.clear()
        this.pccVerificationItem.clear()
        this.globalVerificationItem.clear()
        this.documentVerificationItem.clear()
        this.referenceVerificationItem.clear()
    }
}
