export class Webhook {
    id!: number;
    url!: string;
    company_id!: number;
    company_name!: string;
    client_api_id!: number;
    status_id!: number;
    status_name!: string;
    updated_at!: string;
    created_at!: string;
    headers!: any[];
    settings!: any[];
}
