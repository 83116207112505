import { Injectable } from '@angular/core';
import { Pagination } from '../models/pagination.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Employee } from '../models/employee.model';


import { EducationVerification } from '../models/education-verification.model';
import { Institute } from '../models/institute.model';
import { SubInstitute } from '../models/sub-institute.model';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class EducationService {

    constructor (
        private api: ApiService
    ) {
    }

    getEmployees ( params: HttpParams = new HttpParams() ): Observable<Pagination> {
        return this.api.get( 'education/employees', params );
    }

    filter ( params: HttpParams | Params | undefined ): Observable<Pagination> {
        return this.api.get( 'education', params );
    }

    find ( id: number ): Observable<EducationVerification> {
        return this.api.get( 'education/' + id );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'education/' + id );
    }

    create ( id: number ): Observable<Employee> {
        return this.api.get( 'education/' + id + '/add' );
    }

    update ( id: string | number, data: Object | undefined, params: string ): Observable<EducationVerification> {
        return this.api.put( 'education/' + id + '?' + params, data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'education/' + id );
    }

    getAssignedWorkList ( filterParams: Params ): Observable<string[]> {
        return this.api.get( 'education/assigned-work', filterParams );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    getAllSubInstitutes (): Observable<any[]> {
        return this.api.get( 'education/all-sub-institutes' );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    createInstitute ( data: {} ): Observable<any[]> {
        return this.api.post( 'education/institute', data );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    createSubInstitute ( data: {} ): Observable<any[]> {
        return this.api.post( 'education/sub-institute', data );
    }

    getContacts ( id: number ): Observable<any[]> {
        return this.api.get( 'education/institute/' + id + '/contacts' );
    }

    getHtml ( verificationId: number ): Observable<string> {
        return this.api.get( 'education/' + verificationId + '/mailHtml' );
    }

    sendMail ( data: {} ): Observable<any[]> {
        return this.api.post( 'education/sendMail', data );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    getInstitutes ( filterParams: Params ): Observable<Pagination> {
        return this.api.get( 'education/institute', filterParams );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    findInstitute ( instituteId: number ): Observable<Institute> {
        return this.api.get( 'education/institute/' + instituteId );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    updateInstitute ( instituteId: number, data: Object | undefined ): Observable<Institute> {
        return this.api.post( 'education/institute/' + instituteId, data );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    updateSubInstitute ( subInstituteId: number, data: Object | undefined ): Observable<SubInstitute> {
        return this.api.post( 'education/sub-institute/' + subInstituteId, data );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    findSubInstitute ( subInstituteId: number ): Observable<SubInstitute> {
        return this.api.get( 'education/sub-institute/' + subInstituteId );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    getInstituteDataForExcel ( data: HttpParams ): Observable<any> {
        return this.api.get( 'education/institute-excel' );
    }

    upload ( verificationId: number, data: FormData ): Observable<any> {

        return this.api.upload( 'education/' + verificationId + '/upload', data );
    }
}
