import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmploymentCompanyService } from 'src/app/core/services/employment-company.service';
import { FileUploader } from 'ng2-file-upload';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { EmploymentVerification } from '../../core/models/employment-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { EmploymentEmailModalInitialState } from 'src/app/core/models/employment-email-modal-initial-state';

@Component( {
    selector: 'app-employment-edit-modal',
    templateUrl: './employment-edit-modal.component.html',
    styleUrls: [ './employment-edit-modal.component.css' ],
} )
export class EmploymentEditModalComponent {

    isSubmitting: boolean = false;
    editForm!: FormGroup;
    statusChanged: boolean = false;
    uploader: FileUploader = new FileUploader( { url: '' } );
    employeeId!: number;
    verificationId!: number;

    verification: EmploymentVerification = new EmploymentVerification();

    current_date!: string;
    date: Date = new Date();
    created_at: any;

    get employmentCompanies (): any[] {
        return this.employmentCompanyService.companies;
    }

    @ViewChild( 'editModal', { static: false } ) editModal!: ModalDirective;

    @Output()
    onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private employmentService: EmploymentService,
        private employmentCompanyService: EmploymentCompanyService,
        private commonService: CommonService,
        private fb: FormBuilder,
        protected el: ElementRef,
        protected http: HttpClient,
        private bsModalService: BsModalService,
    ) {
        this.initForm();
    }

    initForm (): void {

        this.editForm = this.fb.group( {
            'id': [ '' ],
            'employee_id': [ '' ],
            'completed_at': [ '' ],
            'employee_name': [ '' ],
            'father_name': [ '' ],
            'mobile_number': [ '' ],
            'status_name': [ '' ],
            'status_id': [ '' ],
            'designation': [ '', [ Validators.required ] ],
            'department': [ '', [ Validators.required ] ],
            'joining_date': [ '', [ Validators.required ] ],
            'end_date': [ '', [ Validators.required ] ],
            'employment_type_id': [ '', [ Validators.required ] ],
            'employment_company_name': [ '', [ Validators.required ] ],
            'employment_company_id': [ '', [ Validators.required ] ],
            'country_name': [ '' ],
            'country_id': [ '', [ Validators.required ] ],
            'sent_to_vendor_on': [ '' ],
            'method': [ '' ],
            'client_remarks': [ '' ],
            'payment_reference_number': [ '' ],
            'salary': [ '', [ Validators.required ] ],
            'hr_name': [ '' ],
            'hr_email': [ '', [ validateEmail() ] ],
            'hr_number': [ '' ],
            'reference_title': [ '', [ Validators.required ] ],
            'reference_name': [ '', [ Validators.required ] ],
            'reference_number': [ '', [ Validators.required ] ],
            'reference_email': [ '', [ validateEmail() ] ],
            'previous_employee_id': [ '' ],
            'agency_name': [ '' ],
            'agency_address': [ '' ],
            'agency_contact': [ '' ],
            'revert_form_id': [ '' ],
        } );
        this.current_date = new Date( this.date.getTime() - ( this.date.getTimezoneOffset() * 60000 ) ).toJSON().slice( 0, 10 );
    }


    show ( employeeId: number, verificationId: number ): void {

        this.employeeId = employeeId;
        this.verificationId = verificationId;

        this.employmentService.findByEmployeeId( employeeId ).subscribe(
            response => {
                response.employment_verifications.filter( item => {
                    if ( item.id === verificationId ) {
                        this.verification = item;
                    }
                } );

                this.created_at = this.verification.created_at;

                this.editForm.patchValue( this.verification );

                if ( this.editForm.get( 'completed_at' )?.value && this.commonService.userLevelId() < 4 ) {

                    this.editForm.disable();

                } else {
                    this.editForm.enable();
                }

                this.editModal.show();
            }
        );

        this.getAllCompanies();

        // Subscribe to any Change in Verification Status
        this.editForm.get( 'status_id' )?.valueChanges.subscribe(
            ( statusId: any ) => {
                this.statusChanged = true;
                this.applyValidationsForStatusChange( statusId );
            }
        );
    }

    hide (): void {
        this.editModal.hide();
    }

    getAllCompanies (): void {

        this.employmentCompanyService.populateCache();

    }

    // Call back from the modal to update the list of all companies.
    onCompanyCreated (): void {
        this.getAllCompanies();
    }

    onEmailSent (): void {
        this.onVerificationUpdated.emit( true );
        this.hide();
    }

    update (): void {

        this.employmentService.update( this.editForm.value.id, this.editForm.value, '' ).subscribe(
            response => {
                this.hide();
                this.commonService.notifications.success( 'Verification Updated', 'Verification Updated' );
                this.isSubmitting = false;
                this.onVerificationUpdated.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
            }
        );
    }

    // Call back from the modal to update details
    onModalUpdated (): void {
        this.onVerificationUpdated.emit( true );
    }

    /**
     * Apply Validations when Status is changed
     *
     * @param statusId
     */
    protected applyValidationsForStatusChange ( statusId: any ): void {

        // List of statuses for which validations is required on completion Date.
        const validStatusesForCompletionDate: number[] = [ 1, 3, 4, 13 ];

        const validStatusesForClientRemarks: number[] = [ 1, 4, 7, 18 ];

        // Check if changed status is in our list.
        if ( validStatusesForCompletionDate.indexOf( statusId ) !== -1 ) {
            this.editForm.get( 'completed_at' )?.setValidators( [ Validators.required ] );
            if ( this.statusChanged ) {
                this.editForm.get( 'completed_at' )?.setValue( '' );
            }

        } else {

            if ( this.statusChanged ) {
                this.editForm.get( 'completed_at' )?.setValue( '' );
            }

            this.editForm.get( 'completed_at' )?.setValidators( [] );
        }

        if ( validStatusesForClientRemarks.indexOf( statusId ) !== -1 ) {
            this.editForm.get( 'client_remarks' )?.setValidators( [ Validators.required ] );
        } else {
            this.editForm.get( 'client_remarks' )?.setValidators( [] );
        }
        this.editForm.get( 'completed_at' )?.updateValueAndValidity();
        this.editForm.get( 'client_remarks' )?.updateValueAndValidity();

    }

    sendEmail ( formGroup: FormGroup ) {

        const initialState: EmploymentEmailModalInitialState = {
            verification: formGroup,
            attachments: formGroup.get( 'attachments' )?.value,
            employeeDocuments: null
        };

        const modal = this.employmentService.showSendEmailModal( initialState );

        modal.onHidden.subscribe( () => {
            this.onEmailSent();
        } );
    }

    sendFollowUpMail ( formGroup: FormGroup ) {

        const initialState: EmploymentEmailModalInitialState = {
            verification: formGroup,
            attachments: formGroup.get( 'attachments' )?.value,
            employeeDocuments: null
        };

        const modal = this.employmentService.showSendFollowupEmailModal( initialState );

        modal.onHidden.subscribe( () => {
            this.onEmailSent();
        } );
    }
}

