<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">{{ lot.name }}</h1>
        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a routerLink="/companies">All Companies</a></li>
            <li><a routerLink="/companies/{{lot.company_id}}">{{lot.company_name}}</a></li>
            <li class="active">{{lot.name}}</li>
            <li><a routerLink="/lots/{{lot.id}}/history">History</a></li>
        </ul>
    </div>
</div>

<div class="wrapper-md">
    <div class="col-md-9 margin-bottom">

        <div class="history">
            <table class="table table-condensed body-color">

                <thead>
                    <tr>
                        <th class="col-md-3">Updated By</th>
                        <th class="col-md-9">What was changed?</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let history of lotHistory?.histories">
                        <td>
                            <b>{{history.user}}</b> on

                            <br /> {{history.updated_at}}
                        </td>
                        <td>
                            <p *ngFor="let updated of history.updated_item" style="margin: 0 0 5px;">
                                <ng-container *ngIf="updated.key !='instructions'; else htmlDiv">
                                    <b>{{updated.key}}</b> : {{updated.value}} <br />
                                </ng-container>
                                <ng-template #htmlDiv style="background-color: whitesmoke;">
                                    <b>{{updated.key}}</b> : <br />
                                    <div [innerHTML]="updated.value | safehtml"></div>
                                </ng-template>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
