export const enum IdentityType {
    PanCard = 'pan_card',
    VoterId = 'voter_id',
    Passport = 'passport',
    DrivingLicence = 'driving_licence',
    AadhaarCard = 'aadhaar_card',
    NationalIdentity = 'national_identity',
    Any = 'any',
}

export interface Identity {
    name: string;
    value: IdentityType;
}

export const idDocuments: Identity[] = [
    {
        name: 'Pancard',
        value: IdentityType.PanCard,
    },
    {
        name: 'Aadhaar Card',
        value: IdentityType.AadhaarCard,
    },
    {
        name: 'Voter Id',
        value: IdentityType.VoterId,
    },
    {
        name: 'Driving Licence',
        value: IdentityType.DrivingLicence,
    },
    {
        name: 'Passport',
        value: IdentityType.Passport,
    },
    {
        name: 'National Identity',
        value: IdentityType.NationalIdentity,
    },
    {
        name: 'Any',
        value: IdentityType.Any,
    }
];

export const enum AddressType {
    Current = 'current',
    Permanent = 'permanent',
    Any = 'any',
}

export interface Address {
    name: string;
    value: AddressType;
}

export const Addresses: Address[] = [
    {
        name: 'Current Address',
        value: AddressType.Current,
    }, {
        name: 'Permanent Address',
        value: AddressType.Permanent,
    }, {
        name: 'Any',
        value: AddressType.Any,
    },
];

export const enum EducationType {
    Any = 'any',
}

export interface Education {
    name: string;
    value: EducationType;
}

export const Educations: Education[] = [
    {
        name: 'Any',
        value: EducationType.Any,
    },
];

export const enum EmploymentType {
    Any = 'any',
}

export interface Employment {
    name: string;
    value: EmploymentType;
}

export const Employees: Employment[] = [
    {
        name: 'Any',
        value: EmploymentType.Any,
    },
];

export const enum GlobalType {
    Passport = 'passport',
}

export interface GlobalVerification {
    name: string;
    value: GlobalType;
}

export const GlobalDocuments: GlobalVerification[] = [
    {
        name: 'Passport',
        value: GlobalType.Passport,
    },
];

export const enum DocumentVerificationType {
    Income = 'income',
    VehicleRC = 'vehicle_rc',
    Cibil = 'cibil',
}

export interface DocumentVerification {
    name: string;
    value: DocumentVerificationType;
}

export const DocumentVerificationList: DocumentVerification[] = [
    {
        name: 'Income',
        value: DocumentVerificationType.Income,
    },
    {
        name: 'Vehicle RC',
        value: DocumentVerificationType.VehicleRC,
    },
    {
        name: 'Cibil',
        value: DocumentVerificationType.Cibil,
    },
];

export const enum ReferenceType {
    Any = 'any',
}

export interface Reference {
    name: string;
    value: ReferenceType;
}

export const ReferenceList: Reference[] = [
    {
        name: 'Any',
        value: ReferenceType.Any,
    },
];

export interface Pcc {
    instructions: string;
    required: boolean;
    verifications: PccVerifications[];
}

export interface PccVerifications {
    address_type: AddressType;
    required: boolean;
}

export enum EmploymentVerificationMethod {
    count = 'count',
    years = 'years'
}
