import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { RemarkService } from '../core/services/remark.service';
import { RemarksFilterComponent } from './remarks-filter/remarks-filter.component';
import { RemarksListComponent } from './remarks-list/remarks-list.component';


@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxPaginationModule,
    ],
    declarations: [ RemarksListComponent, RemarksFilterComponent ],
    providers: [ RemarkService ],
} )
export class RemarksModule {
}
