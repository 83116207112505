export enum AddressVerificationStatusType {
    UnderProcess = 0,
    AddressFailure = 1,
    Verified = 2,
    MovedFromAddress = 3,
    DoesNotReside = 4,
    VerifiedUnclaimed = 5,
    CalledConfirmed = 6,
    CandidateNotResponding = 7,
    SwitchOffUnreachable = 8,
    LetterSent = 9,
    LetterDelivered = 10,
    LetterResent = 11,
    CandidateUncooperative = 12,
    EmploymentTerminated = 13,
    InvalidNumberNotProvided = 14,
    SameAsCurrent = 15,
    LanguageProblem = 16,
    AddressNotTraceable = 17,
    SameAsPermanent = 18,
    SentToVendor = 19,
    ToBeSentToVendor = 20,
    AwaitingDetails = 21,
    ClientTerminated = 22,
    NotApplicable = 23,
    LetterReturned = 24,
    VendorReturned = 25,
    NewDetailsReceived = 26,
    WIPInactive = 27,
    EmployeeTerminatedAtVisit = 28,
    EmployeeTerminatedAtCalling = 29,
    InsufficientInactive = 30,
    QAPending = 31,
    MovedFromAddressInactive = 32,
    VerificationStopped = 33,
    QADone = 34,
    LetterToBePrinted = 35,
    SubmittedToApp = 36,
    AppRevertReceived = 37,
    VendorRevertReceived = 38,
    VendorAssignedCalling = 39,
    OnHold = 40,
    NotApplicableCC = 41,
    LevelTwoInsufficiency = 42,
    WrongNumber = 43,
    QAPendingOne = 44,
    QAPendingTwo = 45
}
