import { HttpParams } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WorkAssignedListComponent } from '../../core/components/assigned-work-list.component';
import { DocumentService } from '../../core/services/document.service';
import { FilterService } from 'src/app/core/services/filter.service';

@Component( {
    selector: 'app-document-assigned-work-list',
    templateUrl: './document-assigned-work-list.component.html',
    styleUrls: [ './document-assigned-work-list.component.css' ],
} )
export class DocumentAssignedWorkListComponent extends WorkAssignedListComponent implements OnInit, OnDestroy {


    // Form group associated with the filters
    override filterFormGroup!: FormGroup;
    override filterParams: HttpParams = new HttpParams();
    override assignedWorkList!: any[];
    filtersSubscription!: Subscription;

    constructor (
        protected verificationService: DocumentService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override filterService: FilterService,
    ) {
        super();
    }

    ngOnInit (): void {

        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );

        this.filterResults( this.filterFormGroup.value );
    }


    // Initialize the filters.
    initForm (): void {

        this.filterFormGroup = this.fb.group( {
            created_after: this.getPreviousMonthDate(),
            created_before: '',
            verification_type: 'document',
        } );

    }

    filterResults ( params: Params ): void {
        this.verificationService.getAssignedWorkList( params ).subscribe( response => {
            this.assignedWorkList = response;
        } );
    }


    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }
}
