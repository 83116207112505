import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { LicenceService } from '../../core/services/licence.service';

@Component( {
    selector: 'app-licence-html-response-modal',
    templateUrl: './licence-html-response-modal.component.html',
    styleUrls: [ './licence-html-response-modal.component.css' ],
} )
export class LicenceHtmlResponseModalComponent {

    htmlResponse!: string;
    additionalResponse!: string;
    attachments: any = [];

    @ViewChild( 'licenceHtmlResponse', { static: false } )
    public licenceHtmlResponse!: ModalDirective;

    constructor (
        private licenceService: LicenceService,
        private commonService: CommonService,
    ) { }

    show ( log: any, type: any ) {

        this.licenceService.getHtmlResponse( log.id, type ).subscribe(
            response => {
                this.htmlResponse = response[ 'response' ];
                this.additionalResponse = response[ 'additionalResponse' ];
                this.attachments = response[ 'attachments' ];
                this.licenceHtmlResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    hide () {
        this.licenceHtmlResponse.hide();
    }


}
