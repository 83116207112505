export enum EmploymentVerificationStatusType {
    UnderProcess = 0,
    DiscrepancyFound = 1,
    UnableToValidate = 2,
    Verified = 3,
    Failure = 4,
    NoPastEmployment = 5,
    DocumentRequired = 6,
    EmploymentTerminated = 7,
    VerificationRequestSent = 8,
    FollowUpEmail1Sent = 9,
    FollowUpEmail2Sent = 10,
    FollowUpEmail3Sent = 11,
    FollowUpEmail4Sent = 12,
    VerifiedVerbal = 13,
    NotApplicable = 14,
    PostJoining = 15,
    AwaitingClientApproval = 16,
    VerificationStopped = 17,
    CompanyClosed = 18,
    OnHold = 19,
    CompanyNotResponding = 20,
    UnderReview = 21,
    RevertReceived = 22,
    DataEntryDone = 23,
    InsufficiencyCleared = 24,
    UnableToFindContact = 25,
    LevelTwoInsufficiency = 26,
    ClientTerminated = 27,
    ContactDetailsFound = 28,
    CroppingDone = 29,
    EmployeeTerminatedAtCalling = 30,
    RRAwaitingClarification = 31,
    PaymentApprovalRequired = 32,
    UANRequired = 33,
    UANReceived = 34,
}
