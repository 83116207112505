<div id="page-content">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    Search result for {{this.query}}
                </div>
                <div class="card-block">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Track ID</th>
                                <th>View details</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let employee of employees">
                            <tr>
                                <td>{{employee.name}}</td>
                                <td>{{employee.track_id}}</td>
                                <td>
                                    <button class="btn btn-sm btn-secondary" (click)="showEmployee(employee)"
                                        id="{{employee.id}}">View
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


</div>