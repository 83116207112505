import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CreditReportSources } from 'src/app/core/enums/credit-report-sources.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { CreditReportService } from 'src/app/core/services/credit-report.service';

@Component( {
    selector: 'app-credit-report-search-modal',
    templateUrl: './credit-report-search-modal.component.html',
    styleUrls: [ './credit-report-search-modal.component.css' ]
} )
export class CreditReportSearchModalComponent implements OnInit {

    @ViewChild( 'creditReportSearchModal', { static: false } )
    public creditReportSearchModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();


    isSubmitting = false;
    creditReportSearchForm!: FormGroup;
    creditReportSources = CreditReportSources;

    constructor (
        private fb: FormBuilder,
        private commonService: CommonService,
        private creditReportService: CreditReportService
    ) { }

    ngOnInit (): void {

        this.initForm();

    }

    get name () {
        return this.creditReportSearchForm.get( 'name' ) as FormControl;
    }

    get mobile_number () {
        return this.creditReportSearchForm.get( 'mobile_number' ) as FormControl;
    }

    get pan_number () {
        return this.creditReportSearchForm.get( 'pan_number' ) as FormControl;
    }

    get address () {
        return this.creditReportSearchForm.get( 'address' ) as FormControl;
    }

    get date_of_birth () {
        return this.creditReportSearchForm.get( 'date_of_birth' ) as FormControl;
    }

    get pincode () {
        return this.creditReportSearchForm.get( 'pincode' ) as FormControl;
    }

    initForm (): void {
        this.creditReportSearchForm = this.fb.group( {
            name: [ '', [ Validators.required ] ],
            mobile_number: [ '', [ Validators.required, Validators.pattern( '^((\\+91-?)|0)?[0-9]{10}$' ) ] ],
            pan_number: [ '', [ Validators.required, Validators.pattern( '^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$' ) ] ],
            driver: [ '', [ Validators.required ] ],
            date_of_birth: [ '' ],
            address: [ '' ],
            pincode: [ '' ]
        } );
    }

    show (): void {
        this.creditReportSearchModal.show();
    }

    hide (): void {
        this.initForm();
        this.creditReportSearchModal.hide();
    }

    search (): void {

        this.isSubmitting = true;

        this.creditReportService.search( this.creditReportSearchForm.value ).subscribe( {

            next: () => {
                this.commonService.notifications.success( 'Request Sent Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onSearch.emit( true );
            },

            error: ( error ) => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
                this.onSearch.emit( true );
            }
        } );
    }
}
