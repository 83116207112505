<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block">Rc Number</label>
                        <input class="form-control" formControlName="query" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Status</label>
                        <select class="form-control" formControlName="status">
                            <option value="all">All</option>
                            <option value="QUEUED">QUEUED</option>
                            <option value="SENT">SENT</option>
                            <option value="DELIVERED">DELIVERED</option>
                            <option value="RECEIVED">RECEIVED</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Filter by Client</label>
                        <select class="form-control" formControlName="client_api_id">
                            <option *ngFor="let clientApi of clientApis" [value]="clientApi.id">
                                {{ clientApi.owner_name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>
                </div>

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Sms Status</label>
                        <select class="form-control" formControlName="sms_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <a (click)="resendAll()" class="btn btn-primary btn-block">
                            Resend All
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>