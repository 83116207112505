<div bsModal #epfoResponseModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4>{{source | uppercase}} response</h4>
                </div>
                <div class="panel-body">

                    <fieldset>
                        <pre>{{ response | json }}</pre>

                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>