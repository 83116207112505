import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component( {
    selector: 'app-user-forget-password',
    templateUrl: './user-forget-password.component.html',
} )
export class UserForgetPasswordComponent implements OnInit {

    title: any;
    isSubmitting: any;
    forgetPasswordForm!: FormGroup;

    constructor (
        private fb: FormBuilder,
        private commonService: CommonService,
        private jwtService: JwtService,
        private router: Router,
    ) { }

    ngOnInit (): void {

        this.checkForToken();
        this.inItForm();

    }

    checkForToken () {

        const token = this.jwtService.getToken();

        if ( token ) {

            this.router.navigateByUrl( 'home' );
        }

        this.commonService.setPageTitle( "Forget Password" );
    }

    inItForm () {
        this.forgetPasswordForm = this.fb.group( {
            email: [ "", Validators.required ]
        } );
    }

    submitForm () {

        this.commonService.auth.forgetPassword( this.forgetPasswordForm.value ).subscribe( {
            next: ( response: any ) => {

                if ( response.error ) {

                    this.commonService.notifications.error( response.message );

                } else {

                    this.commonService.notifications.success( 'Please Check you Email Inbox', response.message );
                }

            },
            error: ( error: any ) => {

            }
        } );

    }
}
