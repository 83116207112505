import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { IdentityService } from '../../core/services/identity.service';
import { GlobalService } from 'src/app/core/services/global.service';


@Component( {
    selector: 'app-auto-verify-modal',
    templateUrl: './auto-verify-modal.component.html',
    styleUrls: [ './auto-verify-modal.component.css' ],
} )
export class AutoVerifyModalComponent {


    sources: { id: string, via: string }[] = [
        {
            id: 'sarathi',
            via: 'Via Sarathi',
        },
        {
            id: 'state',
            via: 'Via State',
        },
        {
            id: 'parivahan_sarathi',
            via: 'Via Parivahan Sarathi',
        },
        {
            id: 'parivahan_application',
            via: 'Via Parivahan Application Search',
        },
        {
            id: 'parivahan_simple',
            via: 'Via Parivahan Simple',
        },
        {
            id: 'sarathi_simple',
            via: 'Via Sarathi Simple',
        },
    ];

    @ViewChild( 'autoVerifyModal', { static: false } )
    public autoVerifyModal!: ModalDirective;

    @Input()
    filterParams!: any;

    @Input()
    checkedIds!: [];

    @Input()
    verification!: string;

    selectedSources: { [ key: number ]: string } = {};

    selectedDataType: { [ key: number ]: string } = {};

    constructor (
        private identityService: IdentityService,
        private commonService: CommonService,
        public global: GlobalService,
    ) { }

    get identityVerifications (): any[] {
        return this.global.fetchIdentityDocumentTypes().filter( item => item.sources?.length > 0 );
    }

    show (): void {
        this.autoVerifyModal.show();
    }

    hide (): void {
        this.autoVerifyModal.hide();
    }

    onSourceSelect ( source: any, documentTypeId: number ): void {
        this.selectedSources[ documentTypeId ] = source.target.value;
    }

    onDataTypeSelect ( dataType: any, documentTypeId: number ): void {
        this.selectedDataType[ documentTypeId ] = dataType.target.value;
    }

    autoVerify (): void {
        const payload = {
            sources: this.selectedSources,
            data_type: this.selectedDataType,
        };

        this.identityService.autoVerify( payload, this.filterParams ).subscribe(
            () => {
                this.commonService.notifications.success( 'Success', 'Request to Auto Verify Sent' );
                this.hide();
            },
            () => {
                this.commonService.notifications.error( 'Error', 'Unable to send request' );
                this.hide();
            }
        );

    }


}
