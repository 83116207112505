export class Licence {
    id!: number;
    number!: string;
    dob!: string;

    status!: string;
    name!: string;
    father_name!: string;
    relative_name!: string;
    address!: string;
    rto_name!: string;
    non_transport_validity_from!: string;
    non_transport_validity_upto!: string;
    transport_validity_from!: string;
    transport_validity_upto!: string;

    updated_at!: string;
}
