import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { CompanyRoleService } from 'src/app/core/services/company-role.service';

@Component( {
    selector: 'app-company-update-role',
    templateUrl: './company-update-role.component.html',
    styleUrls: [ './company-update-role.component.css' ]
} )
export class CompanyUpdateRoleComponent implements OnInit {

    companyId!: number;
    users: any;
    constructor ( private roleService: CompanyRoleService,
        private activateRoute: ActivatedRoute,
        private commonService: CommonService
    ) { }

    ngOnInit () {
        this.companyId = +this.activateRoute.snapshot.params[ 'id' ];
        this.fetchCompanyAssignRoleUsers();
    }

    fetchCompanyAssignRoleUsers () {
        this.roleService.getCompanyAssignRoleUsers( this.companyId ).subscribe(
            {
                next: ( response: any ) => {

                    this.users = response;
                }
            }
        );
    }

    onModalUpdated () {
        this.fetchCompanyAssignRoleUsers();
    }

    removeRole ( roleId: number, userId: number ) {
        const param = {
            'company_id': this.companyId,
            'role_id': roleId,
            'user_id': userId,
        }

        this.roleService.unassignRole( param ).subscribe(
            {
                next: ( response: any ) => {

                    if ( response ) {
                        this.fetchCompanyAssignRoleUsers();
                        this.commonService.notifications.success( 'Role Removed Successfully' );
                    }
                }
            }
        );
    }

}
