import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ClientApi } from '../models/client-api.model';
import { Clients } from '../models/client-api-credentials';
import { Pagination } from '../models/pagination.model';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class ClientApiService {

    constructor ( private api: ApiService ) {
    }

    create ( data: Object | undefined ) {
        return this.api.post( 'clients-api', data );
    }

    getClientApisByEnvironment ( params: Params ): Observable<Pagination> {
        return this.api.get( 'clients-api', params );
    }

    getClientApiDetails ( id: number ): Observable<ClientApi> {
        return this.api.get( 'clients-api/' + id );
    }

    update ( data: Object | undefined, id: number ) {
        return this.api.put( 'clients-api/' + id, data );
    }

    toggleStatus ( id: number ): Observable<ClientApi> {
        return this.api.get( 'clients-api/' + id + '/toggle-status' );
    }

    filter ( params: Params | undefined ) {
        return this.api.get( 'request-logs', params );
    }

    getClients ( companyId: number ): Observable<Clients[]> {
        return this.api.get( 'companies/' + companyId + '/get-all-clients' );
    }

    sendEmails ( params: object, clientApiId: number, companyId: number ): Observable<string[]> {
        return this.api.post( 'companies/' + companyId + '/client-api/' + clientApiId + '/send-credentials', params );
    }

    getApiLogsList ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'api-logs', params );
    }

    updateClientApiIps ( id: string, data: Object | undefined ) {
        return this.api.post( 'clients-api/' + id + '/update-ips', data );
    }

    getWhitelistedIps ( id: number ) {
        return this.api.get( 'clients-api/' + id + '/ips' );
    }

}
