import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { AddressListComponent } from './address-list/address-list.component';
import { AddressService } from '../core/services/address.service';
import { AddressEditComponent } from './address-edit/address-edit.component';
import { AddressAssignedWorkListComponent } from './address-assigned-work-list/address-assigned-work-list.component';
import { AddressSubmitCasesModalComponent } from './address-submit-cases-modal/address-submit-cases-modal.component';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
        MatExpansionModule,
    ],
    declarations: [
        AddressListComponent,
        AddressEditComponent,
        AddressAssignedWorkListComponent,
        AddressSubmitCasesModalComponent,
    ],
    providers: [ AddressService ],
} )
export class AddressModule {
}
