import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { InsuranceService } from '../core/services/insurance.service';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { InsuranceDetailsComponent } from './insurance-details/insurance-details.component';
import { InsuranceExcelImportComponent } from './insurance-excel-import/insurance-excel-import.component';
import { InsuranceFiltersComponent } from './insurance-filters/insurance-filters.component';
import { InsuranceHtmlResponseModalComponent } from './insurance-html-response-modal/insurance-html-response-modal.component';
import { InsuranceListComponent } from './insurance-list/insurance-list.component';
import { InsuranceResponseModalComponent } from './insurance-response-modal/insurance-response-modal.component';
import { InsuranceSearchModalComponent } from './insurance-search-modal/insurance-search-modal.component';
import { InsuranceStatsComponent } from './insurance-stats/insurance-stats.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
    ],
    declarations: [
        InsuranceListComponent,
        InsuranceExcelImportComponent,
        InsuranceDetailsComponent,
        InsuranceFiltersComponent,
        InsuranceHtmlResponseModalComponent,
        InsuranceResponseModalComponent,
        InsuranceSearchModalComponent,
        InsuranceStatsComponent,
    ],
    providers: [
        InsuranceService,
    ],
} )
export class InsuranceModule { }
