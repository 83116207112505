import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AadhaarService } from '../../core/services/aadhaar.service';
import { CommonService } from '../../core/services/common.service';
import { AadhaarSources } from '../../core/enums/aadhaar-sources.enum';

@Component( {
    selector: 'app-aadhaar-new-request',
    templateUrl: './aadhaar-new-request.component.html',
    styleUrls: [ './aadhaar-new-request.component.css' ],
} )
export class AadhaarNewRequestComponent implements OnInit {

    @ViewChild( 'aadhaarSearch', { static: false } )
    public aadhaarSearch!: ModalDirective;

    @Output()
    onSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

    isSubmitting: boolean = false;
    newRequestForm!: FormGroup;
    AadhaarSources = AadhaarSources;

    constructor (
        private aadhaarService: AadhaarService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) { }

    ngOnInit (): void {

        this.initForm();

    }

    initForm (): void {

        this.newRequestForm = this.fb.group( {
            aadhaar_number: [ '', [ Validators.required, Validators.pattern( '^[0-9]{12}$' ) ] ],
            driver: [ '', [ Validators.required ] ],
        } );

    }

    show (): void {
        this.aadhaarSearch.show();
    }


    hide (): void {
        this.initForm();
        this.aadhaarSearch.hide();
    }


    search (): void {

        this.isSubmitting = true;

        this.aadhaarService.create( this.newRequestForm.value ).subscribe(
            () => {
                this.commonService.notifications.success( 'Request Sent Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onSearch.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }

}
