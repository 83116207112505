<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">{{company.name}}</h1>
</div>
<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="addFilterParams()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-6 panel-title">
                    All Lots
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <button class="btn btn-primary" (click)="exportExcel()" id="download_excel"> Download Excel</button>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <div class="col-lg-3" *ngIf="allLots">
                <label class="i-checks m-b-none">
                    <input type="checkbox" class="select-lots" (change)="checkAll($event,allLots,'lots')">
                    <i></i>
                    Select All
                </label>
            </div>
            <div class="col-lg-3" *ngFor="let lot of allLots let i = index">

                <label class="i-checks m-b-none">
                    <input type="checkbox" class="checkbox-all-select-lots" value="{{lot.id}}"
                        (change)="getValues(lot.id,$event,'lots')" />
                    <i></i>
                    {{lot.name}}
                </label>

            </div>
        </div>
    </div>
</div>
<div class="wrapper-md">
    <div class="row">

        <!-- verifications -->
        <div class="col-md-3" *ngFor="let verification of allVerifications">
            <div class="panel" style="width:275px;height: 1200px">
                <div class="panel-heading">
                    <h5 class="panel-title"> {{verification.title}}</h5>
                </div>
                <div class="panel-body">

                    <ul class="list-unstyled">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="{{verification.shortTitle}}"
                                    (change)="checkAll($event,verification.entireExcelColumns,verification.shortTitle)">
                                <i></i>
                                Select All
                            </label>
                        </li>
                    </ul>

                    <ul class="list-unstyled" *ngFor="let column of verification.entireExcelColumns">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all-{{verification.shortTitle}}"
                                    value="{{column.id}}"
                                    (change)="getValues(column.id,$event,verification.shortTitle)" />
                                <i></i>
                                {{column.title}}
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- additional -->
        <div class="col-md-3">
            <div class="panel" style="width:275px;height: 990px">
                <div class="panel-heading">
                    <h5 class="panel-title"> Additional Fields</h5>
                </div>
                <div class="panel-body">

                    <ul class="list-unstyled">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="additional"
                                    (change)="checkAll($event,additionalFields,'additional')">
                                <i></i>
                                Select All
                            </label>
                        </li>
                    </ul>

                    <ul class="list-unstyled" *ngFor="let column of additionalFields">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all-additional" value="{{column.id}}"
                                    (change)="getValues(column.id,$event,'additional')" />
                                <i></i>
                                {{column.name}}
                            </label>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

        <!-- basic -->
        <div class="col-md-3">
            <div class="panel" style="width:275px;height: 990px">
                <div class="panel-heading">
                    <h5 class="panel-title"> Basic Fields</h5>
                </div>
                <div class="panel-body">

                    <ul class="list-unstyled">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="basic" (change)="checkAll($event,basicFields,'basic')">
                                <i></i>
                                Select All
                            </label>
                        </li>
                    </ul>

                    <ul class="list-unstyled" *ngFor="let column of basicFields">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all-basic" value="{{column.id}}"
                                    (change)="getValues(column.id,$event,'basic')" />
                                <i></i>
                                {{column.name}}
                            </label>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

        <!-- metafields -->
        <div class="col-md-3" *ngIf="metaFields.length > 0">
            <div class="panel" style="width:275px;height: 990px">
                <div class="panel-heading">
                    <h5 class="panel-title"> Meta Fields</h5>
                </div>
                <div class="panel-body">

                    <ul class="list-unstyled">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="metaFields"
                                    (change)="checkAllMetafields($event,metaFields,'selectedMetafields')">
                                <i></i>
                                Select All
                            </label>
                        </li>
                    </ul>

                    <ul class="list-unstyled" *ngFor="let column of metaFields">
                        <li>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all-metaFields" value="{{column.name}}"
                                    (change)="getValues(column.name, $event, 'selectedMetafields')" />
                                <i></i>
                                {{column.name}}
                            </label>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>