<div bsModal #licenceResponse="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Response</h4>

            </div>
            <div class="modal-body">
                <pre> {{response|json}} </pre>
            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>