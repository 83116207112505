import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { Pagination } from 'src/app/core/models/pagination.model';
import { ClientFormsService } from 'src/app/core/services/client-forms.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalService, ConfirmationModalOptions } from 'src/app/core/services/confirmation-modal.service';
import { Address, Documents, Education, Employment, GlobalDb, Icr, Identities, Pcc, Reference } from 'src/app/core/models/client-forms.model';

@Component( {
    selector: 'app-client-forms-deleted',
    templateUrl: './client-forms-deleted.component.html',
    styleUrls: [ './client-forms-deleted.component.css' ],
} )
export class ClientFormsDeletedComponent implements OnInit, OnDestroy {

    pagination: Pagination = new Pagination();

    filtersSubscription!: Subscription;

    constructor (
        private clientService: ClientFormsService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService,
        private filterService: FilterService,
    ) { }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.getAllDeleted( params );
        } );

        this.filterService.filter();
    }

    getAllDeleted ( params: Params ): void {
        this.clientService.getDeleted( params ).subscribe(
            response => this.pagination = response
        );
    }

    showPage ( page: number ): void {
        this.filterService.filterByPage( page );
    }

    restoreForm ( id: number ): void {

        this.clientService.restore( id ).subscribe( {
            next: ( response: any ) => {

                this.commonService.notifications.success( 'Form restored!', 'Success!' );

                this.filterService.filter();

            },
            error: ( error ) => {

                this.commonService.notifications.error( error );
            }
        } );


    }
    // TODO:: we can move swal this service
    onRestoreFormButtonCLicked ( id: number ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.restoreForm( id ) );

    }

    isIdentitySelected ( identity: Identities ): boolean {
        return this.clientService.isIdentitySelected( identity );
    }


    deleteForm ( id: number ) {
        this.clientService.permanentDelete( id ).subscribe( {
            next: ( response: any ) => {

                this.commonService.notifications.success( 'Form deleted!', 'Success!' );

                this.filterService.filter();

            },
            error: ( error: any ) => {
                this.commonService.notifications.error( error );
            }
        } );
    }

    onDeleteFormButtonClicked ( id: number ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.deleteForm( id ) );
    }

    isCurrentAddressSelected ( address: Address ): boolean {
        return this.clientService.isCurrentAddressSelected( address )
    }

    isPermanentAddressSelected ( address: Address ): boolean {
        return this.clientService.isPermanentAddressSelected( address )
    }

    isAnyAddressSelected ( address: Address ): boolean {
        return this.clientService.isAnyAddressSelected( address )
    }

    isEducationSelected ( education: Education ): boolean {
        return this.clientService.isEducationSelected( education )
    }

    isEmploymentSelected ( employment: Employment ): boolean {
        return this.clientService.isEmploymentSelected( employment )
    }

    isIcrSelected ( icr: Icr ): boolean {
        return this.clientService.isIcrSelected( icr )
    }

    isPccSelected ( pcc: Pcc ): boolean {
        return this.clientService.isPccSelected( pcc )
    }

    isGlobalDbSelected ( global_db: GlobalDb ): boolean {
        return this.clientService.isGlobalDbSelected( global_db )
    }

    isReferenceSelected ( reference: Reference ): boolean {
        return this.clientService.isReferenceSelected( reference )
    }

    isDocumentSelected ( document: Documents ): boolean {
        return this.clientService.isDocumentSelected( document )
    }

    getFormStatus ( formId: any ): string | undefined {
        return this.clientService.getFormStatusLabel( formId );
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

}
