import { Component, Input, OnInit } from '@angular/core';

@Component( {
    selector: 'app-employee-attachments-documents-list',
    templateUrl: './employee-attachments-documents-list.component.html'
} )
export class EmployeeAttachmentsDocumentsListComponent implements OnInit {

    @Input() attachments: any;
    @Input() documents: any;
    constructor () { }

    ngOnInit () {
    }

}
