<div bsModal #instituteEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="editForm" (ngSubmit)="updateInstitute()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Institute</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <input formControlName="id" class="form-control form-control-lg" type="hidden"
                                        ngModel="{{institute.id}}" />
                                    <label class="center-block"> Name</label>
                                    <input formControlName="name" class="form-control form-control-lg"
                                        ngModel="{{institute.name}}" />

                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label>Address </label>
                                    <input formControlName="address" class="form-control form-control-lg"
                                        ngModel="{{institute.address}}" />
                                </fieldset>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-4 form-group">
                                <label> Select State</label>
                                <select class="form-control" formControlName="state_id"
                                    [(ngModel)]="institute.state_id">
                                    <option *ngFor="let state of states" [value]="state.id">
                                        {{state.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4 form-group">
                                <label>Select District</label>
                                <select class="form-control" formControlName="district_id"
                                    [(ngModel)]="institute.district_id">
                                    <option *ngFor="let district of districts |filterByStateId:institute.state_id"
                                        [value]="district.id">
                                        {{district.name}}
                                    </option>
                                </select>
                            </div>


                            <div class="col-md-4 form-group">
                                <label>Pincode </label>
                                <input formControlName="pincode" class="form-control form-control-lg"
                                    ngModel="{{institute.pincode}}" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label>Beneficiary Name </label>
                                    <input formControlName="beneficiary_name" class="form-control form-control-lg"
                                        ngModel="{{institute.beneficiary_name}}" />

                                </fieldset>

                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label>Recipient Name</label>
                                    <input formControlName="recipient_name" class="form-control form-control-lg"
                                        ngModel="{{institute.recipient_name}}" />

                                </fieldset>
                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label>Phone Number </label>
                                    <input formControlName="phone_number" class="form-control form-control-lg"
                                        ngModel="{{institute.phone_number}}" />

                                </fieldset>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label>Email </label>
                                    <input formControlName="email" class="form-control form-control-lg"
                                        ngModel="{{institute.email}}" email />
                                </fieldset>

                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label>Type </label>
                                    <input formControlName="type" class="form-control form-control-lg"
                                        ngModel="{{institute.type}}" />

                                </fieldset>
                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label>Website </label>
                                    <input formControlName="website" class="form-control form-control-lg"
                                        ngModel="{{institute.website}}" />

                                </fieldset>
                            </div>
                        </div>

                        <fieldset class="form-group">
                            <label>Remarks </label>
                            <textarea rows="2" formControlName="remarks" class="form-control form-control-lg"
                                ngModel="{{institute.remarks}}"></textarea>

                        </fieldset>

                        <fieldset class="form-group">
                            <label>Instructions </label>
                            <textarea rows="2" formControlName="instruction" class="form-control form-control-lg"
                                ngModel="{{institute.instruction}}"></textarea>

                        </fieldset>

                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>