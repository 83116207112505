<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">All Authorities</h1>
</div>

<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Type</label>
                        <select class="form-control" formControlName="type">
                            <option value="licence">Driving Licence</option>
                            <option value="pcc">Pcc</option>
                        </select>

                    </div>

                    <div class="col-md-2 form-group">
                        <label> Select State
                            <select class="form-control" formControlName="state_id">
                                <option *ngFor="let state of states " [value]="state.id">
                                    {{state.name}}
                                </option>
                            </select>
                        </label>
                    </div>

                    <div class="col-md-2 form-group">
                        <label> Select District
                            <select class="form-control" formControlName="district_id">
                                <option
                                    *ngFor="let district of districts |filterByStateId:filterFormGroup.get('state_id')?.value"
                                    [value]="district.id">
                                    {{district.name}}
                                </option>
                            </select>
                        </label>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <a class="btn btn-primary btn-addon" (click)="authorityCreateModal.show()">
                            <i class="fa fa-plus"></i>
                            Create Authority
                        </a>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
<app-authority-create-modal #authorityCreateModal (onAuthorityCreated)="onModalUpdated()"></app-authority-create-modal>
<app-authority-edit-contact #authorityContactEditModal (onContactEdited)="onModalUpdated()">
</app-authority-edit-contact>
<app-authority-create-contact #authorityCreateContactModal (onContactCreated)="onModalUpdated()">
</app-authority-create-contact>
<app-identity-authority-edit #authorityEditModal (onAuthorityEdited)="onModalUpdated()"></app-identity-authority-edit>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th style="width:20%;">Authority</th>
                        <th style="width:20%;">Address</th>
                        <th style="width:50%;">Contacts</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let authority of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i=index">

                        <td>
                            <strong>{{ authority.id}} - {{ authority.name }}</strong> <br />
                            {{authority.payment_mode_name}} <br />
                            {{authority.processing_fee}}
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>{{ authority.address }}</li>
                                <li>{{ authority.district_name}}</li>
                                <li>{{ authority.state_name}}</li>
                                <li>{{ authority.pincode}}</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled" *ngFor="let contact of authority.contacts">
                                <li><a class="btn-link" (click)="authorityContactEditModal.show(contact)">
                                        {{ contact.name }}</a>
                                </li>
                                <li><a class="btn-link" (click)="authorityContactEditModal.show(contact)">{{
                                        contact.phone_number }}</a>
                                </li>
                                <li> {{ contact.email }} : {{ contact.designation }}</li>
                                <li> {{ contact.remarks }}</li>
                                <hr>
                            </ul>
                        </td>

                        <td [appCan]="'admin'">
                            <a class="btn btn-sm btn-block btn-default"
                                (click)="authorityCreateContactModal.show(authority)">
                                Create Contact
                            </a>

                            <a class="btn btn-sm btn-block btn-default" (click)="authorityEditModal.show(authority)">
                                Edit Authority
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>