import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubInstitute } from '../models/sub-institute.model';
import { ApiService } from './api.service';
import { CommonService } from './common.service';

@Injectable( {
    providedIn: 'root',
} )
export class SubInstituteService {

    subInstitutes: SubInstitute[] = []
    subInstitutesLoading: boolean = false;

    constructor (
        private api: ApiService,
        private common: CommonService
    ) { }

    populateCache ( force: boolean = false ): void {

        if ( this.subInstitutesLoading ) return;

        if ( force || this.subInstitutes.length === 0 ) {

            this.subInstitutesLoading = true;

            this.getAll().subscribe(

                response => {
                    this.subInstitutes = response;
                    this.subInstitutesLoading = false;
                },
                err => {
                    this.common.notifications.error( 'Error occurred while getting all sub institutes' );
                    this.subInstitutesLoading = false;
                }
            )
        }
    }

    getAll (): Observable<SubInstitute[]> {
        return this.api.get( 'education/all-sub-institutes' )
    }

    create ( data: {} ): Observable<any[]> {
        return this.api.post( 'education/sub-institute', data );
    }

    update ( subInstituteId: number, data: Object | undefined ): Observable<SubInstitute> {
        return this.api.post( 'education/sub-institute/' + subInstituteId, data );
    }

    find ( subInstituteId: number ): Observable<SubInstitute> {
        return this.api.get( 'education/sub-institute/' + subInstituteId );
    }


}
