import { SentryErrorHandler } from './app.sentry-error-handler.service';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ClientFormsModule } from '../app/client-forms/client-forms.module';
import { WorkAssignModalComponent } from '../app/common/work-assign-modal/work-assign-modal.component';
import { SearchModule } from '../app/core/search/search.module';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { AadhaarModule } from './aadhaar/aadhaar.module';
import { AddressModule } from './address/address.module';
import { ApiRequestLogsComponent } from './api-request-logs/api-request-logs.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthorityModule } from './authority/authority.module';
import { BlacklistedModule } from './blacklisted/blacklisted.module';
import { ClientApiModule } from './client-api/client-api.module';
import { ClientModule } from './clients/client.module';
import { DistrictCreateComponent } from './common/district-create/district-create.component';
import { PendingJobsComponent } from './common/pending-jobs/pending-jobs.component';
import { StatusCreateComponent } from './verification-status/status-create/status-create.component';
import { ScreenTimeDirective } from './common/validators/screen-time.directive';
import { UniqueVendorNameValidatorDirective } from './common/validators/unique-vendor-name-validator.directive';
import { VendorCreateComponent } from './common/vendor-create/vendor-create.component';
import { VerificationClientRemarkCreateComponent } from './common/verification-client-remark-create/verification-client-remark-create.component';
import { CompanyModule } from './company/company.module';
import { AdminGuard } from './core/services/admin.guard';
import { ApiService } from './core/services/api.service';
import { AuthGuard } from './core/services/auth-guard.service';
import { BuildFilesUploadService } from './core/services/build-files-upload.service';
import { CommonService } from './core/services/common.service';
import { EnvironmentService } from './core/services/environment.service';
import { FilterService } from './core/services/filter.service';
import { JwtService } from './core/services/jwt.service';
import { ShortcutService } from './core/services/shortcut.service';
import { UploadService } from './core/services/upload.service';
import { UserService } from './core/services/user.service';
import { WebsocketService } from './core/services/websocket.service';
import { SharedModule } from './core/shared.module';
import { CriminalModule } from './criminal/criminal.module';
import { DocumentModule } from './document/document.module';
import { EditVendorModalComponent } from './edit-vendor-modal/edit-vendor-modal.component';
import { EducationModule } from './education/education.module';
import { EmailLogModule } from './email-log/email-log.module';
import { EmployeeModule } from './employee/employee.module';
import { EmploymentModule } from './employment/employment.module';
import { GlobalDbModule } from './global-db/global-db.module';
import { GstModule } from './gst/gst.module';
import { HistoryModule } from './history/history.module';
import { IcrModule } from './icr/icr.module';
import { IdentityModule } from './identity/identity.module';
import { InsuranceModule } from './insurance/insurance.module';
import { AutoAssignAddressComponent } from './auto-assign/auto-assign-address/auto-assign-address.component';
import { AutoAssignDashboardComponent } from './auto-assign/auto-assign-dashboard/auto-assign-dashboard.component';
import { AutoAssignIdentityComponent } from './auto-assign/auto-assign-identity/auto-assign-identity.component';
import { AutoAssignIdentityShowComponent } from './auto-assign/auto-assign-identity-show/auto-assign-identity-show.component';
import { FooterComponent } from './layout/fotter.component';
import { HeaderComponent } from './layout/header.component';
import { SidebarComponent } from './layout/sidebar.component';
import { LicenceModule } from './licence/licence.module';
import { LoginModule } from './login/login.module';
import { NoAuthGuard } from './login/no-auth-guard.service';
import { LotModule } from './lot/lot.module';
import { PanModule } from './pan/pan.module';
import { PccModule } from './pcc/pcc.module';
import { PermitModule } from './permit/permit.module';
import { ReferenceModule } from './reference/reference.module';
import { RemarksModule } from './remarks/remarks.module';
import { SmsModule } from './sms/sms.module';
import { StolenVehicleModule } from './stolen-vehicle/stolen-vehicle.module';
import { UnclaimedVehicleModule } from './unclaimed-vehicle/unclaimed-vehicle.module';
import { UserModule } from './user/user.module';
import { VcourtModule } from './vcourt/vcourt.module';
import { VehicleModule } from './vehicle/vehicle.module';
import { VoterModule } from './voter/voter.module';
import { WebhookModule } from './webhook/webhook.module';
import { WorkModule } from './work/work.module';
import { IdentityWorkCroppingComponent } from './auto-assign/identity-work-cropping/identity-work-cropping.component';
import { IdentityWorkDataEntryComponent } from './auto-assign/identity-work-data-entry/identity-work-data-entry.component';
import { IdentityWorkVerificationComponent } from './auto-assign/identity-work-verification/identity-work-verification.component';
import { IdentityWorkQaComponent } from './auto-assign/identity-work-qa/identity-work-qa.component';
import { ParentWorkComponent } from './auto-assign/parent-work/parent-work.component';
import { WorkLayoutComponent } from './auto-assign/work-layout/work-layout.component';
import { AutoAssignShowWorkComponent } from './auto-assign/auto-assign-show-work/auto-assign-show-work.component';
import { DocumentCropperComponent } from './common/image-cropper/document-cropper.component';
import { DocumentCropperService } from './core/services/document-cropper.service';
import { FilterConfigService } from './core/services/filter-config.service';
import { OcrModule } from './ocr/ocr.module';
import { IdentityWorkValidationComponent } from './auto-assign/identity-work-validation/identity-work-validation.component';
import { IdentityWorkDataEntryQaComponent } from './auto-assign/identity-work-data-entry-qa/identity-work-data-entry-qa.component';
import { IdentityWorkManualValidationComponent } from './auto-assign/identity-work-manual-validation/identity-work-manual-validation.component';
import { FaceRecognitionModule } from './face-recognition/face-recognition.module';
import { AccessRestrictionModule } from './access-restriction/access-restriction.module';
import { InviteModule } from './invite/invite.module';
import { ApiRequestLogsListModule } from './api-request-logs/api-request-logs-list/api-request-logs-list.module';
import { VerificationStatusModule } from './verification-status/verification-status.module';
import { VerificationStatusService } from './core/services/verification-status.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { AuthService } from './core/services/auth.service';
import { DownloadModalComponent } from './common/download-modal/download-modal.component';
import { EpfoModule } from './epfo/epfo.module';
import { ToastrModule } from 'ngx-toastr';
import { tostNotificationConfig } from './config/tost-notification';
import { ProgressBarComponent } from './core/components/progress-bar/progress-bar.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingInterceptor } from './core/loading.interceptor';
import { DownloadListComponent } from './download/download-list/download-list.component';
import { DownloadParamsComponent } from './download/download-params/download-params.component';

import { GlobalDbLogModule } from './global-db-log/global-db-log.module';
import { NewDownloadModalComponent } from './download/new-download-modal/new-download-modal.component';
import { CreditReportModule } from './credit-report/credit-report.module';

const rootRouting: ModuleWithProviders<any> = RouterModule.forRoot( [], { useHash: true } );


@NgModule( {
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        DistrictCreateComponent,
        VendorCreateComponent,
        StatusCreateComponent,
        PendingJobsComponent,
        EditVendorModalComponent,
        UniqueVendorNameValidatorDirective,
        ScreenTimeDirective,
        ApiRequestLogsComponent,
        VerificationClientRemarkCreateComponent,
        AutoAssignAddressComponent,
        AutoAssignDashboardComponent,
        AutoAssignIdentityComponent,
        AutoAssignIdentityShowComponent,
        IdentityWorkCroppingComponent,
        IdentityWorkDataEntryComponent,
        IdentityWorkVerificationComponent,
        IdentityWorkQaComponent,
        ParentWorkComponent,
        WorkLayoutComponent,
        AutoAssignShowWorkComponent,
        DocumentCropperComponent,
        IdentityWorkValidationComponent,
        IdentityWorkDataEntryQaComponent,
        ProgressBarComponent,
        IdentityWorkManualValidationComponent,
        DownloadListComponent,
        DownloadParamsComponent,
        NewDownloadModalComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        LoginModule,
        rootRouting,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        SearchModule,
        EmployeeModule,
        LotModule,
        AppRoutingModule,
        CompanyModule,
        VcourtModule,
        IcrModule,
        IdentityModule,
        WorkModule,
        BrowserModule,
        HttpClientModule,
        DocumentModule,
        EmploymentModule,
        EducationModule,
        GlobalDbModule,
        AddressModule,
        PccModule,
        ReferenceModule,
        ClientModule,
        ClientFormsModule,
        AuthorityModule,
        UserModule,
        VehicleModule,
        WebhookModule,
        ClientApiModule,
        SmsModule,
        LicenceModule,
        AadhaarModule,
        PanModule,
        CriminalModule,
        StolenVehicleModule,
        UnclaimedVehicleModule,
        VoterModule,
        VirtualScrollerModule,
        GstModule,
        EmailLogModule,
        PermitModule,
        HistoryModule,
        RemarksModule,
        BlacklistedModule,
        InsuranceModule,
        MatExpansionModule,
        HttpClientModule,
        FaceRecognitionModule,
        AccessRestrictionModule,
        OcrModule,
        InviteModule,
        ApiRequestLogsListModule,
        VerificationStatusModule,
        EpfoModule,
        GlobalDbLogModule,
        CreditReportModule,
        ToastrModule.forRoot( tostNotificationConfig ),
        MatProgressBarModule,
    ],
    exports: [],
    providers: [
        UserService,
        ApiService,
        JwtService,
        AuthGuard,
        NoAuthGuard,
        AdminGuard,
        CommonService,
        WebsocketService,
        BuildFilesUploadService,
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        ShortcutService,
        FilterService,
        EnvironmentService,
        UploadService,
        DocumentCropperService,
        FilterConfigService,
        VerificationStatusService,
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        GoogleAnalyticsService,
    ],
    bootstrap: [
        AppComponent,
    ],

    entryComponents: [
        WorkAssignModalComponent,
        DownloadModalComponent,
    ],
} )
export class AppModule {

    constructor () { }

}
