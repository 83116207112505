import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Employee } from '../models/employee.model';
import { ApiService } from './api.service';

import { Lot } from '../models/lot.model';

import { NewPagination, Pagination } from '../models/pagination.model';
import { Client } from '../models/client.model';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';


@Injectable( {
    providedIn: 'root'
} )
export class LotService {

    constructor ( private api: ApiService ) { }

    getAll (): Observable<NewPagination<Lot>> {

        return this.api.get( 'lots' );

    }

    getByCompanyId ( companyId: number, params: Params | HttpParams | undefined ): Observable<NewPagination<Lot>> {

        return this.api.get( 'companies/' + companyId, params );

    }

    filter ( companyId: number, params: string ) {

        return this.api.get( 'companies/' + companyId + '?' + params );

    }

    filterByCompanyId ( companyId: number ): Observable<any[]> {

        return this.api.get( 'lots/company/' + companyId );

    }

    find ( lotId: number ): Observable<Lot> {

        return this.api.get( 'lots/' + lotId );

    }

    stats ( lotId: number ): Observable<any[]> {

        return this.api.get( 'lots/' + lotId + '/stats' );

    }

    create ( values: Object ) {

        return this.api.post( 'lots', values );

    }

    update ( id: number, values: Object ) {

        return this.api.put( 'lots/' + id, values );

    }

    importEmployees ( lotId: number, values: Object ): Observable<Lot> {

        return this.api.post( 'lots/' + lotId + '/import-employees', values );

    }

    deleteLot ( lotId: number ): Observable<any[]> {

        return this.api.delete( 'lots/' + lotId );

    }

    getDeleted ( id: number ): Observable<NewPagination<Lot>> {
        return this.api.get( 'companies/' + id + '/lots-deleted' );
    }

    restoreLot ( lotId: number ): Observable<any[]> {

        return this.api.get( 'lots/' + lotId + '/restore' );

    }

    permanentDeleteLot ( lotId: number ): Observable<any[]> {

        return this.api.delete( 'lots/' + lotId + '/permanent-delete' );

    }

    getEmployees ( lotId: number ): Observable<Employee[]> {

        return this.api.get( 'lots/' + lotId + '/employees' );

    }

    lotMerge ( lotId: number, values: Object ) {

        return this.api.post( 'lots/' + lotId + '/merge', values );

    }

    addMissingVerification ( lotId: number, verificationType: string ) {

        return this.api.get( 'lots/' + lotId + '/add-missing-verification?type=' + verificationType );

    }

    importUberEmployees ( lotId: number, values: Object ): Observable<Lot> {

        return this.api.post( 'lots/' + lotId + '/import-uber', values );

    }

    getClients ( lotId: string ): Observable<Client[]> {

        return this.api.get( 'lots/' + lotId + '/clients' );

    }

    getHistory ( lotId: number ): Observable<Lot> {
        return this.api.get( 'lots/' + lotId + '/history' );
    }

    /**
     * @param companyId
     */
    getLotsByCompanyId ( companyId: number ): Observable<any[]> {
        return this.api.get( 'lots/list?company_id=' + companyId );
    }
}
