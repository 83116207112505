import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { IDocumentAction, ICandidateDocument } from 'src/app/core/models/candidate-document.modal';
import { Employee } from 'src/app/core/models/employee.model';
import { CandidateDocumentService } from 'src/app/core/services/candidate-document.service';
import { CommonService } from 'src/app/core/services/common.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { VerificationService } from 'src/app/core/services/verification.service';


@Component( {
    selector: 'document-action-modal',
    templateUrl: './document-action-modal.component.html',
    styleUrls: [ './document-action-modal.component.css' ],
} )
export class DocumentActionModalComponent implements OnInit {

    @ViewChild( 'documentActionModal', { static: false } ) documentActionModal!: ModalDirective;

    @Input() documentAction!: IDocumentAction;

    @Input() employee!: Employee;

    selfFormGroup!: FormGroup;

    verificationLabelList: { verificationId: string; verificationLabel: string; }[] = [];

    constructor (
        private employeeService: EmployeeService,
        private documentService: CandidateDocumentService,
        private commonService: CommonService,
        private verificationService: VerificationService,
    ) { }

    ngOnInit (): void {

        this.selfFormGroup = new FormGroup( {
            imageUrl: new FormControl( null, [ Validators.required ] ),
            verificationLabel: new FormControl( null, [ Validators.required ] ),
            verificationId: new FormControl( null, [ Validators.required ] ),
            typeLabel: new FormControl( null, [ Validators.required ] ),
            typeId: new FormControl( null, [ Validators.required ] ),
            uploaded: new FormControl( true, [ Validators.required ] ),
            forceUpload: new FormControl( true, [ Validators.required ] ),
            documentLabel: new FormControl( null, [ Validators.required ] ),
            documentType: new FormControl( null, [ Validators.required ] ),
            table: new FormControl( null, [ Validators.required ] ),
            documentId: new FormControl( null, [ Validators.required ] ),
            fromField: new FormControl( null, [ Validators.required ] ),
        } );

        if ( this.employee ) {
            this.verificationLabelList = this.verificationService.getEmployeeVerificationLabels( this.employee );
        }

    }

    get verificationId (): AbstractControl {

        return this.selfFormGroup.get( 'verificationId' ) as AbstractControl;
    }

    get typeLabelList (): ICandidateDocument[] {

        const typeLabels = this.documentService.getTypeLabelList( this.verificationId.value );

        return this.uniqueVerificationIds( typeLabels );

    }

    get typeId (): AbstractControl {

        return this.selfFormGroup.get( 'typeId' ) as AbstractControl;
    }

    uniqueVerificationIds ( document: ICandidateDocument[] ): ICandidateDocument[] {

        return document.filter( ( a, i ) => document.findIndex( ( s ) => a.typeId == s.typeId ) === i )

    }

    get documentLabelList (): ICandidateDocument[] {

        const documents: ICandidateDocument[] = this.documentService.documentList( this.verificationId.value, this.typeId.value );

        if ( this.action == 'Duplicate' ) {

            return documents.filter( document => document.imageUrl === null );
        }

        if ( this.action == 'Swap' ) {

            const documentItem = this.documentService.documentItemList( this.verificationId.value, this.typeId.value );

            return documentItem.filter( document => ( document.imageUrl !== null && document.table == 'self' ) );
        }

        if ( this.action == 'Move' ) {

            return documents.filter( document => ( document.documentId == this.typeId.value ) );
        }

        return documents;

    }

    get documentId (): AbstractControl {

        return this.selfFormGroup.get( 'documentId' ) as AbstractControl;
    }

    get action (): string {

        return this.documentAction?.actionName;

    }

    get index (): number {

        return this.documentAction?.index;

    }

    get fromField (): ICandidateDocument {

        return this.documentAction?.document;

    }

    get imageUrl (): AbstractControl {

        return this.selfFormGroup.get( 'imageUrl' ) as AbstractControl

    }

    show (): void {

        this.selfFormGroup.reset();

        this.documentActionModal.show();

    }

    get verificationLabel (): AbstractControl {

        return this.selfFormGroup.get( 'verificationLabel' ) as AbstractControl;
    }

    get typeLabel (): AbstractControl {

        return this.selfFormGroup.get( 'typeLabel' ) as AbstractControl;
    }

    get table (): AbstractControl {

        return this.selfFormGroup.get( 'table' ) as AbstractControl;
    }

    get documentType (): AbstractControl {

        return this.selfFormGroup.get( 'documentType' ) as AbstractControl;
    }

    get documentLabel (): AbstractControl {

        return this.selfFormGroup.get( 'documentLabel' ) as AbstractControl;
    }

    get uploaded (): AbstractControl {

        return this.selfFormGroup.get( 'uploaded' ) as AbstractControl;
    }

    get forceUpload (): AbstractControl {

        return this.selfFormGroup.get( 'forceUpload' ) as AbstractControl;
    }

    onModalClose (): void {

        this.documentActionModal.hide();

    }

    onDocumentChange (): void {

        const document: ICandidateDocument = this.documentId.value;

        this.verificationLabel.setValue( document.verificationLabel );

        this.typeLabel.setValue( document.typeLabel );

        this.table.setValue( document.table );

        this.documentType.setValue( document.documentType );

        this.documentLabel.setValue( document.documentLabel );

        this.uploaded.setValue( true );

        this.forceUpload.setValue( true );

        this.selfFormGroup.get( 'fromField' )?.setValue( this.fromField );

    }

    onDuplicate (): void {

        this.imageUrl.setValue( this.fromField.imageUrl );

        this.employeeService.documentDuplicate( this.employee.id, this.selfFormGroup.value ).subscribe( ( response: any ) => {

            if ( response.success ) {

                this.documentService.documents.push( response.data );

                this.documentService.addItem( response.data );

                this.documentActionModal.hide();

                this.commonService.notifications.success( 'Success', 'Duplicate created successfully' );
            }

        } );
    }

    onMove (): void {

        this.imageUrl.setValue( this.fromField.imageUrl );

        this.employeeService.documentMove( this.employee.id, this.selfFormGroup.value ).subscribe( ( response: any ) => {

            if ( response.success ) {

                if ( this.table.value != 'self' ) {

                    this.documentService.documents.push( response.data );
                }

                this.documentService.removeDocument( this.index );

                this.documentService.addItem( response.data );

                this.commonService.notifications.success( 'Success', 'Document moved successfully' );

                this.documentActionModal.hide();
            } else {

                this.documentActionModal.hide();

                this.commonService.notifications.error( 'Error', response.message );

            }
        } )
    }

    onSwap (): void {

        this.imageUrl.setValue( this.fromField.imageUrl );

        this.employeeService.documentSwap( this.employee.id, this.selfFormGroup.value ).subscribe( ( response: any ) => {

            if ( response.success ) {

                this.documentService.swapDocument( this.index, this.documentId.value );

                this.commonService.notifications.success( 'Success', 'Document swaped successfully' );

                this.documentActionModal.hide();
            } else {

                this.documentActionModal.hide();

                this.commonService.notifications.error( 'Error', response.message );
            }

        } )
    }
}
