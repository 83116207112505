<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">


    <ng-template #customItemTemplate let-model="item" let-index="index">
        {{model.name}}
    </ng-template>

    <form [formGroup]="editForm" (ngSubmit)="update()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">{{verification.employee_name}} - {{verification.type}}</h4>
                </div>

                <app-address-common-edit *ngIf="verification" (onVerificationUpdated)="onModalUpdated()"
                    [editFormGroup]="editForm" [isSubmitting]="isSubmitting" [vendors]="vendors" [languages]="languages"
                    [verification]="verification" [current_date]="current_date" [created_at]="created_at">
                </app-address-common-edit>


                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>