import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebhookService } from '../../core/services/webhook.service';
import { Pagination } from '../../core/models/pagination.model';
import { ActivatedRoute, NavigationExtras, Router, ParamMap, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';


@Component( {
    selector: 'app-webhook-logs-list',
    templateUrl: './webhook-logs-list.component.html',
    styleUrls: [ './webhook-logs-list.component.css' ],
} )
export class WebhookLogsListComponent implements OnInit, OnDestroy {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    webhookId!: number;
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    loading!: boolean;
    payload: any;
    settings: any;
    objects: any;
    filtersSubscription!: Subscription;

    constructor (
        private webhookService: WebhookService,
        private commonService: CommonService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private filterService: FilterService,
    ) { }

    ngOnInit () {

        this.initForm();
        this.getWebhookId();
        this.getWebhookSettings();
        this.getRelationMorphMap();

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );

        } );

        this.filterService.filter();

    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();

    }

    initForm (): void {

        const myDate = new Date();

        // Change it so that it is 15 days in the past.
        const pastDate = myDate.getDate() - 15;

        myDate.setDate( pastDate );

        this.filterFormGroup = this.fb.group( {
            created_after: 'all',
            created_before: 'all',
            event: '',
            response_status: '',
            object_type: '',
        } );

    }

    filterResults ( params: Params ): void {

        this.webhookService.logs( this.webhookId, params ).subscribe(

            response => {

                this.pagination = response;

            } );

    }

    getWebhookId (): void {

        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.webhookId = +params[ 'id' ];

        } );

    }

    getWebhookSettings (): void {

        this.webhookService.getSettingsByWebhookId( this.webhookId ).subscribe(

            response => {

                this.settings = response;

            } );

    }

    getRelationMorphMap (): void {

        this.commonService.getRelationMorpMap().subscribe(

            response => {

                this.objects = response;

            } );

    }


    showPage ( page: number ) {

        this.filterService.filterByPage( page );
    }

    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    retrySendWebhook ( event: MouseEvent, id: number ) {

        event.preventDefault();

        this.webhookService.retrySendWebhook( id ).subscribe(

            ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] ),

                    this.filterService.filter()

            } )

    }

    retryQueriedWebhook (): void {

        this.webhookService.retryAllWebhooks( this.webhookId, this.filterFormGroup.value ).subscribe(

            ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] ),

                    this.router.navigate( [ '/home' ] )

            } );

    }

}
