import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressService } from 'src/app/core/services/address.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-address-submit-cases-modal',
    templateUrl: './address-submit-cases-modal.component.html',
    styleUrls: [ './address-submit-cases-modal.component.css' ],
} )
export class AddressSubmitCasesModalComponent {

    isSubmitting: boolean = false;
    submitToAppForm!: FormGroup;

    @ViewChild( 'submitToAppModal', { static: false } )
    public submitToApp!: ModalDirective;

    @Output()
    onSubmitToApp: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    checkedIds!: any[];

    constructor (
        private addressService: AddressService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) {
        this.initForm();
    }

    initForm (): void {
        this.submitToAppForm = this.fb.group( {
            email: [ '', Validators.required ],
            password: [ '', Validators.required ],
        } );

    }

    show (): void {
        this.submitToApp.show();
    }


    hide (): void {
        this.initForm();
        this.submitToApp.hide();
    }


    search (): void {
        this.isSubmitting = true;

        const data: any = this.submitToAppForm.value;

        data[ 'ids' ] = this.checkedIds;

        this.addressService.sendToAddressApp( data ).subscribe(
            response => {
                this.commonService.notifications.success( 'Request Sent Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onSubmitToApp.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }
}
