<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
<div bsModal #authorityCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="authorityCreateForm" (ngSubmit)="createAuthority()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Authority</h4>

                </div>
                <div class="panel-body">
                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label class="center-block"> Authority Name</label>
                                    <app-multi-select [options]="authorities" [placeholder]="'Select Authority Name'"
                                        [controlName]="'name'" [formGName]="authorityCreateForm"></app-multi-select>
                                </fieldset>
                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label>Select State </label>

                                    <input formControlName="state_name" [typeahead]="states" [typeaheadMinLength]="0"
                                        typeaheadOptionField="name" placeholder="Select State"
                                        [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                        (typeaheadOnSelect)="OnTypeheadSelect($event,'state')">
                                </fieldset>
                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label class="center-block"> District </label>

                                    <input formControlName="district_name"
                                        [typeahead]="districts | filterByStateId:authorityCreateForm.get('state_id')?.value"
                                        [typeaheadMinLength]="0" typeaheadOptionField="name"
                                        placeholder="Select District" [typeaheadItemTemplate]="customItemTemplate"
                                        class="form-control" (typeaheadOnSelect)="OnTypeheadSelect($event,'district')">
                                </fieldset>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block"> Address
                                        <textarea formControlName="address" class="form-control" rows="2"></textarea>
                                    </label>
                                </fieldset>
                            </div>

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block"> Remarks
                                        <textarea formControlName="remarks" class="form-control" rows="2"></textarea>
                                    </label>
                                </fieldset>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label class="center-block"> Pincode </label>
                                    <input formControlName="pincode" class="form-control">
                                </fieldset>

                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label> Payment Mode </label>

                                    <app-multi-select [options]="paymentModes" [placeholder]="'Select  Payment Mode'"
                                        [controlName]="'payment_mode_id'"
                                        [formGName]="authorityCreateForm"></app-multi-select>

                                </fieldset>
                            </div>

                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label class="center-block"> Processing Fee</label>
                                    <input formControlName="processing_fee" class="form-control">
                                </fieldset>
                            </div>
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label> Type</label>
                                    <app-multi-select [options]="types" [placeholder]="'Select Type'"
                                        [controlName]="'type'" [formGName]="authorityCreateForm"></app-multi-select>
                                </fieldset>
                            </div>

                        </div>

                    </fieldset>
                </div>


                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!authorityCreateForm.valid">
                            Create
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>