import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VehicleHtmlResponseModalComponent } from '../vehicle-html-response-modal/vehicle-html-response-modal.component';

@Component( {
    selector: 'app-vehicle-list-item',
    templateUrl: './vehicle-list-item.component.html',
    styleUrls: [ './vehicle-list-item.component.css' ],
} )
export class VehicleListItemComponent implements OnInit {

    @Input()
    log: any = {};
    @Input()
    source!: string;
    @ViewChild( VehicleHtmlResponseModalComponent, { static: false } )
    vehicleHtmlResponseModal!: VehicleHtmlResponseModalComponent;

    source_received: boolean = false;
    source_log: boolean = false;
    source_details: boolean = false;
    source_failure_reason: boolean = false;

    constructor () { }

    ngOnInit () {

        if ( this.log ) {

            this.source_received = this.log[ this.source + '_received' ];
            this.source_details = this.log[ this.source + '_details' ];
            this.source_log = this.log[ this.source + '_log' ];
            this.source_failure_reason = this.log[ this.source + '_failure_reason' ];

        }
    }

    showVehicleRcResponseModal (): void {
        this.vehicleHtmlResponseModal.show( this.log, this.source );
    }

}
