import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { WorkService } from '../core/services/work.service';
import { GlobalDbService } from '../core/services/global-db.service';
import { GlobalDbListComponent } from './global-db-list/global-db-list.component';
import { GlobalDbEditComponent } from './global-db-edit/global-db-edit.component';

@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,

    ],
    declarations: [ GlobalDbListComponent,
        GlobalDbEditComponent
    ],
    providers: [ GlobalDbService, WorkService ],
} )
export class GlobalDbModule {
}
