export class EducationVerification {
    id!: number;
    college_name!: string;
    status_name!: string;
    status_id!: string;
    color!: string;
    completed_at!: string;
    employee_id!: number;
    employee_name!: string;
    institute_id!: number;
    institute_name!: string;
    sub_institute_id!: number;
    sub_institute_name!: string;
    enrollment_number!: string;
    passing_year!: string;
    course!: string;
    course_name!: string;
    cgpa!: string;
    client_remarks!: string;
    payment_reference_number!: string;
    india_post_id!: number;
    tat!: string;
    vendor_id!: number;
    sent_via!: string;
    sent_to_vendor_on!: string;
    is_checked!: boolean;
    created_at!: string;
    updated_at!: string;
    work_assigned!: any[];
    attachments!: any[];
    proof_url!: string;
    discipline: any;
    insuff_raised_at!: string;
    insuff_cleared_at!: string;
}
