import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { Component, OnInit } from '@angular/core';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Verification } from '../../core/models/verification.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { WorkService } from '../../core/services/work.service';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-work-bulk-assignment',
    templateUrl: './work-bulk-assignment.component.html',
    styleUrls: [ './work-bulk-assignment.component.css' ],
} )
export class WorkBulkAssignmentComponent extends ImportExcelComponent implements OnInit {

    override excelForm!: FormGroup;
    uploadForm!: FormGroup;
    validationMessages: any = {};
    verifications!: Verification[];
    showExcel = false;
    sampleExcelModal!: ModalDirective;
    requiredMessage = 'required';
    pincodeLengthMessage = 'Must be of length 6 digits';
    dateMessage = 'Allowed: "dd-mm-yyyy" ';
    mobileNumberLengthMessage = 'Must be of length 6 digits';
    dobMessage = 'Allowed: "dd-mm-yyyy;yyyy" ';
    workTypes: any;
    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor ( protected override fb: FormBuilder,
        private workSerivce: WorkService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) {
        super( fb );
    }

    ngOnInit () {
        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );

        this.workSerivce.allTypes().subscribe( ( response ) => {
            this.workTypes = response;
        } );

        this.setValidationMessages();

    }

    // Initialize the excelForm.
    initExcelForm () {
        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
            workTypeId: this.fb.control( '', [ Validators.required ] ),
        } );
    }

    applyValidations () {

        this.excelFormArray.controls.forEach( form => {
            for ( const title of this.titles ) {
                form.get( title )?.setValidators( [ Validators.required ] );
                form.get( title )?.updateValueAndValidity();
            }
        } )

    }

    setValidationMessages () {
        this.validationMessages[ 'element_id' ] = this.requiredMessage;
        this.validationMessages[ 'track_id' ] = this.requiredMessage;
        this.validationMessages[ 'email_id' ] = this.requiredMessage;
    }

    importExcel (): void {

        this.workSerivce.bulkAssignExcel( this.excelForm.value )
            .subscribe( {
                next: ( response: any ) => {

                    if ( 'updated' ) {

                        this.commonService.notifications.success( 'Successful', 'Submission Successful' );

                    }
                },
                error: ( errors: any ) => {

                    this.commonService.notifications.error( 'Error', 'Something went wrong!' );

                }
            } );

    }

    onImportExcelButtonClicked () {

        const options: ConfirmationModalOptions = { title: 'Are you sure you want to uplaod this excel ?' };

        this.confirmationModalService.confirm( options, () => this.importExcel() );

    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
