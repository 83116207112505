<div bsModal #vehicleDetails="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{rc_number}} Details</h4>

            </div>
            <div class="modal-body" style="overflow: scroll;">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 18%;">
                                RC and RTO Details
                            </th>
                            <th style="width: 18%;">
                                Owner Details
                            </th>
                            <th style="width: 18%;">
                                Vehicle Details
                            </th>
                            <th style="width: 18%;">
                                Other Details
                            </th>
                            <th style="width: 18%;">
                                blacklisted Details
                            </th>
                            <th style="width: 18%;">
                                Challans
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let owner of vehiclerc">
                            <td style="width: 15%;">
                                <ul class="list-unstyled">
                                    <li>Authority : {{owner.registering_authority}}</li>
                                    <li>State : {{owner.registration_state || 'NA'}}</li>

                                    <li>Engine No : {{owner.engine_number}}</li>
                                    <li>Chassis No : {{owner.chassis_number}}</li>
                                    <li>Registration Date : {{owner.registration_date}}</li>
                                    <li>RC/FC Expiry Date : {{owner.expiry_date}}</li>
                                    <li>PUCC Upto : {{owner.pucc_upto}}</li>
                                </ul>
                            </td>
                            <td style="width: 15%;">
                                {{owner.serial_number}}: {{owner.owners_name}}

                                <br>
                                Blacklisted: {{owner.blacklisted}}
                            </td>

                            <td style="width: 15%;">
                                <ul class="list-unstyled">
                                    <li>Company : {{owner.vehicle_company}}</li>
                                    <li>Model : {{owner.vehicle_model}}</li>
                                    <li>Class : {{owner.vehicle_class}}</li>
                                    <li>Age : {{owner.vehicle_age}}</li>
                                    <li>Fuel Type : {{owner.fuel_type}}</li>
                                    <li>Motor Vehicle Tax Expiry Date : {{owner.mvt_expiry_date}}</li>
                                    <li>Motor Vehicle Tax Type : {{owner.mvt_type}}</li>
                                </ul>
                            </td>
                            <td style="width: 15%;">
                                Financer: {{owner.financing_authority || 'NA'}}

                                <br>
                                Last Updated: {{owner.updated_at}}
                            </td>

                            <td style="width: 15%;">
                                <div *ngFor="let blacklist of owner.blacklisted_details">
                                    <pre>{{blacklist | json}}</pre>
                                </div>
                            </td>
                            <td style="width: 25%;">
                        <tr *ngFor="let challan of owner.challans">
                            <ul class="list-unstyled"
                                style="background-color: #edf1f2; padding: 5px; border: 1px solid #dee5e7;">
                                <li><b>Challan Number:</b> {{challan.challan_number}}</li>
                                <li><b>Offence Details:</b> {{challan.offence_details}}</li>
                                <li><b>Place:</b> {{challan.place}}</li>
                                <li><b>Date Time:</b> {{challan.date}}</li>
                                <li><b>State:</b> {{challan.state}}</li>
                                <li><b>Rto:</b> {{challan.rto}}</li>
                                <li><b>Accused Name:</b> {{challan.accused_name}}</li>
                                <li><b>amount:</b> {{challan.amount}}</li>
                                <li><b>Status:</b> {{challan.status}}</li>
                            </ul>
                        </tr>
                        </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>