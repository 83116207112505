<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">My Download List</h1>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination && pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Route </th>
                        <th>File Type</th>
                        <th>Record Count</th>
                        <th>Params</th>
                        <th>Purpose</th>
                        <th>Status</th>
                        <th>Download</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody *ngIf="pagination && pagination.data">
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">
                        <td> {{log.route}} </td>
                        <td> {{log.file_type}} </td>
                        <td> {{log.record_count}} </td>
                        <td> <button class="btn btn-info btn-sm" (click)="openParamsModal(log.params)">
                                Params
                            </button> </td>
                        <td> {{log.purpose}} </td>
                        <td> {{log.status}} </td>
                        <td>
                            <a *ngIf="isDownloadComplete(log)" class="btn btn-info btn-sm" [href]="log.url">
                                Download
                            </a>
                        </td>
                        <td> {{log.created_at}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination && pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>

</div>