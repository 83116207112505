import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInviteListComponent } from './invite-list/form-invite-list.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { InviteFiltersComponent } from './invite-filters/invite-filters.component';
import { InviteFollowUpModalComponent } from './invite-follow-up-modal/invite-follow-up-modal.component';
import { FormInviteModalComponent } from './form-invite-modal/form-invite-modal.component';
import { InviteUploadComponent } from './invite-upload/invite-upload.component';
import { InviteEmailListItemComponent } from './invite-email-list-item/invite-email-list-item.component';
// import { MatTooltipModule } from '@angular/material';
import { InviteService } from '../core/services/invite.service';
import { SharedModule } from '../core/shared.module';
import { EmailLogModule } from '../email-log/email-log.module';

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        EmailLogModule
        // MatTooltipModule,
    ],
    declarations: [
        FormInviteListComponent,
        InviteFiltersComponent,
        InviteFollowUpModalComponent,
        FormInviteModalComponent,
        InviteUploadComponent,
        InviteEmailListItemComponent,
    ],
    providers: [
        InviteService,
    ],
} )
export class InviteModule { }
