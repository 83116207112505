<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">All Deleted Permissions</h1>
</div>

<div class="wrapper-md">
    <div class="btn-group btn-breadcrumb">
        <a class="btn btn-default" routerLink="/permissions/list">All Permissions</a>
        <a class="btn btn-default" routerLink="/permissions/deleted/list">Deleted Permissions</a>
    </div>
</div>


<div class="wrapper-md">
    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">Permissions</h3>
                </div>
                <div class="panel-body">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Action</th>
                                <th>Description</th>
                                <th>Restore</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let permission of permissions">
                                <td>{{ permission.subject }}</td>
                                <td>{{ permission.action }}</td>
                                <td>{{ permission.description }}</td>
                                <td>
                                    <button (click)="onRestorePermissionButtonClicked(permission.id)"
                                        class="btn btn-info btn-xs"><i class="fa fa-undo"></i> Restore
                                    </button>
                                </td>
                                <td>
                                    <button (click)="onDeletePermissionButtonClicked(permission.id)"
                                        class="btn btn-danger btn-xs"><i class="fa fa-trash"></i> Delete
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>