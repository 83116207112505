import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { InsuranceService } from 'src/app/core/services/insurance.service';

@Component( {
    selector: 'app-insurance-html-response-modal',
    templateUrl: './insurance-html-response-modal.component.html',
    styleUrls: [ './insurance-html-response-modal.component.css' ],
} )
export class InsuranceHtmlResponseModalComponent {

    htmlResponse!: string;
    attachments: any = [];

    @ViewChild( 'insuranceHtmlResponse', { static: false } )
    public insuranceHtmlResponse!: ModalDirective;

    constructor (
        private commonService: CommonService,
        private insuranceService: InsuranceService,
    ) { }

    show ( log: any, type: any ): void {

        this.insuranceService.getHtmlResponse( log.id, type ).subscribe(
            response => {
                this.attachments = response[ 'attachments' ];
                this.htmlResponse = response[ 'response' ];
                this.insuranceHtmlResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );
    }

    hide (): void {

        this.insuranceHtmlResponse.hide();
    }

}
