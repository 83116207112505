export class IdentityVerification {
    id!: number;
    employee_id!: number;
    employee_name!: string;
    name!: string;
    father_name!: string;
    dob!: string;
    number!: string;
    type!: string;
    address!: string;
    lot_name!: string;
    company_name!: string;
    lot_id!: number;
    company_id!: number;
    state_id!: number;
    state_name!: string;
    district_id!: number;
    district_name!: string;
    pincode!: number;
    status_name!: string;
    status_id!: string;
    color!: string;
    front_url!: string;
    back_url!: string;
    proof_url!: string;
    is_checked!: boolean;
    created_at!: string;
    updated_at!: string;
    completed_at!: string;
    type_name!: string;
    file_number!: string;
    registered_authority_name!: string;
    registered_authority_id!: number;
    work_assigned!: any[];
    vendor_id!: number;
    vendor_name!: string;
    sent_to_vendor_on!: string;
    insuff_raised_at!: string;
    insuff_cleared_at!: string;
    client_remarks!: string;
    type_id!: number;
    type_of_doc!: number;

    static typeToString ( type: number ): string {
        switch ( type ) {
            case 0: return 'No Document'; break;
            case 1: return 'Voter ID Card'; break;
            case 2: return 'PAN Card'; break;
            case 3: return 'Aadaar Card'; break;
            case 4: return 'Driving Licence'; break;
            case 5: return 'Passport'; break;
            default: return 'Unknown Document'; break;
        }
    }
}
