import { CommonService } from './../../core/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AadhaarService } from './../../core/services/aadhaar.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component( {
    selector: 'app-uidai-html-response-modal',
    templateUrl: './uidai-html-response-modal.component.html',
    styleUrls: [ './uidai-html-response-modal.component.css' ],
} )
export class UidaiHtmlResponseModalComponent implements OnInit {

    attachments: any = [];

    @ViewChild( 'aadhaarHtmlResponse', { static: false } )
    public aadhaarHtmlResponse!: ModalDirective;


    constructor (
        private aadhaarService: AadhaarService,
        private notification: ToastrService
    ) { }

    ngOnInit () {
    }


    show ( aadhaarLogId: number ) {

        this.aadhaarService.gethtmlResponse( aadhaarLogId ).subscribe(

            {
                next: ( response: any ) => {
                    this.attachments = response[ 'attachments' ];
                    this.aadhaarHtmlResponse.show();
                },
                error: ( erros: any ) => {

                    this.notification.error( 'Some Error Occurred' );
                },
            }

        );
    }

    hide () {
        this.aadhaarHtmlResponse.hide();
    }

}
