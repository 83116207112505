<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">
        All Clients
    </h1>
</div>

<app-client-filter filterBy="company,name,email,status" />

<div class="wrapper-md">
    <div class="row">
        <div class="col-lg-6">
            <a class="btn btn-primary btn-addon" routerLink="/clients/create">
                <i class="fa fa-plus"></i>
                Create New Client
            </a>
            <a class="btn btn-primary btn-addon" (click)="exportExcel()" [appHide]="'clients,download.excel'">
                <i class="fa fa-download"></i>
                Download Excel
            </a>
            <a routerLink="/clients/upload" class="btn btn-primary btn-space">
                <i class="fa fa-upload"></i>
                Upload Bulk Clients
            </a>
        </div>
    </div>
</div>

<app-client-list *ngIf="pagination" [pagination]="pagination" (onClientStatusUpdate)="filterResults()" />