import { Component, ElementRef, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AddressVerification } from '../../core/models/address-verification.model';
import { AddressService } from '../../core/services/address.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-address-edit-modal',
    templateUrl: './address-edit-modal.component.html',
    styleUrls: [ './address-edit-modal.component.css' ],
} )
export class AddressEditModalComponent implements OnInit {


    isSubmitting: boolean = false;
    editForm!: FormGroup;
    vendors!: any[];
    institutes!: any[];
    subInstitutes!: any[];
    uploader: FileUploader = new FileUploader( { url: '' } );
    languages!: any[];
    verification: AddressVerification = new AddressVerification();
    current_date!: string;
    date: Date = new Date();
    created_at: any;

    @ViewChild( 'editModal', { static: false } )
    public editModal!: ModalDirective;

    @Output()
    onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private addressService: AddressService,
        private commonService: CommonService,
        private fb: FormBuilder,
        protected el: ElementRef,
    ) {

    }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {
        this.editForm = this.fb.group( {
            id: [ '' ],
            employee_id: [ '' ],
            completed_at: [ '' ],
            employee_name: [ '' ],
            father_name: [ '' ],
            mobile_number: [ '' ],
            status_name: [ '' ],
            status_id: [ '' ],
            state_name: [ '' ],
            state_id: [ '' ],
            district_name: [ '' ],
            district_id: [ '' ],
            language_id: [ '' ],
            language_name: [ '' ],
            area_type: [ '', [ Validators.required ] ],
            type: [ '' ],
            address: [ '' ],
            case_type: [ '', [ Validators.required ] ],
            police_station: [ '' ],
            landmark: [ '' ],
            pincode: [ '' ],
            sent_to_vendor_on: [ '' ],
            client_remarks: [ '' ],
            duration_of_stay: [ '' ],
            reference_name: [ '' ],
            reference_number: [ '' ],
            reference_relation: [ '' ],
            india_post_id: [ '', [ Validators.pattern( '[A-Za-z]{2}[0-9]{9}[A-Za-z]{2}' ) ] ],
            vendor_id: [ '' ],
            vendor_name: [ '' ],
            insuff_raised_at: [ '' ],
            insuff_cleared_at: [ '' ],
        } );
        this.current_date = new Date( this.date.getTime() - ( this.date.getTimezoneOffset() * 60000 ) ).toJSON().slice( 0, 10 );
    }

    show ( employeeId: number, verificationId: number ): void {


        this.addressService.findByEmployeeId( employeeId ).subscribe(
            response => {
                response.address_verifications.filter( item => {
                    if ( item.id === verificationId ) {
                        this.verification = item;
                    }
                } );

                this.created_at = this.verification.created_at;

                this.editForm.patchValue( this.verification );

                if ( this.editForm.get( 'completed_at' )?.value &&
                    this.commonService.userLevelId() < 4 &&
                    this.editForm.get( 'status_id' )?.value !== 31
                ) {

                    this.editForm.disable();

                } else {
                    this.editForm.enable();
                }

                this.editModal.show();
            }
        );


        this.commonService.getLanguages().subscribe(
            response => {
                this.languages = response;
            }
        );

        this.commonService.getVendors( 'address' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
    }

    hide (): void {
        this.editModal.hide();
    }

    update (): void {

        this.addressService.update( this.editForm.value.id, this.editForm.value, '' ).subscribe(
            response => {
                this.hide();
                this.commonService.notifications.success( 'Verification Updated', 'Verification Updated' );
                this.isSubmitting = false;
                this.onVerificationUpdated.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
            }
        );
    }

    // Call back from the modal to update details
    onModalUpdated (): void {
        this.onVerificationUpdated.emit( true );
    }
}
