import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsListComponent } from './permissions-list/permissions-list.component';
import { AdminGuard } from '../core/services/admin.guard';
import { AuthGuard } from '../core/services/auth-guard.service';
import { PermissionDeletedComponent } from './permission-deleted/permission-deleted.component';
import { ClientCrmListComponent } from '../company/client-crm/client-crm-list/client-crm-list.component';
import { ClientCrmCreateComponent } from '../company/client-crm/client-crm-create/client-crm-create.component';
import { ClientCrmEditComponent } from '../company/client-crm/client-crm-edit/client-crm-edit.component'


const routes: Routes = [
    { path: 'permissions/list', component: PermissionsListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'permissions/deleted/list', component: PermissionDeletedComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'client-crm', component: ClientCrmListComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'client-crm/create', component: ClientCrmCreateComponent, canActivate: [ AuthGuard, AdminGuard ] },
    { path: 'client-crm/:id/edit', component: ClientCrmEditComponent, canActivate: [ AuthGuard, AdminGuard ] },
];

@NgModule( {
    imports: [ RouterModule.forChild( routes ) ],
    exports: [ RouterModule ],
} )
export class AccessRestrictionRoutingModule { }
