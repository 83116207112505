<div bsModal #permissionEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="permissionEditForm" (ngSubmit)="updatePermission()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Permission</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">

                        <fieldset class="form-group">
                            <input formControlName="permission_id" class="form-control form-control-lg" type="hidden"
                                ngModel="{{permission.id}}" />
                            <label class="center-block"><b>Subject</b> (Subject should be in lower case format. e.g.
                                icr)</label>
                            <input formControlName="subject" class="form-control form-control-lg"
                                ngModel="{{permission.subject}}" />
                        </fieldset>
                        <div *ngIf="subject?.invalid && subject?.touched">
                            <small class="text-danger" *ngIf="subject?.errors?.['required']">Subject is required</small>
                        </div>
                        <small class="text-danger" [hidden]="!error.subject">{{ error.subject }}</small>

                        <fieldset class="form-group">
                            <label class="center-block"><b>Action</b> (Action should be in camel case format. e.g.
                                getAllActs)</label>
                            <input formControlName="action" class="form-control form-control-lg"
                                ngModel="{{permission.action}}" />
                        </fieldset>
                        <div *ngIf="action?.invalid && action?.touched">
                            <small class="text-danger" *ngIf="action?.errors?.['required']">Action is required</small>
                        </div>
                        <small class="text-danger" [hidden]="!error.action">{{ error.action }}</small>

                        <fieldset class="form-group">
                            <label><b>Description</b></label>
                            <input formControlName="description" class="form-control form-control-lg"
                                ngModel="{{permission.description}}" />
                        </fieldset>
                        <div *ngIf="description?.invalid && description?.touched">
                            <small class="text-danger" *ngIf="description?.errors?.['required']">Description is
                                required</small>
                        </div>
                        <small class="text-danger" [hidden]="!error.description">{{ error.description }}</small>

                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!permissionEditForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>