<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-2">
                            <label class="center-block">
                                <strong>Select Company: </strong>
                            </label>
                        </div>
                        <div class="col-md-10">
                            <select class="form-control" formControlName="company_id"
                                (change)="onCompanySelected($event)">
                                <option [ngValue]="null">Select Company</option>
                                <option *ngFor="let company of companyList" [value]="company.id">
                                    {{ company.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-2">
                            <label class="center-block"> <strong>Select Form: </strong></label>
                        </div>
                        <div class="col-md-10">
                            <select formControlName="form_id" class="form-control">
                                <option *ngFor="let form of forms;let i=index" value="{{form.id}}">
                                    {{form.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-6 form-group">
                        <div class="col-md-2 form-group">
                            <label class="center-block"> <strong>Select Form Version: </strong></label>
                        </div>

                        <div class="col-md-10 form-group">

                            <select formControlName="form_version" class="form-control" id="select-form-version">
                                <option value="v1"> Old Form </option>
                                <option value="v2"> New Form</option>
                            </select>

                        </div>
                    </div>


                </div>

                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-2">
                            <label class="center-block">
                                <strong>Message: </strong>
                            </label>
                        </div>
                        <div class="col-md-10">
                            <textarea formControlName="message" class="form-control">

							</textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-2"></div>
                        <div class="col-md-10">
                            <div class="form-group">
                                <input type="file" [accept]="acceptedFileType" [disabled]="isFileDisabled"
                                    (change)="onFileChange($event)" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <button class="btn btn-primary">
                <a target="_blank"
                    href="https://docs.google.com/spreadsheets/d/1kpswDgBKUcHBCwJC_jKM3aOpZDKA2uIpYO4jL3YCPcM/edit?usp=sharing">
                    View Sample Excel</a>
            </button>
        </div>
    </div>
</div>

<div class="wrapper-md" *ngIf="excelForm">
    <div class="row">
        <div class="col-md-12">
            <button type="button" [disabled]="uploadForm.invalid || isSubmitting" class="btn btn-primary"
                (click)="inviteSend()">
                Send
            </button>
        </div>
    </div>
</div>

<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-body">
            <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                <fieldset [disabled]="isSubmitting">
                    <div style="max-width: 100%;overflow-x: scroll">
                        <table class="table table-bordered" formArrayName="candidates">
                            <tr>
                                <th style="padding-right: 240px">NAME</th>
                                <th style="padding-right: 240px">EMAIL</th>
                                <th style="padding-right: 240px">PHONE NUMBER</th>
                                <th style="padding-right: 240px">CLIENT EMPLOYEE ID</th>
                            </tr>
                            <tr *ngFor="let row of candidates.controls;let i=index" [formGroupName]="i">
                                <td style="padding-right: 240px">
                                    <input formControlName="name" class="form-control">
                                    <span class="text-danger" *ngIf="nameValidate(i)">
                                        Invalid name
                                    </span><br>
                                </td>

                                <td style="padding-right: 240px">
                                    <input formControlName="email" class="form-control">
                                    <span class="text-danger" *ngIf="emailValidate(i)">
                                        Invalid email address
                                    </span><br>
                                </td>

                                <td style="padding-right: 240px">
                                    <input formControlName="phone_number" class="form-control">
                                    <span class="text-danger" *ngIf="mobileValidate(i)">
                                        Invalid mobile number
                                    </span><br>
                                </td>

                                <td style="padding-right: 240px">
                                    <input formControlName="client_employee_id" class="form-control">
                                    <span class="text-danger" *ngIf="clientEmployeeIdValidate(i)">
                                        Only space not allowed.
                                    </span><br>
                                </td>

                            </tr>
                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>