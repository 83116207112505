import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Verification } from '../core/models/verification.model';
import { ClientFormType } from '../core/enums/client-form-type.enum';

@Component( {
    selector: 'app-client-forms-v2',
    templateUrl: './client-forms-v2.component.html',
} )
export class ClientFormsV2Component {

    @Input() settingFormGroup!: FormGroup;
    @Input() formType!: FormControl;
    @Input() verifications: Verification[] = [];

    get hasAddress (): boolean {
        return this.hasVerification( 'current_address' ) || this.hasVerification( 'permanent_address' );
    }

    get isDigitalAddress (): boolean {
        return this.formType.value == ClientFormType.DigitalAddress
    }

    hasVerification ( type: string ): boolean {
        return this.verifications.find( ( verification ) => verification.shortTitle === type ) ? true : false;
    }

}
