import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FileItem, FileLikeObject, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { Verification } from '../../core/models/verification.model';
import { BuildFilesUploadService } from '../../core/services/build-files-upload.service';
import { CommonService } from '../../core/services/common.service';
import { VerificationService } from '../../core/services/verification.service';
import { EnvironmentService } from './../../core/services/environment.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-bulk-upload-files',
    templateUrl: './bulk-upload-files.component.html',
    styleUrls: [ './bulk-upload-files.component.css' ],
} )
export class BulkUploadFilesComponent implements OnInit, AfterViewInit {
    verification!: string;
    document!: string;
    verifications: Verification[] = [];

    public uploader!: FileUploader;
    public verificationDocumentTypeArray: any = [];

    private url!: string;
    private maxFileSize: number = 300 * 1024; // 300 KB
    private allowedMimeType: string[] = [ 'image/png', 'image/jpg', 'image/jpeg' ];

    constructor (
        private verificationService: VerificationService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService,
        private service: BuildFilesUploadService,
        private environmentService: EnvironmentService,
    ) { }


    ngOnInit (): void {

        this.url = this.environmentService.apiUrl + 'common/bulk-upload';

        this.setUploader();

        this.service.getAllVerificationDocumentsArray().then( documents => this.verificationDocumentTypeArray = documents );

        this.verificationService.getAllVerifications().then( verifications => {

            // TODO: temp fix for icr.case, need to find better solution fot this
            verifications.push( new Verification( '', 'icr.case', 'Icr Case', 'icr.case', '', '' ) );

            this.verifications = verifications;

        } );

        this.uploader.onSuccessItem = ( item, response, status, headers ) => this.onSuccessItem( item, response, status, headers );

        this.uploader.onErrorItem = ( item, response, status, headers ) => this.onErrorItem( item, response, status, headers );

        this.uploader.onBeforeUploadItem = ( item ) => {
            item.withCredentials = false;
            item.url = this.url + '?verification=' + this.verification + '&document=' + this.document;
        };

        this.uploader.onWhenAddingFileFailed = ( item, filter, options ) => this.onWhenAddingFileFailed( item, filter, options );
    }

    onSuccessItem ( item: FileItem, response: string, status: number, headers: ParsedResponseHeaders ): any {
        // success server response
        const data: any = JSON.parse( response );

        if ( data.code === 200 ) {
            this.commonService.notifications.success( data.message );
        } else if ( data.code === 500 || data.code === 404 ) {
            item.progress = 0;
            item.isSuccess = false;
            this.commonService.notifications.error( data.message );
        }
    }


    setUploader (): void {

        this.uploader = new FileUploader( {
            url: this.url,
            headers: [ { name: 'Authorization', value: 'Bearer ' + localStorage.getItem( 'token' ) } ],
            allowedMimeType: this.allowedMimeType,
            maxFileSize: this.maxFileSize,
        } );
    }

    onErrorItem ( item: FileItem, response: string, status: number, headers: ParsedResponseHeaders ): void {

        this.commonService.notifications.error( 'Something went wrong' );
    }

    ngAfterViewInit (): void {

        this.uploader.onAfterAddingFile = ( item => {
            item.withCredentials = false;
        } );
    }

    uploadAll (): void {

        const options: ConfirmationModalOptions = {};

        if ( this.uploader.queue.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.uploader.uploadAll() );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }

    }

    onWhenAddingFileFailed ( item: FileLikeObject, filter: any, options: any ): void {

        switch ( filter.name ) {
            case 'fileSize':
                this.commonService.notifications.warning( `Maximum upload size exceeded (${ item.size } of ${ this.maxFileSize } allowed)` );
                break;
            case 'mimeType':
                const allowedTypes: string = this.allowedMimeType.join();
                this.commonService.notifications.warning( `Type "${ item.type } is not allowed. Allowed types: "${ allowedTypes }"` );

                break;
            default:
                this.commonService.notifications.warning( `Unknown error (filter is ${ filter.name })` );
        }
    }


}
