<div class="wrapper-md">
    <div class="panel panel-default">

        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">

            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Email Type</label>
                        <select class="form-control" formControlName="email_type">
                            <option value="all">all</option>
                            <option value="education">Education</option>
                            <option value="employment">Employment</option>
                            <option value="form_invitee">Form Invitee</option>
                            <option value="digital_address_form">Digital Address Form</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Status</label>
                        <select class="form-control" formControlName="email_status">
                            <option value="all">all</option>
                            <option value="delivered">Delivered</option>
                            <option value="dropped">Dropped</option>
                            <option value="bounced">Bounced</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Sent By</label>
                        <select class="form-control" formControlName="sent_by">
                            <option *ngFor="let user of users" [value]="user.id">
                                {{user.name}}
                            </option>
                        </select>
                    </div>


                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>
                </div>
                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button type="submit" class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
