import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VehicleService } from '../../core/services/vehicle.service';
import { CommonService } from '../../core/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component( {
    selector: 'app-vehicle-search-modal',
    templateUrl: './vehicle-search-modal.component.html',
    styleUrls: [ './vehicle-search-modal.component.css' ],
} )
export class VehicleSearchModalComponent {

    isSubmitting = false;
    searchVehicleForm!: FormGroup;

    @ViewChild( 'vehicleSearch', { static: false } )
    public vehicleSearch!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();

    constructor (
        private vehicleService: VehicleService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) {
        this.initForm();
    }

    initForm () {
        this.searchVehicleForm = this.fb.group( {
            number: [ '', Validators.required ],
            type: [ '', Validators.required ],
            driver: [ [] ],
        } );

    }

    show () {
        this.vehicleSearch.show();
    }


    onCheckboxChange ( event: any, value: any ) {

        const list = this.searchVehicleForm.value.driver;

        const data = {
            index: value,
        };
        if ( event.target.checked == true ) {
            list.push( data );
        } else {
            if ( list.length > 1 ) {
                for ( let i = 0; i < list.length; i++ ) {
                    if ( list[ i ].index == value ) {
                        list.splice( i, true );
                        break;
                    }
                }
            } else {
                list.splice( 0, true );
            }

        }
        this.searchVehicleForm.value.driver = list;

    }

    hide () {
        this.initForm();
        this.vehicleSearch.hide();
    }


    search (): void {
        this.isSubmitting = true;

        this.vehicleService.search( this.searchVehicleForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Request Sent Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onSearch.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }
}
