import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { CriminalService } from '../core/services/criminal.service';
import { CriminalExcelImportComponent } from './criminal-excel-import/criminal-excel-import.component';
import { CriminalSearchComponent } from './criminal-search/criminal-search.component';
import { CriminalExcelSearchComponent } from './criminal-excel-search/criminal-excel-search.component';

@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,

    ],
    declarations: [
        CriminalExcelImportComponent,
        CriminalSearchComponent,
        CriminalExcelSearchComponent,

    ],
    providers: [ CriminalService ],
} )
export class CriminalModule {
}
