import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FilterService } from 'src/app/core/services/filter.service';

import { Subscription } from 'rxjs';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { GstService } from '../../core/services/gst.service';

@Component( {
    selector: 'app-gst-list',
    templateUrl: './gst-list.component.html',
    styleUrls: [ './gst-list.component.css' ],
} )
export class GstListComponent extends ExportExcelComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress: boolean = false;
    filtersSubscription!: Subscription;

    constructor (
        protected service: GstService,
        protected route: ActivatedRoute,
        protected router: Router,

        protected fb: FormBuilder,
        protected commonService: CommonService,
        protected viewContainerRef: ViewContainerRef,
        private filterService: FilterService
    ) {
        super();
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );

        } );
    }


    filterResults ( params: Params ): void {

        this.service.filter( params ).subscribe( response => {
            this.pagination = response;
        } );

    }

    showPage ( page: number ): void {

        this.filterService.filterByPage( page );
    }

    onSearch (): void {

        this.filterService.filter();
    }

    exportLogsExcel (): void {

        this.fetchingInProgress = true;
        this.service.getDataForExcel( this.filterParams ).subscribe(
            response => {

                this.data = this.data.concat( response.data );

                if ( response[ 'next_page_url' ] ) {
                    this.filterParams = this.filterParams.set( 'page', response[ 'next_page_url' ].split( '=' )[ 1 ] );
                    this.exportLogsExcel();
                } else {
                    this.commonService.notifications.success( 'All Data Fetched' );
                    this.downloadExcel( this.data, 'Gst Excel' );
                    this.fetchingInProgress = false;
                    this.data = [];
                }
            } );
    }

    resend ( log: any, type: any ): void {
        this.service.resend( log.id, type ).subscribe( repsonse => {
            this.commonService.notifications.success( 'Success', 'GST Request Sent successfully' );
            this.filterService.filter();
        },
            error => {
                this.commonService.notifications.success( 'Resend Failed' );
            } );
    }

    refresh (): void {
        this.filterService.filter();
    }
}
