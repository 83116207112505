import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AadhaarService } from '../../core/services/aadhaar.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-uidai-new-request',
    templateUrl: './uidai-new-request.component.html',
    styleUrls: [ './uidai-new-request.component.css' ],
} )
export class UidaiNewRequestComponent {

    isSubmitting: boolean = false;
    newRequestForm!: FormGroup;
    companies!: any[];

    @ViewChild( 'uidaiSearch', { static: false } )
    public uidaiSearch!: ModalDirective;

    @Output()
    onSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private aadhaarService: AadhaarService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) {
        this.initForm();
    }

    initForm (): void {
        this.newRequestForm = this.fb.group( {
            aadhaar_number: [ '',
                [
                    Validators.required,
                    Validators.maxLength( 12 ),
                    Validators.minLength( 12 ),
                    Validators.pattern( '[0-9]{12}$' ),
                ],
            ],
            name:
                [],
        } )
            ;

    }

    show (): void {
        this.uidaiSearch.show();
    }

    hide (): void {
        this.initForm();
        this.uidaiSearch.hide();
    }


    search (): void {
        this.isSubmitting = true;

        this.aadhaarService.create( this.newRequestForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Request Sent Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onSearch.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }

}
