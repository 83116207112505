import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Webhook } from '../../core/models/webhook.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';

import { WebhookService } from '../../core/services/webhook.service';

@Component( {
    selector: 'app-webhook-edit',
    templateUrl: './webhook-edit.component.html',
    styleUrls: [ './webhook-edit.component.css' ],
} )
export class WebhookEditComponent {

    isSubmitting = false;
    webhook: Webhook = new Webhook();

    webhookEditForm: FormGroup;

    @ViewChild( 'webhookEditModal', { static: false } )
    public webhookEditModal!: ModalDirective;

    @Output()
    onWebhookEdited = new EventEmitter<boolean>();

    constructor (
        private commonService: CommonService,

        private fb: FormBuilder,
        private webhookService: WebhookService
    ) {
        this.webhookEditForm = this.fb.group( {
            id: [ '' ],
            url: [ '' ],
            status_id: [ '' ],
            headers: this.fb.array( [] ),
        } );
    }

    show ( webhook: Webhook ) {

        this.webhook = webhook;
        this.populateForm();
        this.webhookEditModal.show();

    }

    hide () {
        this.webhookEditModal.hide();
    }


    updateWebhook () {
        this.webhookService.update( this.webhook.id, this.webhookEditForm.value ).subscribe(
            ( response: any ) => {
                this.commonService.notifications.success( 'Success', response[ 'message' ] );
                this.hide();
                this.onWebhookEdited.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.hide();
            }
        );
    }

    get formArray (): FormArray {
        return this.webhookEditForm.get( 'headers' ) as FormArray;
    }

    populateForm () {
        this.webhookEditForm.patchValue( this.webhook );
        const headers = this.webhook.headers;
        const webhookFormGroup = headers.map( item => this.fb.group( item ) );
        const webhookFormGroupArray = this.fb.array( webhookFormGroup );
        this.webhookEditForm.setControl( 'headers', webhookFormGroupArray );
    }

    addNewRow () {

        const control = <FormArray> this.webhookEditForm.controls[ 'headers' ];

        control.push( this.fb.group( {
            header: [ '' ],
            value: [ '' ],
        } ) );

    }
}
