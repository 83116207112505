import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlacklistedListComponent } from './blacklisted-list/blacklisted-list.component';
import { BlacklistedDetailsComponent } from './blacklisted-details/blacklisted-details.component';
import { BlacklistedFiltersComponent } from './blacklisted-filters/blacklisted-filters.component';
import { BlacklistedResponseModalComponent } from './blacklisted-response-modal/blacklisted-response-modal.component';
import { BlacklistedSearchModalComponent } from './blacklisted-search-modal/blacklisted-search-modal.component';
import { BlacklistedStatsComponent } from './blacklisted-stats/blacklisted-stats.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { BlacklistedService } from '../core/services/blacklisted.service';
import { BlacklistedExcelImportComponent } from './blacklisted-excel-import/blacklisted-excel-import.component';



@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
    ],
    declarations: [
        BlacklistedListComponent,
        BlacklistedDetailsComponent,
        BlacklistedFiltersComponent,
        BlacklistedResponseModalComponent,
        BlacklistedSearchModalComponent,
        BlacklistedStatsComponent,
        BlacklistedExcelImportComponent,
    ],
    providers: [ BlacklistedService ],
} )
export class BlacklistedModule {
}
