<div [formGroup]="formGroup">
    <label id="label" class="center-block">{{labelName}}
        <input [formControlName]="controlName" [typeahead]="districts | filterByStateId:stateId "
            [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select {{labelName}}"
            [typeaheadItemTemplate]="customItemTemplate" class="form-control" (typeaheadOnSelect)="onSelect($event)"
            (typeaheadNoResults)="onNoResults()" [id]="controlName">
    </label>
    <small class="text-danger" *ngIf="districtId.errors?.['required'] || districtName.errors?.['required']">
        Required
    </small>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
