import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root',
} )
export class FaceRecognitionService {

    constructor ( private api: ApiService ) { }

    filter ( params: Params | undefined ): Observable<any> {
        return this.api.get( 'face-recognition/list', params );
    }

    getDataForExcel ( params: Params | undefined ): Observable<any> {
        return this.api.get( 'face-recognition/download', params );
    }

    getStats ( params: Params | undefined ): Observable<any> {
        return this.api.get( 'face-recognition/stats', params );
    }

    getApiResponse ( id: string ): Observable<any> {
        return this.api.get( 'face-recognition/' + id );
    }

    addImages ( param: Object | undefined ): Observable<any> {
        return this.api.post( 'face-recognition/fetch', param );
    }

    importExcel ( data: any ): Observable<any> {
        return this.api.post( 'face-recognition/excel', data );
    }
}
