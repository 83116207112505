import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { VoterService } from '../../core/services/voter.service';
import { HttpParams } from '@angular/common/http';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-voter-excel-import',
    templateUrl: './voter-excel-import.component.html',
    styleUrls: [ './voter-excel-import.component.css' ],
} )
export class VoterExcelImportComponent extends ImportExcelComponent implements OnInit {

    override isSubmitting = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    uploadForm: FormGroup;
    validationMessages: ValidationMessages = {};
    clients!: any;

    constructor (
        private voterService: VoterService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected commonService: CommonService,
        protected override fb: FormBuilder
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            'excel': [ '', Validators.required ],
        } );

    }

    setValidationMessages () {
        this.validationMessages[ 'voter_number' ] = 'Required';
        // this.validationMessages['track_id'] = 'Required';
        this.validationMessages[ 'client_api_id' ] = 'Required';
    }

    ngOnInit () {
        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            ( response: any ) => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

    }

    // Initialize the excelForm.
    initExcelForm () {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
            client_api_id: [ '', Validators.required ],
        } );
    }

    applyValidations () {
        this.excelFormArray.controls.forEach( form => {
            // form.get('track_id').setValidators([]);
            // form.get('track_id').updateValueAndValidity();
            form.get( 'voter_number' )?.setValidators( [ Validators.required ] );
            form.get( 'voter_number' )?.updateValueAndValidity();
            this.setValidationMessages();
        } );
    }

    importExcel () {

        this.voterService.importExcel( this.excelForm.value ).subscribe(
            response => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ '/voter' ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
