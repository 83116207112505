import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { BlacklistedService } from '../../core/services/blacklisted.service';
import { CommonService } from '../../core/services/common.service';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

enum blacklistedCheckbox { 'vahan', 'blacklist', 'parivahan' }

@Component( {
    selector: 'app-blacklisted-excel-import',
    templateUrl: './blacklisted-excel-import.component.html',
    styleUrls: [ './blacklisted-excel-import.component.css' ],
} )
export class BlacklistedExcelImportComponent extends ImportExcelComponent implements OnInit {

    uploadForm!: FormGroup;
    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    validationMessages: ValidationMessages = {};
    clients!: any[];

    constructor (
        private blacklisted: BlacklistedService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
    ) {
        super( fb );
        // initialize the upload excel form.


    }

    ngOnInit (): void {

        this.initUploadForm();

        this.getAllClientApis();
    }


    initUploadForm (): void {

        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );
    }

    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
            client_api_id: [ '', Validators.required ],
            excel_type: [ '', Validators.required ],
            sources: [ [] ],
        } );
    }

    getAllClientApis (): void {

        let params: HttpParams = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }

    setValidationMessages (): void {
        this.validationMessages[ 'rc_number' ] = 'Required';
    }

    applyValidations (): void {
    }

    importExcel (): void {

        this.blacklisted.importExcel( this.excelForm.value ).subscribe(
            response => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ '/blacklisted' ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }


    onCheckboxChange ( event: any, value: any ): void {

        const list: Array<{ index: blacklistedCheckbox }> = this.excelForm.value.sources;

        const data: { index: blacklistedCheckbox } = {
            index: value,
        };
        if ( event.target.checked === true ) {

            list.push( data );
        } else {
            for ( let i: number = 0; i < list.length; i++ ) {

                if ( list[ i ].index === value ) {

                    list.splice( i, 1 );

                    break;
                }
            }
        }

        this.excelForm.value.sources = list;
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }

}
