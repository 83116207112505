import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class InsuranceService {

    constructor ( private api: ApiService ) { }

    filter ( params: Params | undefined ) {
        return this.api.get( 'insurance', params );
    }

    resend ( data: Object | undefined ) {
        return this.api.post( 'insurance/resend', data );
    }

    resendAll ( source: string, params: Params | undefined ) {
        return this.api.get( 'insurance/resendAll?type=' + source, params );
    }

    getDataForExcel ( params: Params | undefined ) {
        return this.api.get( 'insurance/download', params );
    }

    getStats ( params: Params | undefined ) {
        return this.api.get( 'insurance/stats', params );
    }

    getDetails ( rcNumber: string ) {
        return this.api.get( 'insurance/details?rc_number=' + rcNumber );
    }

    getApiResponse ( id: string ) {
        return this.api.get( 'insurance/' + id );
    }

    search ( data: Object | undefined ) {
        return this.api.post( 'insurance/search', data );
    }

    importExcel ( data: Object | undefined ) {
        return this.api.post( 'insurance/import', data );
    }

    getHtmlResponse ( id: string, type: string ): Observable<any> {
        return this.api.get( 'insurance/' + id + '/html-response?type=' + type );
    }
}
