<div bsModal #voterSearchModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="voterSearchForm" (ngSubmit)="search()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">New Voter ID Request</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-4">
                                <fieldset class="form-group">
                                    <label class="center-block">Voter ID Number
                                        <input formControlName="voter_number" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>

                            <!--track_id field to be added in future-->
                            <!--<div class="col-md-4">-->
                            <!--<fieldset class="form-group">-->
                            <!--<label class="center-block">Track Id-->
                            <!--<input formControlName="track_id"-->
                            <!--class="form-control form-control-lg"/>-->
                            <!--</label>-->
                            <!--</fieldset>-->
                            <!--</div>-->


                        </div>


                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!voterSearchForm.valid">
                            Submit
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>