import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { CommonService } from 'src/app/core/services/common.service';
import { PanService } from 'src/app/core/services/pan.service';
import { PanSources } from '../../core/enums/pan-sources.enum';
import { PanDataTypes } from '../../core/enums/pan-data-types.enum';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-pan-excel-import',
    templateUrl: './pan-excel-import.component.html',
    styleUrls: [ './pan-excel-import.component.css' ],
} )
export class PanExcelImportComponent extends ImportExcelComponent implements OnInit {

    uploadForm: FormGroup;
    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    validationMessages: ValidationMessages = {};
    clients!: any[];
    PanSources = PanSources;
    PanDataTypes = PanDataTypes;
    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor (
        private panService: PanService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected commonService: CommonService,
        protected override fb: FormBuilder
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            'excel': [ '', Validators.required ],
        } );

    }

    setValidationMessages () {
        this.validationMessages[ 'pan_number' ] = 'Invalid pan number';

    }

    ngOnInit () {
        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

    }

    // Initialize the excelForm.
    initExcelForm () {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
            client_api_id: [ '', Validators.required ],
            driver: [ '', Validators.required ],
            data_type: [ '', Validators.required ],
        } );
    }

    applyValidations () {

        this.excelFormArray.controls.forEach( form => {
            form.get( 'pan_number' )?.setValidators( [ Validators.required, Validators.pattern( '^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$' ) ] );
            form.get( 'pan_number' )?.updateValueAndValidity();
            this.setValidationMessages();
        } );
    }

    importExcel () {

        this.panService.importExcel( this.excelForm.value ).subscribe(
            response => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ '/pan' ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
