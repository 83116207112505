<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">All Permissions</h1>
</div>

<div class="wrapper-md">
    <div class="row">
        <div class="col-lg-3">
            <a class="btn btn-primary btn-addon" (click)="permissionCreateModal.show()">
                <i class="fa fa-plus"></i>
                Create New Permission
            </a>

            <a class="btn btn-primary btn-addon" routerLink="/permissions/deleted/list">
                <i class="fa fa-list"></i>
                Deleted Permissions
            </a>
        </div>
    </div>
</div>

<app-permission-create #permissionCreateModal (onPermissionCreated)="onModalUpdated()"></app-permission-create>
<app-permission-edit #permissionEditModal (onPermissionUpdated)="onModalUpdated()"></app-permission-edit>

<div class="wrapper-md">
    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">Permissions</h3>
                </div>
                <div class="panel-body">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Subject</th>
                                <th>Action</th>
                                <th>Description</th>
                                <th>Edit</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let permission of permissions">
                                <td>{{ permission.subject }}</td>
                                <td>{{ permission.action }}</td>
                                <td>{{ permission.description }}</td>
                                <td>
                                    <a class="btn btn-sm btn-default" (click)="permissionEditModal.show(permission)">
                                        Edit
                                    </a>
                                </td>
                                <td>
                                    <button (click)="ondeletePermissionButtonClicked(permission.id)"
                                        class="btn btn-danger btn-xs"><i class="fa fa-trash"></i> Remove
                                    </button>
                                </td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</div>
