import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ClientApi } from '../../core/models/client-api.model';
import { ClientApiService } from '../../core/services/client-api.service';
import { CommonService } from '../../core/services/common.service';
import { Pagination } from 'src/app/core/models/pagination.model';
import { NavigationExtras, Router, Params } from '@angular/router';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';



@Component( {
    selector: 'app-client-api-list',
    templateUrl: './client-api-list.component.html',
    styleUrls: [ './client-api-list.component.css' ],
} )
export class ClientApiListComponent implements OnInit, OnDestroy {

    loading!: boolean;
    pagination: Pagination = new Pagination();
    protected total!: number;
    database!: string;
    filtersSubscription!: Subscription;

    constructor (
        private clientApiService: ClientApiService,
        private router: Router,
        private commonService: CommonService,
        private filterService: FilterService
    ) { }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.getAllApiList( params );

        } );

        this.filterService.filter();

    }

    getAllApiList ( params: Params ): void {

        this.clientApiService.getClientApisByEnvironment( params ).subscribe(

            response => {

                this.pagination = response;

                this.total = this.pagination.total;

            }

        );
    }

    // Load Next Page when using Pagination
    showPage ( page: number ) {

        this.filterService.filterByPage( page );

    }


    onModalUpdated () {

        this.filterService.filter();

    }

    ngOnDestroy () {

        this.filtersSubscription.unsubscribe();

    }


}
