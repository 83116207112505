<div class="panel panel-default" [formGroup]="educationForm">
    <div class="panel-heading">
        Education Verification

        <h4 class="panel-title pull-right">

            <div class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">
                <span class="glyphicon">
                    <input type="checkbox" (click)="sectionToggle($event)" [checked]="showSection"></span>

            </div>
        </h4>
    </div>
    <div id="collapse-education" [className]="showSection ? 'panel-collapse collapse in' : 'panel-collapse collapse'">
        <div class="panel-body">
            <div class="col-md-12 margin-bottom-10">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"></textarea>
            </div>

            <div class="col-md-5 margin-bottom-10">
                <button type="button" (click)="addEducation()" class="btn btn-primary">Add</button>
            </div>

            <div class="col-md-7 margin-bottom-10 text text-danger" *ngIf="educationVerificationArray.length==0">
                Please select atleast one verification
            </div>

            <div class="col-md-12 margin-bottom-10"
                *ngFor="let idForm of educationVerificationArray.controls;let i=index;">
                <div class="row" [formGroup]="idForm">
                    <div class="col-md-2">
                        <input type="checkbox" formControlName="required"> Is Required
                    </div>
                    <div class="col-md-9">
                        <select formControlName="type" class="form-control" (change)="skipSelectedItem(i,$event)">
                            <option [disabled]="selectedOption.includes(education.value)"
                                *ngFor="let education of educationList" [value]="education.value">
                                {{education.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-1">
                        <button type="button" (click)="educationVerificationArray.removeAt(i)" class="btn"><i
                                class="fa fa-trash text text-danger" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>