
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Company } from '../../core/models/company.model';
import { CompanyService } from '../../core/services/company.service';
import { CompanyRoleService } from 'src/app/core/services/company-role.service';
import { UserService } from 'src/app/core/services/user.service';

@Component( {
    selector: 'app-company-show',
    templateUrl: './company-show.component.html',
    styleUrls: [ './company-show.component.css' ],
} )
export class CompanyShowComponent implements OnInit {

    company: Company = new Company();
    users!: any;
    usersId: any[] = [];

    constructor ( private router: Router,
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private userService: UserService
    ) { }

    get isDisableClientListButton (): boolean {

        return this.userService.isAdmin || this.usersId.includes( this.userService.currentUser.id )

    }

    get isDisableOptionsForInactiveCompany (): boolean {
        return this.company.status === 1 ? true : false;
    }

    ngOnInit (): void {
        this.route.paramMap.pipe(
            switchMap( ( params: ParamMap ) => this.companyService.find(
                +params.get( 'id' )!
            ) ) )
            .subscribe( ( company: Company ) => {
                this.company = company
                this.users = company.users;
                this.updateUsersId();
            }
            );
    }

    updateUsersId () {

        this.usersId = [];

        this.users.forEach( ( item: any ) => {

            this.usersId.push( item.id );

        } );
    }

}
