import { ConfirmationModalService, ConfirmationModalOptions } from 'src/app/core/services/confirmation-modal.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Company } from 'src/app/core/models/company.model';
import { Subscription } from 'rxjs';
import { Params } from '@angular/router';
import { ClientCrmService } from 'src/app/core/services/client-crm.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { Pagination } from 'src/app/core/models/pagination.model';

@Component( {
    selector: 'app-client-crm-list',
    templateUrl: './client-crm-list.component.html',
} )
export class ClientCrmListComponent implements OnInit {

    company: Company = new Company();
    companyId!: number;
    pagination!: Pagination;
    filtersSubscription!: Subscription;

    constructor (
        public fb: FormBuilder,
        public clientCrmService: ClientCrmService,
        public commonService: CommonService,
        private filterService: FilterService,
        private confirmationModalService: ConfirmationModalService
    ) {
    }

    ngOnInit (): void {

        this.fetchAll();
        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

    filterResults ( params: Params ): void {

        const updatedParams = this.updateParams( params );

        this.clientCrmService.filter( updatedParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );
    }

    updateParams ( params: any ): Params {
        params = Object.assign( {}, params );

        Object.keys( params ).forEach( key => {
            if ( key === 'company_id' || key == 'lot_id' ) { params[ key ] = params[ key ].toString(); }
        } );

        return params;
    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }
    fetchAll (): void {

        this.clientCrmService.getAll().subscribe( response => {
            this.pagination = response;
        } );
    }

    deleteCrm ( crmId: number ): void {

        this.clientCrmService.delete( crmId ).subscribe( ( response ) => {

            if ( response ) {

                this.fetchAll();

                this.commonService.notifications.success( response.message );
            }

        } );

    }

    onDeleteCrmButtonClicked ( crmId: number ): void {

        const options: ConfirmationModalOptions = {
            text: 'This change can not be reversed'
        };

        this.confirmationModalService.confirm( options, () => this.deleteCrm( crmId ) );

    }

}

