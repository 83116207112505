<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Criminal Search</h1>
</div>


<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Name</label>
                        <input class="form-control" formControlName="name">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Address</label>
                        <input class="form-control" formControlName="address">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By State</label>
                        <input class="form-control" formControlName="state">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By District</label>
                        <input class="form-control" formControlName="district">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 25%">Name</th>
                        <th style="width: 25%">Address</th>
                        <th style="width: 25%">Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let row of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li *ngIf="row.photo">
                                    <a href="{{row.photo}}" target="_blank"><img [src]="row.photo" width="50px" /></a>
                                </li>
                                <li><strong>{{row.name| uppercase}}</strong></li>
                                <li *ngIf="row.relative_name">
                                    <strong>Relative Name:</strong>
                                    {{row.relative_name}}
                                </li>
                                <li *ngIf="row.dob">
                                    <strong>DOB:</strong>
                                    {{row.dob}}
                                </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>{{row.address}} {{row.state}} {{row.district}}</li>
                                <li *ngIf="row.temporary_address"><strong>Temporary Address:
                                    </strong>{{row.temporary_address}}
                                </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li *ngFor="let detail of row.details">
                                    <div *ngIf="detail.key=='Photo'; else elseBlock">
                                        <strong>{{detail.key}}</strong>:
                                        <a href="{{detail.value}}" target="_blank">
                                            {{detail.value}}
                                        </a>
                                    </div>
                                    <ng-template #elseBlock><strong>{{detail.key}}:</strong> {{detail.value}}
                                    </ng-template>

                                </li>
                                <li><strong>Source: </strong> {{row.source}}</li>

                            </ul>

                        </td>


                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>