import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';


export function validateCompletionDate ( string1: string, string2: string ) {

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        if ( !control.value ) {
            return {};
        }

        if ( control.value <= string1 && control.value >= string2 ) {
            return {};
        }

        return { 'validateCompletionDate': { value: control.value } };
    };
}


@Directive( {
    selector: '[validateDate]',
    providers: [ { provide: NG_VALIDATORS, useExisting: CompletionDateValidatorDirective, multi: true } ],
} )
export class CompletionDateValidatorDirective implements Validator {

    @Input()
    string1!: string;
    string2!: string;

    validate ( control: AbstractControl ): { [ key: string ]: any } {

        return validateCompletionDate( this.string1, this.string2 )( control );
    }
}
