import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { VehicleService } from '../core/services/vehicle.service';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { VehicleHtmlResponseModalComponent } from './vehicle-html-response-modal/vehicle-html-response-modal.component';
import { VehicleStatsComponent } from './vehicle-stats/vehicle-stats.component';
import { VehicleApiResponseModalComponent } from './vehicle-api-response-modal/vehicle-api-response-modal.component';
import { VehicleSearchModalComponent } from './vehicle-search-modal/vehicle-search-modal.component';
import { VehicleExcelImportComponent } from './vehicle-excel-import/vehicle-excel-import.component';
import { VehicleFiltersComponent } from './vehicle-filters/vehicle-filters.component';
import { VehicleListItemComponent } from './vehicle-list-item/vehicle-list-item.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        VehicleListComponent,
        VehicleDetailsComponent,
        VehicleHtmlResponseModalComponent,
        VehicleStatsComponent,
        VehicleApiResponseModalComponent,
        VehicleSearchModalComponent,
        VehicleExcelImportComponent,
        VehicleFiltersComponent,
        VehicleListItemComponent,
    ],
    providers: [ VehicleService ],
} )
export class VehicleModule {
}
