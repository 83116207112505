import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalDbLogSources } from 'src/app/core/enums/global-db-log-sources.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalDbLogService } from 'src/app/core/services/global-db-log.service';

@Component({
  selector: 'app-global-db-log-search-modal',
  templateUrl: './global-db-log-search-modal.component.html',
  styleUrls: ['./global-db-log-search-modal.component.css']
})
export class GlobalDbLogSearchModalComponent {

    @ViewChild( 'globalDbLogSearchModal', { static: false } )
    public globalDbLogSearchModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();


    isSubmitting = false;
    globalDbLogSearchForm!: FormGroup;
    globalDbLogSources = GlobalDbLogSources;

    constructor (
        private fb: FormBuilder,
        private commonService: CommonService,
        private globalDbLogService: GlobalDbLogService
    ) {

        this.initForm();
    }

    get name () {
        return this.globalDbLogSearchForm.get( 'name' ) as FormControl;
    }

    initForm (): void {
        this.globalDbLogSearchForm = this.fb.group( {
            name: [ '', [ Validators.required ] ],
            date_of_birth: [ '' ],
            gender: [ '' ],
            agency: [ '' ],
            pdf_report: [ '' ],
            name_match: [ '' ],
            name_match_threshold: [ '' ],
            driver: [ '', [ Validators.required ] ],
        } );
    }

    show (): void {
        this.globalDbLogSearchModal.show();
    }

    hide (): void {
        this.initForm();
        this.globalDbLogSearchModal.hide();
    }

    search (): void {

        this.isSubmitting = true;
        this.globalDbLogService.search( this.globalDbLogSearchForm.value ).subscribe( () => {
            this.commonService.notifications.success( 'Request Sent Successfully' );
            this.isSubmitting = false;
            this.hide();
            this.onSearch.emit( true );
        }, ( error ) => {
            this.commonService.displayValidationErrors( error );
            this.isSubmitting = false;
            this.hide();
        } );
    }
}
