import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root'
} )
export class FilterConfigService {
    getFilters ( filters: { value: any; }[], verification: any ) {
        const objectIndex = this.search( filters, verification );

        if ( objectIndex !== null ) return filters[ objectIndex ].value;

        return null;
    }

    setFilters ( selectedFilters: string[], verification: any ) {
        const filters: any =
            localStorage.getItem( 'filters' ) !== null
                ? JSON.parse( localStorage.getItem( 'filters' )! )
                : [];
        const obj = {
            name: verification,
            value: selectedFilters,
        };

        if ( localStorage.getItem( 'filters' ) !== null ) {
            const objectIndex = this.search( filters, verification );
            if ( objectIndex !== null ) filters.splice( objectIndex, 1 );
        }
        filters.push( obj );
        localStorage.setItem( 'filters', JSON.stringify( filters ) );
    }

    search ( filters: string | any[], objectKey: string ) {
        for ( let objectIndex = 0; objectIndex < filters.length; objectIndex++ ) {
            if ( filters[ objectIndex ].name === objectKey ) {
                return objectIndex;
            }
        }

        return null;
    }
}
