import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { User } from '../../core/models/user.model';
import { CommonService } from '../../core/services/common.service';
import { WorkService } from '../../core/services/work.service';


@Component( {
    selector: 'app-work-assign-modal',
    templateUrl: './work-assign-modal.component.html',
    styleUrls: [ './work-assign-modal.component.css' ],
    providers: [ WorkService ],
} )
export class WorkAssignModalComponent {

    type_id!: number; // Work type id.
    data!: {};
    types!: any[];


    @ViewChild( 'workAssignModal', { static: false } )
    public workAssignModal!: ModalDirective;

    @Input()
    checkedIds!: any[];

    @Input()
    verification!: string;

    @Output()
    onWorkAssigment: EventEmitter<boolean> = new EventEmitter<boolean>();

    user!: User;

    constructor (
        private workService: WorkService,
        private commonService: CommonService
    ) { }

    show ( user: User ): void {

        this.user = user;
        this.workService.types( this.verification ).subscribe( response => this.types = response );
        this.workAssignModal.show();

    }

    hide (): void {
        this.workAssignModal.hide();
    }

    setData (): void {
        this.data = {
            type_id: this.type_id,
            verification_ids: this.checkedIds,
            user_id: this.user.id,
            verification_type: this.verification,
        };
    }

    /**
     * Common function to assign work to the selected user.
     */
    assignWork ( type: any ): void {

        this.data = {
            type_id: this.type_id,
            verification_ids: this.checkedIds,
            user_id: this.user.id,
            verification_type: this.verification,
            type, // Tells whether to assign or reassign
        };
        if ( this.type_id ) {
            this.workService.assign( this.data ).subscribe(
                {
                    next: ( response: any ) => {

                        if ( ( response[ 'already_assigned' ] ).length ) {

                            this.commonService.notifications.info( 'Already Assigned TrackIds:',
                                response[ 'already_assigned' ] );
                        }
                        if ( response[ 'number_of_work_assignments' ] ) {

                            this.commonService.notifications
                                .success( 'Success', response[ 'message' ] + ':' + response[ 'number_of_work_assignments' ] );
                        }

                        this.hide();
                        this.onWorkAssigment.emit( true );
                    },
                    error: ( errors: any ) => {

                        this.commonService.notifications.error( 'Error', 'Unable to assign' );
                        this.hide();
                    }
                }
            );
        } else {

            this.hide();
            this.commonService.notifications.error( 'Error', 'Select a work type' );
        }

    }

}
