<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" (click)="reload()">
                    <span aria-hidden="true"><span class="glyphicon glyphicon-refresh"></span></span>
                </button>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="reportPdfUrl">
                <iframe [src]="reportPdfUrl" width="100%" height="800px"></iframe>
            </div>
        </div>
    </div>
</div>