import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../../core/services/common.service'

import { ModalDirective } from 'ngx-bootstrap/modal';
import { CompanyRole } from '../../../core/models/company-role.model';
import { CompanyRoleService } from '../../../core/services/company-role.service';

@Component( {
    selector: 'app-role-edit',
    templateUrl: './role-edit-modal.component.html'
} )
export class RoleEditModalComponent implements OnInit {

    isSubmitting = false;
    roleEditForm!: FormGroup;
    role: CompanyRole = new CompanyRole();
    error: any = [];

    @ViewChild( 'roleEditModal', { static: false } )
    public roleEditModal!: ModalDirective;

    @Output()
    onRoleUpdated = new EventEmitter<boolean>();

    get name () {
        return this.roleEditForm.get( 'name' );
    }

    constructor (
        private companyRoleService: CompanyRoleService,
        private common: CommonService,

        private fb: FormBuilder ) {
    }

    ngOnInit () {

        this.initForm();
    }

    initForm (): void {
        this.roleEditForm = this.fb.group( {
            'name': [ '', [ Validators.required, Validators.pattern( '[a-zA-Z ]*' ) ] ]
        } );
    }

    show ( role: CompanyRole ): void {

        this.role = role;
        this.error = [];
        this.roleEditForm.patchValue( this.role );
        this.roleEditModal.show();
    }

    hide (): void {
        this.roleEditModal.hide();
    }


    updateRole (): void {

        this.isSubmitting = true;
        this.companyRoleService.update( this.role.id, this.roleEditForm.value ).subscribe(
            ( response: any ) => {
                this.common.notifications.success( 'Role Data Updated Successfully!' );
                this.isSubmitting = false;
                this.hide();
                this.onRoleUpdated.emit( true );
            },
            error => {
                this.isSubmitting = false;
                this.error = error.errors;
                this.common.notifications.error( error.message );
            } );

    }

}
