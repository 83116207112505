import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { ClientService } from 'src/app/core/services/client.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { HttpParams } from '@angular/common/http';

@Component( {
    selector: 'app-all-clients-list',
    templateUrl: './all-clients-list.component.html',
    styleUrls: [ './all-clients-list.component.css' ]
} )
export class AllClientsListComponent implements OnInit {

    pagination: any;
    filtersSubscription: any;
    filterParams: HttpParams = new HttpParams();

    constructor ( private clientService: ClientService,
        private filterService: FilterService,
    ) {
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: HttpParams ) => {
            this.filterResults();
            this.filterParams = params;
        } );

        this.filterResults();

    }


    filterResults (): void {

        this.clientService.getAll( this.filterService.filters ).subscribe(
            ( response: any ) => {

                this.pagination = response;

            }
        );

    }

    exportExcel () {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.filterService.httpParams,
            paramsString: this.filterService.httpParams?.toString(),
            route: 'clients/download-excel',
            fileName: 'Clients Lits',
            method: 'get',
            postParams: null,
        };

        this.clientService.showDownloadModal( initialState );
    }
}
