import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmploymentCompany } from '../../core/models/employment-company.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-employment-edit-company',
    templateUrl: './employment-edit-company.component.html',
    styleUrls: [ './employment-edit-company.component.css' ],
} )
export class EmploymentEditCompanyComponent {

    isSubmitting: boolean = false;
    company: EmploymentCompany = new EmploymentCompany();

    companyEditForm: FormGroup;

    @ViewChild( 'companyEditModal', { static: false } ) companyEditModal!: ModalDirective;

    constructor (
        private router: Router,
        private employmentService: EmploymentService,
        private commonService: CommonService,
        public global: GlobalService,
        private fb: FormBuilder
    ) {
        this.companyEditForm = this.fb.group( {
            id: [ '' ],
            company_name: [ '' ],
            address: [ '' ],
            state_id: [ '' ],
            state_name: [ '' ],
            district_id: [ '' ],
            district_name: [ '' ],
            pincode: [ '' ],
            company_remarks: [ '' ],
            website: [ '' ],
            email: [ '' ],
            contact_number: [ '' ],
        } );
    }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    show ( company: EmploymentCompany ): void {

        this.company = company;
        this.companyEditForm.patchValue( this.company );
        this.companyEditModal.show();

    }

    hide (): void {
        this.companyEditModal.hide();
    }

    /**
     * Common function to assign work to the selected user.
     */
    updateEmploymentCompany (): void {
        this.isSubmitting = true;
        this.employmentService.updateEmploymentCompany( this.companyEditForm.value.id, this.companyEditForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Company Updated' );
                this.router.navigate( [ '/employment/companies' ] );
                this.isSubmitting = false;
                this.hide();
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Unable to update company' );
                this.router.navigate( [ '/employment/companies' ] );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }


}
