<app-cases-create #caseCreateModal (created)="onCaseCreated($event)"></app-cases-create>
<app-cases-edit #editCaseModal (updated)="onCaseUpdated($event)"></app-cases-edit>

<div *ngIf="cases">
    <h3>
        <!-- <a (click)="showCreateModal()" class="btn btn-primary">Create Case</a> -->
        <a (click)="addCase()" class="btn btn-primary">Add Case</a>
    </h3>
    <table class="table" *ngIf="cases">
        <thead>
            <th>No.</th>
            <th>ID</th>
            <th>Vcourt Case ID</th>
            <th>CNR Number</th>
            <th>Nature Of Case</th>
            <th>Candidate Category</th>
            <th>District Court</th>
            <th>Verification Status</th>
            <th>Edit</th>
            <th>Delete</th>
        </thead>
        <tr *ngFor="let case of cases; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ case.id }}</td>
            <td>{{ case.court_search_result_id }}</td>
            <td>{{ case.cnr_number }}</td>
            <td>{{ case.nature_of_case }}</td>
            <td>{{ case.candidate_category }}</td>
            <td>{{ case.district_court }}</td>
            <td *ngIf="case.status &&  case.status?.title;else emptyTd">{{ case.status.title }}</td>
            <ng-template #emptyTd>
                <td></td>
            </ng-template>
            <td><a (click)="showEditModal(case)" class="btn btn-xs btn-primary">Edit</a></td>
            <td><a (click)="deleteCase(case)" class="btn btn-xs btn-danger">Delete</a></td>
        </tr>
    </table>
</div>
