import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GstService } from '../../core/services/gst.service';

@Component( {
    selector: 'app-gst-details-modal',
    templateUrl: './gst-details-modal.component.html',
    styleUrls: [ './gst-details-modal.component.css' ],
} )
export class GstDetailsModalComponent {

    details!: any[];
    @ViewChild( 'gstDetailsModal', { static: false } ) modal!: ModalDirective;

    constructor (
        private gstService: GstService,
    ) { }

    show ( logId: number ): void {
        this.gstService.show( logId ).subscribe( response => {
            this.details = response;
            this.modal.show();
        } );
    }

    hide (): void {
        this.modal.hide();
    }

}
