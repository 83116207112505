import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from './core/services/user.service';
import { ShortcutService } from './core/services/shortcut.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthService } from './core/services/auth.service';
import { CommonService } from './core/services/common.service';
import { JwtService } from './core/services/jwt.service';
import { filter, map } from 'rxjs';
import { GlobalService } from './core/services/global.service';

declare let gtag: Function;

@Component( {
    selector: 'app-root',
    templateUrl: './app.component.html',
} )
export class AppComponent implements OnInit {
    title = 'app';

    public notificationOptions = {
        timeOut: 3000,
        lastOnBottom: true,
        clickToClose: true,
        showProgressBar: false,
        animate: null,
    };

    constructor (
        private userService: UserService,
        private shortcuts: ShortcutService,
        public router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private commonService: CommonService,
        private jwtService: JwtService,
        private globalService: GlobalService

    ) {
        this.googleAnalytics();
    }

    ngOnInit () {


        if ( this.jwtService.getToken() ) {

            // this.commonService.populateGlobalVariables();
            this.globalService.populateGlobalVariables();

        }

        // this.authService.userLoggedIn.subscribe(

        //     e => {

        //        this.commonService.populateGlobalVariables()
        //     } );

    }

    getCurrentUser () {

        return this.userService.getCurrentUser();

    }


    /**
     * When alt + right arrow key is pressed
     *
     * @param event
     */
    @HostListener( 'document:keydown.control.arrowright', [ '$event' ] )
    onCtrlRightArrow ( event: any ) {

        this.shortcuts.nextPressed.emit();

    }

    /**
     * When alt + left arrow key is pressed
     *
     * @param event
     */
    @HostListener( 'document:keydown.control.arrowleft', [ '$event' ] )
    onCtrlLeftArrow ( event: any ) {

        this.shortcuts.backPressed.emit();

    }

    /**
     * Send information to google analytics
     *
     */
    googleAnalytics () {

        if ( environment.production ) {

            const navEndEvents = this.router.events.pipe(

                filter( event => event instanceof NavigationEnd ),

                map( () => {

                    const obj = this.router.url.split( '?' )[ 0 ]

                    return obj;

                } )

            );


            navEndEvents.subscribe( ( url: any ) => {

                gtag( 'config', environment.gtag_config, { 'page_path': url } );

            } );

        }

    }

}
