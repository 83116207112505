import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { PermitService } from '../../core/services/permit.service';

@Component( {
    selector: 'app-permit-details',
    templateUrl: './permit-details.component.html',
    styleUrls: [ './permit-details.component.css' ],
} )
export class PermitDetailsComponent {

    vehiclerc: any;
    rc_number!: string;

    @ViewChild( 'permitDetails', { static: false } )
    public vehicleDetails!: ModalDirective;

    constructor ( private permitService: PermitService,
        private commonService: CommonService, ) {
    }

    show ( rcNumber: string ) {

        this.rc_number = rcNumber;

        this.permitService.getDetails( rcNumber ).subscribe(
            response => {
                this.vehiclerc = response;
                this.vehicleDetails.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );

    }

    hide () {
        this.vehicleDetails.hide();
    }

}
