export enum IdentityVerificationStatusType {
    UnderProcess = 0,
    Verified = 1,
    RecordDoesNotExist = 2,
    DiscrepancyFound = 3,
    UnableToValidate = 4,
    DocumentRequired = 5,
    AadhaarCardExists = 6,
    AadhaarCardValid = 7,
    DrivingLicenseValid = 8,
    DataDoesNotMatch = 9,
    UnableToValidateLicence = 10,
    VerifiedEpicNoChanged = 11,
    EmploymentTerminated = 12,
    Valid = 13,
    ClientTerminated = 14,
    SentToRto = 15,
    VerifiedRto = 16,
    FailureRto = 17,
    VerificationStopped = 18,
    NotApplicable = 19,
    DataEntryDone = 20,
    InvalidDocument = 21,
    DlToBePrinted = 22,
    VerifiedRtoVerbal = 23,
    FailureRtoVerbal = 24,
    AutoVerified = 25,
    AutoTimeout = 26,
    AutoFailure = 27,
    SentToVendor = 28,
    AutoSecondaryNotFound = 29,
    CroppingDone = 30,
    AutoDiscrepancyFound = 31,
    DeCorrect = 32,
    AutoProcessing = 33,
    EmployeeTerminatedAtCalling = 34,

    // status currently not present in database
    ProofFound = -1,
    ProofNotFound = -1,
    Timeout = -1,
}

