import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { WorkService } from '../core/services/work.service';
import { EducationListComponent } from './education-list/education-list.component';
import { EducationService } from '../core/services/education.service';
import { EducationEditComponent } from './education-edit/education-edit.component';
import { EducationAssignedWorkListComponent } from './education-assigned-work-list/education-assigned-work-list.component';
import { EducationInstitutesListComponent } from './institutes/education-institutes-list/education-institutes-list.component';

@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,

    ],
    declarations: [
        EducationListComponent,
        EducationEditComponent,
        EducationAssignedWorkListComponent,
        EducationInstitutesListComponent,
    ],
    providers: [ EducationService, WorkService ],
} )
export class EducationModule {
}
