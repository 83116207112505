import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-pdf-viewer-popup',
    templateUrl: 'pdf-viewer-popup.component.html',
    styleUrls: [ './pdf-viewer-popup.component.css' ],
} )

export class PdfViewerPopupComponent {
    @Output() onReload: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild( 'editModal', { static: false } ) editModal!: ModalDirective;
    @Input() reportPdfUrl!: string;

    show (): void {
        this.editModal.show();
    }

    closePopup (): void {
        this.editModal.hide();
    }

    reload (): void {
        this.onReload.emit( true );
    }
}
