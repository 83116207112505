import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class SmsService {

    constructor ( private api: ApiService ) {
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'sms', params );
    }

    resend ( id: string ) {
        return this.api.get( 'sms/' + id + '/resend' );
    }

    resendAll ( filterParam: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'sms/resend-all', filterParam );
    }

    getStats ( params: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'sms/stats', params );
    }
}

