export enum DocumentVerificationTypes {
    NO_DOCUMENT = 0,
    VEHICLE_RC = 1,
    ALL_INDIA_PERMIT = 2,
    CONTRACT_CARRIAGE_PERMIT = 3,
    PSV_BADGE = 4,
    FITNESS_CERTIFICATE = 5,
    INSURANCE_CERTIFICATE = 6,
    CREDIT_REPORT = 7,
    GST_CERTIFICATE = 8,
    MCA_CHECK = 9,
    BUSINESS_REGISTRATION = 10,
    BUSINESS_OWNERSHIP_DOCUMENT = 11,
    BANK_STATEMENT = 12,
    INCOME_TAX_RETURNS = 13,
    ECHALLAN_DRIVING_LICENSE = 14,
}
