<div bsModal #revertForm="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <form [formGroup]="revertEditForm" (ngSubmit)="updateRevertForm()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg" *ngIf="details && verification">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left" *ngIf="verification">
                        Revert received from <strong>{{details['filled_by']}}</strong> for verification of
                        <strong>{{verification.employee_name}}</strong>
                        at
                        <strong>{{verification.employment_company_name}}</strong>
                    </h4>

                </div>
                <div class="modal-body">
                    <table class="table table-condensed">
                        <thead>

                            <tr>
                                <th>Component Name</th>
                                <th style="width: 30%">Detail Verified</th>
                                <th>Response Received</th>
                                <th>Remarks</th>
                            </tr>

                        </thead>

                        <tbody>
                            <tr>
                                <td>Employee Id</td>
                                <td *ngIf="verification"><input formControlName="previous_employee_id"
                                        class="form-control form-control-lg"
                                        ngModel="{{verification.previous_employee_id}}" required /></td>
                                <td>

                                    <select formControlName="is_employee_id_correct"
                                        ngModel="{{details['is_employee_id_correct']}}">
                                        <option value="Correct">Correct</option>
                                        <option value="InCorrect">InCorrect</option>
                                        <option value="Not Specified">Not Specified</option>
                                    </select>

                                </td>
                                <td>
                                    <input formControlName="employee_id_remarks" class="form-control form-control-lg"
                                        ngModel="{{details['employee_id_remarks']}}" [disabled]="true" />
                                </td>
                            </tr>

                            <tr>
                                <td>Date Of Joining</td>
                                <td><input type="date" formControlName="joining_date"
                                        class="form-control form-control-lg" ngModel="{{verification.joining_date}}"
                                        required /></td>
                                <td>

                                    <select formControlName="is_date_of_joining_correct"
                                        ngModel="{{details['is_date_of_joining_correct']}}">
                                        <option value="Correct">Correct</option>
                                        <option value="InCorrect">InCorrect</option>
                                        <option value="Not Specified">Not Specified</option>
                                    </select>

                                </td>
                                <td><input formControlName="date_of_joining_remarks"
                                        class="form-control form-control-lg"
                                        ngModel="{{details['date_of_joining_remarks']}}" [disabled]="true" /></td>
                            </tr>

                            <tr>
                                <td>Date of Relieving</td>
                                <td><input type="date" formControlName="end_date" class="form-control form-control-lg"
                                        ngModel="{{verification.end_date}}" required /></td>
                                <td>

                                    <select formControlName="is_date_of_relieving_correct"
                                        ngModel="{{details['is_date_of_relieving_correct']}}">
                                        <option value="Correct">Correct</option>
                                        <option value="InCorrect">InCorrect</option>
                                        <option value="Not Specified">Not Specified</option>
                                    </select>

                                </td>
                                <td><input formControlName="date_of_relieving_remarks"
                                        class="form-control form-control-lg"
                                        ngModel="{{details['date_of_relieving_remarks']}}" [disabled]="true" /></td>
                            </tr>

                            <tr>
                                <td>Designation</td>
                                <td><input formControlName="designation" class="form-control form-control-lg"
                                        ngModel="{{verification.designation}}" required /></td>
                                <td>

                                    <select formControlName="is_designation_correct"
                                        ngModel="{{details['is_designation_correct']}}">
                                        <option value="Correct">Correct</option>
                                        <option value="InCorrect">InCorrect</option>
                                        <option value="Not Specified">Not Specified</option>
                                    </select>

                                </td>
                                <td><input formControlName="designation_remarks" class="form-control form-control-lg"
                                        ngModel="{{details['designation_remarks']}}" [disabled]="true" /></td>
                            </tr>

                            <tr>
                                <td>Salary</td>
                                <td><input formControlName="salary" class="form-control form-control-lg"
                                        ngModel="{{verification.salary}}" required /></td>
                                <td>

                                    <select formControlName="is_salary_correct"
                                        ngModel="{{details['is_salary_correct']}}">
                                        <option value="Correct">Correct</option>
                                        <option value="InCorrect">InCorrect</option>
                                        <option value="Not Specified">Not Specified</option>
                                        <option value="Confidential">Confidential</option>
                                    </select>

                                </td>
                                <td><input formControlName="salary_remarks" class="form-control form-control-lg"
                                        ngModel="{{details['salary_remarks']}}" [disabled]="true" /></td>
                            </tr>

                            <tr>
                                <td>Department</td>
                                <td><input formControlName="department" class="form-control form-control-lg"
                                        ngModel="{{verification.department}}" required /></td>
                                <td>

                                    <select formControlName="is_department_correct"
                                        ngModel="{{details['is_department_correct']}}">
                                        <option value="Correct">Correct</option>
                                        <option value="InCorrect">InCorrect</option>
                                        <option value="Not Specified">Not Specified</option>
                                    </select>

                                </td>
                                <td><input formControlName="department_remarks" class="form-control form-control-lg"
                                        ngModel="{{details['department_remarks']}}" [disabled]="true" /></td>
                            </tr>

                            <tr>
                                <td>Status of Exit Formality</td>
                                <td>-</td>
                                <td>

                                    <select formControlName="exit_formalities_completed"
                                        ngModel="{{details['exit_formalities_completed']}}">
                                        <option value="Pending">Pending</option>
                                        <option value="Completed">Completed</option>
                                    </select>

                                </td>
                                <td><input formControlName="exit_formalities_remarks"
                                        class="form-control form-control-lg"
                                        ngModel="{{details['exit_formalities_remarks']}}" [disabled]="true" />
                                </td>
                            </tr>

                            <tr>
                                <td>Rehire Eligibility</td>
                                <td>-</td>
                                <td>

                                    <select formControlName="rehire_eligibility"
                                        ngModel="{{details['rehire_eligibility']}}">
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>

                                </td>
                                <td><input formControlName="rehire_eligibility_remarks"
                                        class="form-control form-control-lg"
                                        ngModel="{{details['rehire_eligibility_remarks']}}" [disabled]="true" />
                                </td>
                            </tr>

                            <tr>
                                <td>Attached Documents Genuine</td>
                                <td>-</td>
                                <td>

                                    <select formControlName="documents_genuine"
                                        ngModel="{{details['documents_genuine']}}">
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>

                                </td>
                                <td><input formControlName="documents_genuine_remarks"
                                        class="form-control form-control-lg"
                                        ngModel="{{details['documents_genuine_remarks']}}" [disabled]="true" />
                                </td>
                            </tr>

                            <tr>
                                <td>Reason for Leaving</td>
                                <td>{{details['reason_for_leaving']}}</td>
                            </tr>
                            <tr>
                                <td>Additional Remarks</td>
                                <td>{{details['additional_remarks']}}</td>
                            </tr>

                            <tr>
                                <td>Verifying Authority Email</td>
                                <td>{{details['verifying_authority_email']}}</td>
                            </tr>

                            <tr>
                                <td>Verifying Authority Contact</td>
                                <td>{{details['verifying_authority_contact']}}</td>
                            </tr>

                            <tr>
                                <td>Recieved at</td>
                                <td *ngIf="details['verifying_authority_email']">{{details['updated_at']}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!revertEditForm.valid"
                            (click)="updateRevertForm()">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>