<div class="row bg-light lter b-b wrapper-md">


    <div class="wrapper-md">
        <div class="panel">

            <div class="panel-heading font-bold">
                <h3>API REQUEST LOGS</h3>
            </div>
            <div class="panel-body">
                <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()"
                    (keydown.enter)=" $event.preventDefault()">
                    <div class="row">

                        <div class="col-md-2 form-group">
                            <label class="center-block">Creation After</label>
                            <input class="form-control" type="date" formControlName="created_after" />
                        </div>

                        <div class="col-md-2 form-group">
                            <label class="center-block">Creation Before</label>
                            <input class="form-control" type="date" formControlName="created_before" />
                        </div>

                        <div class="col-md-2 form-group">
                            <label class="center-block">Filter by Client</label>
                            <select class="form-control" formControlName="client">
                                <option *ngFor="let client of clients" [value]="client.id">
                                    {{ client.owner_name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group col-md-2">
                            <label class="center-block">Select Source</label>
                            <select class="form-control" formControlName="via">
                                <option value="all">All</option>
                                <option value="crm">CRM</option>
                                <option value="api">API</option>
                                <option value="excel">Excel</option>
                                <option value="dexter">Dexter</option>
                            </select>
                        </div>


                        <div class="form-group col-md-2">
                            <label class="center-block">Click here to</label>
                            <button class="btn btn-primary btn-block">
                                Apply Filters
                            </button>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Click here to</label>
                            <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                                Reset
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="wrapper-md">
        <div class="panel">
            <table class="table table-bordered">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Client</th>
                        <th scope="col">PAN</th>
                        <!-- <th scope="col">Aadhaar</th>
                    <th scope="col">Gst</th> -->
                        <th scope="col">Voter</th>
                        <th scope="col">Vehicle Rc</th>
                        <th scope="col">Driving Licence</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of data">
                        <td>{{row.client_name}}</td>
                        <td>
                            <ul *ngFor="let pan of row.pan">
                                <li>{{pan.status}} : {{pan.count}}</li>
                            </ul>

                        </td>

                        <!-- <td>
                        <ul *ngFor="let aadhaar of row.aadhaar">
                            <li>{{aadhaar.status}} : {{aadhaar.count}}</li>
                          </ul>

                    </td>

                    <td>
                        <ul *ngFor="let gst of row.gst">
                            <li>{{gst.status}} : {{gst.count}}</li>
                          </ul>

                    </td> -->

                        <td>
                            <ul *ngFor="let voter of row.voter">
                                <li>{{voter.status}} : {{voter.count}}</li>
                            </ul>

                        </td>

                        <td>
                            <ul *ngFor="let vehiclerc of row.vehicle_rc">
                                <li>{{vehiclerc.status}} : {{vehiclerc.count}}</li>
                            </ul>

                        </td>

                        <td>
                            <ul *ngFor="let dl of row.driving_licence">
                                <li>{{dl.status}} : {{dl.count}}</li>
                            </ul>

                        </td>

                    </tr>


                </tbody>

            </table>
        </div>
    </div>
</div>