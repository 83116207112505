import { EmploymentService } from './../../core/services/employment.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component( {
    selector: 'app-employment-email-log-modal',
    templateUrl: './employment-email-log-modal.component.html',
    styleUrls: [ './employment-email-log-modal.component.css' ],
} )
export class EmploymentEmailLogModalComponent implements OnInit {

    emailLogs: any;

    @ViewChild( 'emailLogModal', { static: false } ) emailLogModal!: ModalDirective;

    constructor (
        private employmentService: EmploymentService,
    ) { }

    ngOnInit (): void {
    }

    show ( verificationId: number ): void {

        this.employmentService.getEmailLogs( verificationId ).subscribe( response => {
            this.emailLogs = response;
        } );

        this.emailLogModal.show();
    }

    hide (): void {
        this.emailLogModal.hide();
    }

}
