import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Institute } from '../../../core/models/institute.model';
import { CommonService } from '../../../core/services/common.service';
import { EducationService } from '../../../core/services/education.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-education-institutes-edit',
    templateUrl: './education-institutes-edit.component.html',
    styleUrls: [ './education-institutes-edit.component.css' ],
} )
export class EducationInstitutesEditComponent implements OnInit {

    institute: Institute = new Institute();
    editForm!: FormGroup;
    isSubmitting: boolean = false;
    instituteId: number = 0;


    @ViewChild( 'instituteEditModal', { static: false } ) instituteEditModal!: ModalDirective;

    @Output()
    onInstituteEdited: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private educationService: EducationService,
        private fb: FormBuilder,
        public global: GlobalService,
        private commonService: CommonService
    ) { }

    ngOnInit (): void {
        this.initForm();
    }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    initForm (): void {
        this.editForm = this.fb.group( {
            id: [],
            name: [ '' ],
            address: [ '' ],
            state_id: [ '' ],
            state_name: [ '' ],
            district_id: [ '' ],
            district_name: [ '' ],
            pincode: [ '' ],
            instruction: [ '' ],
            remarks: [ '' ],
            website: [ '' ],
            type: [ '' ],
            email: [ '' ],
            recipient_name: [ '' ],
            beneficiary_name: [ '' ],
            phone_number: [ '' ],
        } );
    }

    show ( institute: Institute ): void {
        this.instituteId = institute.id;
        this.educationService.findInstitute( this.instituteId )
            .subscribe( response => {
                this.institute = response;
                this.editForm.patchValue( this.institute );
            } );
        this.instituteEditModal.show();
    }

    hide (): void {
        this.initForm();
        this.instituteEditModal.hide();
    }

    updateInstitute (): void {
        this.isSubmitting = true;
        this.educationService.updateInstitute( this.editForm.value.id, this.editForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Success', 'Institute Updated' );
                this.initForm();
                this.hide();
                this.onInstituteEdited.emit( true );

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error', 'Unable to update Institute' );
                this.hide();
            }
        );
    }
}
