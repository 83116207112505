<div class="panel panel-default">
    <div class="panel-heading">
        Consent Form
    </div>

    <div class="panel-body">

        <div class="row">
            <div class="form-group col-md-12" *ngIf="consentForms && consentForms.length === 0">
                <label class="center-block">Consent Form
                    <input appUploadDocument (uploaded)="onFileUploaded($event, employeeId)" type="file"
                        class="form-control" [documentableId]="employeeId" documentableType="candidate"
                        documentType="consent_form" />
                </label>
            </div>
        </div>

        <div class="row">

            <div class="col-md-12">
                <div *ngFor="let form of consentForms">
                    <a href="{{ form.url }}" class="btn-link">
                        Consent Form
                    </a>
                    <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                        (click)="deleteEmploymentDocument(form, employeeId)">
                        <i class="glyphicon glyphicon-trash"></i>
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>