export class EmploymentCompany {
    id!: number;
    name!: string;
    state_id!: number;
    state_name!: string;
    district_id!: number;
    district_name!: string;
    full_address!: string;
    pincode!: number;
    email!: string;
    website!: string;
    address!: string;
    contact_number!: number;
    remarks!: string;
    updated_at!: string;
    created_at!: string;
    contacts!: any[];
}
