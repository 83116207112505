import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FilterService } from './../../core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-email-logs-filters',
    templateUrl: './email-logs-filters.component.html',
    styleUrls: [ './email-logs-filters.component.css' ],
} )
export class EmailLogsFiltersComponent implements OnInit {


    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;


    constructor (
        public global: GlobalService,
        private fb: FormBuilder,
        private filterService: FilterService,
    ) { }

    get users () {
        return this.global.fetchUsers();
    }

    ngOnInit (): void {

        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filterResults();

    }

    initForm (): void {
        const myDate: Date = new Date();

        const previousMonth: Date = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            email_type: 'all',
            email_status: 'all',
            created_after: previousMonth.toJSON().slice( 0, 10 ),
            created_before: 'all',
            sent_by: 'all',
            page: 1,

        } );
    }

    filterResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }
}
