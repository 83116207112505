export enum ReferenceVerificationStatusType {
    UnderProcess = 0,
    AwaitingDetails = 1,
    SwitchOffOrUnreachable = 2,
    InvalidNumber = 3,
    ReferencenNotResponding = 4,
    CandidateUncooperative = 5,
    CalledAndConfirmed = 6,
    SentToVendor = 7,
    QCPending = 8,
    Verified = 9,
    AddressNotTracable = 10,
    DoesNotReside = 11,
    MovedFromAddress = 12,
    VerificationStopped = 13,
    NotApplicable = 14,
    EmployeeTerminatedAtCalling = 15,
    EmployeeTerminatedAtVisit = 16,
    DataEntryDone = 17,
    ClientTerminated = 18,
    VendorReturned = 19,
    DiscrepancyFound = 20,
    NegativeFeedback = 21,
    PostJoining = 22,
    OnHold = 23,
    ReferenceIsUncooperative = 24,
    UnableToValidate = 25,
    EmailSent = 26,
    PersonalReferenceProvided = 27,
    AwaitingClientApproval = 28,
    LanguageProblem = 29,
}
