<div class="wrapper-md">
    <div class="row" *ngIf="verificationStats">
        <div class="col-sm-3">
            <mat-accordion>

                <mat-expansion-panel>
                    <mat-expansion-panel-header class="waves-effect stats-warning" style="height: 48px;">
                        Under Process
                    </mat-expansion-panel-header>
                    <div class="stats-collapse-panel">
                        <ul class="stats-list list-unstyled">
                            <li class="list-group-item" *ngFor="let status of verificationStats['under_process']">
                                <span class="pull-right">{{ status.count }}</span>
                                {{ status.status_name }}
                            </li>
                        </ul>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="col-sm-3">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="waves-effect stats-positive" style="height: 48px;">
                        Verified
                    </mat-expansion-panel-header>
                    <div class="stats-collapse-panel">
                        <ul class="stats-list list-unstyled">
                            <li class="list-group-item" *ngFor="let status of verificationStats['verified']">
                                <span class="pull-right">{{ status.count }}</span>
                                {{ status.status_name }}
                            </li>
                        </ul>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="col-sm-3">
            <mat-accordion>

                <mat-expansion-panel>
                    <mat-expansion-panel-header class="waves-effect stats-white" style="height: 48px;">
                        Not Initiated
                    </mat-expansion-panel-header>
                    <div class="stats-collapse-panel">
                        <ul class="stats-list list-unstyled">
                            <li class="list-group-item" *ngFor="let status of verificationStats['not_initiated']">
                                <span class="pull-right">{{ status.count }}</span>
                                {{ status.status_name }}
                            </li>
                        </ul>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="col-sm-3">
            <mat-accordion>

                <mat-expansion-panel>
                    <mat-expansion-panel-header class="waves-effect stats-negative" style="height: 48px;">
                        Discrepancy
                    </mat-expansion-panel-header>
                    <div class="stats-collapse-panel">
                        <ul class="stats-list list-unstyled">
                            <li class="list-group-item" *ngFor="let status of verificationStats['discrepancy']">
                                <span class="pull-right">{{ status.count }}</span>
                                {{ status.status_name }}
                            </li>
                        </ul>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>