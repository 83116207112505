<div bsModal #licenceDetails="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{licence_number}} with DOB: {{dob}} Details</h4>

            </div>
            <div class="modal-body">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th width="15%">
                                Status
                            </th>
                            <th width="35%">
                                Personal Details
                            </th>
                            <th width="15%">
                                RTO Details
                            </th>
                            <th width="35%">
                                Timelines
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul class="list-unstyled">
                                    <li>
                                        <strong>{{licence.status}}</strong>
                                    </li>
                                    <li>
                                        {{licence.updated_at}}
                                    </li>
                                </ul>
                            </td>
                            <td>

                                <ul class="list-unstyled">
                                    <li>
                                        <strong> Name:</strong> {{licence.name}}
                                    </li>
                                    <li>
                                        <strong>Address:</strong> {{licence.address}}
                                    </li>
                                    <li>
                                        <strong>DOB:</strong> {{licence.dob}}
                                    </li>
                                    <li>
                                        <strong>Father Name:</strong> {{licence.father_name}}
                                    </li>
                                    <li>
                                        <strong>Relative Name:</strong> {{licence.relative_name}}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                <strong> {{licence.rto_name}}</strong>
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li *ngIf="licence.non_transport_validity_from">
                                        <strong>
                                            Non Transport Validity From:
                                        </strong>
                                        {{licence.non_transport_validity_from}}
                                    </li>
                                    <li *ngIf="licence.non_transport_validity_upto">
                                        <strong>
                                            Non Transport Validity Upto:
                                        </strong>
                                        {{licence.non_transport_validity_upto}}
                                    </li>
                                    <li *ngIf="licence.transport_validity_from">
                                        <strong>
                                            Transport Validity From:
                                        </strong>
                                        {{licence.transport_validity_from}}
                                    </li>
                                    <li *ngIf="licence.transport_validity_upto">
                                        <strong>
                                            Transport Validity Upto:
                                        </strong>
                                        {{licence.transport_validity_upto}}
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>