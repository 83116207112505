import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Webhook } from '../../core/models/webhook.model';
import { CommonService } from '../../core/services/common.service';

import { FormBuilder } from '@angular/forms';
import { WebhookService } from '../../core/services/webhook.service';
import { WebhookSubUrl } from '../../core/models/webhook-sub-url.model';

@Component( {
    selector: 'app-webhook-sub-url-list',
    templateUrl: './webhook-sub-url-list.component.html',
    styleUrls: [ './webhook-sub-url-list.component.css' ],
} )
export class WebhookSubUrlListComponent implements OnInit {

    public subUrls!: WebhookSubUrl[];

    @ViewChild( 'webhookSubUrlListModal', { static: false } )
    public webhookSubUrlListModal!: ModalDirective;

    constructor (
        private commonService: CommonService,

        private fb: FormBuilder,
        private webhookService: WebhookService
    ) { }

    ngOnInit () {
    }


    show ( webhook: Webhook ) {
        this.webhookService.getSubUrlsByWebhookId( webhook.id ).subscribe( response => {
            this.subUrls = response;
        } );
        this.webhookSubUrlListModal.show();
    }

    hide () {
        this.webhookSubUrlListModal.hide();
    }

}
