import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { VerificationList } from 'src/app/core/components/verification-list.component';
import { AddressVerification } from 'src/app/core/models/address-verification.model';
import { AddressService } from 'src/app/core/services/address.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-auto-assign-address',
    templateUrl: './auto-assign-address.component.html',
} )
export class AutoAssignAddressComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications: AddressVerification[] = [];
    isSubmitting = false;

    attachments: any[] = [];
    statsVisible = false;

    constructor (
        protected override verificationService: AddressService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        public override confirmationModalService: ConfirmationModalService,
    ) {
        super( confirmationModalService );
    }

    ngOnInit () {

        this.verification = 'address';
        this.subscribeToQueryChanges();
        this.getVendorsList();

    }

    filterResults (): void {

        // this.verificationService.filter(this.filterParams).subscribe(response => {
        //   this.pagination = response;
        //   this.verifications = response.data;
        //   this.populateExtraDetails(response.data);
        // });

    }

    showAddress ( address: AddressVerification ) {

        // this.router.navigate(['address', address.employee_id, 'edit'], this.navigationExtras);

    }

    populateExtraDetails ( address_verification: any ) {

        // for (const index in address_verification) {
        //   const verification = address_verification[index];
        //   this.attachments[index] = verification.attachments;
        // }

    }

    showStats () {

        this.statsVisible = !this.statsVisible;

    }

    onFiltersChanged ( $e: NavigationExtras ) {

        console.log( $e );

    }

}
