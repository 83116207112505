import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ClientApi } from 'src/app/core/models/client-api.model';
import { Errors } from 'src/app/core/models/errors.module';
import { ClientApiService } from 'src/app/core/services/client-api.service';
import { CommonService } from 'src/app/core/services/common.service';


@Component( {
    selector: 'app-client-api-edit-ip',
    templateUrl: './client-api-edit-ip.component.html',
    styleUrls: [ './client-api-edit-ip.component.css' ],
} )
export class ClientApiEditIpComponent implements OnInit {

    @ViewChild( 'clientsApiEditIp', { static: false } )
    public clientsApiEditIp!: ModalDirective;

    @Output()
    onClientApiIpUpdated = new EventEmitter<boolean>();

    title: string = '';
    errors: Errors = new Errors();
    isSubmitting: boolean = false;
    editForm!: FormGroup;
    clientApiId!: number | any;
    whitelistedIps!: any[];
    clientApi: ClientApi = new ClientApi();

    constructor (
        private fb: FormBuilder,
        private clientApiService: ClientApiService,
        private commonService: CommonService
    ) { }

    initForm (): void {

        this.editForm = this.fb.group( {
            id: [ '', [ Validators.required ] ],
            ips: this.fb.array( [] ),
        } );

    }

    get ips () {

        return this.editForm.get( 'ips' ) as FormArray;

    }

    addIp () {

        this.ips.push( this.fb.control( '', [
            Validators.required,
            Validators.pattern( '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$' ),
        ] ) );

    }

    ngOnInit () {

        this.initForm();

    }

    show ( id: number ) {

        this.clientApiId = id;

        this.initForm();

        this.editForm.patchValue( {
            id: id,
        } );

        this.getWhitelistedIps();

        this.clientsApiEditIp.show();
    }

    hide () {

        this.initForm();

        this.clientsApiEditIp.hide();

    }

    submitForm () {

        this.isSubmitting = true;

        this.errors = new Errors();

        const data = this.editForm.value;

        this.clientApiService.updateClientApiIps( this.clientApiId, data ).subscribe(

            response => {

                this.commonService.notifications.success( 'Successful', response[ 'message' ] );

                this.isSubmitting = false;

                this.initForm();

                this.hide();

                this.onClientApiIpUpdated.emit( true );

            },
            err => {

                this.errors = err;

                this.isSubmitting = false;

                this.commonService.notifications.error( 'Some Error Occurred' );

            }

        )

    }

    getWhitelistedIps () {

        this.clientApiService.getWhitelistedIps( this.clientApiId ).subscribe(

            response => {

                this.editForm.setControl( 'ips', this.setExistingIps( response ) );

            }

        );
    }

    setExistingIps ( whitelistedIps: any ): FormArray | any {

        const formArray = this.fb.array( [] );

        if ( !whitelistedIps.length ) {

            formArray.push( this.fb.control( '', Validators.required ) );

        } else {

            whitelistedIps.forEach( ( ip: any ) => {

                return formArray.push( this.fb.control( ip, [
                    Validators.required,
                    Validators.pattern( '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$' ),
                ] ) );

            } );

        }


        return formArray;
    }

    deleteRow ( index: number ): void {

        // control refers to your form array
        const control: FormArray = this.editForm.controls[ 'ips' ] as FormArray;

        // remove the chosen row
        control.removeAt( index );

    }

}
