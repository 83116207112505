import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VehicleService } from '../../core/services/vehicle.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-vehicle-api-response-modal',
    templateUrl: './vehicle-api-response-modal.component.html',
    styleUrls: [ './vehicle-api-response-modal.component.css' ],
} )
export class VehicleApiResponseModalComponent {


    apiResponse!: any[];

    @ViewChild( 'vehicleApiResponse', { static: false } )
    public vehicleApiResponse!: ModalDirective;

    constructor (
        private vehicleService: VehicleService,
        private commonService: CommonService,
    ) { }

    show ( log: any ) {

        this.vehicleService.getApiResponse( log.id ).subscribe(
            response => {
                this.apiResponse = response;
                this.vehicleApiResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    hide () {
        this.vehicleApiResponse.hide();
    }


}
