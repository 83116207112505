import { SharedModule } from '../core/shared.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { ReferenceListComponent } from './reference-list/reference-list.component';
import { ReferenceService } from '../core/services/reference.service';
import { ReferenceEditComponent } from './reference-edit/reference-edit.component';
import { ReferenceAssignedWorkListComponent } from './reference-assigned-work-list/reference-assigned-work-list.component';
import { ReferenceImportComponent } from './reference-import/reference-import.component';
import { ReferenceEditFormComponent } from './reference-edit-form/reference-edit-form.component';


@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgxPaginationModule,
        TrimValueAccessorModule,
    ],
    declarations: [ ReferenceListComponent,
        ReferenceEditComponent,
        ReferenceAssignedWorkListComponent,
        ReferenceImportComponent,
        ReferenceEditFormComponent ],
    providers: [ ReferenceService ],

} )
export class ReferenceModule {
}
