<div id="content" class="container-fluid">
    <div *ngIf="cnr_details">
        <table class="table table-responsive">


            <thead>
                <tr>
                    <th>CNR</th>
                    <th>Distric Court</th>
                    <th>Case Type</th>
                    <th>Case Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{cnr_details.cnr_number}}</td>
                    <td>{{cnr_details.district_court}}</td>
                    <td>{{cnr_details.case_type}}</td>
                    <td>{{cnr_details.case_status}}</td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th>Nature Of Disposal</th>
                    <th>Acts</th>
                    <th>Sections</th>
                    <th>Filing No</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{cnr_details.nature_of_disposal}}</td>
                    <td>{{cnr_details.cnr_acts}}</td>
                    <td>{{cnr_details.cnr_sections}}</td>
                    <td>{{cnr_details.filling_number}} / {{cnr_details.filling_year}}</td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th>Registration No </th>
                    <th>Police Station</th>
                    <th>Fir No</th>
                    <th>Fir Year</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{cnr_details.registration_number}} / {{cnr_details.registration_year}}</td>
                    <td> {{cnr_details.police_station}}</td>
                    <td>{{cnr_details.fir_number}}</td>
                    <td> {{cnr_details.fir_year}}</td>
                </tr>
            </tbody>

        </table>

        <div>
            <a target="_blank" class="btn btn-link text-main text-semibold"
                href="{{cnr_details.cnr_proof_link}}">View
                ScreenShot</a>
        </div>

        <div class="row" *ngIf="orders">
            <div class="col-md-2" *ngFor="let order of orders">
                <a class="btn btn-link text-main text-semibold" target="_blank"
                    href="{{order.order_link}}">Order
                    link</a>
            </div>

        </div>

    </div>

    <div class="col-md-2">

        <a id="copy-button" class="btn btn-primary" (click)="copyCnrData()">Copy</a>

    </div>

</div>
