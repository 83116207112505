<div class="col-md-8">
    <h1 class="m-n font-thin h3">{{ employee.name }} Verification</h1>
    <ul class="breadcrumb insta-breadcrumb">
        <li><a routerLink="/home">Home</a></li>
        <li><a [routerLink]="['/companies', employee.company_id]">{{ employee.company_name }}</a></li>
        <li><a [routerLink]="['/lots', employee.lot_id]">{{ employee.lot_name }}</a></li>
        <li><a [routerLink]="['/employees', employee.id,'edit']"> {{ employee.track_id }}</a></li>
        <li>Created on : {{ employee.created_at }}</li>
    </ul>
</div>

<div class="col-md-4 text-right text-center-xs">
    <div class="btn-group">
        <button [disabled]="!verificationFormGroup.valid" (click)="onUpdateAndBack(verification)"
            class="btn btn-default btn-addon">
            <i class="fa fa-arrow-left"></i>Back
        </button>
        <button [disabled]="!verificationFormGroup.valid" (click)="onUpdateAndRefresh(verification)"
            class="btn btn-default btn-addon">
            <i class="fa fa-refresh"></i>Refresh
        </button>
        <button [disabled]="!verificationFormGroup.valid" (click)="onUpdateAndNext(verification)"
            class="btn btn-default btn-addon">
            <i class="fa fa-arrow-right"></i>Next
        </button>
    </div>

    <!-- old way  -->
    <!-- <ul class="breadcrumb insta-breadcrumb">
        <li><a [href]="[crmUrl+'employees/'+ employee.id+'/report']" target="_blank"> Report</a></li>
        <li><a routerLink="/employees/{{employee.id}}/history"> History </a></li>
    </ul> -->
    <!-- new way to use report  -->
    <ul class="breadcrumb insta-breadcrumb">
        <li><a [href]="[crmUrl+'employees/'+ employee.id+'/report']" target="_blank">View Report</a></li>
        <li><a downloadDocument [employee]="employee" documentType="pdf" [href]="[reportLink]">Download Report</a></li>
        <li><a routerLink="/employees/{{employee.id}}/history"> History </a></li>
    </ul>

</div>

<app-pdf-viewer-popup #pdfViewerModal [reportPdfUrl]="reportPdfUrl"
    (onReload)="onReportReload()"></app-pdf-viewer-popup>