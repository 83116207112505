<ng-container *ngIf="verification && !loading">


    <div class="row bg-light lter b-b wrapper-md" *ngIf="verification">

        <div class="col-md-8">
            <h1 class="m-n font-thin h3">{{ verification.employee.name }} Verification</h1>
            <ul class="breadcrumb insta-breadcrumb">
                <li><a>Home</a></li>
                <li><a>{{ verification.company.name }}</a> </li>
                <li><a>{{ verification.lot.name }}</a></li>
                <li><a>{{ verification.employee.track_id }}</a> </li>
                <li>Created on : {{ verification.created_at }}</li>
            </ul>
        </div>

        <div class="col-md-4 text-right text-center-xs">
            <div class="btn-group">
                <button [disabled]="loading" (click)="onExitClicked()" class="btn btn-danger"> Exit Task </button>
                <button [disabled]="!form.valid || loading" (click)="onDoneClicked()"
                    class="btn btn-primary">Done</button>
            </div>
        </div>

    </div>

    <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
        <fieldset [disabled]="loading">

            <div class="wrapper-md">
                <div class="panel">
                    <div class="panel-body">
                        <div class="row">
                            <div class="form-group col-md-2">
                                <label class="center-block">Employee Name</label>
                                <input formControlName="name" ngModel="{{verification.employee.name}}"
                                    class="form-control" readonly>
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Client Employee Id</label>
                                <input formControlName="client_employee_id"
                                    ngModel="{{verification.employee.client_employee_id}}" class="form-control"
                                    readonly>
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Mobile Number</label>
                                <input formControlName="mobile_number" ngModel="{{verification.employee.mobile_number}}"
                                    class="form-control" readonly>
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Father Name</label>
                                <input formControlName="father_name" ngModel="{{verification.employee.father_name}}"
                                    class="form-control" readonly>
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Date Of Birth</label>
                                <input formControlName="dob" class="form-control"
                                    ngModel="{{verification.employee.dob}}" readonly>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <div class="col-md-8">
                        <!--Front Url Panel-->
                        <div class="panel" *ngIf="verification.front_url" style="overflow: hidden;">
                            <div class="row">
                                <div class="col-md-12" style="position: inherit">
                                    <button type="button" (click)="zoomInImage('img_front_url')"
                                        class="btn btn-primary"> + </button>
                                    <button type="button" (click)="zoomOutImage('img_front_url')"
                                        class="btn btn-primary"> - </button>
                                </div>

                                <div [style.max-height]="getMaxHeight(verification)"
                                    class="col-md-12 identity-edit-image-frame" id="div_front_url">
                                    <img imageZoom style="width: 100%;height: 60%" [src]="verification.front_url"
                                        id="img_front_url" />
                                </div>
                            </div>
                        </div>


                        <!--Back Url Panel-->
                        <div>
                            <div class="panel" *ngIf="verification.back_url" style="overflow: hidden;">
                                <div class="row">
                                    <div class="col-md-12" style="position: inherit">
                                        <button type="button" (click)="zoomInImage('img_back_url')"
                                            class="btn btn-primary"> + </button>
                                        <button type="button" (click)="zoomOutImage('img_back_url')"
                                            class="btn btn-primary"> - </button>
                                    </div>

                                    <div [style.max-height]="getMaxHeight(verification)"
                                        class="col-md-12 identity-edit-image-frame" id="div_back_url"
                                        *ngIf="verification.back_url">
                                        <img imageZoom style="width: 100%;height: 60%" [src]="verification.back_url"
                                            id="img_back_url" />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <div class="col-md-4">
                        <div class="panel panel-default">
                            <div class="panel-body">

                                <div class="row">

                                    <div class="col-md-6 form-group">
                                        <label class="center-block">Verification Status</label>
                                        <select class="form-control" formControlName="status_id"
                                            (change)="onStatusChanged($event)">
                                            <option *ngFor="let status of statuses" [value]="status.id">{{ status.name
                                                }}</option>
                                        </select>
                                        <small class="text-danger" *ngIf="form.get('status_id')?.errors">
                                            Required
                                        </small>
                                    </div>

                                    <div class="col-md-6 form-group">
                                        <label class="center-block">Document Type</label>
                                        <select class="form-control" formControlName="document_type_id"
                                            (change)="onDocumentTypeChanged($event)">
                                            <option *ngFor="let documentType of documentTypeList"
                                                [value]="documentType.id">{{
                                                documentType.name }}</option>
                                        </select>
                                        <small class="text-danger"
                                            *ngIf="statusId.value == 20 && documentTypeId.value == 0">
                                            Select Type
                                        </small>
                                    </div>

                                    <div *ngIf="documentTypeId.value != 0">

                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Doc Number</label>
                                            <small class="text-danger" *ngIf="form.get('number')?.errors">
                                                Valid Number Required
                                            </small>
                                            <input class="form-control" formControlName="number">
                                        </div>
                                        <div class="col-md-12 form-group" *ngIf="documentTypeId.value==5">
                                            <label class="center-block">File Number</label>
                                            <input class="form-control" formControlName="file_number">
                                            <small class="text-danger" *ngIf="form.get('file_number')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Name</label>
                                            <input class="form-control" formControlName="name_as_per_id"
                                                [readonly]="false">
                                            <small class="text-danger" *ngIf="form.get('name_as_per_id')?.errors">
                                                Only alphabets allowed
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Father Name</label>
                                            <input class="form-control" formControlName="father_name_as_per_id">
                                            <small class="text-danger"
                                                *ngIf="form.get('father_name_as_per_id')?.errors">
                                                Only alphabets allowed
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">DOB (DD-MM-YYYY)</label>
                                            <input class="form-control" formControlName="dob_as_per_id">
                                            <small class="text-danger" *ngIf="form.get('dob_as_per_id')?.errors">
                                                Invalid Dob Format
                                            </small>
                                        </div>
                                        <div class="col-md-12 form-group" *ngIf="documentTypeId.value!=2">
                                            <label class="center-block">Address</label>
                                            <textarea class="form-control"
                                                formControlName="address_as_per_id"></textarea>
                                            <small class="text-danger" *ngIf="form.get('address_as_per_id')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="documentTypeId.value!=2">
                                            <label class="center-block"> State</label>
                                            <input formControlName="state_name" [typeahead]="states"
                                                [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                placeholder="Select State" (typeaheadOnSelect)="OnStateSelect($event)"
                                                [typeaheadItemTemplate]="customItemTemplate" class="form-control">
                                            <small class="text-danger"
                                                *ngIf="form.get('state_id')?.errors || form.get('state_name')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="documentTypeId.value!=2">
                                            <label class="center-block"> District</label>
                                            <input formControlName="district_name"
                                                [typeahead]="districts | filterByStateId:form.controls['state_id'].value"
                                                [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                placeholder="Select District" class="form-control"
                                                [typeaheadItemTemplate]="customItemTemplate"
                                                (typeaheadOnSelect)="OnDistrictSelect($event)">
                                            <small class="text-danger"
                                                *ngIf="form.get('district_id')?.errors || form.get('district_name')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="col-md-12 form-group" *ngIf="documentTypeId.value!=2">
                                            <label class="center-block">Pincode</label>
                                            <input class="form-control" formControlName="pincode"
                                                onkeydown="scrollTo(0,0)">
                                            <small class="text-danger" *ngIf="form.get('pincode')?.errors">
                                                Must contain 6 digits only
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group"
                                            *ngIf="documentTypeId.value==4 || documentTypeId.value==5">
                                            <label class="center-block">Date of Issue</label>
                                            <input type="date" class="form-control" formControlName="date_of_issue">
                                            <small class="text-danger" *ngIf="form.get('date_of_issue')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group"
                                            *ngIf="documentTypeId.value==4 || documentTypeId.value==5">
                                            <label class="center-block">Date Of Expiry</label>
                                            <input type="date" class="form-control" formControlName="date_of_expiry">
                                            <small class="text-danger" *ngIf="form.get('date_of_expiry')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="col-md-6 form-group" *ngIf="documentTypeId.value==4"
                                            id="lmv_first_issue_date">
                                            <label class="center-block">NT Issue Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="lmv_first_issue_date">
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="documentTypeId.value==4"
                                            id="lmv_last_renewal_date">
                                            <label class="center-block">NT EXPIRY Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="lmv_last_renewal_date">
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="documentTypeId.value==4"
                                            id="tr_first_issue_date">
                                            <label class="center-block">TR Issue Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="tr_first_issue_date">
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="documentTypeId.value==4"
                                            id="tr_last_renewal_date">
                                            <label class="center-block">TR Expiry Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="tr_last_renewal_date">
                                        </div>


                                    </div>

                                    <div class="col-md-12 form-group">
                                        <label class="center-block">Client Remarks:
                                            <textarea rows=2 class="form-control"
                                                formControlName="client_remarks"></textarea>
                                        </label>
                                        <small class="text-danger" *ngIf="form.get('client_remarks')?.errors">
                                            Required
                                        </small>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</ng-container>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>