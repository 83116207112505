import { Injectable } from '@angular/core';
import { CandidateDocumentService } from './candidate-document.service';
import { IdentityVerification } from '../models/identity-verification.model';
import { ICandidateDocumentLabel } from '../models/candidate-document.modal';
import { IEmployeeDocumentResponse } from '../models/employee-document-attachment.modal';

@Injectable( {
    providedIn: 'root',
} )
export class CandidateDocumentTransformService {

    constructor ( private documentService: CandidateDocumentService, ) { }

    employee ( verification: IEmployeeDocumentResponse, employeeId: number | string ): void {

        const labels: ICandidateDocumentLabel = this.getDocumentDetail( verification, 'employee', employeeId, 0 );

        this.documentService.addVerificationField( 'Resume', 'resume_url', 'self', employeeId, verification.resume_url, labels );

        this.documentService.addVerificationField( 'Documents', 'document', 'documentables', employeeId, null, labels );

        this.documentService.addExtraDocuments( 'employee', verification.documents, labels );

    }

    identityVerification ( identityVerification: IdentityVerification, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( identityVerification, 'identity_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Front', 'front_url', 'self', verificationId, identityVerification.front_url, labels );

        this.documentService.addVerificationField( 'Back', 'back_url', 'self', verificationId, identityVerification.back_url, labels );

        this.documentService.addVerificationField( 'Proof', 'proof_url', 'self', verificationId, identityVerification.proof_url, labels );

    }

    addressVerification ( verification: any, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( verification, 'address_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Proof', 'proof_url', 'self', verificationId, verification.proof_url, labels );

        this.documentService.addVerificationField( 'Attachment', 'attachment', 'attachables', verificationId, null, labels );

        this.documentService.addExtraDocuments( 'address_verifications', verification.documents, labels );

        this.documentService.addExtraAttachment( 'address_verifications', verification.attachments, labels );

    }

    icrVerification ( verification: any, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( verification, 'icr_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Front', 'front_url', 'self', verificationId, verification.front_url, labels );

        this.documentService.addVerificationField( 'Back', 'back_url', 'self', verificationId, verification.back_url, labels );

    }

    educationVerification ( verification: any, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( verification, 'education_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Payment Reference Proof', 'payment_url', 'attachables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Proof', 'proof_url', 'self', verificationId, verification.proof_url, labels );

        this.documentService.addVerificationField( 'Degree', 'degree', 'documentables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Marksheet', 'marksheet', 'documentables', verificationId, null, labels );

        this.documentService.addExtraDocuments( 'education_verifications', verification.documents, labels );

        this.documentService.addExtraAttachment( 'education_verifications', verification.attachments, labels );

    }

    employmentVerification ( verification: any, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( verification, 'employment_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Payment Proof', 'payment_url', 'attachables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Screenshots', 'screenshot', 'attachables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Proof', 'proof_url', 'self', verificationId, verification.proof_url, labels );

        this.documentService.addVerificationField( 'Appointment Letter', 'offer', 'documentables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Latest Payslips', 'payslip', 'documentables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Resignation Letter', 'relieving', 'documentables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Acceptances Email', 'document', 'documentables', verificationId, null, labels );

        this.documentService.addExtraDocuments( 'employment_verifications', verification.documents, labels );

        this.documentService.addExtraAttachment( 'employment_verifications', verification.attachments, labels );
    }

    pccVerification ( verification: any, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( verification, 'pcc_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Payment Screenshoot', 'payment_url', 'attachables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Application Screenshoot', 'application_screenshot', 'attachables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Pcc Report', 'pcc_report', 'self', verificationId, verification.report_url, labels );

        this.documentService.addVerificationField( 'Attachments', 'attachment', 'attachables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Signature', 'signature', 'documentables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Photo', 'photo', 'documentables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Birth Certificate', 'birth_certificate', 'documentables', verificationId, null, labels );

        this.documentService.addVerificationField( 'Other Documents', 'document', 'documentables', verificationId, null, labels );

        this.documentService.addExtraDocuments( 'pcc_verifications', verification.documents, labels );

        this.documentService.addExtraAttachment( 'pcc_verifications', verification.attachments, labels );

    }

    documentVerification ( verification: any, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( verification, 'document_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Front', 'front_url', 'self', verificationId, verification.front_url, labels );

        this.documentService.addVerificationField( 'Back', 'back_url', 'self', verificationId, verification.back_url, labels );

        this.documentService.addVerificationField( 'Proof', 'proof_url', 'self', verificationId, verification.proof_url, labels );

        this.documentService.addVerificationField( 'Attachment', 'attachment', 'attachables', verificationId, null, labels );

        this.documentService.addExtraAttachment( 'document_verifications', verification.attachments, labels );
    }

    globalVerification ( verification: any, verificationId: number, index: number ): void {

        const labels = this.getDocumentDetail( verification, 'global_db_verifications', verificationId, index );

        this.documentService.addVerificationField( 'Front', 'front_url', 'self', verificationId, verification.front_url, labels );

        this.documentService.addVerificationField( 'Back', 'back_url', 'self', verificationId, verification.back_url, labels );

        this.documentService.addVerificationField( 'Proof', 'attachment', 'attachables', verificationId, null, labels );

        this.documentService.addExtraAttachment( 'global_db_verifications', verification.attachments, labels );

    }

    getDocumentDetail ( verification: any, verificationId: string, typeId: number | any, index: number ): ICandidateDocumentLabel {

        const verificationLabel = verification[ verificationId ] ?? 'Employee';

        const typeLabel = this.getDocumentLabel( verification, verificationId, index );

        const candocumentDocumentLabel: ICandidateDocumentLabel = {
            verificationLabel: verificationLabel,
            verificationId: verificationId,
            typeLabel: typeLabel,
            typeId: typeId,
        };

        return candocumentDocumentLabel;

    }

    getDocumentLabel ( verification: any, verificationType: string, index: number ): string {

        if ( verificationType != 'employee' ) {
            return '#Verification ' + ( index + 1 ) + ' - ' + verification[ 'type' ];
        }

        if ( verificationType == 'employee' ) {
            return '#Employee';
        }

        return '#';

    }

}
