import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as XLSX from 'xlsx';

type AOA = any[][];

export abstract class ImportExcelComponent {

    data!: AOA;
    jsonData: any;
    headers!: AOA;
    headerRaw!: string[];
    excelForm!: FormGroup;
    titles: any[] = [];
    originalHeaders: any[] = [];
    isSubmitting: boolean = false;
    acceptedFileType: string = '';

    // for returning formatted text while parsing excel sheet
    rawInput = false;

    // TODO: make this a service insted of a class
    constructor (
        protected fb: FormBuilder
    ) {

    }

    abstract applyValidations (): void;

    abstract initExcelForm (): void;

    abstract setValidationMessages (): void;

    abstract showInvalidFileTypeError (): void;


    // When File is uploaded.
    onFileChange ( evt: any ): void {

        if ( this.isFileTypeInvalid( this.acceptedFileType, evt.target.files ) ) {

            this.showInvalidFileTypeError();

            return;
        }

        /* wire up file reader */
        const target: DataTransfer = ( evt.target ) as DataTransfer;

        /* Read the file if it is present; otherwise, don't. */

        if ( target.files.length !== 1 ) return;

        const reader: FileReader = new FileReader();

        const self: any = this;

        reader.onload = ( e: any ) => {

            /* read workbook */
            const bstr: any = e.target.result;

            const wb: any = XLSX.read( bstr, { type: 'binary' } );


            /* grab first sheet */
            const wsname: any = wb.SheetNames[ 0 ];
            const ws: any = wb.Sheets[ wsname ];

            // Getting headers
            self.headers = ( XLSX.utils.sheet_to_json( ws, { header: 1 } ) ) as AOA;

            self.updateTitles( self.headers[ 0 ] );

            // with '_'
            self.headerRaw = self.titles;

            /**
             * save data
             *
             * 'raw' property in sheet_to_json() set to false for returning formatted
             * text by default unless explicitly provided by a specific component
             */
            self.jsonData = ( XLSX.utils.sheet_to_json( ws, { defval: null, header: self.headerRaw, raw: self.rawInput } ) ).splice( 1 );

            self.originalHeaders = self.headers[ 0 ];

            self.initExcelForm();

            self.populateForm();

            self.applyValidations();
        };
        reader.readAsBinaryString( target.files[ 0 ] );

    }


    // Get the excel Form Array form excel FormGroup
    get excelFormArray (): FormArray {
        return this.excelForm.get( 'excelFormArray' ) as FormArray;
    }

    private isFileTypeInvalid ( acceptedExtension: string, uploadedFiles: FileList ): boolean {

        if ( !uploadedFiles.length ) return true;

        const uploadedFileName = uploadedFiles[ 0 ].name;

        if ( !uploadedFileName ) return true;

        const uploadedFileExtension = uploadedFileName.split( '.' ).pop()?.toLocaleLowerCase();

        if ( !uploadedFileExtension ) return true;

        return uploadedFileExtension ? !acceptedExtension.includes( uploadedFileExtension ) : true;
    }



    populateForm (): void {
        this.excelForm.patchValue( this.jsonData );
        const rowsFormGroup: FormGroup[] = this.jsonData.map( ( row: any ) => this.fb.group( row ) );
        const rowsFormArray: FormArray = this.fb.array( rowsFormGroup );
        this.excelForm.setControl( 'excelFormArray', rowsFormArray );
    }

    // Convert the column names to snakeCase.
    private updateTitles ( data: any[] ): void {
        this.headers[ 0 ].forEach( key => {
            this.titles.push(
                key.toString().trim().toLowerCase().replace( / /g, '_' )
            );
        } );
    }

    protected addMetaFieldsInForm ( form: FormArray, removeExisting = true ) {

        // this converts metadata_xyz, metadata_abc fields to
        // metadata : {
        // 	xyz: value,
        //  abc: value,
        // }

        form.controls.forEach( ( row: FormGroup | any ) => {

            // add default metdata formgroup object
            row.addControl( 'metadata', new FormGroup( {}, [] ) );

            Object.keys( row.value ).forEach( key => {

                // if the key has our keyword
                if ( key.includes( 'metadata_' ) ) {

                    const metavalue = row.value[ key ];

                    // find only the key
                    const metakey = key.replace( 'metadata_', '' );

                    // remove it from the from the row
                    if ( removeExisting ) row.removeControl( key );

                    // insert new control in the metadata formgroup
                    ( row.get( 'metadata' ) as FormGroup ).addControl( metakey, new FormControl( metavalue ) )

                }
            } );

        } );

    }
}
