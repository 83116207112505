import { DigitalAddressForm } from './../../core/models/address-verification.model';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerificationList } from '../../core/components/verification-list.component';
import { AddressVerification } from '../../core/models/address-verification.model';
import { AddressService } from '../../core/services/address.service';
import { CommonService } from '../../core/services/common.service';
import { EnvironmentService } from './../../core/services/environment.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { DigitalAddressService } from 'src/app/core/services/digital-address.service';
import { DigitalAddressStatusType } from 'src/app/core/models/digital-address-status.model';

@Component( {
    selector: 'app-address-list',
    templateUrl: './address-list.component.html',
    styleUrls: [ './address-list.component.css' ],
} )
export class AddressListComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications: AddressVerification[] = [];
    isSubmitting: boolean = false;
    attachments: any[] = [];
    statsVisible: boolean = false;
    readonly maxFollowUpMailAllowed = 10;
    readonly maxFollowUpSmsAllowed = 10;

    get users () {
        return this.global.fetchUsers();
    }
    constructor (
        protected override verificationService: AddressService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        protected environmentService: EnvironmentService,
        private bsModalService: BsModalService,
        public override confirmationModalService: ConfirmationModalService,
        private digitalAddressService: DigitalAddressService,

    ) {
        super( confirmationModalService );
    }

    ngOnInit (): void {
        this.verification = 'address';
        this.subscribeToQueryChanges();
        this.getVendorsList();

    }

    // TODO:: clean the filter code as per aadhaar log, aadhaar filters.
    //  We have to change in every component which extends 'VerificationList' together
    filterResults (): void {
        this.verificationService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    showAddress ( address: AddressVerification ): void {
        this.router.navigate( [ 'address', address.employee_id, 'edit' ], this.navigationExtras );
    }

    populateExtraDetails ( addressVerification: AddressVerification[] ): void {

        for ( const index in addressVerification ) {

            const verification: AddressVerification = addressVerification[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    showStats (): void {
        this.statsVisible = !this.statsVisible;
    }

    get letterUrl (): string {
        return this.environmentService.oldCrmUrl + 'address/search/letter?id=' + this.ids;
    }

    get envelopeUrl (): string {
        return this.environmentService.oldCrmUrl + 'address/search/envelopes?id=' + this.ids;
    }

    get statuses () { return this.global.fetchStatuses()[ 'address' ]; }

    // overriding super class method
    // TODO:: move to super class after implementing this in all verificaions
    override exportExcel () {

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/' + this.verification + '/downloadExcel',
            fileName: this.verification,
            method: 'get',
            postParams: null,
        };

        this.bsModalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )
    }

    onFollowUpButtonClicked ( verification: AddressVerification ) {

        this.confirmationModalService.confirm( {
            title: 'Follow Up Digital Address',
            text: 'Are you sure you want to send digital address follow up?'
        }, () => {

            this.digitalAddressService.sendFollowUp( verification.digital_address_form.id ).subscribe( {

                next: () => {

                    this.filterResults();
                    this.commonService.notifications.success( 'Successful', 'Follow-up Sent Successfully' )

                }
            } )
        } )
    }

    onSendInviteButtonClicked ( verification: AddressVerification ) {

        const payload = {
            verification_id: verification.id,
            verification: "address",
        }

        this.confirmationModalService.confirm( {
            title: 'Send Digital Address Invite',
            text: 'Are you sure you want to send digital address invite?'
        }, () => {

            this.digitalAddressService.sendInvite( payload ).subscribe( {

                next: ( response: any ) => {

                    this.filterResults();
                    this.commonService.notifications.success( response[ 'message' ], 'Success' );

                }
            } )
        } )
    }

    isSendInviteAllowed ( verification: AddressVerification ) {

        if ( verification.digital_address_form ) return false;

        if ( !verification.has_digital_settings ) return false;

        return true;
    }

    isFollowUpAllowed ( verification: AddressVerification ) {

        if ( !verification.digital_address_form ) return false;

        if ( verification.digital_address_form.status != DigitalAddressStatusType.PENDING ) return false;

        if ( verification.digital_address_form.emails_count == 0 && verification.digital_address_form.sms_count == 0 ) return false;

        if ( verification.digital_address_form.email_follow_up_count >= this.maxFollowUpMailAllowed && verification.digital_address_form.sms_follow_up_count >= this.maxFollowUpSmsAllowed ) return false;

        return true;
    }

    isChangeDigitalAddressStatusAllowed ( verification: AddressVerification ) {

        if ( !verification.digital_address_form ) return false;

        return true;
    }

    changedStatusText ( digitalAddressForm: DigitalAddressForm ) {
        return digitalAddressForm.status == DigitalAddressStatusType.PENDING ? 'Inactive' : 'Active'
    }

    changeDigitalAddressStatus ( verification: AddressVerification ) {

        const changedStatusText = this.changedStatusText( verification.digital_address_form )

        this.confirmationModalService.confirm( {

            title: 'Change digital address status',
            text: 'Are you sure you want to mark digital address as ' + changedStatusText + '?'
        }, () => {

            this.digitalAddressService.toggleDigitalAddressStatus( verification.digital_address_form.id ).subscribe( {

                next: () => {

                    this.filterResults();
                    this.commonService.notifications.success( 'Successful', 'Status Changed Successfully' )

                }
            } )
        } )
    }
}
