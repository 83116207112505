import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';


import { Employee } from '../models/employee.model';
import { HttpParams } from '@angular/common/http';
import { ReferenceVerification } from '../models/reference-verification.model';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class ReferenceService {

    constructor (
        private api: ApiService
    ) {
    }


    getAll (): Observable<Pagination> {
        return this.api.get( 'reference/' );
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'reference', params );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'reference/' + id );
    }

    find ( id: number ): Observable<ReferenceVerification> {
        return this.api.get( 'reference/' + id );
    }

    create ( id: number ): Observable<Employee> {
        return this.api.get( 'reference/' + id + '/add' );
    }

    update ( id: string | number, data: Object | undefined, params: string ): Observable<ReferenceVerification> {
        return this.api.put( 'reference/' + id + '?' + params, data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'reference/' + id );
    }


    getAssignedWorkList ( filterParams: Params ): Observable<any[]> {
        return this.api.get( 'reference/assigned-work', filterParams );
    }

    importEmployees ( values: Object ): Observable<any[]> {
        return this.api.post( 'reference/import', values );
    }
}

