import { Component, OnInit } from '@angular/core';
import { WorkService } from '../../core/services/work.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Pagination } from '../../core/models/pagination.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VerificationService } from '../../core/services/verification.service';
import { Verification } from '../../core/models/verification.model';
import { HttpParams } from '@angular/common/http';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { User } from 'src/app/core/models/user.model';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';



@Component( {
    selector: 'app-work-history',
    templateUrl: './work-history.component.html',
    styleUrls: [ './work-history.component.css' ],
    providers: [ WorkService, VerificationService ],
} )
export class WorkHistoryComponent extends ExportExcelComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    historyStats!: any[];
    loading!: boolean;
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    verifications: Verification[] = [];
    allStatuses!: any[];
    user_id!: number;
    user!: User | undefined;

    constructor (
        private workService: WorkService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private verificationService: VerificationService,
        public global: GlobalService,
        private commonService: CommonService
    ) {
        super();
        this.initForm();
        this.route.params.subscribe( ( params: any ) => {

            this.user_id = params.id

            this.commonService.getUsers().subscribe( users => {
                this.user = users.find( i => i.id == this.user_id );
            } )

        } );
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            updated_after: '',
            updated_before: '',
            verification_type: 'all',
            work_query: '',
            filter_verification_status: [ [] ],
        } );
    }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();

        } );

        // Populate the list of all verifications.
        this.verificationService.getAllVerifications().then( verifications => this.verifications = verifications );

    }


    filterResults (): void {

        // Getting the history stats.
        this.workService.stats( this.filterParams, this.user_id ).subscribe( response => this.historyStats = response );

        // Getting the history for user Id.
        this.workService.userHistory( this.filterParams, this.user_id ).subscribe( response => {
            this.pagination = response;
        } );

    }

    // Pagination next page.
    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    filterQueriedResults (): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        // Navigates to current path along with the query string
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();

        this.filterParams = new HttpParams();

        this.filterResults();

    }

    onVerificationTypeChanged (): void {
        this.allStatuses = [];
        let verification = this.filterFormGroup.controls[ 'verification_type' ].value;
        if ( verification == 'current_address' || verification == 'permanent_address' ) {
            verification = 'address';
        }

        this.allStatuses = this.global.fetchStatuses()[ verification ];

    }

    generateExcel () {
        this.workService.getDataForExcel( this.filterParams, this.user_id ).subscribe(
            response => {
                this.downloadExcel( response, 'User History Excel' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }


}
