<div class="wrapper-md">
    <div class="row">
        <div class="col-sm-3" *ngIf="smsStats">
            <div class="panel panel-default">
                <div class="panel-heading">Sms Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        SMS Details Found

                        <span class="pull-right text-bold"> {{ smsStats.sms_has_details }}</span>
                    </p>
                    <p class="mar-no">
                        SMS Details Not Found
                        <span class="pull-right text-bold"> {{ smsStats['sms_does_not_has_details'] }}</span> need to
                        fix
                    </p>
                    <p class="mar-no">
                        SMS Failure
                        <span class="pull-right text-bold">{{ smsStats['sms_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        SMS Not Initiated
                        <span class="pull-right text-bold">{{ smsStats['sms_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        Multiple RTO Error
                        <span class="pull-right text-bold">{{ smsStats['sms_multiple_rtos_error'] }}</span>
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm-3" *ngIf="vahanStats">
            <div class="panel panel-default">
                <div class="panel-heading">Vahan Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Vahan Details Found
                        <span class="pull-right text-bold"> {{ vahanStats['vahan_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Details Not Found
                        <span class="pull-right text-bold"> {{ vahanStats['vahan_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Failure
                        <span class="pull-right text-bold">{{ vahanStats['vahan_failure'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Invalid Captcha
                        <span class="pull-right text-bold">{{ vahanStats['vahan_invalid_captcha'] }}</span>
                    </p>

                    <p class="mar-no">
                        Vahan Not Initiated
                        <span class="pull-right text-bold">{{ vahanStats['vahan_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm-3" *ngIf="stateStats">
            <div class="panel panel-default">
                <div class="panel-heading">State Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        State Details Found
                        <span class="pull-right text-bold"> {{ stateStats['state_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        State Details Not Found
                        <span class="pull-right text-bold"> {{ stateStats['state_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        State Failure
                        <span class="pull-right text-bold">{{ stateStats['state_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        State Not Initiated
                        <span class="pull-right text-bold">{{ stateStats['state_not_initiated'] }}</span>
                    </p>

                    <p class="mar-no">
                        --
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm-3" *ngIf="parivahanStats">
            <div class="panel panel-default">
                <div class="panel-heading">Parivahan Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Parivahan Details Found
                        <span class="pull-right text-bold"> {{ parivahanStats['parivahan_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Parivahan Details Not Found
                        <span class="pull-right text-bold">
                            {{ parivahanStats['parivahan_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Parivahan Failure
                        <span class="pull-right text-bold">{{ parivahanStats['parivahan_failure'] }}</span>
                    </p>
                    <p class="mar-no">
                        Parivahan Invalid Captcha
                        <span class="pull-right text-bold">{{ parivahanStats['parivahan_invalid_captcha'] }}</span>
                    </p>

                    <p class="mar-no">
                        Parivahan Not Initiated
                        <span class="pull-right text-bold">{{ parivahanStats['parivahan_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        Multiple RTOs Error
                        <span class="pull-right text-bold">{{ parivahanStats['parivahan_multiple_rtos_error'] }}</span>
                    </p>

                </div>
            </div>
        </div>
        <div class="col-sm-3" *ngIf="vahanCitizenStats">
            <div class="panel panel-default">
                <div class="panel-heading">Vahan Citizen Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Vahan Citizen Details Found
                        <span class="pull-right text-bold"> {{ vahanCitizenStats['vahan_citizen_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Citizen Details Not Found
                        <span class="pull-right text-bold">
                            {{ vahanCitizenStats['vahan_citizen_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Vahan Citizen Failure
                        <span class="pull-right text-bold">{{ vahanCitizenStats['vahan_citizen_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Vahan Citizen Not Initiated
                        <span class="pull-right text-bold">{{ vahanCitizenStats['vahan_citizen_not_initiated'] }}</span>
                    </p>

                    <p class="mar-no">
                        --
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>

        <!-- Karza stats -->
        <div class="col-sm-3" *ngIf="karzaStats">
            <div class="panel panel-default">
                <div class="panel-heading">Karza Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Karza Details Found
                        <span class="pull-right text-bold"> {{ karzaStats['karza_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Karza Details Not Found
                        <span class="pull-right text-bold"> {{ karzaStats['karza_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Karza Failure
                        <span class="pull-right text-bold">{{ karzaStats['karza_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Karza Not Initiated
                        <span class="pull-right text-bold">{{ karzaStats['karza_not_initiated'] }}</span>
                    </p>

                    <p class="mar-no">
                        --
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>

        <!-- Signzy logs -->
        <div class="col-sm-3" *ngIf="signzyStats">
            <div class="panel panel-default">
                <div class="panel-heading">Signzy Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Signzy Details Found
                        <span class="pull-right text-bold"> {{ signzyStats['signzy_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Signzy Details Not Found
                        <span class="pull-right text-bold"> {{ signzyStats['signzy_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Signzy Failure
                        <span class="pull-right text-bold">{{ signzyStats['signzy_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Signzy Not Initiated
                        <span class="pull-right text-bold">{{ signzyStats['signzy_not_initiated'] }}</span>
                    </p>

                    <p class="mar-no">
                        --
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>


    </div>
</div>