import { Component, ViewChild, OnInit, EventEmitter, Output } from '@angular/core';

import { FormGroup, FormBuilder } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { CommonService } from 'src/app/core/services/common.service';
import { FaceRecognitionService } from 'src/app/core/services/face-recognition.service';

@Component( {
    selector: 'app-face-recognition-add-images',
    templateUrl: './face-recognition-add-images.component.html',
} )

export class FaceRecognitionAddImagesComponent implements OnInit {
    @ViewChild( 'addImage', { static: false } )
    public ImageCreate!: ModalDirective;

    @Output() addImageStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

    form!: FormGroup;
    submitted: boolean = false;
    optionSelect: boolean = false;

    constructor (
        protected commonService: CommonService,
        private fb: FormBuilder,
        private faceRecognitionService: FaceRecognitionService,

    ) { }

    ngOnInit () {
        this.initForm();
    }

    initForm () {
        this.form = this.fb.group( {
            first_image_url: null,
            second_image_url: null,
        } );
    }

    onFileChange ( event: any, name: any ) {
        const reader = new FileReader();
        if ( event.target.files && event.target.files.length > 0 ) {
            const file = event.target.files[ 0 ];
            reader.readAsDataURL( file );
            reader.onload = () => {
                this.form.get( name )?.setValue( ( reader.result as string ).split( ',' )[ 1 ] );
            };
        }
    }

    onSubmit () {
        this.submitted = true;
        const formModel = this.form.value;
        this.faceRecognitionService.addImages( formModel ).subscribe( ( response ) => {
            this.submitted = false;
            this.initForm();
            this.commonService.notifications.success(
                'Success',
                'Added Successfully'
            );
            this.addImageStatus.emit( true );
            this.hide();
        }, ( error ) => {
            this.submitted = false;
            this.addImageStatus.emit( true );
            this.commonService.notifications.error( 'Some Error Occurred' );
        } );
    }

    onChange () {
        this.initForm();
        this.optionSelect = !this.optionSelect;
    }

    show () {
        this.initForm();
        this.ImageCreate.show();
    }

    hide () {
        this.ImageCreate.hide();
    }

}
