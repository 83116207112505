import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from 'src/app/core/services/filter.service';
import { IdentityService } from 'src/app/core/services/identity.service';

@Component( {
    selector: 'app-auto-assign-show-work',
    templateUrl: './auto-assign-show-work.component.html',
    styleUrls: [ './auto-assign-show-work.component.css' ],
} )
export class AutoAssignShowWorkComponent implements OnInit {

    public verificationType!: string;
    public verificationId!: number;

    public verification: any;

    public workTypeId!: number;

    constructor (
        private route: ActivatedRoute,
        private identityService: IdentityService,
        private filterService: FilterService ) { }

    ngOnInit (): void {

        this.verificationId = +this.route.snapshot.params[ 'id' ];

        this.verificationType = this.route.snapshot.params[ 'verification' ];

        this.workTypeId = +this.route.snapshot.queryParams[ 'work_type_id' ];

        console.log( this.verificationId, this.verificationType, this.workTypeId );

        this.fetchWorkDetails();
    }

    fetchWorkDetails (): void {

        console.log( this.filterService );

        console.log( this.filterService.filters );

        console.log( this.filterService.httpParams );

        this.identityService.getWork( this.filterService.httpParams ).subscribe( ( response: any ) => {

            this.verification = response;

        } );

    }

    onDoneClicked (): void {

        alert( 'done' );

        // this.identityService.postCroppingWork( this.filterService.httpParams ).subscribe( e => console.log( e ) );

    }

    onExitClicked (): void {

        alert( 'exit' );

    }

}
