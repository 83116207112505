<div bsModal #editCaseModal="bs-modal" class="modal fade" tabindex="-1">
    <div *ngIf="case">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form [formGroup]="editForm" (ngSubmit)="update()" (keydown.enter)="$event.preventDefault()">
                    <div class="modal-header">
                        <h4 class="modal-title pull-left">Update Case Details</h4>
                    </div>
                    <div class="modal-body">
                        <div class="row">

                            <a *ngIf="!showCnrDetails && case.cnr_details_found==1"
                                class="btn btn-link text-main text-semibold" (click)="toggleCnrDetails()">View Cnr
                                Details</a>
                            <div *ngIf="showCnrDetails">

                                <a class="btn btn-link text-main text-semibold" (click)="toggleCnrDetails()">Hide
                                    Cnr Details</a>
                                <app-cnr-details (onCnrDetailsFetched)="updateCaseDetails($event)"
                                    [cnr_number]="case.cnr_number">
                                </app-cnr-details>

                            </div>
                            <br>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="center-block">Candidate Category
                                    <select class="form-control" formControlName="candidate_category">
                                        <option *ngFor="let category of categories" [value]="category.key">
                                            {{category.index}}
                                        </option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('candidate_category')?.errors">
                                    Required
                                </small>
                            </div>
                            <div class="form-group col-md-3">
                                <label style="min-width: 188.88px;">Nature Of Case
                                    <select class="form-control" formControlName="nature_of_case">
                                        <option *ngFor="let nature_of_case of natureOfCases"
                                            [value]="nature_of_case.key">
                                            {{nature_of_case.index}}
                                        </option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('nature_of_case')?.errors">
                                    Required
                                </small>
                            </div>
                            <div *ngIf="acts" class="form-group col-md-3">
                                <label>Acts Types
                                    <app-multi-select [options]="acts" [placeholder]="'Select Acts Types'"
                                        [controlName]="'act_ids'" [formGName]="editForm"></app-multi-select>
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('act_ids')?.errors">
                                    Required
                                </small>
                            </div>
                            <div *ngIf="editForm.get('act_ids')?.value" class="form-group col-md-3">
                                <span class="text-mute" [popover]="popTemplate" triggers="mouseenter:mouseleave"
                                    container="body">Acts
                                    Sections
                                </span>
                                <ng-template #popTemplate>
                                    <ul class="list-unstyled"
                                        *ngFor="let section of editForm.get('section_names')?.value">
                                        <li> {{section}}</li>
                                    </ul>
                                </ng-template>
                                <app-multi-select [options]="allSections| filterByActId:editForm.get('act_ids')?.value"
                                    [placeholder]="'Select Section'" [controlName]="'section_ids'"
                                    [formGName]="editForm"></app-multi-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 form-group">
                                <label class="center-block">CNR Number
                                    <input class="form-control" formControlName="cnr_number">
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('cnr_number')?.errors">
                                    16 characters only
                                </small>
                            </div>
                            <div class="col-md-3 form-group">
                                <label class="center-block">Case Type
                                    <input class="form-control" formControlName="type">
                                </label>
                            </div>
                            <div class="col-md-3 form-group">
                                <label class="center-block">Filling Number
                                    <input class="form-control" formControlName="filling_number">
                                </label>
                            </div>
                            <div class="col-md-3 form-group">
                                <label class="center-block">Filling Year
                                    <input class="form-control" type="number" formControlName="filling_year">
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('filling_year')?.errors">
                                    4 digits allowed
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 form-group">
                                <label class="center-block">Registration Number
                                    <input class="form-control" formControlName="registration_number">
                                </label>
                            </div>
                            <div class="col-md-3 form-group">
                                <label class="center-block">Registration Year
                                    <input type="number" class="form-control" formControlName="registration_year">
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('registration_year')?.errors">
                                    4 digits allowed
                                </small>
                            </div>
                            <div class="col-md-3 form-group">
                                <label class="center-block">Case Status
                                    <input class="form-control" formControlName="case_status">
                                </label>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">District Court:
                                    <input class="form-control" formControlName="district_court">
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('district_court')?.errors">
                                    Required
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="center-block">Police Station
                                    <input class="form-control" formControlName="police_station">
                                </label>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">FIR Number
                                    <input class="form-control" formControlName="fir_number">
                                </label>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">FIR Year
                                    <input type="number" class="form-control" formControlName="fir_year">
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('fir_year')?.errors">
                                    4 digits allowed
                                </small>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Case Stage
                                    <input value="" class="form-control" formControlName="stage">
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="center-block">Nature Of Disposal
                                    <input class="form-control" formControlName="nature_of_disposal">
                                </label>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Fir Available
                                    <select class="form-control" formControlName="fir_available">
                                        <option value=""> </option>
                                        <option value="1"> Yes </option>
                                        <option value="0"> No </option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('fir_available')?.errors">
                                    Required
                                </small>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Judgement Available
                                    <select class="form-control" formControlName="judgement_available">
                                        <option value=""> </option>
                                        <option value="1"> Yes </option>
                                        <option value="0"> No </option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('judgement_available')?.errors">
                                    Required
                                </small>
                            </div>
                            <ng-template #caseTemplate>
                                <ul class="list-unstyled" style="max-height: 250px;overflow-y: scroll" *ngIf="case">
                                    <li>
                                        Status: {{case.vcourt_status}}
                                    </li>
                                </ul>
                            </ng-template>
                            <div class="form-group col-md-3">
                                <label class="center-block">Vcourt Case ID
                                    <a class="btn btn-xs" [outsideClick]="true" [popover]="caseTemplate"
                                        popoverTitle="Vcourt Case Results">?</a>
                                    <input class="form-control" type="number" formControlName="court_search_result_id">
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="center-block">Risk Level
                                    <select class="form-control" formControlName="risk_level">
                                        <option value=""></option>
                                        <option value="low"> Low </option>
                                        <option value="very-low"> Very Low </option>
                                        <option value="high"> High </option>
                                        <option value="very-high"> Very High </option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="editForm.get('risk_level')?.errors">
                                    Required
                                </small>
                            </div>
                            <div class="form-group col-md-3" *ngIf="statuses && statuses.cases">
                                <label class="center-block">Verification Status</label>
                                <app-multi-select [options]="statuses.cases" [placeholder]="'Select Status'"
                                    [controlName]="'status_id'" [formGName]="editForm"
                                    [multipleValue]="false"></app-multi-select>
                                <small class="text-danger" *ngIf="editForm.get('status_id')?.errors">
                                    Required
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label class="center-block">Client Remarks
                                    <textarea class="form-control" formControlName="client_remarks"></textarea>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Upload Proofs (Screenshot)
                                    <input appUploadAttachment (uploaded)="onFileUploaded($event)" type="file" multiple
                                        class="form-control" [attachableId]="case.id" attachableType="icr.case"
                                        attachmentType="screenshot" />
                                </label>
                            </div>
                            <div class="col-md-4">
                                <label>Upload Ecourt Proof
                                    <input appUploadAttachment (uploaded)="onFileUploaded($event)" type="file" multiple
                                        class="form-control" [attachableId]="case.id" attachableType="icr.case"
                                        attachmentType="ecourt_proof" />
                                </label>
                            </div>

                            <div class="col-md-4">
                                <label>Upload Judgements/FIR/Orders
                                    <input appUploadAttachment (uploaded)="onFileUploaded($event)" type="file" multiple
                                        class="form-control" [attachableId]="case.id" attachableType="icr.case"
                                        attachmentType="fir" />
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div *ngFor="let screenshot of screenshots; index as i">
                                    <a href="{{ screenshot.url }}" class="btn-link">
                                        {{ i + 1 }}. {{ screenshot.filename }}
                                    </a>
                                    <a style="float: right;margin-right: 10px;"
                                        (click)="deleteCaseAttachment(screenshot)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div *ngFor="let ecourtProof of ecourtProofs; index as i">
                                    <a href="{{ ecourtProof.url }}" class="btn-link">
                                        {{ i + 1 }}. {{ ecourtProof.filename }}
                                    </a>
                                    <a style="float: right;margin-right: 10px;"
                                        (click)="deleteCaseAttachment(ecourtProof)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div *ngFor="let fir of firs; index as i">
                                    <a href="{{ fir.url }}" class="btn-link">
                                        {{ i + 1 }}. {{ fir.filename }}
                                    </a>
                                    <a style="float: right;margin-right: 10px;" (click)="deleteCaseAttachment(fir)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="pull-right">
                            <button type="button" class="btn btn-default btn-primary" (click)="update()"
                                [disabled]="!editForm.valid">
                                Update
                            </button>
                        </div>
                        <div class="pull-left">
                            <a class="btn btn-default btn-danger" (click)="cancel()"> Cancel</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>