export const categories = [

    {
        index: '',
        key: null,
    },
    {
        index: 'Petitioner',
        key: 'petitioner',
    },

    {
        index: 'Respondent',
        key: 'respondent',
    },

    {
        index: 'Petitioner - Bail Applicant',
        key: 'bail-applicant',
    },

];

export const natureOfCases = [

    {
        index: '',
        key: null,
    },
    {
        key: 'civil',
        index: 'Civil',
    },

    {
        key: 'criminal',
        index: 'Criminal',
    },

    {
        key: 'motor',
        index: 'Motor Vehicle',
    },

];

export const booleanValues = [
    {
        index: '',
        key: null,
    },
    {
        key: 'yes',
        index: 'Yes',
    },

    {
        key: 'no',
        index: 'No',
    },

]

export const riskOptions = [
    {
        key: null,
        index: null,
    },
    {
        key: 'very low',
        index: 'Very Low Risk',
    },
    {
        key: 'low',
        index: 'Low Risk',
    },
    {
        key: 'high',
        index: 'High Risk',
    },
    {
        key: 'very high',
        index: 'Very High Risk',
    },
];

