import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFormComponent } from '../../search-form/search-form.component';
import { SearchListComponent } from '../../search-list/search-list.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';


@NgModule( {
    declarations: [
        SearchListComponent,
        SearchFormComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        SharedModule,
        TypeaheadModule.forRoot(),
    ],
    exports: [
        SearchListComponent,
        SearchFormComponent,
    ],
} )
export class SearchModule { }
