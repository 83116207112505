import { DigitalAddressFollowUpModalComponent } from './../digital-address/digital-address-follow-up-modal/digital-address-follow-up-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { VerificationStatusTypeaheadComponent } from '../common/verification-status-typeahead/verification-status-typeahead.component';
import { TaskCompletedComponent } from './../work/task-completed/task-completed.component';
import { DownloadModalComponent } from '../common/download-modal/download-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EmployeeInviteFormResponseComponent } from './../employee/employee-invite-form-response/employee-invite-form-response.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ListErrorsComponent } from './list-errors.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { EqualValidatorDirective } from '../common/validators/equal-validtor.directive';
import {
    DistrictIdPipe,
    FilterByActIdPipe,
    FilterByCompanyIdPipe,
    FilterByInstituteIdPipe,
    InArrayPipe,
    StateIdPipe,
} from '../common/state-id.pipe';
import { VerificationFiltersComponent } from '../common/verification-filters/verification-filters.component';
import { AutoAssignFiltersComponent } from '../common/verification-filters/auto-assign-filters/auto-assign-filters.component';
import { VerificationStatsComponent } from '../common/verification-stats/verification-stats.component';
import { VerificationStatusComponent } from '../common/verification-status/verification-status.component';
import { CanDirective } from '../directives/can/can.directive';
import { VerificationEditComponent } from './components/verification-edit/verification-edit.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EmployeeRemarksComponent } from '../employee/employee-remarks/employee-remarks.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropComponent } from './components/image-crop/image-crop.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WorkAssignModalComponent } from '../common/work-assign-modal/work-assign-modal.component';
import { DateValidatorDirective } from '../common/validators/date-validator.directive';
import { AllowedCharactersValidatorDirective } from '../common/validators/allowed-characters-validator.directive';
import { NumericValidatorDirective } from '../common/validators/numeric-validator.directive';
import { LengthValidatorDirective } from '../common/validators/length-validator.directive';
import { ImageZoomDirective } from '../directives/image-zoom/image-zoom.directive';
import { TypeaheadDropdownComponent } from '../common/typeahead-dropdown/typeahead-dropdown.component';
import { VendorDropdownComponent } from '../common/vendor-dropdown/vendor-dropdown.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { AuthorityCreateModalComponent } from '../authority/authority-create-modal/authority-create-modal.component';
import { ContactEditComponent } from '../contact/contact-edit/contact-edit.component';
import { ContactCreateComponent } from '../contact/contact-create/contact-create.component';
import { EmailValidatorDirective } from '../common/validators/email-validator.directive';
import { EmploymentEditModalComponent } from '../employment/employment-edit-modal/employment-edit-modal.component';
import { EmploymentCreateCompanyComponent } from '../employment/employment-create-company/employment-create-company.component';
import { EmploymentFormItemComponent } from '../employment/employment-form-item/employment-form-item.component';
import { EmploymentSendMailComponent } from '../employment/employment-send-mail/employment-send-mail.component';
import { LoadingComponentComponent } from '../common/loading-component/loading-component.component';
import { AddressEditModalComponent } from '../address/address-edit-modal/address-edit-modal.component';
import { AddressCommonEditComponent } from '../address/address-common-edit/address-common-edit.component';
import { EducationCommonEditComponent } from '../education/education-common-edit/education-common-edit.component';
import { EducationEditModalComponent } from '../education/education-edit-modal/education-edit-modal.component';
import { EducationInstitutesCreateSubInstituteComponent } from '../education/institutes/education-institutes-create-sub-institute/education-institutes-create-sub-institute.component';
import { EducationSubInstituteEditComponent } from '../education/institutes/education-sub-institute-edit/education-sub-institute-edit.component';
import { EducationInstitutesEditComponent } from '../education/institutes/education-institutes-edit/education-institutes-edit.component';
import { EducationSendMailComponent } from '../education/education-send-mail/education-send-mail.component';
import { EducationCreateInstituteComponent } from '../education/education-create-institute/education-create-institute.component';
import { EmploymentFollowUpMailComponent } from '../employment/employment-follow-up-mail/employment-follow-up-mail.component';
import { EmploymentRevertFormModalComponent } from '../employment/employment-revert-form-modal/employment-revert-form-modal.component';
import { EmployeeAdditionalDetailsModalComponent } from '../common/employee-additional-details-modal/employee-additional-details-modal.component';
import { EmployeeAdditionalDetailsImportComponent } from '../common/employee-additional-details-import/employee-additional-details-import.component';
import { MobileNumberValidatorDirective } from '../common/validators/mobile-number-validator.directive';
import { CopyVerificationAddressDetailsComponent } from '../common/copy-verification-address-details/copy-verification-address-details.component';
import { EmployeeSideBlockComponent } from '../employee/employee-side-block/employee-side-block.component';
import { CompletionDateValidatorDirective } from '../common/validators/validate-completion-date.directive';
import { MaintenanceModeComponent } from './components/maintenance-mode/maintenance-mode.component';
import { VerificationCommonEditComponent } from './components/verification-common-edit/verification-common-edit.component';
import { BulkUploadFilesComponent } from '../common/bulk-upload-files/bulk-upload-files.component';
import { VerificationDocumentTypePipe } from '../common/bulk-upload-files/verification-document-type.pipe';
import { AllowNotNullValidatorDirective } from '../common/validators/allow-not-null-values-validator.directive';
import { VerificationEditHeaderComponent } from '../common/verification-edit-header/verification-edit-header.component';
import { SendToVendorComponent } from '../vendor/send-to-vendor/send-to-vendor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatExpansionModule } from '@angular/material/expansion';
// import { VerificationHeaderComponent } from '../common/verification-edit-header/verification-header.component';
import { SelectFilterModalComponent } from '../common/verification-filters/select-filter-modal/select-filter-modal.component'
import { NgxPaginationModule } from 'ngx-pagination';
import { HideDirective } from '../directives/hide/hide.directive';
import { DisableDirective } from '../directives/disable/disable.directive';
import { UploadAttachmentDirective } from '../directives/upload-attachment.directive';
import { UploadDocumentDirective } from '../directives/upload-document.directive';
import { OnlyNumbersDirective } from '../directives/only-numbers.directive';
import { EmployeeConsentFormComponent } from '../employee/employee-consent-form/employee-consent-form.component';
import { EmployeeBasicDetailsComponent } from '../employee/employee-basic-details/employee-basic-details.component';
import { DistrictTypeaheadComponent } from '../common/district-typeahead/district-typeahead.component';
import { StateTypeaheadComponent } from '../common/state-typeahead/state-typeahead.component';
import { VendorTypeaheadComponent } from '../common/vendor-typeahead/vendor-typeahead.component';
import { EmploymentCompanyTypeaheadComponent } from '../common/employment-company-typeahead/employment-company-typeahead.component';
import { LanguageTypeaheadComponent } from '../common/language-typeahead/language-typeahead.component';
import { SubInstituteTypeaheadComponent } from '../common/sub-institute-typeahead/sub-institute-typeahead.component';
import { AuthorityTypeaheadComponent } from '../common/authority-typeahead/authority-typeahead.component';
import { InstituteTypeaheadComponent } from '../common/institute-typeahead/institute-typeahead.component';
import { CountryTypeaheadComponent } from '../common/country-typeahead/country-typeahead.component';
import { CopyToClipboardComponent } from './components/copy-to-clipboard/copy-to-clipboard.component';
import { PccFormItemComponent } from 'src/app/client-forms-v2/pcc-form/pcc-form-item/pcc-form-item.component';
import { DownloadDocumentDirective } from '../directives/download-document/download-document.directive';
import { PdfViewerPopupComponent } from './components/pdf-viewer-popup/app-pdf-viewer-popup.component';
import { MultiSelectComponent } from '../common/multi-select/multi-select.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SmartMultiSelectorComponent } from '../common/smart-multi-selector/smart-multi-selector.component';
import { DigitalAddressListComponent } from '../digital-address/digital-address-list/digital-address-list.component';
import { DigitalAddressFiltersComponent } from '../digital-address/digital-address-filters/digital-address-filters.component';
import { DigitalAddressInviteEmailListItemComponent } from '../digital-address/digital-address-invite-email-list-item/digital-address-invite-email-list-item.component';
import { DigitalAddressInviteSmsListItemComponent } from '../digital-address/digital-address-invite-sms-list-item/digital-address-invite-sms-list-item.component';
import { EmailValidatorByDomainDirective } from '../common/validators/email-validator-by-domain.directive';
import { UserLevelIdValidatorDirective } from '../common/validators/user-level-id-validator.directive';
import { ErrorMessagesComponent } from './components/validation/error-messages.component';
import { SafehtmlPipe } from './pipe/safehtml.pipe';

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        FileUploadModule,
        NgSelectModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        ImageCropperModule,
        TypeaheadModule.forRoot(),
        CKEditorModule,
        MatExpansionModule,
        NgxPaginationModule,
        ProgressbarModule.forRoot(),
        MatProgressBarModule

    ],
    declarations: [
        ListErrorsComponent,
        ShowAuthedDirective,
        StateIdPipe,
        InArrayPipe,
        FilterByActIdPipe,
        FilterByCompanyIdPipe,
        VerificationFiltersComponent,
        AutoAssignFiltersComponent,
        VerificationStatsComponent,
        VerificationStatusComponent,
        VerificationStatusTypeaheadComponent,
        SelectFilterModalComponent,
        CanDirective,
        HideDirective,
        DisableDirective,
        VerificationEditComponent,
        EmployeeRemarksComponent,
        WorkAssignModalComponent,
        ImageCropComponent,
        DateValidatorDirective,
        AllowedCharactersValidatorDirective,
        NumericValidatorDirective,
        MobileNumberValidatorDirective,
        LengthValidatorDirective,
        EmailValidatorDirective,
        CompletionDateValidatorDirective,
        EqualValidatorDirective,
        ImageZoomDirective,
        LengthValidatorDirective,
        TypeaheadDropdownComponent,
        VendorDropdownComponent,
        FilterByInstituteIdPipe,
        DistrictIdPipe,
        AuthorityCreateModalComponent,
        ContactEditComponent,
        ContactCreateComponent,
        EmploymentEditModalComponent,
        EmploymentCreateCompanyComponent,
        EmploymentFormItemComponent,
        EmploymentSendMailComponent,
        LoadingComponentComponent,
        AddressEditModalComponent,
        AddressCommonEditComponent,
        EducationEditModalComponent,
        EducationCommonEditComponent,
        EducationSendMailComponent,
        EducationInstitutesEditComponent,
        EducationInstitutesCreateSubInstituteComponent,
        EducationSubInstituteEditComponent,
        EducationCreateInstituteComponent,
        EmploymentFollowUpMailComponent,
        EmploymentRevertFormModalComponent,
        EmployeeAdditionalDetailsModalComponent,
        EmployeeAdditionalDetailsImportComponent,
        CopyVerificationAddressDetailsComponent,
        EmployeeSideBlockComponent,
        EmployeeBasicDetailsComponent,
        EmployeeConsentFormComponent,
        MaintenanceModeComponent,
        AllowNotNullValidatorDirective,
        VerificationCommonEditComponent,
        BulkUploadFilesComponent,
        VerificationDocumentTypePipe,
        VerificationEditHeaderComponent,
        // VerificationHeaderComponent,
        SendToVendorComponent,
        EmployeeInviteFormResponseComponent,
        UploadAttachmentDirective,
        UploadDocumentDirective,
        DownloadModalComponent,
        TaskCompletedComponent,
        OnlyNumbersDirective,
        CountryTypeaheadComponent,
        EmploymentCompanyTypeaheadComponent,
        AuthorityTypeaheadComponent,
        DistrictTypeaheadComponent,
        StateTypeaheadComponent,
        InstituteTypeaheadComponent,
        CopyToClipboardComponent,
        SubInstituteTypeaheadComponent,
        LanguageTypeaheadComponent,
        VendorTypeaheadComponent,
        PccFormItemComponent,
        DownloadDocumentDirective,
        PdfViewerPopupComponent,
        MultiSelectComponent,
        SmartMultiSelectorComponent,
        DigitalAddressListComponent,
        DigitalAddressFiltersComponent,
        DigitalAddressFollowUpModalComponent,
        DigitalAddressInviteEmailListItemComponent,
        DigitalAddressInviteSmsListItemComponent,
        EmailValidatorByDomainDirective,
        UserLevelIdValidatorDirective,
        ErrorMessagesComponent,
        SafehtmlPipe,
    ],
    exports: [
        BsDropdownModule,
        ModalModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        ListErrorsComponent,
        ShowAuthedDirective,
        EqualValidatorDirective,
        StateIdPipe,
        InArrayPipe,
        FilterByCompanyIdPipe,
        VerificationFiltersComponent,
        AutoAssignFiltersComponent,
        VerificationStatsComponent,
        VerificationStatusComponent,
        VerificationStatusTypeaheadComponent,
        CanDirective,
        HideDirective,
        DisableDirective,
        FileUploadModule,
        EmployeeRemarksComponent,
        NgSelectModule,
        FilterByActIdPipe,
        PopoverModule,
        WorkAssignModalComponent,
        ImageCropComponent,
        ImageCropperModule,
        FilterByActIdPipe,
        DateValidatorDirective,
        AllowedCharactersValidatorDirective,
        NumericValidatorDirective,
        MobileNumberValidatorDirective,
        LengthValidatorDirective,
        EmailValidatorDirective,
        EqualValidatorDirective,
        CompletionDateValidatorDirective,
        ImageZoomDirective,
        LengthValidatorDirective,
        TypeaheadModule,
        TypeaheadDropdownComponent,
        VendorDropdownComponent,
        FilterByInstituteIdPipe,
        CKEditorModule,
        DistrictIdPipe,
        AuthorityCreateModalComponent,
        ContactEditComponent,
        ContactCreateComponent,
        EmploymentEditModalComponent,
        EmploymentCreateCompanyComponent,
        EmploymentFormItemComponent,
        EmploymentSendMailComponent,
        LoadingComponentComponent,
        AddressEditModalComponent,
        AddressCommonEditComponent,
        EducationEditModalComponent,
        EducationCommonEditComponent,
        EducationSendMailComponent,
        EducationInstitutesEditComponent,
        EducationInstitutesCreateSubInstituteComponent,
        EducationSubInstituteEditComponent,
        EducationCreateInstituteComponent,
        EmploymentFollowUpMailComponent,
        EmploymentRevertFormModalComponent,
        EmployeeAdditionalDetailsModalComponent,
        EmployeeAdditionalDetailsImportComponent,
        CopyVerificationAddressDetailsComponent,
        EmployeeSideBlockComponent,
        EmployeeBasicDetailsComponent,
        EmployeeConsentFormComponent,
        BulkUploadFilesComponent,
        VerificationDocumentTypePipe,
        AllowNotNullValidatorDirective,
        VerificationEditHeaderComponent,
        // VerificationHeaderComponent,
        SendToVendorComponent,
        EmployeeInviteFormResponseComponent,
        NgxPaginationModule,
        UploadAttachmentDirective,
        UploadDocumentDirective,
        TooltipModule,
        DownloadModalComponent,
        ProgressbarModule,
        TaskCompletedComponent,
        OnlyNumbersDirective,
        CountryTypeaheadComponent,
        EmploymentCompanyTypeaheadComponent,
        AuthorityTypeaheadComponent,
        DistrictTypeaheadComponent,
        StateTypeaheadComponent,
        InstituteTypeaheadComponent,
        CopyToClipboardComponent,
        SubInstituteTypeaheadComponent,
        LanguageTypeaheadComponent,
        VendorTypeaheadComponent,
        PccFormItemComponent,
        DownloadDocumentDirective,
        PdfViewerPopupComponent,
        MultiSelectComponent,
        SmartMultiSelectorComponent,
        DigitalAddressListComponent,
        DigitalAddressFiltersComponent,
        DigitalAddressFollowUpModalComponent,
        DigitalAddressInviteEmailListItemComponent,
        DigitalAddressInviteSmsListItemComponent,
        EmailValidatorByDomainDirective,
        UserLevelIdValidatorDirective,
        ErrorMessagesComponent,
        SafehtmlPipe,
    ],

} )
export class SharedModule {

}
