import { CommonService } from 'src/app/core/services/common.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/core/models/user.model';
import { ClientService } from 'src/app/core/services/client.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-client-list',
    templateUrl: './client-list.component.html',
    styleUrls: [ './client-list.component.css' ],
} )
export class ClientListComponent implements OnInit {


    loading: any;
    @Input() pagination!: any;
    @Output() onClientStatusUpdate: EventEmitter<any> = new EventEmitter<any>();

    constructor ( private clientService: ClientService,
        private commonService: CommonService,
        private filterService: FilterService,
        private confirmationModalService: ConfirmationModalService
    ) { }
    ngOnInit (): void {

    }

    toggleStatus ( user: User ): void {

        this.clientService.toggleStatus( user.id ).subscribe(
            {
                next: ( response: any ) => {
                    this.commonService.notifications.success( 'Client Deactivated', response[ 'message' ] )
                },
                error: ( error: any ) => {
                    this.commonService.notifications.error( error );
                },
                complete: () => {
                    this.onClientStatusUpdate.emit();
                }

            }
        );
    }

    showPage ( page: string | any ): void {

        this.filterService.filterByPage( page );
    }


    onResetPasswordButtonClicked ( user: any ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.resetPassword( user.id ) );
    }

    resetPassword ( userId: any ) {
        this.clientService.resetPassword( userId ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( response[ 'message' ] );
            },
            error: ( err: any ) => {
                this.commonService.notifications.error( err );
            },
        } );
    }

    getActivationStatusLabel ( user: User ) {
        return user.status === 1 ? 'Deactivate' : 'Activate';
    }
}
