import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FaceRecognitionService } from 'src/app/core/services/face-recognition.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { FaceRecognitionStats } from 'src/app/core/interfaces/face-recognotion-logs/face-recognotion-stats';

@Component( {
    selector: 'app-face-recognition-stats',
    templateUrl: './face-recognition-stats.component.html',
} )
export class FaceRecognitionStatsComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();
    faceRecognitionStats!: FaceRecognitionStats;

    constructor (
        private faceRecognitionService: FaceRecognitionService,
        private filterService: FilterService
    ) { }

    ngOnInit () {
        this.filterParams = this.filterService.httpParams;
        this.filterStats();
    }

    filterStats (): void {
        this.faceRecognitionService.getStats( this.filterParams ).subscribe( ( response ) => {
            this.faceRecognitionStats = response[ 'face_recognition' ];
        } );
    }
}
