<div bsModal #userPermissionsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Assigned Permissions</h4>
            </div>
            <div class="panel-body">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Component</th>
                            <th>Action</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let permission of permissions">
                            <td>{{ permission.subject }}</td>
                            <td>{{ permission.action }}</td>
                            <td>{{ permission.description }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="modal-footer">
                <div class="pull-right">
                    <button class="btn btn-default btn-primary" (click)="hide()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>