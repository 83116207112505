<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">{{ company.name }}

        <span *ngIf="company.logo"><img src="{{company.logo}}" style="width: inherit; height: 40px;margin-left:900px ">
        </span>

    </h1>
    <ul class="breadcrumb insta-breadcrumb">
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/companies">All Companies</a></li>
        <li class="active">{{company.name}}</li>
    </ul>
</div>

<div class="wrapper-md">

    <div class="row">
        <div class="col-md-2 col-xs-2 mt-1"
            *ngIf="company && isDisableClientListButton && isDisableOptionsForInactiveCompany">

            <a class="btn btn-primary btn-addon" style="width: 100%;"
                [routerLink]="['/companies/'+company.id+'/clients']">
                <i class="fa fa-info"></i>
                Clients Account Details
            </a>

        </div>

        <div class="col-md-2 col-xs-2 mt-1">

            <a *ngIf="company.id" class="btn btn-primary btn-addon" [routerLink]="['/companies', company.id,'history']"
                style="width: 100%;">
                <i class="fa fa-plus"></i>
                History
            </a>

        </div>

        <div class="col-md-2 col-xs-2 mt-1" [appHide]="'company,generate.excel'">

            <a *ngIf="company.id" class="btn btn-primary btn-addon" [routerLink]="['/companies', company.id,'excel']"
                style="width: 100%;">
                <i class="fa fa-plus"></i>
                Generate Excel
            </a>

        </div>

        <div [appCan]="'admin'" *ngIf="isDisableOptionsForInactiveCompany">

            <div class="col-md-2 col-xs-2 mt-1">

                <a class="btn btn-primary btn-addon" style="width: 100%;"
                    [routerLink]="['/companies', company.id,'edit']">
                    <i class="fa fa-edit"></i>
                    Edit Company
                </a>

            </div>

            <div class="col-md-2 col-xs-2 mt-1">

                <a *ngIf="company" class="btn btn-primary btn-addon" style="width: 100%;"
                    [routerLink]="['/lots/create']" [queryParams]="{company:company.id}">
                    <i class="fa fa-plus"></i>
                    Create New Lot
                </a>

            </div>

            <div class="col-md-2 col-xs-2 mt-1">

                <a *ngIf="company" class="btn btn-primary btn-addon" style="width: 100%;"
                    routerLink="/companies/{{company.id}}/lots/deleted">
                    <i class="fa fa-list"></i>
                    Deleted Lots
                </a>

            </div>

            <div class="col-md-2 col-xs-2 mt-1">

                <a *ngIf="company" class="btn btn-primary btn-addon" style="width: 100%;"
                    routerLink="/companies/{{company.id}}/metafields">
                    <i class="fa fa-list"></i> Metafields
                </a>

            </div>

            <div class="col-md-2 col-xs-2 mt-1">

                <a *ngIf="company" class="btn btn-primary btn-addon" style="width: 100%;"
                    routerLink="/companies/{{company.id}}/update-role">
                    <i class="fa fa-edit"></i> Company Update Role
                </a>

            </div>

        </div>

    </div>

</div>

<app-lot-list></app-lot-list>