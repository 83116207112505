import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateAllowedCharacters } from '../common/validators/allowed-characters-validator.directive';
import { validateEmail } from '../common/validators/email-validator.directive';
import { validateLength } from '../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../common/validators/numeric-validator.directive';
import { CommonService } from '../core/services/common.service';
import { VerificationService } from '../core/services/verification.service';

@Component( {
    selector: 'app-edit-vendor-modal',
    templateUrl: './edit-vendor-modal.component.html',
    styleUrls: [ './edit-vendor-modal.component.css' ],
} )
export class EditVendorModalComponent implements OnInit {

    editVendorFormGroup!: FormGroup;
    response: any;

    @ViewChild( 'editModal', { static: false } ) editModal!: ModalDirective;

    @Output()
    onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private verificationService: VerificationService,
        private fb: FormBuilder,
        private commonService: CommonService,
    ) { }

    ngOnInit (): void {

        this.editVendorFormGroup = this.fb.group( {
            id: [ '' ],
            company_name: [ '', [ Validators.required ] ],
            places_covered: [ '' ],
            contact_person: [ '', [ validateAllowedCharacters( '' ) ] ],
            contact_number: [ '', [ validateLength( '10' ), validateNumericCharacters( '' ) ] ],
            contact_email: [ '', [ validateEmail() ] ],
            education_verification: [ '' ],
            employment_verification: [ '' ],
            address_verification: [ '' ],
            identity_verification: [ '' ],
            global_db_verification: [ '' ],
        } );
    }


    show ( vendorId: number ): void {

        this.verificationService.getVendorById( vendorId ).subscribe(
            response => {
                this.response = response;
                this.editVendorFormGroup.patchValue( response );
                this.editModal.show();
            }
        );
    }

    update (): void {

        this.verificationService.updateVendor( this.editVendorFormGroup.value ).subscribe(
            response => {
                this.editModal.hide();
                this.commonService.notifications.success( 'Vendor Updated', 'Vendor Details Updated' );
            },
            error => {
                this.commonService.notifications.error( 'Error', 'Vendor could not be Updated' );
            }
        );

    }

    hide (): void {

        this.editModal.hide();
    }
}
