<div bsModal #userEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="userEditForm" (ngSubmit)="editUser()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Contact</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <input formControlName="id" class="form-control form-control-lg" type="hidden"
                                        ngModel="{{user.id}}" />
                                    <label class="center-block"> Name of Person</label>
                                    <input formControlName="name" class="form-control form-control-lg"
                                        ngModel="{{user.name}}" />

                                </fieldset>
                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Designation </label>
                                    <input formControlName="designation" class="form-control form-control-lg"
                                        ngModel="{{user.designation}}" />
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label>Email </label>
                                    <input formControlName="email" class="form-control form-control-lg"
                                        ngModel="{{user.email}}" />
                                </fieldset>
                            </div>

                        </div>

                        <div class="row">

                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Mobile Number </label>
                                    <input formControlName="mobile_number" class="form-control form-control-lg"
                                        ngModel="{{user.mobile_number}}" />
                                </fieldset>

                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group" *ngIf="userLevels">
                                    <label>Level Id </label>
                                    <input formControlName="level_name" [typeahead]="userLevels"
                                        [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Level"
                                        [typeaheadItemTemplate]="customItemTemplate"
                                        (typeaheadOnSelect)="OnLevelSelected($event)" class="form-control">
                                </fieldset>
                            </div>

                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Birthday </label>
                                    <input type="date" formControlName="dob" class="form-control form-control-lg"
                                        ngModel="{{user.dob}}" />
                                </fieldset>

                            </div>

                        </div>


                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!userEditForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>