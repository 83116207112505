import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaceRecognitionLogListComponent } from './face-recognition-log-list/face-recognition-log-list.component';
import { FaceRecognitionFitersComponent } from './face-recognition-fiters/face-recognition-fiters.component';
import { FaceRecognitionStatsComponent } from './face-recognition-stats/face-recognition-stats.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { FaceRecognitionService } from '../core/services/face-recognition.service';
import { FaceRecognitionAddImagesComponent } from './face-recognition-add-images/face-recognition-add-images.component';
import { FaceRecognitionExcelImportComponent } from './face-recognition-excel-import/face-recognition-excel-import.component';
import { SharedModule } from '../core/shared.module';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
    ],
    declarations: [
        FaceRecognitionLogListComponent,
        FaceRecognitionFitersComponent,
        FaceRecognitionStatsComponent,
        FaceRecognitionAddImagesComponent,
        FaceRecognitionExcelImportComponent,
    ],
    providers: [
        FaceRecognitionService,
    ],
} )

export class FaceRecognitionModule { }
