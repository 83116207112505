import { CommonService } from 'src/app/core/services/common.service';
import { LotService } from 'src/app/core/services/lot.service';
import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ClientFormsService } from 'src/app/core/services/client-forms.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-client-forms-filters',
    templateUrl: './client-forms-filters.component.html'
} )
export class ClientFormsFiltersComponent {

    @Input() isFormStatusFilterRequired = true;

    filterFormGroup!: FormGroup;
    lots: any[] = [];
    formStatuses: { id: number, name: string }[] = [];

    get companies () { return this.global.fetchCompanies(); }

    constructor (
        public global: GlobalService,
        private fb: FormBuilder,
        private filterService: FilterService,
        private lotService: LotService,
        private formService: ClientFormsService,
        private commonService: CommonService,
    ) { }

    ngOnInit (): void {

        this.formStatuses = this.formService.getStatuses();

        this.initForm();

        this.updateFormFiltersv2();

        this.filterResults();

        this.filterFormGroup.get( 'company_id' )?.valueChanges.subscribe( data => {
            this.lots = []
            this.filterFormGroup.get( 'lot_id' )?.reset();
        } )
    }

    initForm (): void {

        this.filterFormGroup = this.fb.group( {
            company_id: [ [] ],
            lot_id: [],
            page: 1,
        } );

        if ( this.isFormStatusFilterRequired ) {
            this.filterFormGroup.setControl( 'form_status', new FormControl( [ 1 ] ) )
        }
    }

    onCompanyDropDownClosed () {
        this.fetchLots();
    }

    fetchLots (): void {

        const params = new HttpParams();

        const companyIdControl = this.filterFormGroup.get( 'company_id' )

        const $companyIds: [] = companyIdControl?.value;

        // remove all current lots
        this.lots.splice( 0, this.lots.length );

        const combinedLots: any[] = [];

        if ( !$companyIds || !$companyIds.toString() ) {
            this.lots = [];
            return;
        }

        let request = params.set( 'company_id', $companyIds.toString() );

        // fetch the lots for each company id selected
        this.commonService.companiesLots( request ).subscribe( lots => {

            lots.forEach( lot => combinedLots.push( lot ) );

            this.lots = combinedLots;

        } );
    }

    updateFormFiltersv2 () {

        const convertIds = [ 'company_id', 'lot_id', 'form_status' ]

        this.filterService.updateFormFilters( this.filterFormGroup, convertIds );
    }

    filterResults (): void {

        this.fetchLots();

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    resetForm (): void {

        this.initForm();

        this.filterResults();

    }
}
