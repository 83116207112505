import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { ClientService } from 'src/app/core/services/client.service';
import { FilterService } from 'src/app/core/services/filter.service';

@Component( {
    selector: 'app-company-clients-list',
    templateUrl: './company-clients-list.component.html',
    styleUrls: [ './company-clients-list.component.css' ]
} )
export class CompanyClientsListComponent implements OnInit {

    pagination: any;
    filtersSubscription: any;
    filterParams: HttpParams = new HttpParams();;

    constructor ( private clientService: ClientService,
        private filterService: FilterService,
        private route: ActivatedRoute
    ) {
    }

    get companyId () { return this.route.snapshot.paramMap.get( 'id' ) }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( this.updateParamsForCompany( params ) );

        } );

        this.filterResults( this.updateParamsForCompany( this.filterService.filters ) );
    }


    updateParamsForCompany ( params: any ): any {
        return {
            ...params, // Copy existing properties from params
            company_id: this.companyId // Add or update company_id
        };
    }

    filterResults ( params: Params ): void {

        this.clientService.getAll( params ).subscribe(
            ( response: any ) => {

                this.pagination = response;
            }
        );

    }

    exportExcel () {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.updateParamsForCompany( this.filterService.httpParams ),
            paramsString: this.updateParamsForCompany( this.filterService.httpParams ).toString(),
            route: 'clients/download-excel',
            fileName: 'Clients Lits',
            method: 'get',
            postParams: null,
        };

        this.clientService.showDownloadModal( initialState );
    }

}
