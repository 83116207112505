import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

/**
 * Check if input contains digits only
 *
 * @param {string} allowedCharacters
 * @returns {ValidatorFn}
 */
export function validateNumericCharacters ( allowedCharacters: string, blank = false ): ValidatorFn {

    return ( control: AbstractControl ): ValidationErrors | null => {

        // Regex to only allow numbers
        const regex = new RegExp( '^[0-9]*$' );

        if ( !control.value ) {
            return {};
        }

        if ( blank ) {
            if ( regex.test( control.value ) || control.value == 'blank' ) {
                return {};
            }

        }

        if ( regex.test( control.value ) ) {
            return {};
        }

        return { 'validateNumericCharacters': { value: control.value } };
    };
}


@Directive( {
    selector: '[validateNumericCharacters]',
    providers: [ { provide: NG_VALIDATORS, useExisting: NumericValidatorDirective, multi: true } ],
} )
export class NumericValidatorDirective implements Validator {
    @Input()
    allowedCharacters!: string;

    validate ( control: AbstractControl ): { [ key: string ]: any } {

        return validateNumericCharacters( this.allowedCharacters )( control ) || {};
    }
}
