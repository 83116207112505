import { Component, OnInit, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

import { HttpParams } from '@angular/common/http';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { PanService } from '../../core/services/pan.service';
import { CommonService } from '../../core/services/common.service';
import { VoterService } from '../../core/services/voter.service';

@Component( {
    selector: 'app-voter-filters',
    templateUrl: './voter-filters.component.html',
    styleUrls: [ './voter-filters.component.css' ],
} )
export class VoterFiltersComponent extends ExportExcelComponent implements OnInit {


    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients: any;
    fetchingInProgress!: boolean;
    data: any[] = [];

    constructor (
        private router: Router,

        private fb: FormBuilder,
        private voterService: VoterService,
        private commonService: CommonService
    ) {
        super();
        this.initForm();
    }

    ngOnInit () {
        this.setDefault();

        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

        this.filterResults();
    }

    private setDefault () {

        this.filterParams.keys().forEach( key => {

            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            voter_id_number: 'all',
            created_before: 'all',
            created_after: 'all',
            status: 'all',
            source: 'all',
            client_api_id: 'all',
            page: 1,
        } );
    }

    filterResults (): void {

        this.filterFormGroup.get( 'page' )?.setValue( 1 );

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };
        // Get the current url string & split it into 2 part by '?'
        // Get only the first part which is the path
        // eg. 'identity?xyx=abc' in url will return only 'identity'
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];
        // Navigate to current path along with our set query string
        // This will add query params to the urls
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    // generateExcel() {
    //     this.panService.getDataForExcel(this.filterParams).subscribe(
    //         response => {
    //             this.downloadExcel(response, 'Pan Logs Excel');
    //         },
    //         error => {
    //             this.commonService.notifications.error('Some Error Occurred')
    //         });
    // }

    onSearch () {
        this.filterResults();
    }

    exportLogsExcel () {
        this.fetchingInProgress = true;
        this.voterService.getDataForExcel( this.filterParams ).subscribe(
            ( response ) => {

                this.data = this.data.concat( response.data );

                if ( response[ 'next_page_url' ] ) {

                    this.filterParams = this.filterParams.set( 'page', response[ 'next_page_url' ].split( '=' )[ 1 ] );

                    this.exportLogsExcel();
                } else {
                    this.commonService.notifications.success( 'All Data Fetched' );
                    this.downloadExcel( this.data, 'Voter Logs Excel' );
                    this.fetchingInProgress = false;
                    this.data = [];
                }
            } );
    }

    resendAll () {
        this.voterService.resendAll( this.filterParams ).subscribe( response => {
            this.commonService.notifications.success( ' Request Resent' );
        },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

}
