import { Component, OnInit, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';


@Component( {
    selector: 'app-face-recognition-fiters',
    templateUrl: './face-recognition-fiters.component.html',
} )
export class FaceRecognitionFitersComponent implements OnInit {
    @Input() filterParams!: HttpParams;
    all: string = 'all';
    filterFormGroup!: FormGroup;
    clients!: any[];

    constructor (
        private router: Router,

        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService ) {
        this.initForm();
    }

    ngOnInit () {
        this.setDefault();
        this.getAllClientApis();
        this.filterResults();
    }

    initForm (): void {
        const myDate = new Date();
        const previousMonth = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );
        previousMonth.setDate( myDate.getDate() + 1 );

        this.filterFormGroup = this.fb.group( {
            client_api_id: 'all',
            via: 'all',
            signzy_status: 'all',
            created_before: 'all',
            created_after: previousMonth.toJSON().slice( 0, 10 ),
            page: 1,
        } );
    }

    setDefault (): void {
        this.filterParams.keys().forEach( ( key ) => {
            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    filterResults (): void {
        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    getAllClientApis (): void {
        let params = new HttpParams();
        params = params.set( 'type', 'company' );
        this.commonService.getAllClientApis( params ).subscribe( ( response ) => {
            this.clients = response;
        },
            ( error ) => {
                this.commonService.notifications.error( 'Some Error Occured' );
            } );
    }

    getImage ( imagerUrl: string ) {
        window.open( imagerUrl, '_blank' );
    }

}
