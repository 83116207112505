<app-vehicle-html-response-modal></app-vehicle-html-response-modal>

<div *ngIf="source_log && source_received; else sourceNotReceived">
    <div *ngIf="source_details; else sourceDetailsNotFound">
        <button class="btn btn-block label-success btn-status" (click)="showVehicleRcResponseModal()"
            tooltip="Found">F</button>
    </div>
</div>

<ng-template #sourceNotReceived>
    <div *ngIf="source_log; else sourceLogNotCreated">
        <button class="btn btn-block label-danger btn-status" (click)="showVehicleRcResponseModal()"
            tooltip="Failure">E</button>
    </div>
    <ul class="list-unstyled">
        <li>{{source_failure_reason}}</li>
    </ul>
</ng-template>
<ng-template #sourceDetailsNotFound>
    <button class="btn btn-block label-danger btn-status" (click)="showVehicleRcResponseModal()"
        tooltip="Not Found">NF</button>
</ng-template>

<ng-template #sourceLogNotCreated>
    <button class="btn btn-block label-warning btn-status" tooltip="Not Initiated">NI</button>
</ng-template>