import { Component, OnInit } from '@angular/core';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VehicleService } from '../../core/services/vehicle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { HttpParams } from '@angular/common/http';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-vehicle-excel-import',
    templateUrl: './vehicle-excel-import.component.html',
    styleUrls: [ './vehicle-excel-import.component.css' ],
} )
export class VehicleExcelImportComponent extends ImportExcelComponent implements OnInit {

    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    uploadForm: FormGroup;
    validationMessages: ValidationMessages = {};
    clients!: any[];

    constructor (
        private vehicleService: VehicleService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            'excel': [ '', Validators.required ],
        } );

    }

    ngOnInit () {
        let params = new HttpParams();
        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

    }

    // Initialize the excelForm.
    initExcelForm () {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
            client_api_id: [ '', Validators.required ],
            excel_type: [ '', Validators.required ],
            sources: [ [] ],
        } );
    }

    setValidationMessages () {
        this.validationMessages[ 'rc_number' ] = 'Required';
    }

    applyValidations () {
    }

    importExcel () {

        this.vehicleService.importExcel( this.excelForm.value ).subscribe(
            response => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ '/vehiclerc' ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }


    onCheckboxChange ( event: any, value: any ) {

        const list = this.excelForm.value.sources;

        const data = {
            index: value,
        };
        if ( event.target.checked == true ) {
            list.push( data );
        } else {
            if ( list.length > 1 ) {
                for ( let i = 0; i < list.length; i++ ) {
                    if ( list[ i ].index == value ) {
                        list.splice( i, true );
                        break;
                    }
                }
            } else {
                list.splice( 0, true );
            }

        }
        this.excelForm.value.sources = list;

    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
