import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { idDocuments } from '../client-forms-v2.enum'

@Component( {
    selector: 'app-identity-form',
    templateUrl: './identity-form.component.html',
} )
export class IdentityFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    identityList = idDocuments;

    selectedOption: string[] = [];

    isSectionChecked: boolean = false;

    maxLimit: number = 6;

    identityForm = this.fb.group( {
        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] ),
    } )

    get identityVerificationArray (): FormArray | any {
        return this.identityForm.get( 'verifications' ) as FormArray;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.identityVerificationArray.length > 0;
    }

    constructor ( private fb: FormBuilder, private commonService: CommonService ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'identity', this.identityForm );
    }

    sectionToggle ( $event: any ): void {
        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {
            this.identityVerificationArray.clear();
        }
    }

    formSettingItem (): FormGroup {

        return this.fb.group( {
            type: this.fb.control( 'any', [ Validators.required ] ),
            required: this.fb.control( true, [ Validators.required ] ),
        } );

    }

    addIdentity (): void {
        if ( this.identityVerificationArray.length >= this.maxLimit ) {
            this.commonService.notifications.error( 'Identity', `Identity max limit is ${ this.maxLimit }` );
        } else {
            this.identityVerificationArray.push( this.formSettingItem() );
        }
    }

    skipSelectedIdentity ( index: any, $event: any ): void {
        this.selectedOption[ index ] = $event.target.value;
    }

    onIdentityTypeDeleted ( index: number ): void {

        const selectedIdentityTypeValue: string = this.identityVerificationArray.at( index ).get( 'type' )?.value;

        this.selectedOption = this.selectedOption.filter( ( identityTypeValue: string ) => identityTypeValue != selectedIdentityTypeValue );

        this.identityVerificationArray.removeAt( index );
    }
}
