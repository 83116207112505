import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Permission } from '../models/permission.model';

@Component( {
    selector: 'app-permission-edit',
    templateUrl: './permission-edit.component.html',
    styleUrls: [ './permission-edit.component.css' ],
} )
export class PermissionEditComponent implements OnInit {

    isSubmitting = false;
    permissionEditForm!: FormGroup;
    permission: Permission = new Permission();
    error: any = [];

    @ViewChild( 'permissionEditModal', { static: false } )
    public permissionEditModal!: ModalDirective;

    @Output()
    onPermissionUpdated = new EventEmitter<boolean>();

    // Getters for permissionEditForm
    get subject () {
        return this.permissionEditForm.get( 'subject' );
    }

    get action () {
        return this.permissionEditForm.get( 'action' );
    }

    get description () {
        return this.permissionEditForm.get( 'description' );
    }

    constructor ( private permissionService: PermissionService,
        private common: CommonService,
        private fb: FormBuilder ) { }

    ngOnInit () {

        this.initForm();
    }

    initForm () {
        this.permissionEditForm = this.fb.group( {
            'permission_id': [ '', Validators.required ],
            'subject': [ '', Validators.required ],
            'action': [ '', Validators.required ],
            'description': [ '', Validators.required ],
        } );
    }

    show ( permission: Permission ) {
        this.permission = permission;
        this.error = [];
        this.permissionEditForm.patchValue( this.permission );
        this.permissionEditModal.show();
    }

    hide () {
        this.permissionEditModal.hide();
    }

    updatePermission () {
        this.isSubmitting = true;
        this.permissionService.update( this.permissionEditForm.value.permission_id, this.permissionEditForm.value ).subscribe(
            response => {
                this.common.notifications.success( 'Permission Data Updated Successfully!' );
                this.isSubmitting = false;
                this.hide();
                this.onPermissionUpdated.emit( true );
            },
            error => {
                this.isSubmitting = false;
                this.error = error.errors;
                this.common.notifications.error( error.message );
            } )
    }


}
