import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Licence } from '../../core/models/licence.model';
import { CommonService } from '../../core/services/common.service';
import { LicenceService } from '../../core/services/licence.service';

@Component( {
    selector: 'app-licence-details',
    templateUrl: './licence-details.component.html',
    styleUrls: [ './licence-details.component.css' ],
} )
export class LicenceDetailsComponent {

    licence: Licence = new Licence();
    licence_number!: string;
    dob!: string;

    @ViewChild( 'licenceDetails', { static: false } )
    public licenceDetails!: ModalDirective;

    constructor (
        private licenceService: LicenceService,
        private commonService: CommonService,
    ) { }

    show ( licenceNumber: string, dob: string ) {

        this.licence_number = licenceNumber;
        this.dob = dob;

        this.licenceService.getDetails( licenceNumber ).subscribe(
            response => {
                this.licence = response;
                this.licenceDetails.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );

    }

    hide () {
        this.licenceDetails.hide();
    }

}
