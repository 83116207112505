import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserHistory } from 'src/app/core/models/user-history.model';
import { UserService } from 'src/app/core/services/user.service';

@Component( {
    selector: 'app-user-history',
    templateUrl: './user-history.component.html',
} )
export class UserHistoryComponent implements OnInit {

    userId!: number;
    user!: UserHistory;

    constructor (
        private userService: UserService,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit (): void {

        this.userId = +this.activatedRoute.snapshot.params[ 'id' ];

        this.fetchHistory();
    }

    fetchHistory (): void {

        this.userService.getHistory( this.userId ).subscribe( {

            next: ( response: any ) => {

                this.user = response;

            }
        } );
    }
}
