import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';


@Injectable( {
    providedIn: 'root'
} )
export class PermitService {

    constructor ( private api: ApiService ) {
    }

    filter ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'permit', params );
    }

    resend ( data: Object | undefined ) {
        return this.api.post( 'permit/resend', data );
    }

    resendAll ( source: string, params: Params | HttpParams | undefined ) {
        return this.api.get( 'permit/resendAll?type=' + source, params );
    }

    getDataForExcel ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'permit/download', params );
    }

    getStats ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'permit/stats', params );
    }

    getDetails ( rcNumber: string ) {
        return this.api.get( 'permit/details?rc_number=' + rcNumber );
    }

    getApiResponse ( id: string ) {
        return this.api.get( 'permit/' + id );
    }

    search ( data: Object | undefined ) {
        return this.api.post( 'permit/search', data );
    }

    importExcel ( data: Object | undefined ) {
        return this.api.post( 'permit/import', data );
    }

    getHtmlResponse ( id: string, type: string ): Observable<any> {
        return this.api.get( 'permit/' + id + '/html-response?type=' + type );
    }


}
