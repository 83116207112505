import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import { validateDate } from 'src/app/common/validators/date-validator.directive';
import { validateEmailByDomain } from 'src/app/common/validators/email-validator-by-domain.directive';
import { validateEmail } from 'src/app/common/validators/email-validator.directive';
import { validateUserLevelId } from 'src/app/common/validators/user-level-id-validator.directive';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { DownloadExcelService } from 'src/app/core/services/download-excel.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { UserService } from 'src/app/core/services/user.service';

@Component( {
    selector: 'app-user-upload',
    templateUrl: './user-upload.component.html',
} )
export class UserUploadComponent extends ImportExcelComponent implements OnInit {

    acceptedHeader = [
        'name',
        'email',
        'level_id',
        'designation',
        'dob',
        'mobile_number'
    ];
    validDomain = [ 'instaveritas.com' ]
    uploadForm!: FormGroup;
    lots: any[] = [];
    routes: any[] = [];
    errorMessage: string = "";
    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor (
        protected override fb: FormBuilder,
        public global: GlobalService,
        private commonService: CommonService,
        private userService: UserService,
        private downloadExcelService: DownloadExcelService
    ) {
        super( fb );
    }

    get users () {
        return this.uploadForm.get( 'users' ) as FormArray;
    }

    ngOnInit (): void {
        this.initForm();

    }


    initForm (): void {

        this.uploadForm = this.fb.group( {
            users: this.fb.array( [] ),
        } );
    }

    downloadSampleExcel (): void {
        this.downloadExcelService.downloadUserSampleExcel();
    }

    get isFileDisabled () {
        return this.uploadForm.invalid
    }

    initExcelForm (): void {

        const isValidExcelHeader = this.isValidExcelHeader();

        this.errorMessage = "";

        if ( !isValidExcelHeader ) {

            this.users.clear();

            this.errorMessage = "Excel Header Mismatch"

        }

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );

    }

    isValidExcelHeader () {
        if ( JSON.stringify( this.acceptedHeader ) === JSON.stringify( this.headerRaw ) ) {
            return true;
        }
        return false;
    }

    addUser ( formGroup: AbstractControl<any, any> ) {

        this.users.push( this.fb.group( {
            name: [ formGroup.get( 'name' )?.value, [ Validators.required ] ],
            mobile_number: [ formGroup.get( 'mobile_number' )?.value, [ Validators.required, Validators.pattern( /^(\s*\d{10}\s*,\s*)*\s*\d{10}\s*$/ ) ] ],
            email: [ formGroup.get( 'email' )?.value, [ Validators.required, validateEmail( true ), validateEmailByDomain( true, this.validDomain ) ] ],
            level_id: [ formGroup.get( 'level_id' )?.value, [ Validators.required, validateUserLevelId( true ) ] ],
            designation: [ formGroup.get( 'designation' )?.value, [ Validators.required ] ],
            dob: [ formGroup.get( 'dob' )?.value, [ Validators.required, validateDate( 'yyyy-mm-dd' ) ] ]
        } ) );

    }

    applyValidations (): void {
        this.users.clear();

        this.excelFormArray.controls.forEach( formGroup => {

            this.addUser( formGroup );

        } );
    }

    setValidationMessages (): void {
    }

    onSubmit () {
        this.isSubmitting = true;

        this.userService.bulkCreate( this.uploadForm.value ).subscribe( {
            next: ( response: ApiResponse ) => {
                this.commonService.notifications.success( response.message );
                this.isSubmitting = false;
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( error );
            }
        } );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
