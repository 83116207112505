import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../core/services/user.service';
import { User } from '../core/models/user.model';

@Component( {
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
} )


export class SidebarComponent {
    @Input() user: User;
    constructor ( private router: Router, private userService: UserService ) {

        this.user = this.userService.getCurrentUser();
    }


}
