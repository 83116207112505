import { Component, Input } from '@angular/core';
import { EmailLog } from 'src/app/core/models/email-log';
import { EmailLogStatusTimeLineModalInitialState } from 'src/app/core/models/email-log-status-time-line-modal-initial-state';
import { EmailLogService } from 'src/app/core/services/email-log.service';

@Component( {
    selector: 'app-digital-address-invite-email-list-item',
    templateUrl: './digital-address-invite-email-list-item.component.html',
} )
export class DigitalAddressInviteEmailListItemComponent {
    @Input() lastEmail!: EmailLog;

    get deliveryStatusLabel (): string {
        return this.emailLogService.getStatusLabel( this.lastEmail.delivery_status )
    }

    get statusColor (): string {
        return this.emailLogService.getStatusColor( this.lastEmail.delivery_status )
    }

    constructor ( private emailLogService: EmailLogService, ) { }

    showEmailLogStatusTimeLineModal (): void {

        const initialState: EmailLogStatusTimeLineModalInitialState = {
            statusLogs: this.lastEmail.status
        }

        this.emailLogService.showEmailStatusTimeLineModal( initialState );
    }
}
