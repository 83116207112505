<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Face Recognotion Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-face-recognition-add-images (addImageStatus)='ImageAdded($event)' #addImage></app-face-recognition-add-images>
<app-loading-component [fetchingInProgress]="fetchingInProgress"></app-loading-component>
<app-face-recognition-stats></app-face-recognition-stats>
<app-face-recognition-fiters [filterParams]="filterParams"></app-face-recognition-fiters>

<div class="row" style="padding: 0px 0px 0px 35px">
    <a class="btn btn-primary btn-addon" (click)="addImage.show();">
        <i class="fa fa-retweet" aria-hidden="true"></i>
        Add Image
    </a>
    <a class="btn btn-primary btn-addon" (click)="exportLogsExcel()">
        <i class="fa fa-download"></i>
        Download Logs
    </a>
    <a class="btn btn-primary btn-addon" [routerLink]="['import']">
        Import Excel
    </a>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 20%">Details</th>
                        <th style="width: 20%">Signzy</th>
                        <th style="width: 10%">Image1</th>
                        <th style="width: 10%">Image2</th>
                        <th style="width: 20%">Timeline</th>
                        <th style="width: 20%">Api Response</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
          let log of pagination.data
            | paginate
              : {
                  id: 'server',
                  itemsPerPage: pagination.per_page,
                  currentPage: pagination.current_page,
                  totalItems: pagination.total
                }">
                        <td>
                            <ul class="list-unstyled">
                                <li>{{ log.id }}</li>
                                <ul class="list-unstyled">
                                    <li *ngIf="log.client_name">{{ log.client_name }}</li>
                                </ul>
                            </ul>
                        </td>
                        <td>
                            <div *ngIf="log.status == 'success';">
                                <a class="btn btn-block label-success">Found</a>
                            </div>

                            <div *ngIf="log.status == 'has-details'">
                                <a class="btn btn-block label-danger">Not Found</a>
                            </div>

                            <div *ngIf="log.status == 'not-initiated'">
                                <a class="btn btn-block label-warning">Not Initialted</a>
                            </div>

                            <div *ngIf="log.status == 'failed'">
                                <a class="btn btn-block label-danger">Failure</a>
                            </div>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li> <a (click)="showFirstImage(log.first_image_url)">Show First Image</a> </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li> <a (click)="showSecondImage(log.second_image_url)">Show Second Image</a> </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Received At:- {{ log.created_at }}</li>
                                <li>Last Updated:- {{ log.updated_at }}</li>
                            </ul>
                        </td>

                        <td>
                            <ul *ngIf="log.response" class="list-unstyled">
                                <li> {{ log.response | json }}
                                </li>
                            </ul>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>