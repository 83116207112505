import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { InviteService } from 'src/app/core/services/invite.service';

@Component( {
    selector: 'app-invite-follow-up-modal',
    templateUrl: './invite-follow-up-modal.component.html',
} )
export class InviteFollowUpModalComponent implements OnInit {

    followUpDetails: any;
    name: any;

    @ViewChild( 'followUpDetailsModal', { static: false } )
    public followUpDetailsModal!: ModalDirective;

    constructor ( private inviteService: InviteService ) { }

    ngOnInit () {
    }

    show ( id: string, name: any ) {
        this.inviteService.getFollowUpDetails( id ).subscribe(
            response => {
                this.followUpDetails = response;
                this.followUpDetailsModal.show();
            }
        );
        this.name = name;
    }

    hide () {
        this.followUpDetailsModal.hide();
    }

}
