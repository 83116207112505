import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { VerificationList } from '../../core/components/verification-list.component';
import { ReferenceVerification } from '../../core/models/reference-verification.model';
import { CommonService } from '../../core/services/common.service';
import { ReferenceService } from '../../core/services/reference.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { Attachment } from 'src/app/core/models/attachment.model';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-reference-list',
    templateUrl: './reference-list.component.html',
    styleUrls: [ './reference-list.component.css' ],
} )
export class ReferenceListComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications: ReferenceVerification[] = [];
    isSubmitting = false;
    attachments: Attachment[] | any = [];
    crmUrl!: string;
    statsVisible = false;
    downloading: boolean = true;

    constructor (
        protected override verificationService: ReferenceService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        private bsModalService: BsModalService,
        public override confirmationModalService: ConfirmationModalService

    ) {
        super( confirmationModalService );
    }

    get users () {
        return this.global.fetchUsers();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit () {
        this.crmUrl = environment.crm_url;
        this.verification = 'reference';
        this.subscribeToQueryChanges();
        this.getVendors();


    }

    filterResults (): void {
        this.verificationService.filter( this.filterParams ).subscribe( ( response: any ) => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    showReference ( reference: ReferenceVerification ): void {
        this.router.navigate( [ 'reference', reference.employee_id, 'edit' ], this.navigationExtras );
    }

    populateExtraDetails ( reference_verification: any ): void {

        for ( const index in reference_verification ) {

            const verification = reference_verification[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    getVendors (): void {

        this.commonService.getVendors( 'address' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
    }

    showStats (): void {
        this.statsVisible = !this.statsVisible;
    }

    override exportExcel (): void {

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/' + this.verification + '/downloadExcel',
            fileName: this.verification,
            method: 'get',
            postParams: null,
        };

        this.bsModalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )
    }

    get reportLink (): string {
        return environment.api_url + 'reference/letter?id=' + this.ids;
    }

    get envelopeLink (): string {
        return environment.api_url + 'reference/envelopes?id=' + this.ids;
    }

}
