import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { ModalService } from './modal.service';

@Injectable( {
    providedIn: 'root'
} )
export class LicenceService {

    constructor (
        private api: ApiService,
        private modalService: ModalService
    ) {
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'licence', params );
    }

    getHtmlResponse ( id: string, type: string ): Observable<any> {
        return this.api.get( 'licence/' + id + '/html-response?type=' + type );
    }

    getResponse ( id: string ) {
        return this.api.get( 'licence/' + id + '/response' );
    }

    getDetails ( licenceNumber: any ) {
        return this.api.get( 'licence/get-details?licence=' + licenceNumber );
    }

    search ( values: Object | undefined ): Observable<any[]> {
        return this.api.post( 'licence/search', values );
    }

    resendAll ( source: string, filterParam: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'licence/resend-all?type=' + source, filterParam );
    }

    importExcel ( values: Object ): Observable<any> {
        return this.api.post( 'licence/import-excel', values );
    }

    getStats ( filterParams: HttpParams ) {
        return this.api.get( 'licence/stats', filterParams );
    }

    getDataForExcel ( params: string | HttpParams ): Observable<Pagination> {
        return this.api.get( 'licence/export-excel?' + params );
    }

    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }
}
