<app-edit-vendor-modal #editModal></app-edit-vendor-modal>
<div class="wrapper-md">
    <div class="row">
        <div class="col-md-offset-1 col-md-3">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Edit Vendor
                </div>

                <form [formGroup]="addVendorFormGroup">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label class="control-label">All Vendors</label>
                                <select class="form-control" [(ngModel)]="selectedVendor"
                                    [ngModelOptions]="{standalone: true}">
                                    <option *ngFor="let vendor of vendors" value="{{vendor.id}}">
                                        {{vendor.company_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="panel-footer" [appCan]="'superadmin'">
                        <div class="row">
                            <div class="col-md-offset-6 col-md-6 text-right">
                                <button class="btn btn-primary" [disabled]="!selectedVendor"
                                    (click)="editModal.show(selectedVendor)">
                                    Edit Vendor
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>


        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Add New Vendor
                </div>

                <form [formGroup]="addVendorFormGroup">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class="center-block">Vendor Name</label>
                                <input formControlName="company_name" class="form-control">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Type</label>
                                <app-multi-select [options]="options" [placeholder]="'Select Options'"
                                    [controlName]="'type'" [formGName]="addVendorFormGroup"></app-multi-select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block">Places Covered</label>
                                <input formControlName="places_covered" class="form-control">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block">Contact Person</label>
                                <input formControlName="contact_person" class="form-control">
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="center-block">Contact Number</label>
                                <input formControlName="contact_number" class="form-control">
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="center-block">Contact Email</label>
                                <input formControlName="contact_email" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="panel-footer">
                        <div class="row">


                            <div class="col-md-offset-8 col-md-4 text-right">
                                <button class="btn btn-primary" (click)="submit()"
                                    [disabled]="!addVendorFormGroup.valid">
                                    Create Vendor
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>