<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Address Work Assignments</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Assigned After</label>
                        <input class="form-control" type="date" formControlName="created_after">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Assigned Before</label>
                        <input class="form-control" type="date" formControlName="created_before">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="row">
        <div class="col-sm-3" *ngFor="let work of assignedWorkList; let i = index">
            <div class="panel panel-default">
                <div class="panel-heading">
                    {{work['assigned_to']}}
                </div>
                <ul class="list-group no-radius">
                    <li class="list-group-item">Total Assigned : {{work['total']}}</li>
                    <li class="list-group-item">Completed : {{work['completed']}}</li>
                    <li class="list-group-item">Pending : {{work['pending']}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>