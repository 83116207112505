export class Authority {
    id!: number;
    district_id!: number;
    name!: string;
    address!: string;
    district_name!: string;
    state_name!: string;
    state_id!: number;
    pincode!: string;
    remarks!: string;
    contacts!: any[];
}
