<div bsModal #autoVerifyModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">AutoVerify</h4><br>
                <h5 class="text-thin">Select ID Verification and Source</h5>
            </div>
            <div class="panel-body">
                <div class="row" *ngFor="let type of identityVerifications">
                    <ng-container *ngIf="!type.dataType">
                        <div class="col-md-12">
                            <fieldset class="form-group">
                                <label class="center-block">{{type.name}} source
                                    <select class="form-control" [value]="selectedSources[type.id]"
                                        (change)="onSourceSelect($event, type.id)">
                                        <option value>None</option>
                                        <option *ngFor="let source of type.sources" [value]="source">
                                            {{source | uppercase}}
                                        </option>
                                    </select>
                                </label>
                            </fieldset>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="type.dataType">
                        <fieldset class="form-group">
                            <div class="col-md-6">
                                <label class="center-block">{{type.name}} source
                                    <select class="form-control" [value]="selectedSources[type.id]"
                                        (change)="onSourceSelect($event, type.id)">
                                        <option value>None</option>
                                        <option *ngFor="let source of type.sources" [value]="source">
                                            {{source | uppercase}}
                                        </option>
                                    </select>
                                </label>
                            </div>
                            <div class="col-md-6">
                                <label class="center-block">{{type.name}} Data Type
                                    <select class="form-control" [value]="selectedDataType[type.id]"
                                        (change)="onDataTypeSelect($event, type.id)">
                                        <option value>None</option>
                                        <option *ngFor="let dataType of type.dataType" [value]="dataType">
                                            {{dataType | uppercase}}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </fieldset>
                    </ng-container>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default" (click)="autoVerify()">Verify</button>
                    </div>
                    <div class="pull-left">
                        <button class="btn btn-default" (click)="hide()"> Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>