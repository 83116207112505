import { Injectable } from '@angular/core';

@Injectable( {
    providedIn: 'root'
} )
export class JwtService {
    getToken (): string | null {
        return window.localStorage.getItem( 'token' )
    }

    saveToken ( token: string ) {
        window.localStorage.setItem( 'token', token );
    }

    destroyToken () {
        window.localStorage.clear();
    }

    getCurrentUser () {

        const payload = localStorage.getItem( 'token' );

        if ( payload ) return JSON.parse( atob( payload.split( '.' )[ 1 ] ) );

        return;

    }

}
