<div [formGroup]="formGroup">
    <label id="label" class="center-block">{{labelName}}</label>

    <input [typeahead]="authorities|filterByDistrictId:districtId" [formControlName]="controlName"
        [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select {{labelName}}"
        [typeaheadItemTemplate]="customItemTemplate" (typeaheadOnSelect)="onSelect($event)"
        (typeaheadNoResults)="onNoResults()" class="form-control" autocomplete="off" [id]="controlName">

    <small class="text-danger" *ngIf="authorityId?.errors?.['required'] || authorityName?.errors?.['required']">
        Required
    </small>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>