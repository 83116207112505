import { AbstractControl, FormControl, Validators } from '@angular/forms';

export const validateEmailsWithComma = ( control: AbstractControl ): { [ key: string ]: any } | null => {

    const emails: string[] = control.value.split( ',' ).map( ( email: string ) => email.trim() );

    const failed = emails.some( ( email: any ) => Validators.email( new FormControl( email ) ) );

    return failed ? { 'extraEmails': { value: control.value } } : null;

};
