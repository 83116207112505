import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DownloadService } from 'src/app/core/services/download.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-pcc-challan-excel',
    templateUrl: './pcc-challan.component.html',
} )

export class PccChallanComponent extends ImportExcelComponent implements OnInit {

    sources: any;
    actionUrl!: string;
    token!: string;
    uploadForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor (
        protected override fb: FormBuilder,
        private downloadService: DownloadService,
        protected commonService: CommonService,
    ) { super( fb ); }

    ngOnInit (): void {

        this.sources = { 'up': 'Uttar Pradesh', 'rajasthan': 'Rajasthan' };

        this.actionUrl = `${ environment.api_url }${ 'pcc/challan/download' }`;

        this.uploadForm = this.fb.group( {
            via: [ '', [ Validators.required ] ],
            excel_data: this.fb.array( [] ),
        } );

    }

    get excelData (): FormArray {
        return this.uploadForm.get( 'excel_data' ) as FormArray;
    }

    addExcelData ( formGroup: FormGroup | any ): void {

        this.excelData.push( this.fb.group( {
            address: [ formGroup.get( 'address' )?.value ],
            age: [ formGroup.get( 'age' )?.value ],
            ancestor_address: [ formGroup.get( 'ancestor_address' )?.value ],
            caste: [ formGroup.get( 'caste' )?.value ],
            character_certificate_purpose: [ formGroup.get( 'character_certificate_purpose' )?.value ],
            current_address: [ formGroup.get( 'current_address' )?.value ],
            date: [ formGroup.get( 'date' )?.value ],
            district: [ formGroup.get( 'district' ).value ],
            duration_of_stay_from: [ formGroup.get( 'duration_of_stay_from' ).value ],
            duration_of_stay_to: [ formGroup.get( 'duration_of_stay_to' ).value ],
            dwelling_place: [ formGroup.get( 'dwelling_place' ).value ],
            father_name: [ formGroup.get( 'fathers_name' ).value ],
            identity_document: [ formGroup.get( 'identity_document' ).value ],
            mobile_number: [ formGroup.get( 'mobile_number' ).value ],
            name: [ formGroup.get( 'name' ).value, [ Validators.required ] ],
            permanent_address: [ formGroup.get( 'permanent_address' ).value ],
            phone_number: [ formGroup.get( 'phone_number' ).value ],
            police_station: [ formGroup.get( 'police_station' ).value ],
            state: [ formGroup.get( 'state' ).value ],
            track_id: [ formGroup.get( 'track_id' ).value, [ Validators.required ] ],
        } ) );
    }

    applyValidations (): void {
        this.excelData.clear();
        this.excelFormArray.controls.forEach( formGroup => {
            this.addExcelData( formGroup );
        } );
    }

    initExcelForm (): void {
        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void { }

    onSubmit (): void {
        const fileType = 'zip';

        const documentName = 'Pcc Challan';

        this.downloadService.downloadFile( this.actionUrl, fileType, this.uploadForm.value ).subscribe(
            ( blob: Blob ) => {
                this.downloadService.getFile( blob, documentName );
            },
            ( error ) => {
                this.commonService.notifications.error( 'Some Error Occured', error );
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
