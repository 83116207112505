import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { Webhook } from '../models/webhook.model';
import { HttpParams } from '@angular/common/http';
import { WebhookSubUrl } from '../models/webhook-sub-url.model';
import { WebhookLogPayload } from '../models/webhook-log-payload.model';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class WebhookService {

    constructor ( private api: ApiService ) {
    }

    create ( values: Object ): Observable<any[]> {
        return this.api.post( 'webhooks', values );
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'webhooks', params );
    }

    update ( id: string | number, data: Object | undefined ): Observable<Webhook> {
        return this.api.put( 'webhooks/' + id, data );
    }

    logs ( id: number, params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'webhooks/' + id, params );
    }

    updateSettings ( id: number, value: any ): Observable<Webhook> {
        return this.api.put( 'webhooks/' + id + '/settings', value );
    }

    incominglogs ( filterParams: HttpParams ): Observable<Pagination> {
        return this.api.get( 'incoming-webhooks/', filterParams );
    }

    createSubUrl ( values: Object ): Observable<any[]> {
        return this.api.post( 'webhooks/sub-urls', values );
    }

    getSubUrlsByWebhookId ( webhhookId: number ): Observable<WebhookSubUrl[]> {
        return this.api.get( 'webhooks/' + webhhookId + '/sub-urls' );
    }

    getPayload ( id: number ): Observable<WebhookLogPayload> {
        return this.api.get( 'webhooks/log/' + id + '/payload' );
    }

    getSettingsByWebhookId ( id: number ): Observable<any[]> {
        return this.api.get( 'webhooks/' + id + '/events' );
    }

    retrySendWebhook ( id: number ): Observable<any[]> {
        return this.api.get( 'webhooks/log/' + id + '/retry' );
    }

    retryAllWebhooks ( id: number, data: any ): Observable<any[]> {
        return this.api.get( 'webhooks/' + id + '/retry-all', data );
    }
}
