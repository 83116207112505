<div class="wrapper-md">

    <div class="panel panel-default">

        <div class="panel-body">

            <div class="row">

                <div class="col-md-2">

                    <div class="file-uploader">
                        <label for="file-input">Select your files</label>
                        <input id="file-input" [accept]="acceptFileOnly" multiple="true" (change)="uploadFile($event)"
                            type="file" class="form-control" />

                    </div>

                </div>

            </div>

            <div class="row margin-top-4" *ngIf="documentFormArray.length>0">

                <div class="col-md-2">

                    <span class='text file-count'>(<span class='text file-count'>{{documentFormArray.length}}</span>
                        Images found)</span>

                </div>

            </div>

            <div class="row margin-top-50"></div>

            <div class="row margin-top-4 margin-left-right-5" *ngIf="documentFormArray.length>0">

                <form [formGroup]="documentFormGroup">

                    <div id="resp-table">

                        <div id="resp-table-body">

                            <div class="row resp-table-row">

                                <div class="col-md-1 table-body-cell">Pdf/Page</div>

                                <div class="col-md-2 table-body-cell">Image</div>

                                <div class="col-md-3 table-body-cell">Verification Type</div>

                                <div class="col-md-2 table-body-cell">Verification ID</div>

                                <div class="col-md-2 table-body-cell">Document</div>

                                <div class="col-md-2 table-body-cell">Action</div>

                            </div>

                            <div app-candidate-document-item
                                *ngFor="let document of documentFormArray?.controls;let i=index;" [index]="i"
                                [employee]="employee" [documentFormGroup]="document"
                                [documentFormArray]="documentFormArray" (onActionEmitter)="onActionClick($event)">

                            </div>

                        </div>

                    </div>

                </form>

            </div>

            <div class="row margin-top-4" *ngFor="let pdfSrc of pdfFileSrc;let index = index">

                <pdf-viewer [src]="pdfSrc" [render-text]="false" [original-size]="false"
                    style="width: 1px; height: 1px;" (after-load-complete)="onLoadCompleted($event,index)">

                </pdf-viewer>

            </div>
        </div>

    </div>

    <document-action-modal *ngIf="employee" #documentActionModal [documentAction]="documentAction"
        [employee]="employee"></document-action-modal>
    `
</div>