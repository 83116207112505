<div [formGroup]="formGroup">
    <label id="label" class="center-block">{{labelName}}</label>
    <input [formControlName]="controlName" [typeahead]="options" [typeaheadMinLength]="0" typeaheadOptionField="name"
        placeholder="Select {{labelName}}" [typeaheadItemTemplate]="customItemTemplate" class="form-control"
        (typeaheadOnSelect)="onSelect($event)" (typeaheadNoResults)="onNoResults()" autocomplete="off"
        [id]="controlName">
    <small class="text-danger" *ngIf="countryId?.errors?.['required'] || countryName?.errors?.['required']">
        Required
    </small>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>