import { Component, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthorityContact } from '../../core/models/authority-contact.model';
import { AuthorityService } from '../../core/services/authority.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-authority-edit-contact',
    templateUrl: './authority-edit-contact.component.html',
    styleUrls: [ './authority-edit-contact.component.css' ],
} )
export class AuthorityEditContactComponent implements OnInit {

    contact: AuthorityContact = new AuthorityContact();
    editForm!: FormGroup;
    isSubmitting: boolean = false;

    @ViewChild( 'authorityContactEditModal', { static: false } )
    public authorityContactEditModal!: ModalDirective;

    @Output()
    onContactEdited: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private authorityService: AuthorityService,
        private fb: FormBuilder,
        private commonService: CommonService
    ) { }


    ngOnInit (): void {

        this.initForm();
    }


    initForm (): void {
        this.editForm = this.fb.group( {
            id: [],
            name: [ '' ],
            designation: [ '' ],
            email: [ '' ],
            phone_number: [ '' ],
            remarks: [ '' ],
        } );
    }


    show ( contact: AuthorityContact ): void {

        this.contact = contact;

        this.editForm.patchValue( this.contact );

        this.authorityContactEditModal.show();
    }

    hide (): void {

        this.authorityContactEditModal.hide();
    }

    updateContact (): void {

        this.isSubmitting = true;

        this.authorityService.updateAuthorityContact( this.editForm.value.id, this.editForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Authority Contact Updated' );
                this.hide();
                this.onContactEdited.emit( true );
            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to edit Authority' );
                this.hide();
            }
        );
    }


}
