import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BlacklistedService } from 'src/app/core/services/blacklisted.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component( {
    selector: 'app-blacklisted-details',
    templateUrl: './blacklisted-details.component.html',
    styleUrls: [ './blacklisted-details.component.css' ],
} )
export class BlacklistedDetailsComponent {

    vehiclerc: any;
    rcNumber!: string;

    @ViewChild( 'blacklistedDetails', { static: false } )
    public vehicleDetails!: ModalDirective;

    constructor ( private blacklistedService: BlacklistedService,
        private commonService: CommonService ) {
    }

    show ( rcNumber: string ): void {

        this.rcNumber = rcNumber;

        this.blacklistedService.getDetails( rcNumber ).subscribe(
            response => {
                this.vehiclerc = response;
                this.vehicleDetails.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );

    }

    hide (): void {
        this.vehicleDetails.hide();
    }

}
