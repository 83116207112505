import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { WorkAssignedListComponent } from '../../core/components/assigned-work-list.component';
import { EducationService } from '../../core/services/education.service';
import { FilterService } from './../../core/services/filter.service';

@Component( {
    selector: 'app-education-assigned-work-list',
    templateUrl: './education-assigned-work-list.component.html',
    styleUrls: [ './education-assigned-work-list.component.css' ],
} )
export class EducationAssignedWorkListComponent extends WorkAssignedListComponent implements OnInit, OnDestroy {

    // Form group associated with the filters
    override filterFormGroup!: FormGroup;
    override filterParams: HttpParams = new HttpParams();
    override assignedWorkList!: string[] | any;
    filtersSubscription!: Subscription;

    constructor (
        protected verificationService: EducationService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        // public override global: SimpleGlobal,
        protected override fb: FormBuilder,
        protected override filterService: FilterService
    ) {
        super();
        this.initForm();
    }

    ngOnInit (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );

        this.filterResults( this.filterFormGroup.value );
    }

    // Initialize the filters.
    initForm (): void {

        this.filterFormGroup = this.fb.group( {
            created_after: this.getPreviousMonthDate(),
            created_before: '',
            verification_type: 'education',
            track_id: '',
        } );

    }


    filterResults ( params: Params ): void {
        this.verificationService.getAssignedWorkList( params ).subscribe( response => {
            this.assignedWorkList = response;
        } );
    }


    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }

}
