import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-district-typeahead',
    templateUrl: './district-typeahead.component.html',
    styleUrls: [ './district-typeahead.component.css' ],
} )
export class DistrictTypeaheadComponent implements OnInit {

    @Input() control: string = 'district'; // to ask :  why we are taken as input from pareant
    @Input() stateId!: number;
    @Input() formGroup!: FormGroup;
    @Input() labelName: string = 'District';

    @Output() changed: EventEmitter<number> = new EventEmitter<number>();

    constructor (
        public global: GlobalService,
        public common: CommonService,

    ) {

    }

    get controlId (): string { return this.control + '_id'; }

    get controlName (): string { return this.control + '_name'; }

    get districtId (): FormControl { return this.formGroup.get( this.controlId ) as FormControl }

    get districtName (): FormControl { return this.formGroup.get( this.controlName ) as FormControl }

    get districtIdValue (): number { return this.formGroup.get( this.controlId )?.value }

    get districtNameValue (): string { return this.formGroup.get( this.controlName )?.value }

    get districts () { return this.global.fetchDistricts(); }


    ngOnInit (): void {

    }

    onSelect ( $event: TypeaheadMatch ): void {

        this.formGroup.get( this.controlId )?.setValue( $event.item.id );
        this.formGroup.get( this.controlName )?.setValue( $event.item.name );

        this.changed.emit( this.districtIdValue );
    }

    onNoResults (): void {
        if ( this.districtName.value == '' || this.districtName.value == ' ' ) {

            this.formGroup.get( this.controlId )?.setValue( null );
            this.formGroup.get( this.controlName )?.setValue( null );

            this.changed.emit( this.districtIdValue );
        }
    }
}
