import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';

import { isUndefined } from '../../core/unit';
import { EducationVerification } from '../../core/models/education-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EducationService } from '../../core/services/education.service';
import { fileTypes } from './../../core/enums/file-types.enum';
import { UploadService } from './../../core/services/upload.service';
import { EducationVerificationStatusType } from '../../core/enums/education-verification-status-types.enum';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { User } from '@sentry/browser';
import { validateLength } from 'src/app/common/validators/length-validator.directive';
import { validateNumericCharacters } from 'src/app/common/validators/numeric-validator.directive';
import { UserService } from 'src/app/core/services/user.service';
import { GlobalService } from 'src/app/core/services/global.service';
import Status from 'src/app/core/models/status.model';

@Component( {
    selector: 'app-education-common-edit',
    templateUrl: './education-common-edit.component.html',
    styleUrls: [ './education-common-edit.component.css' ],
} )
export class EducationCommonEditComponent implements OnInit {

    @Input() verification!: EducationVerification | any;
    @Input() isSubmitting!: boolean | any;
    @Input() verificationFormGroup!: FormGroup;
    @Input() formArray!: FormArray;
    @Input() editFormGroup!: FormGroup | any;
    @Input() formGName!: FormGroup | any;
    @Input() vendors!: User[];
    @Input() current_date!: string;
    @Input() created_at!: string;

    uploader: FileUploader = new FileUploader( { url: '' } );
    statusChanged: boolean = false;
    attachments: any;

    @Output()
    onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
    maxFileError!: boolean;

    get statuses () { return this.global.fetchStatuses(); }

    get methodology () { return this.global.fettchMethodology(); }

    get countries () { return this.global.fetchCountries(); }

    // form getters
    get statusId (): number { return this.verificationForm.get( 'status_id' )?.value; }

    get passingYear (): AbstractControl | null { return this.verificationForm.get( 'passing_year' ); }

    // get clientRemarks () { return this.verificationForm.get( 'client_remark' ); }

    get verificationForm (): FormGroup {

        return this.editFormGroup;

    }

    get employeeId (): AbstractControl | null { return this.verificationForm.get( 'employee_id' ); }

    get enrollementNumber (): AbstractControl | null { return this.verificationForm.get( 'enrollement_number' ); }

    get courseName (): AbstractControl | null { return this.editFormGroup.get( 'course_name' ); }

    get collegeName (): AbstractControl | null { return this.editFormGroup.get( 'college_name' ); }

    get indiaPostId (): AbstractControl | null { return this.editFormGroup.get( 'india_post_id' ); }

    get sentToVendorOn (): AbstractControl | null { return this.editFormGroup.get( 'sent_to_vendor_on' ); }

    get completedAt (): AbstractControl | null { return this.editFormGroup.get( 'completed_at' ); }

    get discipline (): AbstractControl | null { return this.editFormGroup.get( 'discipline' ); }

    get insuffRaisedAt (): AbstractControl | null { return this.editFormGroup.get( 'insuff_raised_at' ); }

    get insuffClearedAt (): AbstractControl | null { return this.editFormGroup.get( 'insuff_cleared_at' ); }

    // validations

    get clientRemarksValidation (): boolean {
        // if ( !this.statusId ) return true;

        return this.statusId === EducationVerificationStatusType.DocumentRequired ||
            this.statusId === EducationVerificationStatusType.OnHold ||
            this.statusId === EducationVerificationStatusType.VerificationStopped ||
            this.statusId === EducationVerificationStatusType.NotApplicable;
    }

    get completedAtValidation (): boolean {

        return this.statusId === EducationVerificationStatusType.DiscrepancyFound ||
            this.statusId === EducationVerificationStatusType.Verified ||
            this.statusId === EducationVerificationStatusType.Failure;
    }

    get sentOn (): boolean {
        return this.statusId === EducationVerificationStatusType.SentToVendor;
    }
    constructor (
        protected route: ActivatedRoute,
        protected router: Router,
        private educationService: EducationService,
        public global: GlobalService,
        protected el: ElementRef,
        private commonService: CommonService,
        private uploadService: UploadService,
        private user: UserService,
    ) {
        // super( commonService );
    }

    ngOnInit (): void {

        this.setFormPermissions();

        this.applyDefaults();
        if ( !isUndefined( this.verificationFormGroup ) ) {
            this.attachments = this.editFormGroup.get( 'attachments' )?.value;
        }

    }


    onStatuschanged ( $event: TypeaheadMatch ): void {

        this.editFormGroup.get( 'status_id' )?.setValue( $event.item.id );

    }

    onCountryChanged ( $event: TypeaheadMatch ): void {

        this.editFormGroup.get( 'country_id' )?.setValue( $event.item.id );

    }

    onVendorChanged ( $event: TypeaheadMatch ): void {

        this.editFormGroup.get( 'vendor_id' )?.setValue( $event.item.id );

    }


    setFormPermissions (): void {

        if ( !this.completedAt?.value ) return;

        if ( this.user.isAdmin ) {

            this.editFormGroup.enable();

        } else {

            this.editFormGroup.disable();

        }
    }

    upload ( typeOfFile: any ): void {

        const verificationId: number = this.editFormGroup.get( 'id' )?.value;

        // Locate the file element meant for the file upload.
        const inputEl: HTMLInputElement | any = this.el.nativeElement.querySelector( '#' + typeOfFile + '_' + verificationId );

        // Get the total amount of files attached to the file input.
        const fileCount: number = inputEl.files.length;

        this.uploadService.hasExceededFileSize( inputEl );

        const formData: FormData = this.uploadService.addFilesToForm( verificationId, typeOfFile, inputEl );

        if ( !this.uploadService.maxFileError ) {

            // Create a new form data instance
            // Check if the file count is greater than zero, to be sure a file was selected.
            if ( fileCount > 0 ) { // a file was selected

                this.educationService.upload( verificationId, formData ).subscribe( () => {

                    this.getEducationVerification( verificationId );

                    this.onVerificationUpdated.emit( true );

                    this.commonService.notifications.success( 'File uploaded Successfully' );

                }, () => {

                    this.commonService.notifications.error( 'Unable to upload File' );

                } );
            }
        }

        this.maxFileError = false;
    }


    getEducationVerification ( verificationId: number ): void {

        this.educationService.findByEmployeeId( this.employeeId?.value ).subscribe( ( employee: any ) => {

            employee.education_verifications.filter( ( item: any ) => {

                if ( item.id === verificationId ) {

                    this.verification = item;

                    if ( !isUndefined( this.verificationFormGroup ) ) {
                        this.getAttachments( item );
                    }
                }

            } );

        }, );
    }


    getAttachments ( verification: EducationVerification ): void {

        this.attachments = ( verification.attachments );

    }
    applyDefaults (): void {

        this.applyDefaultValidations( this.verificationForm );

    }


    /**
     * Set Default Validations to be applied on Page Load
     *
     * @param {AbstractControl} form
     */
    private applyDefaultValidations ( form: AbstractControl ): void {

        form.get( 'enrollment_number' )?.setValidators( [ Validators.required ] );
        form.get( 'enrollment_number' )?.updateValueAndValidity();
        form.get( 'passing_year' )?.setValidators( [ Validators.required, validateNumericCharacters( '' ), validateLength( '4' ) ] );
        form.get( 'passing_year' )?.updateValueAndValidity();
        form.get( 'course_name' )?.setValidators( [ Validators.required ] );
        form.get( 'course_name' )?.updateValueAndValidity();
        form.get( 'college_name' )?.setValidators( [ Validators.required ] );
        form.get( 'college_name' )?.updateValueAndValidity();
        form.get( 'india_post_id' )?.setValidators( [ validateLength( '13' ) ] );
        form.get( 'india_post_id' )?.updateValueAndValidity();
    }
}
