<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Sms Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-sms-stats></app-sms-stats>
<app-sms-filters [filterParams]="filterParams"></app-sms-filters>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 15%">Details</th>
                        <th style="width: 10%">Status</th>
                        <th style="width: 40%">Response</th>
                        <th style="width: 12%">Actions</th>
                        <th style="width: 23%">TimeLine</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong>{{log.rc_number}}</strong>
                                </li>
                                <li>
                                    # {{log.id}}
                                </li>

                                <li>
                                    {{log.client_name}}
                                </li>
                                <li>
                                    {{log.via}}
                                </li>
                                <li>
                                    Success: <i class="{{log.is_success}}"></i> &nbsp;
                                    Details <i class="{{log.has_details}}"></i>
                                </li>
                            </ul>
                        </td>

                        <td>
                            <a class="btn sms-btn-{{log.status_color}} btn-block">{{log.status}}</a>
                        </td>
                        <td>
                            {{log.response}}
                        </td>
                        <td>
                            <a class="btn btn-primary btn-addon" (click)="resend(log)">
                                <i class="fa fa-refresh"></i>
                                Resend
                            </a>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Created At: {{log.created_at}}</li>
                                <li>Completed At: {{log.completed_at}}</li>
                            </ul>
                            <h4>{{log.tat}} Seconds</h4>
                        </td>
                        <td>

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>