import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { EpfoSources } from 'src/app/core/enums/epfo-sources.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { EpfoService } from 'src/app/core/services/epfo.service';

@Component( {
    selector: 'app-epfo-search-modal',
    templateUrl: './epfo-search-modal.component.html',
    styleUrls: [ './epfo-search-modal.component.css' ]
} )
export class EpfoSearchModalComponent {

    @ViewChild( 'epfoSearchModal', { static: false } )
    public epfoSearchModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();


    isSubmitting = false;
    epfoSearchForm!: FormGroup;
    epfoSources = EpfoSources;

    constructor (
        private fb: FormBuilder,
        private commonService: CommonService,
        private efoService: EpfoService
    ) {

        this.initForm();
    }

    get mobile_number () {
        return this.epfoSearchForm.get( 'mobile_number' ) as FormControl;
    }
    initForm (): void {
        this.epfoSearchForm = this.fb.group( {
            mobile_number: [ '', [ Validators.required, Validators.pattern( '^((\\+91-?)|0)?[0-9]{10}$' ) ] ],
            employer_name: [ '' ],
            employee_name: [ '' ],
            driver: [ '', [ Validators.required ] ],
        } );
    }

    show (): void {
        this.epfoSearchModal.show();
    }

    hide (): void {
        this.initForm();
        this.epfoSearchModal.hide();
    }

    search (): void {

        this.isSubmitting = true;
        this.efoService.search( this.epfoSearchForm.value ).subscribe( () => {
            this.commonService.notifications.success( 'Request Sent Successfully' );
            this.isSubmitting = false;
            this.hide();
            this.onSearch.emit( true );
        }, ( error ) => {
            this.commonService.displayValidationErrors( error );
            this.isSubmitting = false;
            this.hide();
        } );
    }
}
