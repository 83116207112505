export class Lot {
    id!: number;
    name!: string;
    status!: string;
    cities!: string;
    users!: string;
    identity_verification!: boolean;
    current_address_verification!: boolean;
    permanent_address_verification!: boolean;
    icr_verification!: boolean;
    education_verification!: boolean;
    employment_verification!: boolean;
    pcc_verification!: boolean;
    document_verification!: boolean;
    reference_verification!: boolean;
    globaldb_verification!: boolean;
    created_at!: string;
    employee_count!: number;
    verifications!: any[];
    unique_verifications!: any[];
    clients!: any[];
    company_id!: number;
    company_name!: string;
    icr_civil_status!: boolean;
    histories!: Object;
    settings!: LotSettings | null;

    constructor ( lot?: any ) {
        Object.assign( this, lot );
    }
}

export interface LotSettings {
    icr_civil_status: boolean;
    icr_criminal_status: boolean;
    gst_number: string;
    billing_name: string;
    email_visibility: boolean;
    mobile_visibility: boolean;
    current_digital_address_form_id: number;
    permanent_digital_address_form_id: number;
}
