import { Injectable } from '@angular/core';
import { Pagination } from '../models/pagination.model';
import { IdentityVerification } from '../models/identity-verification.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Employee } from '../models/employee.model';


import { Authority } from '../models/authority.model';
import { IdentityManualValidationData } from '../interfaces/identity-manual-validation-data';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class IdentityService {

    constructor (
        private api: ApiService
    ) {
    }

    getEmployees ( params: HttpParams = new HttpParams() ): Observable<Pagination> {
        return this.api.get( 'identity/employees', params );
    }

    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'identity', params );
    }

    find ( id: number ): Observable<IdentityVerification> {
        return this.api.get( 'identity/' + id );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'identity/' + id );
    }

    create ( id: number ): Observable<Employee> {
        return this.api.get( 'identity/' + id + '/add' );
    }

    update ( id: string | number, data: Object | undefined, params: string ): Observable<IdentityVerification> {
        return this.api.put( 'identity/' + id + '?' + params, data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'identity/' + id );
    }

    getAssignedWorkList ( filterParams: any ): Observable<any[]> {
        return this.api.get( 'identity/assigned-work', filterParams );
    }

    createAuthority ( data: {} ): Observable<any[]> {
        return this.api.post( 'identity/authorities', data );
    }

    getAllAuthorities ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'identity/authorities', params );
    }

    findAuthority ( id: number ): Observable<Authority> {
        return this.api.get( 'identity/authorities/' + id );
    }

    updateAuthority ( id: number, values: Object ) {
        return this.api.put( 'identity/authorities/' + id, values );
    }

    autoVerify ( data: {}, params: string | any[] ): Observable<any[]> {
        return this.api.post( 'identity/auto-verify?' + params, data );
    }

    getPaymentUrlExcelData ( params: HttpParams = new HttpParams() ): Observable<any[]> {
        return this.api.get( 'identity/get-payment-url-data?', params );
    }

    getRemarksExcelData ( params: HttpParams = new HttpParams() ): Observable<any[]> {
        return this.api.get( 'identity/get-remarks-data?', params );
    }

    swapFrontAndBack ( data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'identity/swap-document', data );
    }

    getWork ( params: HttpParams ) {
        return this.api.get( 'auto-assign/identity', params );
    }

    getCroppingWork ( params: Params | undefined, id: string ): Observable<any> {
        return this.api.get( 'auto-assign/identity/' + id + '/cropping', params );
    }

    postCroppingWork ( params: HttpParams | undefined, id: number, body: {} = {} ): Observable<any> {
        return this.api.post( 'auto-assign/identity/' + id + '/cropping', body, params );
    }

    cancelCroppingWork ( params: HttpParams, id: number ): Observable<any> {
        return this.api.delete( 'auto-assign/identity/' + id + '/cropping', params );
    }

    getDataEntryWork ( params: HttpParams, id: string ): Observable<any> {
        return this.api.get( 'auto-assign/identity/' + id + '/data-entry', params );
    }

    postDataEntryWork ( params: HttpParams, id: number, body: {} = {} ): Observable<any> {
        return this.api.post( 'auto-assign/identity/' + id + '/data-entry', body, params );
    }

    cancelDataEntryWork ( params: HttpParams, id: number ): Observable<any> {
        return this.api.delete( 'auto-assign/identity/' + id + '/data-entry', params );
    }

    getVerificationWork ( params: HttpParams, id: string ): Observable<any> {
        return this.api.get( 'auto-assign/identity/' + id + '/verification', params );
    }

    postVerificationWork ( params: HttpParams, id: number, body: {} = {} ): Observable<any> {
        return this.api.post( 'auto-assign/identity/' + id + '/verification', body, params );
    }

    cancelVerificationWork ( params: HttpParams, id: number ): Observable<any> {
        return this.api.delete( 'auto-assign/identity/' + id + '/verification', params );
    }

    getValidationWork ( params: HttpParams, id: string ): Observable<any> {
        return this.api.get( 'auto-assign/identity/' + id + '/validation', params );
    }

    postValidationWork ( params: HttpParams, id: number, body: {} = {} ): Observable<any> {
        return this.api.post( 'auto-assign/identity/' + id + '/validation', body, params );
    }

    cancelValidationWork ( params: HttpParams, id: number ): Observable<any> {
        return this.api.delete( 'auto-assign/identity/' + id + '/validation', params );
    }

    getQualityCheckWork ( params: HttpParams, id: string ): Observable<any> {
        return this.api.get( 'auto-assign/identity/' + id + '/quality-check', params );
    }

    postQualityCheckWork ( params: HttpParams, id: number, body: {} = {} ): Observable<any> {
        return this.api.post( 'auto-assign/identity/' + id + '/quality-check', body, params );
    }

    cancelQualityCheckWork ( params: HttpParams, id: number ): Observable<any> {
        return this.api.delete( 'auto-assign/identity/' + id + '/quality-check', params );
    }

    getDataEntryQaWork ( params: HttpParams, id: string ): Observable<any> {
        return this.api.get( 'auto-assign/identity/' + id + '/data-entry-qa', params );
    }

    postDataEntryQaWork ( params: HttpParams, id: number, body: {} = {} ): Observable<any> {
        return this.api.post( 'auto-assign/identity/' + id + '/data-entry-qa', body, params );
    }

    cancelDataEntryQaWork ( params: HttpParams, id: number ): Observable<any> {
        return this.api.delete( 'auto-assign/identity/' + id + '/data-entry-qa', params );
    }

    getManualValidationWork ( params: HttpParams, id: any ): Observable<IdentityManualValidationData> {
        return this.api.get( 'auto-assign/identity/' + id + '/manual-validation', params );
    }

    postManualValidationWork ( params: HttpParams, id: number, body: {} = {} ): Observable<IdentityManualValidationData> {
        return this.api.post( 'auto-assign/identity/' + id + '/manual-validation', body, params );
    }

    cancelManualValidationWork ( params: HttpParams, id: number ): Observable<any> {
        return this.api.delete( 'auto-assign/identity/' + id + '/manual-validation', params );
    }

}
