import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { VerificationCommonEditComponent } from '../../core/components/verification-common-edit/verification-common-edit.component';
import { AddressVerification } from '../../core/models/address-verification.model';
import { AddressService } from '../../core/services/address.service';
import { CommonService } from '../../core/services/common.service';
import { AcceptedFileType, fileTypes } from './../../core/enums/file-types.enum';
import { UploadService } from './../../core/services/upload.service';
import { FileUploader } from 'ng2-file-upload';
import { GlobalService } from 'src/app/core/services/global.service';
import { isUndefined } from 'src/app/core/unit';
import { AddressVerificationStatusType } from 'src/app/core/enums/address-verification-status-types.enum';
// import { FileUploader } from 'ng2-file-upload/file-upload/file-uploader.class';.


@Component( {
    selector: 'app-address-common-edit',
    templateUrl: './address-common-edit.component.html',
    styleUrls: [ './address-common-edit.component.css' ],
} )
export class AddressCommonEditComponent extends VerificationCommonEditComponent implements OnInit {
    @Input() verification!: AddressVerification | any;
    @Input() isSubmitting!: boolean;
    @Input() verificationFormGroup!: FormGroup;
    @Input() formArray!: FormArray;
    @Input() editFormGroup!: FormGroup | any;
    @Input() formGName!: FormGroup | any;
    @Input() vendors!: any[];
    @Input() languages!: any[];
    @Input() current_date: any;
    @Input() created_at: any;
    acceptedFileType = AcceptedFileType.IMAGE;

    uploader: FileUploader = new FileUploader( { url: '' } );
    statusChanged: boolean = false;
    attachments: any;
    pincodeText: any[] = [];
    validStatusesForWorkCompleted: number[] = [ 7, 8, 9, 21, 16 ];
    statusesThatDeniedFormEditing: AddressVerificationStatusType[] = [
        AddressVerificationStatusType.QAPending,
        AddressVerificationStatusType.QAPendingOne,
        AddressVerificationStatusType.QAPendingTwo
    ];

    @Output() onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        protected route: ActivatedRoute,
        protected router: Router,
        private verificationService: AddressService,
        protected el: ElementRef,
        private commonService: CommonService,
        private uploadService: UploadService,
        private globalService: GlobalService,

    ) {
        super( commonService );
    }

    get statuses () { return this.globalService.fetchStatuses(); }

    get digitalAddressForm () {
        return this.editFormGroup.get( 'digital_address_form' ) as FormGroup;
    }

    get deviceForm () {
        return this.editFormGroup.get( 'device' ) as FormGroup;
    }

    get deviceTypeValue () {
        return this.deviceForm.get( 'device_type' )?.value
    }

    get isDigitalAddressStatusDisabled () {
        return this.digitalAddressForm?.get( 'status' )?.value
    }

    get distanceValue () {
        return this.deviceForm.get( 'distance' )?.value
    }

    get proofUrl () {
        return this.digitalAddressForm?.get( 'proof_url' )?.value
    }

    get deviceLatitude () {
        return this.deviceForm.get( 'device_lat' )
    }

    get deviceLongitude () {
        return this.deviceForm.get( 'device_long' )
    }

    get propertyType () {
        return this.editFormGroup.get( 'property_type' )
    }

    get addressLat () {
        return this.editFormGroup.get( 'latitude' )
    }

    get addressLong () {
        return this.editFormGroup.get( 'longitude' )
    }

    get digitalAddressStatus () {
        return this.digitalAddressForm.get( 'status' )
    }

    ngOnInit (): void {

        this.setFormPermission();

        if ( !isUndefined( this.verificationFormGroup ) ) {

            this.attachments = this.editFormGroup.get( 'attachments' )?.value;
        }

        // Subscribe to any Change in Verification Status
        this.editFormGroup.get( 'status_id' )?.valueChanges.subscribe( ( statusId: any ) => {

            this.statusChanged = true;
            this.applyValidationsForStatusChange( statusId );

        } );

    }

    setFormPermission (): void {

        if (
            this.editFormGroup.get( 'completed_at' )?.value &&
            this.commonService.userLevelId() < 4 &&
            !this.statusesThatDeniedFormEditing.includes( this.editFormGroup.get( 'status_id' )?.value )
        ) {

            this.editFormGroup.disable();
        } else {

            this.editFormGroup.enable();
        }

    }


    // Set the value of the form control district_id
    OnTypeheadSelect ( event: any, name: string ): void {

        const controlId: string = name + '_id';


        if ( name === 'state' ) {

            this.editFormGroup.get( 'district_name' )?.setValue( '' );

        }

        this.editFormGroup.get( controlId )?.setValue( event.item.id );

    }

    upload ( typeOfFile: any, $event: any ) {

        if ( this.checkForFileSize( $event ) && this.isAllowedType( $event ) ) {

            return
        }

        const formData = this.addToFormData( $event, typeOfFile );

        const verificationId = this.editFormGroup.get( 'id' ).value;

        this.verificationService.upload( verificationId, formData ).subscribe( {
            next: ( resposne: any ) => {
                this.getAddressVerification( verificationId );

                this.onVerificationUpdated.emit( true );

                this.commonService.notifications.success(
                    'File uploaded Successfully'
                );
            },
            error: ( errors: any ) => {
                this.commonService.notifications.error( 'Unable to upload File' );
            }
        } )
    }

    checkForFileSize ( $event: any ) {

        const count: number = $event.target.files.length;
        const maxSize: number = 300;
        let maxSizeError: boolean = false;

        for ( let i = 0; i < count; i++ ) {

            const selectedFile: File = $event.target.files[ i ];

            if ( selectedFile ) {

                const size = selectedFile.size / 1024;

                if ( size > maxSize ) {

                    this.commonService.notifications.error( 'file size error' );
                    maxSizeError = true;

                }
            }
        }

        return maxSizeError
    }

    isAllowedType ( $event: any ) {

        const count: number = $event.target.files.length;
        const allowedType: any[] = [ 'png', 'jpeg', 'ico', 'jpg' ];
        let allowedTypeError: boolean = false;

        for ( let i = 0; i < count; i++ ) {
            const selectedFile: File = $event.target.files[ i ];
            if ( selectedFile ) {

                const fileExtension: any = selectedFile.name.split( '.' ).pop();

                if ( !allowedType.includes( fileExtension ) ) {

                    this.commonService.notifications.error( 'this file type is not allowed' );
                    allowedTypeError = true;

                }
            }
        }

        return allowedTypeError
    }

    addToFormData ( $event: any, typeOfFile: string ): FormData {

        let formData: FormData = new FormData();

        const count: number = $event.target.files.length;

        const verificationId = this.editFormGroup.get( 'id' ).value;

        for ( let i = 0; i < count; i++ ) {
            const selectedFile = $event.target.files[ i ] as Blob;
            if ( selectedFile ) {
                formData.append( 'file_' + i, selectedFile );
            }
        }

        formData.append( 'type', typeOfFile + '_' + verificationId );

        return formData;
    }

    getAddressVerification ( verificationId: number ): void {

        this.verificationService.findByEmployeeId( this.editFormGroup.get( 'employee_id' )?.value )
            .subscribe( employee => {

                employee.address_verifications.filter( item => {

                    if ( item.id === verificationId ) {

                        this.verification = item;

                        if ( !isUndefined( this.verificationFormGroup ) ) {
                            this.getAttachments( item );
                        }
                    }
                } );
            } );

    }


    getAttachments ( verification: AddressVerification ): void {

        this.attachments = verification.attachments;

    }

    pincodeDetails ( pincode: string ): void {

        if ( pincode.length === 6 ) {

            this.commonService.getPincodeDetails( pincode ).subscribe( response => {

                this.pincodeText = response;

            } );

        }

    }

    /**
     * Apply Validations when Status is changed
     *
     * @param statusId
     */
    protected applyValidationsForStatusChange ( statusId: any ): void {
        // List of statuses for which validations is required on completion Date.
        const validStatusesForCompletionDate: number[] = [
            1,
            2,
            3,
            4,
            5,
            10,
            17,
            22,
            27,
            28,
            30,
            32,
            33,
        ];

        // List of Statuses for which validations is required on sent to vendor on date.
        const validStatusesForSentToVendorOnDate: number[] = [ 19 ];

        // List of Statuses for which validations is required on sent to vendor on date.
        const validStatusesForLanguageId: number[] = [ 19 ];

        // List of statuses for which validations is required on pincode.
        const validStatusesForPincode: number[] = [
            1,
            2,
            3,
            4,
            5,
            6,
            9,
            10,
            11,
            17,
            19,
            20,
            24,
            25,
            28,
            31,
            32,
        ];

        // List of statuses for which validations is required on Address
        const validStatusesForAddress: number[] = [
            1,
            2,
            3,
            4,
            5,
            6,
            9,
            10,
            11,
            17,
            19,
            20,
            24,
            25,
            28,
            31,
            32,
        ];

        // List of statuses for which validations is required on State id.
        const validStatusesForStateId: number[] = [
            1,
            2,
            3,
            4,
            5,
            6,
            9,
            10,
            11,
            17,
            19,
            20,
            24,
            25,
            28,
            31,
            32,
        ];

        // List of statuses for which validations is required on district id.
        const validStatusesForDistrictId: number[] = [
            1,
            2,
            3,
            4,
            5,
            6,
            9,
            10,
            11,
            17,
            19,
            20,
            24,
            25,
            28,
            31,
            32,
        ];

        // Check if changed status is in our list.
        if ( validStatusesForCompletionDate.indexOf( statusId ) !== -1 ) {
            this.editFormGroup
                .get( 'completed_at' )
                ?.setValidators( [
                    Validators.required,
                    validateCompletionDate( this.current_date, this.created_at ),
                ] );
        } else {
            this.editFormGroup
                .get( 'completed_at' )
                ?.setValidators( [
                    validateCompletionDate( this.current_date, this.created_at ),
                ] );
        }

        if ( validStatusesForPincode.indexOf( statusId ) !== -1 ) {
            this.editFormGroup
                .get( 'pincode' )
                ?.setValidators( [
                    Validators.required,
                    validateNumericCharacters( '' ),
                    validateLength( '6' ),
                ] );
        } else {
            this.editFormGroup.get( 'pincode' )?.setValidators( [] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForSentToVendorOnDate.indexOf( statusId ) !== -1 ) {
            this.editFormGroup
                .get( 'sent_to_vendor_on' )
                ?.setValidators( [
                    Validators.required,
                    validateCompletionDate( this.current_date, this.created_at ),
                ] );
            this.editFormGroup.get( 'vendor_id' )?.setValidators( [ Validators.required ] );
        } else {
            this.editFormGroup
                .get( 'sent_to_vendor_on' )
                ?.setValidators( [
                    validateCompletionDate( this.current_date, this.created_at ),
                ] );
            this.editFormGroup.get( 'vendor_id' )?.setValidators( [] );
        }

        if ( validStatusesForAddress.indexOf( statusId ) !== -1 ) {
            this.editFormGroup.get( 'address' )?.setValidators( [ Validators.required ] );
        } else {
            this.editFormGroup.get( 'address' )?.setValidators( [] );
        }

        if ( validStatusesForStateId.indexOf( statusId ) !== -1 ) {
            this.editFormGroup.get( 'state_id' )?.setValidators( [ Validators.required ] );
        } else {
            this.editFormGroup.get( 'state_id' )?.setValidators( [] );
        }
        if ( validStatusesForDistrictId.indexOf( statusId ) !== -1 ) {
            this.editFormGroup
                .get( 'district_id' )
                ?.setValidators( [ Validators.required ] );
        } else {
            this.editFormGroup.get( 'district_id' )?.setValidators( [] );
        }

        if ( validStatusesForLanguageId.indexOf( statusId ) !== -1 ) {
            this.editFormGroup
                .get( 'language_id' )
                ?.setValidators( [ Validators.required ] );
        } else {
            this.editFormGroup.get( 'language_id' )?.setValidators( [] );
        }

        if ( this.validStatusesForWorkCompleted.indexOf( statusId ) != -1 && this.editFormGroup.get( 'work_completed' ) ) {
            this.editFormGroup.get( 'work_completed' )?.setValidators( [ Validators.required ] );
            this.editFormGroup.get( 'work_completed' )?.updateValueAndValidity();
        } else if ( this.editFormGroup.get( 'work_completed' ) ) {
            this.editFormGroup.get( 'work_completed' )?.setValidators( [] );
            this.editFormGroup.get( 'work_completed' )?.updateValueAndValidity();
        }


        this.editFormGroup.get( 'completed_at' )?.updateValueAndValidity();
        this.editFormGroup.get( 'pincode' )?.updateValueAndValidity();
        this.editFormGroup.get( 'sent_to_vendor_on' )?.updateValueAndValidity();
        this.editFormGroup.get( 'district_id' )?.updateValueAndValidity();
        this.editFormGroup.get( 'state_id' )?.updateValueAndValidity();
        this.editFormGroup.get( 'address' )?.updateValueAndValidity();
        this.editFormGroup.get( 'language_id' )?.updateValueAndValidity();
        this.editFormGroup.get( 'vendor_id' )?.updateValueAndValidity();


    }

    onStateChanged ( $event: any ) {

    }

    onDistrictChanged ( $event: any ) {

    }
}
