import { filter } from 'rxjs';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { VerificationList } from '../../core/components/verification-list.component';
import { PccVerification } from '../../core/models/pcc-verification.model';
import { CommonService } from '../../core/services/common.service';
import { PccService } from '../../core/services/pcc.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { Attachment } from 'src/app/core/models/attachment.model';
import { PccVerifications } from 'src/app/client-forms-v2/client-forms-v2.enum';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-pcc-list',
    templateUrl: './pcc-list.component.html',
    styleUrls: [ './pcc-list.component.css' ],
} )
export class PccListComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications!: PccVerification[];
    isSubmitting = false;
    attachments: any[] = [];
    crmUrl!: string;
    statsVisible = false;

    constructor (
        protected override verificationService: PccService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected modalService: BsModalService,
        protected override viewContainerRef: ViewContainerRef,
        public override confirmationModalService: ConfirmationModalService

    ) {
        super( confirmationModalService );

    }

    get users () {
        return this.global.fetchUsers();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit () {
        this.crmUrl = environment.crm_url;
        this.verification = 'pcc';
        this.subscribeToQueryChanges();
    }

    showPcc ( pcc: PccVerification ) {
        this.router.navigate( [ 'pcc', pcc.employee_id, 'edit' ], this.navigationExtras );
    }

    filterResults (): void {
        this.verificationService.filter( this.filterParams ).subscribe( ( response: any ) => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    populateExtraDetails ( reference_verification: any ) {

        for ( const index in reference_verification ) {

            if ( reference_verification.hasOwnProperty( index ) ) {

                const verification = reference_verification[ index ].new_attachments;

                const attachments: any = {
                    'payment_screenshot': verification.filter( ( item: any ) => item.name === 'payment_screenshot' ),
                    'attachments': verification.filter( ( item: any ) => item.name === 'attachment' ),
                    'application_screenshot': verification.filter( ( item: any ) => item.name === 'application_screenshot' )
                };

                this.attachments[ +index ] = attachments;

            }
        }
    }

    showStats () {
        this.statsVisible = !this.statsVisible;
    }

    downloadNewExcel () {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/pcc/downloadExcel',
            fileName: 'Pcc Verification',
            method: 'get',
            postParams: null,
        };

        this.verificationService.showDownloadModal( initialState );
    }

}
