import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Params } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { DownloadExcelService } from 'src/app/core/services/download-excel.service';

@Component( {
    selector: 'app-new-download-modal',
    templateUrl: './new-download-modal.component.html',
} )
export class NewDownloadModalComponent implements OnInit {
    hasDownloadStarted: any;

    @Input()
    filterParams!: Params | undefined | any;
    @Input()
    route!: string;
    @Input()
    paramsString!: string;
    @Input()
    fileName!: string;
    @Input()
    method!: string;
    @Input()
    postParams!: object;

    cancellProcess ( $event: MouseEvent ) {
        throw new Error( 'Method not implemented.' );
    }

    downloadFormGroup!: FormGroup;
    isSubmitting!: boolean;

    constructor (
        public bsModalRef: BsModalRef,
        private fb: FormBuilder,
        private downloadExcelService: DownloadExcelService,
        private commonService: CommonService

    ) { }

    ngOnInit (): void {
        this.initForm();
    }

    get defaultPurpose (): string | null {
        return window.localStorage.getItem( 'purpose' );
    }

    set defaultPurpose ( purpose: string | any ) {
        window.localStorage.setItem( 'purpose', purpose );
    }

    initForm (): void {
        this.downloadFormGroup = this.fb.group( {
            'purpose': [ this.defaultPurpose, Validators.required ],
            'params': this.paramsString,
            'post_params': this.postParams,
            'route': this.route,
            'request_method': this.method,
            'file_name': this.fileName
        } );
    }

    submit (): void {

        this.isSubmitting = true;

        this.defaultPurpose = this.downloadFormGroup.get( 'purpose' )?.value

        this.downloadExcelService.newDownloadExcel( this.downloadFormGroup.value ).subscribe( {
            next: ( response: any ) => {

                this.bsModalRef.hide();

                if ( response.success ) {

                    this.commonService.notifications.success( response.message );

                } else {

                    this.commonService.notifications.error( response.message );

                }

                localStorage.setItem( 'token', response.auth_token );

                this.isSubmitting = false;

            },
            error: ( error: any ) => {

                this.commonService.notifications.error( error );
                this.isSubmitting = false;
            },
        } )
    }

    onClose (): void {

        this.bsModalRef.hide();

    }
}
