<mat-accordion *ngIf="remarks && remarks.length>0" >

    <div class="panel panel-default">
        <mat-expansion-panel>
            <mat-expansion-panel-header class="waves-effect remark-title">
                Remarks
            </mat-expansion-panel-header>
            <div>
                <ul class="list-unstyled">
                    <li *ngFor="let remark of remarks">
                        {{ remark.created_at }}
                        - {{ remark.text }}
                        - {{ remark.name }}
                    </li>
                </ul>
            </div>
        </mat-expansion-panel>
    </div>

</mat-accordion>


<div class="panel panel-default">
    <div class="panel-footer text-center">
        <form (keydown)="insertRemarks($event)">
            <input class="form-control" placeholder="Add new remarks" name="remark_text"
                style="height: 36px;padding: 8px 10px;" />

            <button type="submit" style="display:none">hidden submit</button>
        </form>
    </div>
</div>
