<div bsModal #globalDbLogSearchModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="globalDbLogSearchForm" (ngSubmit)="search()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Search for a GlobalDB Record</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Name
                                        <input formControlName="name" class="form-control form-control-lg"
                                            type="text" />
                                    </label>
                                    <span *ngIf="name?.errors?.['pattern']" class="text-danger">
                                        Name should not contain special characters or numbers
                                    </span>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Date of Birth
                                        <input type="date" formControlName="date_of_birth" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 25px;">

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Gender
                                        <select class="form-control" formControlName="gender">
                                            <option value="MALE">
                                                Male
                                            </option>
                                            <option value="FEMALE">
                                                Female
                                            </option>
                                        </select>
                                    </label>
                                </fieldset>
                            </div>

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Agency
                                        <input formControlName="agency" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>

                        </div>
                        <div class="row" style="margin-top: 25px;">

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">PDF Report
                                        <select class="form-control" formControlName="pdf_report">
                                            <option value="true">
                                                True
                                            </option>
                                            <option value="false">
                                                False
                                            </option>
                                        </select>
                                    </label>
                                </fieldset>
                            </div>

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Name Match
                                        <select class="form-control" formControlName="name_match">
                                            <option value="true">
                                                True
                                            </option>
                                            <option value="false">
                                                False
                                            </option>
                                        </select>
                                    </label>
                                </fieldset>
                            </div>

                        </div>
                        <div class="row" style="margin-top: 25px;">

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Name Match Threshold
                                        <select class="form-control" formControlName="name_match_threshold">
                                            <option value="true">
                                                True
                                            </option>
                                            <option value="false">
                                                False
                                            </option>
                                        </select>
                                    </label>
                                </fieldset>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Sources</label>
                                    <div *ngFor="let source of globalDbLogSources | keyvalue" class="display-inline">
                                        <input type="radio" formControlName="driver" id={{source.value}}
                                            value={{source.value}} />
                                        <label for="{{source.value}}" class="radios">{{source.key}}</label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!globalDbLogSearchForm.valid">
                            Search
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
