<div bsModal #editStatus="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">

    <div class="wrapper-md">
        <div class="row">
            <div class="col-md-offset-3 col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Edit Status
                    </div>
                    <form [formGroup]="editStatusForm" (ngSubmit)="submit()">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="center-block">Status Name</label>
                                    <input formControlName="title" class="form-control">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Type</label>
                                    <select class="form-control" formControlName="type">
                                        <option value="under_process">under_process</option>
                                        <option value="verified">verified</option>
                                        <option value="not_initiated">not_initiated</option>
                                        <option value="discrepancy">discrepancy</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Insufficiency</label>
                                    <select class="form-control" formControlName="insuff">
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="control-label">Verification Complete</label>
                                    <select class="form-control" formControlName="is_completed">
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="center-block">Description</label>
                                    <input formControlName="description" class="form-control">
                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="center-block">Client title</label>
                                    <input formControlName="client_title" class="form-control">
                                </div>

                                <div class="col-md-6 form-group">
                                    <label class="center-block">Client Description</label>
                                    <input formControlName="client_description" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="panel-footer text-right">
                            <div class="row">
                                <div class="col-sm-offset-8 col-sm-4">
                                    <button [appCan]="'superadmin'" class="btn btn-primary"
                                        [disabled]="!editStatusForm.valid">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>