import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailLogsListComponent } from './email-logs-list/email-logs-list.component';
import { EmailLogService } from '../core/services/email-log.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmailDetailsComponent } from './email-details/email-details.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EmailLogsFiltersComponent } from './email-logs-filters/email-logs-filters.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailLogStatusTimeLineComponent } from '../common/email-log-status-time-line/email-log-status-time-line.component';

@NgModule( {
    imports: [ CommonModule, BrowserModule, NgxPaginationModule, ModalModule, FormsModule, ReactiveFormsModule ],
    declarations: [
        EmailLogsListComponent,
        EmailDetailsComponent,
        EmailLogsFiltersComponent,
        EmailLogStatusTimeLineComponent
    ],
    exports: [
        EmailLogStatusTimeLineComponent
    ],
    providers: [ EmailLogService ],
} )
export class EmailLogModule {
}
