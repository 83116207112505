<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Employment Companies</h1>
</div>
<app-verification-filters verification="employment" [filterParams]="filterParams" filterBy="name,employment_company">
</app-verification-filters>

<app-employment-edit-modal #editModal (onVerificationUpdated)="onModalUpdated()">
</app-employment-edit-modal>

<div class="wrapper-md">
    <div class="row">
        <div class="col-md-12">
            <a class="btn btn-primary btn-addon" routerLink="/employment/companies">
                All Companies
            </a>
        </div>
    </div>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width:30%;">Employee Details</th>
                        <th style="width:30%;">Employment Details</th>
                        <th style="width:30%;">Status Details</th>
                        <th style="width:10%;">Attachments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let verification of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">
                        <td>
                            <a class="btn-link text-main"
                                (click)="editModal.show(verification.employee_id,verification.id)">
                                <strong>{{verification.track_id}}</strong>
                            </a>

                            <br>

                            <strong>{{verification.employee_name}}</strong>
                            <br>

                            <a class="btn-link" routerLink="/lots/{{verification.lot_id}}">
                                {{verification.lot_name}}
                            </a>
                            <br>
                            <a class="btn-link" routerLink="/companies/{{verification.company_id}}">
                                {{verification.company_name}}
                            </a>


                        </td>

                        <td>

                            <ul class="list-unstyled">

                                <li><strong>{{verification.employment_company_name }}</strong></li>


                                <li *ngIf="verification.designation">
                                    <strong>Designation: </strong>
                                    {{verification.designation}}
                                </li>


                                <li *ngIf="verification.department">
                                    <strong>Department: </strong>
                                    {{verification.department}}
                                </li>


                                <li *ngIf="verification.employment_type">
                                    <strong>Employment Type:</strong>
                                    {{verification.employment_type}}
                                </li>

                                <li *ngIf="verification.joining_date">
                                    <strong>Joining Date: </strong>
                                    {{verification.joining_date}}
                                </li>

                                <li *ngIf="verification.end_date">
                                    <strong>Worked Till:</strong>
                                    {{verification.end_date}}
                                </li>

                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    {{verification.status_name}}
                                </li>
                                <li>
                                    Created At: {{verification.created_at}}
                                </li>
                                <li *ngIf="verification.completed_at">
                                    Completion Date: {{ verification.completed_at}}
                                </li>
                            </ul>


                        </td>


                        <td>
                            <div class="row" *ngIf="attachments[i]['documents']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['documents']">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>

                            <div class="row" *ngIf="verification.proof_url">
                                <div class="col-md-12">
                                    <a target="_blank" href="{{verification.proof_url}}" class="btn-link">
                                        Proof Url
                                    </a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>