import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalDocuments, GlobalVerification } from '../client-forms-v2.enum'

@Component( {
    selector: 'app-global-db-form',
    templateUrl: './global-db-form.component.html',
} )
export class GlobalDbFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    selectedOption: string[] = [];

    isSectionChecked: boolean = false;

    globalDocumentList = GlobalDocuments;

    maxLimit: number = 1;

    globalForm = this.fb.group( {
        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] ),
    } )

    get isGlobalSectionChecked (): boolean | undefined {
        return this.globalForm.get( 'required' )?.value;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.globalVerificationArray.length > 0;
    }

    get globalVerificationArray (): FormArray | any {
        return this.globalForm.get( 'verifications' ) as FormArray;
    }

    constructor ( private fb: FormBuilder, private commonService: CommonService ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'global_db', this.globalForm );
    }

    addGlobal (): void {
        const globalItem = this.fb.group( {
            type: this.fb.control( 'passport', [ Validators.required ] ),
            required: this.fb.control( true, [ Validators.required ] ),
        } );

        if ( this.globalVerificationArray.length >= this.maxLimit ) {
            this.commonService.notifications.error( 'Global DB', `Global DB max limit is ${ this.maxLimit }` );
        } else {
            this.globalVerificationArray.push( globalItem );
        }
    }

    sectionToggle ( $event: any ): void {
        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {
            this.globalVerificationArray.clear();
        }
    }

    skipSelectedItem ( index: any, $event: any ): void {
        this.selectedOption[ index ] = $event.target.value;
    }

}
