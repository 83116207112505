import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { VerificationList } from '../../core/components/verification-list.component';
import { DocumentVerification } from '../../core/models/document-verification.model';
import { CommonService } from '../../core/services/common.service';
import { DocumentService } from '../../core/services/document.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: [ './document-list.component.css' ],
} )
export class DocumentListComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications: DocumentVerification[] = [];
    isSubmitting: boolean = false;
    statsVisible: boolean = false;
    attachments: any[] = [];

    constructor (
        protected override verificationService: DocumentService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        public override confirmationModalService: ConfirmationModalService,
    ) {
        super( confirmationModalService );
    }

    get users () {
        return this.global.fetchUsers();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {

        this.verification = 'document';
        this.subscribeToQueryChanges();
    }

    filterResults (): void {

        this.verificationService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    showDocument ( document: DocumentVerification ): void {

        this.router.navigate( [ 'document', document.employee_id, 'edit' ], this.navigationExtras );
    }

    showStats (): void {

        this.statsVisible = !this.statsVisible;
    }

    populateExtraDetails ( documentVerification: DocumentVerification[] ): void {


        for ( const index in documentVerification ) {

            const verification = documentVerification[ index ];

            this.attachments[ index ] = verification.attachments;


        }


    }

    downloadNewExcel () {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/document/downloadExcel',
            fileName: 'Document Verification',
            method: 'get',
            postParams: null,
        };

        this.verificationService.showDownloadModal( initialState );
    }

}
