<form [formGroup]="verificationFormGroup" (ngSubmit)="updateVerification('education')"
    (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">

        <div *ngIf="employee && employee.id" class="row bg-light lter b-b wrapper-md">

            <app-verification-edit-header [verification]="'education'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>

            <app-education-create-institute #instituteCreateModal (onInstituteCreated)="onInstituteCreated()">
            </app-education-create-institute>

            <div class="col-md-6">

                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="instituteCreateModal.show()">
                    <i class="fa fa-plus"></i>Create Institute
                </a>

                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createEducation()">
                    <i class="fa fa-plus"></i>
                    Add Education Verification
                </a>

                <a class="btn m-b-xs btn-sm btn btn-primary btn-addon" routerLink="/education/institutes">
                    <i class="fa fa-plus"></i>
                    All Institutes
                </a>
            </div>

        </div>


        <div class="wrapper-md">
            <div class="row">

                <div class="col-md-3">

                    <!--Remarks-->
                    <app-employee-remarks *ngIf="employee" [remarks]="employee.remarks"></app-employee-remarks>

                    <!--Employee Details-->
                    <app-employee-side-block [refreshEmployee]="refreshEmployee"></app-employee-side-block>
                    <app-employee-basic-details [employee]="employee" [submitting]="isSubmitting">
                    </app-employee-basic-details>

                </div>

                <div class="col-md-9">
                    <app-image-crop></app-image-crop>
                    <div formArrayName="formArray">
                        <div *ngFor="let verification of formArray.controls; let i=index" [formGroupName]="i">

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    Education Verification #{{i+1}}
                                </div>

                                <app-education-common-edit *ngIf="verification" [current_date]="current_date"
                                    [created_at]="employee.created_at.slice(0,10)"
                                    [verificationFormGroup]="verificationFormGroup" [formArray]="formArray"
                                    [editFormGroup]="verification" [formGName]="i" [vendors]="vendors"
                                    [isSubmitting]="isSubmitting" [verification]="verification">
                                </app-education-common-edit>

                                <div class="panel-footer">

                                    <div class="row">
                                        <div class="col-md-6">
                                            <a class="btn btn-primary"
                                                (click)="sendMail.show(verification,verification.get('attachments')?.value)"
                                                *ngIf="verification.get('institute_id')?.value">
                                                Send Mail
                                            </a>
                                        </div>
                                        <div class="col-md-offset-10" [appCan]="'admin'">
                                            <a class="btn btn-danger"
                                                (click)="onDeleteVerificationButtonClicked(verification.get('id')?.value,i)">
                                                Delete Verification
                                            </a>
                                        </div>
                                    </div>

                                    <app-education-send-mail #sendMail></app-education-send-mail>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
