import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoryShowComponent } from './history-show/history-show.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { HistoryService } from '../core/services/history.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../core/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HistoryListComponent } from './history-list/history-list.component';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule( {
    imports: [
        CommonModule,
        NgxPaginationModule,
        BrowserModule,
        RouterModule,
        SharedModule,
        BrowserAnimationsModule,
        MatExpansionModule,
    ],
    declarations: [
        HistoryShowComponent,
        HistoryListComponent,
    ],
    exports: [
        HistoryShowComponent,
    ],
    providers: [ HistoryService ],
} )
export class HistoryModule {
}
