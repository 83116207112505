import { FilterService } from './../../core/services/filter.service';
import { Component, Input, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';

import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-vehicle-filters',
    templateUrl: './vehicle-filters.component.html',
    styleUrls: [ './vehicle-filters.component.css' ],
} )
export class VehicleFiltersComponent implements OnInit {

    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    clients!: any[];

    constructor (

        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService,
    ) { }

    ngOnInit () {
        this.initForm();

        this.updateFormFilter();

        this.filterResults();

        this.getAllClientApis();
    }

    initForm (): void {

        const myDate = new Date();

        const previousMonth = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );

        previousMonth.setDate( myDate.getDate() + 1 );

        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            number: 'all',
            client_api_id: 'all',
            via: 'all',
            created_before: 'all',
            created_after: previousMonth.toJSON().slice( 0, 11 ),
            sms_status: 'all',
            vahan_status: 'all',
            state_status: 'all',
            parivahan_status: 'all',
            vahan_citizen_status: 'all',
            signzy_status: 'all',
            karza_status: 'all',
            page: 1,
            updated_before: 'all',
            updated_after: 'all',
        } );
    }


    filterResults (): void {

        this.filterService.filterByParams( this.filterFormGroup.value );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    onSearch () {

        this.filterService.filter();
    }

    private updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );
    }

    private getAllClientApis (): void {

        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }
}
