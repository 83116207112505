import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { UserListComponent } from './user-list/user-list.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserService } from '../core/services/user.service';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { ShowPermissionComponent } from './show-permission/show-permission.component';
import { EditPermissionComponent } from './edit-permission/edit-permission.component';
import { UserFiltersComponent } from './user-filters/user-filters.component';
import { UserUploadComponent } from './user-upload/user-upload.component';
import { UserForgetPasswordComponent } from './user-forget-password/user-forget-password.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserHistoryComponent } from './user-history/user-history.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        UserListComponent,
        UserCreateComponent,
        UserEditComponent,
        UserChangePasswordComponent,
        ShowPermissionComponent,
        EditPermissionComponent,
        UserFiltersComponent,
        UserUploadComponent,
        ChangePasswordComponent,
        UserForgetPasswordComponent,
        UserResetPasswordComponent,
        UserHistoryComponent,
    ],
    providers: [ UserService ],
} )
export class UserModule {
}
