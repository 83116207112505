import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import Status from '../../core/models/status.model';
import { CommonService } from '../../core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-verification-status-typeahead',
    templateUrl: './verification-status-typeahead.component.html',
} )
export class VerificationStatusTypeaheadComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() control!: string;
    @Input() verification!: string;
    @Input() disabled!: boolean;
    @Input() label: string = 'Status';
    @Input() required: boolean = true;

    @Output() changed: EventEmitter<number> = new EventEmitter<number>();

    constructor (
        public global: GlobalService,
        public common: CommonService,
    ) {
    }

    get idValue () { return this.formGroup.get( this.statusId )?.value }

    get nameValue () { return this.formGroup.get( this.statusName )?.value }

    get statusName () { return this.control + '_name' }

    get statusId () { return this.control + '_id' }

    get statuses (): Status[] {
        return this.global.fetchStatuses()[ this.verification ];
    }

    get options (): Status[] {
        return this.statuses;
    }

    ngOnInit (): void {

        // console.log( this.formGroup );

    }

    onSelect ( $event: TypeaheadMatch ) {

        this.formGroup.get( this.statusId )?.setValue( $event.item.id );

        this.changed.emit( this.idValue );

    }

    onNoResults ( $event: boolean ) {

        if ( $event === true ) {

            this.formGroup.get( this.statusId )?.setValue( null );
            this.formGroup.get( this.statusName )?.setValue( null );

            this.changed.emit( this.idValue );
        }
    }

}
