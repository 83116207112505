export function isUndefined ( value: any ) {
    return typeof value === 'undefined';
}

export function isNullOrUndefined ( value: any ): boolean {
    return value === null || value === undefined;
}

export function isNull ( value: any ): boolean {
    return value === null || value === 'null';
}
