<div class="bg-light lter b-b wrapper-md" xmlns="http://www.w3.org/1999/html">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Identity Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>


</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="identity"></app-verification-stats>
</ng-container>

<app-verification-filters verification="identity" [filterParams]="filterParams" [vendors]="vendors"
    filterBy="company,lot,verification_status,state,district,pincode,created_after,created_before,completion_date_from,completion_date_to,assigned_to,type_of_doc,proof_url,vendor,sent_to_vendor,work_assignment,qa_present,qa_required,qa_done,work_types,track_id,dov_present,work_completed">
</app-verification-filters>

<app-work-assign-modal #workAssignModal [verification]="'identity'" [checkedIds]="ids"
    (onWorkAssigment)="onModalUpdated()"></app-work-assign-modal>
<app-auto-verify-modal #autoVerifyModal [filterParams]="filterParams" [verification]="'identity'"
    [checkedIds]="ids"></app-auto-verify-modal>

<div class="row" style="padding: 0px 0px 0px 35px">

    <div class="col-md-1" style="padding: 0;">

        <!-- <a class="btn btn-primary btn-addon" (click)="exportExcel()" [appHide]="'identity,download.excel'">
        <i class="fa fa-download"></i>
        Download Excel
    </a> -->

        <a class="btn btn-primary btn-addon" (click)="downloadBackendExcel()" [appHide]="'identity,download.excel'">
            <i class="fa fa-download"></i>
            Download Excel
        </a>

    </div>

    <div class="col-md-11" [appCan]="'admin'" style="padding-left: 0;">

        <button routerLink="/identity/submit-to-icr" class="btn btn-primary">Submit To Vcourt</button>

        <a class="btn btn-primary btn-addon" (click)="exportPaymentUrlExcel()">
            <i class="fa fa-download"></i>
            Payment URL Excel
        </a>

        <a class="btn btn-primary btn-addon" (click)="exportRemarksExcel()">
            <i class="fa fa-download"></i>
            Remarks Excel
        </a>

        <!-- old way -->
        <!-- <a class="btn btn-primary btn-addon" target="_blank" [href]="[crmUrl+'identity/print/letters?'+filterParams]">
        <i class="fa fa-print"></i>
        DL Letters </a>

    <a class="btn btn-primary btn-addon" target="_blank"
        [href]="[crmUrl+'identity/print/letters?stamp=1&letterhead=1'+filterParams]">
        <i class="fa fa-print"></i>
        DL Letters(stamps) </a>

    <a class="btn btn-primary btn-addon" target="_blank" [href]="[crmUrl+'identity/print/envelopes?'+filterParams]">
        <i class="fa fa-print"></i>
        DL Envelopes </a>

    <a class="btn btn-primary btn-addon" target="_blank" [href]="[crmUrl+'identity/print/licences?'+filterParams]">
        <i class="fa fa-print"></i>
        Licences </a> -->

        <!-- new model  -->
        <a downloadDocument documentName="Letters" documentType="pdf" [href]="letterLink"
            class="btn btn-primary btn-addon">
            <i class="fa fa-print"></i>
            DL Letters </a>

        <a downloadDocument documentName="Letters Stamps" documentType="pdf" class="btn btn-primary btn-addon"
            [href]="letterStampLink">
            <i class="fa fa-print"></i>
            DL Letters(stamps) </a>

        <a downloadDocument documentName="Envelopes" documentType="pdf" class="btn btn-primary btn-addon"
            [href]="envelopeLink">
            <i class="fa fa-print"></i>
            DL Envelopes </a>

        <a downloadDocument documentName="Licences" documentType="pdf" class="btn btn-primary btn-addon"
            [href]="licenceLink">
            <i class="fa fa-print"></i>
            Licences </a>

    </div>

</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="row wrapper">
            <div class="col-sm-8" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses['identity']"
                            (click)="onChangeStatusButtonClicked(status.id,status.title)">
                            <a class="dropdown-item">{{status.title}}</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa required <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa Done <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown [appCan]="'admin'">
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Vendor <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let vendor of vendors"
                            (click)="onChangeVendorButtonClicked(vendor.id)">
                            <a class="dropdown-item">{{vendor.name}}</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" [appCan]="'admin'">
                    <button (click)="editModal.show()" type="button" class="btn btn-primary">
                        Sent to Vendor Date
                    </button>

                    <div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
                        aria-labelledby="mySmallModalLabel" aria-hidden="true">


                        <form (ngSubmit)="onChangeVendorDateButtonClicked()" (keydown.enter)="$event.preventDefault()">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        Select Vendor Date
                                    </div>

                                    <div class="modal-body">
                                        <input style="width: 100%;" [(ngModel)]="vendorDate" name="vendorDate"
                                            type="date" required class="form-control">
                                    </div>

                                    <div class="modal-footer">
                                        <div class="pull-right">
                                            <button class="btn btn-default btn-primary">
                                                Update
                                            </button>
                                        </div>
                                        <div class="pull-left">
                                            <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>


                </div>

                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('identity')"
                    class="btn btn-danger btn-addon"><i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{user.name}}</a>
                        </li>
                    </ul>
                </div>

                <a class="btn btn-primary btn-addon" (click)="autoVerifyModal.show()">
                    Auto Verify
                </a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)">
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:35%;">Details</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:15%;">Vendor Details</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let verification of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">

                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{verification.id}}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id,$event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <a class="btn-link text-main text-semibold"
                                href="/#/identity/{{verification.employee_id}}/edit" target="_blank">
                                <strong> {{ verification.employee_name }}</strong>
                            </a>
                            <br>
                            <a class="btn-link text-bold"
                                (click)="showIdentity(verification)">{{verification.track_id}}</a>
                            <br>
                            <a class="btn-link" routerLink="/lots/{{verification.lot_id}}">
                                {{ verification.lot_name }}
                            </a>
                            <br>
                            <a class="btn-link" routerLink="/companies/{{verification.company_id}}">
                                {{ verification.company_name }}
                            </a>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>Name: {{ verification.name }}</li>
                                <li>Address: {{ verification.address }}</li>
                                <li>District: {{ verification.district_name }}</li>
                                <li>State: {{ verification.state_name }}</li>
                                <li>Type: {{ verification.type_name }}</li>
                                <li>Number: {{ verification.number }}</li>
                                <li>Created on: {{ verification.created_at }}</li>
                                <li *ngFor="let work of verification.work_assigned[0]">
                                    <strong> {{work.title}} is assigned to {{work.assigned_to}}</strong>
                                </li>
                            </ul>
                        </td>
                        <td class="{{ verification.color }}">
                            <app-verification-status verification="identity" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at">
                            </app-verification-status>
                            <div class="row">
                                <div class="col-md-12" *ngIf="verification.front_url">
                                    <a href="{{verification.front_url}}" class="btn-link">
                                        Front Url
                                    </a>
                                </div>

                                <div class="col-md-12" *ngIf="verification.back_url">
                                    <a href="{{verification.back_url}}" class="btn-link">
                                        Back Url
                                    </a>
                                </div>

                                <div class="col-md-12" *ngIf="verification.proof_url">
                                    <a href="{{verification.proof_url}}" class="btn-link">
                                        Proof Url
                                    </a>
                                </div>

                            </div>
                            <div class="row" *ngIf="attachments && attachments[i]['payment_urls']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['payment_urls']">
                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                        </td>

                        <td>

                            <app-vendor-dropdown verification="identity" [verificationId]="verification.id"
                                [vendorId]="verification.vendor_id" [verificationDate]="verification.completed_at"
                                [sentToVendorDate]="verification.sent_to_vendor_on" [vendorList]="vendors"
                                (onVerificationUpdated)="onModalUpdated()">
                            </app-vendor-dropdown>
                        </td>
                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id,i)">
                                Delete
                            </button>

                            <div class="row">
                                <div class="col-md-12" *ngIf="verification.front_url">
                                    <button
                                        (click)="onDeleteFileButtonClicked(verification.id,'front_url','delete_front_url_'+i)"
                                        class="btn btn-danger btn-xs" id="delete_front_url_{{i}}">
                                        Delete Front Url
                                    </button>
                                </div>
                                <div class="col-md-12" *ngIf="verification.back_url">
                                    <button
                                        (click)="onDeleteFileButtonClicked(verification.id,'back_url','delete_back_url_'+i)"
                                        class="btn btn-danger btn-xs" id="delete_back_url_{{i}}">
                                        Delete Back Url
                                    </button>
                                </div>
                                <div class="col-md-12" *ngIf="verification.proof_url">
                                    <button
                                        (click)="onDeleteFileButtonClicked(verification.id,'proof_url','delete_proof_url_'+i)"
                                        class="btn btn-danger btn-xs" id="delete_proof_url_{{i}}">
                                        Delete Proof Url
                                    </button>
                                </div>

                            </div>

                            <div class="row" *ngIf="attachments && attachments[i]['payment_urls']">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['payment_urls']">
                                    <button
                                        (click)="onDeleteAttachmentButtonClicked(verification.id,attachment['id'],'delete_payment_url_'+attachment['id'])"
                                        class="btn btn-danger btn-xs" id="delete_payment_url_{{attachment['id']}}">
                                        Delete {{attachment['file_name']}}
                                    </button>
                                </div>
                            </div>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>