import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { WorkService } from 'src/app/core/services/work.service';



@Component( {
    selector: 'app-work-type-list',
    templateUrl: './work-type-list.component.html',
    styleUrls: [ './work-type-list.component.css' ],
} )
export class WorkTypeListComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();
    workTypes!: any;
    constructor (
        protected router: Router,
        private workService: WorkService,
        // public global: SimpleGlobal
    ) { }

    ngOnInit () {
        this.getAllWorkTypeList();
    }

    getAllWorkTypeList (): void {
        this.workService.getAllWorkTypeList().subscribe( ( response ) => {
            this.workTypes = response.data;
        } );
    }
}
