<div [formGroup]="formGroup">

    <label class="center-block">Select {{labelName}}

        <input [formControlName]="languageName" [typeahead]="options" [typeaheadMinLength]="0"
            typeaheadOptionField="name" placeholder="Select {{labelName}}" [typeaheadItemTemplate]="customItemTemplate"
            class="form-control" (typeaheadOnSelect)="onSelect($event)" (typeaheadNoResults)="onNoResults($event)">

    </label>

    <small class="text-danger" *ngIf="formGroup.get(languageId)?.errors">
        Required
    </small>

</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
