import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';

import { isUndefined } from '../../core/unit';
import { VerificationCommonEditComponent } from '../../core/components/verification-common-edit/verification-common-edit.component';
import { EmploymentVerification } from '../../core/models/employment-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { fileTypes } from './../../core/enums/file-types.enum';
import { UploadService } from './../../core/services/upload.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { EmploymentVerificationStatusType } from '../../core/enums/employment-verification-status-type.enum'
import { validateEmail } from 'src/app/common/validators/email-validator.directive';
import { Attachment } from 'src/app/core/models/attachment.model';
import Status from 'src/app/core/models/status.model';
import { AttachmentService } from 'src/app/core/services/attachment.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { DocumentsUploadService } from 'src/app/core/services/documents-upload.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-employment-form-item',
    templateUrl: './employment-form-item.component.html',
    styleUrls: [ './employment-form-item.component.css' ],
} )
export class EmploymentFormItemComponent extends VerificationCommonEditComponent implements OnInit {

    @Input() verification!: EmploymentVerification | any;
    @Input() isSubmitting!: boolean | any;
    @Input() employmentCompanies!: any;
    @Input() verificationFormGroup!: FormGroup;
    @Input() formArray!: FormArray;
    @Input() editFormGroup!: FormGroup | any;
    @Input() formGName!: FormGroup | any;
    @Input() current_date: any;
    @Input() created_at: any;

    companyContacts: any;
    attachments!: { [ key: string ]: any };
    documents: Document[] = [];
    uploader: FileUploader = new FileUploader( { url: '' } );
    verificationId!: number;

    @Output()
    onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private employmentService: EmploymentService,
        protected common: CommonService,
        public global: GlobalService,
        protected el: ElementRef,
        private commonService: CommonService,
        private uploadService: UploadService,
        private dialogService: DialogService,
        private documentService: DocumentsUploadService,
        private attachmentService: AttachmentService,
        private notificationService: ToastrService,
        protected cdr: ChangeDetectorRef,
    ) {
        super( commonService );
    }

    ngOnInit (): void {

        this.setFormPermission();

        this.applyDefaultValidations( this.editFormGroup );

        if ( !isUndefined( this.verificationFormGroup ) ) {
            this.attachments = this.editFormGroup.get( 'attachments' )?.value;

            this.verificationId = this.editFormGroup.get( 'id' )?.value;
        }
    }

    get statuses () { return this.global.fetchStatuses()?.[ 'employment' ]; }

    // get employmentStatuses () { return this.statuses[ 'employment' ] };

    get methodology () { return this.global.fettchMethodology(); }

    get employmentTypes () { return this.global.fetchEmploymentType(); }

    get verificationForm (): any {

        return this.editFormGroup;

    }

    get statusId (): number {

        return this.verificationForm.get( 'status_id' ).value;

    }

    get status () {

        if ( !this.statuses ) return null;
        return this.statuses.find( ( i: { id: number; } ) => i.id === this.statusId );

    }

    get statusType (): string | any {
        if ( !this.status ) return;

        return this.status.type;
    }

    get employmentType (): string | number {

        return this.verificationForm.get( 'employment_type_id' ).value;

    }

    get employmentTypeCurrent (): boolean {

        return this.employmentType == '0';

    }

    get unableToValidate (): boolean {

        return this.statusId === EmploymentVerificationStatusType.UnableToValidate;

    }

    get documentRequired (): boolean {

        return this.statusId === EmploymentVerificationStatusType.DocumentRequired;

    }

    // gets fo validation

    get isStatusRedOrGreen (): boolean {

        return (
            this.statusType === 'verified' || this.statusType === 'discrepancy'
        );

    }

    get commonFieldValidations (): boolean {

        return this.statusType === 'verified'
            || this.statusType === 'discrepancy'
            || this.unableToValidate;
    }

    get employmentTypeValidations (): boolean {

        return this.unableToValidate
            || this.isStatusRedOrGreen
            || this.statusId === EmploymentVerificationStatusType.PostJoining
            || this.statusId === EmploymentVerificationStatusType.DataEntryDone
            || this.statusId === EmploymentVerificationStatusType.VerificationRequestSent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail1Sent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail2Sent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail3Sent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail4Sent;

    }

    get clientRemarkValidations (): boolean {

        return this.statusType === 'discrepancy'
            || this.unableToValidate
            || this.documentRequired
            || this.statusId === EmploymentVerificationStatusType.VerificationStopped
            || this.statusId === EmploymentVerificationStatusType.ClientTerminated;

    }

    get qaValidations (): boolean {
        return this.statusId === EmploymentVerificationStatusType.VerificationRequestSent
            || this.statusId === EmploymentVerificationStatusType.RevertReceived;
    }

    get companyNameValidations (): boolean {
        return this.commonFieldValidations
            || this.statusId === EmploymentVerificationStatusType.DataEntryDone
            || this.statusId === EmploymentVerificationStatusType.VerificationRequestSent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail1Sent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail2Sent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail3Sent
            || this.statusId === EmploymentVerificationStatusType.FollowUpEmail4Sent;
    }

    get dateOfVerificationValidations (): boolean {
        return this.commonFieldValidations
            || this.statusId === EmploymentVerificationStatusType.VerificationStopped
            || this.statusId === EmploymentVerificationStatusType.ClientTerminated;
    }

    get inititationDateValidations (): boolean {

        return this.employmentTypeCurrent && (
            this.statusId === EmploymentVerificationStatusType.CroppingDone ||
            this.statusId === EmploymentVerificationStatusType.DataEntryDone ||
            this.statusId === EmploymentVerificationStatusType.DocumentRequired
        );
    }

    setFormPermission (): void {
        if (
            this.editFormGroup.get( 'completed_at' )?.value &&
            this.commonService.userLevelId() < 4
        ) {
            this.editFormGroup.disable();
        } else {
            this.editFormGroup.enable();
        }
    }

    onStatusChanged ( $event: TypeaheadMatch ): void {

        this.editFormGroup.get( 'status_id' )?.setValue( $event.item.id );

        // TODO: can be improved
        if ( this.employmentTypeValidations ) {

            this.editFormGroup.get( 'employment_type_id' )?.setValidators( [ Validators.required, this.currentEmploymentValidator() ] );
            this.editFormGroup.get( 'employment_type_id' )?.updateValueAndValidity();

        }

        if ( this.inititationDateValidations ) {

            this.editFormGroup.get( 'initiated_at' )?.setValidators( [ Validators.required ] );
            this.editFormGroup.get( 'initiated_at' )?.updateValueAndValidity();


        } else {

            this.editFormGroup.get( 'initiated_at' )?.clearValidators();
            this.editFormGroup.get( 'initiated_at' )?.updateValueAndValidity();

        }

    }


    onEmploymentCompanyChanged ( $event: TypeaheadMatch ) {

        this.editFormGroup.get( 'company_id' )?.setValue( $event.item.id );

    }

    onCountryChanged ( $event: TypeaheadMatch ) {

        this.editFormGroup.get( 'country_id' )?.setValue( $event.item.id );

    }

    upload ( typeOfFile: fileTypes | any ): void {
        const verificationId: number = this.editFormGroup.get( 'id' )?.value;

        //     // Locate the file element meant for the file upload.
        const inputEl: HTMLInputElement | any = this.el.nativeElement.querySelector(
            '#' + typeOfFile + '_' + verificationId
        );

        //     // Get the total amount of files attached to the file input.
        const fileCount: number = inputEl.files.length;

        this.checkFileSize( inputEl, fileCount );
        this.uploadService.hasExceededFileSize( inputEl );

        const formData: FormData = this.uploadService.addFilesToForm(
            verificationId,
            typeOfFile,
            inputEl
        );

        if ( !this.uploadService.maxFileError ) {
            // Check if the file count is greater than zero, to be sure a file was selected.
            if ( fileCount > 0 ) {
                formData.append( 'type', typeOfFile + '_' + verificationId );

                this.employmentService
                    .upload( verificationId, formData )
                    .subscribe(
                        ( result ) => {
                            this.getEmploymentVerification( verificationId );

                            this.onVerificationUpdated.emit( true );

                            this.commonService.notifications.success(
                                'File uploaded Successfully'
                            );
                        },
                        ( error ) => {
                            this.commonService.notifications.error(
                                'Unable to upload File'
                            );
                        }
                    );
            }
        }
        this.maxFileError = false;
    }

    getEmploymentVerification ( verificationId: number ): void {
        this.employmentService
            .findByEmployeeId( this.editFormGroup.get( 'employee_id' )?.value )
            .subscribe( ( employee ) => {
                employee.employment_verifications.filter( ( item ) => {
                    if ( item.id === verificationId ) {
                        this.verification = item;
                        if ( !isUndefined( this.verificationFormGroup ) ) {
                            this.getAttachments( item );
                        }
                    }
                } );
            } );
    }

    getAttachments ( verification: any ): void {
        this.attachments = verification.attachments;
    }

    onFileUploaded ( $e: any, verificationId: number ): void {
        this.getEmploymentVerification( verificationId );

        this.onVerificationUpdated.emit( true );
    }

    get paymentUrls (): Attachment[] | any {
        return this.attachments[ 'payment_urls' ].filter(
            ( item: { name: string; } ) => item.name === 'payment_url'
        );
    }

    get screenshots (): Attachment[] | any {
        return this.attachments[ 'screenshots' ].filter(
            ( item: { name: string; } ) => item.name === 'screenshot'
        );
    }

    get offerLetters (): Document[] | any {
        return this.attachments[ 'offers' ].filter(
            ( item: { name: string; } ) => item.name === 'offer'
        );
    }

    get payslips (): Document[] | any {
        return this.attachments[ 'payslips' ].filter(
            ( item: { name: string; } ) => item.name === 'payslip'
        );
    }

    get resignationLetters (): Document[] | any {
        return this.attachments[ 'relievings' ].filter(
            ( item: { name: string; } ) => item.name === 'relieving'
        );
    }

    get otherDocuments (): Document[] | any {
        return this.attachments[ 'documents' ].filter(
            ( item: { name: string; } ) => item.name === 'document'
        );
    }

    deleteEmploymentDocument ( document: any, verificationId: number ): void {
        const title: string = `Are you sure you want to delete ${ document.file_name }?`;

        this.dialogService.confirm( 'Are you sure?', title ).then(
            () => {
                this.documentService.delete( document ).subscribe( () => {
                    this.notificationService.success( 'File deleted' );

                    // filter and remove the deleted attachment from list
                    this.getEmploymentVerification( verificationId );

                    // fire case updated event when attachment is deleted
                    this.onVerificationUpdated.emit( true );
                } );
            },
            () => {
                // do nothing in case of no
            }
        );
    }

    deleteEmploymentAttachment ( attachment: any, verificationId: number ): void {
        const title: string = `Are you sure you want to delete ${ attachment.file_name }?`;

        this.dialogService.confirm( 'Are you sure?', title ).then(
            () => {
                this.attachmentService.delete( attachment ).subscribe( () => {
                    this.notificationService.success( 'File deleted' );

                    // filter and remove the deleted attachment from list
                    this.getEmploymentVerification( verificationId );

                    // fire case updated event when attachment is deleted
                    this.onVerificationUpdated.emit( true );
                } );
            },
            () => {
                // do nothing in case of no
            }
        );
    }

    onEmploymentTypeChanged ( $employmentTypeId: any ): void {

        this.cdr.detectChanges();

        if ( this.inititationDateValidations ) {

            this.editFormGroup.get( 'initiated_at' )?.setValidators( [ Validators.required ] );
            this.editFormGroup.get( 'initiated_at' )?.updateValueAndValidity();

        } else {

            this.editFormGroup.get( 'initiated_at' )?.clearValidators();
            this.editFormGroup.get( 'initiated_at' )?.updateValueAndValidity();

        }

    }

    getContacts ( employmentCompanyId?: number ) {
        if ( !employmentCompanyId ) {
            this.companyContacts = [];

            return;
        }

        this.employmentService.getContactDetails( employmentCompanyId ).subscribe(
            ( response ) => {
                this.companyContacts = response;
            },
            ( error ) => {
                this.commonService.notifications.error(
                    'Error',
                    'Something went wrong'
                );
            }
        );
    }


    /**
     * Set Default Validations to be applied on Page Load
     *
     * @param {AbstractControl} form
     */
    private applyDefaultValidations ( form: AbstractControl ): void {

        form.get( 'reference_email' )?.setValidators( [ validateEmail() ] );
        form.get( 'reference_email' )?.updateValueAndValidity();
        form.get( 'hr_email' )?.setValidators( [ validateEmail() ] );
        form.get( 'hr_email' )?.updateValueAndValidity();

    }


    /**
     * Validates that employment type is current
     * when post-joining status is selected
     *
     * @returns null if valid other returns error object
     */
    currentEmploymentValidator (): ValidatorFn {

        return ( control: AbstractControl ): ValidationErrors | null => {

            if ( this.statusId != EmploymentVerificationStatusType.PostJoining ) return null;

            // 0 =  employment type is current
            if ( control.value == 0 ) return null;

            return { forbidden: { value: control.value } };
        };
    }
}
