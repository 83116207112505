<div bsModal #permissionCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="permissionCreateForm" (ngSubmit)="createPermission()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Permission</h4>
                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">

                        <fieldset class="form-group">
                            <label class="center-block"><b>Subject</b> (Subject should be in lower case format. e.g.
                                icr)</label>
                            <input formControlName="subject" class="form-control form-control-lg" />
                        </fieldset>

                        <small class="text-danger"
                            [hidden]="permissionCreateForm.get('subject')?.valid || permissionCreateForm.get('subject')?.pristine">
                            Subject is required and should be smallcased single word
                        </small>

                        <fieldset class="form-group">
                            <label class="center-block"><b>Action</b> (Action should be in dot case format. e.g.
                                get.all.acts)</label>
                            <input formControlName="action" class="form-control form-control-lg" />
                        </fieldset>
                        <small class="text-danger"
                            [hidden]="permissionCreateForm.get('action')?.valid || permissionCreateForm.get('action')?.pristine">
                            Action is required and should be in dot case format
                        </small>

                        <fieldset class="form-group">
                            <label><b>Description</b></label>
                            <input formControlName="description" class="form-control form-control-lg" />
                        </fieldset>
                        <small class="text-danger"
                            [hidden]="permissionCreateForm.get('description')?.valid || permissionCreateForm.get('description')?.pristine">
                            Description is required
                        </small>

                    </fieldset>

                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!permissionCreateForm.valid">
                            Create
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>