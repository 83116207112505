import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { Params } from '@angular/router';
import { EmailStatusType } from '../models/email-status-type';
import { ModalService } from './modal.service';
import { EmailLogStatusTimeLineComponent } from '../../common/email-log-status-time-line/email-log-status-time-line.component';
import { EmailLogStatusTimeLineModalInitialState } from '../models/email-log-status-time-line-modal-initial-state';
@Injectable( {
    providedIn: 'root'
} )
export class EmailLogService {

    constructor ( private api: ApiService, private modalService: ModalService ) {
    }

    // Fetch Filtered logs
    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'email-logs', params );
    }


    getStatuses (): { id: any, name: string }[] {

        return Object.keys( EmailStatusType ).map( key => {

            const label = this.getStatusLabel( EmailStatusType[ key as keyof typeof EmailStatusType ] );;

            return {
                id: label,
                name: label,
            }

        } );

    }

    getStatusLabel ( status: EmailStatusType ): string {

        switch ( status ) {

            case EmailStatusType.DELIVERED:
                return 'DELIVERED';

            case EmailStatusType.FAILED:
                return 'FAILED';

            case EmailStatusType.ACCEPTED:
                return 'ACCEPTED';

            case EmailStatusType.REJECTED:
                return 'REJECTED';

        }

    }

    getStatusColor ( status: EmailStatusType ): string {

        switch ( status ) {

            case EmailStatusType.DELIVERED:
                return 'label-success';

            case EmailStatusType.FAILED:
                return 'label-danger';

            case EmailStatusType.ACCEPTED:
                return 'label-secondary';

            case EmailStatusType.REJECTED:
                return 'label-danger';
        }

    }

    showEmailStatusTimeLineModal ( initialState: EmailLogStatusTimeLineModalInitialState ) {

        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg'
        };

        return this.modalService.showModal( EmailLogStatusTimeLineComponent, config );
    }
}
