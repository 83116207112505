import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { VoterService } from '../../core/services/voter.service';

@Component( {
    selector: 'app-voter-search-modal',
    templateUrl: './voter-search-modal.component.html',
    styleUrls: [ './voter-search-modal.component.css' ],
} )
export class VoterSearchModalComponent {

    @ViewChild( 'voterSearchModal', { static: false } )
    public voterSearchModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();


    isSubmitting = false;
    voterSearchForm!: FormGroup;

    constructor (
        private fb: FormBuilder,
        private voterService: VoterService,
        private commonService: CommonService
    ) {

        this.initForm();
    }

    initForm () {
        this.voterSearchForm = this.fb.group( {
            voter_number: [ '', [ Validators.required ] ],
        } );
    }

    show () {
        this.voterSearchModal.show();
    }

    hide () {
        this.initForm();
        this.voterSearchModal.hide();
    }

    search (): void {
        this.isSubmitting = true;

        this.voterService.search( this.voterSearchForm.value ).subscribe( response => {
            this.commonService.notifications.success( 'Request Sent Successfully' );
            this.isSubmitting = false;
            this.hide();
            this.onSearch.emit( true );


        },
            ( error ) => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            } );
    }
}
