import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

@Injectable()
export class PanService {

    constructor ( private api: ApiService ) {
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'pan', params );
    }

    resend ( id: string ) {
        return this.api.get( 'pan/' + id + '/resend' );
    }

    getDataForExcel ( params: string | HttpParams ): Observable<any[]> {
        return this.api.get( 'pan/export-excel?' + params );
    }

    resendAll ( filterParam: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'pan/resend-all', filterParam );
    }

    importExcel ( data: Object | undefined ) {
        return this.api.post( 'pan/import-excel', data );
    }

    search ( data: Object | undefined ) {
        return this.api.post( 'pan/search', data );
    }

    getStats ( params: Params | undefined ) {
        return this.api.get( 'pan/stats', params );
    }

}
