<div bsModal #gstDetailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" style="max-height: 100%; overflow-y: auto;">
            <div class="modal-header">
                <h4 class="modal-title pull-left">GST details</h4>

            </div>
            <div class="modal-body">

                <div *ngFor="let info of details">


                    <div class="row">
                        <div class="col-md-4">
                            <ul class="list-unstyled">
                                <li><strong>Number</strong> : {{info.number}}</li>
                                <li><strong>Status</strong> : {{info.status}}</li>
                                <li><strong>Date of Registration</strong> : {{info.date_of_registration}}</li>
                                <li><strong>Date of Cancellation</strong> : {{info.date_of_cancellation}}</li>
                                <li><strong>Aadhaar Validation</strong> : {{info.aadhaar_validation}}</li>
                                <li><strong>E-kyc</strong> : {{info.e_kyc}}</li>
                            </ul>

                        </div>
                        <div class="col-md-4">

                            <ul class="list-unstyled">
                                <li><strong>Centre Jurisdiction</strong> : {{info.center_jurisdiction}}</li>
                                <li><strong>State Jurisdiction</strong> : {{info.state_jurisdiction}}</li>
                                <li><strong>Constitution of Business</strong> : {{info.constitution_of_business}}</li>
                                <li><strong>Taxpayer Type</strong> : {{info.tax_payer_type}}</li>
                                <li><strong>Principal Place Of Business</strong> : {{info.principal_place_of_business}}
                                </li>
                            </ul>


                        </div>

                        <div class="col-md-4">

                            <ul class="list-unstyled">
                                <li><strong>Legal Name of Business</strong> : {{info.business_name}}</li>
                                <li><strong>Trade Name of Business</strong> : {{info.trade_name}}</li>
                                <li><strong>Field Visit Conducted</strong> : {{info.is_field_visit_conducted}}</li>
                                <li><strong>Nature of Business Activities</strong> :
                                    {{info.nature_of_business_activities}}</li>
                                <li><strong>Nature of Core Business Activities</strong> :
                                    {{info.nature_of_core_business_activities}}</li>
                            </ul>


                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-4 fixed-box">
                            <strong>Fling status</strong>:
                            <pre>{{ info.filing_status | json }}</pre>
                        </div>

                        <div class="col-md-4 fixed-box">
                            <strong>Good Details</strong>:
                            <pre>{{ info.good_details | json }}</pre>
                        </div>

                        <div class="col-md-4 fixed-box">
                            <strong>Service Details</strong>:
                            <pre>{{ info.service_details | json }}</pre>
                        </div>


                    </div>


                </div>

            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>