import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmploymentVerification } from '../../core/models/employment-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { VerificationList } from 'src/app/core/components/verification-list.component';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-employment-analysis',
    templateUrl: './employment-analysis.component.html',
    styleUrls: [ './employment-analysis.component.css' ],
} )
export class EmploymentAnalysisComponent extends VerificationList implements OnInit {


    loading!: boolean;
    override verifications: EmploymentVerification[] = [];
    isSubmitting: boolean = false;
    attachments: any[] = [];

    constructor (
        protected override verificationService: EmploymentService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        public override confirmationModalService: ConfirmationModalService,

    ) {
        super( confirmationModalService );
    }


    ngOnInit (): void {
        this.verification = 'employment';
        this.subscribeToQueryChanges();

    }

    filterResults (): void {
        this.filterParams.set( 'employment_type_not_in', '2' );
        this.verificationService.filter( this.filterParams ).subscribe( response => {

            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    populateExtraDetails ( employmentVerifications: EmploymentVerification[] ): void {

        for ( const index in employmentVerifications ) {

            const verification: EmploymentVerification = employmentVerifications[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

}
