import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { Employee } from '../models/employee.model';
import { HttpParams } from '@angular/common/http';
import { PccVerification } from '../models/pcc-verification.model';
import { Params } from '@angular/router';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { ModalService } from './modal.service';

@Injectable( {
    providedIn: 'root'
} )
export class PccService {

    constructor (
        private api: ApiService,
        private modalService: ModalService
    ) {
    }

    getAll (): Observable<Pagination> {
        return this.api.get( 'pcc/' );
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'pcc', params );
    }

    find ( id: number ): Observable<PccVerification> {
        return this.api.get( 'pcc/' + id );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'pcc/' + id );
    }

    create ( id: number ): Observable<Employee> {
        return this.api.get( 'pcc/' + id + '/add' );
    }

    update ( id: string | number, data: Object | undefined, filters: string ): Observable<PccVerification> {
        return this.api.put( 'pcc/' + id + '?' + filters, data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'pcc/' + id );
    }

    getAssignedWorkList ( filterParams: Params ): Observable<any[]> {
        return this.api.get( 'pcc/assigned-work', filterParams );
    }

    getDocumentTypes (): Observable<any[]> {
        return this.api.get( 'pcc/document-types' );
    }

    getAddressDetails ( id: string, typeOfAddress: string ): Observable<any[]> {
        return this.api.get( 'pcc/' + id + '/get-address-details?type=' + typeOfAddress );
    }

    importExcel ( values: Object ): Observable<any[]> {
        return this.api.post( 'pcc/import-excel', values );
    }

    getLetterheadSource (): Observable<any> {
        return this.api.get( 'pcc/letterhead-source' );
    }

    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }

}

