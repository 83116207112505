import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-client-crm-params-modal',
    templateUrl: './client-crm-params-modal.component.html',
} )
export class ClientCrmParamsModalComponent {

    @ViewChild( 'clientCrmParamsModal', { static: false } )

    public clientCrmParamsModal!: ModalDirective;

    public params!: any;

    show ( params: any ): void {
        this.params = params;
        this.clientCrmParamsModal.show();
    }


    hide (): void {
        this.clientCrmParamsModal.hide();
    }

}
