import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { IdentityVerification } from '../../core/models/identity-verification.model';
import { CommonService } from '../../core/services/common.service';
import { IdentityService } from '../../core/services/identity.service';
import { DownloadService } from 'src/app/core/services/download.service';
import { VerificationList } from 'src/app/core/components/verification-list.component';
import { GlobalService } from 'src/app/core/services/global.service';
import { NewDownloadModalComponent } from 'src/app/download/new-download-modal/new-download-modal.component';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-identity-list',
    templateUrl: './identity-list.component.html',
} )


export class IdentityListComponent extends VerificationList implements OnInit {


    loading!: boolean;
    override verifications: IdentityVerification[] = [];
    isSubmitting: boolean = false;
    crmUrl!: string;
    attachments: any = [];
    vendorDate: any;
    statsVisible: boolean = false;

    @ViewChild( 'editModal', { static: false } )
    public editModal!: ModalDirective;

    constructor (
        protected override verificationService: IdentityService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        private bsModalService: BsModalService,
        private downloadService: DownloadService,
        public override confirmationModalService: ConfirmationModalService,
    ) {
        super( confirmationModalService );
    }

    get users () {
        return this.global.fetchUsers();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {

        this.crmUrl = environment.crm_url;

        this.verification = 'identity';
        this.subscribeToQueryChanges();
        this.getVendorsList();
    }

    filterResults (): void {
        this.verificationService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );

        } );
    }

    showIdentity ( identity: IdentityVerification ): void {

        this.router.navigate( [ 'identity', identity.employee_id, 'edit' ], this.navigationExtras );
    }

    exportPaymentUrlExcel (): void {

        this.verificationService.getPaymentUrlExcelData( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Identity Payment Url Data' );
            } );
    }

    exportRemarksExcel (): void {
        this.verificationService.getRemarksExcelData( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Identity Remarks Data' );
            } );
    }

    populateExtraDetails ( identityVerification: any ): void {

        for ( const index in identityVerification ) {

            const verification: any = identityVerification[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    changeVendorDate (): void {

        this.commonService.updateSentToVendorOn( this.verification, this.ids, this.vendorDate ).subscribe(
            {
                next: ( response: any ) => {
                    this.filterResults();
                    this.commonService.notifications.success( 'Vendor Dates Updated', response.message );

                },
                error: ( erros ) => {
                    this.commonService.notifications.error( erros );
                }
            }
        );

    }

    onChangeVendorDateButtonClicked (): void {

        const options: ConfirmationModalOptions = { text: 'You want to change Sent to Vendor date to ' + this.vendorDate, };

        this.confirmationModalService.confirm(
            options,
            () => this.changeVendorDate(),
            () => this.editModal.hide()
        );

    }

    hide (): void {
        this.editModal.hide();
    }

    showStats (): void {
        this.statsVisible = !this.statsVisible;
    }

    // overriding super class method
    // TODO:: move to super class after implementing this in all verificaions
    override exportExcel () {

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/' + this.verification + '/downloadExcel',
            fileName: this.verification,
            method: 'get',
            postParams: null,
        };

        this.bsModalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )
    }

    get letterLink (): string {

        return environment.api_url + 'identity/print/letters?' + this.filterParams;

    }

    get letterStampLink (): string {

        return environment.api_url + 'identity/print/letters?stamp=1&letterhead=1&' + this.filterParams;

    }

    get envelopeLink (): string {

        return environment.api_url + 'identity/print/envelopes?' + this.filterParams;
    }

    get licenceLink (): string {

        return environment.api_url + 'identity/print/licences?' + this.filterParams;

    }

    downloadBackendExcel (): void {

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/' + this.verification + '/downloadExcel',
            fileName: this.verification,
            method: 'get',
            postParams: null,
            auth_token: localStorage.getItem( 'token' )
        };

        this.bsModalService.show( NewDownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )

    }
}
