<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div *ngIf="companies" class="form-group col-md-2">
                        <label class="center-block"> Company</label>
                        <app-multi-select [options]="companies" [placeholder]="'Select Companies'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block"> Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Filter by status</label>
                        <app-multi-select [options]="statuses" [placeholder]="'Select Statuses'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div *ngIf="states" class="form-group col-md-2">
                        <label class="center-block"> State</label>
                        <input formControlName="state_name" [typeahead]="states" [typeaheadMinLength]="0"
                            typeaheadOptionField="name" placeholder="Select State"
                            (typeaheadOnSelect)="OnStateSelect($event)" [typeaheadItemTemplate]="customItemTemplate"
                            class="form-control">
                    </div>

                    <div *ngIf="districts" class="form-group col-md-2">
                        <label class="center-block"> District</label>
                        <input formControlName="district_name"
                            [typeahead]="districts | filterByStateId:filterFormGroup.controls['state_id'].value"
                            [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select District"
                            class="form-control" [typeaheadItemTemplate]="customItemTemplate"
                            (typeaheadOnSelect)="OnDistrictSelect($event)">
                    </div>

                </div>
                <div class="row">

                    <div class="col-md-2 form-group">
                        <label class="center-block"> Creation Date</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>

                    <div class="col-md-2 form-group" [hidden]="verification !== 'identity'">
                        <label class="center-block">Doc Type</label>
                        <select class="form-control" formControlName="type_of_doc">
                            <option *ngFor="let doc of identityTypes" [value]="doc.id">
                                {{doc.name}}
                            </option>
                        </select>
                    </div>

                </div>

                <!-- Button Row -->
                <div class="row">

                    <div class="col-md-2 form-group">
                        <label class="center-block">QA Present</label>
                        <select class="form-control" formControlName="qa_present">
                            <option value="all">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <small class="text-danger" *ngIf="filterFormGroup.controls['qa_present'].errors">
                            Required
                        </small>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">QA Required</label>
                        <select class="form-control" formControlName="qa_required">
                            <option value="all">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <small class="text-danger" *ngIf="filterFormGroup.controls['qa_required'].errors">
                            Required
                        </small>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">QA Done</label>
                        <select class="form-control" formControlName="qa_done">
                            <option value="all">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div *ngIf="workTypes" class="col-md-2 form-group">
                        <label class="center-block">Filter by Work Type</label>
                        <select class="form-control" formControlName="work_type">
                            <option *ngFor="let option of workTypes[verification]" [value]="option.id">
                                {{option.name}}</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="control-label">Assigned Before</label>
                        <input class="form-control" type="time" formControlName="assigned_before">
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="control-label">Assignment Count</label>
                        <input class="form-control" type="number" formControlName="assignment_count" value="3">
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">-</label>
                        <button class="btn btn-primary btn-block">
                            Get Work
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">-</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset Filters
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>