import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component( {
    selector: 'app-copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
} )
export class CopyToClipboardComponent implements OnInit {

    @Input()
    value!: string;
    @Input()
    labelName!: string;

    constructor ( private commonService: CommonService ) { }

    ngOnInit () {
    }

    copyToClipBoaard (): void {

        if ( this.value ) {

            navigator.clipboard.writeText( this.value );

            const notificatioMessage = this.labelName + " Copy successfully";
            this.commonService.notifications.info( notificatioMessage );

        }
        else {

            this.commonService.notifications.warning( 'Nothing to copy' );
        }


    }




}
