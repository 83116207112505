import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PermissionsService } from 'src/app/core/services/permissions.service';

@Component( {
    selector: 'app-show-permission',
    templateUrl: './show-permission.component.html',
    styleUrls: [ './show-permission.component.css' ],
} )
export class ShowPermissionComponent {

    isSubmitting = false;
    permissions!: any[];

    @ViewChild( 'userPermissionsModal', { static: false } )
    public userPermissionsModal!: ModalDirective;

    @Output()
    onUserEdited = new EventEmitter<boolean>();

    constructor ( private permissionService: PermissionsService,
    ) { }

    show ( user_id: number ) {
        this.permissionService.getUserAssignedPermissions( user_id ).subscribe(
            response => {
                return this.permissions = response;
            }
        );
        this.userPermissionsModal.show();
    }

    hide () {
        this.userPermissionsModal.hide();
    }

}
