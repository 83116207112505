import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';
import { Authority } from '../../core/models/authority.model';
import { Pagination } from '../../core/models/pagination.model';
import { AuthorityService } from '../../core/services/authority.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { District } from 'src/app/core/models/district.model';
import { State } from 'src/app/core/models/state.model';

@Component( {
    selector: 'app-identity-authority-list',
    templateUrl: './authority-list.component.html',
    styleUrls: [ './authority-list.component.css' ],
} )
export class AuthorityListComponent implements OnInit, OnDestroy {

    loading!: boolean;
    authorities!: Authority[];
    isSubmitting: boolean = false;
    pagination: Pagination = new Pagination();
    filtersSubscription!: Subscription;

    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    constructor (
        private authorityService: AuthorityService,
        private fb: FormBuilder,
        public global: GlobalService,
        private filterService: FilterService,
    ) {
        this.initForm();

        this.updateFormFilter();

        this.filterResults( this.filterFormGroup.value );
    }

    get states (): State[] { return this.global.fetchStates(); }

    get districts (): District[] { return this.global.fetchDistricts(); }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );

        } );
    }


    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            type: [ '' ],
            state_id: [ '' ],
            district_id: [ '' ],
        } );
    }


    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }


    filterResults ( params: Params ): void {

        this.authorityService.getAllAuthorities( params ).subscribe(
            response => this.pagination = response
        );
    }


    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }

    showPage ( page: any ): void {

        this.filterService.filterByParams( { page } );
    }


    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    onModalUpdated (): void {

        this.filterResults( this.filterFormGroup.value );
    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }

}
