import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { SubInstitute } from 'src/app/core/models/sub-institute.model';
import { SubInstituteService } from 'src/app/core/services/sub-institute.service';

@Component( {
    selector: 'app-sub-institute-typeahead',
    templateUrl: './sub-institute-typeahead.component.html',
} )
export class SubInstituteTypeaheadComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() control: string = 'sub_institute';
    @Input() label: string = 'Sub Institute';
    @Input() required: boolean = false;

    constructor ( private subInstituteService: SubInstituteService ) { }

    get subInstituteName (): string { return this.control + '_name' }

    get subInstituteId (): string { return this.control + '_id' }

    get subInstituteIdValue (): string { return this.formGroup.get( this.subInstituteId )?.value }

    get subInstituteNameValue (): string { return this.formGroup.get( this.subInstituteName )?.value }

    get instituteIdValue (): any { return this.formGroup.get( 'institute_id' )?.value }

    get options (): SubInstitute[] {
        return this.subInstituteService.subInstitutes;
    }

    ngOnInit (): void {
        return this.subInstituteService.populateCache();
    }

    onSelect ( $event: TypeaheadMatch ): void {

        this.formGroup.get( this.subInstituteId )?.setValue( $event.item.id );
        this.formGroup.get( this.subInstituteName )?.setValue( $event.item.name );

    }

    onNoResults ( $event: any ): void {

        if ( $event ) {

            this.formGroup.get( this.subInstituteId )?.setValue( null );
            // this.formGroup.get( this.subInstituteName )?.setValue( null );

        }
    }


}
