import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { AddressVerification } from 'src/app/core/models/address-verification.model';
import { Client } from 'src/app/core/models/client.model';
import { EducationVerification } from 'src/app/core/models/education-verification.model';
import { Employee } from 'src/app/core/models/employee.model';
import { EmploymentVerification } from 'src/app/core/models/employment-verification.model';
import { IcrVerification } from 'src/app/core/models/icr-verification.model';
import { IdentityVerification } from 'src/app/core/models/identity-verification.model';
import { ReferenceVerification } from 'src/app/core/models/reference-verification.model';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { LotService } from 'src/app/core/services/lot.service';
import { ToastrService } from 'ngx-toastr';
import { validateEmailArray } from 'src/app/common/validators/multiple-emails-validator.directive';
import { validateEmailsWithComma } from 'src/app/common/validators/comma-sep-email-validator.directive';

@Component( {
    selector: 'app-insuff-email-modal',
    templateUrl: './insuff-email-modal.component.html',
    styleUrls: [ './insuff-email-modal.component.css' ],
} )
export class InsuffEmailModalComponent implements OnInit {

    @Input()
    employee!: Employee;

    contents: string = '';

    insuffVerifications: any = [];

    sending = false;

    clients: Client[] = [];

    emailForm!: FormGroup;

    loadingClients = false;

    employeeEmails: any = [];

    constructor (

        public bsModalRef: BsModalRef,
        public employeeService: EmployeeService,
        public notifications: ToastrService,
        public lotService: LotService,
        public fb: FormBuilder,
    ) {
    }

    ngOnInit () {

        this.emailForm = this.fb.group( {
            to: [ [], [ Validators.required, validateEmailArray ] ],
            cc: [],
            extraEmails: [ '', validateEmailsWithComma ],
        } )

        if ( this.employee ) {

            this.composeEmailTemplate();

            this.getRecruiterEmails();

            this.getEmployeeEmail();
        }

    }

    getEmployeeEmail (): void {
        if ( this.employee.email ) {
            this.employeeEmails.push( {
                id: this.employee.email,
                name: this.employee.email,
            } );
        }

        if ( this.employee.official_email ) {
            this.employeeEmails.push( {
                id: this.employee.official_email,
                name: this.employee.official_email,
            } );
        }
    }

    getRecruiterEmails (): void {

        this.loadingClients = true;

        this.lotService.getClients( this.employee.lot_id ).subscribe( ( clients: Client[] ) => {

            this.clients = clients;

            this.loadingClients = false;

        }, () => {

            this.loadingClients = false;

        } )

    }

    composeEmailTemplate () {

        this.contents += '<ul>';

        this.appendInsuffIdentity();

        this.appendInsuffAddress();

        this.appendInsuffIcr();

        this.appendInsuffEducation();

        this.appendInsuffEmployment();

        this.appendInsuffReference();

        this.appendInsuffGlobal();

        this.appendInsuffPcc();

        this.appendInsuffDocument();

        this.contents += '</ul>';
    }

    appendInsuffIdentity () {

        const insuffVerifications = this.employee?.identity_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications?.length == 0 ) return;

        this.contents += '<li>Identity</li>'

        this.contents += '<ul>';

        insuffVerifications?.forEach( ( verification: IdentityVerification ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Identity' } );

            const type = IdentityVerification.typeToString( verification.type_of_doc );

            this.contents += `<li>${ type }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';
    }

    appendInsuffAddress () {

        const insuffVerifications = this.employee.address_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>Address</li>';

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: AddressVerification ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Address' } );

            const type = AddressVerification.typeToString( verification.type_of_address );

            this.contents += `<li>${ type }: ${ verification.client_remarks }`;

            this.contents += '<ul>';

            this.contents += '<li>House Owned or Rented (if rented, please share owner name):</li>';

            this.contents += '<li>Duration of stay at the Address:</li>';

            this.contents += '<li>Door / House / Building Number:</li>';

            this.contents += '<li>Street name / Block name / Street / Road name:</li>';

            this.contents += '<li>Area / Colony / Village Name:</li>';

            this.contents += '<li>Post Office Name:</li>';

            this.contents += '<li>Tehsil / Taluka / Mandal / Block:</li>';

            this.contents += '<li>Police station:</li>';

            this.contents += '<li>Nearest landmark:</li>';

            this.contents += '<li>City and District:</li>';

            this.contents += '<li>Area Pin Code:</li>';

            this.contents += '<li>Alternate Contact details at address:  (Name, Mobile Number & Relationship)</li>';

            this.contents += '</ul>';

        } );

        this.contents += '</ul>';
    }


    appendInsuffIcr () {

        const insuffVerifications = this.employee.icr_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>ICR</li>'

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: any ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'ICR' } );

            this.contents += `<li>${ verification.address }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';

    }

    appendInsuffEducation () {

        const insuffVerifications = this.employee.education_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>Education</li>'

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: EducationVerification ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Education' } );

            this.contents += `<li>${ verification.course } - ${ verification.college_name }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';
    }

    appendInsuffEmployment () {

        const insuffVerifications = this.employee.employment_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>Employment</li>'

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: EmploymentVerification ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Employment' } );

            const companyName = verification.company ? verification.company.name : verification.company_name;

            this.contents += `<li>${ companyName }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';
    }

    appendInsuffReference () {

        const insuffVerifications = this.employee.reference_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>Reference</li>'

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: any ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Reference' } );

            this.contents += `<li>${ verification.address.full_address }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';
    }

    appendInsuffGlobal (): void {


        const insuffVerifications = this.employee.global_db_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>Global Db Verifications</li>'

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: any ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Global Db Verifications' } );

            const fullAddress = verification.address ? verification.address.full_address : '';

            this.contents += `<li>${ fullAddress }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';
    }

    appendInsuffPcc () {

        const insuffVerifications = this.employee.pcc_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>Pcc Verifications</li>'

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: any ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Pcc Verifications' } );

            const fullAddress = verification.address ? verification.address.full_address : '';

            this.contents += `<li>${ fullAddress }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';

    }

    appendInsuffDocument () {

        const insuffVerifications = this.employee.document_verifications.filter( ( v: any ) => v.status.insuff == 1 );

        if ( insuffVerifications.length == 0 ) return;

        this.contents += '<li>Document Verifications</li>'

        this.contents += '<ul>';

        insuffVerifications.forEach( ( verification: any ) => {

            this.insuffVerifications.push( { ...verification, verification_type: 'Document Verifications' } );

            this.contents += `<li>${ verification.type }`;

            this.contents += '<ul>';

            this.contents += `<li>${ verification.client_remarks }</li>`;

            this.contents += '</li></ul>';

        } );

        this.contents += '</ul>';

    }

    sendMail (): void {

        this.sending = true;

        const cc = this.emailForm.value.cc;

        const extraEmails = this.emailForm.value.extraEmails.split( ',' ).map( ( item: string ) => item.trim() );

        let ccEmails = [], finalCcEmails: any[] = [];

        // if cc emails exist then merge/concat with extra emails
        if ( cc ) {

            ccEmails = this.clients.filter( client => cc?.includes( client.id ) ).map( client => client.email );

            finalCcEmails = finalCcEmails.concat( ccEmails );

        }

        extraEmails.map( ( item: any ) => {
            if ( !item ) return
            finalCcEmails.push( item );
        } );

        const emailData = {
            to: this.emailForm.get( 'to' )?.value,
            cc: finalCcEmails,
            contents: this.contents,
        };

        this.employeeService.sendInsuffMail( this.employee[ 'employee_id' ], emailData ).subscribe( {
            next: ( response: any ) => {
                if ( response ) {

                    this.notifications.success( 'Email Sent', 'Email Sent' );

                    this.bsModalRef.hide();
                }
            },
            error: ( errors: any ) => {

                this.sending = false;
                this.notifications.error( 'Error In Sending', errors.error.message );
            },
        } );


    }

}

