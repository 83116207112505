import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { EmailLogService } from 'src/app/core/services/email-log.service';
import { Subscription } from 'rxjs';
import { Pagination } from '../../core/models/pagination.model';
import { FilterService } from './../../core/services/filter.service';
import { EmailStatusLog } from 'src/app/core/models/email-status-log';
import { EmailLogStatusTimeLineModalInitialState } from 'src/app/core/models/email-log-status-time-line-modal-initial-state';
import { EmailStatusType } from 'src/app/core/models/email-status-type';


@Component( {
    selector: 'app-email-logs',
    templateUrl: './email-logs.component.html',
    styleUrls: [ './email-logs.component.css' ],
} )
export class EmailLogsListComponent implements OnInit, OnDestroy {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;
    filtersSubscription!: Subscription;

    constructor (
        private emailLogService: EmailLogService,
        private filterService: FilterService,
    ) { }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );

        } );
    }

    // Filter results
    filterResults ( params: Params ): void {

        this.emailLogService.filter( params ).subscribe( response => {
            this.pagination = response;
        } );

    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }

    refresh (): void {
        this.filterService.filter();
    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }

    showEmailLogStatusTimeLineModal ( emailStatusLog: EmailStatusLog[] ): void {

        const initialState: EmailLogStatusTimeLineModalInitialState = {
            statusLogs: emailStatusLog
        }

        this.emailLogService.showEmailStatusTimeLineModal( initialState );
    }

    getStatusColor ( status: EmailStatusType ): string {

        return this.emailLogService.getStatusColor( status )

    }

}

