import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/core/models/pagination.model';
import { CommonService } from 'src/app/core/services/common.service';
import { CreditReportService } from 'src/app/core/services/credit-report.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { CreditReportLog } from '../credit-report-log.model';

@Component( {
    selector: 'app-credit-report-list',
    templateUrl: './credit-report-list.component.html',
    styleUrls: [ './credit-report-list.component.css' ]
} )
export class CreditReportListComponent implements OnInit, OnDestroy {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;

    filtersSubscription!: Subscription;

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private filterService: FilterService,
        private commonService: CommonService,
        private creditReportService: CreditReportService
    ) { }

    ngOnInit () {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ](
            {
                next: ( params: HttpParams ) => {

                    this.filterParams = params;

                    this.filterByParams();
                }
            }
        );
    }

    filterByParams (): void {

        this.creditReportService.filter( this.filterParams ).subscribe( response => {

            this.pagination = response;
        } );

    }

    showPage ( page: number ): void {

        this.filterService.filterByPage( page );
    }

    resend ( id: number ) {
        return this.creditReportService.resend( id ).subscribe( ( response ) => {
            if ( response ) {
                this.commonService.notifications.success( 'Resent Successfully' );
            } else {
                this.commonService.notifications.error( 'Failed to Resend' );
            }
        } );
    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }

    getColor ( log: CreditReportLog ): string {

        if ( log.success == null ) return 'label-warning';
        if ( log.success == 0 ) return 'label-danger';
        if ( log.has_details != 1 ) return 'label-danger';
        if ( log.has_details == 1 ) return 'label-success';

        return 'label-warning';
    }

    getLabel ( log: CreditReportLog ): string {

        if ( log.success == null ) return 'Pending';
        if ( log.success == 0 ) return 'Failed';
        if ( log.has_details != 1 ) return 'Not Found';
        if ( log.has_details == 1 ) return 'Found';

        return 'Pending';

    }
}
