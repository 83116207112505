<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th style="width:20%;">User Details</th>
                        <th style="width:20%;">Company</th>
                        <th style="width:30%;">Extra Details</th>
                        <th style="width:10%;">Last Login </th>
                        <th style="width:10%;">IP </th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let user of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i=index">
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <a [appCan]="'superadmin'" class="txt-link" routerLink="/clients/{{user.id}}/edit">
                                        <strong>{{ user.name }}</strong>
                                    </a>
                                </li>
                                <li>
                                    <a [appCan]="'superadmin'" class="btn-link"
                                        (click)="onResetPasswordButtonClicked(user)">Reset
                                        Password</a>
                                </li>
                                <li>
                                    <a class="txt-link" routerLink="/clients/{{user.id}}/history">
                                        History
                                    </a>
                                </li>
                                <li>{{ user.created_at}}</li>
                                <li>{{ user.updated_at}}</li>
                            </ul>
                        </td>
                        <td>
                            {{user.company_name}}
                        </td>


                        <td>
                            <ul class="list-unstyled">
                                <li>Email: {{ user.email }}</li>
                                <li>Number: {{ user.mobile_number}}</li>
                                <li>Designation: {{ user.designation}}</li>
                                <li>Status: {{ user.status_name}}</li>
                            </ul>
                        </td>
                        <td>
                            {{user.last_login_at | date:'MMMM d, y, EEEE, hh:mm a' }}
                        </td>
                        <td>
                            {{user.ip}}
                        </td>
                        <td [appCan]="'admin'">

                            <button [ngClass]="user.status ? 'btn btn-danger btn-xs' : 'btn btn-success btn-xs' "
                                (click)="toggleStatus(user)">

                                {{ getActivationStatusLabel(user)}}

                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
