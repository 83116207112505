<div class="wrapper-md">

    <form [formGroup]="createForm" (ngSubmit)="onSubmit()">

        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Create Form
                    </div>
                    <div class="panel-body">
                        <fieldset [disabled]="isSubmitting">

                            <fieldset class="form-group">
                                <label class="center-block"> Company</label>
                                <app-multi-select [options]="companies" [placeholder]="'Select Company'"
                                    [controlName]="'company_id'" [formGName]="createForm" [multipleValue]="false"
                                    (onDropDownValueChanged)="onCompanySelected()"></app-multi-select>
                            </fieldset>

                            <fieldset class="form-group">
                                <label class="center-block"> Lot</label>
                                <app-multi-select [options]="lots" [placeholder]="'Select Lot'" [controlName]="'lot_id'"
                                    [formGName]="createForm" [multipleValue]="false"
                                    (onDropDownValueChanged)="onLotChanged()"></app-multi-select>
                            </fieldset>
                            <fieldset class="form-group">
                                <label>Form Name</label>
                                <input formControlName="name" class="form-control form-control-lg" />
                            </fieldset>

                            <fieldset class="form-group">
                                <label class="center-block"> Form Type</label>
                                <select class="form-control" formControlName="type" (change)="onFormTypeChanged()">
                                    <option [value]="0">Invite</option>
                                    <option [value]="1">Digital Address</option>
                                </select>
                            </fieldset>

                        </fieldset>


                    </div>
                </div>

                <div class="panel-default">
                    <app-client-forms-v2 [settingFormGroup]="formSetting" [formType]="formType"
                        [verifications]="verifications"></app-client-forms-v2>
                </div>

                <div class="row">

                    <div class="col-md-12 center">

                        <button class="btn btn-lg btn-primary btn-block"
                            [disabled]="createForm.invalid || isSubmitting">
                            Create
                        </button>
                    </div>

                </div>

            </div>

            <div class="col-md-6">

                <div class="panel panel-default">
                    <div class="panel-body">

                        <fieldset class="form-group">
                            <label>Email Subject </label>
                            <input formControlName="email_subject" class="form-control form-control-lg" />
                        </fieldset>



                        <fieldset class="form-group">
                            <label>Email Body </label> <span class="pull-right"><a
                                    (click)="renderSampleEmail.render()">Preview sample email</a></span>
                            <ckeditor formControlName="email_body"></ckeditor>
                        </fieldset>
                    </div>
                </div>

                <app-preview-form-invite-email [emailContent]="emailBody.value" [companyId]="companyId.value"
                    #renderSampleEmail>
                </app-preview-form-invite-email>
            </div>
        </div>

    </form>
</div>
