import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { WorkService } from '../core/services/work.service';
import { DocumentListComponent } from './document-list/document-list.component';
import { DocumentService } from '../core/services/document.service';
import { DocumentEditComponent } from './document-edit/document-edit.component';
import { DocumentAssignedWorkListComponent } from './document-assigned-work-list/document-assigned-work-list.component';

@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,

    ],
    declarations: [
        DocumentListComponent,
        DocumentEditComponent,
        DocumentAssignedWorkListComponent,

    ],
    providers: [ DocumentService, WorkService ],
} )
export class DocumentModule {
}
