<ng-container *ngIf="verification">

    <div class="row bg-light lter b-b wrapper-md" *ngIf="verification">

        <div class="col-md-8">
            <h1 class="m-n font-thin h3">{{ verification.employee.name }} Verification</h1>
            <ul class="breadcrumb insta-breadcrumb">
                <li><a routerLink="/home">Home</a></li>
                <li><a [routerLink]="['/companies', verification.company.id]">{{ verification.company.name }}</a>
                </li>
                <li><a [routerLink]="['/lots', verification.lot.id]">{{ verification.lot.name }}</a></li>
                <li><a [routerLink]="['/employees', verification.employee.id,'edit']">
                        {{ verification.employee.track_id }}</a>
                </li>
                <li>Created on : {{ verification.created_at }}</li>
            </ul>
        </div>

        <div class="col-md-4 text-right text-center-xs">
            <div class="btn-group">
                <button [disabled]="formSubmitting" (click)="onExitClicked()" class="btn btn-default btn-addon">
                    Exit
                </button>
                <button [disabled]="!verificationFormGroup.valid || formSubmitting" (click)="onDoneClicked()"
                    class="btn btn-default btn-addon">
                    Done
                </button>

            </div>


        </div>

    </div>

    <form [formGroup]="verificationFormGroup" (keydown.enter)="$event.preventDefault()">
        <fieldset [disabled]="formSubmitting">

            <!--Top Section Of the Form-->
            <div *ngIf="verification.employee" class="row bg-light lter b-b wrapper-md">


            </div>

            <div class="wrapper-md">
                <div class="panel">
                    <div class="panel-body">
                        <div class="row">
                            <div class="form-group col-md-2">
                                <label class="center-block">Employee Name</label>
                                <input formControlName="name" ngModel="{{verification.employee.name}}"
                                    class="form-control" readonly>
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Client Employee Id</label>
                                <input formControlName="client_employee_id"
                                    ngModel="{{verification.employee.client_employee_id}}" class="form-control">
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Mobile Number</label>
                                <input formControlName="mobile_number" ngModel="{{verification.employee.mobile_number}}"
                                    class="form-control">
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Father Name</label>
                                <input formControlName="father_name" ngModel="{{verification.employee.father_name}}"
                                    class="form-control" readonly>
                            </div>
                            <div class="form-group col-md-2">
                                <label class="center-block">Date Of Birth</label>
                                <input formControlName="dob" class="form-control"
                                    ngModel="{{verification.employee.dob}}" readonly>
                            </div>


                        </div>
                    </div>
                </div>

                <div>
                    <div>

                        <div class="row">
                            <div class="col-md-8">
                                <!--Front Url Panel-->
                                <div class="panel" *ngIf="verification.front_url" style="overflow: hidden;">
                                    <div class="row">
                                        <div class="col-md-12" style="position: inherit">
                                            <button type="button" (click)="zoomInImage('img_front_url')"
                                                class="btn btn-primary"> +
                                            </button>
                                            <button type="button" (click)="zoomOutImage('img_front_url')"
                                                class="btn btn-primary"> -
                                            </button>

                                        </div>

                                        <div [style.max-height]="getMaxHeight(verification)"
                                            class="col-md-12 identity-edit-image-frame" id="div_front_url">
                                            <img imageZoom style="width: 100%;height: 60%"
                                                [src]="verification.front_url" id="img_front_url" />
                                        </div>
                                    </div>
                                </div>


                                <!--Back Url Panel-->
                                <div>
                                    <div class="panel" *ngIf="verification.back_url" style="overflow: hidden;">
                                        <div class="row">
                                            <div class="col-md-12" style="position: inherit">
                                                <button type="button" (click)="zoomInImage('img_back_url')"
                                                    class="btn btn-primary"> +
                                                </button>
                                                <button type="button" (click)="zoomOutImage('img_back_url')"
                                                    class="btn btn-primary"> -
                                                </button>

                                            </div>

                                            <div [style.max-height]="getMaxHeight(verification)"
                                                class="col-md-12 identity-edit-image-frame" id="div_back_url"
                                                *ngIf="verification.back_url">
                                                <img imageZoom style="width: 100%;height: 60%"
                                                    [src]="verification.back_url" id="img_back_url" />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                            <div class="col-md-4">
                                <div class="panel panel-default">
                                    <div class="panel-body">

                                        <div class="row">


                                            <div class="col-md-6 form-group">
                                                <label class="center-block">Document Type</label>
                                                <input formControlName="type_name" [typeahead]="documentTypeList"
                                                    [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                    placeholder="Document Type"
                                                    (typeaheadOnSelect)="OnTypeSelected($event)"
                                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control">

                                            </div>
                                            <div class="col-md-6 form-group">

                                                <label class="center-block">Verification Status</label>
                                                <select class="form-control" formControlName="status_id">
                                                    <option *ngFor="let status of statuses" [value]="status.id">{{
                                                        status.name
                                                        }}</option>
                                                </select>

                                            </div>

                                            <div class="col-md-6 form-group">
                                                <label class="center-block">Doc Number
                                                </label>
                                                <small class="text-danger"
                                                    *ngIf="verificationFormGroup.get('number')?.errors">
                                                    <!-- {{doc_number_error_message}} -->
                                                </small>
                                                <input class="form-control" formControlName="number">
                                            </div>

                                            <div class="col-md-6 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value==5">
                                                <label class="center-block">File Number</label>
                                                <input class="form-control" formControlName="file_number">
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <label class="center-block">Name</label>
                                                <small class="text-danger"
                                                    *ngIf="verificationFormGroup.get('name_as_per_id')?.errors">
                                                    Only alphabets allowed
                                                </small>
                                                <input class="form-control" formControlName="name_as_per_id"
                                                    [readonly]="false">
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="center-block">Father Name</label>
                                                <small class="text-danger"
                                                    *ngIf="verificationFormGroup.get('father_name_as_per_id')?.errors">
                                                    Only alphabets allowed
                                                </small>
                                                <input class="form-control" formControlName="father_name_as_per_id">
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="center-block">DOB (DD-MM-YYYY)</label>
                                                <small class="text-danger"
                                                    *ngIf="verificationFormGroup.get('dob_as_per_id')?.errors">
                                                    Invalid Dob Format
                                                </small>
                                                <input class="form-control" formControlName="dob_as_per_id">
                                            </div>
                                            <div class="col-md-12 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value!=2">
                                                <label class="center-block">Address</label>
                                                <textarea class="form-control"
                                                    formControlName="address_as_per_id"></textarea>
                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value!=2">
                                                <label class="center-block"> State</label>
                                                <input formControlName="state_name" [typeahead]="states"
                                                    [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                    placeholder="Select State"
                                                    (typeaheadOnSelect)="OnStateSelect($event)"
                                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control">


                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value!=2">
                                                <label class="center-block"> District</label>
                                                <input formControlName="district_name"
                                                    [typeahead]="districts | filterByStateId:verificationFormGroup.controls['state_id'].value"
                                                    [typeaheadMinLength]="0" typeaheadOptionField="name"
                                                    placeholder="Select District" class="form-control"
                                                    [typeaheadItemTemplate]="customItemTemplate"
                                                    (typeaheadOnSelect)="OnDistrictSelect($event)">


                                            </div>
                                            <div class="col-md-12 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value!=2">
                                                <label class="center-block">Pincode</label>
                                                <small class="text-danger"
                                                    *ngIf="verificationFormGroup.get('pincode')?.errors">
                                                    Must contain 6 digits only
                                                </small>
                                                <input class="form-control" formControlName="pincode"
                                                    onkeydown="scrollTo(0,0)">
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="center-block">Date of Issue</label>
                                                <input type="date" class="form-control" formControlName="date_of_issue">
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label class="center-block">Date Of Expiry</label>
                                                <input type="date" class="form-control"
                                                    formControlName="date_of_expiry">
                                            </div>


                                            <div class="col-md-6 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value==4"
                                                id="lmv_first_issue_date">
                                                <label class="center-block">NT Issue Date</label>
                                                <input type="date" class="form-control"
                                                    formControlName="lmv_first_issue_date">
                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value==4"
                                                id="lmv_last_renewal_date">
                                                <label class="center-block">NT EXPIRY Date</label>
                                                <input type="date" class="form-control"
                                                    formControlName="lmv_last_renewal_date">
                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value==4"
                                                id="tr_first_issue_date">
                                                <label class="center-block">TR Issue Date</label>
                                                <input type="date" class="form-control"
                                                    formControlName="tr_first_issue_date">
                                            </div>
                                            <div class="col-md-6 form-group"
                                                *ngIf="verificationFormGroup.get('type_id')?.value==4"
                                                id="tr_last_renewal_date">
                                                <label class="center-block">TR Expiry Date</label>
                                                <input type="date" class="form-control"
                                                    formControlName="tr_last_renewal_date">
                                            </div>

                                            <div class="col-md-12 form-group">
                                                <label class="center-block">Client Remarks:
                                                    <textarea rows=2 class="form-control"
                                                        formControlName="client_remarks">
                                    </textarea>
                                                </label>
                                                <small class="text-danger"
                                                    *ngIf="verificationFormGroup.get('client_remarks')?.errors">
                                                    Required
                                                </small>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</ng-container>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>