import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';

import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-licence-filters',
    templateUrl: './licence-filters.component.html',
    styleUrls: [ './licence-filters.component.css' ],
} )
export class LicenceFiltersComponent implements OnInit {
    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    filterFormGroup!: FormGroup;

    clients!: any[];

    constructor (
        private router: Router,

        private fb: FormBuilder,
        private commonService: CommonService,
    ) {
        this.initForm();
    }

    ngOnInit () {
        this.setDefault();

        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

        this.filterResults();
    }

    private setDefault () {

        // TODO:: fix this
        this.filterParams.keys().forEach( key => {

            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    initForm (): void {

        const myDate = new Date();

        const previousMonth = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );

        previousMonth.setDate( myDate.getDate() + 1 );

        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            requested_licence_number: 'all',
            client_api_id: 'all',
            via: 'all',
            created_before: 'all',
            created_after: previousMonth.toJSON().slice( 0, 10 ),
            sarathi_status: 'all',
            parivahan_sarathi_status: 'all',
            parivahan_application_status: 'all',
            state_status: 'all',
            parivahan_simple_status: 'all',
            sarathi_simple_status: 'all',
        } );
    }

    filterResults (): void {

        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };

        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    onSearch () {
        this.filterResults();
    }

}
