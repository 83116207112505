import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { ValidationService } from 'src/app/core/services/validation.service';

@Component( {
    selector: 'app-user-reset-password',
    templateUrl: './user-reset-password.component.html',
} )
export class UserResetPasswordComponent implements OnInit {

    title: any;
    isSubmitting: any;
    resetPasswordForm!: FormGroup;
    token!: string;

    get password (): FormControl {
        return this.resetPasswordForm.get( 'password' ) as FormControl
    }

    get confirmPassword (): FormControl {
        return this.resetPasswordForm.get( 'confirm_password' ) as FormControl
    }

    constructor (
        private fb: FormBuilder,
        private commonService: CommonService,
        private router: Router,
        private route: ActivatedRoute,
        private jwtService: JwtService,
    ) { }
    ngOnInit (): void {

        this.checkForToken();

        this.route.params.subscribe( ( params: Params ) => {
            this.token = params[ 'token' ];
        } );

        this.inItForm()
    }

    checkForToken () {

        const token = this.jwtService.getToken();

        if ( token ) {
            this.router.navigateByUrl( 'home' );
            this.commonService.setPageTitle( "Insta Client" );
        }

        this.commonService.setPageTitle( "Reset Password" );
    }

    inItForm () {

        this.resetPasswordForm = this.fb.group( {
            password: [ null, [ Validators.required, ValidationService.hasLowercase, ValidationService.hasNumber, ValidationService.hasSpecialCharacter, ValidationService.hasUppercase, Validators.minLength( 6 ) ] ],
            confirm_password: [ null, [ Validators.required, this.comparePassword( 'password', 'confirm_password' ) ] ],
            token: [ this.token ]
        } );

    }

    submitForm () {

        this.commonService.auth.resetPassword( this.resetPasswordForm.value ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( "Password Has Updated Successfully", response.message );
                this.router.navigateByUrl( 'login' );
                this.commonService.setPageTitle( "Insta Client" );
            },
            error: ( error: any ) => {
            }
        } )
    }

    comparePassword ( fieldFirst: string, fieldSecond: string ): ValidatorFn {

        return ( control: AbstractControl ): ValidationErrors | null => {

            const isNotSame = this.resetPasswordForm?.get( fieldFirst )?.value != this.resetPasswordForm?.get( fieldSecond )?.value;

            return isNotSame ? { confirmPassword: { value: control.value } } : null;

        };

    }

}
