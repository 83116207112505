import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';

@Component( {
    selector: 'app-blacklisted-filters',
    templateUrl: './blacklisted-filters.component.html',
    styleUrls: [ './blacklisted-filters.component.css' ],
} )
export class BlacklistedFiltersComponent implements OnInit {

    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    clients!: any[];

    constructor (
        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService,
    ) { }

    ngOnInit (): void {

        this.initForm();

        this.updateFormFilter();

        this.filterResults();

        this.getAllClientApis();
    }


    getAllClientApis (): void {

        let params: HttpParams = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }


    initForm (): void {

        const myDate: Date = new Date();

        const previousMonth: Date = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );

        previousMonth.setDate( myDate.getDate() + 1 );

        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            rc_number: 'all',
            client_api_id: 'all',
            via: 'all',
            created_before: 'all',
            created_after: previousMonth.toJSON().slice( 0, 10 ),
            blacklist_status: 'all',
            vahan_status: 'all',
            parivahan_status: 'all',
        } );
    }

    filterResults (): void {

        this.filterService.filterByParams( this.filterFormGroup.value );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();

        this.filterResults();
    }

    onSearch (): void {

        this.filterService.filter();
    }


    private updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );
    }
}
