import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { WebhookService } from 'src/app/core/services/webhook.service';

@Component( {
    selector: 'app-webhook-log-payload',
    templateUrl: './webhook-log-payload.component.html',
    styleUrls: [ './webhook-log-payload.component.css' ],
} )
export class WebhookLogPayloadComponent {

    isSubmitting = false;
    payload: any;

    @ViewChild( 'getPayloadModal', { static: false } )
    public getPayloadModal!: ModalDirective;

    constructor (
        private webhookService: WebhookService,
    ) { }


    show ( id: number ) {

        this.webhookService.getPayload( id ).subscribe(

            response => {
                this.payload = response;
            }

        );

        this.getPayloadModal.show();
    }

    hide () {

        this.getPayloadModal.hide();

    }

}
