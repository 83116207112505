<ng-container *ngIf="identityVerification">

    <div class="row bg-light lter b-b wrapper-md" *ngIf="identityVerification">

        <div class="col-md-8">
            <h1 class="m-n font-thin h3">{{ identityVerification.employee.name }} Verification</h1>
            <ul class="breadcrumb insta-breadcrumb">
                <li><a routerLink="/home">Home</a></li>
                <li><a [routerLink]="['/companies', identityVerification.company.id]">{{
                        identityVerification.company.name }}</a>
                </li>
                <li><a [routerLink]="['/lots', identityVerification.lot.id]">{{ identityVerification.lot.name }}</a>
                </li>
                <li><a [routerLink]="['/employees', identityVerification.employee.id,'edit']">
                        {{ identityVerification.employee.track_id }}</a>
                </li>
                <li>Created on : {{ identityVerification.created_at }}</li>
            </ul>
        </div>

        <div class="col-md-4 text-right text-center-xs">
            <div class="btn-group">
                <button [disabled]="formSubmitting" (click)="onExitClicked()" class="btn btn-default btn-addon">
                    Exit
                </button>
                <button [disabled]="!validationForm.valid || formSubmitting" (click)="onDoneClicked()"
                    class="btn btn-default btn-addon">
                    Done
                </button>

            </div>


        </div>

    </div>

    <div class="wrapper-md">
        <div class="panel">
            <div class="panel-body">

                <div class="row">
                    <form class="col-md-12 " [formGroup]="validationForm">
                        <div class="row">
                            <div class="col-md-3 form-group ">
                                <label for="client_remarks" class="center-block">Client Remarks</label>

                                <textarea class="form-control" name="client_remarks" id="client_remarks"
                                    formControlName="client_remarks" type="text"> </textarea>
                                <small class="text-danger" *ngIf="validationForm.get('client_remarks')?.errors">
                                    Required
                                </small>

                            </div>

                            <div class="col-md-3 form-group">
                                <label class="center-block">QA Required
                                    <select class="form-control" formControlName="qa_required">
                                        <option [value]="0">
                                            NO
                                        </option>
                                        <option [value]="1">
                                            YES
                                        </option>
                                    </select>
                                </label>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="center-block">QA done
                                    <select class="form-control" formControlName="qa_done">
                                        <option [value]="0">
                                            NO
                                        </option>
                                        <option [value]="1">
                                            YES
                                        </option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="validationForm.get('qa_done')?.errors">
                                    Required
                                </small>
                            </div>

                            <div class="col-md-3 form-group pull-right">
                                <label class="center-block">Verification Status</label>
                                <select class="form-control" formControlName="verification_status">
                                    <option *ngFor="let status of statuses" value="{{status.id}}">
                                        {{status.name}}
                                    </option>
                                </select>

                            </div>

                        </div>

                    </form>
                </div>

                <div class="row">

                    <div class="col-md-6">
                        <div class="row">

                            <div class="col-md-12 identity-edit-image-frame">
                                <img style="width:100%; height: 45%" class="img-responsive" imageZoom
                                    [src]="identityVerification.front_url" />
                            </div>

                            <div class="col-md-12 identity-edit-image-frame">
                                <img style="width:100%; height: 45%" class="img-responsive" imageZoom
                                    [src]="identityVerification.back_url" />
                            </div>

                        </div>
                    </div>

                    <div class="col-md-6">
                        <img class="img-responsive" style="width:100%; height: 45%" imageZoom
                            src="{{identityVerification.proof_url}}" alt="">
                    </div>

                </div>


            </div>

        </div>

    </div>

</ng-container>