<div class="wrapper-md">
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Login
                </div>

                <div class="panel-body">
                    <app-errors [errors]="errors"></app-errors>
                    <form [formGroup]="authForm" (ngSubmit)="submitForm()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Email</label>
                                <input id="email" formControlName="email" class="form-control" type="email" />
                            </fieldset>
                            <fieldset class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="">
                                            Password
                                        </label>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <a [routerLink]="'/forget-password'">
                                            Forget Password
                                        </a>
                                    </div>
                                </div>
                                <input id="password" formControlName="password" class="form-control" type="password" />
                            </fieldset>
                            <button id="login" class="btn btn-lg btn-primary btn-block" [disabled]="!authForm.valid"
                                type="submit">
                                {{ title }}
                            </button>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
