import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Pagination } from 'src/app/core/models/pagination.model';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalDbLogService } from 'src/app/core/services/global-db-log.service';

@Component({
  selector: 'app-global-db-log-list',
  templateUrl: './global-db-log-list.component.html',
  styleUrls: ['./global-db-log-list.component.css']
})
export class GlobalDbLogListComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;

    constructor (
        private route: ActivatedRoute,
        private router: Router,

        private commonService: CommonService,
        private globalDbLogService: GlobalDbLogService
    ) { }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );


    }

    filterResults (): void {

        this.globalDbLogService.filter( this.filterParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );

    }

    showPage ( page: number ): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    resend ( id: number ) {
        return this.globalDbLogService.resend( id ).subscribe( ( response ) => {
            if ( response ) {
                this.commonService.notifications.success( 'Resend Successfully!' );
            } else {
                this.commonService.notifications.error( 'Faild to Resend' );
            }
        } );
    }
}
