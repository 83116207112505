import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { CompanyRole } from '../models/company-role.model';
import { Pagination } from '../models/pagination.model';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class CompanyRoleService {
    [ x: string ]: any;

    constructor ( private api: ApiService ) { }

    getPaginated (): Observable<Pagination> {
        return this.api.get( 'company-roles' );
    }

    getAll (): Observable<CompanyRole[]> {
        return this.api.get( 'company-roles/list' );
    }

    find ( roleId: number ): Observable<CompanyRole> {
        return this.api.get( 'company-roles/' + roleId );
    }

    create ( values: object ): Observable<any> {
        return this.api.post( 'company-roles', values );
    }

    update ( roleId: number, values: object ): Observable<any> {
        return this.api.put( 'company-roles/' + roleId, values );
    }

    delete ( roleId: number ): Observable<any> {
        return this.api.delete( 'company-roles/' + roleId );
    }

    getAllDeleted ( _params: Params | null = null ): Observable<Pagination> {
        return this.api.get( 'company-roles/soft-deletes' );
    }

    restore ( values: object ): Observable<any> {
        return this.api.post( 'company-roles/restore', values );
    }

    permanentDelete ( roleId: number ): Observable<any> {
        return this.api.delete( 'company-roles/' + roleId + '/permanent-delete' );
    }

    filter ( params: Params ): Observable<Pagination> {
        return this.api.get( 'company-roles', params );
    }

    assignRole ( values: object ) {
        return this.api.post( 'company-roles/assign', values );
    }

    unassignRole ( values: object ) {
        return this.api.post( 'company-roles/unassign', values );
    }

    getCompanyAssignRoleUsers ( companyId: number ) {
        return this.api.get( 'company/' + companyId + '/users/list' );
    }

    getUsersByCompanyRole ( roleId: number ) {
        return this.api.get( 'company-roles/' + roleId + '/users' );
    }
}
