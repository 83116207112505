<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Generate PCC letterheads</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-2">
                            <label class="center-block">
                                <strong>Select Source: </strong>
                            </label>
                        </div>
                        <div class="col-md-8 form-group">
                            <select class="form-control" formControlName="via" required>
                                <option value="">Select Source</option>
                                <option *ngFor="let company of sources | keyvalue" [value]="company.key">
                                    {{ company.value }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2">
                            <label class="center-block">
                                <strong>Upload Excel: </strong>
                            </label>
                        </div>
                        <div class="col-md-8 form-group">
                            <input type="file" [accept]="acceptedFileType" (change)="onFileChange($event)"
                                class="form-control" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-2">

                        </div>
                        <div class="col-md-8 form-group">
                            <div class="pull-right">
                                <button class="btn btn-primary" id="send-invite-button" (click)="onSubmit()">Upload
                                    Excel
                                </button>
                            </div>
                        </div>
                    </div>



                </div>

                <div class="col-md-6">
                    <div class="row">

                        <div class="col-md-8 form-group">
                            <div>
                                <label class="center-block">
                                    <strong>Mandatory Fields: </strong>
                                </label>
                            </div>
                            <ul>
                                <li>TRACK ID</li>
                                <li>NAME</li>
                                <li>FATHERS NAME</li>
                                <li>IDENTITY PROOF</li>
                                <li>MOBILE NUMBER</li>
                                <li>ADDRESS</li>
                                <li>AUTHORITY NAME</li>
                                <li>POLICE STATION</li>
                                <li>DISTRICT NAME</li>
                                <li>STATE NAME</li>
                            </ul>
                        </div>
                    </div>
                </div>

            </form>


        </div>
    </div>
</div>