<form [formGroup]="verificationFormGroup" (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">
        <div *ngIf="employee && employee.id" class="row bg-light lter b-b wrapper-md">
            <app-verification-edit-header [verification]="'pcc'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>

            <div class="col-md-6">
                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createPcc()">
                    <i class="fa fa-plus"></i>
                    Add Pcc Verification
                </a>
            </div>
        </div>


        <div class="wrapper-md">
            <div class="row">
                <div class="col-md-3">

                    <!--Remarks-->
                    <app-employee-remarks *ngIf="employee" [remarks]="employee.remarks"></app-employee-remarks>

                    <!--Employee Details-->
                    <app-employee-side-block [refreshEmployee]="refreshEmployee"></app-employee-side-block>
                    <app-employee-basic-details [employee]="employee">
                    </app-employee-basic-details>

                    <!--Identity Verification-->
                    <div *ngIf="employee">
                        <div class="panel panel-default"
                            *ngFor="let identity of employee.identity_verifications; let i=index">
                            <div class="panel-heading">
                                ID Verification #{{i+1}}
                            </div>

                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <label> Status : {{identity.status_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Name : {{identity.name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Father Name : {{identity.father_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Date of Birth : {{identity.dob}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Type Of Document : {{identity.type_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Address : {{identity.address}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> State : {{identity.state_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> District : {{identity.district_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Pincode : {{identity.pincode}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Address Verification Details-->
                    <div *ngIf="employee">
                        <div class="panel panel-default"
                            *ngFor="let address of employee.address_verifications; let i=index">
                            <div class="panel-heading">
                                {{address.type}}
                            </div>

                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <label> Status : {{address.status_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Full Address : {{address.full_address}}</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-md-9">
                    <app-image-crop></app-image-crop>
                    <div formArrayName="formArray">
                        <div *ngFor="let verification of formArray.controls; let i=index" [formGroupName]="i">

                            <app-copy-verification-address-details #copyAddressFromSelectedVerification
                                [mainFormGroup]="verificationFormGroup" [duration_of_stay]="duration_of_stay"
                                [police_station]="police_station" [verificationIndex]="i"
                                [verificationShortTitle]="'pcc'" [verificationId]="verification.get('id')?.value">
                            </app-copy-verification-address-details>

                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <div class="panel-control" style="float: right;">
                                        <a (click)="copyAddressFromSelectedVerification.show()" id="copy-address">
                                            <strong>Copy Address</strong>
                                        </a>
                                    </div>
                                    <h3 class="panel-title">Pcc Verification #{{i+1}}</h3>
                                </div>

                                <div class="panel-body">
                                    <div class="row">
                                        <div class="form-group col-md-3">
                                            <label class="center-block">Qa Required: </label>
                                            <select class="form-control" formControlName="qa_required">
                                                <option [value]="0"> NO </option>
                                                <option [value]="1"> YES </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="center-block">Qa Done: </label>
                                            <select class="form-control" formControlName="qa_done">
                                                <option [value]="0"> NO </option>
                                                <option [value]="1"> YES </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">

                                        <div *ngIf="statuses" class="col-md-3 form-group">

                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'status'" [labelName]="'Status'"
                                                [options]="statuses['pcc']">
                                            </app-typeahead-dropdown>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Eligibility: </label>
                                            <select class="form-control" formControlName="eligible">
                                                <option value="0">Not Eligible</option>
                                                <option value="1">Eligible</option>
                                            </select>

                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class="center-block">Address Type </label>
                                            <select class="form-control" formControlName="address_type" required>
                                                <option value="current">Current</option>
                                                <option value="permanent">Permanent</option>
                                                <option value="both">Both</option>
                                            </select>

                                            <small class="text-danger" *ngIf="verification.get('address_type')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="center-block">Caste</label>
                                            <input class="form-control" formControlName="caste">

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Address </label>
                                            <textarea rows=5 class="form-control" formControlName="address"
                                                id="address_{{verification.get('id')?.value}}">
                                                </textarea>
                                            <small class="text-danger" *ngIf="verification.get('address')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="col-md-3 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'state'" [labelName]="'State'"
                                                [options]="states" id="state_{{verification.get('id')?.value}}">
                                            </app-typeahead-dropdown>
                                            <small class="text-danger" *ngIf="verification.get('state_id')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'district'" [labelName]="'District'"
                                                [options]="districts | filterByStateId:verification.get('state_id')?.value"
                                                id="district_{{verification.get('id')?.value}}">
                                            </app-typeahead-dropdown>
                                            <small class=" text-danger
                                            " *ngIf="verification.get('district_id')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">LandMark </label>
                                            <input class="form-control" formControlName="landmark"
                                                id="landmark_{{verification.get('id')?.value}}">

                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Pincode</label>
                                            <input class="form-control" formControlName="pincode"
                                                id="pincode_{{verification.get('id')?.value}}">

                                            <small class="text-danger" *ngIf="verification.get('pincode')?.errors">
                                                Required
                                            </small>
                                        </div>

                                    </div>


                                    <div class="row">

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Duration Of Stay </label>
                                            <input class="form-control" formControlName="duration_of_stay">

                                            <small class="text-danger"
                                                *ngIf="verification.get('duration_of_stay')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Police Station </label>
                                            <input class="form-control" formControlName="police_station">

                                            <small class="text-danger"
                                                *ngIf="verification.get('police_station')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Identification Mark</label>
                                            <input class="form-control" formControlName="identification_mark">
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Pcc Application number </label>
                                            <input class="form-control" formControlName="pcc_application_number">

                                            <small class="text-danger"
                                                *ngIf="verification.get('pcc_application_number')?.errors">
                                                Required
                                            </small>
                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Payment Reference number </label>
                                            <input class="form-control" formControlName="payment_reference_number">
                                            <small class="text-danger"
                                                *ngIf="verification.get('payment_reference_number')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Official Fees</label>
                                            <input class="form-control" formControlName="official_fees" type="number">
                                            <small class="text-danger"
                                                *ngIf="verification.get('official_fees')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Payment mode</label>

                                            <select class="form-control" formControlName="payment_mode_id"
                                                placeholder="Select one">
                                                <option *ngFor="let payment_mode of payment_modes"
                                                    [value]="payment_mode.id">
                                                    {{payment_mode.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="verification.get('payment_mode_id')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Email Address </label>
                                            <input class="form-control" formControlName="email_address">

                                        </div>

                                    </div>
                                    <div class="row">

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Login Id </label>
                                            <input class="form-control" formControlName="login_id">

                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Password </label>
                                            <input class="form-control" formControlName="password">

                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Application Date </label>
                                            <input class="form-control" type="date" formControlName="applied_at">

                                            <small class="text-danger" *ngIf="verification.get('applied_at')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="center-block">Report/Verification Date </label>
                                            <input class="form-control" type="date"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}"
                                                formControlName="completed_at">

                                            <small class="text-danger" *ngIf="verification.get('completed_at')?.errors">
                                                Required
                                            </small>
                                        </div>

                                    </div>

                                    <div class="row">


                                        <div class="form-group col-md-3">
                                            <label class="center-block">India Post ID-Application </label>
                                            <input class="form-control" formControlName="india_post_id_application">
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="center-block">India Post ID-Report </label>
                                            <input class="form-control" formControlName="india_post_id_report">
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label>Document Types</label>

                                            <app-multi-select [options]="documentTypes"
                                                [placeholder]="'Select Document Types'" [controlName]="'document_types'"
                                                [formGName]="verification"></app-multi-select>
                                            <small class="text-danger"
                                                *ngIf="verification.get('document_types')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="form group col-md-3">
                                            <app-authority-typeahead [formGroup]="verification"
                                                [districtId]="verification.get('district_id')?.value">
                                            </app-authority-typeahead>

                                            <app-authority-create-modal (onAuthorityCreated)="onModalUpdated()"
                                                #authorityCreateModal></app-authority-create-modal>
                                            <a class="btn m-b-xs btn-sm" (click)="authorityCreateModal.show()">
                                                <i class="fa fa-plus"></i>
                                                Create Authority
                                            </a>
                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="form-group col-md-12">
                                            <label class="center-block">Client Remarks</label>
                                            <textarea rows=1 class="form-control" formControlName="client_remarks">
                                                </textarea>

                                            <small class="text-danger"
                                                *ngIf="verification.get('client_remarks')?.errors">
                                                Required
                                            </small>
                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="form-group col-md-3">
                                            <label class="center-block"> Vendor </label>
                                            <select class="form-control" formControlName="vendor_id">
                                                <option *ngFor="let vendor of vendors" [value]="vendor.id">{{
                                                    vendor.name }}</option>
                                            </select>
                                            <small class="text-danger" *ngIf="verification.get('vendor_id')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label class="center-block">Sent To Vendor On </label>
                                            <input type="date" class="form-control" formControlName="sent_to_vendor_on">
                                            <small class="text-danger"
                                                *ngIf="verification.get('sent_to_vendor_on')?.errors">
                                                Invalid
                                            </small>
                                        </div>

                                    </div>

                                    <!-- <div class="row">

                                        <div class="col-md-3 form-group" *ngIf="verification.get('insuff_raised_at')">
                                            <label class="center-block">Insufficiency Raised On</label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('insuff_raised_at')?.errors">
                                                Invalid
                                            </small>
                                            <input readonly type="date" class="form-control"
                                                formControlName="insuff_raised_at" max="{{current_date}}">
                                        </div>

                                        <div class="col-md-3 form-group" *ngIf="verification.get('insuff_cleared_at')">
                                            <label class="center-block">Insufficiency Cleared On</label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('insuff_cleared_at')?.errors">
                                                Invalid
                                            </small>
                                            <input readonly type="date" class="form-control"
                                                formControlName="insuff_cleared_at" max="{{current_date}}">
                                        </div>
                                    </div> -->

                                    <div class="row">
                                        <div class="col-md-3">

                                            <label>Payment Screenshot</label>

                                            <input type="file" id="payment_screenshot_{{verification.value.id}}"
                                                ng2FileSelect accept="image/*" [uploader]="uploader"
                                                class="form-control"
                                                (change)="upload(verification,'payment_screenshot')" />

                                        </div>

                                        <div class="col-md-3">

                                            <label>Application Screenshot</label>

                                            <input type="file" id="application_screenshot_{{verification.value.id}}"
                                                multiple class="form-control" accept="image/*"
                                                (change)="upload(verification,'application_screenshot')" />
                                        </div>

                                        <div class="col-md-3">

                                            <label>Pcc Report</label>

                                            <input type="file" id="pcc_report_{{verification.value.id}}" ng2FileSelect
                                                accept="image/*" [uploader]="uploader" class="form-control"
                                                (change)="upload(verification,'pcc_report')" />
                                        </div>

                                        <div class="col-md-3">

                                            <label>Attachments
                                                <input type="file" id="attachment_{{verification.value.id}}"
                                                    class="form-control" multiple accept="image/*"
                                                    (change)="upload(verification,'attachment')" />
                                            </label>
                                        </div>

                                    </div>

                                    <div class="row" *ngIf="attachments[i]">

                                        <div class="col-md-3" *ngIf="attachments[i]['payment_urls']; else elseDiv">
                                            <div class="row" *ngFor="let attachment of attachments[i]['payment_urls']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3"
                                            *ngIf="attachments[i]['application_screenshot']; else elseDiv">
                                            <div class="row"
                                                *ngFor="let attachment of attachments[i]['application_screenshot']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-md-3"
                                            *ngIf="verification.get('pcc_report')?.value; else elseDiv">
                                            <div class="row">

                                                <div class="col-md-12">
                                                    <a href="{{verification.get('pcc_report')?.value}}"
                                                        class="btn-link">
                                                        Pcc Report Url
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3" *ngIf="attachments[i]['attachments']; else elseDiv">
                                            <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-template #elseDiv>
                                            <div class="col-md-3">
                                                No File Attached
                                            </div>
                                        </ng-template>

                                    </div>


                                    <div class="row">
                                        <div class="col-md-3">

                                            <label>Signature</label>

                                            <input type="file" id="pcc_signature_{{verification.value.id}}"
                                                ng2FileSelect accept="image/*" [uploader]="uploader"
                                                class="form-control" (change)="upload(verification,'pcc_signature')" />
                                        </div>

                                        <div class="col-md-3">
                                            <label>Photo</label>
                                            <input type="file" id="pcc_photo_{{verification.value.id}}" ng2FileSelect
                                                accept="image/*" [uploader]="uploader" class="form-control"
                                                (change)="upload(verification,'pcc_photo')" />
                                        </div>


                                        <div class="col-md-3">
                                            <label>Birth Certificate
                                                <input appUploadDocument (uploaded)="onFileUploaded($event,i)"
                                                    type="file" multiple class="form-control"
                                                    [documentableId]="verification?.value.id" documentableType="pcc"
                                                    documentType="birth_certificate" />
                                            </label>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3" *ngIf="attachments[i] && attachments[i]['signature']; ">
                                            <div class="row" *ngFor="let attachment of attachments[i]['signature']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3" *ngIf="attachments[i] && attachments[i]['photo']; ">
                                            <div class="row" *ngFor="let attachment of attachments[i]['photo']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <h5>Other Documents</h5>
                                            <div *ngIf="documents">
                                                <div *ngFor="let document of documents[i]; let i=index ">
                                                    <a href="{{ document.full_path }}" class="btn-link">
                                                        {{ i + 1 }}. {{ document.name }}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="panel-footer text-right" [appCan]="'admin'">
                                    <div class="row">
                                        <div class="col-sm-offset-8 col-sm-4">
                                            <a class="btn btn-danger"
                                                (click)="onDeleteVerificationButtonClicked(verification.get('id')?.value,i)">
                                                Delete Verification
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
