import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';

// var vendorsCache: any;

@Component( {
    selector: 'app-vendor-typeahead',
    templateUrl: './vendor-typeahead.component.html',
    styleUrls: [ './vendor-typeahead.component.css' ],
} )
export class VendorTypeaheadComponent implements OnInit {

    public static vendorsCache: any = [];

    @Input() formGroup!: FormGroup;
    @Input() control: string = 'vendor';
    @Input() label: string = 'Vendor';
    @Input() verification!: string;
    @Input() required: boolean = false;
    vendors!: any;

    constructor (
        public global: GlobalService,
        public common: CommonService,
    ) {
    }

    get vendorName (): string { return this.control + '_name' }

    get vendorId (): string { return this.control + '_id' }

    get vendorIdValue (): string { return this.formGroup.get( this.vendorId )?.value }

    get vendorNameValue (): string { return this.formGroup.get( this.vendorName )?.value }

    get options (): any[] {
        return this.vendors;
    }

    ngOnInit (): void {

        this.fetchVendors();

        if ( !this.verification ) throw new Error( 'Verfication input is needed' );

    }

    fetchVendors (): void {

        if ( VendorTypeaheadComponent.vendorsCache[ this.verification ] ) {

            this.vendors = VendorTypeaheadComponent.vendorsCache[ this.verification ];

        } else {

            this.common.getVendors( 'address' ).subscribe(
                response => {
                    this.vendors = VendorTypeaheadComponent.vendorsCache[ this.verification ] = response;
                }
            );

        }

    }

    onSelect ( $event: TypeaheadMatch ): void {

        this.formGroup.get( this.vendorId )?.setValue( $event.item.id );
        this.formGroup.get( this.vendorName )?.setValue( $event.item.name );


    }

    onNoResults ( $event: boolean ): void {

        if ( $event === true ) {

            this.formGroup.get( this.vendorId )?.setValue( null );
            this.formGroup.get( this.vendorName )?.setValue( null );

        }
    }

}
