import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { BlacklistedService } from 'src/app/core/services/blacklisted.service';
import { Subscription } from 'rxjs';
import { FilterService } from './../../core/services/filter.service';
import { BlacklistedParivahanStatus } from 'src/app/core/interfaces/blacklisted-logs/parivan-stats';
import { BlacklistedVahanStats } from 'src/app/core/interfaces/blacklisted-logs/vahan-stats';
import { BlacklistStats } from 'src/app/core/interfaces/blacklisted-logs/blacklist-stats';


@Component( {
    selector: 'app-blacklisted-stats',
    templateUrl: './blacklisted-stats.component.html',
    styleUrls: [ './blacklisted-stats.component.css' ],
} )
export class BlacklistedStatsComponent implements OnInit, OnDestroy {

    blacklistStats!: BlacklistStats;
    vahanStats!: BlacklistedVahanStats;
    parivahanStats!: BlacklistedParivahanStatus;
    filtersSubscription!: Subscription;


    constructor (
        private blacklistedService: BlacklistedService,
        private filterService: FilterService
    ) { }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterStats( params );
        } );
    }

    filterStats ( params: Params ): void {

        this.blacklistedService.getStats( params ).subscribe(
            response => {

                this.blacklistStats = response[ 'blacklist' ];
                this.vahanStats = response[ 'vahan' ];
                this.parivahanStats = response[ 'parivahan' ];

            }
        );
    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }

}
