<div bsModal #webhookCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Create Webhook</h4>
                <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <label class="center-block"> ENTER WEBHOOK URL (*)
                                    <input [(ngModel)]="url" class="form-control" required>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class="center-block"> Select Type
                                    <select class="form-control" [(ngModel)]="type" required
                                        (change)="onTypeSelected($event)">
                                        <option [value]="'company'">Company</option>
                                        <option [value]="'vendor'">Vendor</option>
                                    </select>
                                </label>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block"> Select Client
                                    <select class="form-control" [(ngModel)]="client_api_id" required>
                                        <option *ngFor="let client_api of client_apis " [value]="client_api.id">
                                            {{client_api.owner_name}}
                                        </option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class="center-block"> Header
                                    <input [(ngModel)]="fixed_header_key" class="form-control" readonly>
                                </label>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block"> Value
                                    <input [(ngModel)]="fixed_header_value" class="form-control" readonly>
                                </label>
                            </div>
                        </div>
                        <div class="row" *ngFor="let header of numberOfHeaders; let i = index;">
                            <div class="col-md-6 form-group">
                                <label class="center-block">
                                    Header
                                    <input class="form-control" [(ngModel)]="headers[i]">
                                </label>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block">
                                    Value
                                    <input class="form-control" [(ngModel)]="values[i]">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="pull-right">
                    <a class="btn btn-default btn-primary" (click)="createWebhook()"> Create</a>
                </div>
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>

</div>