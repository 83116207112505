import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { Company } from '../models/company.model';
import { State } from '../models/state.model';
import { District } from '../models/district.model';
import { Authority } from '../models/authority.model';
import { PanSources } from '../enums/pan-sources.enum';
import { PanDataTypes } from '../enums/pan-data-types.enum';
import { DrivingLicenceSources } from '../enums/driving-licence-sources.enum';
import { AadhaarSources } from '../enums/aadhaar-sources.enum';
import { StatusService } from './status.service';
import { StatusResponse } from '../models/status-response.model';

@Injectable( {
    providedIn: 'root'
} )
export class GlobalService {

    constructor (
        private api: ApiService,
        private statusService: StatusService
    ) { }

    private users!: User[] | any[];

    private companies!: Company[] | any[];

    private states!: State[] | any[];

    private districts !: District[] | any[];

    private authorities!: Authority[] | any[];

    private methodology!: any[];

    private employmentType!: any[];

    private documentTypes!: any[];

    private identityDocumentTypes!: any[];

    private countries!: any[];

    private languages!: any[];

    private verificationClientRemarks!: any;

    private vendors!: any[];

    private typeOptions!: any[];

    private workTypes!: any;

    private userLevels!: any;

    populateGlobalVariables () {
        this.populateCompanies();
        this.populateStates();
        this.populateDistricts();
        this.statusService.populateCache( true );
        this.populdateIdentityDocumentTypes();
        this.populateDocumentTypes();
        this.populateUsers();
        this.populateEmploymentType();
        this.populateMethodology();
        this.populateCountries();
        this.populateVendors();
        this.populateTypeOptions();
        this.populateVerificationClientRemarks();
        this.populateWorkTypes();
        this.populateUserLevels();
    }

    fetchCompanies () {
        return this.companies;
    }

    fetchUsers () {
        return this.users;
    }

    fetchStates () {
        return this.states;
    }

    fetchDistricts () {
        return this.districts;
    }

    fetchAuthorities () {
        return this.authorities;
    }

    fettchMethodology () {
        return this.methodology;
    }

    fetchEmploymentType () {
        return this.employmentType;
    }

    fetchDocumentTypes () {
        return this.documentTypes;
    }

    fetchCountries () {
        return this.countries;
    }

    fetchIdentityDocumentTypes () {
        return this.identityDocumentTypes;
    }

    fetchlanguages () {
        return this.languages;
    }

    fetchVerificationClientRemarks () {
        return this.verificationClientRemarks;
    };

    fetchVendors () {
        return this.vendors;
    }

    fetchWorkTypes () {
        return this.workTypes;
    }

    fetchTypeOptions () {
        return this.typeOptions;
    }

    /**
     * @returns
     * @deprecated use status.service -> statuses instead
     */
    fetchStatuses (): StatusResponse {
        return this.statusService.statuses;
    }

    fetchUserLevels () {
        return this.userLevels;
    }

    private populdateIdentityDocumentTypes () {
        this.identityDocumentTypes = [
            {
                id: 0,
                name: 'No Document',
                sources: [],
            },
            {
                id: 1,
                name: 'Voter ID Card',
                sources: [],
            },
            {
                id: 2,
                name: 'PAN Card',
                sources: Object.values( PanSources ),
                dataType: Object.values( PanDataTypes ),
            },
            {
                id: 3,
                name: 'Aadhaar Card',
                sources: Object.values( AadhaarSources ),
            },
            {
                id: 4,
                name: 'Driving Licence',
                sources: Object.values( DrivingLicenceSources ),
            },
            {
                id: 5,
                name: 'Passport',
                sources: [],
            },
            {
                id: 6,
                name: 'National Identity',
                sources: [],
            }
        ];
    }

    // TODO:: create and use enum for these
    private populateDocumentTypes () {
        this.documentTypes = [
            {
                id: 0,
                name: 'No Document',
            },
            {
                id: 1,
                name: 'Vehicle RC',
            },
            {
                id: 2,
                name: 'All India Permit',
            },
            {
                id: 3,
                name: 'Contract Carriage Permit',
            },
            {
                id: 4,
                name: 'PSV Badge',
            }, {
                id: 5,
                name: 'Fitness Certificate',
            },
            {
                id: 6,
                name: 'Insurance Certificate',
            },
            {
                id: 7,
                name: 'Credit Report',
            },
            {
                id: 8,
                name: 'Gst Certificate',
            },
            {
                id: 9,
                name: 'MCA Check',
            },
            {
                id: 10,
                name: 'Business Registration',
            },
            {
                id: 11,
                name: 'Business Ownership Document',
            },
            {
                id: 12,
                name: 'Bank Statement',
            },
            {
                id: 13,
                name: 'Income Tax Returns',
            },
            {
                id: 14,
                name: 'E-Challan Driving Licence',
            },
        ];
    }

    private populateEmploymentType () {
        this.employmentType = [
            {
                id: 0,
                name: 'Current Employment',
            },
            {
                id: 1,
                name: 'Past Employment',
            },
            {
                id: 2,
                name: 'No Employment',
            },
        ]
    }

    private populateUsers (): Promise<any> {
        if ( this.users ) {
            return Promise.resolve( this.users );
        } else {
            return this.getUsers().then( data => {
                this.users = data;
                return data;
            } );
        }
    }

    private populateCompanies (): Promise<any> {
        if ( this.companies ) {
            return Promise.resolve( this.companies );
        } else {
            return this.getCompanies().then( companies => {
                this.companies = companies;
                return companies;
            } );
        }
    }

    private populateCountries (): Promise<any> {
        if ( this.countries ) {
            return Promise.resolve( this.countries );
        } else {
            return this.getCountries().then( countries => {
                this.countries = countries;
                return countries;
            } );
        }
    }

    private populateStates (): Promise<any> {
        if ( this.states ) {
            return Promise.resolve( this.states );
        } else {
            return this.getStates().then( states => {
                this.states = states;
                return states;
            } );
        }
    }

    private populateDistricts (): Promise<any> {
        if ( this.districts ) {
            return Promise.resolve( this.districts );
        } else {
            return this.getDistricts().then( districts => {
                this.districts = districts;
                return districts;
            } );
        }
    }

    private populateLanguages (): Promise<any> {
        if ( this.languages ) {
            return Promise.resolve( this.languages );
        } else {
            return this.getLanguages().then( languages => {
                this.languages = languages;
                return languages;
            } );
        }
    }

    private populateVerificationClientRemarks (): Promise<any> {
        if ( this.verificationClientRemarks ) {
            return Promise.resolve( this.verificationClientRemarks );
        } else {
            return this.getVerificationClientRemarks().then( verificationClientRemarks => {
                this.verificationClientRemarks = verificationClientRemarks
                return verificationClientRemarks;
            } );
        }
    }

    private populateVendors (): Promise<any> {
        if ( this.vendors ) {
            return Promise.resolve( this.vendors );
        } else {
            return this.getAllVendors().then( vendors => {
                this.vendors = vendors;
                return vendors;
            } );
        }
    }

    populateAuthorities ( type: string ): Promise<any> {
        if ( this.authorities ) {
            return Promise.resolve( this.authorities );
        } else {
            return this.getAuthorities( type ).then( authorities => {
                this.authorities = authorities;
                return authorities;
            } );
        }
    }
    private populateTypeOptions () {
        this.typeOptions = [
            {
                id: 'address',
                name: 'Address Verification',
            },
            {
                id: 'education',
                name: 'Education Verification',
            },
            {
                id: 'employment',
                name: 'Employment Verification',
            },
            {
                id: 'identity',
                name: 'Identity Verification',
            },
            {
                id: 'global',
                name: 'Global DB Verification',
            },

        ];
    }

    populateUserLevels () {
        this.userLevels = [
            {
                id: 1,
                name: 'Default',
            },
            {
                id: 2,
                name: 'Editor',
            },
            {
                id: 3,
                name: 'Moderator',
            },
            {
                id: 4,
                name: 'Admin',
            },
            {
                id: 5,
                name: 'Super-Admin',
            },
        ];
    }

    private populateWorkTypes (): Promise<any> {
        if ( this.workTypes ) {
            return Promise.resolve( this.workTypes );
        } else {
            return this.getVerificationWorkTypes().then( workTypes => {
                this.workTypes = workTypes;
                return workTypes;
            } )
        }
    }

    private populateMethodology () {
        this.methodology = [
            {
                id: 0,
                name: 'Verbal',
            },
            {
                id: 1,
                name: 'Online',
            },
            {
                id: 2,
                name: 'Offline',
            },
            {
                id: 3,
                name: 'Vendor',
            },
            {
                id: 4,
                name: 'Mail',
            },
            {
                id: 5,
                name: 'Non Offical Mail',
            },
            {
                id: 6,
                name: 'Reference',
            },
            {
                id: 7,
                name: 'EPFO'
            },
        ]
    }

    getUsers (): Promise<any> {
        return this.api.get( 'common/users' ).toPromise();
    }

    getCompanies (): Promise<any> {
        return this.api.get( 'companies/list' ).toPromise();
    }

    getStates (): Promise<any> {
        return this.api.get( 'common/states' ).toPromise();
    }

    getDistricts (): Promise<any> {
        return this.api.get( 'common/districts' ).toPromise();
    }

    getAuthorities ( type: string ): Promise<any> {
        return this.api.get( 'common/authorities?type=' + type ).toPromise();
    }

    /**
     * @deprecated use status.service -> populateCache() instead
     */
    getStatuses (): Promise<any> {
        return this.api.get( 'common/statuses' ).toPromise();
    }

    getAllVendorNames (): Promise<any> {
        return this.api.get( 'common/get-all-vendor-names' ).toPromise();
    }

    // It returns the json of id and name of vendors
    getAllVendors (): Promise<any> {
        return this.api.get( 'common/get-all-vendors' ).toPromise();
    }

    getVendors ( verification: string ): Promise<any> {
        return this.api.get( 'common/vendors?verification=' + verification ).toPromise()
    }

    getCountries (): Promise<any> {
        return this.api.get( 'common/countries' ).toPromise()
    }

    getLanguages (): Promise<any> {
        return this.api.get( 'common/languages' ).toPromise()
    }

    getVerificationClientRemarks (): Promise<any> {
        return this.api.get( 'common/verification-client-remarks' ).toPromise();
    }

    getVerificationWorkTypes (): Promise<any> {
        return this.api.get( 'common/all-work-types' ).toPromise();
    }
}
