import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IDocumentAction, ICandidateDocument } from 'src/app/core/models/candidate-document.modal';
import { Employee } from 'src/app/core/models/employee.model';
import { CandidateDocumentService } from 'src/app/core/services/candidate-document.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { VerificationService } from 'src/app/core/services/verification.service';


@Component( {
    selector: 'div[app-candidate-document-item]',
    templateUrl: './candidate-document-item.component.html',
    styleUrls: [ './candidate-document-item.component.css' ],
} )
export class CandidateDocumentItemComponent {

    @Output() onActionEmitter = new EventEmitter<IDocumentAction>();

    @Input() index!: number;

    @Input() employee!: Employee;

    @Input() documentFormArray!: FormArray;

    @Input() documentFormGroup!: FormGroup | any;

    zoom: boolean = false;

    uploadCompleted: boolean = false;

    uploadProcessing: boolean = false;

    previousDocument: ICandidateDocument | null = null;

    verificationLabelList: { verificationId: string; verificationLabel: string; }[] = [];


    constructor (
        private employeeService: EmployeeService,
        private commonService: CommonService,
        private documentService: CandidateDocumentService,
        private verificationService: VerificationService,
        private confirmationModalService: ConfirmationModalService
    ) { }

    ngOnInit () {
        this.verificationLabelList = this.verificationService.getEmployeeVerificationLabels( this.employee );
    }

    get employeeId (): number {

        return this.employee.id;

    }

    get imageUrl (): AbstractControl {

        return this.documentFormGroup.get( 'imageUrl' ) as AbstractControl;
    }



    get typeLabelList (): ICandidateDocument[] {

        const verificationTypes = this.documentService.getTypeLabelList( this.verificationId.value );

        if ( !this.uploaded.value ) {

            return this.uniqueVerificationIds( verificationTypes );

        }

        return verificationTypes;

    }

    get documentLabelList (): ICandidateDocument[] {


        if ( this.uploaded.value ) {

            return this.documentService.getVerificationDocuments( this.verificationId.value, this.typeId.value, this.documentType.value, this.documentId.value );

        } else {

            return this.documentService.documentList( this.verificationId.value, this.typeId.value );

        }

    }

    get verificationId (): AbstractControl {

        return this.documentFormGroup.get( 'verificationId' ) as AbstractControl;
    }

    get pageDetail (): AbstractControl {

        return this.documentFormGroup.get( 'page' ) as AbstractControl;
    }

    get verificationLabel (): AbstractControl {

        return this.documentFormGroup.get( 'verificationLabel' ) as AbstractControl;
    }

    get typeLabel (): AbstractControl {

        return this.documentFormGroup.get( 'typeLabel' ) as AbstractControl;
    }

    get table (): AbstractControl {

        return this.documentFormGroup.get( 'table' ) as AbstractControl;
    }

    get typeId (): AbstractControl {

        return this.documentFormGroup.get( 'typeId' ) as AbstractControl;
    }

    get documentId (): AbstractControl {

        return this.documentFormGroup.get( 'documentId' ) as AbstractControl;
    }

    get documentType (): AbstractControl {

        return this.documentFormGroup.get( 'documentType' ) as AbstractControl;
    }

    get documentLabel (): AbstractControl {

        return this.documentFormGroup.get( 'documentLabel' ) as AbstractControl;
    }

    get disabled (): boolean | null {

        return this.uploaded.value ? true : null;
    }

    get uploaded (): AbstractControl {

        return this.documentFormGroup.get( 'uploaded' ) as AbstractControl;
    }

    onDocumentChange (): void {

        if ( !this.uploaded.value && this.documentId?.value ) {

            const field = this.documentId.value;

            this.verificationLabel.setValue( field.verificationLabel );

            this.typeLabel.setValue( field.typeLabel );

            this.table.setValue( field.table );

            this.documentType.setValue( field.documentType );

            this.documentLabel.setValue( field.documentLabel );

        }

    }

    uniqueVerificationIds ( typeLabels: any ): ICandidateDocument[] {

        return typeLabels.filter( ( a: any, i: any ) => typeLabels.findIndex( ( s: any ) => a.typeId == s.typeId ) === i )

    }

    croppedImageUrl ( $event: { base64: string } ): void {


        if ( this.uploaded.value ) {

            this.documentFormGroup.setControl( 'base64Image', new FormControl( $event.base64, [ Validators.required ] ) );

            this.employeeService.cropDocumentUpload( this.employeeId, this.documentFormGroup.value, ).subscribe(
                ( response: any ) => {

                    const imageUrl = response.data + '?timestramp=' + new Date().getTime();

                    this.imageUrl.patchValue( imageUrl );

                    this.documentFormGroup.removeControl( 'base64Image' );

                    this.commonService.notifications.success( 'Success', response.message );
                } );
        } else {
            this.documentService.addEmptyDocument( $event.base64, this.index + 1, 'Croped' );
        }
    }

    onDuplicate (): void {

        if ( !this.uploaded.value ) {

            this.documentService.addEmptyDocument( this.imageUrl.value, this.index, 'Duplicate' );

        } else {

            const documentAction: IDocumentAction = { actionName: 'Duplicate', index: this.index, document: this.documentFormGroup.value };

            this.onActionEmitter.emit( documentAction );
        }
    }

    onDelete (): void {

        this.employeeService.documentDelete( this.employeeId, this.documentFormGroup.value ).subscribe( ( documents ) => {

            this.documentFormArray.removeAt( this.index );

            this.commonService.notifications.success( 'Success', documents.message );

        } )
    }

    onDeleteButtonClicked (): void {

        const options: ConfirmationModalOptions = { text: 'Want to delete!' };

        if ( !this.uploaded.value ) {

            this.confirmationModalService.confirm( options, () => this.documentService.removeDocument( this.index ) );

        } else {

            options.text = 'Want to delete permanently!';

            this.confirmationModalService.confirm( options, () => this.onDelete() );
        }
    }

    onMove (): void {

        const documentAction: IDocumentAction = { actionName: 'Move', index: this.index, document: this.documentFormGroup.value };

        this.onActionEmitter.emit( documentAction );
    }

    onSwap (): void {

        const action: IDocumentAction = { actionName: 'Swap', index: this.index, document: this.documentFormGroup.value };

        this.onActionEmitter.emit( action );
    }

    onUpload (): void {

        this.uploadCompleted = false;

        this.uploadProcessing = true;

        this.previousDocument = null;

        this.employeeService.documentUpload( this.employeeId, this.documentFormGroup.value ).subscribe(
            response => {
                if ( response.success ) {

                    this.commonService.notifications.success( 'Success', response.message );

                    this.uploadProcessing = false;

                    this.uploadCompleted = true;

                    this.documentFormArray.removeAt( this.index );

                    if ( this.table.value != 'self' ) {

                        this.documentService.documents.push( response.data );
                    }

                    this.documentService.addItem( response.data );

                } else {

                    this.commonService.notifications.error( 'Error', response.message );

                    if ( response.data ) {

                        this.previousDocument = response.data;

                    }

                    this.uploadProcessing = false;

                    this.uploadCompleted = false;

                }
            }, error => {

                this.uploadProcessing = false;

                this.uploadCompleted = false;
            }
        );
    }

    getFileType ( url: any ) {

        const extension = url.split( '.' ).pop().toLowerCase();

        const imageExtensions = [ 'jpg', 'jpeg', 'png' ];

        const documentExtensions = [ 'doc', 'docx', 'xls', 'xlsx' ];

        const excelExtensions = [ 'xls', 'xlsx' ];

        const base64 = url.includes( 'data:image' ) || url.includes( 'blob' );


        if ( extension === 'pdf' ) {
            return 'pdf';
        } else if ( imageExtensions.includes( extension ) || base64 ) {
            return 'image';
        } else if ( documentExtensions.includes( extension ) ) {
            return 'document';
        } else if ( excelExtensions.includes( extension ) ) {
            return 'excel';
        } else {
            return 'document';
        }
    }

}
