import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { State } from 'src/app/core/models/state.model';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';


@Component( {
    selector: 'app-state-typeahead',
    templateUrl: './state-typeahead.component.html',
} )


export class StateTypeaheadComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() control: string = 'state';
    @Input() label: string = 'State';
    @Input() disabled!: boolean;

    @Output() changed: EventEmitter<number> = new EventEmitter<number>();

    constructor (
        public global: GlobalService,
        public common: CommonService,
    ) { }

    get controlName (): string { return this.control + '_name'; }

    get controlId (): string { return this.control + '_id'; }

    get stateName (): FormControl { return this.formGroup.get( this.controlName ) as FormControl; }

    get stateId (): FormControl { return this.formGroup.get( this.controlId ) as FormControl; }

    get stateNameValue (): string { return this.formGroup.get( this.controlName )?.value; }

    get stateIdValue (): number { return this.formGroup.get( this.controlId )?.value; }

    get states (): State[] { return this.global.fetchStates(); }

    ngOnInit (): void { }


    onSelect ( $event: TypeaheadMatch ): void {

        this.formGroup.get( this.controlId )?.setValue( $event.item.id );
        this.formGroup.get( this.controlName )?.setValue( $event.item.name );

        this.changed.emit( this.stateIdValue );

    }

    onNoResults (): void {

        if ( this.stateNameValue == '' || this.stateNameValue == ' ' ) {

            this.formGroup.get( this.controlId )?.setValue( null );
            this.formGroup.get( this.controlName )?.setValue( null );

            this.changed.emit( this.stateIdValue );

        }
    }
}
