import { DownloadModalComponent } from './../../common/download-modal/download-modal.component';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Company } from '../models/company.model';


import { Pagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';


export const AdditionalFields = [
    {
        id: 'zone',
        name: 'Zone',
    },
    {
        id: 'city',
        name: 'City',
    },
    {
        id: 'current_work_name',
        name: 'Current Work',
    },
    {
        id: 'current_work_description',
        name: 'Current Work Description',
    },
    {
        id: 'driving_experience',
        name: 'Driving Experience',
    },
    {
        id: 'monthly_income',
        name: 'Monthly Income',
    },
    {
        id: 'commercial_licence_issue_date',
        name: 'Licence Issue Date',
    },
    {
        id: 'martial_status',
        name: 'Martial Status',
    },
    {
        id: 'number_of_children',
        name: 'Number of Children',
    },
    {
        id: 'number_of_dependents',
        name: 'Number of Dependents',
    },
    {
        id: 'referred_by',
        name: 'Referred By',
    },
    {
        id: 'current_address_status',
        name: 'Current Address Status',
    },
    {
        id: 'residing_since',
        name: 'Residing Since',
    },
    {
        id: 'permanent_address_status',
        name: 'Permanent Address Status',
    },
    {
        id: 'reference_relation',
        name: 'Reference Relation',
    },
    {
        id: 'education_qualification',
        name: 'Education Qualification',
    },
    {
        id: 'source_of_deposit',
        name: 'Source Of Deposit',
    },

];

export const BasicFields = [
    {
        id: 'interim_report_date',
        name: 'Interim Report Date',
    },
    {
        id: 'completion_date',
        name: 'Completion Date',
    },
    {
        id: 'report_date',
        name: 'Report Date',
    },
    {
        id: 'location',
        name: 'Location',
    },
    {
        id: 'recruiter',
        name: 'Recruiter',
    },
    {
        id: 'status',
        name: 'Status',
    },
    {
        id: 'employment_type',
        name: 'Employment Type',
    },
]

@Injectable( {
    providedIn: 'root'
} )
export class CompanyService {

    constructor (
        private api: ApiService,
        private modalService: BsModalService,
    ) {
    }

    getAll ( params: string ): Observable<Company[]> {
        return this.api.get( 'companies?' + params );
    }

    find ( id: number ): Observable<Company> {
        return this.api.get( 'companies/' + id + '/edit' );
    }

    update ( id: number, values: Object ) {
        return this.api.put( 'companies/' + id, values );
    }

    create ( values: Object ) {
        return this.api.post( 'companies', values );
    }

    getListOfCompanies (): Observable<Company[]> {
        return this.api.get( 'companies/list' );
    }

    getAllClients ( id: number ): Observable<any[]> {
        return this.api.get( 'companies/' + id + '/get-all-clients' );
    }

    getLots ( id: number ): Observable<any[]> {
        return this.api.get( 'companies/' + id + '/get-lots-by-company' )
    }

    verificationsList (): Observable<any[]> {
        return this.api.get( 'common/all-verifications' )
    }

    downloadExcelData ( data: any, id: string | number, params: HttpParams, company_name: string ): void {
        const initialState = {
            filterParams: params,
            paramsString: params.toString(),
            route: 'companies/' + id + '/download-excel',
            fileName: company_name + '-Excel',
            method: 'post',
            postParams: data,
        };

        this.modalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } );
    }

    getAdditionalFields (): Promise<any[]> {
        return Promise.resolve( AdditionalFields );
    }

    getBasicFields (): Promise<any[]> {
        return Promise.resolve( BasicFields );
    }

    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'companies', params );
    }

    uploadCompanyLogo ( companyId: number, formData: FormData ): Observable<{ success: boolean }> {
        return this.api.upload( 'companies/' + companyId + '/upload-logo', formData );
    }

    getHistory ( companyId: number ) {
        return this.api.get( 'companies/' + companyId + '/history' );
    }
}
