import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccessRestrictionRoutingModule } from './access-restriction-routing.module';
import { PermissionsListComponent } from './permissions-list/permissions-list.component';
import { PermissionCreateComponent } from './permission-create/permission-create.component';
import { PermissionEditComponent } from './permission-edit/permission-edit.component';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { PermissionDeletedComponent } from './permission-deleted/permission-deleted.component';


@NgModule( {
    declarations: [
        PermissionsListComponent,
        PermissionCreateComponent,
        PermissionEditComponent,
        PermissionDeletedComponent,
    ],
    imports: [
        CommonModule,
        AccessRestrictionRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
    ],
} )
export class AccessRestrictionModule { }
