import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { idDocuments, Identity, Address, Addresses, IdentityType } from '../client-forms-v2.enum'
import { CommonService } from '../../core/services/common.service';
@Component( {
    selector: 'app-icr-form',
    templateUrl: './icr-form.component.html',
} )
export class IcrFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    identityList = idDocuments;

    addressList = Addresses;

    selectedOption: string[] = [];

    isSectionChecked: boolean = false;

    maxLimit: number = 1;

    icrForm = this.fb.group( {
        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] ),
    } )

    icrItem = this.fb.group( {
        via: this.fb.control( null, [ Validators.required ] ),
        id_type: this.fb.control( null ),
        id_required: this.fb.control( false ),
        address_type: this.fb.control( null ),
        address_required: this.fb.control( false ),
    } );

    get icrVerificationArray (): FormArray | any {
        return this.icrForm.get( 'verifications' ) as FormArray;
    }

    get via (): FormControl {
        return this.icrItem.get( 'via' ) as FormControl;
    }

    get idType (): FormControl {

        return this.icrItem.get( 'id_type' ) as FormControl;
    }

    get idRequired (): FormControl {

        return this.icrItem.get( 'id_required' ) as FormControl;
    }

    get addressType (): FormControl {

        return this.icrItem.get( 'address_type' ) as FormControl;
    }

    get addressRequired (): FormControl {

        return this.icrItem.get( 'address_required' ) as FormControl;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.icrVerificationArray.length > 0;
    }

    get identities (): Identity[] {
        return this.identityList.filter( item => item.value !== 'any' );
    }

    get addresses (): Address[] {
        return this.addressList.filter( item => item.value !== 'any' );
    }

    constructor ( private fb: FormBuilder, private commonService: CommonService ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'icr', this.icrForm );
    }

    setDefaultIdentity (): void {
        this.icrVerificationArray.patchValue( [ {
            id_type: IdentityType.AadhaarCard,
            id_required: true,
            address_type: null,
            address_required: false,
        } ] )
    }

    setDefaultAddress (): void {
        this.icrVerificationArray.patchValue( [ {
            id_type: null,
            id_required: false,
            address_type: 'current',
            address_required: true,
        } ] )
    }

    setDefaultBothIdentity (): void {
        this.icrVerificationArray.patchValue( [ {
            id_type: IdentityType.AadhaarCard,
            id_required: true,
            address_type: 'current',
            address_required: true,
        } ] )
    }

    icrTypeChange ( $event: any ): void {
        const icrType = $event.target.value;

        if ( icrType == 'identity' ) {
            this.setDefaultIdentity();
        }
        if ( icrType == 'address' ) {
            this.setDefaultAddress();
        }
        if ( icrType == 'both' ) {
            this.setDefaultBothIdentity();
        }

        this.selectedOption = [];
    }

    addIcr (): void {
        if ( this.icrVerificationArray?.length >= this.maxLimit ) {
            this.commonService.notifications.error( 'Icr', `Icr max limit is ${ this.maxLimit }` );
        } else {
            this.icrVerificationArray?.push( this.icrItem );
        }
    }

    sectionToggle ( $event: any ): void {
        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {
            this.icrVerificationArray?.clear();
        }
    }

    skipSelectedIdentity ( index: any, $event: any ): void {
        this.selectedOption[ index ] = $event.target.value;
    }

}
