<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">


    <ng-template #customItemTemplate let-model="item" let-index="index">
        {{model.name}}
    </ng-template>

    <form [formGroup]="editForm" (ngSubmit)="update()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">{{verification.employee_name}}</h4>

                    <h5 class="modal-title pull-right">
                        <app-education-create-institute #instituteCreateModal
                            (onInstituteCreated)="onInstituteCreated()"></app-education-create-institute>
                        <app-education-send-mail #sendMail></app-education-send-mail>
                        <a class="btn m-b-xs btn-sm btn-primary"
                            (click)="sendMail.show(editForm,verification.attachments)"
                            *ngIf="editForm.get('institute_id')?.value">
                            Send Mail
                        </a>

                        <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="instituteCreateModal.show()">
                            <i class="fa fa-plus"></i>Create Institutes</a>
                    </h5>
                </div>

                <app-education-common-edit *ngIf="verification" (onVerificationUpdated)="onModalUpdated()"
                    [editFormGroup]="editForm" [isSubmitting]="isSubmitting" [vendors]="vendors"
                    [verification]="verification" [current_date]="current_date" [created_at]="created_at">
                </app-education-common-edit>


                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>