import { BsModalService } from 'ngx-bootstrap/modal';
import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { Pagination } from '../models/pagination.model';
import { VehiclercOwner } from '../models/vehiclerc-owner.model';
import { HttpParams } from '@angular/common/http';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';


@Injectable( {
    providedIn: 'root'
} )
export class VehicleService {

    constructor (
        private api: ApiService,
        private modalService: BsModalService,
    ) {
    }

    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'vehiclerc', params );
    }

    getStats ( params: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'vehiclerc/stats', params );
    }

    resend ( values: Object | undefined ): Observable<any[]> {
        return this.api.post( 'vehiclerc/resend', values );
    }

    resendAll ( source: string, filterParam: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'vehiclerc/resend-all?type=' + source, filterParam );
    }

    getDetails ( rcNumber: string ): Observable<VehiclercOwner[]> {
        return this.api.get( 'vehiclerc/' + rcNumber );
    }

    getHtmlResponse ( id: string, type: string ): Observable<any> {
        return this.api.get( 'vehiclerc/' + id + '/html-response?type=' + type );
    }

    getApiResponse ( id: string ): Observable<any> {
        return this.api.get( 'vehiclerc/' + id + '/api-response' );
    }

    search ( values: Object | undefined ): Observable<any[]> {
        return this.api.post( 'vehiclerc/search', values );
    }

    importExcel ( values: Object ): Observable<any> {
        return this.api.post( 'vehiclerc/import-excel', values );
    }

    importStolenVehicles ( values: Object ): Observable<any> {
        return this.api.post( 'vehiclerc/stolen/import-excel', values );
    }

    importUnclaimedVehicles ( values: Object ): Observable<any> {

        return this.api.post( 'vehiclerc/unclaimed/import-excel', values );

    }

    downloadBlacklistExcel ( params: Params ): void {

        const initialState: any = {
            filterParams: params,
            paramsString: params.toString(),
            route: 'vehiclerc/blacklisted-excel',
            fileName: 'BlackListed Excel',
            method: 'get',
            postParams: null,
        };

        this.modalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } );
    }

    downloadChallanExcel ( params: Params ): void {
        const initialState: any = {
            filterParams: params,
            paramsString: params.toString(),
            route: 'vehiclerc/challan-excel',
            fileName: 'Challan Excel',
            method: 'get',
            postParams: null,
        };

        this.modalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } );
    }


    getFields ( clientId: number ): Observable<any> {
        return this.api.get( 'vehiclerc/' + clientId + '/get-fields' );
    }

    setFields ( params: any, clientId: number ): Observable<any> {
        return this.api.post( 'vehiclerc/client-settings', params );
    }

    downloadVehicleExcel ( params: Params ): void {
        const initialState: any = {
            filterParams: params,
            paramsString: params.toString(),
            route: 'vehiclerc/export-excel',
            fileName: 'Vehicle Rc Excel',
            method: 'get',
            postParams: null,
        };

        this.modalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } );
    }
}
