import { BsModalService } from 'ngx-bootstrap/modal'; import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerificationList } from '../../core/components/verification-list.component';
import { EmploymentVerification } from '../../core/models/employment-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-employment-list',
    templateUrl: './employment-list.component.html',
    styleUrls: [ './employment-list.component.css' ],
} )
export class EmploymentListComponent extends VerificationList implements OnInit {

    loading!: boolean;
    override verifications: EmploymentVerification[] = [];
    isSubmitting: boolean = false;
    attachments: any[] = [];
    statsVisible: boolean = false;

    constructor (
        protected override verificationService: EmploymentService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected override viewContainerRef: ViewContainerRef,
        private bsModalService: BsModalService,
        public override confirmationModalService: ConfirmationModalService
    ) {
        super( confirmationModalService );
    }

    get users () {
        return this.global.fetchUsers();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {
        this.verification = 'employment';
        this.subscribeToQueryChanges();
        this.getVendorsList();

    }

    filterResults (): void {
        this.verificationService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    showEmployment ( employment: EmploymentVerification ): void {
        this.router.navigate( [ 'employment', employment.employee_id, 'edit' ], this.navigationExtras );
    }

    populateExtraDetails ( employmentVerifications: EmploymentVerification[] ): void {

        for ( const index in employmentVerifications ) {

            const verification: EmploymentVerification = employmentVerifications[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    sendFollowUpMail (): void {

        this.verificationService.sendMultipleFollowUpMail( this.ids ).subscribe( {
            next: ( response: any ) => {

                this.filterResults();

                this.commonService.notifications.success( 'Email sent Successfully' );
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( error );
            }
        }
        );
    }

    /**
     * delete multiple verifications
     */
    onSendFollowUpMailButtonClicked (): void {

        const options: ConfirmationModalOptions = {};

        if ( this.ids.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.sendFollowUpMail() );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }

    showStats (): void {
        this.statsVisible = !this.statsVisible;
    }

    sendconsentMail (): void {

        this.verificationService.sendMultipleConsentMail( this.ids ).subscribe( {
            next: ( response: any ) => {

                this.filterResults();

                this.commonService.notifications.success( 'Email sent Successfully' );

                if ( response.failed_track_ids.length > 0 ) {

                    this.commonService.notifications.warning( 'Unable to send email for these trackIds', response.failed_track_ids.toString() );
                }

                this.ids = [];
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( error );
            }
        }
        );
    }

    // TODO:: we can create a service for swal
    onSendconsentMailButtonClicked (): void {

        const options: ConfirmationModalOptions = {};

        if ( this.ids.length > 0 ) {
            this.confirmationModalService.confirm( options, () => this.sendconsentMail() )
        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }

    // overriding super class method
    // TODO:: move to super class after implementing this in all verificaions
    override exportExcel () {

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/' + this.verification + '/downloadExcel',
            fileName: this.verification,
            method: 'get',
            postParams: null,
        };

        this.bsModalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )
    }

}


