<div [formGroup]="formGName">
    <ng-select [formControlName]="controlName" [items]="options" bindValue="id" bindLabel="name" [searchable]="true"
        [multiple]="multipleValue" [placeholder]="placeholder" [closeOnSelect]="!multipleValue"
        (search)="onSearch($event)" (add)="onSelected($event)" (remove)="onUnselected($event)"
        [clearOnBackspace]="false" (close)="onClosed($event)" (change)="onChange($event)">
        <ng-template ng-header-tmp *ngIf="multipleValue && options?.length>0">

            <div>
                <button type="button" class="btn btn-link" (click)="selectAll()">Select All</button>
                <button type="button" class="btn btn-link" (click)="unSelectAll()">Clear All</button>
                <button type="button" class="btn btn-link">Selected : {{ selectedCount }} </button>
            </div>

        </ng-template>
    </ng-select>
</div>
