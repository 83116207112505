import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-response-modal',
    templateUrl: './response-modal.component.html',
} )
export class ResponseModalComponent implements OnInit {
    @ViewChild( 'apiLogRespose', { static: false } )
    public apiLogRespose!: ModalDirective;

    response: any = '';
    closeBtnName!: string;

    constructor ( public bsModalRef: BsModalRef ) { }

    ngOnInit () {
    }


}
