<div [formGroup]="mainFormGroup">
    <div formArrayName="formArray">
        <div [formGroupName]="formGName">
            <label class="center-block" *ngIf="labelName">{{labelName}} </label>
            <input [formControlName]="controlName" [typeahead]="options" [typeaheadMinLength]="0"
                typeaheadOptionField="name" placeholder="Select {{labelName}}"
                [typeaheadItemTemplate]="customItemTemplate" class="form-control" autocomplete="off"
                (typeaheadOnSelect)="OnSelect($event,fGroup)"
                (typeaheadNoResults)="changeTypeaheadNoResults($event,fGroup)">
            <small class="text-danger" *ngIf="requiredField && fGroup.get(controlId)?.errors">
                Required
            </small>
        </div>
    </div>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>