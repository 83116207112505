<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                        <div class="form-group">
                            <input type="file" (change)="onFileChange($event)" [accept]="acceptedFileType"
                                class="form-control" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="wrapper-md">
    <div class="panel">

        <div class="panel-heading">
            <h3 class="panel-title">Excel Heading </h3>
        </div>
        <div class="panel-body" style="display:block;width: 1120px; overflow-x: auto;">
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th>licence_number</th>
                        <th>dob</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>
<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-10 panel-title">
                    Imported Rows are as follows
                </div>
                <div class="col-md-2 text-right text-center-xs">
                    <button type="button" (click)="importExcel()" [disabled]="!excelForm.valid"
                        class="btn btn-primary">Import
                        Excel
                    </button>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <form [formGroup]="excelForm" (keydown.enter)="$event.preventDefault()">
                <fieldset [disabled]="isSubmitting">


                    <div class="row">
                        <div class="col-md-2 form-group">
                            <label class="center-block">Filter by Client</label>
                            <select class="form-control" formControlName="client_api_id">
                                <option *ngFor="let client_api of clients" [value]="client_api.id">
                                    {{ client_api.owner_name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-8 form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <fieldset class="form-group">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" value="sarathi"
                                                (change)="onCheckboxChange($event,'sarathi')">
                                            Sarathi
                                        </label>
                                    </fieldset>
                                </div>
                                <div class="col-md-2">
                                    <fieldset class="form-group">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" value="parivahan_sarathi"
                                                (change)="onCheckboxChange($event,'parivahan_sarathi')">
                                            Parivahan Sarathi
                                        </label>
                                    </fieldset>
                                </div>
                                <div class="col-md-2">
                                    <fieldset class="form-group">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" value="parivahan_app"
                                                (change)="onCheckboxChange($event,'parivahan_application')">
                                            Parivahan app
                                        </label>
                                    </fieldset>
                                </div>
                                <div class="col-md-2">
                                    <fieldset class="form-group">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" value="state"
                                                (change)="onCheckboxChange($event,'state')">
                                            State
                                        </label>
                                    </fieldset>
                                </div>
                                <div class="col-md-2">
                                    <fieldset class="form-group">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" value="parivahan_simple"
                                                (change)="onCheckboxChange($event,'parivahan_simple')">
                                            Parivahan Simple
                                        </label>
                                    </fieldset>
                                </div>
                                <div class="col-md-2">
                                    <fieldset class="form-group">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" value="parivahan_simple"
                                                (change)="onCheckboxChange($event,'sarathi_simple')">
                                            Sarathi Simple
                                        </label>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="max-width: 100%;overflow-x: scroll">
                        <table class="table table-bordered" formArrayName="excelFormArray">
                            <tr>
                                <th *ngFor="let title of originalHeaders">
                                    {{title}}
                                </th>
                            </tr>
                            <tr *ngFor="let row of excelFormArray.controls;let i=index" [formGroupName]="i">
                                <td *ngFor="let header of headerRaw;let k=index">
                                    <div>
                                        <input formControlName="{{header}}"><br>
                                        <small class="text-danger" [hidden]="row.get(header)?.valid">
                                            {{validationMessages[header]}}
                                        </small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>