<div bsModal #addMissingVerificationsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left" *ngIf="lot">Add Missing Verifications for {{lot.name}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <select class="form-control" [(ngModel)]="selected_verification" required>
                    <option *ngFor="let verification of lot.unique_verifications" [value]="verification.key">
                        {{verification.title}}
                    </option>
                </select>
            </div>

            <div class="modal-footer">
                <div class="pull-right">
                    <button class="btn btn-default" (click)="addMissingVerifications()"> Add</button>
                </div>
                <div class="pull-left">
                    <button class="btn btn-default" (click)="hide()"> Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>