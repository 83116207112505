import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IcrService } from 'src/app/core/services/icr.service';

@Component( {
    selector: 'app-cnr-details',
    templateUrl: './cnr-details.component.html',
    styleUrls: [ './cnr-details.component.css' ],
} )
export class CnrDetailsComponent implements OnInit {
    @Input() cnr_number: any;
    @Output() onCnrDetailsFetched: EventEmitter<any> = new EventEmitter<any>();
    cnr_details: any;
    orders: any;

    constructor ( private icrService: IcrService ) { }

    ngOnInit () {
        this.icrService.getCnrDetails( this.cnr_number ).subscribe( ( response: any ) => {
            this.cnr_details = response;
            this.orders = response.cnr_orders;
        } );
    }

    copyCnrData () {
        const data = {

            ...this.cnr_details,
            type: this.cnr_details.case_type,
            status: this.cnr_details.case_status,

        };

        this.onCnrDetailsFetched.emit( data );
    }
}
