import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Authority } from '../../core/models/authority.model';
import { AuthorityService } from '../../core/services/authority.service';
import { CommonService } from '../../core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { State } from 'src/app/core/models/state.model';
import { District } from 'src/app/core/models/district.model';

@Component( {
    selector: 'app-identity-authority-edit',
    templateUrl: './authority-edit.component.html',
    styleUrls: [ './authority-edit.component.css' ],
} )
export class AuthorityEditComponent implements OnInit {

    @ViewChild( 'authorityEditModal', { static: false } )
    public authorityEditModal!: ModalDirective;

    @Output()
    onAuthorityEdited: EventEmitter<boolean> = new EventEmitter<boolean>();

    payment_modes!: any[];
    authority: Authority = new Authority();
    editForm!: FormGroup;
    isSubmitting: boolean = false;

    // TODO: authority creation have more authority then update authority. Why?
    authorities!: any[];

    constructor (
        private authorityService: AuthorityService,
        private fb: FormBuilder,
        public global: GlobalService,
        private commonService: CommonService
    ) { }

    get states (): State[] { return this.global.fetchStates(); }

    get districts (): District[] { return this.global.fetchDistricts(); }

    ngOnInit (): void {

        this.initForm();

        this.authorities = this.authorityService.editableAuthorities;
    }

    initForm (): void {

        this.editForm = this.fb.group( {
            id: [],
            name: [ '' ],
            address: [ '' ],
            state_id: [ '' ],
            district_id: [ '' ],
            pincode: [ '' ],
            remarks: [ '' ],
            payment_mode_id: [ '' ],
            processing_fee: [ '' ],
        } );
    }

    show ( authority: Authority ): void {

        this.isSubmitting = false;

        this.authority = authority;

        this.commonService.getPaymentModes().subscribe(
            response => {
                this.payment_modes = response;
            }
        );

        this.editForm.patchValue( this.authority );

        this.authorityEditModal.show();
    }

    hide (): void {

        this.authorityEditModal.hide();
    }

    updateAuthority (): void {

        this.isSubmitting = true;

        this.authorityService.updateAuthority( this.editForm.value.id, this.editForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Authority Updated' );
                this.hide();
                this.onAuthorityEdited.emit( true );
            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to edit Authority' );
                this.hide();
            }
        );
    }
}

