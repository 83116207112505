import { DownloadExcelInitialState } from './../../core/models/download-excel-initial-state.model';
import { InviteStatusType } from './../../core/models/invite.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Pagination } from 'src/app/core/models/pagination.model';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { InviteService } from 'src/app/core/services/invite.service';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-invite-list',
    templateUrl: './form-invite-list.component.html',
} )

export class FormInviteListComponent extends ExportExcelComponent implements OnInit, OnDestroy {

    pagination: Pagination = new Pagination();
    loading!: boolean;
    filterFormGroup!: FormGroup;
    clients!: any[];
    fetchingInProgress: boolean = false;

    filtersSubscription!: Subscription;


    constructor (
        private inviteService: InviteService,
        public filterService: FilterService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) {
        super();
    }

    ngOnInit (): void {
        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

    filterResults ( params: Params ): void {

        const updatedParams = this.updateParams( params );

        this.inviteService.filter( updatedParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );
    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }

    sendFollowUpMail ( id: any ): void {

        this.inviteService.sendFollowUpMail( id ).subscribe( {
            next: ( response: any ) => {

                this.filterService.filter();

                this.commonService.notifications.success( 'Email sent Successfully' );

            },
            error: ( error: any ) => {

                this.commonService.notifications.error( error );
            }
        } );
    }

    onSendFollowUpMailButtonClicked ( id: any ): void {

        const options: ConfirmationModalOptions = { text: 'You want to send followup email' };

        this.confirmationModalService.confirm( options, () => this.sendFollowUpMail( id ) );

    }

    inviteCreateStatus ( status: boolean ): void {

        if ( status ) {
            this.filterService.filter();
        }
    }

    exportExcel () {

        const updatedParams = this.updateParams( this.filterService.filters );

        const initialState: DownloadExcelInitialState = {
            filterParams: updatedParams,
            paramsString: updatedParams.toString(),
            route: 'form-invites/download-excel',
            fileName: 'Form Invite ',
            method: 'get',
            postParams: null,
        };

        this.inviteService.showDownloadModal( initialState );
    }

    refreshPage (): void {
        this.filterService.filter();
    }

    toggleInviteStatus ( inviteId: number ): void {

        this.inviteService.toggleInviteStatus( inviteId ).subscribe(
            response => {

                this.filterService.filter();

                if ( response.success ) {

                    this.commonService.notifications.success( 'Success', 'status changed successfully' );
                } else {

                    this.commonService.notifications.error( 'error', 'some error occured' );
                }
            }
        );
    }

    updateParams ( params: any ): HttpParams {
        let httpParams = new HttpParams();

        const multipleValueFilters = [ 'company_id', 'lot_id', 'invite_status', 'email_status' ];

        Object.keys( params ).forEach( key => {
            if ( multipleValueFilters.includes( key ) ) {
                httpParams = httpParams.set( key, params[ key ].toString() );
            } else {
                httpParams = httpParams.set( key, params[ key ] );
            }
        } );

        return httpParams;
    }

    getStatusColor ( status: InviteStatusType ): string {
        if ( status === InviteStatusType.PENDING ) {
            return 'label-warning'
        }
        if ( status === InviteStatusType.COMPLETED ) {
            return 'label-success';
        }
        if ( status == InviteStatusType.CANCELLED ) {
            return 'label-danger'
        }
        return '';
    }

    showToggleButton ( status: InviteStatusType ): boolean {

        if ( status === InviteStatusType.COMPLETED ) return false;

        return true;
    }

    showFollowUpButton ( invite: any ): boolean {

        if ( invite.is_active && invite.follow_up_count < this.inviteService.maxFollowUpallowed ) return true;

        return false;
    }
}
