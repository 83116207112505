<div class="wrapper-md">
    <div class="row">
        <div class="col-md-offset-3 col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Add New District
                </div>

                <form [formGroup]="addDistrictFormGroup" (ngSubmit)="submit()">
                    <div class="panel-body">
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label class="center-block">District Name</label>
                                <input formControlName="name" class="form-control">
                            </div>
                            <div *ngIf="states" class="col-md-6 form-group">
                                <label class="center-block"> State</label>
                                <input formControlName="state_name" [typeahead]="states" [typeaheadMinLength]="0"
                                    typeaheadOptionField="name" placeholder="Select State"
                                    (typeaheadOnSelect)="OnStateSelect($event)"
                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="panel-footer text-right">
                        <div class="row">
                            <div class="col-sm-offset-8 col-sm-4">
                                <button class="btn btn-primary" [disabled]="!addDistrictFormGroup.valid">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>