import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Pagination } from 'src/app/core/models/pagination.model';
import { DownloadExcelService } from 'src/app/core/services/download-excel.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { DownloadParamsComponent } from '../download-params/download-params.component';
import { DownloadLogStatus } from 'src/app/core/enums/download-log-status.enum';
import { DownloadLog } from 'src/app/core/models/download-log.model';

@Component( {
    selector: 'app-download-list',
    templateUrl: './download-list.component.html',
} )
export class DownloadListComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();

    constructor (
        private downloadExcelService: DownloadExcelService,
        protected router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService
    ) { }

    ngOnInit () {
        this.route.queryParams.subscribe( ( params: { [ x: string ]: string | number | boolean; } ) => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.fetchDownloadLogs();

        } );
    }

    fetchDownloadLogs () {
        this.downloadExcelService.getDownloadLogs( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );
    }

    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    openParamsModal ( params: any ) {
        const config = {
            initialState: {
                params: params
            },
            class: 'modal-lg',
            backdrop: 'static',
        }
        this.modalService.showModal( DownloadParamsComponent, config );
    }

    isDownloadComplete ( downloadLog: DownloadLog ): boolean {

        if ( downloadLog.status == DownloadLogStatus.COMPLETED ) {

            return true;

        }

        return false;
    }
}
