import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeCreateComponent } from './employee-create/employee-create.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { EmployeeService } from '../core/services/employee.service';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { EmployeeImportComponent } from './employee-import/employee-import.component';
import { EmployeeAdditionalDataService } from '../core/services/employee-additional-data.service';
import { EmployeeBulkUpdateComponent } from './employee-bulk-update/employee-bulk-update.component';
import { UberImportComponent } from './uber-import/uber-import.component';
import { EmployeeDashboardComponent } from './employee-dashboard/employee-dashboard.component';
import { InsuffEmailModalComponent } from './insuff-email-modal/insuff-email-modal.component';
import { EmployeeFileListComponent } from './employee-file-list/employee-file-list.component';
import { EmployeeAttachmentsDocumentsListComponent } from './employee-attachments-documents-list/employee-attachments-documents-list.component';
import { CandidateDocumentsPageComponent } from './candidate-documents-page/candidate-documents-page.component';
import { CandidateDocumentItemComponent } from './candidate-documents-page/candidate-document-item/candidate-document-item.component';
import { DocumentActionModalComponent } from './candidate-documents-page/document-action-modal/document-action-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CandidateDocumentCropComponent } from './candidate-documents-page/candidate-document-crop/candidate-document-crop.component';
import { CandidateDocumentService } from './../core/services/candidate-document.service'
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EmployeeDashboardFiltersComponent } from '../common/employee-dashboard-filters/employee-dashboard-filters.component';

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgxPaginationModule,
        TrimValueAccessorModule,
        PdfViewerModule,
        ImageCropperModule,
        TooltipModule,

    ],
    declarations: [
        EmployeeDetailsComponent,
        EmployeeCreateComponent,
        EmployeeEditComponent,
        EmployeeImportComponent,
        EmployeeBulkUpdateComponent,
        UberImportComponent,
        EmployeeDashboardComponent,
        InsuffEmailModalComponent,
        EmployeeFileListComponent,
        EmployeeAttachmentsDocumentsListComponent,
        CandidateDocumentsPageComponent,
        CandidateDocumentItemComponent,
        DocumentActionModalComponent,
        CandidateDocumentCropComponent,
        EmployeeDashboardFiltersComponent
    ],
    providers: [
        EmployeeService, EmployeeAdditionalDataService, CandidateDocumentService
    ],
    entryComponents: [
        InsuffEmailModalComponent,
    ],
} )
export class EmployeeModule {
}
