import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Sms } from 'src/app/core/models/sms.model';

@Component( {
    selector: 'app-sms-log-status-time-line',
    templateUrl: './sms-log-status-time-line.component.html'
} )
export class SmsLogStatusTimeLineComponent {
    @Input() logs: Sms[] = [];

    constructor (
        public bsModalRef: BsModalRef ) { }

    onClose (): void {

        this.bsModalRef.hide();
    }
}
