<ng-container *ngIf="identityVerification">

    <div class="row bg-light lter b-b wrapper-md" *ngIf="identityVerification">
        <div class="col-md-8">
            <h1 class="m-n font-thin h3">{{ identityVerification.employee.name }} Verification</h1>
            <ul class="breadcrumb insta-breadcrumb">
                <li><a>Home</a></li>
                <li><a>{{ identityVerification.company.name }}</a></li>
                <li><a>{{ identityVerification.lot.name }}</a></li>
                <li><a>{{ identityVerification.employee.track_id }}</a></li>
                <li>Created on : {{ identityVerification.created_at }}</li>
            </ul>
        </div>
        <div class="col-md-4 text-right text-center-xs">
            <div class="btn-group">
                <button [disabled]="formSubmitting" (click)="onExitClicked()" class="btn btn-danger">
                    Exit Task
                </button>
                <button [disabled]="!form.valid || formSubmitting" (click)="onDoneClicked()" class="btn btn-primary">
                    Task Done
                </button>
            </div>
        </div>
    </div>

    <div class="wrapper-md" *ngIf="!formSubmitting">
        <div class="panel">
            <div class="panel-body">

                <div class="row">
                    <form class="col-md-6 pull-right" [formGroup]="form">
                        <div class="row">

                            <!-- <div *ngIf="statuses" class="col-md-6 form-group pull-right">
                                <label class="center-block">Verification Status</label>
                                <select class="form-control" formControlName="verification_status">
                                    <option *ngFor="let status of statuses" value="{{status.id}}">
                                        {{status.name}}
                                    </option>
                                </select>
                            </div> -->

                        </div>

                    </form>
                </div>

                <div class="row">

                    <div class="col-md-6">

                        <div class="row">

                            <div *ngIf="identityVerification.front_url">
                                <app-document-cropper [verification]="'identity'" [verificationId]="verificationId"
                                    [document]="identityVerification.front_url" [documentType]="'front_url'">
                                </app-document-cropper>
                            </div>

                        </div>

                    </div>

                    <div class="col-md-6">

                        <div class="row">

                            <div *ngIf="identityVerification.back_url">
                                <app-document-cropper [verification]="'identity'" [verificationId]="verificationId"
                                    [document]="identityVerification.back_url" [documentType]="'back_url'">
                                </app-document-cropper>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>

</ng-container>