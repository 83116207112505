import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable( {
    providedIn: 'root',
} )
export class PermissionsService {

    constructor ( private apiService: ApiService ) {
    }

    handlePermissions ( permissions: any[] ) {
        this.setPermissions( permissions );
    }

    setPermissions ( permissions: any[] ) {
        localStorage.setItem( 'permissions', JSON.stringify( permissions ) );
    }

    getStoredPermissions () {
        return localStorage.getItem( 'permissions' );
    }

    getAllPermissions (): Observable<any> {
        return this.apiService.get( 'permissions' );
    }

    assignPermissions ( values: Object ) {
        return this.apiService.post( 'attach/permission', values );
    }

    getUserAssignedPermissions ( id: number ) {
        return this.apiService.get( 'user/' + id + '/permissions' );
    }

    getPermissionSubjects (): Observable<any> {
        return this.apiService.get( 'permission/subjects' );
    }

    getPermissionGroups ( name: string ): Observable<any> {
        return this.apiService.get( 'permission/groups/' + name );
    }

}
