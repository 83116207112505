<div bsModal #submitNewVcourtRequest="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="createFormGroup" (ngSubmit)="createJob()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Submit New Request</h4>

                </div>
                <div class="panel-body">

                    <div class="row">
                        <div class="col-md-4 form-group">
                            <label class="control-label">Type</label>
                            <select class="form-control" formControlName="type">
                                <option value="candidate">Candidate</option>
                                <option value="company">Company</option>
                            </select>
                        </div>

                        <div class="col-md-4 form-group">
                            <label class="center-block">Track ID</label>
                            <small class="text-danger"
                                [hidden]="createFormGroup.get('track_id')?.valid || createFormGroup.get('track_id')?.pristine">
                                Required and alpha-numeric allowed.
                            </small>
                            <input formControlName="track_id" class="form-control">

                        </div>
                        <div class="col-md-4 form-group">
                            <label class="center-block">Name</label>
                            <small class="text-danger"
                                [hidden]="createFormGroup.get('name')?.valid || createFormGroup.get('name')?.pristine">
                                Required and alphabets allowed
                            </small>
                            <input formControlName="name" class="form-control">
                        </div>
                    </div>
                    <div class="row" *ngIf="createFormGroup.get('type')?.value=='candidate'">

                        <div class="col-md-4 form-group">
                            <label class="center-block">Father Name</label>
                            <small class="text-danger"
                                [hidden]="createFormGroup.get('father_name')?.valid || createFormGroup.get('father_name')?.pristine">
                                Only alphabets allowed
                            </small>
                            <input formControlName="father_name" class="form-control">
                        </div>

                        <div class="col-md-4 form-group">
                            <label class="center-block">State</label>
                            <small class="text-danger"
                                [hidden]="createFormGroup.get('state')?.valid || createFormGroup.get('state')?.pristine">
                                {{errorMessage}}
                            </small>
                            <input formControlName="state" class="form-control">

                        </div>
                        <div class="col-md-4 form-group">
                            <label class="center-block">District</label>
                            <small class="text-danger"
                                [hidden]="createFormGroup.get('district')?.valid || createFormGroup.get('district')?.pristine">
                                {{errorMessage}}
                            </small>
                            <input formControlName="district" class="form-control">
                        </div>
                    </div>

                    <div class="row" *ngIf="createFormGroup.get('type')?.value=='candidate'">
                        <div class="col-md-8">
                            <label class="control-label">Address</label>
                            <small class="text-danger"
                                [hidden]="createFormGroup.get('address')?.valid || createFormGroup.get('address')?.pristine">
                                {{errorMessage}}
                            </small>
                            <textarea name='address' class="form-control" formControlName="address" rows="1"></textarea>
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="center-block">PinCode</label>
                            <small class="text-danger"
                                [hidden]="createFormGroup.get('pincode')?.valid || createFormGroup.get('pincode')?.pristine">
                                Numeric and 6 digits long allowed.
                            </small>
                            <input formControlName="pincode" class="form-control">
                        </div>
                    </div>

                    <div class="row" *ngIf="createFormGroup.get('type')?.value=='candidate'">
                        <div class="col-md-8">
                            <label class="control-label">Landmark</label>
                            <textarea name='landmark' class="form-control" formControlName="landmark"
                                rows="1"></textarea>
                        </div>
                        <div class="col-md-4">
                            <label class="control-label">Via</label>
                            <select class="form-control" formControlName="via">
                                <option value="verify24*7">Verify24*7</option>
                                <option value="instaveritas">Instaveritas</option>
                                <option value="both">Both</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-primary" [disabled]="!createFormGroup.valid">
                            Submit Job
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>