import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router, ParamMap } from '@angular/router';
import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { LotService } from '../../core/services/lot.service';
import { switchMap } from 'rxjs/operators';
import { CompanyService } from 'src/app/core/services/company.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-lot-deleted',
    templateUrl: './lot-deleted.component.html',
    styleUrls: [ './lot-deleted.component.css' ],
} )
export class LotDeletedComponent implements OnInit {

    loading!: boolean;
    pagination: Pagination = new Pagination();
    filterFormGroup!: FormGroup;
    lots: any;
    filterParams: HttpParams = new HttpParams();
    company_id!: number;


    constructor ( private route: ActivatedRoute,
        private lotService: LotService,
        private companyService: CompanyService,
        private router: Router,
        private fb: FormBuilder,
        private filterService: FilterService,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) {
    }


    ngOnInit () {
        this.getAllDeleted();
    }

    getAllDeleted () {
        this.route.paramMap.pipe(
            switchMap( ( params: ParamMap ) => this.lotService.getDeleted( +params.get( 'id' )! ) ) )
            .subscribe(
                {
                    next: ( data: any ) => {
                        this.pagination = data
                    },
                } );
    }

    restoreLot ( id: number ): void {

        this.lotService.restoreLot( id ).subscribe( {
            next: ( response: any ) => {

                this.commonService.notifications.success( 'Lot restored!', 'Success!' );

                this.getAllDeleted();

            },
            error: ( error: any ) => {

                this.commonService.notifications.error( error );

            }
        }
        );
    }

    onRestoreLotButtonClicked ( id: number ) {

        const options: ConfirmationModalOptions = { text: 'Selected will be restored in lots list!' };

        this.confirmationModalService.confirm( options, () => this.restoreLot( id ) );

    }

    deleteLot ( id: number ): void {

        this.lotService.permanentDeleteLot( id ).subscribe( {
            next: ( response: any ) => {

                this.commonService.notifications.success( 'Lot deleted!', 'Success!' );

                this.getAllDeleted();

            },
            error: ( error: any ) => {

                this.commonService.notifications.error( error );

            }
        } );
    }

    onDeleteLotButtonClicked ( id: number ) {

        const options: ConfirmationModalOptions = { text: 'This action cannot be reverted!' };

        this.confirmationModalService.confirm( options, () => this.deleteLot( id ) )
    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );

    }


}
