import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { EpfoService } from '../core/services/epfo.service';
import { SharedModule } from '../core/shared.module';
import { EpfoListComponent } from './epfo-list/epfo-list.component';
import { EpfoExcelImportComponent } from './epfo-excel-import/epfo-excel-import.component';
import { EpfoFiltersComponent } from './epfo-filters/epfo-filters.component';
import { EpfoSearchModalComponent } from './epfo-search-modal/epfo-search-modal.component';
import { EpfoStatsComponent } from './epfo-stats/epfo-stats.component';
import { EpfoResponseModalComponent } from './epfo-response-modal/epfo-response-modal.component';
@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        EpfoListComponent,
        EpfoExcelImportComponent,
        EpfoFiltersComponent,
        EpfoSearchModalComponent,
        EpfoResponseModalComponent,
        EpfoStatsComponent ],
    providers: [ EpfoService ],
} )
export class EpfoModule {
}
