<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>

<div *ngIf="verificationFormGroup;then editMultiForm else editForm">
</div>

<ng-template #emptyDiv>

    <div class="col-md-3 form-group">

    </div>
</ng-template>
<ng-template #editMultiForm>
    <div [formGroup]="verificationFormGroup">
        <div formArrayName="formArray">
            <div [formGroupName]="formGName">
                <div class="panel-body">

                    <div class="row">
                        <ul>
                            <li *ngFor="let work of editFormGroup.get('work_assigned')?.value">
                                <strong>{{work.title}} is assigned to {{work.assigned_to}}</strong>
                            </li>
                        </ul>
                    </div>

                    <div class="row">


                        <div
                            *ngIf="(editFormGroup.get('status_id')?.value | inArray: validStatusesForWorkCompleted);then taskCompletedDiv else emptyDiv">

                        </div>
                        <ng-template #taskCompletedDiv>
                            <div class="col-md-3 form-group">
                                <label class="center-block">Task Completed
                                    <select class="form-control" formControlName="work_completed">
                                        <option [value]="1">YES</option>
                                        <option [value]="0">NO</option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="editFormGroup.get('work_completed')?.errors">
                                    Required
                                </small>
                            </div>
                        </ng-template>

                        <div class="col-md-3 form-group">
                            <app-task-completed [verification]="'address'"
                                [workAssigned]="editFormGroup.get('work_assigned')?.value"></app-task-completed>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">QA Required
                                <select class="form-control" formControlName="qa_required"
                                    [required]="editFormGroup.get('status_id')?.value | inArray:[37,38]">
                                    <option [value]="0">
                                        NO
                                    </option>
                                    <option [value]="1">
                                        YES
                                    </option>
                                </select>
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">QA done
                                <select class="form-control" formControlName="qa_done"
                                    [required]="editFormGroup.get('qa_required')?.value | inArray:[37,38]">
                                    <option [value]="0">
                                        NO
                                    </option>
                                    <option [value]="1">
                                        YES
                                    </option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('qa_done')?.errors">
                                Required
                            </small>
                        </div>
                    </div>


                    <div class="row">

                        <div *ngIf="statuses" class="col-md-3 form-group">
                            <label class="center-block"> Status

                                <input formControlName="status_name" [typeahead]="statuses['address']"
                                    [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select Status"
                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                    (typeaheadOnSelect)="OnTypeheadSelect($event,'status')">
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Area Type
                                <select class="form-control" formControlName="area_type" required>
                                    <option [value]="'urban'">Urban</option>
                                    <option [value]="'rural'">Rural</option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('area_type')?.errors">
                                Required
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Case Type
                                <select class="form-control" formControlName="case_type">
                                    <option [value]="0">TAT</option>
                                    <option [value]="1">NON-TAT</option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('case_type')?.errors">
                                Required
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Nearest Police Station
                                <input class="form-control" formControlName="police_station">
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block">{{editFormGroup.get('type')?.value}}
                                <textarea row="5" class="form-control textarea-height" formControlName="address">
                                                </textarea>
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('address')?.errors">
                                Required
                            </small>
                        </div>

                        <div class="col-md-3 form-group">
                            <app-state-typeahead (changed)="onStateChanged($event)" [formGroup]="editFormGroup">
                            </app-state-typeahead>
                        </div>

                        <div class=" col-md-3 form-group">
                            <app-district-typeahead [stateId]="editFormGroup.get('state_id')?.value"
                                (changed)="onDistrictChanged($event)" [formGroup]="editFormGroup">
                            </app-district-typeahead>
                            <small class="text-danger" *ngIf="editFormGroup.get('district_id')?.errors">
                                Required
                            </small>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="center-block">LandMark:
                                <input class="form-control" formControlName="landmark">
                            </label>
                        </div>

                        <div class="form-group col-md-3">

                            <ng-template #popTemplate>
                                <ul class="list-unstyled" style="max-height: 250px;overflow-y: scroll">
                                    <li>
                                        State - District - Taluk - P.Office
                                    </li>
                                    <li *ngFor="let text of pincodeText">
                                        {{text}}
                                    </li>
                                </ul>

                            </ng-template>

                            <label class="center-block">Pincode:
                                <a class="btn btn-xs" style="line-height: 1.1;" [outsideClick]="true"
                                    [popover]="popTemplate" popoverTitle="Pincode Details"
                                    (click)="pincodeDetails(editFormGroup.get('pincode')?.value)">?</a>

                                <input class="form-control" formControlName="pincode">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('pincode')?.errors">
                                Required
                            </small>
                        </div>

                    </div>


                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label class="center-block">Duration of Stay
                                <input class="form-control" formControlName="duration_of_stay">
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Reference Name
                                <input class="form-control" formControlName="reference_name">
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Reference Number
                                <input class="form-control" formControlName="reference_number">
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Reference Relation
                                <input class="form-control" formControlName="reference_relation">
                            </label>
                        </div>

                    </div>
                    <div class="row">

                        <div class="col-md-3 form-group">
                            <label class="center-block">Post Office
                                <input class="form-control" formControlName="post_office">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('post_office')?.errors">
                                Invalid format
                            </small>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">India Post Id
                                <input class="form-control" formControlName="india_post_id">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('india_post_id')?.errors">
                                Invalid format
                            </small>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Mode of verification
                                <select class="form-control" formControlName="mode">
                                    <option [value]="'physical'">Physical</option>
                                    <option [value]="'postal'">Postal</option>
                                    <option [value]="'digital'">Digital</option>
                                </select>
                            </label>
                        </div>

                        <div class="form-group col-md-3">
                            <label class="center-block">Select Vendor
                                <input formControlName="vendor_name" [typeahead]="vendors" [typeaheadMinLength]="0"
                                    typeaheadOptionField="name" placeholder="Select Vendor"
                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                    (typeaheadOnSelect)="OnTypeheadSelect($event,'vendor')">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('vendor_id')?.errors">
                                Required
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Sent On
                                <input type="date" class="form-control" formControlName="sent_to_vendor_on"
                                    min="{{created_at}}" max="{{current_date}}">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('sent_to_vendor_on')?.errors">
                                Invalid
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Date of Verification
                                <input type="date" class="form-control" min="{{created_at}}" max="{{current_date}}"
                                    formControlName="completed_at">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('completed_at')?.errors">
                                Invalid
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 form-group">
                            <app-language-typeahead [formGroup]="editFormGroup"></app-language-typeahead>
                        </div>
                        <div class="form-group col-md-9">
                            <label class="center-block">Client Remarks:
                                <textarea class="form-control" rows="1" formControlName="client_remarks">
                                                </textarea>
                            </label>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3 form-group">
                            <label class="center-block">Insufficiency Raised On
                                <input readonly type="date" class="form-control" formControlName="insuff_raised_at">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('insuff_raised_at')?.errors">
                                Invalid
                            </small>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Insufficiency Cleared On
                                <input readonly type="date" class="form-control" formControlName="insuff_cleared_at">
                            </label>
                            <small class="text-danger" *ngIf="editFormGroup.get('insuff_cleared_at')?.errors">
                                Invalid
                            </small>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3">
                            <label>Upload Proof
                                <input type="file" id="proof_url_{{editFormGroup.get('id')?.value}}"
                                    class="form-control" [accept]="acceptedFileType"
                                    (change)="upload('proof_url', $event)" multiple>
                            </label>

                        </div>

                        <div class="col-md-3">
                            <label>Attachments
                                <input type="file" id="attachment_{{editFormGroup.get('id')?.value}}"
                                    class="form-control" multiple [accept]="acceptedFileType"
                                    (change)="upload('attachment',$event)" />
                            </label>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-md-3" *ngIf="editFormGroup.get('proof_url')?.value; else elseDiv">
                            <div class="row">

                                <div class="col-md-12">
                                    <a target="_blank" [href]="editFormGroup.get('proof_url')?.value" class="btn-link">
                                        Proof Url
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="attachments && attachments['attachments']; else elseDiv">
                            <div class="row" *ngFor="let attachment of attachments['attachments']">
                                <div class="col-md-12">
                                    <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                        {{attachment['file_name']}}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <ng-template #elseDiv>
                            <div class="col-md-3">
                                No File Attached
                            </div>
                        </ng-template>
                    </div>

                    <hr style="margin-left: -20px; margin-right: -20px; margin-bottom: 12px; margin-top:12px">

                    <div class="row" *ngIf="proofUrl" style="margin-top: 2rem;">
                        <div class="col-md-12">
                            <label><b>Digital Address Proof</b></label>
                        </div>

                        <div class="col-md-8">
                            <a [href]="proofUrl" target="_blank">
                                <img [src]="proofUrl" height="400px">
                            </a>
                        </div>

                        <div class="col-md-4">

                            <div class="row">
                                <div class="col-md-12">
                                    <strong>Digital Address Status:
                                        {{digitalAddressStatus?.value | uppercase}}
                                    </strong>
                                </div>

                                <div class="col-md-12"> <strong>Property Type: </strong>
                                    {{propertyType?.value | titlecase}}
                                </div>

                                <div class="col-md-12"> <strong>Device Type: </strong> {{deviceTypeValue | titlecase}}
                                </div>

                                <div class="col-md-12"><strong>Device Lat-Long: </strong>
                                    {{deviceLatitude?.value}}, {{deviceLongitude?.value}}
                                </div>

                                <div class="col-md-12"> <strong>Address Lat-Long: </strong>
                                    {{addressLat?.value}}, {{addressLong?.value}}
                                </div>

                                <div class="col-md-12">
                                    <strong>Distance: </strong>
                                    {{distanceValue}} km
                                </div>
                            </div>



                            <div class="row" style="margin-top: 32%;">

                                <div class="col-md-12" style="margin-bottom: 20px;font-size: 16px;">
                                    <strong><b>Legends </b></strong>
                                </div>

                                <div class="col-md-6">
                                    <strong>Device Location Indicator: </strong>
                                </div>

                                <div class="col-md-6">
                                    <img src="../../../assets/images/location_icon.png" width="25" height="25">
                                </div>

                                <div class="col-md-6">
                                    <strong>Address Location Indicator: </strong>
                                </div>

                                <div class="col-md-6">
                                    <img src="../../../assets/images/google_map_pin.png" width="25" height="25">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <strong>Address Unverified: </strong>
                                </div>

                                <div class="col-md-6">
                                    <img src="../../../assets/images/address_unverified_logo.png" width="25"
                                        height="25">
                                </div>

                                <div class="col-md-6">
                                    <strong>Address Verified: </strong>
                                </div>

                                <div class="col-md-6">
                                    <img src="../../../assets/images/address_verified_logo.png" width="25" height="25">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #editForm>
    <div [formGroup]="editFormGroup">
        <div class="panel-body">

            <div class="row">

                <div *ngIf="statuses" class="col-md-3 form-group">
                    <label class="center-block"> Status </label>

                    <input formControlName="status_name" [typeahead]="statuses['address']" [typeaheadMinLength]="0"
                        typeaheadOptionField="name" placeholder="Select Status"
                        [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                        (typeaheadOnSelect)="OnTypeheadSelect($event,'status')">
                </div>

                <div class="col-md-3 form-group">
                    <label class="center-block">Area Type
                        <select class="form-control" formControlName="area_type" required>
                            <option [value]="'urban'">Urban</option>
                            <option [value]="'rural'">Rural</option>
                        </select>
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('area_type')?.errors">
                        Required
                    </small>
                </div>
                <div class="col-md-3 form-group">
                    <label class="center-block">Case Type
                        <select class="form-control" formControlName="case_type">
                            <option [value]="0">TAT</option>
                            <option [value]="1">NON-TAT</option>
                        </select>
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('case_type')?.errors">
                        Required
                    </small>
                </div>
                <div class="col-md-3 form-group">
                    <label class="center-block">Nearest Police Station
                        <input class="form-control" formControlName="police_station">
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 form-group">
                    <label class="center-block">{{editFormGroup.get('type')?.value}}
                        <textarea rows=5 class="form-control textarea-height" formControlName="address">
                                                </textarea>
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('address')?.errors">
                        Required
                    </small>
                </div>

                <div class="col-md-3 form-group">
                    <app-state-typeahead (changed)="onStateChanged($event)" [formGroup]="editFormGroup">
                    </app-state-typeahead>
                </div>

                <div class="col-md-3 form-group">
                    <app-district-typeahead [stateId]="editFormGroup.get('state_id')?.value"
                        (changed)="onDistrictChanged($event)" [formGroup]="editFormGroup">
                    </app-district-typeahead>
                </div>

                <div class="form-group col-md-3">
                    <label class="center-block">LandMark:
                        <input class="form-control" formControlName="landmark">
                    </label>
                </div>

                <div class="form-group col-md-3">

                    <ng-template #popTemplate>
                        <ul class="list-unstyled" style="max-height: 250px;overflow-y: scroll">
                            <li>
                                State - District - Taluk - P.Office
                            </li>
                            <li *ngFor="let text of pincodeText">
                                {{text}}
                            </li>
                        </ul>

                    </ng-template>

                    <label class="center-block">Pincode:
                        <a class="btn btn-xs" [outsideClick]="true" [popover]="popTemplate"
                            popoverTitle="Pincode Details"
                            (click)="pincodeDetails(editFormGroup.get('pincode')?.value)">?</a>

                        <input class="form-control" formControlName="pincode">
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('pincode')?.errors">
                        Required
                    </small>
                </div>

            </div>


            <div class="row">
                <div class="col-md-3 form-group">
                    <label class="center-block">Duration of Stay
                        <input class="form-control" formControlName="duration_of_stay">
                    </label>
                </div>

                <div class="col-md-3 form-group">
                    <label class="center-block">Reference Name
                        <input class="form-control" formControlName="reference_name">
                    </label>
                </div>

                <div class="col-md-3 form-group">
                    <label class="center-block">Reference Number
                        <input class="form-control" formControlName="reference_number">
                    </label>
                </div>

                <div class="col-md-3 form-group">
                    <label class="center-block">Reference Relation
                        <input class="form-control" formControlName="reference_relation">
                    </label>
                </div>


            </div>
            <div class="row">

                <div class="col-md-3 form-group">
                    <label class="center-block">India Post Id
                        <input class="form-control" formControlName="india_post_id">
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('india_post_id')?.errors">
                        Invalid format
                    </small>
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Select Vendor
                        <input formControlName="vendor_name" [typeahead]="vendors" [typeaheadMinLength]="0"
                            typeaheadOptionField="name" placeholder="Select Vendor"
                            [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                            (typeaheadOnSelect)="OnTypeheadSelect($event,'vendor')">
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('vendor_id')?.errors">
                        Required
                    </small>
                </div>
                <div class="form-group col-md-3">
                    <label class="center-block">Sent On
                        <input type="date" class="form-control" formControlName="sent_to_vendor_on">
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('sent_to_vendor_on')?.errors">
                        Required
                    </small>
                </div>
                <div class="col-md-3 form-group">
                    <label class="center-block">Date of Verification
                        <input type="date" class="form-control" min="{{created_at}}" max="{{current_date}}"
                            formControlName="completed_at">
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('completed_at')?.errors">
                        Required
                    </small>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3 form-group">
                    <app-language-typeahead [formGroup]="editFormGroup"></app-language-typeahead>
                </div>
                <div class="form-group col-md-9">
                    <label class="center-block">Client Remarks:
                        <textarea class="form-control" rows="1" formControlName="client_remarks">
                                                </textarea>
                    </label>
                    <small class="text-danger" *ngIf="editFormGroup.get('client_remarks')?.errors">
                        Required
                    </small>
                </div>
            </div>

            <div class="row">

                <div class="col-md-3 form-group">
                    <label class="center-block">Insufficiency Raised On</label>
                    <input readonly type="date" class="form-control" formControlName="insuff_raised_at">
                    <small class="text-danger" *ngIf="editFormGroup.get('insuff_raised_at')?.errors">
                        Invalid
                    </small>
                </div>

                <div class="col-md-3 form-group">
                    <label class="center-block">Insufficiency Cleared On</label>
                    <small class="text-danger" *ngIf="editFormGroup.get('insuff_cleared_at')?.errors">
                        Invalid
                    </small>
                    <input readonly type="date" class="form-control" formControlName="insuff_cleared_at">
                </div>
            </div>
            <div class="row">

                <div class="col-md-3">

                    <label>Upload Proof
                        <input type="file" id="proof_url_{{verification.id}}" ng2FileSelect [uploader]="uploader"
                            class="form-control" [accept]="acceptedFileType" (change)="upload('proof_url',$event)"
                            multiple>
                    </label>
                </div>

                <div class="col-md-3">

                    <label>Attachments
                        <input type="file" id="attachment_{{verification.id}}" class="form-control" multiple
                            [accept]="acceptedFileType" (change)="upload('attachment',$event)" />
                    </label>
                </div>

            </div>

            <div class="row" *ngIf="verification.attachments">

                <div class="col-md-3" *ngIf="verification.proof_url; else elseDiv">
                    <div class="row">

                        <div class="col-md-12">
                            <a target="_blank" href="{{verification.proof_url}}" class="btn-link">
                                Proof Url
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3" *ngIf="verification.attachments['attachments']; else elseDiv">
                    <div class="row" *ngFor="let attachment of verification.attachments['attachments']">
                        <div class="col-md-12">
                            <a target="_blank" href="{{attachment['full_path']}}" class="btn-link">
                                {{attachment['file_name']}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #elseDiv>
                <div class="col-md-3">
                    No File Attached
                </div>
            </ng-template>

        </div>
    </div>
</ng-template>