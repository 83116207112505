import { BsModalRef } from 'ngx-bootstrap/modal';
import { NewPagination } from './../models/pagination.model';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Params } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { UsersStub } from 'src/app/user/user-list/users.stub';


interface DownloadStats {
    percentage: number,
    estimatedTime: number,
    maxCount: number,
    downloadedCount: number
}

@Injectable( {
    providedIn: 'root',
} )
export class DownloadExcelService extends ExportExcelComponent {

    @Output() downloadStats = new EventEmitter<DownloadStats>();

    // this is a common service for download excel so items structure is not defined
    private excelItems: any;

    private downloadCancelled: boolean = false;

    private totalTime: number = 0;

    constructor (
        private apiService: ApiService,
        private commonService: CommonService,
    ) {
        super()
    }


    download ( route: string, params: Params, paramString: string, purpose: string, fileName: string, method: string, postBody: Params, bsModalRef?: BsModalRef ): void {

        this.downloadCancelled = false;

        this.excelItems = [];

        this.requestAccess( route, paramString, purpose, method, postBody ).subscribe(
            response => {

                this.downloadPaginatedData( response.token, route, params, fileName, method, postBody, bsModalRef );

            },
            error => {

                this.commonService.notifications.error( 'Error', 'Unable to get download permission' );

                this.hideModal( bsModalRef );
            }
        );
    }

    cancel () {
        this.downloadCancelled = true;
    }


    private downloadPaginatedData ( token: string, route: string, params: any, fileName: string, method: string, postBody: any, bsModalRef: any ) {

        if ( this.downloadCancelled ) {

            this.hideModal( bsModalRef );

            this.terminateAccess( token, this.excelItems.length, 'cancelled' ).subscribe(

                success => {

                    this.commonService.notifications.error( 'Cancelled!', 'Download Cancelled' );
                }
            )

            this.excelItems = [];

            return;
        }

        const startTimestamp = new Date().getTime();

        this.getData( token, route, params, method, postBody ).subscribe(

            response => {

                this.excelItems = this.excelItems.concat( response.data );

                this.emitDownloadStats( startTimestamp, response );

                if ( response.next_page_url ) {

                    params = params[ 'set' ]( 'page', response.next_page_url.split( '=' )[ 1 ] );

                    this.downloadPaginatedData( token, route, params, fileName, method, postBody, bsModalRef );

                } else {

                    this.downloadExcel( this.excelItems, fileName );

                    this.terminateAccess( token, this.excelItems.length, 'downloaded' ).subscribe(

                        success => {

                            this.commonService.notifications.success( 'All Data Fetched' );

                            this.hideModal( bsModalRef );
                        }
                    )
                }
            },
            error => {

                this.commonService.notifications.error( 'Error', error.error.message );
                this.hideModal( bsModalRef );
            }
        );
    }

    private hideModal ( bsModalRef?: BsModalRef ): void {
        if ( bsModalRef ) {
            bsModalRef.hide();
        }
    }

    private emitDownloadStats ( startTimestamp: any, response: any ): void {

        const endTimeStamp = new Date().getTime();

        const percentage = ( response.current_page / response.last_page ) * 100;
        const currentRequestTime = ( endTimeStamp - startTimestamp );
        const remainingPages = ( response.last_page - response.current_page );

        this.downloadStats.emit( {
            percentage: Math.ceil( percentage ),
            estimatedTime: this.averageRequestTime( currentRequestTime, response.current_page ) * remainingPages / 1000,
            maxCount: response.total,
            downloadedCount: this.excelItems.length,
        } );

    }


    private requestAccess ( route: string, paramString: string, purpose: string, method: string, postBody: Params ): Observable<{ 'success': boolean, 'token': string }> {
        return this.apiService.post( 'download-logs', {
            route,
            params: paramString,
            purpose,
            request_method: method,
            post_params: postBody,
        } );
    }


    private terminateAccess ( token: string, recordCount: number, remarks: string ): Observable<{ success: boolean }> {
        return this.apiService.put( 'download-logs', { token, record_count: recordCount, remarks } )
    }

    private getData ( token: string, route: string, routeParams: Params, method: string, postBody: Params ): Observable<NewPagination<any>> {

        if ( method == 'post' ) {
            return this.apiService.postExcel( token, route, postBody, routeParams );
        }

        return this.apiService.getExcel( token, route, routeParams );
    }

    private averageRequestTime ( time: any, totalRequest: any ): number {

        this.totalTime = this.totalTime + time;

        return this.totalTime / totalRequest;
    }

    public getDownloadLogs ( params: Params | HttpParams | undefined, ) {
        return this.apiService.get( 'download-logs', params );
    }

    public newDownloadExcel ( params: Params | HttpParams | undefined ) {
        return this.apiService.post( 'download-logs/create-excel', params );
    }

    downloadClientSampleExcel (): void {

        let { sheetName, fileName } = { sheetName: "client upload sample", fileName: "client_bulk_upload_sample" };

        var wb = XLSX.utils.book_new();

        var ws = XLSX.utils.json_to_sheet( [ { "EMAIL": "client@one.com", "USER_NAME": "Client One", "DESIGNATION": "HR", "MOBILE": "9876543210" } ] );

        XLSX.utils.book_append_sheet( wb, ws, sheetName );

        XLSX.writeFile( wb, `${ fileName }.xlsx` );

    }

    downloadUserSampleExcel (): void {

        let { sheetName, fileName } = { sheetName: "user upload sample", fileName: "user_bulk_upload_sample" };

        var wb = XLSX.utils.book_new();

        var ws = XLSX.utils.json_to_sheet( UsersStub );

        XLSX.utils.book_append_sheet( wb, ws, sheetName );

        XLSX.writeFile( wb, `${ fileName }.xlsx` );

    }
}
