import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { CommonService } from '../../core/services/common.service';
import { ReferenceService } from '../../core/services/reference.service';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-reference-import',
    templateUrl: './reference-import.component.html',
    styleUrls: [ './reference-import.component.css' ],
} )
export class ReferenceImportComponent extends ImportExcelComponent implements OnInit {

    override isSubmitting = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    uploadForm: FormGroup;
    validationMessages: ValidationMessages = {};

    constructor (
        private referenceService: ReferenceService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            'excel': [ '', Validators.required ],
        } );

    }

    ngOnInit () {
    }

    // Initialize the excelForm.
    initExcelForm () {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages () {
        this.validationMessages[ 'candidate_name' ] = 'Required and No special characters allowed';
        this.validationMessages[ 'reference_name' ] = 'Required and No special characters allowed';
        this.validationMessages[ 'reference_mobile_number' ] = '10 digits allowed';
        this.validationMessages[ 'track_id' ] = 'Required" ';
    }

    applyValidations () {

        this.excelFormArray.controls.forEach( form => {
            form.get( 'candidate_name' )?.setValidators( [ Validators.required, validateAllowedCharacters( '' ) ] );
            form.get( 'candidate_name' )?.updateValueAndValidity();
            form.get( 'reference_name' )?.setValidators( [ Validators.required, validateAllowedCharacters( '' ) ] );
            form.get( 'reference_name' )?.updateValueAndValidity();
            form.get( 'track_id' )?.setValidators( [ Validators.required ] );
            form.get( 'track_id' )?.updateValueAndValidity();
            form.get( 'reference_mobile_number' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '10' ) ] );
            form.get( 'reference_mobile_number' )?.updateValueAndValidity();
            this.setValidationMessages();
        } );
    }

    importEmployees () {

        this.referenceService.importEmployees( this.excelForm.value ).subscribe(
            ( response: any ) => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ '/reference' ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
