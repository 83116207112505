export class EmployeeAdditionalDetails {
    zone!: string;
    city!: string;
    current_work!: string;
    current_work_description!: string;
    driving_experience!: string;
    monthly_income!: string;
    commercial_licence_issue_date!: string;
    martial_status!: string;
    number_of_children!: string;
    number_of_dependents!: string;
    referred_by!: string;
    current_address_status!: string;
    residing_since!: string;
    permanent_address_status!: string;
    reference_realtion!: string;
    education_qualification!: string;
    source_of_deposit!: string;
}
