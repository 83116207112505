import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Errors } from '../../core/models/errors.module';
import { UserService } from '../../core/services/user.service';
import { CommonService } from '../../core/services/common.service';

import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { User } from '../../core/models/user.model';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: [ './user-edit.component.css' ],
} )
export class UserEditComponent {

    isSubmitting = false;
    userEditForm!: FormGroup;
    errors: Errors = new Errors();
    user: User = new User();

    constructor ( private userService: UserService,
        private commonService: CommonService,
        public global: GlobalService,
        private fb: FormBuilder,
        private router: Router ) {
        this.initForm();
    }

    get userLevels () { return this.global.fetchUserLevels(); }

    @ViewChild( 'userEditModal', { static: false } )
    public userEditModal!: ModalDirective;

    @Output()
    onUserEdited = new EventEmitter<boolean>();

    show ( user: any ) {
        this.user = user;
        this.userEditForm.patchValue( this.user );
        this.userEditModal.show();
    }

    hide () {
        this.initForm();
        this.userEditModal.hide();
    }

    editUser () {
        this.isSubmitting = true;
        this.userService.update( this.user.id, this.userEditForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( response[ 'message' ] );
                this.isSubmitting = false;
                this.hide();
                this.onUserEdited.emit( true );
            },
            err => {
                this.errors = err;
                this.isSubmitting = false;
            }
        )
    }


    initForm () {
        this.userEditForm = this.fb.group( {
            id: [ '' ],
            name: [ '', Validators.required ],
            email: [ '', [ Validators.required, validateEmail() ] ],
            mobile_number: [ '' ],
            level_id: [ '', Validators.required ],
            level_name: [ '', Validators.required ],
            dob: [ '', Validators.required ],
            designation: [ '', Validators.required ],
        } );
    }

    OnLevelSelected ( event: any ) {
        this.userEditForm.controls[ 'level_id' ].setValue( event.item.id );
    }


}
