<div bsModal #uidaiDetailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" style="max-height: 100%; overflow-y: auto;">
            <div class="modal-header">
                <h4 class="modal-title pull-left">UIDAI details</h4>

            </div>
            <div class="modal-body">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th style="width: 25%">
                                Age Band
                            </th>
                            <th style="width: 25%">
                                Gender
                            </th>
                            <th style="width: 25%">
                                State
                            </th>
                            <th style="width: 25%">
                                Mobile Number
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr *ngFor="let info of details">
                            <td>
                                <ul class="list-unstyled">
                                    <li>{{info.age_band}}</li>
                                </ul>
                            </td>

                            <td>
                                <ul class="list-unstyled">
                                    <li> {{info.gender}}</li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li>{{info.state}}</li>
                                </ul>
                            </td>

                            <td>
                                <ul class="list-unstyled">
                                    <li>{{info.mobile_number}}</li>

                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>