import { SmsLogService } from './../../core/services/sms-log.service';
import { Component, Input } from '@angular/core';
import { SmsLogStatusTimeLineModalInitialState } from 'src/app/core/models/sms-log-status-time-line-modal-initial-state';
import { Sms } from 'src/app/core/models/sms.model';

@Component( {
    selector: 'app-digital-address-invite-sms-list-item',
    templateUrl: './digital-address-invite-sms-list-item.component.html',
} )
export class DigitalAddressInviteSmsListItemComponent {
    @Input() lastSms!: Sms
    @Input() smsLogs!: Sms[]

    get statusLabel (): string {
        return this.smsLogService.getStatusLabel( this.lastSms.status )
    }

    get statusColor (): string {
        return this.smsLogService.getStatusColor( this.lastSms.status )
    }

    constructor ( private smsLogService: SmsLogService ) { }

    showSmsLogStatusTimeLineModal (): void {

        const initialState: SmsLogStatusTimeLineModalInitialState = {
            logs: this.smsLogs
        }

        this.smsLogService.showSmsStatusTimeLineModal( initialState );
    }
}
