import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { JsonUtil } from 'src/app/core/utils/json-util';

@Component({
  selector: 'app-credit-report-response-modal',
  templateUrl: './credit-report-response-modal.component.html',
  styleUrls: ['./credit-report-response-modal.component.css']
})
export class CreditReportResponseModalComponent implements OnInit {

    @ViewChild( 'creditReportResponseModal', { static: false } )
    public creditReportResponseModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();

    public response!: any;
    public source!: any;
    constructor () { }

    ngOnInit () {
    }

    show ( source: string, response: string ): void {
        this.source = source;
        this.setResponse( response );
        this.creditReportResponseModal.show();
    }

    setResponse ( response: string ): void {

        if(JsonUtil.isJsonString(response)) {

            this.response = JSON.parse(response);
        } else {

            this.response = response;
        }
    }

    hide (): void {
        this.creditReportResponseModal.hide();
    }

}
