import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable( {
    providedIn: 'root'
} )
export class EnvironmentService {

    get crmUrl (): string { return environment.crm_url; }

    get apiUrl (): string { return environment.api_url; }

    get oldCrmUrl (): string {
        return environment.old_crm_url;
    }
}
