<div bsModal #addImage="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-body">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Add Images</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
                        <fieldset class="form-group">
                            <select class="form-control" name="selectInput" (change)="onChange()">
                                <option [value]="0">Url</option>
                                <option [value]="1">Image</option>
                            </select>
                        </fieldset>
                        <fieldset [disabled]="submitted">
                            <fieldset *ngIf="!optionSelect" class="form-group">
                                <label>Url 1</label>
                                <input formControlName="first_image_url" class="form-control form-control-lg"
                                    type="text" />
                            </fieldset>
                            <fieldset *ngIf="!optionSelect" class="form-group">
                                <label>Url 2</label>
                                <input formControlName="second_image_url" class="form-control form-control-lg"
                                    type="text" />
                            </fieldset>
                            <fieldset *ngIf="optionSelect" class="form-group">
                                <label class="center-block">Image 1</label>
                                <input type="file" #input1 accept=".jpg,.png,.jpeg"
                                    (change)="onFileChange($event,'first_image_url')">
                            </fieldset>
                            <fieldset *ngIf="optionSelect" class="form-group">
                                <label class="center-block">Image 2</label>
                                <input type="file" #input2 accept=".jpg,.png,.jpeg"
                                    (change)="onFileChange($event,'second_image_url')">
                            </fieldset>
                            <button class="btn btn-lg btn-primary btn-block" type="submit">Add</button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>