<div class="navbar-form navbar-left shift">
    <input [(ngModel)]="asyncSelected" [typeahead]="dataSource" (typeaheadLoading)="changeTypeaheadLoading($event)"
        (typeaheadNoResults)="changeTypeaheadNoResults($event)" (typeaheadOnSelect)="typeaheadOnSelect($event)"
        [typeaheadOptionsLimit]="10" [typeaheadMinLength]="3" typeaheadOptionField="name" [typeaheadWaitMs]="300"
        [typeaheadItemTemplate]="customItemTemplate" placeholder="Search.." class="form-control">

    <div *ngIf="typeaheadLoading===true">
        <ul class="no-search-result-dropdown">
            <li class="" style="padding: 0px 0px 0px 8px">
                Loading..
            </li>
        </ul>
    </div>

    <div *ngIf="typeaheadNoResults===true" class="" style="">
        <ul class="no-search-result-dropdown">
            <li class="" style="padding: 0px 0px 0px 8px">
                No Result Found
            </li>
        </ul>
    </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}} - {{model.track_id}}
    <br>
    {{model.lot_name}}
</ng-template>