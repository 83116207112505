import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { StatusResponse } from '../models/status-response.model';

@Injectable( {
    providedIn: 'root',
} )
export class StatusService {

    statuses!: StatusResponse;
    statusesLoading: boolean = false;

    constructor (
        private api: ApiService,
        private common: CommonService
    ) { }

    populateCache ( force: boolean = false ): void {

        if ( this.statusesLoading ) return;

        if ( force || !this.statuses ) {

            this.statusesLoading = true;

            this.getAll().subscribe( {

                next: ( response ) => {
                    this.statuses = response;
                    this.statusesLoading = false;
                },

                error: () => {
                    this.common.notifications.error( 'Error occurred while getting all statuses' );
                    this.statusesLoading = false;
                }
            } )
        }
    }

    getAll (): Observable<StatusResponse> {
        return this.api.get( 'common/statuses' )
    }
}
