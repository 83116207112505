import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AadhaarService } from '../../core/services/aadhaar.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-uidai-details-modal',
    templateUrl: './uidai-details-modal.component.html',
    styleUrls: [ './uidai-details-modal.component.css' ],
} )
export class UidaiDetailsModalComponent {

    logId!: number;
    details: any;
    receieved!: boolean;

    @ViewChild( 'uidaiDetailsModal', { static: false } )
    public uidaiDetailsModal!: ModalDirective;


    constructor (
        private aadhaarService: AadhaarService,
        private commonService: CommonService
    ) {
    }


    show ( logId: number ): void {

        this.logId = logId;

        this.aadhaarService.show( logId ).subscribe( response => {
            this.details = response;
            this.uidaiDetailsModal.show();

        },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );

    }

    hide (): void {
        this.uidaiDetailsModal.hide();
    }

}
