import { Subscription } from 'rxjs';
import { Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RequestModalComponent } from '../request-modal/request-modal.component';
import { ResponseModalComponent } from '../response-modal/response-modal.component';
import { Pagination } from 'src/app/core/models/pagination.model';
import { ClientApiService } from 'src/app/core/services/client-api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';

@Component( {
    selector: 'app-api-request-logs-list',
    templateUrl: './api-request-logs-list.component.html',
} )
export class ApiRequestLogsListComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();
    data: any;
    filterFormGroup!: FormGroup;
    clients: any;
    pagination: Pagination = new Pagination();
    bsModalRef!: BsModalRef;
    filtersSubscription!: Subscription;

    constructor (
        private clientApiService: ClientApiService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService,
        private modalService: BsModalService
    ) {

        this.initForm();

        this.getAllClients();

        this.updateFormFilter();

        this.filterResults( this.filterFormGroup.value );
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );

        } );

    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            created_before: 'all',
            created_after: 'all',
            client: '',
            response_status: '',
            request_type: 'all',
            end_point: '',
            page: 1,
        } );
    }

    getAllClients (): void {

        let params: HttpParams = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }

    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    filterResults ( filterParams: Params ): void {

        this.clientApiService.getApiLogsList( filterParams )
            .subscribe( ( response ) => {
                this.pagination = response;
            } );
    }

    showPage ( page: number ): void {
        this.filterService.filterByPage( page );
    }

    showRequestModal ( req: any ): void {

        const initialState = {
            request: req,
        };

        this.bsModalRef = this.modalService.show( RequestModalComponent, { initialState } );
        this.bsModalRef.content.closeBtnName = 'Close';

    }

    showResponseModal ( res: any ): void {

        const initialState = {
            response: res,
        };

        this.bsModalRef = this.modalService.show( ResponseModalComponent, { initialState } );
        this.bsModalRef.content.closeBtnName = 'Close';

    }

    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }
}
