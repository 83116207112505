import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { LotService } from '../../core/services/lot.service';

@Component( {
    selector: 'app-lot-list',
    templateUrl: './lot-list.component.html',
    styleUrls: [ './lot-list.component.css' ],
    providers: [ LotService ],
} )
export class LotListComponent implements OnInit {
    loading!: boolean;
    pagination: Pagination = new Pagination();
    filterFormGroup!: FormGroup;
    lots: any;
    filterParams: HttpParams = new HttpParams();
    company_id!: number;


    constructor ( private route: ActivatedRoute, private lotService: LotService, private router: Router,
        private fb: FormBuilder,

        private commonService: CommonService ) {
    }


    ngOnInit (): void {
        this.initForm();
        this.route.params.subscribe( params => this.company_id = params?.[ 'id' ] );
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
        this.commonService.getLotsByCompanyId( this.company_id ).subscribe( ( response ) => {
            this.lots = response;
        } );


    }


    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            lot_id: [ [] ],
            lot_status: '',
        } );
    }

    filterResults () {

        this.lotService.getByCompanyId( this.company_id, this.filterParams ).subscribe(
            response => {
                this.pagination = response;
            }
        );

    }


    get currentUrlPath (): string {
        return this.router.url.split( '?' )[ 0 ];
    }

    showPage ( page: any ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }


    filterQueriedResults (): void {

        this.filterParams = new HttpParams();

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };


        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );

    }

    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    public onSelectAll () {
        const selected = this.lots.map( ( item: { id: any; } ) => item.id );
        this.filterFormGroup.get( 'company_id' )?.patchValue( selected );
    }

    public onClearAll () {
        this.filterFormGroup.get( 'company_id' )?.patchValue( [] );
    }
}
