<div class="wrapper-md">

    <app-vehicle-search-modal #vehicleSearch (onSearch)="onSearch()"></app-vehicle-search-modal>

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block">Number</label>
                        <input class="form-control" formControlName="number" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Filter by Client</label>
                        <select class="form-control" formControlName="client_api_id">
                            <option *ngFor="let client_api of clients" [value]="client_api.id">
                                {{ client_api.owner_name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Select Source</label>
                        <select class="form-control" formControlName="via">
                            <option value="all">All</option>
                            <option value="crm">CRM</option>
                            <option value="api">API</option>
                            <option value="excel">Excel</option>
                            <option value="retry">Retry</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Sms Status</label>
                        <select class="form-control" formControlName="sms_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                            <option value="4">Multiple RTOs Error</option>
                        </select>
                    </div>

                </div>

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Parivahan Status</label>
                        <select class="form-control" formControlName="parivahan_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Invalid Captcha</option>
                            <option value="4">Not Initiated</option>
                            <option value="5">Multiple RTOs Error</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Vahan Status</label>
                        <select class="form-control" formControlName="vahan_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Invalid Captcha</option>
                            <option value="4">Not Initiated</option>
                        </select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">State Status</label>
                        <select class="form-control" formControlName="state_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Vahan Citizen Status</label>
                        <select class="form-control" formControlName="vahan_citizen_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Signzy Status</label>
                        <select class="form-control" formControlName="signzy_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Karza Status</label>
                        <select class="form-control" formControlName="karza_status">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Updation After</label>
                        <input class="form-control" type="date" formControlName="updated_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Updation Before</label>
                        <input class="form-control" type="date" formControlName="updated_before" />
                    </div>

                </div>
                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Search</label>
                        <a class="btn btn-primary btn-addon" (click)="vehicleSearch.show()">
                            <i class="fa fa-search"></i>
                            Search
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>