import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentService } from '../core/services/attachment.service';
import { CommonService } from '../core/services/common.service';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Directive( {
    selector: '[appUploadAttachment]',
} )
export class UploadAttachmentDirective {

    /**
     * Event fired when a file is uploaded successfully.
     * Usefull in parent components for notifications etc
     */
    @Output() uploaded: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Maximum file size allowed in kb
     */
    @Input() maxFileSize: number = 300;

    /**
     * Id of the model
     */
    @Input() attachableId!: string | any;

    /**
     * Type/Name of model eg. identity, address, icr, case
     */
    @Input() attachableType!: string;

    /**
     * Type of attachment eg. screenshot, proof, fir
     */
    @Input() attachmentType!: string;

    /**
     * Whether files should be uploaded asynchronous
     */
    @Input() async: boolean = false;

    constructor (
        private el: ElementRef,
        private attachmentService: AttachmentService,
        private commonService: CommonService
    ) {
    }

    // @HostListener( 'click', [ '$event' ] )
    // onClicked ( event: any ): void {
    //   console.log( 'on clicked' );
    // }

    @HostListener( 'change', [ '$event' ] )
    onChanged ( event: any ): void {

        this.upload( this.el.nativeElement.files );

    }

    async upload ( files: FileList ): Promise<void> {

        if ( this.isAboveMaxFileSize( files ) ) {

            this.commonService.notifications.error( `Max file size allowed is ${ this.maxFileSize }kb` );

            return;

        }

        // tslint:disable-next-line: prefer-for-of
        for ( let i = 0; i < files.length; i++ ) {

            const file: File = files[ i ];

            if ( this.async ) {

                // upload all files together without waiting
                this.getUploadPromise( file ).then( ( res ) => {

                    this.commonService.notifications.success( 'File uploaded successfully' );

                    this.uploaded.emit( res );

                } );


            } else {

                // wait and upload files one by one
                await this.getUploadPromise( file ).then( ( res ) => {

                    this.commonService.notifications.success( 'File uploaded successfully' );

                    this.uploaded.emit( res );

                } );

            }

        }

    }


    getUploadPromise ( file: File ): Promise<any> {

        return this.attachmentService.uploadAttachment( this.attachableId, this.attachableType, this.attachmentType, file ).toPromise()

    }

    isAboveMaxFileSize ( files: FileList | any ): boolean {

        let fileSize: number = 0;
        let isAboveMaxFileSize: boolean = false;

        for ( let i: number = 0; i <= files.length; i++ ) {

            // some blank file comes in chrome, putting this check to avoid any error
            if ( !files.item( i ) ) continue;

            fileSize = files.item( i ).size / 1024;

            if ( fileSize > this.maxFileSize ) isAboveMaxFileSize = true;

        }

        return isAboveMaxFileSize;
    }

}
