<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Create Client</h1>
</div>

<div class="wrapper-md">

    <div class="row">
        <form [formGroup]="createForm" (ngSubmit)="createClient()">
            <fieldset [disabled]="isSubmitting">
                <div class="col-md-6">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                        </div>
                        <div class="panel-body">
                            <div class="col-md-12 form-group">
                                <label class="center-block"> Company </label>
                                <select (change)="onCompanySelected()" class="form-control"
                                    formControlName="company_id">
                                    <option *ngFor="let company of companies" [value]="company.id">
                                        {{company.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="center-block"> Lots</label>
                                <app-multi-select [options]="lots" [placeholder]="'Select Lots'"
                                    [controlName]="'lot_id'" [formGName]="createForm"></app-multi-select>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="center-block"> Access</label>
                                <app-multi-select [options]="routes" [placeholder]="'Select Access'"
                                    [controlName]="'access_ids'" [formGName]="createForm"></app-multi-select>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="center-block"> Select SPOC User</label>
                                <app-multi-select [options]="users" [multipleValue]="false"
                                    [placeholder]="'Select SPOC User'" [controlName]="'spoc_user_id'"
                                    [formGName]="createForm"></app-multi-select>
                            </div>

                            <div class="col-md-6 form-group">
                                <label>User Name</label>
                                <input formControlName="name" class="form-control form-control-lg" />
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                        </div>
                        <div class="panel-body">

                            <div class="col-md-12 form-group">
                                <label>Email Address </label>
                                <input formControlName="email" type="email" class="form-control form-control-lg" />
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="center-block"> Designation</label>
                                <input formControlName="designation" class="form-control form-control-lg" />
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="center-block"> Mobile number</label>
                                <input formControlName="mobile_number" class="form-control form-control-lg" />
                            </div>

                            <button class="btn btn-lg btn-primary btn-block" [disabled]="!createForm.valid">
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>
