import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClientFormsService } from '../core/services/client-forms.service';
import { VerificationService } from '../core/services/verification.service';
import { SharedModule } from '../core/shared.module';
import { ClientFormsCreateComponent } from './client-forms-create/client-forms-create.component';
import { ClientFormsEditComponent } from './client-forms-edit/client-forms-edit.component';
import { ClientFormsListComponent } from './client-forms-list/client-forms-list.component';
import { PreviewFormInviteEmailComponent } from './preview-form-invite-email/preview-form-invite-email.component';
import { ClientFormsDeletedComponent } from './client-forms-deleted/client-forms-deleted.component';

import { ClientFormsV2Component } from '../client-forms-v2/client-forms-v2.component';
import { ConsentFormComponent } from '../client-forms-v2/consent-form/consent-form.component';
import { BasicFormComponent } from '../client-forms-v2/basic-form/basic-form.component';
import { IdentityFormComponent } from '../client-forms-v2/identity-form/identity-form.component';
import { AddressFormComponent } from '../client-forms-v2/address-form/address-form.component';
import { EducationFormComponent } from '../client-forms-v2/education-form/education-form.component';
import { EmploymentFormComponent } from '../client-forms-v2/employment-form/employment-form.component';
import { IcrFormComponent } from '../client-forms-v2/icr-form/icr-form.component';
import { GlobalDbFormComponent } from '../client-forms-v2/global-db-form/global-db-form.component';
import { DocumentFormComponent } from '../client-forms-v2/document-form/document-form.component';
import { ReferenceFormComponent } from '../client-forms-v2/reference-form/reference-form.component';
import { PccFormComponent } from '../client-forms-v2/pcc-form/pcc-form.component';
import { ClientFormsHistoryComponent } from './client-forms-history/client-forms-history.component';
import { ClientFormsFiltersComponent } from './client-forms-filters/client-forms-filters.component';

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPaginationModule,
        SharedModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        ClientFormsListComponent,
        ClientFormsEditComponent,
        ClientFormsCreateComponent,
        PreviewFormInviteEmailComponent,
        ClientFormsDeletedComponent,
        ClientFormsV2Component,
        ConsentFormComponent,
        BasicFormComponent,
        IdentityFormComponent,
        AddressFormComponent,
        EducationFormComponent,
        EmploymentFormComponent,
        IcrFormComponent,
        GlobalDbFormComponent,
        DocumentFormComponent,
        ReferenceFormComponent,
        PccFormComponent,
        ClientFormsHistoryComponent,
        ClientFormsFiltersComponent,
    ],
    providers: [ ClientFormsService, VerificationService ],
} )
export class ClientFormsModule {
}
