import { Component, OnInit } from '@angular/core';
import { WebhookService } from '../../core/services/webhook.service';
import { Pagination } from '../../core/models/pagination.model';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';


@Component( {
    selector: 'app-webhook-list',
    templateUrl: './webhook-list.component.html',
    styleUrls: [ './webhook-list.component.css' ],
} )
export class WebhookListComponent implements OnInit {

    pagination: Pagination = new Pagination();
    filterParams: HttpParams = new HttpParams();
    loading!: boolean;

    constructor (
        private webhookService: WebhookService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit () {
        this.filterResults();
    }

    filterResults (): void {
        this.webhookService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );
    }

    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    onModalUpdated () {
        this.filterResults();
    }
}
