import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { GlobalDbLogSources } from 'src/app/core/enums/global-db-log-sources.enum';
import { Client } from 'src/app/core/models/client.model';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalDbLogService } from 'src/app/core/services/global-db-log.service';

@Component({
  selector: 'app-global-db-log-filters',
  templateUrl: './global-db-log-filters.component.html',
  styleUrls: ['./global-db-log-filters.component.css']
})
export class GlobalDbLogFiltersComponent extends ExportExcelComponent implements OnInit {

    @Input() filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients!: Client | any;
    globalDbLogSources!: { key: string, value: string }[];

    constructor (
        private router: Router,

        private fb: FormBuilder,
        private commonService: CommonService,
        private globalDbLogService: GlobalDbLogService

    ) {
        super();
        this.initForm();
    }

    ngOnInit () {

        this.globalDbLogSources = Object.entries( GlobalDbLogSources ).map( ( [ key, value ] ) => ( { key, value } ) );

        this.setDefault();

        this.filterResults();

        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }

    private setDefault (): void {

        this.filterParams.keys().forEach( key => {

            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            name: 'all',
            created_before: 'all',
            created_after: 'all',
            page: 1,
            client: 'all',
            via: 'all',
            status: 'all',
            driver: 'all',
        } );
    }

    filterResults (): void {

        this.filterFormGroup.get( 'page' )?.setValue( 1 );

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };
        // Get the current url string & split it into 2 part by '?'
        // Get only the first part which is the path
        // eg. 'identity?xyx=abc' in url will return only 'identity'
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];
        // Navigate to current path along with our set query string
        // This will add query params to the urls
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    generateExcel (): void {
        this.globalDbLogService.getDataForExcel( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Global Db Logs Excel' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    resendAll (): void {
        this.globalDbLogService.resendAll( this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( ' GlobalDB Log Resent' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }


    onSearch (): void {
        this.filterResults();
    }

}
