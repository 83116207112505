<div class="wrapper-md">

    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Create Company
                </div>
                <div class="panel-body">
                    <app-errors [errors]="errors"></app-errors>
                    <form [formGroup]="createForm" (ngSubmit)="createCompany()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Company name</label>
                                <input formControlName="company_name" class="form-control form-control-lg"
                                    type="text" />

                            </fieldset>
                            <fieldset class="form-group" [disabled]="isSubmitting">
                                <label>Company official name </label>
                                <input formControlName="company_official_name" class="form-control form-control-lg"
                                    type="text" />

                            </fieldset>
                            <fieldset class="form-group" [disabled]="isSubmitting">
                                <label>Company prefix </label>
                                <input formControlName="company_prefix" class="form-control form-control-lg">

                            </fieldset>
                            <fieldset class="form-group" [disabled]="isSubmitting">
                                <label>Company city </label>
                                <input formControlName="company_city" class="form-control form-control-lg"
                                    type="text" />

                            </fieldset>

                            <button class="btn btn-lg btn-primary btn-block" type="submit"
                                [disabled]="!createForm.valid">
                                Create
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>