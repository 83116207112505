import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { LicenceService } from '../core/services/licence.service';
import { SharedModule } from '../core/shared.module';
import { LicenceDetailsComponent } from './licence-details/licence-details.component';
import { LicenceExcelImportComponent } from './licence-excel-import/licence-excel-import.component';
import { LicenceFiltersComponent } from './licence-filters/licence-filters.component';
import { LicenceHtmlResponseModalComponent } from './licence-html-response-modal/licence-html-response-modal.component';
import { LicenceListComponent } from './licence-list/licence-list.component';
import { LicenceResponseModalComponent } from './licence-response-modal/licence-response-modal.component';
import { LicenceSearchModalComponent } from './licence-search-modal/licence-search-modal.component';
import { LicenceStatsComponent } from './licence-stats/licence-stats.component';


@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,

    ],
    declarations: [
        LicenceListComponent,
        LicenceHtmlResponseModalComponent,
        LicenceResponseModalComponent,
        LicenceDetailsComponent,
        LicenceSearchModalComponent,
        LicenceExcelImportComponent,
        LicenceStatsComponent,
        LicenceFiltersComponent,
    ],
    providers: [ LicenceService ],
} )
export class LicenceModule {
}
