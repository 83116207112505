import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-client-crm-headers-modal',
    templateUrl: './client-crm-headers-modal.component.html',
} )
export class ClientCrmHeadersModalComponent {

    @ViewChild( 'clientCrmHeadersModal', { static: false } )

    public clientCrmHeadersModal!: ModalDirective;

    public headers!: any;

    show ( headers: any ): void {
        this.headers = headers;
        this.clientCrmHeadersModal.show();
    }


    hide (): void {
        this.clientCrmHeadersModal.hide();
    }

}
