<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">All Forms</h1>
</div>

<app-client-forms-filters></app-client-forms-filters>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th style="width:50%;">Form Details</th>
                        <th style="width:20%;">Company</th>
                        <th style="width:20%;">Verifications</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let form of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i=index">
                        <td>
                            <ul class="list-unstyled">
                                <li><strong>{{ form.name }}</strong></li>
                                <li>Status: {{ getFormStatus(form.status)}}</li>
                                <li>Not Filled Invites Count: {{form.not_filled_invites_count}}</li>
                                <li>{{ form.created_at}}</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li *ngIf="form.company">{{ form.company.company_name }}</li>
                                <li *ngIf="form.lot">{{ form.lot.lot_name}}</li>
                            </ul>
                        </td>


                        <td>
                            <ul class="list-unstyled" *ngIf="form.settings">
                                <li *ngIf="isIdentitySelected(form.settings.identity)">Identity verification</li>

                                <li *ngIf="isCurrentAddressSelected(form.settings.address)">Current Address
                                    verification
                                </li>

                                <li *ngIf="isPermanentAddressSelected(form.settings.address)">Permanent Address
                                    verification
                                </li>

                                <li *ngIf="isAnyAddressSelected(form.settings.address)">Any Address verification</li>

                                <li *ngIf="isEducationSelected(form.settings.education)">Education verification</li>

                                <li *ngIf="isEmploymentSelected(form.settings.employment)">Employment verification</li>

                                <li *ngIf="isIcrSelected(form.settings.icr)">ICR verification</li>

                                <li *ngIf="isPccSelected(form.settings.pcc)">PCC verification</li>

                                <li *ngIf="isGlobalDbSelected(form.settings.global_db)">Global DB verification</li>

                                <li *ngIf="isDocumentSelected(form.settings.document)">Document Verification</li>

                                <li *ngIf="isReferenceSelected(form.settings.reference)">Reference Verification</li>
                            </ul>
                        </td>

                        <td [appCan]="'admin'">

                            <a class="btn btn-sm btn-default actions" routerLink="/forms/{{form.id}}/edit">
                                Edit
                            </a>
                            <a class="btn btn-sm btn-default actions" routerLink="/forms/{{form.id}}/history">
                                History
                            </a>
                            <br>
                            <button type="button" class="btn btn-danger btn-xs actions"
                                (click)="onRemoveFormButtonClicked(form.id)">Delete
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
