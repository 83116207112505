import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserHistory } from 'src/app/core/models/user-history.model';
import { ClientService } from 'src/app/core/services/client.service';

@Component( {
    selector: 'app-clients-history',
    templateUrl: './client-history.component.html'
} )
export class ClientHistoryComponent implements OnInit {

    //TODO: ask to sir
    client!: UserHistory;
    clientId!: number;

    constructor ( private clientService: ClientService, private activatedRoute: ActivatedRoute ) { }

    ngOnInit (): void {

        this.clientId = +this.activatedRoute.snapshot.params[ 'id' ];

        this.fetchHistory();

    }

    fetchHistory () {

        this.clientService.getHistory( this.clientId ).subscribe( {
            next: ( response: UserHistory ) => {
                this.client = response;
            }
        } )
    }
}
