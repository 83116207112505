import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Params, Router } from '@angular/router';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';
import { ClientForms } from '../../core/models/client-forms.model';
import { Lot } from '../../core/models/lot.model';
import { Pagination } from '../../core/models/pagination.model';
import { ClientFormsService } from '../../core/services/client-forms.service';
import { CommonService } from '../../core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';


@Component( {
    selector: 'app-client-forms-list',
    templateUrl: './client-forms-list.component.html',
    styleUrls: [ './client-forms-list.component.css' ],
} )
export class ClientFormsListComponent implements OnInit, OnDestroy {

    loading!: boolean;
    pagination: Pagination = new Pagination();
    filtersSubscription!: Subscription;

    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    constructor (
        private router: Router,
        private clientService: ClientFormsService,
        private fb: FormBuilder,
        private commonService: CommonService,
        public global: GlobalService,
        private filterService: FilterService,
        private confirmationModalService: ConfirmationModalService
    ) {
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );
    }

    filterResults ( params: Params ): void {

        const updatedParams = this.updateParams( params )

        this.clientService.getAll( updatedParams ).subscribe(
            response => this.pagination = response
        );
    }

    updateParams ( params: any ): Params {
        const updatedParams: any = {};

        const multipleValueFilters = [ 'company_id', 'lot_id', 'form_status' ];

        Object.keys( params ).forEach( key => {
            if ( multipleValueFilters.includes( key ) ) {
                updatedParams[ key ] = params[ key ].toString();
            } else {
                updatedParams[ key ] = params[ key ];
            }
        } );

        return updatedParams;
    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }


    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }

    removeForm ( id: number ) {

        this.clientService.delete( id ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( 'Form removed!', 'Success!' );
                this.ngOnInit();
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( error );
            }
        } );
    }

    onRemoveFormButtonClicked ( id: number ): void {

        const options: ConfirmationModalOptions = {
            text: 'Selected will be removed from forms list!'
        };

        this.confirmationModalService.confirm( options, () => this.removeForm( id ) );

    }

    isIdentitySelected ( identity: any ): boolean {
        return this.clientService.isIdentitySelected( identity );
    }

    isCurrentAddressSelected ( address: any ): boolean {
        return this.clientService.isCurrentAddressSelected( address )
    }

    isPermanentAddressSelected ( address: any ): boolean {
        return this.clientService.isPermanentAddressSelected( address )
    }

    isAnyAddressSelected ( address: any ): boolean {
        return this.clientService.isAnyAddressSelected( address )
    }

    isEducationSelected ( education: any ): boolean {
        return this.clientService.isEducationSelected( education )
    }

    isEmploymentSelected ( employment: any ): boolean {
        return this.clientService.isEmploymentSelected( employment )
    }

    isIcrSelected ( icr: any ): boolean {
        return this.clientService.isIcrSelected( icr )
    }

    isPccSelected ( pcc: any ): boolean {
        return this.clientService.isPccSelected( pcc )
    }

    isGlobalDbSelected ( global_db: any ): boolean {
        return this.clientService.isGlobalDbSelected( global_db )
    }

    isReferenceSelected ( reference: any ): boolean {
        return this.clientService.isReferenceSelected( reference )
    }

    isDocumentSelected ( document: any ): boolean {
        return this.clientService.isDocumentSelected( document )
    }

    getFormStatus ( formId: any ): string | undefined {
        return this.clientService.getFormStatusLabel( formId );
    }
}
