<div class="row bg-light lter b-b wrapper-md">
    <div class="wrapper-md">
        <div class="panel">
            <div class="panel-heading font-bold">
                <h3>Api Request Logs</h3>
            </div>
            <div class="panel-body">
                <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()"
                    (keydown.enter)=" $event.preventDefault()">
                    <div class="row">

                        <div class="col-md-2 form-group">
                            <label class="center-block">Creation After</label>
                            <input class="form-control" type="date" formControlName="created_after" />
                        </div>

                        <div class="col-md-2 form-group">
                            <label class="center-block">Creation Before</label>
                            <input class="form-control" type="date" formControlName="created_before" />
                        </div>

                        <div class="col-md-2 form-group">
                            <label class="center-block">Filter by Client</label>
                            <select class="form-control" formControlName="client">
                                <option [value]=""> </option>
                                <option *ngFor="let client of clients" [value]="client.id">
                                    {{ client.owner_name }}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-2 form-group">
                            <label class="center-block">End Point</label>
                            <input class="form-control" type="text" formControlName="end_point" />
                        </div>

                        <div class="col-md-2 form-group">
                            <label class="center-block">Response Status</label>
                            <input class="form-control" type="text" formControlName="response_status" />
                        </div>

                        <div class="form-group col-md-2">
                            <label class="center-block">Select Request Type</label>
                            <select class="form-control" formControlName="request_type">
                                <option value="all">All</option>
                                <option value="get">Get</option>
                                <option value="post">Post</option>
                                <option value="put">Put</option>
                                <option value="delete">Delete</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-2">
                            <label class="center-block">Click here to</label>
                            <button class="btn btn-primary btn-block">
                                Apply Filters
                            </button>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Click here to</label>
                            <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                                Reset
                            </button>
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-md-2">
                            <a class="btn btn-primary" routerLink="stats"> View Stats</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="wrapper-md">
        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="row" *ngIf="pagination.data">
                    <div class="col-md-6 panel-title">
                        Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                    </div>
                    <div class="col-md-6 text-right text-center-xs">
                        <!-- <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div> ask to sir -->
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <table class="table b-t b-light">
                    <thead>
                        <tr>
                            <th style="width:20%;">Client Name</th>
                            <th style="width:20%;">End Point</th>
                            <th style="width:10%;">Method</th>
                            <th style="width:10%;">Status</th>
                            <th style="width:10%;">Created At</th>
                            <th style="width:10%;">Updated At</th>
                            <th style="width:10%;">Request</th>
                            <th style="width:10%;">Response</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let log of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i=index">
                            <td> {{ log.client_name }} </td>

                            <td> {{ log.endpoint }} </td>

                            <td> {{ log.method | uppercase }} </td>

                            <td> {{ log.status }} </td>

                            <td> {{ log.created_at }} </td>

                            <td> {{ log.updated_at }} </td>

                            <td>
                                <button class="btn btn-sm btn-success" (click)="showRequestModal(log.request)">
                                    Request
                                </button>
                            </td>

                            <td>
                                <button class="btn btn-sm btn-success" (click)="showResponseModal(log.response)">
                                    Response
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <footer class="panel-footer">
                <div class="row" *ngIf="pagination.data">
                    <div class="col-sm-12 text-right text-center-xs">
                        <!-- <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div> -->
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </footer>
        </div>
    </div>


</div>