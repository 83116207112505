import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AadhaarService } from '../../core/services/aadhaar.service';

@Component( {
    selector: 'app-aadhaar-stats',
    templateUrl: './aadhaar-stats.component.html',
    styleUrls: [ './aadhaar-stats.component.css' ],
} )
export class AadhaarStatsComponent implements OnInit {

    @Input() filterParams: HttpParams = new HttpParams();
    stats!: any[];

    constructor (
        private route: ActivatedRoute,
        private aadhaarService: AadhaarService,
        private router: Router
    ) { }

    ngOnInit (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterStats();
        } );
    }

    filterStats (): void {

        let currentSource: string = this.router.url.split( '?' )[ 0 ];

        currentSource = currentSource.split( '/' )[ 1 ];

        if ( this.filterParams.keys().length ) {
            this.aadhaarService.getStats( this.filterParams ).subscribe(
                response => {
                    this.stats = response;
                }
            );
        }
    }

}
