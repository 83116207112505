import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { ClientFormsService } from './../../core/services/client-forms.service';

@Component( {
    selector: 'app-preview-form-invite-email',
    templateUrl: './preview-form-invite-email.component.html',
    styleUrls: [ './preview-form-invite-email.component.css' ],
} )
export class PreviewFormInviteEmailComponent implements OnInit {

    @Input() emailContent!: string;
    @Input() companyId!: number;
    email!: string;

    constructor (
        private formService: ClientFormsService,
    ) { }

    ngOnInit (): void {

        if ( !this.companyId ) return;

        if ( Array.isArray( this.companyId ) && this.companyId.length == 0 ) return;

        const params: Params = {
            email_body: this.emailContent ?? "",
            company_id: this.companyId,
        };

        this.formService.previewInviteEamil( params ).subscribe(
            respone => {
                this.email = respone.content;
            }
        );
    }

    render (): void {
        this.ngOnInit();
    }

}
