import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';


import { Pagination } from '../models/pagination.model';
import { Contact } from '../models/contact.model';


@Injectable( {
    providedIn: 'root'
} )
export class ContactService {

    constructor ( private api: ApiService ) {
    }

    getAll ( params: string ): Observable<Pagination> {
        return this.api.get( 'contacts?' + params );
    }

    find ( id: number ): Observable<Contact> {
        return this.api.get( 'contacts/' + id );
    }

    update ( id: number, values: Object ) {
        return this.api.put( 'common/update-contact/' + id, values );
    }

    create ( values: Object ) {
        return this.api.post( 'common/create-contact', values );
    }

}
