<div bsModal #vehicleSearch="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="searchInsuranceForm" (ngSubmit)="search()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Search for a Rc Number</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset class="form-group">
                                    <label class="center-block">Rc Number
                                        <input formControlName="rc_number" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="vahan"
                                            (change)="onCheckboxChange($event,'vahan')">
                                        Via Vahan
                                    </label>
                                </fieldset>
                            </div>

                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!searchInsuranceForm.valid">
                            Search
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>