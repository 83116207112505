import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-identity-validation-modal',
    templateUrl: './identity-validation-modal.component.html',
    styleUrls: [ './identity-validation-modal.component.css' ],
} )
export class IdentityValidationModalComponent implements OnInit {

    @ViewChild( 'identityValidationModal', { static: false } )
    public identityValidationModal!: ModalDirective;

    @Input()
    verification: any;

    constructor () {

    }

    ngOnInit (): void {

    }

    show (): void {
        this.identityValidationModal.show();
    }

    hide (): void {
        this.identityValidationModal.hide();
    }

}
