import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { EducationVerification } from '../../core/models/education-verification.model';
import { EducationService } from '../../core/services/education.service';
import { Employee } from 'src/app/core/models/employee.model';
import { User } from 'src/app/core/models/user.model';
import { CommonService } from 'src/app/core/services/common.service';
import { InstituteService } from 'src/app/core/services/institute.service';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-education-edit',
    templateUrl: './education-edit.component.html',
    styleUrls: [ './education-edit.component.css' ],
} )
export class EducationEditComponent extends VerificationEditComponent implements OnInit {

    verifications: EducationVerification[] = [];
    override attachments: AbstractControl[] = [];
    vendors!: User[];
    countries!: User[];

    constructor (
        protected override verificationService: EducationService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override common: CommonService,

        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected instituteService: InstituteService,
        protected override confirmationModalService: ConfirmationModalService

    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );

        this.initForm();
    }

    initForm (): void {
        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            name: [ null ],
            client_employee_id: [ null ],
            mobile_number: [ null ],
            father_name: [ null ],
            dob: [ null ],
            remarks: [ null ],
            id: [ null ],
            formArray: this.fb.array( [] ),
        } );
    }

    override ngOnInit (): void {

        this.verification = 'education';

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                employee => {
                    this.employee = employee;
                    this.populateForm( employee );
                    this.getAttachments( employee );
                    this.isSubmitting = false;
                }
            );
        } );

        // Subscribe to changes in the form.
        this.subscribeToQueryChanges();

        this.common.getVendors( 'education' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
        this.common.getCountries().subscribe(
            response => {
                this.countries = response;
            }
        );
    }


    populateForm ( employee: Employee ): void {

        this.verificationFormGroup.patchValue( employee );

        const verifications: EducationVerification[] = employee.education_verifications as EducationVerification[];

        const verificationFormGroup: FormGroup[] = verifications.map( verification => this.fb.group( verification ) );

        const verificationGroupArray: FormArray = this.fb.array( verificationFormGroup );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );

    }

    createEducation (): void {

        this.verificationService.create( this.employee.id ).subscribe(
            response => {
                this.populateForm( response );
            }
        );

        this.common.notifications.success( 'Education Verification added' );
    }

    onInstituteCreated (): void {
        this.instituteService.populateCache( true );
    }

    updateVerification ( verification: any ): void {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.cdr.detectChanges();
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                () => {
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }
}
