import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AadhaarService } from '../../core/services/aadhaar.service';
import { CommonService } from '../../core/services/common.service';
import { FilterService } from '../../core/services/filter.service';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { AadhaarSources } from '../../core/enums/aadhaar-sources.enum';
import { Client } from 'src/app/core/models/client.model';

@Component( {
    selector: 'app-aadhaar-filters',
    templateUrl: './aadhaar-filters.component.html',
    styleUrls: [ './aadhaar-filters.component.css' ],
} )
export class AadhaarFiltersComponent extends ExportExcelComponent implements OnInit {

    @Input() filterParams!: HttpParams;

    all: string = 'all';

    filterFormGroup!: FormGroup;

    clients!: Client | any;

    errors!: any[];

    data: any[] = [];

    fetchingInProgress: boolean = false;

    aadhaarSources!: { key: string, value: string }[];

    constructor (
        private fb: FormBuilder,
        private commonService: CommonService,
        private aadhaarService: AadhaarService,
        private filterService: FilterService
    ) {
        super();
        this.initForm();
    }

    ngOnInit (): void {

        this.aadhaarSources = Object.entries( AadhaarSources ).map( ( [ key, value ] ) => ( { key, value } ) );

        this.updateFormFilter();

        this.getClientApis();

        this.getErrorMessages();

        this.filterResults();
    }

    getErrorMessages (): void {

        this.aadhaarService.getErrorMessages().subscribe(
            ( response ) => {
                this.errors = response;
            },
            () => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            },
        );
    }

    getClientApis (): void {
        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            ( response ) => {
                this.clients = response;

            },
            () => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            },
        );
    }


    initForm (): void {

        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            aadhaar_number: '',
            created_after: 'all',
            created_before: 'all',
            client: 'all',
            via: 'all',
            status: 'all',
            driver: 'all',
        } );

    }

    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }

    filterResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();

        this.filterResults();

    }

    onSearch (): void {

        this.filterService.filter();

    }

    resendAll (): void {
        this.aadhaarService.resendAll( this.filterParams ).subscribe(
            () => {
                this.commonService.notifications.success( ' Aadhaar Resent' );
            },
            () => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }

    exportLogsExcel (): void {

        this.fetchingInProgress = true;

        this.aadhaarService.getDataForExcel( this.filterService.filters ).subscribe(

            response => {

                this.data = this.data.concat( response.data );

                if ( response[ 'next_page_url' ] ) {

                    this.filterService.filterByPage( response.current_page + 1 );

                    this.exportLogsExcel();

                } else {

                    this.commonService.notifications.success( 'All Data Fetched' );

                    this.downloadExcel( this.data, 'Aadhaar Logs Excel' );

                    this.fetchingInProgress = false;

                    this.data = [];
                }

            } );
    }
}
