import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../../services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';


@Component( {
    selector: 'app-image-crop',
    templateUrl: './image-crop.component.html',
    styleUrls: [ './image-crop.component.css' ],
} )
export class ImageCropComponent {

    data: any;
    showHide: boolean;
    verificationId!: number;
    scale = 1;
    canvasRotation = 0;
    transform: ImageTransform = {};
    imageBase64!: any;

    @Input()
    verification_type!: string;

    @Input()
    verification!: FormGroup | any;

    image_type!: string;

    @Output()
    filtered: EventEmitter<string> = new EventEmitter();

    @Output()
    saved: EventEmitter<any> = new EventEmitter();

    // Custom cropper.
    @ViewChild( 'cropper', { static: false } ) cropper!: ModalDirective;


    // Package for croppper.
    @ViewChild( 'imageCropperComponent', { static: false } )
    imageCropper!: ImageCropperComponent;

    imageChangedEvent: any = '';

    croppedPreview!: string | null | undefined | any;

    constructor (
        protected commonService: CommonService,
        protected http: HttpClient
    ) {

        this.data = null;
        this.showHide = false;
    }

    fileChangeEvent ( event: any ): void {
        this.imageChangedEvent = event;
    }

    imageCropped ( event: ImageCroppedEvent ) {

        this.croppedPreview = event.objectUrl;
        this.convertToBase64();

    }

    imageLoaded () {
        // show cropper
    }

    cropperReady () {
        // cropper ready
    }

    loadImageFailed () {
        // show message
    }

    show ( image_type: any ) {

        const headers = this.setHeaders();

        this.verificationId = this.verification.value.id;
        this.image_type = image_type;

        document.body.scrollTop = document.documentElement.scrollTop = 0;

        // TODO:: move the http request to service

        const imageUrl = this.verification.value[ image_type ];

        const image: any = new Image();
        const that = this;

        // this.http.options( imageUrl ).subscribe( {
        //     next: ( value ) => {
        //         this.http.get( imageUrl, {
        //             responseType: 'blob', headers,
        //         } ).subscribe( {
        //             next: ( value ) => {

        //                 const reader = new FileReader();
        //                 const blob = new Blob( [ value ], { type: 'image/' } );

        //                 reader.onloadend = function ( loadEvent: any ) {
        //                     image.src = loadEvent.target.result;
        //                     that.data = image.src;
        //                 }

        //                 this.showHide = true;
        //                 reader.readAsDataURL( blob );
        //                 this.cropper.show();
        //             }
        //         } );
        //     }
        // } );

        this.http.get( imageUrl, {
            responseType: 'blob', headers,
        } ).subscribe( data => {
            const reader = new FileReader();
            const blob = new Blob( [ data ], { type: 'image/' } );
            reader.onloadend = function ( loadEvent: any ) {
                image.src = loadEvent.target.result;
                that.data = image.src;
            };
            this.showHide = true;
            reader.readAsDataURL( blob );
            this.cropper.show();
        }, error => {
            console.log( error );

        } );
    }

    cropped () {

    }

    private setHeaders (): HttpHeaders {
        const headersConfig = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type',
        };

        return new HttpHeaders( headersConfig );
    }

    saveCroppedImage () {

        if ( !this.imageBase64 ) throw new Error( "image cropper not working" );

        this.commonService.saveCroppedImage( this.imageBase64, this.verificationId, this.image_type, this.verification_type ).subscribe(
            {
                next: ( value ) => {
                    this.commonService.notifications.success( 'update done' );
                    document.getElementById( 'img_' + this.image_type + '_' + this.verificationId )?.setAttribute( 'src', value[ 'file_name' ] );
                    this.showHide = false;
                    this.hide();
                }
            }
        );
    }

    private convertToBase64 (): void {
        this.http.get( this.croppedPreview, { responseType: 'blob' } ).subscribe( ( blob ) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64Data = reader.result as string;
                this.imageBase64 = base64Data;
            };
            reader.readAsDataURL( blob );
        } );
    }

    hide () {
        this.showHide = false;
        this.cropper.hide();
        this.data = null;
        this.saved.emit();
    }

    rotateLeft () {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight () {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate () {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH,
        };
    }


    flipHorizontal () {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    flipVertical () {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };
    }

    zoomOut () {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    zoomIn () {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

}
