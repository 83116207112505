import { Injectable } from '@angular/core';
import { State } from '../models/state.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { District } from '../models/district.model';
import { Company } from '../models/company.model';
import { HttpParams } from '@angular/common/http';
import { User, UserLevel } from '../models/user.model';
import { AuthService } from './auth.service';

import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Injectable( {
    providedIn: 'root'
} )
export class CommonService {

    constructor (
        private api: ApiService,
        public notifications: ToastrService,
        public auth: AuthService,
        public titleService: Title
    ) {
    }

    /**
  * API method to update verification status of multiple verifications
  *
  * @param verification : which verification, identity,address,icr etc.
  * @param verificationIds : array of verification ids to be updated.
  * @param statusId : verification status id to be updated to.
  */
    changeVerificationStatus ( verification: string, verificationIds: number[], statusId: number ): Observable<any[]> {
        return this.api.post( 'common/change-verification-status', {
            verification: verification,
            ids: verificationIds,
            status_id: statusId,
        } );
    }

    /**
     * API method to update verification status of multiple verifications
     *
     * @param verification : which verification, identity,address,icr etc.
     * @param verificationIds : array of verification ids to be updated.
     * @param vendorId : verification status id to be updated to.
     */
    changeVendorId ( verification: string, verificationIds: number[], vendorId: number ): Observable<any[]> {
        return this.api.post( 'common/change-vendor-id', {
            verification: verification,
            ids: verificationIds,
            vendor_id: vendorId,
        } );
    }

    updateSentToVendorOn ( verification: string, verificationIds: number[], sentToVendorOn: string ): Observable<any[]> {
        return this.api.post( 'common/change-sent-to-vendor-on', {
            verification: verification,
            ids: verificationIds,
            sent_to_vendor_on: sentToVendorOn,
        } );
    }

    public displayValidationErrors ( errors: any ): void {

        const fields = [];
        const messages = [];

        if ( errors && errors !== null ) {

            Object.keys( errors ).forEach( key => {
                if ( errors[ key ] !== '' ) {
                    messages.push( errors[ key ] );
                    fields.push( key );
                }

                this.notifications.error( key, errors[ key ] );
            } );
        }
    }

    getVerificationStats ( verification: string, params: HttpParams = new HttpParams() ): Observable<any[]> {
        return this.api.get( verification + '/stats', params );
    }

    /**
     * @param companyId
     * @deprecated Use lot.service.ts instead
     */
    getLotsByCompanyId ( companyId: number ): Observable<any[]> {
        return this.api.get( 'lots/list?company_id=' + companyId );
    }

    getCompanies (): Observable<Company[]> {
        return this.api.get( 'companies/list' );
    }

    getStates (): Observable<State[]> {
        return this.api.get( 'common/states' );
    }

    getDistricts (): Observable<District[]> {
        return this.api.get( 'common/districts' );
    }

    getAuthorities ( type: string ): Observable<District[]> {
        return this.api.get( 'common/authorities?type=' + type );
    }

    getStatuses (): Observable<any[]> {
        return this.api.get( 'common/statuses' );
    }

    getUsers (): Observable<User[]> {
        return this.api.get( 'common/users' )
    }

    getAllVendorNames (): Observable<any[]> {
        return this.api.get( 'common/get-all-vendor-names' );
    }

    // It returns the json of id and name of vendors
    getAllVendors (): Observable<any[]> {
        return this.api.get( 'common/get-all-vendors' );
    }

    getVendors ( verification: string ): Observable<User[]> {
        return this.api.get( 'common/vendors?verification=' + verification )
    }

    getCountries (): Observable<User[]> {
        return this.api.get( 'common/countries' )
    }

    getLanguages (): Observable<User[]> {
        return this.api.get( 'common/languages' )
    }

    getVerificationClientRemarks (): Observable<any> {
        return this.api.get( 'common/verification-client-remarks' );
    }

    getVerificationWorkTypes (): Observable<any> {
        return this.api.get( 'common/all-work-types' );
    }

    populateAuthorities ( type: string ) {
        this.getAuthorities( type ).subscribe(
            // response => this.global[ 'authorities' ] = response
        );
    }
    deleteMultiple ( ids: number[], verification: string ): Observable<any[]> {
        return this.api.post( 'common/delete-verifications', {
            ids: ids,
            verification: verification,
        } )
    }

    changestatus ( ids: number[], verification: string, status_id: number ): Observable<any[]> {
        return this.api.post( 'common/change-verification-status', {
            ids: ids,
            verification: verification,
            status_id: status_id,
        } )
    }

    changeQaRequired ( ids: number[], verification: string, value: number ): Observable<any[]> {
        return this.api.post( 'common/change-qa-status', {
            ids: ids,
            verification: verification,
            qa_required: value,
        } )
    }

    changeQaDone ( ids: number[], verification: string, value: number ): Observable<any[]> {
        return this.api.post( 'common/change-qa-status', {
            ids: ids,
            verification: verification,
            qa_done: value,
        } )
    }

    saveCroppedImage ( image: string, verificationId: string | number, proofType: string, verificationType: string ): Observable<any> {
        return this.api.post( 'identity/' + verificationId + '/save-image', {
            image_data: image,

            image_type: proofType,
            verification: verificationType,
        } );
    }

    getDataForExcel ( verification: string, params: HttpParams = new HttpParams() ): Observable<any[]> {
        return this.api.get( 'common/downloadExcel?verification=' + verification, params );
    }

    getPincodeDetails ( pincode: string ): Observable<any[]> {
        return this.api.post( 'common/pincodes', {
            pincode: pincode,
        } );
    }

    userLevelId (): number {
        return this.auth.user?.level_id || UserLevel.Default;
    }

    /**
     *
     * @deprecated use employment-company service instead
     */
    getAllEmploymentCompanies (): Observable<any[]> {
        return this.api.get( 'employment/all-companies' );
    }

    /**
     * @deprecated use `institute.service.ts` methods
     */
    getAllInstitutes (): Observable<string[]> {
        return this.api.get( 'education/all-institutes' );
    }

    getClientRoutes (): Observable<any[]> {
        return this.api.get( 'common/client-routes' );
    }

    deleteVerificationDocument ( data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'common/delete-document', data );
    }

    deleteVerificationAttachment ( data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'common/delete-attachment', data );
    }

    deleteFile ( data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'common/delete-file', data );
    }

    getAllClientApis ( params: HttpParams ) {
        return this.api.get( 'common/all-client-api', params );
    }

    getPendingJobs () {
        return this.api.get( 'common/pending-jobs' );
    }

    clearQueueByName ( name: string ) {
        return this.api.post( 'common/clear-queue', { queue_name: name } );
    }

    getPaymentModes () {
        return this.api.get( 'common/payment-modes' );
    }

    deleteContact ( id: number | Object | undefined ) {
        // console.log(id);
        return this.api.post( 'common/delete-contact', id );
    }

    getSelectedVerificationAddress ( verificationId: string | number, params: HttpParams = new HttpParams() ): Observable<any[]> {
        return this.api.get( 'common/' + verificationId + '/get-verification-address-details', params );
    }

    sendRequestToVendor ( verification: string, verificationIds: number[] ): Observable<any[]> {
        return this.api.post( 'common/send-to-vendor', {
            verification: verification,
            ids: verificationIds,
        } );
    }

    withDrawCaseFromVendor ( verification: string, verificationIds: number[] ): Observable<any[]> {
        return this.api.post( 'common/withdraw-from-vendor',
            {
                verification: verification,
                ids: verificationIds,
            } );
    }

    getRelationMorpMap (): Observable<any[]> {
        return this.api.get( 'common/relation/morph-map' );
    }

    companiesLots ( params: HttpParams ): Observable<any[]> {
        return this.api.get( 'lots/companies-lots', params );
    }

    setPageTitle ( title: string ) {
        this.titleService.setTitle( title );
    }
}
