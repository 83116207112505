<div class="wrapper-md">
    <div class="row">
        <div class="col-md-offset-3 col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Add New Status
                </div>

                <form [formGroup]="addStatusFormGroup" (ngSubmit)="submit()">
                    <div class="panel-body">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="center-block">Verification</label>
                                <select (change)="onVerificationTypeChanged()" class="form-control"
                                    formControlName="verification">
                                    <option *ngFor="let verification of verifications"
                                        [value]="verification.shortTitle">
                                        {{verification.title}}
                                    </option>
                                </select>
                            </div>
                            <!-- <div class="form-group col-md-6">
                                <label class="center-block">Status</label>
                                <select class="form-control" formControlName="filter_verification_status">
                                    <option *ngFor="let status of allStatuses" [value]="status.id">
                                        {{status.name}}
                                    </option>
                                </select>
                            </div> -->
                            <div class="col-md-6 form-group">
                                <label class="control-label">Insufficiency</label>
                                <select class="form-control" formControlName="insuff">
                                    <option value="1">
                                        Yes
                                    </option>
                                    <option value="0">
                                        No
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block">Status Name</label>
                                <input formControlName="title" class="form-control">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Type</label>
                                <select class="form-control" formControlName="type">
                                    <option value="under_process">under_process</option>
                                    <option value="verified">verified</option>
                                    <option value="not_initiated">not_initiated</option>
                                    <option value="discrepancy">discrepancy</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block">Description</label>
                                <input formControlName="description" class="form-control">
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block">Client title</label>
                                <input formControlName="client_title" class="form-control">
                            </div>

                            <div class="col-md-6 form-group">
                                <label class="center-block">Client Description</label>
                                <input formControlName="client_description" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="panel-footer text-right">
                        <div class="row">
                            <div class="col-sm-offset-8 col-sm-4">
                                <button class="btn btn-primary" [disabled]="!addStatusFormGroup.valid">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>