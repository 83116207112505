import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { CommonService } from '../../core/services/common.service';
import { EmployeeAdditionalDataService } from '../../core/services/employee-additional-data.service';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-employee-additional-details-import',
    templateUrl: './employee-additional-details-import.component.html',
    styleUrls: [ './employee-additional-details-import.component.css' ],
} )
export class EmployeeAdditionalDetailsImportComponent extends ImportExcelComponent implements OnInit {

    uploadForm!: FormGroup;
    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    validationMessages: ValidationMessages = {};
    clients!: any[];

    constructor (
        private additionalDataService: EmployeeAdditionalDataService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
    ) {
        super( fb );

    }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );
    }

    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void {

        this.validationMessages[ 'track_id' ] = 'Required';
    }

    applyValidations (): void {

        this.excelFormArray.controls.forEach( form => {
            form.get( 'track_id' )?.setValidators( [ Validators.required ] );
            form.get( 'track_id' )?.updateValueAndValidity();
            this.setValidationMessages();
        } );
    }

    importExcel (): void {

        this.additionalDataService.importExcel( this.excelForm.value ).subscribe(
            response => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ '/home' ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }

}
