import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';

/**
 * Check if any special character is present in input
 *
 * @param {string} allowedCharacters
 * @returns {ValidatorFn}
 */
export function validateAllowedCharacters ( allowedCharacters: string, blank = false ): ValidatorFn {

    // Allowing Alphabets, Space and .
    let regexString = '^[a-zA-Z\\s.';

    if ( allowedCharacters.length > 0 ) {

        regexString = regexString + allowedCharacters;
    }

    // Create Regex Pattern
    const regexPattern = new RegExp( regexString + ']*$' );

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        if ( !control.value ) {

            return {};
        }

        if ( regexPattern.test( control.value || blank ) ) {

            return {};
        }

        return { 'validateAllowedCharacters': { value: control.value } };
    };
}


@Directive( {
    selector: '[validateAllowedCharacters]',
    providers: [ { provide: NG_VALIDATORS, useExisting: AllowedCharactersValidatorDirective, multi: true } ],
} )
export class AllowedCharactersValidatorDirective implements Validator {
    @Input()
    allowedCharacters!: string;

    validate ( control: AbstractControl ): ValidationErrors | null {

        return validateAllowedCharacters( this.allowedCharacters )( control );
    }
}
