import { Component, Input, } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { EmailStatusLog } from 'src/app/core/models/email-status-log';

@Component( {
    selector: 'app-email-log-status-time-line',
    templateUrl: './email-log-status-time-line.component.html',
} )
export class EmailLogStatusTimeLineComponent {

    @Input() statusLogs: EmailStatusLog[] = [];

    constructor (
        public bsModalRef: BsModalRef ) { }

    onClose (): void {

        this.bsModalRef.hide();
    }
}
