import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { PanService } from '../../core/services/pan.service';

@Component( {
    selector: 'app-pan-list',
    templateUrl: './pan-list.component.html',
    styleUrls: [ './pan-list.component.css' ],
} )
export class PanListComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;

    constructor (
        private panService: PanService,
        private route: ActivatedRoute,
        private router: Router,

        private commonService: CommonService
    ) { }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    filterResults (): void {

        this.panService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );

    }

    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    resend ( log: any ) {
        this.panService.resend( log.id ).subscribe( response => {
            this.commonService.notifications.success( 'Success', 'Pan Request Sent successfully' );
        } );
    }

}
