import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Address, AddressType, Addresses, Identity, IdentityType, idDocuments } from '../../client-forms-v2.enum';
import { CommonService } from 'src/app/core/services/common.service';

@Component( {
    selector: 'app-pcc-form-item',
    templateUrl: './pcc-form-item.component.html'
} )
export class PccFormItemComponent implements OnInit {

    @Input() formGroup: FormGroup = new FormGroup( {} );

    @Input() index!: number;

    addressList: Address[] = Addresses;

    identityList: Identity[] = idDocuments;

    selectedIdentityOption: string[] = [];

    selectedAddressOption: string[] = [];

    readonly MAX_DOCUMENTS: number = 2;

    get addresses (): Address[] {
        return this.addressList.filter( item => item.value !== 'any' );
    }

    get identities (): Identity[] {
        return this.identityList.filter( item => item.value !== 'any' );
    }

    get identityFormArray (): FormArray | any {
        return this.formGroup.get( 'documents' ) as FormArray;
    }

    get identityFormGroup (): FormGroup {

        return this.fb.group( {
            type: this.fb.control( IdentityType.AadhaarCard, [ Validators.required ] ),
            required: this.fb.control( true, [ Validators.required ] )
        } )

    }

    get addressType (): FormControl {
        return this.formGroup?.get( 'address_type' ) as FormControl;
    }

    get addressRequired (): FormControl {
        return this.formGroup?.get( 'address_required' ) as FormControl;
    }

    constructor (
        protected fb: FormBuilder,
        protected commonService: CommonService
    ) { }

    ngOnInit (): void {
        this.formGroup.setValidators( this.customRequiredValidator() );
    }

    addIdentity (): void {

        if ( this.identityFormArray.length >= this.MAX_DOCUMENTS ) {

            this.commonService.notifications.error( 'Identity', `Identity max limit is ${ this.MAX_DOCUMENTS }` );

        } else {

            this.identityFormArray.push( this.identityFormGroup );
        }
    }

    removeIdentity ( index: number ): void {

        const selectedIdentityType: string = this.identityFormArray.at( index ).get( 'type' )?.value;

        this.selectedIdentityOption = this.selectedIdentityOption
            .filter( ( identityType: string ) => identityType != selectedIdentityType );

        this.identityFormArray.removeAt( index );
    }

    skipSelectedIdentity ( index: any, $event: any ): void {
        this.selectedIdentityOption[ index ] = $event.target.value;
    }

    onAddressChecked ( $event: any ): void {

        if ( $event.target.checked ) {

            this.addressType.setValue( AddressType.Current )

        } else {

            this.addressType.setValue( null );

        }
    }

    customRequiredValidator (): ValidatorFn {

        return ( control: AbstractControl ): ValidationErrors | null => {

            if ( !this.formGroup ) {

                return null;

            } else if ( !this.addressRequired.value && this.identityFormArray.length == 0 ) {

                return { required: { value: control.value } };

            } else {

                return null;

            }
        }
    }
}
