<div bsModal #authorityContactEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="editForm" (ngSubmit)="updateContact()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Contact</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">

                        <div class="row">
                            <div class="col-md-6 form-group">
                                <fieldset class="form-group">
                                    <input formControlName="id" class="form-control form-control-lg" type="hidden"
                                        ngModel="{{contact.id}}" />
                                    <label class="center-block"> Name</label>
                                    <input formControlName="name" class="form-control form-control-lg"
                                        ngModel="{{contact.name}}" />
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label>Designation </label>
                                    <input formControlName="designation" class="form-control form-control-lg"
                                        ngModel="{{contact.designation}}" />
                                </fieldset>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label>Phone Number </label>
                                    <input formControlName="phone_number" class="form-control form-control-lg"
                                        ngModel="{{contact.phone_number}}" />
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label>Email </label>
                                    <input formControlName="email" class="form-control form-control-lg"
                                        ngModel="{{contact.email}}" />
                                </fieldset>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <fieldset class="form-group">
                                    <label>Remarks </label>
                                    <input formControlName="remarks" class="form-control form-control-lg"
                                        ngModel="{{contact.remarks}}" />
                                </fieldset>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>