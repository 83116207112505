<div [formGroup]="formGroup">
    <label class="center-block"> {{labelName}} </label>
    <input [formControlName]="companyName" [typeahead]="options" [typeaheadMinLength]="0" typeaheadOptionField="name"
        placeholder="Select {{labelName}}" [typeaheadItemTemplate]="customItemTemplate" class="form-control"
        (typeaheadOnSelect)="onSelect($event)" (typeaheadNoResults)="onNoResults($event)" [required]="required">
    <small class="text-danger" *ngIf="formGroup.get(companyId)?.errors">
        Required
    </small>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>