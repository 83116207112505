import { FilterService } from './../../core/services/filter.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { VehicleService } from '../../core/services/vehicle.service';
import { ActivatedRoute, NavigationExtras, Router, Params } from '@angular/router';

import { CommonService } from '../../core/services/common.service';
import { Pagination } from '../../core/models/pagination.model';


@Component( {
    selector: 'app-vehicle-list',
    templateUrl: './vehicle-list.component.html',
    styleUrls: [ './vehicle-list.component.css' ],
} )
export class VehicleListComponent implements OnInit, OnDestroy {

    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    all: string = 'all';
    // Form group associated with the filters
    clients!: any[];

    filtersSubscription!: Subscription;

    constructor (
        private vehicleService: VehicleService,
        private route: ActivatedRoute,
        private router: Router,

        private commonService: CommonService,
        private filterService: FilterService
    ) { }

    ngOnInit () {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );
    }


    filterResults ( params: Params ): void {

        this.vehicleService.filter( params ).subscribe( response => {
            this.pagination = response;
        } );
    }

    showPage ( page: any ) {
        this.filterService.filterByPage( page );
    }

    resend ( source: string, log: { id: any; } ) {

        const data = {
            'type': source,
            'log_id': log.id,
        };

        this.vehicleService.resend( data ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }

    resendAll ( source: string ) {

        this.vehicleService.resendAll( source, this.filterService.httpParams ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }

    exportLogsExcel () {
        this.getData( this.filterService.httpParams.set( 'unique', 'no' ) );
    }

    exportUniqueLogsExcel () {
        this.getData( this.filterService.httpParams.set( 'unique', 'yes' ) );
    }


    exportDailyUniqueLogsExcel () {
        this.getData( this.filterService.httpParams.set( 'unique', 'daily' ) );
    }

    getData ( params: Params ) {
        this.vehicleService.downloadVehicleExcel( params );
    }

    filterQueriedResults (): void {

        this.filterService.filter();
    }

    exportBlacklistedLogsExcel () {
        this.getBlacklistedExcel( this.filterService.httpParams.set( 'unique', 'no' ) );
    }

    getBlacklistedExcel ( params: Params ): void {
        this.vehicleService.downloadBlacklistExcel( params );
    }

    exportChallanLogsExcel () {
        this.vehicleService.downloadChallanExcel( this.filterService.httpParams );
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }
}
