import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';


@Injectable( {
    providedIn: 'root'
} )
export class SearchFormService {

    constructor ( private apiService: ApiService ) { }

    searchEmployee ( value: string ): Observable<any[]> {
        return this.apiService.get( 'employees/search' + '?query=' + value );
    }

}
