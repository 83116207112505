<div bsModal #companyAssignRoleModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="assignCompanyRoleForm" (ngSubmit)="assignRole()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Assign Role</h4>
                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">

                        <fieldset class="form-group">
                            <label class="center-block"><b>Select Role</b></label>
                            <select formControlName="role_id" class="form-control form-control-lg" id="role_id">
                                <option value="">Select Role</option>
                                <option *ngFor="let role of roles" [value]="role.id">{{role.name}}</option>
                            </select>
                        </fieldset>

                        <small class="text-danger" id="role-error"
                            [hidden]="assignCompanyRoleForm.get('role_id')?.valid || assignCompanyRoleForm.get('role_id')?.pristine">
                            Select Role
                        </small>

                    </fieldset>

                    <fieldset [disabled]="isSubmitting">

                        <fieldset class="form-group">
                            <label class="center-block"><b>Select User</b></label>
                            <select formControlName="user_id" class="form-control form-control-lg" id="user_id">
                                <option value=""> Select User </option>
                                <option *ngFor="let user of users" [value]="user.id">{{user.name}}</option>
                            </select>
                        </fieldset>

                        <small class="text-danger" id="user-error"
                            [hidden]="assignCompanyRoleForm.get('user_id')?.valid || assignCompanyRoleForm.get('user_id')?.pristine">
                            Select User
                        </small>

                    </fieldset>

                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" id="assign_role_button"
                            [disabled]="!assignCompanyRoleForm.valid">
                            Assign Role
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>