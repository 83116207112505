import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Remarks } from '../../core/models/remarks.model';
import { CommonService } from '../../core/services/common.service';
import { EmployeeService } from '../../core/services/employee.service';

@Component( {
    selector: 'app-employee-remarks',
    templateUrl: './employee-remarks.component.html',
    styleUrls: [ './employee-remarks.component.css' ],
} )
export class EmployeeRemarksComponent implements OnInit {

    // Which lot is in use here
    @Input() remarks!: Remarks[] | any;

    constructor (
        protected route: ActivatedRoute,
        protected router: Router,
        private employeeService: EmployeeService,
        protected common: CommonService,
    ) { }

    ngOnInit (): void {
    }

    insertRemarks ( event: any ): void {

        let remarks: string = event.target.value;

        // Insert Remarks on Pressing Enter Key
        if ( event.keyCode === 13 && remarks.length > 0 ) {

            this.employeeService.insertRemarks( +this.route.snapshot.params[ 'id' ], remarks )
                .subscribe(
                    response => {
                        event.target.value = '';
                        remarks = '';
                        this.remarks = response.remarks;
                        this.common.notifications.success( 'Remarks added' );
                    }
                );
        }
    }

}
