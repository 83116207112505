<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
<div bsModal #subInstituteCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">

        <form [formGroup]="educationInstituteFormGroup" (ngSubmit)="createSubInstitute()"
            (keydown.enter)="$event.preventDefault()">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Sub Institute</h4>

                </div>

                <div class="modal-body">



                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Name
                                <input formControlName="name" class="form-control" required>
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Address
                                <input formControlName="address" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">

                            <label class="center-block"> States </label>

                            <input formControlName="state_name" [typeahead]="states" [typeaheadMinLength]="0"
                                typeaheadOptionField="name" placeholder="Select State"
                                [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                (typeaheadOnSelect)="OnTypeheadSelect($event,'state')">
                            <small class="text-danger" *ngIf="educationInstituteFormGroup.get('state_id')?.errors">
                                Required
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> District </label>

                            <input formControlName="district_name"
                                [typeahead]="districts | filterByStateId:educationInstituteFormGroup.get('state_id')?.value"
                                [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select District"
                                [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                (typeaheadOnSelect)="OnTypeheadSelect($event,'district')">
                            <small class="text-danger" *ngIf="educationInstituteFormGroup.get('district_id')?.errors">
                                Required
                            </small>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3 form-group">
                            <label class="center-block"> Pincode
                                <input formControlName="pincode" class="form-control">
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block"> Beneficiary Name
                                <input formControlName="beneficiary_name" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Recipient Name
                                <input formControlName="recipient_name" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Contact Number
                                <input formControlName="number" class="form-control">
                            </label>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3 form-group">
                            <label class="center-block">Email
                                <input formControlName="email" class="form-control" email>
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Website
                                <input formControlName="website" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Remarks
                                <textarea formControlName="remarks" class="form-control" rows="1"></textarea>
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Instructions
                                <textarea formControlName="instruction" class="form-control" rows="1"></textarea>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <a class="btn btn-default btn-primary" (click)="createSubInstitute()"> Create</a>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>