import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { CommonService } from '../../core/services/common.service';
import { LotService } from '../../core/services/lot.service';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-uber-import',
    templateUrl: './uber-import.component.html',
    styleUrls: [ './uber-import.component.css' ],
} )
export class UberImportComponent extends ImportExcelComponent implements OnInit {

    uploadForm: FormGroup;
    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    lotId!: number;
    validationMessages: string[] | any = [];

    constructor (
        private lotService: LotService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );

    }

    ngOnInit (): void {
        this.route.params.subscribe( params => {
            this.lotId = +params[ 'id' ];
        } );
    }

    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void {

    }

    applyValidations (): void {

    }

    importEmployees (): void {

        this.lotService.importUberEmployees( this.lotId, this.excelForm.value ).subscribe(
            ( response: any ) => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ 'lots/' + this.lotId ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error Occured' );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }

}
