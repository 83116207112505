import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { Contact } from '../../core/models/contact.model';
import { CommonService } from '../../core/services/common.service';
import { ContactService } from '../../core/services/contact.service';

@Component( {
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: [ './contact-edit.component.css' ],
    providers: [ ContactService ],
} )
export class ContactEditComponent implements OnInit {

    isSubmitting: boolean = false;
    contact: Contact = new Contact();

    contactEditForm!: FormGroup;

    @ViewChild( 'contactEditModal', { static: false } )
    public contactEditModal!: ModalDirective;

    @Output()
    onContactEdited: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private contactService: ContactService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) { }


    ngOnInit (): void {

        this.initForm();
    }


    initForm (): void {

        this.contactEditForm = this.fb.group( {
            id: [ '' ],
            name: [ '', [ Validators.required ] ],
            primary_email: [ '', [ validateEmail() ] ],
            secondary_email: [ '', [ validateEmail() ] ],
            object_id: [ '' ],
            designation: [ '' ],
            phone_number: [ '' ],
            remarks: [ '' ],
            location: [ '' ],
            division: [ '' ],
            reference_name: [ '' ],
            active: [ '' ],
            helpful: [ '' ],
            centralised: [ '' ],

        } );
    }


    show ( contact: Contact ): void {

        this.contact = contact;
        this.contactEditForm.patchValue( this.contact );
        this.contactEditModal.show();
    }

    hide (): void {

        this.contactEditModal.hide();
    }

    /**
     * Common function to assign work to the selected user.
     */
    update (): void {

        this.isSubmitting = true;

        this.contactService.update( this.contactEditForm.value.id, this.contactEditForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Company Updated' );
                this.isSubmitting = false;
                this.hide();
                this.onContactEdited.emit( true );
            },
            error => {
                this.commonService.notifications.error( 'Unable to update company' );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }
}
