<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
<form [formGroup]="employmentCompanyForm" (ngSubmit)="createCompany()" (keydown.enter)="$event.preventDefault()">
    <div bsModal #companyCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="mySmallModalLabel" aria-hidden="true">

        <div class="modal-dialog modal-lg">


            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Company</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>


                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-md-4 form-group">
                                    <label class="center-block"> Company Name
                                        <input formControlName="company_name" class="form-control" required
                                            #companySearch>
                                    </label>
                                </div>

                                <div class="col-md-8 form-group">
                                    <label class="center-block"> Address
                                        <input formControlName="address" class="form-control">
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 form-group">

                                    <label class="center-block"> States </label>

                                    <input formControlName="state_name" [typeahead]="states" [typeaheadMinLength]="0"
                                        typeaheadOptionField="name" placeholder="Select State"
                                        [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                        (typeaheadOnSelect)="OnTypeheadSelect($event,'state')">
                                    <small class="text-danger" *ngIf="employmentCompanyForm.get('state_id')?.errors">
                                        Required
                                    </small>
                                </div>
                                <div class="col-md-3 form-group">
                                    <label class="center-block"> District </label>

                                    <input formControlName="district_name"
                                        [typeahead]="districts | filterByStateId:employmentCompanyForm.get('state_id')?.value"
                                        [typeaheadMinLength]="0" typeaheadOptionField="name"
                                        placeholder="Select District" [typeaheadItemTemplate]="customItemTemplate"
                                        class="form-control" (typeaheadOnSelect)="OnTypeheadSelect($event,'district')">
                                    <small class="text-danger" *ngIf="employmentCompanyForm.get('district_id')?.errors">
                                        Required
                                    </small>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="center-block"> Pincode
                                        <input formControlName="pincode" class="form-control">
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 form-group">
                                    <label class="center-block"> Contact Number
                                        <input formControlName="contact_number" class="form-control">
                                    </label>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="center-block">Email
                                        <input formControlName="email" class="form-control" email>
                                    </label>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label class="center-block"> Website
                                        <input formControlName="website" class="form-control">
                                    </label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <label class="center-block"> Remarks
                                        <textarea formControlName="company_remarks" class="form-control"></textarea>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <h4>Available Companies:</h4>
                            <ul class="list-unstyled">
                                <li *ngFor="let company of companySuggestions | slice:0:10">
                                    {{company?.name}}
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Contact</h4>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Name Of Person
                                <input formControlName="name" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Designation
                                <input formControlName="designation" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Primary Mail
                                <input formControlName="primary_email" class="form-control" email>
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Secondary Mail
                                <input formControlName="secondary_email" class="form-control" email>
                            </label>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3 form-group">
                            <label class="center-block"> Number
                                <input formControlName="phone_number" class="form-control">
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block"> Location
                                <input formControlName="location" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Division
                                <input formControlName="division" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block"> Reference Name
                                <input formControlName="reference_name" class="form-control">
                            </label>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-md-3 form-group">
                            <label class="center-block"> Remarks
                                <textarea formControlName="remarks" class="form-control" rows="1"></textarea>
                            </label>
                        </div>


                        <div class="col-md-3 form-group">
                            <label class="center-block"> Active
                                <input type="checkbox" formControlName="active">
                            </label>
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block"> Helpful
                                <input type="checkbox" formControlName="helpful">
                            </label>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <a class="btn btn-default btn-primary" (click)="createCompany()"> Create</a>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>