import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-verification-stats',
    templateUrl: './verification-stats.component.html',
    styleUrls: [ './verification-stats.component.css' ],
} )
export class VerificationStatsComponent implements OnInit {

    // Which verification is in use here
    @Input()
    verification!: string;

    // Stats
    verificationStats: { [ key: string ]: any[] } = {};

    filterParams: HttpParams = new HttpParams();

    constructor (
        private route: ActivatedRoute,

        private commonService: CommonService
    ) {
    }

    ngOnInit (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterStats();
        } );
    }

    filterStats (): void {
        this.commonService.getVerificationStats( this.verification, this.filterParams ).subscribe(
            ( response: any ) => {
                this.verificationStats = response;
            }
        );
    }

}
