import { EventEmitter, Injectable, Output } from '@angular/core';
import { fileTypes } from './../enums/file-types.enum';
import { CommonService } from './common.service';

@Injectable( {
    providedIn: 'root'
} )
export class UploadService {

    public maxFileSize: number = 300;
    public maxFileError: boolean = false;

    @Output() uploadSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor ( private commonService: CommonService ) { }


    addFilesToForm ( verificationId: number, typeOfFile: fileTypes, inputEl: HTMLInputElement | any ): FormData {

        const fileCount: number = inputEl.files?.length ?? 0;

        const formData: FormData = new FormData();

        for ( let i: number = 0; i < fileCount; i++ ) {

            formData.append( 'file_' + i, inputEl.files.item( i ) );
        }

        formData.append( 'type', typeOfFile + '_' + verificationId );

        return formData;
    }

    hasExceededFileSize ( inputEl: HTMLInputElement | any ): void {

        const fileCount: number = inputEl.files.length;

        let fileSize: number = 0;

        for ( let i: number = 0; i <= fileCount; i++ ) {

            if ( inputEl.files[ i ] ) {

                fileSize = inputEl.files[ i ].size / 1024;

                if ( fileSize > this.maxFileSize ) {

                    this.maxFileError = true;

                    this.commonService.notifications.error(
                        'Unable to upload!! File size must be less than 300KB'
                    );

                }
            }
        }

    }

}
