<div class="wrapper-md">

    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Change Password
                </div>
                <div class="panel-body">
                    <form [formGroup]="changePasswordForm" (ngSubmit)="updatePassword()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Password </label>
                                <input formControlName="password" class="form-control form-control-lg"
                                    type="password" />
                                <div app-error-messages class="mt-2" [control]="password" [multipleErrors]="true"></div>
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Confirm Password</label>
                                <input formControlName="confirm_password" class="form-control form-control-lg"
                                    type="password" />
                                <div app-error-messages class="mt-2" [control]="confirmPassword"
                                    [multipleErrors]="true"></div>
                            </fieldset>

                            <button class="btn btn-lg btn-primary btn-block" type="submit"
                                [disabled]="!changePasswordForm.valid">
                                Update
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>