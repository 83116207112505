<ng-container [formGroup]="formGroup">

    <div class="col-md-12 pcc-verification">

        <div class="row">
            <div class="col-md-12">
                <input type="checkbox" (change)="onAddressChecked($event)" formControlName="address_required">
                Address Required
            </div><br /><br />

            <div class="col-md-12" *ngIf="addressRequired?.value">
                <label>Select Address Type</label>
                <select formControlName="address_type" class="form-control">
                    <option *ngFor="let address of addresses" [value]="address.value">
                        {{address.name}}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="col-md-12 pcc-verification">
        <button type="button" (click)="addIdentity()" class="btn btn-primary btn-sm">Add Identity</button>
    </div>

    <div class="col-md-12 pcc-verification" *ngFor="let identityForm of identityFormArray?.controls;let i=index;">

        <div class="row" [formGroup]="identityForm">
            <div class="col-md-2">
                <input type="checkbox" formControlName="required"> Is Required
            </div>

            <div class="col-md-9">

                <select formControlName="type" class="form-control" (change)="skipSelectedIdentity(i,$event)">

                    <option [disabled]="selectedIdentityOption.includes(identity.value)"
                        *ngFor="let identity of identities" [value]="identity.value">

                        {{identity.name}}

                    </option>
                </select>
            </div>

            <div class="col-md-1">
                <button type="button" (click)="removeIdentity(i)" class="btn">
                    <i class="fa fa-trash text text-danger" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</ng-container>