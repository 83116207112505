import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnChanges, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { environment } from '../../../environments/environment';
import { validateCompletionDate } from '../../common/validators/validate-completion-date.directive';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { Employee } from '../../core/models/employee.model';
import { PccVerification } from '../../core/models/pcc-verification.model';
import { CommonService } from '../../core/services/common.service';
import { PccService } from '../../core/services/pcc.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-pcc-edit',
    templateUrl: './pcc-edit.component.html',
    styleUrls: [ './pcc-edit.component.css' ],
} )
export class PccEditComponent extends VerificationEditComponent implements OnInit, OnChanges {

    verifications!: PccVerification[];
    override attachments: any[] = [];
    override crmUrl!: string;
    override verificationFormGroup!: FormGroup;
    documentTypes!: any[];
    payment_modes!: any[];
    duration_of_stay: string | any = '';
    police_station: string | any = '';
    vendors!: any;
    documents: any[] = [];

    constructor (
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override verificationService: PccService,
        public global: GlobalService,
        protected override common: CommonService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected override confirmationModalService: ConfirmationModalService

    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );
        this.initForm();
    }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    get statuses () { return this.global.fetchStatuses(); }

    override ngOnInit (): void {

        this.verification = 'pcc';

        // this.common.populateAuthorities( 'pcc' );

        this.global.populateAuthorities( 'pcc' );

        this.crmUrl = environment.crm_url;

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                employee => {
                    this.employee = employee;
                    this.populateForm( employee );
                    this.getAttachments( employee );
                    this.isSubmitting = false;
                    this.ngOnChanges();
                },
            );

        } );
        this.common.getPaymentModes().subscribe(
            response => {
                this.payment_modes = response;
            }
        );
        this.verificationService.getDocumentTypes().subscribe(
            response => this.documentTypes = response,
            err => this.common.notifications.error( 'Error occurred while getting Document Types' )
        );

        this.subscribeToQueryChanges();

    }

    initForm (): void {

        this.getVendors();

        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            remarks: [ null ],
            id: [ null ],
            formArray: this.fb.array( [] ),
        } );

    }

    getVendors (): void {

        this.common.getVendors( 'address' ).subscribe( ( vendors: any ) => this.vendors = vendors );

    }

    populateForm ( employee: Employee ): void {

        this.documents = [];

        this.verificationFormGroup.patchValue( employee );

        const verifications = employee.pcc_verifications as PccVerification[];

        const verificationFormGroup = verifications.map( verification => {

            this.documents.push( verification.documents );

            return this.fb.group( verification );
        } );

        const verificationGroupArray = this.fb.array( verificationFormGroup );

        if ( verificationGroupArray.value[ 0 ] ) {

            this.duration_of_stay = verificationGroupArray.value[ 0 ].duration_of_stay;

            this.police_station = verificationGroupArray.value[ 0 ].police_station;
        }

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );

    }

    ngOnChanges (): void {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );

            this.applyValidationsForStatus( form.get( 'status_id' )?.value, form );

            this.applyValidationsForEligibility( form.get( 'eligible' )?.value, form );

            form.get( 'status_id' )?.valueChanges.subscribe(
                ( statusId: any ) => {
                    this.applyValidationsForStatus( statusId, form );
                }
            );

            form.get( 'eligible' )?.valueChanges.subscribe(
                ( eligible: any ) => {
                    this.applyValidationsForEligibility( eligible, form );
                }
            );

        } );
    }

    // Apply Validations on edit Page.
    applyValidationsForStatus ( statusId: any, form: any ) {

        const validStatusesCompletionDate = [ 3, 4, 6, 8, 10, 11 ];

        // List of statuses for which validations is required on client remarks.
        const validStatusesForClientRemarks = [ 2, 4, 5, 6, 7, 8, 10, 11 ];

        const validStatusesForPccDetails = [ 1, 3, 4, 8 ];

        // If selected status is present in the list of statuses defined, apply validations.
        if ( validStatusesCompletionDate.indexOf( statusId ) != -1 ) {

            form.get( 'completed_at' ).setValidators( [ Validators.required, validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );

        } else {
            form.get( 'completed_at' ).setValidators( [ validateCompletionDate( this.current_date, this.employee.created_at.slice( 0, 10 ) ) ] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForClientRemarks.indexOf( statusId ) != -1 ) {

            form.get( 'client_remarks' ).setValidators( [ Validators.required ] );

        } else {
            form.get( 'client_remarks' ).setValidators( [] );
        }

        if ( validStatusesForPccDetails.indexOf( statusId ) != -1 ) {
            form.get( 'payment_reference_number' ).setValidators( [ Validators.required ] );
            form.get( 'pcc_application_number' ).setValidators( [ Validators.required ] );
            form.get( 'official_fees' ).setValidators( [ Validators.required ] );
            form.get( 'applied_at' ).setValidators( [ Validators.required ] );
            form.get( 'payment_mode_id' ).setValidators( [ Validators.required ] );
            form.get( 'document_types' ).setValidators( [ Validators.required ] );
            form.get( 'registered_authority_id' ).setValidators( [ Validators.required ] );
        }
        else {
            form.get( 'payment_reference_number' ).setValidators( [] );
            form.get( 'pcc_application_number' ).setValidators( [] );
            form.get( 'official_fees' ).setValidators( [] );
            form.get( 'applied_at' ).setValidators( [] );
            form.get( 'payment_mode_id' ).setValidators( [] );
            form.get( 'document_types' ).setValidators( [] );
            form.get( 'registered_authority_id' ).setValidators( [] );
        }

        // Update Fields
        form.get( 'completed_at' ).updateValueAndValidity();
        form.get( 'client_remarks' ).updateValueAndValidity();
        form.get( 'payment_reference_number' ).updateValueAndValidity();
        form.get( 'pcc_application_number' ).updateValueAndValidity();
        form.get( 'official_fees' ).updateValueAndValidity();
        form.get( 'applied_at' ).updateValueAndValidity();
        form.get( 'payment_mode_id' ).updateValueAndValidity();
        form.get( 'document_types' ).updateValueAndValidity();
        form.get( 'registered_authority_id' ).updateValueAndValidity();
    }

    createPcc () {

        this.verificationService.create( this.employee.id ).subscribe(
            response => {
                this.populateForm( response );
            }
        );

        this.common.notifications.success( 'Pcc Verification added' );
    }

    private applyValidationsForEligibility ( value: any, form: AbstractControl ) {

        if ( value == '1' ) {
            form.get( 'address_type' )?.setValidators( [ Validators.required ] );
            form.get( 'address' )?.setValidators( [ Validators.required ] );
            form.get( 'state_id' )?.setValidators( [ Validators.required ] );
            form.get( 'district_id' )?.setValidators( [ Validators.required ] );
            form.get( 'pincode' )?.setValidators( [ Validators.required, Validators.pattern( '(^[0-9]{6}$)' ) ] );
            form.get( 'duration_of_stay' )?.setValidators( [ Validators.required ] );
            form.get( 'police_station' )?.setValidators( [ Validators.required ] );
        } else {
            form.get( 'address_type' )?.setValidators( [] );
            form.get( 'address' )?.setValidators( [] );
            form.get( 'state_id' )?.setValidators( [] );
            form.get( 'district_id' )?.setValidators( [] );
            form.get( 'pincode' )?.setValidators( [] );
            form.get( 'duration_of_stay' )?.setValidators( [] );
            form.get( 'police_station' )?.setValidators( [] );
        }

        form.get( 'address_type' )?.updateValueAndValidity();
        form.get( 'address' )?.updateValueAndValidity();
        form.get( 'state_id' )?.updateValueAndValidity();
        form.get( 'district_id' )?.updateValueAndValidity();
        form.get( 'pincode' )?.updateValueAndValidity();
        form.get( 'payment_reference_number' )?.updateValueAndValidity();
        form.get( 'pcc_application_number' )?.updateValueAndValidity();
        form.get( 'duration_of_stay' )?.updateValueAndValidity();
        form.get( 'applied_at' )?.updateValueAndValidity();
        form.get( 'police_station' )?.updateValueAndValidity();
    }


    onModalUpdated () {

        this.common.populateAuthorities( 'pcc' );

    }

    onFileUploaded ( $event: any, index: any ): void {

        // console.log( $event );

        // this.documents[i].push( $event );

    }

    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;

            this.cdr.detectChanges();

            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() ).subscribe(

                response => {

                    this.handleUpdateResponse( response );

                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );

                    this.isSubmitting = false;

                    this.cdr.detectChanges();

                },

                error => {

                    this.common.displayValidationErrors( error.errors );

                    this.isSubmitting = false;

                    this.cdr.detectChanges();

                },

                () => {

                    this.isSubmitting = false;

                    this.cdr.detectChanges();

                }

            );
        }
    }

}
