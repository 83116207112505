export const environment = {
    production: false,
    app_env: 'staging',
    api_url: 'https://staging.instaveritas.com/api/',
    crm_url: 'https://staging.instaveritas.com/',
    socket_url: 'http://staging.instaveritas.com:3300',
    fir_url: 'https://fir.instaveritas.com/api/',
    sentry_dns: 'https://873c2805223d4cf3b4c50402c040c2d1@o439184.ingest.sentry.io/5405518',
    gtag_config: 'G-E9YBHTWBQY',
    old_crm_url: 'https://staging.instaveritas.com/',
};
