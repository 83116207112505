<div bsModal #followUpDetailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content" style="max-height: 100%; overflow-y: auto;">
            <div class="modal-header">
                <h4 class="modal-title pull-left">FollowUp Mail Details: <b>{{name}}</b></h4>

            </div>
            <div class="modal-body">
                <div style="overflow-x: auto;overflow-y: auto;max-width: 100%">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th style="width:25%;">DETAILS</th>
                                <th style="width:25%;">SENT AT</th>
                                <th style="width:25%;">STATUS</th>
                                <th style="width:25%;">SENDER</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let details of followUpDetails; index as i">
                                <td style="width:25%;">Follow Up {{i + 1}}</td>
                                <td style="width:25%;">{{details.sent_at}}</td>
                                <td style="width:25%;">{{details.status}}</td>
                                <td style="width:25%;">
                                    <ul class="list-unstyled">
                                        <li>
                                            {{details.sender.name}}
                                        </li>
                                        <li>
                                            {{details.sender.company}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>