import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-email-details',
    templateUrl: './email-details.component.html',
    styleUrls: [ './email-details.component.css' ],
} )
export class EmailDetailsComponent implements OnInit {
    body: any;
    safeHtml!: SafeHtml;

    @ViewChild( 'emailDetailsModal', { static: false } ) emailDetailsModal!: ModalDirective;

    constructor ( private sanitizer: DomSanitizer ) { }

    ngOnInit (): void {

    }

    show ( body: any ): void {
        this.body = body;
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml( this.body );
        this.emailDetailsModal.show();
    }

    hide (): void {
        this.emailDetailsModal.hide();
    }
}
