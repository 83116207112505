import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import * as Rx from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from './user.service';


@Injectable( {
    providedIn: 'root'
} )
export class WebsocketService {


    // Our socket connection
    private _socket: any;

    constructor (
        private userService: UserService,
    ) {
        this.connect();
    }


    connect (): Rx.Subject<WindowEventMap> {

        const currentUser = this.userService.getCurrentUser();

        const options: any = {

            query: 'user_id=' + currentUser.id,

        };

        this._socket = io.connect( environment.socket_url, options );

        return Rx.Subject.create();
    }


    get socket () {
        return this._socket;
    }


}
