<div *ngIf="createForm" bsModal #createCaseModal="bs-modal" class="modal fade" tabindex="-1">
    <form [formGroup]="createForm" (ngSubmit)="create()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Case</h4>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="form-group col-md-3">

                            <label class="center-block">Candidate Category

                                <select class="form-control" formControlName="candidate_category">
                                    <option *ngFor="let category of categories" [value]="category.key">
                                        {{category.index}}
                                    </option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('candidate_category')?.errors">
                                Required
                            </small>

                        </div>

                        <div class="form-group col-md-3">
                            <label style="min-width: 188.88px;">Nature Of Case

                                <select class="form-control" formControlName="nature_of_case">
                                    <option *ngFor="let nature_of_case of natureOfCases" [value]="nature_of_case.key">
                                        {{nature_of_case.index}}
                                    </option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('nature_of_case')?.errors">
                                Required
                            </small>
                        </div>

                        <div *ngIf="acts" class="form-group col-md-3">
                            <label>Acts Types
                                <app-multi-select [options]="acts" [placeholder]="'Select Acts Types'"
                                    [controlName]="'act_ids'" [formGName]="createForm"></app-multi-select>
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('act_ids')?.errors">
                                Required
                            </small>
                        </div>

                        <div *ngIf="createForm.get('act_ids')?.value" class="form-group col-md-3">

                            <span class="text-mute" [popover]="popTemplate" triggers="mouseenter:mouseleave"
                                container="body">Acts
                                Sections</span>

                            <ng-template #popTemplate>
                                <ul class="list-unstyled"
                                    *ngFor="let section of createForm.get('section_names')?.value">
                                    <li> {{section}}</li>
                                </ul>
                            </ng-template>

                            <app-multi-select [options]="allSections| filterByActId:createForm.get('act_ids')?.value"
                                [placeholder]="'Select Section'" [controlName]="'section_ids'"
                                [formGName]="createForm"></app-multi-select>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label class="center-block">CNR Number
                                <input class="form-control" formControlName="cnr_number">
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('cnr_number')?.errors">
                                16 characters only
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Case Type
                                <input class="form-control" formControlName="type">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Filling Number
                                <input class="form-control" formControlName="filling_number">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Filling Year
                                <input class="form-control" formControlName="filling_year">
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('filling_year')?.errors">
                                4 digits allowed
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label class="center-block">Registration Number
                                <input class="form-control" formControlName="registration_number">
                            </label>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Registration Year
                                <input type="number" class="form-control" formControlName="registration_year">
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('registration_year')?.errors">
                                4 digits allowed
                            </small>
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Case Status
                                <input class="form-control" formControlName="case_status">
                            </label>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">District Court:
                                <input class="form-control" formControlName="district_court">
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('district_court')?.errors">
                                Required
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">Police Station
                                <input class="form-control" formControlName="police_station">
                            </label>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">FIR Number
                                <input class="form-control" formControlName="fir_number">
                            </label>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">FIR Year
                                <input class="form-control" formControlName="fir_year">
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('fir_year')?.errors">
                                4 digits allowed
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Case Stage
                                <input class="form-control" formControlName="stage">
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">Nature Of Disposal
                                <input class="form-control" formControlName="nature_of_disposal">
                            </label>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Fir Available
                                <select class="form-control" formControlName="fir_available">
                                    <option value="null"> </option>
                                    <option value="1"> Yes </option>
                                    <option value="0"> No </option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('fir_available')?.errors">
                                Required
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Judgement Available
                                <select class="form-control" formControlName="judgement_available">
                                    <option value="null"> </option>
                                    <option value="1"> Yes </option>
                                    <option value="0"> No </option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('judgement_available')?.errors">
                                Required
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="center-block">Vcourt Case ID
                                <input class="form-control" type="number" formControlName="court_search_result_id">
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-3">
                            <label class="center-block">Risk Level
                                <select class="form-control" formControlName="risk_level">
                                    <option value="low"> Low </option>
                                    <option value="very-low"> Very Low </option>
                                    <option value="high"> High </option>
                                    <option value="very-high"> Very High </option>
                                </select>
                            </label>
                            <small class="text-danger" *ngIf="createForm.get('risk_level')?.errors">
                                Required
                            </small>
                        </div>
                        <div class="form-group col-md-3" *ngIf="statuses && statuses?.cases">
                            <label class="center-block">Verification Status</label>
                            <select class="form-control" formControlName="status_id">
                                <option *ngFor="let status of statuses.cases" value="{{status.id}}">
                                    {{status.title}}
                                </option>
                            </select>
                            <small class="text-danger" *ngIf="createForm.get('status_id')?.errors">
                                Required
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-12">
                            <label class="center-block">Client Remarks
                                <textarea class="form-control" formControlName="client_remarks"></textarea>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">

                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!createForm.valid">
                            Create
                        </button>
                    </div>

                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>

                </div>
            </div>
        </div>
    </form>
</div>