<div bsModal #insuranceDetails="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{rc_number}} Insurance Details</h4>

            </div>
            <div class="modal-body" *ngFor="let owner of vehiclerc">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th>
                                Insurance Company
                            </th>
                            <th>
                                Insurance Valid Upto
                            </th>
                            <th>
                                Insurance Policy Number
                            </th>
                            <th>
                                Owners Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{owner.insurance_company}}
                            </td>

                            <td>
                                {{owner.insurance_upto}}
                            </td>

                            <td>
                                {{owner.insurance_policy_number}}
                            </td>

                            <td>
                                {{owner.owners_name}}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>