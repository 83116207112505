import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LicenceService } from '../../core/services/licence.service';
import { ParivahanSarathiStats } from 'src/app/core/interfaces/licence-logs/parivahan-sarathi-stats';
import { SarathiSimpleStats } from 'src/app/core/interfaces/licence-logs/sarathi-simple-stats';
import { SarathiStatus } from 'src/app/core/interfaces/licence-logs/sarathi-stats';
import { ParivahanAppStats } from 'src/app/core/interfaces/licence-logs/parivahan-app-stats';
import { ParivahanSimpleStats } from 'src/app/core/interfaces/licence-logs/parivahan-simple-stats';
import { StateStatsLicenceStats } from 'src/app/core/interfaces/licence-logs/states-stats-licence';

@Component( {
    selector: 'app-licence-stats',
    templateUrl: './licence-stats.component.html',
    styleUrls: [ './licence-stats.component.css' ],
} )
export class LicenceStatsComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    sarathiStats!: SarathiStatus;
    parivahanSarathiStats!: ParivahanSarathiStats;
    parivahanAppStats!: ParivahanAppStats;
    stateStats!: StateStatsLicenceStats;
    parivahanSimpleStats!: ParivahanSimpleStats;
    sarathiSimpleStats!: SarathiSimpleStats;

    constructor (
        private route: ActivatedRoute,
        private licenceService: LicenceService
    ) { }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterStats();
        } );
    }

    filterStats (): void {
        if ( this.filterParams.keys().length ) {
            this.licenceService.getStats( this.filterParams ).subscribe(
                response => {
                    this.sarathiStats = response[ 'sarathi' ];
                    this.parivahanSarathiStats = response[ 'parivahan_sarathi' ];
                    this.parivahanAppStats = response[ 'parivahan_app' ];
                    this.stateStats = response[ 'state' ];
                    this.parivahanSimpleStats = response[ 'parivahan_simple' ];
                    this.sarathiSimpleStats = response[ 'sarathi_simple' ];
                }
            );
        }
    }


}
