import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs/internal/Observable';
import { HttpParams } from '@angular/common/http';
import { VerificationStatus } from '../models/verification-status.model';
import Status from '../models/status.model';


@Injectable( {
    providedIn: 'root'
} )

export class VerificationStatusService {

    constructor ( private api: ApiService ) { }

    create ( values: Status ): Observable<{ code: number, message: string }> {
        return this.api.post( 'statuses', values );
    }


    getStatuses ( params: HttpParams ): Observable<any> {
        return this.api.get( 'statuses', params );
    }

    update ( data: Status, status: { verification: string, id: number } ): Observable<any> {
        return this.api.put( `statuses/${ status.verification }/${ status.id }`, data );
    }

}
