import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { AuthorityService } from '../core/services/authority.service';
import { AuthorityEditComponent } from './authority-edit/authority-edit.component';
import { AuthorityListComponent } from './authority-list/authority-list.component';
import { AuthorityCreateContactComponent } from './authority-create-contact/authority-create-contact.component';
import { AuthorityEditContactComponent } from './authority-edit-contact/authority-edit-contact.component';

@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        AuthorityEditComponent,
        AuthorityListComponent,
        AuthorityCreateContactComponent,
        AuthorityEditContactComponent,
    ],
    providers: [ AuthorityService ],
} )
export class AuthorityModule {
}
