import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-request-modal',
    templateUrl: './request-modal.component.html',
} )
export class RequestModalComponent implements OnInit {


    request: any = '';
    closeBtnName!: string;

    constructor ( public bsModalRef: BsModalRef ) { }

    ngOnInit () {

    }


}
