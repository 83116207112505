import { CommonService } from './../../core/services/common.service';
import { OcrService } from './../../core/services/ocr.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component( {
    selector: 'app-html-response-modal',
    templateUrl: './html-response-modal.component.html',
    styleUrls: [],
} )
export class HtmlResponseModalComponent implements OnInit {

    public attachments: any;

    @ViewChild( 'htmlResponse', { static: false } )
    public htmlResponse!: ModalDirective;

    constructor (
        private ocrService: OcrService,
        private commonService: CommonService
    ) { }

    ngOnInit () {
    }

    show ( logId: number ) {

        this.ocrService.getHtmlResponse( logId ).subscribe(
            ( response: any ) => {
                this.attachments = response[ 'attachments' ];
                this.htmlResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    hide () {
        this.htmlResponse.hide();
    }

}
