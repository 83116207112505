import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, fromEvent } from 'rxjs';
import { Component, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-employment-create-company',
    templateUrl: './employment-create-company.component.html',
    styleUrls: [ './employment-create-company.component.css' ],
} )
export class EmploymentCreateCompanyComponent {

    employmentCompanyForm!: FormGroup;
    companySuggestions!: any[];

    @ViewChild( 'companyCreateModal', { static: false } ) companyCreateModal!: ModalDirective;
    @ViewChild( 'companySearch', { static: false } ) companySearch!: ElementRef;

    @Output()
    onCompanyCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private employmentService: EmploymentService,
        private commonService: CommonService,
        public global: GlobalService,
        private fb: FormBuilder,
    ) {
        this.initForm();
    }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    ngAfterViewInit () {
        fromEvent( this.companySearch.nativeElement, 'input' )
            .pipe( map( ( event: any ) => ( event.target as HTMLInputElement ).value ) )
            .pipe( debounceTime( 300 ) )
            .pipe( distinctUntilChanged() )
            .subscribe( data => this.subscribeToCompanyNameChanges() );
    }

    // Making the values blnak again.
    initForm (): void {

        this.employmentCompanyForm = this.fb.group( {
            company_name: [ '' ],
            address: [ '' ],
            state_id: [ 0 ],
            district_id: [ 0 ],
            pincode: [ '' ],
            contact_number: [ '' ],
            company_remarks: [ '' ],
            website: [ '' ],
            email: [ '' ],
            name: [ '' ],
            designation: [ '' ],
            primary_email: [ '' ],
            secondary_email: [ '' ],
            phone_number: [ '' ],
            location: [ '' ],
            division: [ '' ],
            reference_name: [ '' ],
            remarks: [ '' ],
            active: [ false ],
            helpful: [ false ],
            state_name: [ '' ],
            district_name: [ '' ],
        } );
    }


    show (): void {

        this.companyCreateModal.show();

    }

    hide (): void {
        this.initForm();
        this.companySuggestions = [];
        this.companyCreateModal.hide();
    }

    /**
     * Common function to assign work to the selected user.
     */
    createCompany (): void {

        this.employmentService.createCompany( this.employmentCompanyForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Success', 'Company Created' );
                this.hide();
                this.onCompanyCreated.emit( true );

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error', 'Unable to create Company' );
            }
        );
    }

    OnTypeheadSelect ( event: any, name: string ): void {

        const controlId: string = name + '_id';


        if ( name === 'state' ) {

            this.employmentCompanyForm.get( 'district_name' )?.setValue( '' );

        }

        this.employmentCompanyForm.get( controlId )?.setValue( event.item.id );

    }

    subscribeToCompanyNameChanges () {

        this.employmentService.getPaginatedCompaniesList( { 'name': this.employmentCompanyForm.controls[ 'company_name' ].value } ).subscribe(
            ( response: any ) => {

                this.companySuggestions = response.data
            }
        );
    }
}
