import { FilterService } from './../../core/services/filter.service';
import { Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router, Params } from '@angular/router';
import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-employment-list-company',
    templateUrl: './employment-list-company.component.html',
    styleUrls: [ './employment-list-company.component.css' ],
} )
export class EmploymentListCompanyComponent implements OnInit {

    pagination: Pagination = new Pagination();
    loading!: boolean;
    isSubmitting: boolean = false;
    id!: number;
    filterParams: HttpParams = new HttpParams();
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    filtersSubscription!: Subscription;


    constructor (
        protected commonService: CommonService,
        private employmentService: EmploymentService,
        private fb: FormBuilder,
        private filterService: FilterService,
        private confirmationModalService: ConfirmationModalService
    ) {
        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filterResults( this.filterFormGroup.value );
    }


    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            name: [ '' ],
        } );
    }

    filterResults ( params: Params ): void {
        this.employmentService.getPaginatedCompaniesList( params ).subscribe(
            response => this.pagination = response
        );
    }

    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }


    showPage ( page: number ): void {

        this.filterService.filterByPage( page );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    // Call back from the modal to update the list of all companies.
    onModalUpdated (): void {

        this.filterResults( this.filterFormGroup.value );
    }

    destroyContact ( id: number ): void {

        this.commonService.deleteContact( id ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( 'Success', response[ 'message' ] );
                this.ngOnInit();
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( 'Something went wrong' );

            }
        } );
    }

    onDestroyContactButtonClicked ( id: number ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.destroyContact( id ) );
    }
}
