import { CompanyService } from 'src/app/core/services/company.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component( {
    selector: 'app-company-history',
    templateUrl: './company-history.component.html'
} )
export class CompanyHistoryComponent implements OnInit {

    company: any;
    companyId!: number;

    constructor ( private companyService: CompanyService, private activatedRoute: ActivatedRoute ) { }

    ngOnInit (): void {

        this.companyId = +this.activatedRoute.snapshot.params[ 'id' ];

        this.fetchHistory();
    }

    fetchHistory (): void {

        this.companyService.getHistory( this.companyId ).subscribe( {

            next: ( response: any ) => {

                this.company = response;

            }
        } )
    }
}
