import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { EducationService } from '../../core/services/education.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-education-send-mail',
    templateUrl: './education-send-mail.component.html',
    styleUrls: [ './education-send-mail.component.css' ],
} )
export class EducationSendMailComponent {

    allAttachments: any[] = [];
    verificationId!: number;
    data!: FormGroup;
    contacts!: any[];
    to!: string;
    cc!: string;
    bcc!: string;
    contents!: string;
    checkedAttachments: any[] = [];

    @ViewChild( 'sendMail', { static: false } ) sendMail!: ModalDirective;

    constructor (
        private educationService: EducationService,
        private commonService: CommonService,
        public global: GlobalService,
        public fb: FormBuilder
    ) {
    }

    // Making the field values blank.
    initForm (): void {
        this.data = this.fb.group( {
            to: [],
            cc: [],
            bcc: [],
            contents: [],
            educationId: [ '' ],
            email_attachments: []
        } );
    }

    show ( verification: any, attachments: any ): void {

        this.initForm();

        const instituteId: number = verification.get( 'institute_id' )?.value;

        this.verificationId = verification.get( 'id' )?.value;

        this.checkedAttachments = [];

        this.data.get( 'educationId' )?.setValue( this.verificationId );

        this.allAttachments = attachments[ 'documents' ].concat( attachments[ 'degrees' ] ).concat( attachments[ 'marksheets' ] );

        this.educationService.getContacts( instituteId ).subscribe(
            response => {
                this.contacts = response;
            }
        );

        this.educationService.getHtml( this.verificationId ).subscribe(
            ( response: any ) => {
                this.contents = response[ 'content' ];
                this.data.get( 'contents' )?.setValue( response[ 'content' ] );
            }
        );

        this.sendMail.show();
    }

    hide (): void {
        this.initForm();
        this.sendMail.hide();
    }


    checkAll ( ev: any ): void {

        if ( ev.target.checked ) {

            for ( let i: number = 0; i < this.allAttachments.length; i++ ) {
                ( document.getElementsByClassName( 'checkbox-all' )[ i ] as HTMLInputElement ).checked = true;
                this.checkedAttachments.push( this.allAttachments[ i ].id );
            }
        } else {
            for ( let i: number = 0; i < this.allAttachments.length; i++ ) {
                ( document.getElementsByClassName( 'checkbox-all' )[ i ] as HTMLInputElement ).checked = false;
                this.checkedAttachments = [];
            }
        }
    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( option: any, data: any ): void {

        if ( data.target.checked ) {
            this.checkedAttachments.push( option );
        } else {
            const index: number = this.checkedAttachments.indexOf( option );
            if ( index !== -1 ) {
                this.checkedAttachments.splice( index, 1 );
            }
        }
        this.data.get( 'email_attachments' )?.setValue( this.checkedAttachments );
    }


    sendMailRequest (): void {

        this.educationService.sendMail( this.data.value ).subscribe(
            ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] );

                this.data.reset();

                this.hide();

            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to send mail' );

                this.hide();
            }
        );
    }
}
