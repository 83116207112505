import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../core/services/common.service';
import { DocumentsUploadService } from '../core/services/documents-upload.service';

@Directive( {
    selector: '[appUploadDocument]',
} )
export class UploadDocumentDirective {

    /**
     * Event fired when a file is uploaded successfully.
     * Usefull in parent components for notifications etc
     */
    @Output() uploaded: EventEmitter<any> = new EventEmitter<any>();

    /**
     * Maximum file size allowed in kb
     */
    @Input() maxFileSize: number = 5120;

    /**
     * Id of the model
     */
    @Input()
    documentableId!: string | any;

    /**
     * Type/Name of model eg. identity, address, icr, case
     */
    @Input()
    documentableType!: string;

    /**
     * Type of attachment eg. screenshot, proof, fir
     */
    @Input()
    documentType!: string;

    /**
     * Whether files should be uploaded asynchronous
     */
    @Input() async: boolean = false;

    @Input() allowedMimeTypes: string[] = [ 'image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ];

    constructor (
        private el: ElementRef,
        private documentService: DocumentsUploadService,
        private commonService: CommonService
    ) {
    }

    // @HostListener( 'click', [ '$event' ] )
    // onClicked ( event: any ): void {
    //   console.log( 'on clicked' );
    // }

    @HostListener( 'change', [ '$event' ] )
    onChanged ( event: any ): void {

        this.upload( this.el.nativeElement.files );

    }

    async upload ( files: FileList ): Promise<void> {

        if ( !this.isAboveMaxFileSize( files ) ) return;

        // tslint:disable-next-line: prefer-for-of
        for ( let i = 0; i < files.length; i++ ) {

            const file: File = files[ i ];

            if ( this.async ) {

                // upload all files together without waiting
                this.getUploadPromise( file ).then( ( res ) => {

                    this.commonService.notifications.success( 'File uploaded successfully' );

                    this.uploaded.emit( res );

                } );


            } else {

                // wait and upload files one by one
                await this.getUploadPromise( file ).then( ( res ) => {

                    this.commonService.notifications.success( 'File uploaded successfully' );

                    this.uploaded.emit( res );

                } );

            }

        }

    }


    getUploadPromise ( file: File ): Promise<any> {

        return this.documentService.uploadDocument( this.documentableId, this.documentableType, this.documentType, file ).toPromise()

    }

    isAboveMaxFileSize ( files: FileList | any ): boolean {

        let fileSize: number = 0;

        for ( let i: number = 0; i < files.length; i++ ) {

            // some blank file comes in chrome, putting this check to avoid any error
            if ( !files.item( i ) ) continue;

            fileSize = files.item( i ).size / 1024;

            if ( fileSize > this.maxFileSize ) {

                this.commonService.notifications.error( `Max file size allowed is ${ this.maxFileSize }kb` );

                return false;
            }

            if ( !this.allowedMimeTypes.includes( files.item( i ).type ) ) {

                this.commonService.notifications.error( "Images, documents and pdfs are allowed" );

                this.el.nativeElement.value = ''; // Remove the Filename from input element in HTML

                return false;
            }
        }

        return true;
    }

}
