<div class="panel panel-default" [formGroup]="icrForm">
    <div class=" panel-heading">
        Icr Verification
        <h4 class="panel-title pull-right">

            <div class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">
                <span class="glyphicon">
                    <input type="checkbox" (click)="sectionToggle($event)" [checked]="showSection">
                </span>

            </div>
        </h4>
    </div>
    <div id="collapse-icr" [className]="showSection ? 'panel-collapse collapse in' : 'panel-collapse collapse'">
        <div class="panel-body">
            <div class="col-md-12 margin-bottom-10">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"></textarea>
            </div>

            <div class="col-md-5 margin-bottom-10">
                <button type="button" (click)="addIcr()" class="btn btn-primary">Add</button>
            </div>
            <div class="col-md-7 margin-bottom-10 text text-danger" *ngIf="icrVerificationArray?.length==0">
                Please select atleast one verification
            </div>

            <div class=" col-md-12 margin-bottom-10" *ngFor="let idForm of icrVerificationArray?.controls;let i=index;">
                <div class="row" [formGroup]="idForm">
                    <div class="col-md-12 margin-bottom-10">
                        <div class="form-group">
                            <label>Icr verification by? </label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" (change)="icrTypeChange($event)" type="radio"
                                    formControlName="via" value="identity" id="radioIdentity">
                                <label class="form-check-label padding-left-5" for="radioIdentity">Identity</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" (change)="icrTypeChange($event)" type="radio"
                                    formControlName="via" value="address" id="radioAddress">
                                <label class="form-check-label padding-left-5" for="radioAddress">Address</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" (change)="icrTypeChange($event)" type="radio"
                                    formControlName="via" value="both" id="radioBoth">
                                <label class="form-check-label padding-left-5" for="radioBoth">Both</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 padding-top-0-6"
                        *ngIf="idForm.value.via =='identity' || idForm.value.via=='both'">
                        <input type="checkbox" formControlName="id_required"> Is Required
                    </div>

                    <div class="col-md-9" *ngIf="idForm.value.via =='identity' || idForm.value.via=='both'">

                        <select formControlName="id_type" class="form-control"
                            (change)="skipSelectedIdentity(i,$event)">
                            <option [disabled]="selectedOption.includes(identity.value)"
                                *ngFor="let identity of identities" [value]="identity.value">
                                {{identity.name}}
                            </option>
                        </select>

                        <span *ngIf="idForm.value.via=='both'"> <br> </span>
                    </div>

                    <div class="col-md-3 padding-top-0-6"
                        *ngIf="idForm.value.via=='address' || idForm.value.via=='both'">
                        <input type="checkbox" formControlName="address_required"> Is Required
                    </div>

                    <div class="col-md-9" *ngIf="idForm.value.via=='address' || idForm.value.via=='both'">

                        <select formControlName="address_type" class="form-control"
                            (change)="skipSelectedIdentity(i,$event)">

                            <option [disabled]="selectedOption.includes(address.value)"
                                *ngFor="let address of addresses" [value]="address.value">
                                {{address.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>