export enum EducationVerificationStatusType {
    UnderProcess = 0,
    DiscrepancyFound = 1,
    UnableToValidate = 2,
    Verified = 3,
    Failure = 4,
    NotApplicable = 5,
    DocumentRequired = 6,
    EmploymentTerminated = 7,
    FollowUpEmail1Sent = 8,
    FollowUpEmail2Sent = 9,
    FollowUpEmail3Sent = 10,
    FollowUpEmail4Sent = 11,
    VerificationRequestSent = 12,
    AwaitingClientApproval = 13,
    VerifiedVerbal = 14,
    SentToVendor = 15,
    VerificationStopped = 16,
    DemandDraftSent = 17,
    OnHold = 18,
    OnlinePaymentDone = 19,
    UnderReview = 20,
    DataEntryDone = 21,
    InsufficiencyCleared = 22,
    Institutionclosed = 23,
    LevelTwoInsufficiency = 24,
    ClientTerminated = 25,
    CroppingDone = 26,
    EmployeeTerminatedAtCalling = 27,
}
