<div bsModal #editPermissionsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="editAssignedPermissionForm" (ngSubmit)="updateAssignedPermissions()"
        (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Assigned Permission</h4>
                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <input formControlName="user" class="form-control form-control-lg" type="hidden"
                            ngModel="{{user_id}}" />

                        <div class="form-group">
                            <label for="center-block">Components</label>
                            <select class="form-control" (change)="changeComponent($event)" formControlName="component">
                                <option value="" selected>All Components</option>
                                <option *ngFor="let component of components" [value]="component.subject">
                                    {{component.subject}}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="permission">Permissions</label>
                            <app-multi-select [options]="myOptions" [placeholder]="'Select Permission'"
                                [controlName]="'permission'"
                                [formGName]="editAssignedPermissionForm"></app-multi-select>
                        </div>
                    </fieldset>


                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editAssignedPermissionForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>