import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component( {
    selector: 'app-auto-assign-dashboard',
    templateUrl: './auto-assign-dashboard.component.html',
} )
export class AutoAssignDashboardComponent implements OnInit {

    verification!: string | any;

    constructor ( private route: ActivatedRoute ) {
    }


    ngOnInit (): void {

        this.verification = this.route.snapshot.paramMap.get( 'verification' );

    }


}
