import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: '[app-error-messages]',
  templateUrl: './error-messages.component.html'
})
export class ErrorMessagesComponent {
  @Input() control!: AbstractControl;
  @Input() multipleErrors: boolean = false;

  get errorMessage(): string | null {

    for (let controlName in this.control.errors) {

      if (this.control.errors.hasOwnProperty(controlName) && this.control.invalid && (this.control.dirty || this.control.touched)) {

        return ValidationService.getValidatorErrorMessage(controlName, this.control.errors[controlName]);
      }

    }

    return null;
  }

  get errorMessages(): string[] {

    let messages = [];

    for (let controlName in this.control.errors) {

      if (this.control.errors.hasOwnProperty(controlName) && this.control.invalid && (this.control.dirty || this.control.touched)) {

        messages.push(ValidationService.getValidatorErrorMessage(controlName, this.control.errors[controlName]));
      }
    }

    return messages;
  }
}
