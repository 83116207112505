import { DeviceType } from '../enums/device-type.enum';

export class AddressVerification {

    employee_id!: number;
    employee_name!: string;
    id!: number;
    name!: string;
    status_id!: string;
    status_name!: string;
    completed_at!: string;
    created_at!: string;
    updated_at!: string;
    client_remarks!: string;

    india_post_id!: string;
    full_address!: string;
    type!: string;
    address!: string;
    proof_url!: string;
    color!: string;
    state_id!: number;
    district_id!: number;
    state_name!: string;
    district_name!: string;
    pincode!: number;
    landmark!: string;
    police_station!: string;
    area_type!: string;
    reference_name!: string;
    reference_relation!: string;
    reference_number!: string;
    case_type!: string;
    duration_of_stay!: string;
    language_id!: number;
    language_name!: string;

    attachments!: any[];
    is_checked!: boolean;
    work_assigned!: any[];
    vendor_id!: number;
    vendor_name!: string;
    sent_to_vendor_on!: string;
    insuff_raised_at!: string;
    insuff_cleared_at!: string;
    type_id!: number;
    type_of_address!: number;
    property_type!: string;
    device_type!: DeviceType;
    device_lat!: string;
    device_long!: string;
    latitude!: string;
    longitude!: string;
    device!: Device;
    digital_address_form!: DigitalAddressForm;
    has_digital_settings!: boolean;

    static typeToString ( type_id: number ): string {

        switch ( type_id ) {

            case 0: return 'No Type'; break;

            case 1: return 'Current Address'; break;

            case 2: return 'Permanent Address'; break;

            default: return 'No Type'; break;

        }

    }

}

export class DigitalAddressForm {
    id!: number;
    filled_at!: string;
    is_filled!: boolean;
    status!: string;
    email_follow_up_count!: number;
    sms_follow_up_count!: number;
    emails_count!: number;
    sms_count!: number;
    proof_url!: string;
}

export class Device {
    type!: DeviceType;
    lat!: string;
    long!: string;
    distance!: string;
}
