import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { EmploymentCompanyService } from 'src/app/core/services/employment-company.service';

@Component( {
    selector: 'app-employment-company-typeahead',
    templateUrl: './employment-company-typeahead.component.html',
} )
export class EmploymentCompanyTypeaheadComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() labelName: string = 'Company';
    @Input() control: string = 'employment_company';
    @Input() required: boolean = false;

    constructor ( public employmentCompanyService: EmploymentCompanyService ) { }

    get companyId (): string { return this.control + '_id'; }

    get companyName (): string { return this.control + '_name'; }

    get companyIdValue (): number { return this.formGroup.get( this.companyId )?.value; }

    get companyNameValue (): string { return this.formGroup.get( this.companyName )?.value; }

    get options (): any[] {
        return this.employmentCompanyService.companies;
    }

    ngOnInit (): void {

        this.employmentCompanyService.populateCache();

    }


    onSelect ( $event: TypeaheadMatch ): void {

        this.formGroup.get( this.companyId )?.setValue( $event.item.id );
        this.formGroup.get( this.companyName )?.setValue( $event.item.name );

    }

    onNoResults ( $event: boolean ): void {

        if ( $event ) {

            this.formGroup.get( this.companyId )?.setValue( null );
            // this.formGroup.get( this.companyName )?.setValue( null );

        }

    }

}
