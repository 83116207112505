<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">GlobalDB Logs Home Page</h1>
    </div>
    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>



<app-global-db-log-stats [filterParams]="filterParams"></app-global-db-log-stats>

<app-global-db-log-filters [filterParams]="filterParams"></app-global-db-log-filters>

<app-global-db-log-response-modal #globalDbLogResponseModal></app-global-db-log-response-modal>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 20%">Request</th>
                        <th style="width: 15%">Client Name</th>
                        <th style="width: 10%">Status</th>
                        <th style="width: 10%">Source</th>
                        <th style="width: 15%">Response</th>
                        <th style="width: 15%">Actions</th>
                        <th style="width: 15%">TimeLine</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    Log Id: {{log.id}}
                                </li>
                                <li>
                                    Name: {{log.name}}
                                </li>
                            </ul>
                        </td>
                        <td>
                            {{log.client}}
                        </td>
                        <td>
                            <div *ngIf="(log.success != null) ; else Pending">
                                <div *ngIf="(log.success == 1 ); else Failure">
                                    <div *ngIf="log.has_details == 'Global DB Record Exists'; else notFound">
                                        <a class="btn label-success">Found</a>
                                    </div>
                                </div>
                            </div>



                            <ng-template #notFound>
                                <div *ngIf="log.success == 1 && log.has_details == 'Global DB Record Does Not Exist'">
                                    <a class="btn label-danger">Not Found</a>
                                </div>
                            </ng-template>

                            <ng-template #Pending>
                                <div *ngIf="!log.success">
                                    <a class="btn label-warning">Pending</a>
                                </div>
                            </ng-template>

                            <ng-template #Failure>
                                <div *ngIf="log.success == 0">
                                    <a class="btn label-danger">Failure</a>
                                </div>
                            </ng-template>

                        </td>
                        <td>
                            {{log.driver | uppercase}}
                        </td>
                        <td>
                            <a class="btn btn-primary btn-addon btn-sm" *ngIf="(log.success == 1 )"
                                (click)="globalDbLogResponseModal.show(log.driver, log.response)">View detailed response</a>
                        </td>
                        <td>
                            <a class="btn btn-primary btn-sm" (click)="resend(log.id)">
                                <i class="fa fa-refresh"></i>
                                Resend
                            </a>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Created At: {{log.created_at}}</li>
                                <li>Completed At: {{log.completed_at}}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>
