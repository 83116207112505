import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { OcrService } from 'src/app/core/services/ocr.service';


@Component( {
    selector: 'app-ocr-response-modal',
    templateUrl: './ocr-response-modal.component.html',
} )
export class OcrResponseModalComponent implements OnInit {

    response: any;

    @ViewChild( 'ocrApiResponse', { static: false } )
    public ocrApiResponse!: ModalDirective;

    constructor ( private ocrService: OcrService,
        private commonService: CommonService ) {
    }

    show ( response: any ) {
        this.response = response;
        this.ocrApiResponse.show();
    }

    hide () {
        this.ocrApiResponse.hide();
    }

    keys (): Array<string> {
        return Object.keys( this.response.data );
    }

    ngOnInit () {
    }

}
