import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DocumentCropperComponent } from 'src/app/common/image-cropper/document-cropper.component';
import { IdentityCroppingData } from 'src/app/core/interfaces/identity-cropping-data';
import { IWorkTypeComponent } from 'src/app/core/interfaces/work-type-interface';
import { CommonService } from 'src/app/core/services/common.service';
import { DocumentCropperService } from 'src/app/core/services/document-cropper.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { IdentityService } from 'src/app/core/services/identity.service';
import { Subscription } from 'rxjs';
import { IdentityVerificationStatusType } from '../../core/enums/identity-verification-status-type.enum';

@Component( {
    selector: 'app-identity-work-cropping',
    templateUrl: './identity-work-cropping.component.html',
    styleUrls: [ './identity-work-cropping.component.css' ],
} )
export class IdentityWorkCroppingComponent implements IWorkTypeComponent, OnInit, OnDestroy {

    identityVerification!: IdentityCroppingData;

    filterParams: HttpParams = new HttpParams();

    form!: FormGroup;

    data: any;

    showHide!: boolean;
    verificationId!: number;

    croppedFrontUrl: string | null = null;
    croppedBackUrl: string | null = null;

    formSubmitting!: boolean;

    filtered: EventEmitter<string> = new EventEmitter();

    filtersSubscription!: Subscription;

    @ViewChild( DocumentCropperComponent, { static: false } ) documentCropper!: DocumentCropperComponent;

    imageCropped!: Subscription;

    statuses: any = [
        {
            id: IdentityVerificationStatusType.CroppingDone,
            name: 'Cropping Done'
        }

    ]

    constructor (
        private identityService: IdentityService,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private commonService: CommonService,
        private router: Router,
        private filterService: FilterService,
        private documentCropperService: DocumentCropperService ) { }

    ngOnInit (): void {

        this.imageCropped = this.documentCropperService.imageCroppedSubscriber$.subscribe( ( data ) => {
            if ( data ) {
                if ( data.type === 'front_url' ) {
                    this.croppedFrontUrl = data.image;
                } else {
                    this.croppedBackUrl = data.image;
                }
            }

        } );


        // this.route.queryParams.subscribe( params => {

        // 	this.subscribeToQueryParams( params );

        // 	this.formSubmitting = false;
        // 	this.initForm();

        // 	this.verificationId = this.route.snapshot.params.id;

        // 	this.identityService.getCroppingWork( this.filterParams, this.verificationId ).subscribe( ( response ) => {
        // 		this.identityVerification = response;
        // 		this.form.patchValue( response );
        // 	} );


        // } );

        this.verificationId = this.route.snapshot.params[ 'id' ];

        const params = this.route.snapshot.queryParamMap;

        params.keys.forEach( key => {

            if ( key !== 'params' && ( params.get( key ) !== '' && params.get( key ) !== 'all' ) ) {

                this.filterParams = this.filterParams.set( key, params.get( key ) as string );

            }

        } );

        this.getWork( this.verificationId, this.filterParams );

    }

    getWork ( verificationId: any, filters: any ) {

        this.initForm();

        this.formSubmitting = true;

        this.identityService.getCroppingWork( filters, verificationId ).subscribe( ( response ) => {

            this.formSubmitting = false;

            this.identityVerification = response;

        }, error => {

            this.formSubmitting = false;

        } );

    }

    initForm (): void {

        this.form = this.fb.group( {
            // verification_status: [ 30 ],
        } );

    }

    onDoneClicked (): void {

        this.formSubmitting = true;

        if ( this.croppedFrontUrl ) {

            this.commonService.saveCroppedImage( this.croppedFrontUrl, this.verificationId, 'front_url', 'identity' ).subscribe( data => {

                this.commonService.notifications.success( 'Front Saved!' );

            } );
        }

        if ( this.croppedBackUrl ) {

            this.commonService.saveCroppedImage( this.croppedBackUrl, this.verificationId, 'back_url', 'identity' ).subscribe( data => {

                this.commonService.notifications.success( 'Back Saved!' );

            } );
        }

        this.identityService.postCroppingWork( this.filterParams, this.verificationId, this.form.value ).subscribe( response => {

            this.formSubmitting = false;

            const params = this.filterParams;

            const navigationExtras: NavigationExtras = {
                queryParams: this.route.snapshot.queryParams,
                queryParamsHandling: 'merge',
            };

            this.verificationId = response.id;

            this.getWork( response.id, this.filterParams );

            this.router.navigate( [ 'work/identity/cropping', response.id ], navigationExtras );

        }, ( error ) => {

            this.formSubmitting = false;

            if ( error.status == 404 ) {

                this.commonService.notifications.warning( 'No Case Found' );

                this.router.navigate( [ 'work/identity' ] );

            } else if ( error.error.code == 422 ) {

                this.commonService.notifications.error( error.error );

            } else {

                this.commonService.notifications.error( 'Unknown error' );

                this.commonService.notifications.error( error.error );

                this.router.navigate( [ 'work/identity' ] );
            }

        } );

    }

    onExitClicked (): void {

        this.formSubmitting = true;

        this.identityService.cancelCroppingWork( this.filterParams, this.verificationId ).subscribe( () => {

            this.router.navigate( [ 'work/identity' ] );

        } );


    }


    ngOnDestroy (): void {

        this.imageCropped.unsubscribe();

    }

}
