<div class="modal-header">
    <h4 class="modal-title pull-left">Download</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div class="modal-body">
    <pre> {{ params | json }} </pre>
</div>

<div class="modal-footer">

</div>