import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable( {
    providedIn: 'root',
} )
export class EmploymentCompanyService {

    public companies = [];
    private companiesLoading = false;

    constructor (
        private api: ApiService
    ) {
    }

    populateCache ( force: boolean = false ): void {

        if ( this.companiesLoading ) return;

        if ( force || this.companies.length === 0 ) {

            this.companiesLoading = true;

            this.getAll().subscribe( response => {

                this.companies = response;

                this.companiesLoading = false;

            }, error => {

                this.companiesLoading = false;

            } );

        }

    }

    getAll (): Observable<[]> {
        return this.api.get( 'employment/all-companies' );
    }


}

