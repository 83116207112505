<div bsModal #aadhaarHtmlResponse="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" style="overflow-y: initial;">
        <div class="modal-content" style="  max-height: 100%; overflow-y: auto;">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Response</h4>

            </div>
            <div class="modal-body">

                <div *ngIf="attachments.length>0">
                    <h5> Links </h5>
                    <div>
                        <p *ngFor="let attachment of attachments ">
                            <a href="{{attachment.link}}" target="_blank" class="btn-link">{{attachment.name}}</a>
                        </p>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>