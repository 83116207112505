<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                        <div class="form-group">
                            <input type="file" (change)="onFileChange($event)" [accept]="acceptedFileType"
                                class="form-control" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel">
        <div class="panel-heading">
            <h3 class="panel-title">Important points to note: </h3>
        </div>
        <div class="panel-body">
            Please select type of Document as follows:
            <ul class="unstyled">
                <li>Voter ID</li>
                <li>Pan Card</li>
                <li>Aadhaar Card</li>
                <li>DL</li>
                <li>Passport</li>
            </ul>
        </div>
    </div>

    <div class="panel">

        <div class="panel-heading">
            <h3 class="panel-title">Excel Heading </h3>
        </div>
        <div class="panel-body" style="display:block; overflow-x: auto;">
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Client Id</th>
                        <th>Mobile Number</th>
                        <th>DOB</th>
                        <th>Father Name</th>
                        <th>Email</th>
                        <th>Official Email</th>
                        <th>Joining Date</th>
                        <th>Current Address</th>
                        <th>CA Landmark</th>
                        <th>CA State</th>
                        <th>CA District</th>
                        <th>CA Pincode</th>
                        <th>Permanent Address</th>
                        <th>PA Landmark</th>
                        <th>PA State</th>
                        <th>PA District</th>
                        <th>PA Pincode</th>
                        <th>Address as per Id</th>
                        <th>ID State</th>
                        <th>ID District</th>
                        <th>ID Pincode</th>
                        <th>Type of Doc</th>
                        <th>Doc Number</th>
                        <th>Type of Doc 2</th>
                        <th>Doc Number 2</th>
                        <th>Type of Doc 3</th>
                        <th>Doc Number 3</th>
                        <th>ICR Address</th>
                        <th>ICR Landmark</th>
                        <th>ICR State</th>
                        <th>ICR District</th>
                        <th>ICR Pincode</th>
                        <th>ICR Address 2</th>
                        <th>ICR Landmark 2</th>
                        <th>ICR State 2</th>
                        <th>ICR District 2</th>
                        <th>ICR Pincode 2</th>
                        <th>Reference Name</th>
                        <th>Reference Address</th>
                        <th>Reference State</th>
                        <th>Reference District</th>
                        <th>Reference Pincode</th>
                        <th>Reference Landmark</th>
                        <th>Reference Number</th>
                        <th *ngFor="let item of metaFields">Metadata {{item.name}}</th>
                        <th>Location Name</th>
                        <th>Location Code</th>
                        <th>Recruiter Name</th>
                        <th>Recruiter Email</th>
                        <th>Recruiter Number</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>
<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-10 panel-title">
                    Imported Rows are as follows
                </div>
                <div class="col-md-2 text-right text-center-xs">
                    <button type="button" (click)="importEmployees()" [disabled]="!excelForm.valid"
                        class="btn btn-primary">Import
                        Excel
                    </button>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <form [formGroup]="excelForm" (keydown.enter)="$event.preventDefault()">
                <fieldset class="row" [disabled]="isSubmitting">
                    <div id="content">
                        <table class="table table-responsive" formArrayName="excelFormArray">
                            <thead>
                                <tr>
                                    <th style="min-width:22rem" *ngFor="let title of originalHeaders">
                                        {{title}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of excelFormArray.controls;let i=index" [formGroupName]="i">
                                    <td *ngFor="let header of headerRaw;let k=index">
                                        <input class="form-control" type="text" formControlName="{{header}}">
                                        <div class="invalid-feedback" [hidden]="row.get(header)?.valid">
                                            {{validationMessages[header]}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>