import { SmsLogService } from './../../core/services/sms-log.service';
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { EmailLogService } from 'src/app/core/services/email-log.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { DigitalAddressService } from 'src/app/core/services/digital-address.service';

@Component( {
    selector: 'app-digital-address-filters',
    templateUrl: './digital-address-filters.component.html'
} )
export class DigitalAddressFiltersComponent {
    filterFormGroup!: FormGroup;
    lots: any[] = [];
    emailStatuses: { id: number, name: string }[] = [];
    smsStatuses: { id: number, name: string }[] = [];
    digitalStatuses: { id: string, name: number }[] = [];
    smsFollowUpFilter: { value: number, label: string }[] = [];
    emailFollowUpFilter: { value: number, label: string }[] = [];

    constructor (
        public global: GlobalService,
        private fb: FormBuilder,
        private filterService: FilterService,
        private commonService: CommonService,
        private route: ActivatedRoute,
        private emailLogService: EmailLogService,
        private smsLogService: SmsLogService,
        private digitalAddressService: DigitalAddressService
    ) { }

    get companies () { return this.global.fetchCompanies(); }

    ngOnInit (): void {

        this.emailStatuses = this.emailLogService.getStatuses();
        this.smsStatuses = this.smsLogService.getStatuses();

        this.digitalStatuses = this.digitalAddressService.getStatuses();

        this.populateSmsFollowUpFilters();
        this.populateEmailFollowUpFilters();

        this.initForm();

        this.updateFormFilter();

        this.filterResults();

    }

    populateSmsFollowUpFilters () {

        this.smsFollowUpFilter = [
            {
                value: 1,
                label: "Digital Address Invite Sent"
            }
        ];

        for ( let index = 1; index <= 10; index++ ) {

            this.smsFollowUpFilter.push( {
                value: index + 1,
                label: index + " Follow-up SMS Sent"
            } )
        }
    }

    populateEmailFollowUpFilters () {

        this.emailFollowUpFilter = [
            {
                value: 1,
                label: "Digital Address Invite Sent"
            },
            {
                value: 2,
                label: "1 Follow-up Email Sent"
            },
        ];

        for ( let index = 2; index <= 10; index++ ) {

            this.emailFollowUpFilter.push( {
                value: index + 1,
                label: index + " Follow-up Emails Sent"
            } )
        }
    }

    initForm (): void {

        const currentDate: Date = new Date();

        const previousMonth: Date = new Date( currentDate );

        previousMonth.setDate( currentDate.getDate() - 30 );

        this.filterFormGroup = this.fb.group( {
            email_address: '',
            company_id: [ [] ],
            lot_id: [],
            sent_after: previousMonth.toJSON().slice( 0, 10 ),
            sent_before: '',
            digital_address_invite_status: [ [] ],
            type_of_address: 'all',
            is_active: '',
            email_status: [ [] ],
            sms_status: [ [] ],
            email_count: 'all',
            sms_count: 'all',
            name: '',
            phone_number: '',
            page: 1,
        } );
    }


    onCompanyDropDownOpened ( $event: any ) {

    }

    onCompanyDropDownClosed () {

        this.fetchLots();

    }

    fetchLots (): void {

        const params = new HttpParams();

        const $companyIds: [] = this.filterFormGroup.get( 'company_id' )?.value;

        // remove all current lots
        this.lots.splice( 0, this.lots.length );

        const combinedLots: any[] = [];

        if ( !$companyIds || !$companyIds.toString() ) {
            this.lots = [];
            return;
        }

        const request = params.set( 'company_id', $companyIds.toString() );

        // fetch the lots for each company id selected
        this.commonService.companiesLots( request ).subscribe( lots => {

            lots.forEach( lot => combinedLots.push( lot ) );

            this.lots = combinedLots;

        } );

        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        // this.filterFormGroup.controls.lot_id.setValue( 'all' );
    }

    onCompanyChanged ( $companyIds: [] ) {
    }

    onLotChanged () {

    }

    updateFormFilter (): void {

        this.filterService.updateFormFiltersv2( this.filterFormGroup );

    }

    filterResults (): void {

        this.fetchLots();

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    resetForm (): void {

        this.initForm();

        this.filterResults();

    }

}
