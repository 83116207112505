import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { Errors } from '../../core/models/errors.module';
import { EmployeeService } from '../../core/services/employee.service';

@Component( {
    selector: 'app-employee-create',
    templateUrl: './employee-create.component.html',
} )
export class EmployeeCreateComponent implements OnInit {

    title: string = '';
    errors: Errors = new Errors();
    isSubmitting: boolean = false;
    createForm!: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private employeeService: EmployeeService
    ) { }


    ngOnInit (): void {

        this.initForm();

        this.route.url.subscribe( data => {
            this.title = 'Create Employee';
        } );

        let lotId: number | any;
        // Setting the query parameter in the route as the company_id for creating the lot.
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    lotId = params[ key ];
                }
            } );

        } );

        this.createForm.patchValue( { lot_id: lotId } );
    }

    initForm (): void {

        this.createForm = this.fb.group( {
            name: [ '', [ validateAllowedCharacters( '()/,' ), Validators.required ] ],
            mobile_number: [ '', [ Validators.required ] ],
            father_name: [],
            dob: [],
            client_id: [],
            email: [ '', validateEmail() ],
            official_email: [ '', validateEmail() ],
            lot_id: [ '' ],
            settings: this.fb.group( {
                'email_visibility': [ true ],
                'mobile_visibility': [ true ],
            } ),
        } );
    }


    submitForm (): void {
        this.isSubmitting = true;
        this.errors = new Errors();
        const data: Params = this.createForm.value;
        this.employeeService
            .create( data )
            .subscribe(
                response => this.router.navigateByUrl( '/lots/' + response.lot_id ),
                err => {
                    this.errors = err;
                    this.isSubmitting = false;
                }
            );
    }
}
