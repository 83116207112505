<div bsModal #webhookSubUrlCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">


    <form [formGroup]="createSubUrlForm" (ngSubmit)="createSubUrl()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Webhook Sub-URL</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label class="center-block">Sub-URL</label>
                            <small class="text-danger"
                                [hidden]="createSubUrlForm.get('sub_url')?.valid || createSubUrlForm.get('sub_url')?.pristine">
                                Not a Valid URL
                            </small>
                            <input formControlName="sub_url" class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block">Method</label>
                            <select class="form-control" formControlName="method_type">
                                <option value="get">GET</option>
                                <option value="post">POST</option>
                                <option value="put">PUT</option>
                            </select>
                            <small class="text-danger"
                                [hidden]="createSubUrlForm.get('method_type')?.valid || createSubUrlForm.get('method_type')?.pristine">
                                Select a method
                            </small>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="center-block">Sub-URL Type</label>
                            <small class="text-danger"
                                [hidden]="createSubUrlForm.get('type')?.valid || createSubUrlForm.get('type')?.pristine">
                                Required
                            </small>
                            <input formControlName="type" class="form-control"
                                placeholder="Enter the name of the URL like 'submit','withdraw' etc...">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block">Query Params(comma separated)</label>
                            <input formControlName="query_params" class="form-control">
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-primary" [disabled]="!createSubUrlForm.valid">
                            Create Sub-URL
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>