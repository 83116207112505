<div bsModal #sendInvite="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">
    <form [formGroup]="inviteForm" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left" id="invite-modal-title">Send Form To Candidates</h4>

                    <h5 class="modal-title pull-right">
                        <a type="button" (click)="addNewCandidate()" class="btn m-b-xs btn-sm btn-primary btn-addon">
                            <i class="fa fa-plus"></i> Add Another Candidate
                        </a>
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block">
                                <strong>Select Company: </strong></label>
                        </div>
                        <div class="col-md-6 form-group">
                            <select class="form-control" formControlName="company_id"
                                (change)="onOptionsSelected($event)">
                                <option [value]=""></option>
                                <option *ngFor="let company of companies" [value]="company.id">
                                    {{ company.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block"> <strong>Select Batch: </strong></label>
                        </div>
                        <div class="col-md-6 form-group">
                            <select formControlName="form_id" class="form-control" id="select-batch">
                                <option *ngFor="let form of forms;let i=index" value="{{form.id}}">
                                    {{form.name}}
                                </option>
                            </select>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-md-6 form-group">
                            <label class="center-block"> <strong>Select Form Version: </strong></label>
                        </div>

                        <div class="col-md-6 form-group">

                            <select formControlName="form_version" class="form-control" id="select-form-version">
                                <option value="v1"> Old Form </option>
                                <option value="v2"> New Form</option>
                            </select>

                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block"> <strong>Message: </strong></label>
                        </div>
                        <div class="col-md-6 form-group">
                            <input formControlName="message" class="form-control">
                        </div>
                    </div>

                    <div formArrayName="candidates">
                        <div *ngFor="let itemrow of candidatesArray.controls; let i=index" [formGroupName]="i">

                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="center-block"> <strong>Name of Candidate </strong> <span
                                            class="required-asterik">*</span></label>
                                    <small class="text-left" style="color: red"
                                        [hidden]="itemrow.get('name')?.valid || itemrow.get('name')?.pristine"
                                        id="name-validator">
                                        Only alphabets allowed
                                    </small>
                                </div>
                                <div class="col-md-6 form-group">
                                    <input formControlName="name" class="form-control" id="name">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="center-block"> <strong>E-Mail of Candidate </strong> <span
                                            class="required-asterik">*</span></label>
                                    <small class="text-left" style="color: red"
                                        [hidden]="itemrow.get('email')?.valid || itemrow.get('email')?.pristine"
                                        id="email-validator">
                                        Please enter an valid mail address
                                    </small>
                                </div>
                                <div class="col-md-6 form-group">
                                    <input formControlName="email" class="form-control" id="email">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="center-block"> <strong>Mobile number of
                                            Candidate </strong></label>
                                    <small class="text-left" style="color: red"
                                        [hidden]="itemrow.get('phone_number')?.valid || itemrow.get('phone_number')?.pristine"
                                        id="mobile-number-validator">
                                        Please enter a 10 digit mobile number
                                    </small>
                                </div>
                                <div class="col-md-6 form-group">
                                    <input formControlName="phone_number" class="form-control" id="mobile-number">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 form-group">
                                    <label class="center-block"> <strong>Client Employee ID </strong></label>
                                    <small class="text-left" style="color: red"
                                        [hidden]="itemrow.get('client_employee_id')?.valid || itemrow.get('client_employee_id')?.pristine"
                                        id="client-employee-id-validator">
                                        Please enter valid client employee id
                                    </small>
                                </div>
                                <div class="col-md-6 form-group">
                                    <input formControlName="client_employee_id" class="form-control"
                                        id="client_employee_id">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6 form-group">

                                </div>

                                <div class="col-md-6 form-group">
                                    <a *ngIf="candidatesArray.controls.length > 1" (click)="deleteCandidate(i)"
                                        class="text-danger pull-right">Remove
                                        Candidate
                                    </a>
                                </div>

                            </div>

                            <div class="col-md-12">
                                <hr>
                            </div>

                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-primary" [disabled]="!inviteForm.valid || isSubmitting"
                            id="send-invite-button" (click)="sendMail()">Send
                        </button>
                    </div>

                    <div class="pull-left">
                        <a class="btn btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>

</div>