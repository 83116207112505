<div class="wrapper-md">
    <form [formGroup]="editForm">
        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Edit Client
                    </div>
                    <div class="panel-body">

                        <fieldset [disabled]="isSubmitting">

                            <fieldset class="form-group">
                                <input formControlName="id" class="form-control form-control-lg" type="hidden"
                                    ngModel="{{form.id}}" />
                                <label>Form Name</label>
                                <input formControlName="name" class="form-control form-control-lg"
                                    ngModel="{{form.name}}" />
                            </fieldset>

                            <fieldset class="form-group">
                                <label class="center-block"> Status</label>
                                <select class="form-control" formControlName="status">
                                    <option value="1">Active</option>
                                    <option value="0">Disabled</option>
                                </select>
                            </fieldset>

                            <fieldset class="form-group">
                                <label class="center-block"> Form Type</label>
                                <select class="form-control" formControlName="type" (change)="onFormTypeChanged()">
                                    <option [value]="0">Invite</option>
                                    <option [value]="1">Digital Address</option>
                                </select>
                            </fieldset>

                        </fieldset>

                    </div>

                </div>

                <div class="panel-default">
                    <app-client-forms-v2 [settingFormGroup]="formSetting" [verifications]="verifications"
                        [formType]="formType"></app-client-forms-v2>
                </div>

                <div class="row">

                    <div class="col-md-6">
                        <button class="btn btn-lg btn-primary btn-block" [disabled]="!editForm.valid || isSubmitting"
                            (click)="updateClient()">
                            Update & Refresh
                        </button>
                    </div>

                    <div class="col-md-6">
                        <button class="btn btn-lg btn-primary btn-block" [disabled]="!editForm.valid || isSubmitting"
                            (click)="updateClient(true)">
                            Update & Redirect
                        </button>
                    </div>
                </div>

            </div>

            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Verifications
                    </div>
                    <div class="panel-body">
                        <div class="form-group col-md-6">


                        </div>

                        <div class="col-md-12">

                            <fieldset class="form-group">
                                <label>Email Subject </label>
                                <input formControlName="email_subject" type="email" class="form-control form-control-lg"
                                    ngModel="{{form.email_subject}}" />

                            </fieldset>

                            <fieldset class="form-group">
                                <label>Email Body </label> <span class="pull-right"><a
                                        (click)="renderSampleEmail.render()">Preview sample email</a></span>

                                <ckeditor formControlName="email_body" [(ngModel)]="form.email_body"></ckeditor>
                            </fieldset>
                        </div>
                    </div>
                </div>
                <app-preview-form-invite-email [emailContent]="editForm.get('email_body')?.value"
                    [companyId]="editForm.get('company_id')?.value" #renderSampleEmail>
                </app-preview-form-invite-email>

            </div>
        </div>

    </form>
</div>
