export class JsonUtil {

    static isJsonString( str: string ): boolean {
        try {

            JSON.parse( str );

            return true;
        } catch ( e ) {

            return false;
        }
    }
}
