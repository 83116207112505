<div [formGroup]="formGroup">
    <label id="label" class="center-block">{{label}}
        <input [formControlName]="controlName" [typeahead]="states" [typeaheadMinLength]="0" typeaheadOptionField="name"
            placeholder="Select {{label}}" [typeaheadItemTemplate]="customItemTemplate" class="form-control"
            (typeaheadOnSelect)="onSelect($event)" (typeaheadNoResults)="onNoResults()" autocomplete="off"
            [id]="controlName">
    </label>
    <small class="text-danger" *ngIf="stateId.errors?.['required'] || stateName.errors?.['required']">
        Required
    </small>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
