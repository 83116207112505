import { Component, Output, ViewChild, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UserService } from '../../core/services/user.service';
import { CommonService } from '../../core/services/common.service';

import { FormBuilder, FormGroup, Validator, Validators } from '@angular/forms';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { Errors } from '../../core/models/errors.module';
import { Router } from '@angular/router';
import { validateEqual } from '../../common/validators/equal-validtor.directive';
import { GlobalService } from 'src/app/core/services/global.service';
import { validateEmailByDomain } from 'src/app/common/validators/email-validator-by-domain.directive';

@Component( {
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: [ './user-create.component.css' ],
} )
export class UserCreateComponent {

    isSubmitting = false;
    userCreateForm!: FormGroup;
    errors: Errors = new Errors();
    validDomain: string[] = [
        'instaveritas.com'
    ];

    constructor ( private userService: UserService,
        private commonService: CommonService,
        public global: GlobalService,
        private fb: FormBuilder,
        private router: Router ) {
        this.initForm();
    }

    get userLevels () { return this.global.fetchUserLevels(); }

    @ViewChild( 'userCreateModal', { static: false } )
    public userCreateModal!: ModalDirective;

    @Output()
    onUserCreated = new EventEmitter<boolean>();

    show () {
        // this.userService.populateLevels();

        this.userCreateModal.show();
    }

    hide () {
        this.initForm();
        this.userCreateModal.hide();
    }

    createUser () {
        this.isSubmitting = true;
        this.userService.create( this.userCreateForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( response[ 'message' ] );
                this.isSubmitting = false;
                this.hide();
                this.onUserCreated.emit( true );
            },
            err => {
                this.errors = err;
                if ( err.code === 422 ) {
                    Object.keys( err.errors ).forEach( key => {
                        this.commonService.notifications.error( err.errors[ key ][ 0 ] );
                    } );
                }
                else { this.commonService.notifications.error( err.message ); }
                this.isSubmitting = false;
            }
        )
    }


    initForm () {
        this.userCreateForm = this.fb.group( {
            name: [ '', Validators.required ],
            dob: [ '', Validators.required ],
            email: [ '', [ Validators.required, validateEmail(), validateEmailByDomain( true, this.validDomain ) ] ],
            mobile_number: [ '', [ Validators.required, Validators.minLength( 10 ), Validators.maxLength( 10 ) ] ],
            level_id: [ '', Validators.required ],
            designation: [ '', Validators.required ],
        } );
    }

    OnLevelSelected ( event: any ) {
        this.userCreateForm.controls[ 'level_id' ].setValue( event.item.id );
    }

}
