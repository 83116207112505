import { Component, OnInit } from '@angular/core';
import { WorkService } from '../../core/services/work.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Pagination } from '../../core/models/pagination.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';
import { Lot } from '../../core/models/lot.model';

import { HttpParams } from '@angular/common/http';
import { Verification } from '../../core/models/verification.model';
import { VerificationService } from '../../core/services/verification.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-work-list',
    templateUrl: './work-list.component.html',
    styleUrls: [ './work-list.component.css' ],
    providers: [ WorkService, CommonService ],
} )
export class WorkListComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    lots: Lot[] = [];
    verifications: Verification[] = [];

    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    constructor (
        protected router: Router,
        private route: ActivatedRoute,
        private workService: WorkService,
        private fb: FormBuilder,
        private commonService: CommonService,
        public global: GlobalService,
        private verificationService: VerificationService
    ) {
        this.initForm();
    }

    get companies () {
        return this.global.fetchCompanies();
    }

    // Initialize the filters.
    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            company_id: 'all',
            lot_id: 'all',
            created_on: '',
            is_completed: 'all',
            track_id: 'all',
            work_assignable_type: 'all',
        } );
    }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
        // Populate the list of all verifications.
        this.verificationService.getAllVerifications().then( verifications => this.verifications = verifications );

    }

    filterResults (): void {
        this.workService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
        } );
    }


    // Pagination next page.
    showPage ( page: number ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Change the list of lots as soon as company is selected.
    onCompanySelected (): void {

        this.filterFormGroup.controls[ 'lot_id' ].setValue( 'all' );

        const companyId = this.filterFormGroup.controls[ 'company_id' ].value;

        this.commonService.getLotsByCompanyId( companyId ).subscribe(
            response => {
                this.lots = response;
            }
        );
    }

    filterQueriedResults (): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };


        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        // Navigates to current path along with the query string
        this.router.navigate( [ currentUrlPath ], navigationExtras );


    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();
        this.filterQueriedResults();
    }


}
