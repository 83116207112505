import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterService } from 'src/app/core/services/filter.service';

import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { CommonService } from '../../core/services/common.service';
import { GstService } from '../../core/services/gst.service';

@Component( {
    selector: 'app-gst-filters',
    templateUrl: './gst-filters.component.html',
    styleUrls: [ './gst-filters.component.css' ],
} )
export class GstFiltersComponent extends ExportExcelComponent implements OnInit {

    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    filterFormGroup!: FormGroup;
    data: any[] = [];
    fetchingInProgress: boolean = false;

    clients!: any[];

    constructor (
        protected service: GstService,

        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService,
    ) {
        super();

    }

    ngOnInit (): void {

        this.initForm();

        this.updateFormFilter();

        this.getAllClientApis();

        this.filterResults();
    }


    getAllClientApis (): void {

        let params: HttpParams = new HttpParams();
        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }


    initForm (): void {

        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            gst_number: 'all',
            client_api_id: 'all',
            via: 'all',
            created_before: 'all',
            created_after: 'all',
            gov_gst_status: 'all',
            eway_bill_status: 'all',
        } );
    }

    filterResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    onSearch (): void {
        this.filterResults();
    }

    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }

    exportLogsExcel (): void {

        this.fetchingInProgress = true;
        this.service.getDataForExcel( this.filterParams ).subscribe(
            response => {

                this.data = this.data.concat( response.data );

                if ( response[ 'next_page_url' ] ) {
                    this.filterParams = this.filterParams.set( 'page', response[ 'next_page_url' ].split( '=' )[ 1 ] );
                    this.exportLogsExcel();
                } else {
                    this.commonService.notifications.success( 'All Data Fetched' );
                    this.downloadExcel( this.data, 'Gst Excel' );
                    this.fetchingInProgress = false;
                    this.data = [];
                }
            } );
    }

    resendAll ( source: string ): void {
        this.service.resendAll( this.filterParams, source ).subscribe( response => {
            this.commonService.notifications.success( 'Success', 'GST Request Sent successfully' );
            this.filterResults();
        },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );
    }
}
