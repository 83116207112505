<div class="panel panel-default">

    <div class="panel-heading">
        Sample Email View
    </div>

    <div class="panel-body">

        <div [innerHTML]="email"></div>

    </div>
</div>