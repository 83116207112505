<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add New Metafield</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="form" class="form">
            <div class="form-group">
                <label>Name </label>
                <input type="text" required="true" formControlName="name" class="form-control"
                    (ngModelChange)="onNameChanged()">
                <small>eg. store_name, store_id, restaurant_name</small> <br />
                <small>must not contain any special chars except `_` underscore</small><br />
            </div>
            <div class="form-group">
                <button class="btn btn-success" type="button" [disabled]="form.invalid || creating"
                    (click)="createNewField(name)">Create</button>
            </div>
        </form>
    </div>
</ng-template>

<div class="wrapper-md">
    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Attached Metafields
                    <div class="btn-group pull-right">
                        <button (click)="openModal(template)" class="btn btn-default btn-xs btn-success">
                            Add New Metafield
                        </button>
                    </div>
                </div>
                <div class="panel-body">
                    <ul class="list-group" *ngIf="!loadingAttachedFields">
                        <li class="list-group-item" *ngFor="let field of companyFields">{{field.name}}
                            <button class="btn btn-danger btn-xs" style="float:right"
                                (click)="remove(field)">Remove</button>
                        </li>
                    </ul>
                    <div *ngIf="loadingAttachedFields">
                        Loading...
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Available Metafields
                </div>
                <div class="panel-body">
                    <ul class="list-group" *ngIf="!loadingAvailableFields">
                        <li class="list-group-item" *ngFor="let field of uniqueFields">
                            {{field.name}}
                            <button *ngIf="!presentInCompany(field)" class="btn btn-success btn-xs" style="float:right"
                                (click)="attach(field)">Attach</button>
                        </li>
                    </ul>
                    <div *ngIf="loadingAvailableFields">
                        Loading...
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>