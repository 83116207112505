import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { Params } from '@angular/router';
import { ModalService } from './modal.service';
import { SmsStatusType } from '../models/sms-status-type';
import { SmsLogStatusTimeLineComponent } from 'src/app/common/sms-log-status-time-line/sms-log-status-time-line.component';
import { SmsLogStatusTimeLineModalInitialState } from '../models/sms-log-status-time-line-modal-initial-state';

@Injectable( {
    providedIn: 'root'
} )
export class SmsLogService {

    constructor ( private api: ApiService, private modalService: ModalService ) {
    }

    // Fetch Filtered logs
    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'sms-logs', params );
    }


    getStatuses (): { id: any, name: string }[] {

        return Object.keys( SmsStatusType ).map( key => {

            const label = this.getStatusLabel( SmsStatusType[ key as keyof typeof SmsStatusType ] );;

            return {
                id: label,
                name: label,
            }

        } );

    }

    getStatusLabel ( status: SmsStatusType ): string {

        switch ( status ) {

            case SmsStatusType.DELIVERED:
                return 'DELIVERED';

            case SmsStatusType.FAILURE:
                return 'FAILURE';

            case SmsStatusType.ACCEPTED:
                return 'ACCEPTED';

            case SmsStatusType.REJECTED:
                return 'REJECTED';

            case SmsStatusType.SENT:
                return 'SENT';

            case SmsStatusType.SUCCESS:
                return 'SUCCESS';
        }
    }

    getStatusColor ( status: SmsStatusType ): string {

        switch ( status ) {

            case SmsStatusType.DELIVERED:
                return 'label-success';

            case SmsStatusType.FAILURE:
                return 'label-danger';

            case SmsStatusType.ACCEPTED:
                return 'label-secondary';

            case SmsStatusType.REJECTED:
                return 'label-danger';

            case SmsStatusType.SENT:
                return 'label-secondary';

            case SmsStatusType.SUCCESS:
                return 'label-success';
        }
    }

    showSmsStatusTimeLineModal ( initialState: SmsLogStatusTimeLineModalInitialState ) {

        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg'
        };

        return this.modalService.showModal( SmsLogStatusTimeLineComponent, config );
    }
}
