<div bsModal #emailLogModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">


    <ng-template #customItemTemplate let-model="item" let-index="index">
        {{model.name}}
    </ng-template>

    <div class="modal-dialog modal-full">
        <div class="modal-content">
            <div class="modal-header">
                <h4>
                    Email Log
                </h4>

            </div>

            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 30%">Email Details</th>
                            <th style="width: 10%">Status</th>
                            <th style="width: 10%">Sent To</th>
                            <th style="width: 10%">Sent By</th>
                            <th style="width: 40%">Timeline</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let log of emailLogs">
                            <td>
                                <ul class="list-unstyled">
                                    <li>{{ log.id }}</li>
                                    <li>Subject {{ log.subject }}</li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li *ngFor="let status of log.statuses">{{ status }}</li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li *ngFor="let receiver of log.receivers">{{ receiver }}</li>
                                </ul>
                            </td>
                            <td>
                                <ul class="list-unstyled">
                                    <li>{{ log.sender.name }}</li>
                                    <li>{{ log.sender.email }}</li>
                                </ul>
                            </td>

                            <td>
                                <ul class="list-unstyled">
                                    <li>Sent At: {{ log.sent_at }}</li>
                                    <li>Updated At: {{ log.updated_at }}</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="modal-footer">
                <!-- <div class="pull-right">
                    <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                        Update
                    </button>
                </div> -->

                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>

            </div>
        </div>
    </div>
</div>