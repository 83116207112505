<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                        <div class="form-group">
                            <input type="file" (change)="onFileChange($event)" [accept]="acceptedFileType"
                                class="form-control" />
                        </div>
                    </form>
                </div>


            </div>

            <button type="button" class="btn btn-primary" (click)="staticModal.show()">View Sample Excel</button>

            <div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
                role="dialog" aria-labelledby="dialog-static-name">
                <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 id="dialog-static-name" class="modal-title pull-left">View Sample Excel</h4>
                            <button type="button" class="close pull-right" aria-label="Close"
                                (click)="staticModal.hide()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">

                                <div class="col-md-12">
                                    <button class="btn"><a target="_blank"
                                            href="https://docs.google.com/spreadsheets/d/1NCC0swAjyklIB2AjebTX6qJ-ZD8lypjvZHDH4U1p3rA/edit?usp=sharing">Current
                                            Sample Bulk Work Assign Excel</a></button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>


<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-8 panel-title">
                    Imported Rows are as follows
                </div>
                <div class="col-md-2 text-left text-center-xs">
                    <button type="button" [disabled]="!excelForm.valid" class="btn btn-primary"
                        (click)="onImportExcelButtonClicked()">
                        Import
                        Excel
                    </button>
                </div>

            </div>
        </div>


        <div class="panel-body">
            <form [formGroup]="excelForm" (keydown.enter)="$event.preventDefault()">

                <div class="row">
                    <div class="form-group col-md-3">
                        <label class="center-block">Work Type</label>
                        <select class="form-control" formControlName="workTypeId">
                            <option *ngFor="let workType of workTypes" [value]="workType.id">
                                {{workType.work_type}} : {{workType.title}}
                            </option>
                        </select>
                    </div>
                </div>

                <fieldset [disabled]="isSubmitting">
                    <div style="max-width: 100%;overflow-x: scroll">
                        <table class="table table-bordered" formArrayName="excelFormArray">
                            <tr>
                                <th *ngFor="let title of originalHeaders">
                                    {{title}}
                                </th>
                            </tr>
                            <tr *ngFor="let row of excelFormArray.controls;let i=index" [formGroupName]="i">
                                <td *ngFor="let header of headerRaw;let k=index">
                                    <div>
                                        <input formControlName="{{header}}"><br>
                                        <small class="text-danger" [hidden]="row.get(header)?.valid">
                                            {{validationMessages[header]}}
                                        </small>
                                    </div>
                                </td>
                            </tr>

                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>