<span *ngIf="!multipleErrors" class="text-sm text-red-500">{{errorMessage}}</span>

<span *ngIf="multipleErrors && errorMessages.length > 0">

    <div style="color: red;">
        <h3 class="text-sm font-medium text-red-800">It should satisfy below rules:</h3>

        <ul role="list">
            <li class="pl-2" *ngFor="let error of errorMessages">
                {{error}}
            </li>
        </ul>
    </div>

</span>