import { Directive, forwardRef, Attribute, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';

export function validateEqual ( otherControlName: string ): ValidatorFn {
    return ( control: AbstractControl ): { [ key: string ]: any } => {
        const otherControl: AbstractControl = control.root.get( otherControlName )!;

        if ( otherControl ) {
            const subscription: Subscription = otherControl
                .valueChanges
                .subscribe( () => {
                    control.updateValueAndValidity();
                    subscription.unsubscribe();
                } );
        }

        return ( otherControl && control.value !== otherControl.value ) ? { match: true } : {};
    };
}

@Directive( {
    selector: '[validateEqual]',
    providers: [ { provide: NG_VALIDATORS, useExisting: EqualValidatorDirective, multi: true } ],
} )
export class EqualValidatorDirective implements Validator {
    @Input()
    otherControlName!: string;

    validate ( control: AbstractControl ): { [ key: string ]: any } {

        return validateEqual( this.otherControlName )( control ) || {};
    }
}
