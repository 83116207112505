import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { ClientApiService } from '../core/services/client-api.service';
import { ClientApiCreateComponent } from './client-api-create/client-api-create.component';
import { ClientApiListComponent } from './client-api-list/client-api-list.component';
import { VehicalRcSettingsComponent } from './vehical/vehical-rc-settings/vehical-rc-settings.component';
import { ClientApiCredentialsComponent } from './client-api-credentials/client-api-credentials.component';
import { ClientApiEditComponent } from './client-api-edit/client-api-edit.component';
import { ClientApiEditIpComponent } from './client-api-edit-ip/client-api-edit-ip.component';
import { VehicleService } from '../core/services/vehicle.service';


@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        ClientApiCreateComponent,
        ClientApiListComponent,
        VehicalRcSettingsComponent,
        ClientApiCredentialsComponent,
        ClientApiEditComponent,
        ClientApiEditIpComponent,
    ],
    providers: [
        ClientApiService,
        VehicleService,
    ],
} )
export class ClientApiModule {
}
