import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Employee } from 'src/app/core/models/employee.model';
import { CommonService } from 'src/app/core/services/common.service';
import { ShortcutService } from 'src/app/core/services/shortcut.service';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { AddressVerification } from '../../core/models/address-verification.model';
import { AddressService } from '../../core/services/address.service';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-address-edit',
    templateUrl: './address-edit.component.html',
    styleUrls: [ './address-edit.component.css' ],
} )
export class AddressEditComponent extends VerificationEditComponent implements OnInit {

    verifications: AddressVerification[] = [];
    vendors!: any[];
    statusChanged: boolean = false;
    override attachments: any[] = [];
    languages!: any[];
    pincodeText: any[] = [];
    validStatusesForWorkCompleted: number[] = [ 7, 8, 21, 16 ];


    constructor (
        protected override verificationService: AddressService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override common: CommonService,
        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        protected override confirmationModalService: ConfirmationModalService
    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );
        // Initialise the identity verification form
        // with the required form controls
    }

    override ngOnInit (): void {

        this.initForm();

        this.verification = 'address';

        this.verificationByEmployeeId();

        // Subscribe to changes in the form.
        this.subscribeToQueryChanges();

        this.getVendors();

    }


    getVendors (): void {
        this.common.getVendors( 'address' ).subscribe(
            response => {
                this.vendors = response;
            }
        );
    }


    verificationByEmployeeId (): void {

        this.route.params.subscribe( ( params: any ) => {

            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];

            this.verificationService.findByEmployeeId( this.employeeId ).subscribe(
                {
                    next: ( employee: any ) => {
                        this.employee = employee;
                        this.populateForm( employee );
                        this.getAttachments( employee );
                        this.isSubmitting = false;
                        this.applyDefaults();
                    },
                }
            );

        } );
    }


    initForm (): void {
        this.verificationFormGroup = this.fb.group( {
            navigation: 'refresh',
            remarks: [ null ],
            id: [ null ],
            formArray: this.fb.array( [] ),
        } );
    }


    populateForm ( employee: Employee ): void {

        this.verificationFormGroup.patchValue( employee );

        const verifications: AddressVerification[] = employee.address_verifications as AddressVerification[];

        const verificationFormGroup: FormGroup[] = verifications.map( verification => {

            const verificationForm = this.fb.group( verification ) as FormGroup;

            if ( !verification.digital_address_form ) return verificationForm;

            verificationForm.setControl( 'digital_address_form', this.fb.group( verification.digital_address_form ) )

            if ( !verification.device ) return verificationForm;

            verificationForm.setControl( 'device', this.fb.group( {
                device_type: [ verification.device.type ],
                device_lat: [ verification.device.lat ],
                device_long: [ verification.device.long ],
                distance: [ verification.device.distance ],
            } ) )

            return verificationForm;
        } );


        const verificationGroupArray: AbstractControl = this.fb.array( verificationFormGroup );

        this.verificationFormGroup.setControl( 'formArray', verificationGroupArray );

    }

    applyDefaults (): void {

        this.formArray.controls.forEach( ( form: AbstractControl<any, any> ) => {

            this.disableFields( form );

            this.applyDefaultValidations( form );

            // Set the default area_type to urban on page load.
            if ( form.get( 'area_type' )?.value == null ) {
                form.get( 'area_type' )?.setValue( 'urban' );
            }


            // Subscribe to any Change in Verification Status
            form.get( 'status_id' )?.valueChanges.subscribe(
                ( statusId: any ) => {
                    this.statusChanged = true;
                    this.applyValidationsForStatusChange( statusId, form );
                }
            );

        } );
    }


    createAddress ( type: string ): void {
        this.verificationService.create( this.employee.id, type ).subscribe(
            response => {
                this.populateForm( response );
            }
        );

        this.common.notifications.success( type + ' Verification added' );
    }


    /**
     * Apply Validations when Status is changed
     *
     * @param statusId
     * @param {AbstractControl} form
     */
    protected applyValidationsForStatusChange ( statusId: any, form: AbstractControl ): void {

        // List of statuses for which validations is required on completion Date.
        const validStatusesForCompletionDate: number[] = [ 1, 2, 3, 4, 5, 10, 17, 22, 23, 27, 28, 30, 32, 33 ];

        // List of Statuses for which validations is required on sent to vendor on date.
        const validStatusesForSentToVendorOnDateAndVendorName: number[] = [ 9, 11, 19 ];

        // List of Statuses for which validations is required on sent to vendor on date.
        const validStatusesForLanguageId: number[] = [ 19 ];

        // List of statuses for which validations is required on pincode.
        const validStatusesForAddressRelatedFields: number[] = [
            1, 2, 3, 4, 5, 6, 9, 10, 11, 17, 19, 20, 24, 25, 28, 31, 32, 34,
        ];

        // List of statuses for which validations is required on Address
        const validStatusesForIndiaPost: number[] = [ 9, 11 ];

        const validStatusesForClientRemarks: number[] = [ 22, 23, 27, 28, 30, 32, 33 ];

        const validStatusesFoQaDone: number[] = [ 37, 38 ];

        // Check if changed status is in our list.
        if ( validStatusesForCompletionDate.indexOf( statusId ) != -1 ) {

            form.get( 'completed_at' )?.setValidators( [ Validators.required ] );

        } else {

            form.get( 'completed_at' )?.setValidators( [] );
        }

        if ( validStatusesForAddressRelatedFields.indexOf( statusId ) != -1 ) {

            form.get( 'address' )?.setValidators( [ Validators.required ] );
            form.get( 'state_id' )?.setValidators( [ Validators.required ] );
            form.get( 'district_id' )?.setValidators( [ Validators.required ] );
            form.get( 'pincode' )?.setValidators( [ Validators.required, validateNumericCharacters( '' ), validateLength( '6' ) ] );
        } else {
            form.get( 'address' )?.setValidators( [] );
            form.get( 'state_id' )?.setValidators( [] );
            form.get( 'district_id' )?.setValidators( [] );
            form.get( 'pincode' )?.setValidators( [] );
        }

        // Check if changed status is in our list.
        if ( validStatusesForSentToVendorOnDateAndVendorName.indexOf( statusId ) != -1 ) {

            form.get( 'sent_to_vendor_on' )?.setValidators( [ Validators.required ] );
            form.get( 'vendor_id' )?.setValidators( [ Validators.required ] );

        } else {
            form.get( 'sent_to_vendor_on' )?.setValidators( [] );
            form.get( 'vendor_id' )?.setValidators( [] );
        }

        if ( validStatusesForLanguageId.indexOf( statusId ) != -1 ) {

            form.get( 'language_id' )?.setValidators( [ Validators.required ] );

        } else {
            form.get( 'language_id' )?.setValidators( [] );
        }

        if ( validStatusesForIndiaPost.indexOf( statusId ) != -1 ) {
            form.get( 'india_post_id' )?.setValidators( [ Validators.required ] );
        } else {
            form.get( 'india_post_id' )?.setValidators( [] );
        }


        if ( validStatusesForClientRemarks.indexOf( statusId ) != -1 ) {
            form.get( 'client_remarks' )?.setValidators( [ Validators.required ] );
        } else {
            form.get( 'client_remarks' )?.setValidators( [] );
        }

        if ( this.validStatusesForWorkCompleted.indexOf( statusId ) != -1 ) {

            form.get( 'work_completed' )?.setValidators( [ Validators.required ] );
        } else {
            form.get( 'work_completed' )?.setValidators( [] );
        }

        form.get( 'completed_at' )?.updateValueAndValidity();
        form.get( 'pincode' )?.updateValueAndValidity();
        form.get( 'sent_to_vendor_on' )?.updateValueAndValidity();
        form.get( 'district_id' )?.updateValueAndValidity();
        form.get( 'state_id' )?.updateValueAndValidity();
        form.get( 'address' )?.updateValueAndValidity();
        form.get( 'language_id' )?.updateValueAndValidity();
        form.get( 'vendor_id' )?.updateValueAndValidity();
        form.get( 'india_post_id' )?.updateValueAndValidity();
        form.get( 'client_remarks' )?.updateValueAndValidity();
        form.get( 'work_completed' )?.updateValueAndValidity();

    }

    /**
     * Set Default Validations to be applied on Page Load
     *
     * @param {AbstractControl} form
     */
    private applyDefaultValidations ( form: AbstractControl ): void {

        form.get( 'area_type' )?.setValidators( [ Validators.required ] );
        form.get( 'area_type' )?.updateValueAndValidity();
        form.get( 'case_type' )?.setValidators( [ Validators.required ] );
        form.get( 'case_type' )?.updateValueAndValidity();
        form.get( 'india_post_id' )?.setValidators( [ Validators.pattern( '[A-Za-z]{2}[0-9]{9}[A-Za-z]{2}' ) ] );
        form.get( 'india_post_id' )?.updateValueAndValidity();

        this.applyValidationsForStatusChange( form.get( 'status_id' )?.value, form );
    }

    updateVerification ( verification: any ) {

        if ( this.verification === verification ) {

            this.isSubmitting = true;
            this.cdr.detectChanges();
            this.verificationService.update( this.employeeId, this.verificationFormGroup.value, this.filterParams.toString() )?.subscribe(
                response => {
                    this.handleUpdateResponse( response );
                    this.common.notifications.success( 'Verification Updated', 'Verification Updated' );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                error => {
                    this.common.displayValidationErrors( error.errors );
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                },
                () => {
                    this.isSubmitting = false;
                    this.cdr.detectChanges();
                }
            );
        }
    }

}
