<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Invites List</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="refreshPage()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-form-invite-modal #sendInvite (onInviteCreate)="inviteCreateStatus($event)"></app-form-invite-modal>

<app-invite-filters></app-invite-filters>

<div class="list-page-buttons">
    <div class="btn-group" role="group">
        <button class="btn btn-primary btn-space" (click)="sendInvite.show()" id="invite-candidate-button">
            <i class="fa fa-send"></i>
            Invite Candidates
        </button>
        <button routerLink="/form-invites/upload" class="btn btn-primary btn-space">
            <i class="fa fa-upload"></i>
            Upload Invite Candidates
        </button>
        <button appCan="editor" class="btn btn-primary btn-space" (click)="exportExcel()">
            <i class="fa fa-edit"></i>
            Download Excel
        </button>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination && pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 20%;">Candidate</th>
                        <th style="width: 20%;">Details</th>
                        <th style="width: 13%;">Invite Creator</th>
                        <th style="width: 13%;">Last Email Sender</th>
                        <th style="width: 12%;"> Last Email Status</th>
                        <th style="width: 12%;">Invite Status</th>
                        <th style="width: 20%;">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let log of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    };
              let i = index
            ">
                        <td>
                            <div *ngIf="log.employee_id; else elseBlock">
                                <a routerLink="/employees/{{log.employee_id}}/edit">
                                    <b>Name:</b> {{ log.name }} <br />
                                </a>
                            </div>
                            <ng-template #elseBlock>
                                <b>Name:</b> {{ log.name }} <br />
                            </ng-template>
                            <b>Email:</b> {{ log.email_id }} <br />
                            <b>Number: </b> {{ log.number }} <br />
                            <div *ngIf="log.employee_id">
                                <a routerLink="/employees/{{log.employee_id}}/edit">
                                    <b>Employee Id</b> {{ log.employee_id}}
                                </a>
                            </div>
                            <b>Client Employee Id: </b> {{log.client_employee_id}}<br>
                            <strong>Sent on:</strong> {{ log.last_sent_at }}<br />
                            <li *ngIf="log.filled_at">
                                <strong>TAT:</strong> {{ log.tat }} days
                            </li>
                        </td>
                        <td>

                            <strong>Company: </strong>
                            <a class="btn-link text-main text-semibold lots"
                                routerLink="/companies/{{log.lot?.company_id}}">
                                {{ log.company }}
                            </a><br />
                            <strong>Lot: </strong>
                            <a class="btn-link text-main text-semibold lots" routerLink="/lots/{{log.lot?.lot_id}}">
                                {{ log.lot?.lot_name }}
                            </a><br />

                            <strong>Form: </strong>
                            <a class="btn-link text-main text-semibold lots" routerLink="/forms/{{log.form_id}}/edit">
                                {{log.form_name}}
                            </a><br />

                            <strong>FollowUp Count:</strong>
                            {{ log.follow_up_count }} Followup email sent <br />

                            <strong>Is Filled: </strong>
                            <span>{{log.is_filled ? 'YES' : 'NO'}}</span><br />

                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong>Company : </strong> {{ log.creator?.company }}
                                </li>
                                <li>
                                    <strong>Name : </strong>{{ log.creator?.name }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong> Company : </strong> {{ log.sender?.company }}
                                </li>
                                <li>
                                    <strong> Name : </strong> {{ log.sender?.name }}

                                </li>
                            </ul>
                        </td>
                        <td>
                            <ng-container *ngIf="log.last_email">
                                <div class="row">
                                    <app-invite-email-list-item
                                        [lastEmail]="log.last_email"></app-invite-email-list-item>
                                </div>
                            </ng-container>
                        </td>
                        <td style="text-align: center;">
                            <button (click)="followUpDetailsModal.show(log.id, log.name)"
                                class="btn btn-block {{getStatusColor(log.status)}} btn-status p-1">
                                {{log.status | uppercase}}
                            </button>
                            <a *ngIf="log.follow_up_count" (click)="followUpDetailsModal.show(log.id, log.name)">
                                See Followups
                            </a>
                        </td>

                        <td>
                            <button (click)="onSendFollowUpMailButtonClicked(log.id)" class="btn btn-primary btn-addon"
                                *ngIf="showFollowUpButton(log)">
                                Send Follow Up Mail
                            </button><br /><br />

                            <button (click)="toggleInviteStatus(log.id)" *ngIf="showToggleButton(log.status)"
                                class="btn btn-{{log.is_active? 'danger': 'primary'}} btn-addon">
                                {{log.is_active? 'Mark Inactive': 'Mark Active'}}
                            </button><br /><br />
                        </td>
                        <app-invite-follow-up-modal #followUpDetailsModal></app-invite-follow-up-modal>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>
