import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable( {
    providedIn: 'root'
} )
export class ModalService {

    constructor ( private bsModalService: BsModalService, ) { }

    showModal ( component: any, config: any, ) {
        return this.bsModalService.show( component, config );
    }

}
