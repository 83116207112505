import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';
import { VehicleService } from 'src/app/core/services/vehicle.service';


@Component( {
    selector: 'app-vehical-rc-settings',
    templateUrl: './vehical-rc-settings.component.html',
    styles: [ `.list-group-item {
    position: relative;
    display: block;
    padding: 0px;
    background-color: #fff;
    border: none;
    width: 196px;
  }`],
} )
export class VehicalRcSettingsComponent implements OnInit {

    fieldAddForm!: FormGroup;

    clientId!: number;

    deprecatedFields: string[] = [
        'fitness_certificate_expiry_date',
        'fitness_upto',
    ];

    fields: string[] = [
        'blacklisted',
        'blacklisted_reason',

        'registration_number',
        'chassis_number',
        'engine_number',

        'expiry_date',

        'fitness_certificate_expiry_date',

        'fuel_type',

        'registration_date',
        'registering_authority',
        'registration_state',

        'vehicle_company',
        'vehicle_model',
        'vehicle_type',
        'vehicle_age',

        'owners_name',
        'owner_serial_number',

        'fitness_upto',
        'financing_authority',

        'insurance_upto',
        'insurance_policy_number',
        'insurance_company',

        'fuel_norms',

        'mv_tax_paid_upto',
        'mv_tax_structure',

        'pucc_upto',
        'pucc_number',

        'permit_type',
        'permit_number',
        'permit_valid_upto',
        'permit_valid_from',
        'permit_valid_issue_date',

        'national_permit_number',
        'national_permit_upto',
        'national_permit_issued_by',

        'vehicle_manufacturing_month_year',
        'vehicle_colour',
        'body_type',
        'vehicle_category',
        'owner_father_name',
        'mobile_number',
        'present_address',
        'permanent_address',
        'vehicle_cubic_capacity',
        'gross_vehicle_weight',
        'unladen_weight',
        'rc_standard_cap',
        'vehicle_cylinders_no',
        'vehicle_seat_capacity',
        'vehicle_sleeper_capacity',
        'vehicle_standing_capacity',
        'wheelbase',
        'challanDetails',
        'non_use_status',
        'non_use_from',
        'non_use_to',
        'is_commercial',
        'noc_details',
    ];

    @ViewChild( 'vehicalRcSettings', { static: false } )
    public vehicalRcSettings!: ModalDirective;

    constructor (
        private fb: FormBuilder,
        private vehicleService: VehicleService,
        private commonService: CommonService
    ) { }

    get filterFields (): AbstractControl[] | null {
        const fieldsControl = this.fieldAddForm.get( 'fields' );
        return fieldsControl instanceof FormArray ? fieldsControl.controls : null;
    }

    ngOnInit () {
        this.initForm();
        this.addCheckboxes();
        this.fields.sort();
    }

    initForm () {
        this.fieldAddForm = this.fb.group( {
            fields: new FormArray( [] ),
        } );
    }

    show ( id: number ) {
        this.initForm();
        this.getData( id );
        this.vehicalRcSettings.show();
        this.clientId = id;
    }

    hide () {
        this.vehicalRcSettings.hide();
        this.initForm();
    }

    addCheckboxes ( newfields: string[] = [] ) {
        this.fields.forEach( ( value, index ) => {
            const control = new FormControl( newfields !== null ? newfields.includes( value ) : false );
            ( this.fieldAddForm.controls[ 'fields' ] as FormArray ).push( control );
        } );
    }

    getData ( clientId: number ) {
        this.vehicleService.getFields( clientId ).subscribe( ( response: { fields_subscribed: string[] | undefined; } ) => {
            this.addCheckboxes( response.fields_subscribed );
        } );
    }

    updatedFields () {
        const selectedFields = this.fieldAddForm.value.fields
            .map( ( inputValue: any, index: any ) => ( inputValue ? this.fields[ index ] : null ) )
            .filter( ( inputValue: null ) => inputValue != null );

        const params = {
            fields_subscribed: selectedFields,
            clientId: this.clientId,
        };

        this.vehicleService.setFields( params, this.clientId ).subscribe(
            ( response: { response: any; } ) => {
                if ( response.response ) {
                    this.hide();
                    this.initForm();
                    this.commonService.notifications.success( response.response );
                }
            },
            () => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }
}
