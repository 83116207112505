import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../core/services/common.service';
import { EducationService } from '../../../core/services/education.service';
import { Institute } from './../../../core/models/institute.model';
import { GlobalService } from 'src/app/core/services/global.service';
import { State } from 'src/app/core/models/state.model';
import { District } from 'src/app/core/models/district.model';

@Component( {
    selector: 'app-education-institutes-create-sub-institute',
    templateUrl: './education-institutes-create-sub-institute.component.html',
    styleUrls: [ './education-institutes-create-sub-institute.component.css' ],
} )
export class EducationInstitutesCreateSubInstituteComponent implements OnInit {

    instituteId: number = 0;
    educationInstituteFormGroup!: FormGroup;


    @ViewChild( 'subInstituteCreateModal', { static: false } ) subInstituteCreateModal!: ModalDirective;

    @Output()
    onSubInstituteCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private educationService: EducationService,
        private commonService: CommonService,
        public global: GlobalService,
        private fb: FormBuilder,
    ) { }

    get states (): State[] { return this.global.fetchStates(); }

    get districts (): District[] { return this.global.fetchDistricts(); }

    ngOnInit (): void {

        this.initForm();
    }

    show ( institute: Institute ): void {

        this.instituteId = institute.id;
        this.subInstituteCreateModal.show();
    }

    hide (): void {
        this.initForm();
        this.subInstituteCreateModal.hide();
    }

    createSubInstitute (): void {

        // this.data = this.getData();
        this.educationInstituteFormGroup.get( 'institute_id' )?.setValue( this.instituteId );


        this.educationService.createSubInstitute( this.educationInstituteFormGroup.value ).subscribe(
            response => {

                this.commonService.notifications.success( 'Success', 'Sub Institute Created' );

                this.initForm();
                this.hide();
                this.onSubInstituteCreated.emit( true );

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error', 'Unable to create Sub Institute' );
                this.hide();
            }
        );
    }

    initForm (): void {

        this.educationInstituteFormGroup = this.fb.group( {
            name: [ '' ],
            address: [ '' ],
            state_id: [ 0 ],
            district_id: [ 0 ],
            pincode: [ '' ],
            instruction: [ '' ],
            remarks: [ '' ],
            website: [ '' ],
            type: [ '' ],
            email: [ '' ],
            recipient_name: [ '' ],
            number: [ '' ],
            beneficiary_name: [ '' ],
            institute_id: [ null ],
            state_name: [ '' ],
            district_name: [ '' ],
        } );
    }

    // Set the value of the form control district_id
    OnTypeheadSelect ( event: any, name: string ): void {

        const controlId: string = name + '_id';


        if ( name === 'state' ) {

            this.educationInstituteFormGroup.get( 'district_name' )?.setValue( '' );

        }

        this.educationInstituteFormGroup.get( controlId )?.setValue( event.item.id );

    }

}
