export class Contact {
    id!: number;
    name!: string;
    primary_email!: string;
    secondary_email!: string;
    object_id!: number;
    designation!: string;
    phone_number!: string;
    remarks!: string;
    location!: string;
    division!: string;
    reference_name!: string;
    active!: number;
    helpful!: number;
    centralised!: number;
    isActive!: string;
    isHelpful!: string;
    is_active!: boolean;
    is_helpful!: boolean;
    is_centralised!: boolean;
    created_at!: string;

}
