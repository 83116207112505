import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';
import { isNull } from 'src/app/core/unit';

export function validateDate ( format: string, blank = false ) {

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        let validate = {};
        if ( blank ) {
            if ( control.value == 'blank' ) {
                return {};
            }
        }

        // Apply validation for each format seperated by ','
        // Formats can be like 'dd-mm-yyyy,yyyy' or 'dd-mm-yyyy'
        format.split( ',' ).forEach( formatValue => {

            if ( !isNull( validate ) && control.value && formatValue == 'dd-mm-yyyy' && control.value.toString().length > 4 ) {

                // Allow dates from 01-01-1900 to 31/12/9999 (including Leap years)
                const regex = new RegExp( '(^(((0[1-9]|1[0-9]|2[0-8])[\-](0[1-9]|1[012]))|((29|30|31)[\-](0[13578]|1[02]))|((29|30)[\-](0[4,6,9]|11)))[\-](19|[2-9][0-9])\\d\\d$)|(^29[\-]02[\-](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)' );

                if ( control.value.length == 0 ) {

                    validate = {};
                }
                else if ( control.value.length == 10 && regex.test( control.value ) ) {

                    validate = {};
                }
                else {
                    validate = { 'validateDate': { value: control.value } };
                }
            }

            if ( !isNull( validate ) && control.value && formatValue == 'yyyy' && control.value.toString().length < 4 ) {

                const regex = new RegExp( '(19\\d{2})|(20\\d{2})' );

                if ( control.value.length == 0 ) {

                    validate = {};
                }
                else if ( control.value.length == 4 && regex.test( control.value ) ) {

                    validate = {};
                } else {
                    validate = { 'validateDate': { value: control.value } };
                }

            }
        } );

        return validate;
    };
}


@Directive( {
    selector: '[validateDate]',
    providers: [ { provide: NG_VALIDATORS, useExisting: DateValidatorDirective, multi: true } ],
} )
export class DateValidatorDirective implements Validator {

    @Input()
    format!: string;

    validate ( control: AbstractControl ): { [ key: string ]: any } {
        return this.format ? validateDate( this.format )( control )
            : {};
    }
}
