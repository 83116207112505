<app-work-type-list-create #workTypeCreate (onWorkTypeCreate)="getAllWorkTypeList()"></app-work-type-list-create>
<app-work-type-list-edit #workTypeEdit (onWorkTypeEdit)="getAllWorkTypeList()"></app-work-type-list-edit>
<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Work Type List</h1>
        </div>
        <div class="col-md-2" colSpan="3">
            <button type="button" class="btn btn-primary" (click)="workTypeCreate.show();">
                <i class="fa fa-retweet" aria-hidden="true"></i> Add Work Type
            </button>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Work Type</th>
                        <th>Title</th>
                        <th>Completion Statuses</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let workType of workTypes">
                        <td>{{workType.work_type}}</td>
                        <td>{{workType.title}}</td>
                        <td>
                            <ul *ngFor="let statuses of workType.completion_statuses">
                                <li>{{ statuses.status.title }}</li>
                            </ul>
                        </td>
                        <td>{{workType.created_at}}</td>
                        <td>{{workType.updated_at}}</td>
                        <td>
                            <button type="button" class="btn btn-success btn-md">
                                <a (click)="workTypeCreate.hide();workTypeEdit.show(workType);">
                                    Edit
                                </a>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>