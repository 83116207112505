import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Attachment } from '../models/attachment.model';

@Injectable( {
    providedIn: 'root',
} )
export class AttachmentService {

    constructor ( private apiService: ApiService ) { }

    public uploadAttachment ( attachableId: string, attachableType: string, attachmentType: string, file: File ) {

        const formData: FormData = new FormData();

        formData.append( 'attachable_id', attachableId );
        formData.append( 'attachable_type', attachableType );
        formData.append( 'attachment_type', attachmentType );

        formData.append( 'file', file );

        return this.apiService.upload( 'attachments', formData );
    }

    delete ( attachment: Attachment ) {

        return this.apiService.delete( `attachments/${ attachment.id }` );

    }

}
