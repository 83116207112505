import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Errors } from '../../core/models/errors.module';
import { CommonService } from '../../core/services/common.service';
import { VerificationService } from '../../core/services/verification.service';
import { District } from './../../core/models/district.model';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-district-create',
    templateUrl: './district-create.component.html',
    styleUrls: [ './district-create.component.css' ],
} )
export class DistrictCreateComponent implements OnInit {


    addDistrictFormGroup!: FormGroup;
    isSubmitting: boolean = false;
    errors: Errors = new Errors();

    constructor (
        private verificationService: VerificationService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private router: Router,
        public global: GlobalService
    ) { }

    get states () { return this.global.fetchStates(); }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {

        this.addDistrictFormGroup = this.fb.group( {
            name: [ '', [ Validators.required ] ],
            state_id: [ '', [ Validators.required ] ],
            state_name: '',
        } );
    }


    submit (): void {

        this.isSubmitting = true;

        const data: District = this.addDistrictFormGroup.value;

        this.verificationService.createDistrict( data ).subscribe(
            response => {
                this.commonService.notifications.success( 'District Added' );
                this.router.navigate( [ '/home' ] );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            } );
    }


    // Set the value of the form control state_id
    OnStateSelect ( event: any ): void {
        this.addDistrictFormGroup.controls[ 'state_id' ].setValue( event.item.id );
    }

}
