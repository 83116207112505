import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyRoleService } from '../../../core/services/company-role.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/core/services/common.service';

@Component( {
    selector: 'app-role-create',
    templateUrl: './role-create-modal.component.html'
} )
export class RoleCreateModalComponent implements OnInit {

    isSubmitting = false;
    roleCreateForm!: FormGroup;
    error: any = [];

    @ViewChild( 'roleCreateModal', { static: false } )
    public roleCreateModal!: ModalDirective;

    @Output()
    onRoleCreated = new EventEmitter<boolean>();


    constructor (
        private companyRoleService: CompanyRoleService,
        private common: CommonService,

        private fb: FormBuilder
    ) {
    }

    get name () {
        return this.roleCreateForm.get( 'name' );
    }

    ngOnInit () {
        this.initForm();
    }

    initForm (): void {
        this.roleCreateForm = this.fb.group( {
            'name': [ '', [ Validators.required, Validators.pattern( '[a-zA-Z ]*' ) ] ],
        } );
    }

    show (): void {
        this.error = [];
        this.roleCreateModal.show();
    }

    hide (): void {
        this.initForm();
        this.roleCreateModal.hide();
    }

    createRole (): void {
        this.isSubmitting = true;
        this.companyRoleService.create( this.roleCreateForm.value ).subscribe(
            data => {
                this.isSubmitting = false;
                this.hide();
                this.onRoleCreated.emit( true );
                this.common.notifications.success( 'Role Created Successfully' );
            },
            error => {
                this.isSubmitting = false;
                this.error = error.errors;
                this.common.notifications.error( error.message );
            } );
    }

}
