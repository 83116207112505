import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-pan-response-modal',
    templateUrl: './pan-response-modal.component.html',
    styleUrls: [ './pan-response-modal.component.css' ],
} )
export class PanResponseModalComponent implements OnInit {

    @ViewChild( 'panResponseModal', { static: false } )
    public panResponseModal!: ModalDirective;

    public response!: any;

    public source!: string;

    constructor () {
        //
    }

    ngOnInit (): void {
        //
    }

    show ( source: string, response: string ): void {
        this.source = source;
        this.setResponse( response );
        this.panResponseModal.show();
    }

    setResponse(response: string): void {
        try {
            this.response = JSON.parse(response);
        } catch (error) {

            this.response = this.source === 'nsdl'
                ? this.parseNSDLResponse(response)
                : JSON.stringify(response);
        }
    }

    parseNSDLResponse( response: string ): string {
        const keys = [
            'response_code',
            'pan_number',
            'pan_status',
            'last_name',
            'first_name',
            'middle_name',
            'title',
            'last_updated_date',
            'full_name',
            'aadhaar_seeding_status',
        ];

        const values = response.split('^');

        const keyValuePairs: { [key: string]: string } = {};

        for (let i = 0; i < keys.length; i++) {

            // For an error message the values array will be empty
            if (values[i]) {

                keyValuePairs[keys[i]] = i < values.length ? values[i] : '';
            }
        }

        return JSON.parse(JSON.stringify(keyValuePairs));
    }

    hide (): void {
        this.panResponseModal.hide();
    }

}
