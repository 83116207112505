<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)="$event.preventDefault()">
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block">Name </label>
                        <input class="form-control" type="text" formControlName="name" placeholder="Name" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block">Email </label>
                        <input class="form-control" type="email" formControlName="email" placeholder="Email" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block">Mobile Number </label>
                        <input class="form-control" type="text" formControlName="mobile_number"
                            placeholder="Mobile Number" />
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block"> Level</label>
                        <app-multi-select [options]="userLevels" [placeholder]="'Select Level'" [controlName]="'level'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block"> Status</label>
                        <select class="form-control" formControlName="status">
                            <option value="all" [defaultSelected]="true">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>