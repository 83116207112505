import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { FilterConfigService } from 'src/app/core/services/filter-config.service';

@Component( {
    selector: 'app-select-filter-modal',
    templateUrl: './select-filter-modal.component.html',
} )
export class SelectFilterModalComponent implements OnInit {
    isSubmitting = false;
    filterAddForm!: FormGroup;
    objectValue: string[] = [];

    @ViewChild( 'selectFilterModal', { static: false } )
    public selectFilterModal!: ModalDirective;

    @Output() onFilterUpdate = new EventEmitter<string[]>();

    @Input()
    filters!: string[];
    @Input() verification: string | undefined;

    get filterControls () {
        return ( this.filterAddForm.get( 'filters' ) as FormArray ).controls;
    }
    constructor (
        private fb: FormBuilder,
        private filterConfigService: FilterConfigService
    ) { }

    ngOnInit () {
        this.initForm();
    }

    initForm () {
        this.filterAddForm = this.fb.group( {
            filters: new FormArray( [] ),
        } );
        this.filters.sort();
        this.addCheckboxes();
    }

    show () {
        this.selectFilterModal.show();
    }

    hide () {
        this.selectFilterModal.hide();
        this.initForm();
    }

    addCheckboxes () {
        const filtersString: any = localStorage.getItem( 'filters' );
        const filters =
            localStorage.getItem( 'filters' ) !== null
                ? this.filterConfigService.getFilters(
                    JSON.parse( filtersString ),
                    this.verification
                )
                : null;

        this.objectValue = filters !== null ? filters : null;

        this.filters.forEach( ( value, index ) => {
            const control = new FormControl(
                this.objectValue !== null ? this.objectValue.includes( value ) : false
            );
            ( this.filterAddForm.controls[ 'filters' ] as FormArray ).push( control );
        } );
    }

    updatedFilters () {
        const selectedFilters = this.filterAddForm.value.filters
            .map( ( inputValue: any, index: any ) => ( inputValue ? this.filters[ index ] : null ) )
            .filter( ( inputValue: null ) => inputValue != null );

        this.filterConfigService.setFilters( selectedFilters, this.verification );

        this.hide();
        this.initForm();
        this.onFilterUpdate.emit( this.objectValue );
    }
}
