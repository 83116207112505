<div bsModal #imageCropModal="bs-modal" aria-hidden="true" class="top-50 modal fade" tabindex="-1" role="dialog" aria-labelledby="imageCropModal">

    <div class="modal-dialog modal-lg">

        <div class="modal-content">

            <div class="modal-header">

                <button type="button" class="close" data-dismiss="modal" (click)="closeModel()">&times;</button>

                <h4 class="modal-title">Image Cropper</h4>

            </div>

            <div class="modal-body">

                <div class="row margin-2">

                    <div class="col-md-6 text-center boarder-right">Original Image</div>

                    <div class="col-md-6 text-center">Crop Preview</div>

                </div>

                <div class="row margin-2">

                    <div class="col-md-6 boarder-right">

                    <image-cropper #imageCropper
                        [imageURL]="selectedImageUrl"
                        [maintainAspectRatio]="false"
                        [aspectRatio]="4 / 3"
                        format="png"
                        [transform]="transform"
                        (imageCropped)="imageCropped($event)">
                    </image-cropper>

                </div>

                <div class="col-md-6 preview-image">

                    <img [src]="croppedImage" class="width-100 image-boarder" id="canvas-image"/>

                </div>

            </div>

        </div>

        <div class="modal-footer">

            <div class="row">

            <div class="col-md-3">

                <div class="dn btn btn-sm btn-success default-color" title="rotate left" (click)="updateRotation(-15)">
                    <i class="fa fa-undo" aria-hidden="true"></i>
                </div>

                <div class="dn btn btn-sm btn-success default-color" title="rotate right" (click)="updateRotation(+15)">
                    <i class="fa fa-repeat" aria-hidden="true"></i>
                </div>

                <div class="dn btn btn-sm btn-success default-color" title="rotate right" (click)="updateRotation(+90)">
                    <i class="fa" aria-hidden="true">90`</i>
                </div>

                 <div class="dn btn btn-sm btn-success default-color" title="rotate right" (click)="resetImage()">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                </div>

            </div>

            <div class="col-md-1 seprator"></div>

            <div class="col-md-8">

                <div class="dn btn btn-sm btn-success default-color" title="rotate left" (click)="changeZoom(+0.1)">
                    <i class="fa fa-search-plus" aria-hidden="true"></i>
                </div>

                <div class="dn btn btn-sm btn-success default-color" title="rotate right" (click)="changeZoom(-0.1)">
                    <i class="fa fa-search-minus" aria-hidden="true"></i>
                </div>

            </div>

            <div class="col-md-1" style="padding-left: 0px;">
                <button type="button" class="btn btn-primary" (click)="onCropCompleted()">Done</button>
            </div>

        </div>

    </div>

</div>

  </div>
</div>
