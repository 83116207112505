import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Errors } from '../../core/models/errors.module';
import { Pagination } from '../../core/models/pagination.model';
import { User } from '../../core/models/user.model';
import { Verification } from '../../core/models/verification.model';
import { CompanyService } from '../../core/services/company.service';
import { LotService } from '../../core/services/lot.service';
import { UserService } from '../../core/services/user.service';
import { VerificationService } from '../../core/services/verification.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-lot-create',
    templateUrl: './lot-create.component.html',
    styleUrls: [ './lot-create.component.css' ],
    providers: [ LotService, UserService, VerificationService, CompanyService ],
} )
export class LotCreateComponent implements OnInit {

    title: string = '';
    errors: Errors = new Errors();
    isSubmitting = false;
    lotCreateFormGroup: FormGroup;
    verifications: Verification[] = [];
    editors: User[] = [];
    data!: Pagination;
    clients!: any[];


    constructor ( private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private lotService: LotService,
        private verificationService: VerificationService,
        private userService: UserService,
        private companyService: CompanyService,
        public global: GlobalService,
    ) {

        this.lotCreateFormGroup = this.fb.group( {
            'lot_name': [ '', Validators.required ],
            'cities': [ '' ],
            'verifications': [ [] ],
            'editors': [ [] ],
            'company_id': [ '' ],
            'lot_status': '',
            'type': [ '', Validators.required ],
            'clients': [ [] ],
            'instructions': [ '' ],
            'settings': this.fb.group( {
                'icr_civil_status': [ false ],
                'icr_criminal_status': [ false ],
                'gst_number': [ '' ],
                'billing_name': [ '' ],
                'email_visibility': [ true ],
                'mobile_visibility': [ true ],
                'current_digital_address_form_id': [],
                'permanent_digital_address_form_id': []
            } ),
        } );
    }

    get users () {
        return this.global.fetchUsers();
    }

    ngOnInit () {
        let company_id: number;
        // Setting the query parameter in the route as the company_id for creating the lot.
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    company_id = params[ key ];
                }
            } );

        } );

        this.lotCreateFormGroup.patchValue( { 'company_id': company_id! } );
        this.companyService.getAllClients( company_id! ).subscribe(
            {
                next: ( clients ) => {
                    this.clients = clients
                },
                error: ( errors ) => { }
            }
        );

        this.verificationService.getAllVerifications().then( verifications => this.verifications = verifications );

    }

    onCheckboxChange ( event: any, index: any, verificationData: any ) {

        const list = this.lotCreateFormGroup.value.verifications;

        if ( event.target.checked == true ) {
            verificationData.index = index;
            list.push( verificationData );
        } else {
            if ( list.length > 1 ) {
                for ( let i = 0; i < list.length; i++ ) {
                    if ( list[ i ].index == index ) {
                        list.splice( i, true );
                        break;
                    }
                }
            } else {
                list.splice( 0, true );
            }

        }
        this.lotCreateFormGroup.value.verifications = list;

    }

    onSubmit () {
        this.isSubmitting = true;
        this.errors = new Errors();
        const data = this.lotCreateFormGroup.value;
        this.lotService
            .create( data )
            .subscribe( data => this.router.navigateByUrl( '/companies/' + data.company_id ), err => {
                this.errors = err;
                this.isSubmitting = false;
            } )
    }
}
