<div *ngIf="document" class="col-md-12">
    <app-loading-component [fetchingInProgress]="fetchingInProgress"></app-loading-component>

    <div>
        <button type="button" (click)="rotateLeft()">Rotate left</button>
        <button type="button" (click)="rotateRight()">Rotate right</button>
        <button type="button" (click)="flipHorizontal()">Flip horizontal</button>
        <button type="button" (click)="flipVertical()">Flip vertical</button>
        <button type="button" class="pull-right" (click)="zoomOut()">-</button>
        <button type="button" class="pull-right" (click)="zoomIn()">+</button>
    </div>
    <div class="form-group">
        <image-cropper #imageCropperComponent [imageURL]="document" [maintainAspectRatio]="false"
            [transform]="transform" [canvasRotation]="canvasRotation" [aspectRatio]="4 / 3" [imageQuality]="75"
            format="jpeg" (imageCropped)="imageCropped($event,documentType)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>

</div>