import { Component, OnInit } from '@angular/core';
import { CompanyService } from '../../core/services/company.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Lot } from '../../core/models/lot.model';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { CommonService } from '../../core/services/common.service';
import { Company, MetaField } from '../../core/models/company.model';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MetafieldService } from 'src/app/core/services/metafield.service';

@Component( {
    selector: 'app-company-excel',
    templateUrl: './company-excel.component.html',
    styleUrls: [ './company-excel.component.css' ],
} )
export class CompanyExcelComponent implements OnInit {

    loading!: boolean;
    companyId!: number;
    allLots: Lot[] = [];
    lots: any[] = [];
    address: any[] = [];
    identity: any[] = [];
    education: any[] = [];
    employment: any[] = [];
    icr: any[] = [];
    reference: any[] = [];
    pcc: any[] = [];
    document: any[] = [];
    globaldb: any[] = [];
    additional: any[] = [];
    cases: any[] = [];
    basic: any[] = [];
    allVerifications: any[] = [];
    company: Company = new Company();
    filterParams: HttpParams = new HttpParams();
    data: any[] = [];
    selectedMetafields: any[] = [];

    additionalFields!: any[];
    basicFields!: any[];
    metaFields: MetaField[] = [];
    button: any;
    filterFormGroup!: FormGroup;

    constructor (
        private route: ActivatedRoute,
        private companyService: CompanyService,
        private commonService: CommonService,
        private metafieldService: MetafieldService,
        private fb: FormBuilder,
        private router: Router,
    ) { }

    ngOnInit () {

        this.initForm();
        this.button = <HTMLInputElement> document.getElementById( 'download_excel' );
        this.button.disabled = true;
        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.companyId = +params[ 'id' ];

            this.companyService.find( this.companyId ).subscribe(
                response => {
                    this.company = response;
                }
            );

            this.companyService.getLots( this.companyId ).subscribe(
                response => {
                    this.allLots = response;
                    this.companyService.verificationsList().subscribe(
                        response => {
                            this.allVerifications = response;
                        }
                    );
                }
            );

            this.companyService.getAdditionalFields().then( response => this.additionalFields = response );

            this.companyService.getBasicFields().then( response => this.basicFields = response );

            this.metafieldService.getCompanyMetafields( this.companyId ).subscribe(
                response => this.metaFields = response
            );

        } );

    }

    initForm () {
        this.filterFormGroup = this.fb.group( {
            created_after: 'all',
            created_before: 'all',
        } );
    }

    subscribeToQueryChanges (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
        } );

    }

    checkAll ( ev: any, type: string | any[], save_in: any ) {

        const val = ev.target.className;

        const arrayName: any = this.getArray( save_in );

        if ( ev.target.checked ) {
            this.button.disabled = false;
            arrayName.length = 0;
            for ( let i = 0; i < type.length; i++ ) {
                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all-' + val )[ i ] ).checked = true;
                arrayName.push( type[ i ].id );

            }
        } else {
            for ( let i = 0; i < type.length; i++ ) {
                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all-' + val )[ i ] ).checked = false;
                arrayName.length = 0;
                this.button.disabled = true;
            }
        }
    }

    checkAllMetafields ( $event: any, values: MetaField[], propertyName: any ) {

        const val = $event.target.className;

        if ( $event.target.checked ) {

            this.button.disabled = false;

            for ( let i = 0; i < values.length; i++ ) {

                const element = values[ i ];

                // TODO: improve this
                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all-' + val )[ i ] ).checked = true;

                this.selectedMetafields.push( element.name );
            }

        } else {

            this.button.disabled = true;

            for ( let i = 0; i < values.length; i++ ) {

                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all-' + val )[ i ] ).checked = false;

                this.selectedMetafields.splice( 0, this.selectedMetafields.length );

                this.button.disabled = true;
            }
        }
    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( option: any, data: any, save_in: any ) {

        const arrayName: any = this.getArray( save_in );

        if ( data.target.checked ) {
            this.button.disabled = false;
            arrayName.push( option )
        } else {
            const index = arrayName.indexOf( option );
            if ( index !== -1 ) {
                arrayName.splice( index, 1 );
                if ( arrayName.length == 0 ) {
                    this.button.disabled = true;
                }
            }
        }
    }

    // Get the array of the selected columns.
    getArray ( saveIn: string ): any {

        if ( saveIn == 'lots' ) return this.lots;

        if ( saveIn == 'address' ) return this.address;

        if ( saveIn == 'icr' ) return this.icr;

        if ( saveIn == 'identity' ) return this.identity;

        if ( saveIn == 'pcc' ) return this.pcc;

        if ( saveIn == 'education' ) return this.education;

        if ( saveIn == 'employment' ) return this.employment;

        if ( saveIn == 'globaldb' ) return this.globaldb;

        if ( saveIn == 'document' ) return this.document;

        if ( saveIn == 'reference' ) return this.reference;

        if ( saveIn == 'additional' ) return this.additional;

        if ( saveIn == 'cases' ) return this.cases;

        if ( saveIn == 'basic' ) return this.basic;

        if ( saveIn == 'selectedMetafields' ) return this.selectedMetafields;

    }

    exportExcel () {

        this.subscribeToQueryChanges();

        const data = {
            'lots': this.lots,
            'verifications': {
                'identity': this.identity,
                'pcc': this.pcc,
                'address': this.address,
                'education': this.education,
                'employment': this.employment,
                'globaldb': this.globaldb,
                'icr': this.icr,
                'cases': this.cases,
                'document': this.document,
                'reference': this.reference,
            },
            'additional': this.additional,
            'basic': this.basic,
            'metafields': this.selectedMetafields,
        };

        this.getData( data );


    }

    getData ( data: { lots: any[]; verifications: { identity: any[]; pcc: any[]; address: any[]; education: any[]; employment: any[]; globaldb: any[]; icr: any[]; cases: any[]; document: any[]; reference: any[]; }; additional: any[]; basic: any[]; metafields: any[]; } ) {

        this.companyService.downloadExcelData( data, this.companyId, this.filterParams, this.company.name );

    }

    addFilterParams () {

        this.filterParams = new HttpParams();

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );

    }

    resetForm () {
        this.initForm();
    }
}
