<div bsModal #blacklistedDetails="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{rcNumber}} Blacklisted Details</h4>

            </div>
            <div class="modal-body" *ngFor="let blacklisted of vehiclerc">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th>
                                Registering State
                            </th>

                            <th>
                                Registering Authority
                            </th>


                            <th>
                                Fir Number
                            </th>

                            <th>
                                Fir Date
                            </th>

                            <th>

                                Blacklisted Reason

                            </th>

                            <th>
                                Blacklisted Date
                            </th>

                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>

                                {{blacklisted.registering_authority}}


                            </td>

                            <td>

                                {{blacklisted.registering_state}}


                            </td>

                            <td>
                                {{blacklisted.fir_number}}


                            </td>

                            <td>
                                {{blacklisted.fir_year}}


                            </td>

                            <td>

                                {{blacklisted.blacklisted_reason}}
                            </td>

                            <td>
                                {{blacklisted.blacklisted_date}}

                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>