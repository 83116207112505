<div bsModal #documentActionModal="bs-modal" aria-hidden="true" class="top-50 modal fade" tabindex="-1" role="dialog"
    aria-labelledby="imageCropModal">

    <div class="modal-dialog modal-lg" [formGroup]="selfFormGroup">

        <div class="modal-content">

            <div class="modal-header" *ngIf="documentAction">

                <button type="button" class="close" data-dismiss="modal" (click)="onModalClose()">&times;</button>

                <h4 class="modal-title">Document {{documentAction.actionName}} Action</h4>

            </div>


            <!-- {{actionSelectedOption|json}} -->
            |
            <!-- {{verificationIdList|json}} -->

            <div class="modal-body">

                <div class="row margin-2" style="margin-bottom: 6%;" *ngIf="fromField">

                    <div class="col-md-3 text-left boarder-right">From Location</div>

                    <div class="col-md-3 text-left boarder-right">{{fromField.verificationLabel}}</div>
                    <div class="col-md-3 text-left boarder-right">{{fromField.typeLabel}}</div>
                    <div class="col-md-3 text-left boarder-right">{{fromField.documentLabel}}</div>


                </div>

                <div class="row margin-2">

                    <div class="col-md-3 text-left">To Location</div>

                    <div class="col-md-3 text-left boarder-right">
                        <select name="verificationField" class="form-control item-center"
                            formControlName="verificationId">

                            <option [ngValue]="null" selected>Select Verification</option>

                            <option value="employee">Employee</option>

                            <option *ngFor="let label of verificationLabelList" [value]="label.verificationId">
                                {{label.verificationLabel}}
                            </option>

                        </select>
                    </div>

                    <div class="col-md-3 text-left boarder-right">

                        <select name="verificationField" class="form-control item-center" formControlName="typeId">

                            <option [ngValue]="null" selected>Verification Type</option>

                            <option *ngFor="let verification of typeLabelList" [ngValue]="verification.typeId">
                                {{verification.typeLabel}}</option>

                        </select>
                    </div>

                    <div class="col-md-3 text-left boarder-right">

                        <select name="verificationField" class="form-control item-center" formControlName="documentId"
                            (change)="onDocumentChange()">

                            <option [ngValue]="null" selected>Select Document</option>

                            <option *ngFor="let fields of documentLabelList" [ngValue]="fields">{{fields.documentLabel}}
                            </option>

                        </select>
                    </div>


                </div>


            </div>

            <div class="modal-footer">

                <div class="row">

                    <div class="col-md-4"></div>

                    <div class="col-md-4"></div>

                    <div class="col-md-3"></div>

                    <div class="col-md-1" style="padding-left: 0px;"
                        *ngIf="verificationId.value && typeId.value && documentId.value">

                        <button *ngIf="documentAction?.actionName=='Duplicate'" type="button"
                            class="btn btn-primary pull-right" (click)="onDuplicate()">Copy</button>

                        <button *ngIf="documentAction?.actionName=='Move'" type="button"
                            class="btn btn-primary pull-right" (click)="onMove()">Move</button>

                        <button *ngIf="documentAction?.actionName=='Swap'" type="button"
                            class="btn btn-primary pull-right" (click)="onSwap()">Swap</button>
                    </div>

                </div>

            </div>

        </div>

    </div>

</div>