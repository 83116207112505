
import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Lot } from '../../core/models/lot.model';
import { LotService } from '../../core/services/lot.service';
import { EmployeeListComponent } from '../../employee/employee-list/employee-list.component';

@Component( {
    selector: 'app-lot-show',
    templateUrl: './lot-show.component.html',
    providers: [ LotService, EmployeeListComponent ],
} )
export class LotShowComponent implements OnInit {

    lot: Lot = new Lot();
    verificationStats: any[] = [];
    verifications: any[] = [];

    constructor (
        private route: ActivatedRoute,
        private lotService: LotService
    ) {
    }

    ngOnInit (): void {

        // Fetch Lot Details
        this.route.paramMap.pipe( switchMap( ( params: ParamMap ) => this.lotService.find( +params.get( 'id' )! ) ) )
            .subscribe(
                lot => {
                    this.lot = lot;
                }
            );

        // Fetch Lot Stats
        this.route.paramMap.pipe( switchMap( ( params: ParamMap ) => this.lotService.stats( +params.get( 'id' )! ) ) )
            .subscribe(
                stats => this.parseStats( stats )
            );
    }

    parseStats ( stats: any[] ): void {
        Object.keys( stats ).forEach( ( key: any ) => {
            if ( stats[ key ] !== '' ) {
                this.verifications.push( key );
                this.verificationStats[ key ] = stats[ key ];
            }
        } );
    }


}
