import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Pipe } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Lot } from 'src/app/core/models/lot.model';
import { LotService } from 'src/app/core/services/lot.service';

@Component( {
    selector: 'app-lot-history',
    templateUrl: './lot-history.component.html',
    styleUrls: [ './lot-history.component.css' ]
} )

export class LotHistoryComponent implements OnInit {

    lotHistory!: Lot | any;
    lot: Lot = new Lot();
    id!: number;

    constructor (
        private activatedRoute: ActivatedRoute,
        private lotService: LotService
    ) {
        this.activatedRoute.params.subscribe( ( params: any ) => this.id = +params.id );
    }

    ngOnInit (): void {

        this.id = +this.activatedRoute.snapshot.params[ 'id' ];

        this.fetchLot( this.id );

        this.fetchHistory( this.id );
    }

    fetchLot ( lotId: number ): void {

        this.lotService.find( lotId ).subscribe( lot => {

            this.lot = lot;

        } )
    }

    fetchHistory ( lotId: number ): void {

        this.lotService.getHistory( lotId ).subscribe( ( response: Lot ) => {

            this.lotHistory = response;

        } );
    }
}
