<div class="row margin-0" [formGroup]="documentFormGroup">

    <div class="col-md-1 table-body-cell">

        <span class="pageDetails" *ngIf="pageDetail?.value; else emptyPage;">{{pageDetail.value}}</span>

        <ng-template #emptyPage>

            <span class="pageDetails">Uploaded</span>

        </ng-template>

    </div>

    <div class="col-md-2 table-body-cell">

        <div *ngIf="getFileType(imageUrl.value)!='image'">

            <a [href]="imageUrl.value" target="_blank">

                <i class="fa fa-file-pdf-o fa-4x btn text-danger" aria-hidden="true"
                    *ngIf="getFileType(imageUrl.value)=='pdf'"></i>

                <i class="fa fa-file-excel-o fa-4x btn text-success" aria-hidden="true"
                    *ngIf="getFileType(imageUrl.value)=='excel'"></i>

                <i class="fa fa-file-text-o fa-4x btn text-info" aria-hidden="true"
                    *ngIf="getFileType(imageUrl.value)=='document'"></i>


            </a>

        </div>

        <div class="zoom-container" *ngIf="getFileType(imageUrl.value)=='image'">

            <img [src]="imageUrl.value" alt="Image" #image class="thumbnail-image" (mouseenter)="zoom=true"
                (mouseleave)="zoom=false">



            <div *ngIf="zoom" class="zoomed-image-container">

                <img [src]="image.src" alt="Zoomed In Image" class="zoomed-image">

            </div>

        </div>

    </div>

    <div class="col-md-3 table-body-cell">

        <select formControlName="verificationId" class="form-control item-center">

            <option [ngValue]="null">Select Verification Type</option>

            <option value="employee">Employee</option>

            <option *ngFor="let verification of verificationLabelList" [value]="verification.verificationId">
                {{verification.verificationLabel}}</option>

        </select>

    </div>

    <div class="col-md-2 table-body-cell">

        <select name="verificationId" [attr.disabled]="disabled" formControlName="typeId"
            class="form-control item-center">

            <option [ngValue]="null">Select Verification Id</option>

            <option *ngFor="let verification of typeLabelList" [ngValue]="verification.typeId">
                {{verification.typeLabel}}</option>

        </select>

    </div>

    <div class="col-md-2 table-body-cell">

        <select name="verificationField" *ngIf="disabled" [attr.disabled]="disabled" formControlName="documentId"
            class="form-control item-center">

            <option [ngValue]="null" selected>Select Document</option>

            <option *ngFor="let fields of documentLabelList" [ngValue]="fields.documentId">{{fields.documentLabel}}
            </option>


        </select>

        <select name="verificationField" required *ngIf="!disabled" formControlName="documentId"
            class="form-control item-center" (change)="onDocumentChange()">

            <option [ngValue]="null" selected>Select Document</option>

            <option *ngFor="let fields of documentLabelList" [ngValue]="fields">{{fields.documentLabel}}</option>


        </select>

    </div>

    <div class="col-md-2 table-body-cell">

        <div class="row" *ngIf="!disabled">

            <div class="col-md-12 text-center">

                <!-- <div *ngIf="previousImage" class="">

                    <input formControlName="forceUpload" type="checkbox" class="pull-left margin-left-5 margin-right-5"> Replace

                    <a target="_blank" [href]="previousImage"><span class="pull-right"> Old file</span></a>
                    need to check
                </div> -->

            </div>

            <div class="col-md-12 text-center" *ngIf="verificationId.value && typeId.value && documentId.value">

                <div class="dn btn btn-sm btn-default form-control width-150 upload-button">

                    <div *ngIf="!uploadProcessing && !uploadCompleted" (click)="onUpload()"><i class="fa fa-upload"
                            aria-hidden="true"></i> Upload</div>

                    <div *ngIf="uploadProcessing && !uploadCompleted"><i class="fa fa-spinner fa-pulse fa-fw"></i>
                        Upload...</div>

                    <div *ngIf="!uploadProcessing && uploadCompleted" style="color: green;" (click)="onUpload()"><i
                            class="fa fa-check-circle" aria-hidden="true"></i> Done</div>

                </div>

            </div>

        </div>

        <div class="row">

            <div class="col-md-12 text-center">

                <div class="dn btn btn-sm btn-info width-150" tooltip="Duplicate the image that was not uploaded"
                    (click)="onDuplicate()">
                    Duplicate
                </div>

            </div>

            <div class="col-md-12 text-center">

                <div class="dn btn btn-sm btn-success width-150"
                    tooltip="Trim or crop the image as necessary with appropriate dimensions"
                    (click)="imageCropModal.show()">
                    Crop
                </div>

            </div>

            <div class="col-md-12 text-center">

                <div class="dn btn btn-sm btn-danger width-150" tooltip="Deletion of the specified image as needed"
                    (click)="onDeleteButtonClicked()">
                    Delete
                </div>

            </div>

        </div>

        <div class="row" *ngIf="uploaded.value">

            <div class="col-md-12 text-center">

                <div class="dn btn btn-sm btn-info width-150"
                    tooltip="Relocate or transfer the specified image to its origional destination" (click)="onMove()">
                    Move
                </div>

            </div>

            <div class="col-md-12 text-center">

                <div class="dn btn btn-sm btn-success width-150"
                    tooltip="Ensuring that the designated images are exchanged appropriately" (click)="onSwap()">
                    Swap
                </div>

            </div>

        </div>

    </div>
</div>


<candidate-document-crop #imageCropModal [imagesUrl]="imageUrl.value" [uploaded]="uploaded.value"
    (croppedUrl)="croppedImageUrl($event)">
</candidate-document-crop>