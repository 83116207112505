<div *ngIf="lot.id" class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Edit Lot</h1>
        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a [routerLink]="['/companies', lot.company_id]">{{ lot.company_name }}</a></li>
            <li><a [routerLink]="['/lots', lot.id]">{{ lot.name }}</a></li>
            <li>Edit</li>
        </ul>
    </div>

    <div class="col-md-4 text-right text-center-xs" [appCan]="'admin'">
        <div class="btn-group">
            <button (click)="onDeleteLotButtonClicked()" class="btn btn-default btn-addon">Delete Lot
            </button>
        </div>
    </div>
</div>


<div class="wrapper-md">
    <div class="row">
        <form [formGroup]="editFormGroup" (ngSubmit)="updateLot()">
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Lot Details
                    </div>
                    <div class="panel-body">
                        <app-errors [errors]="errors"></app-errors>

                        <fieldset [disabled]="isSubmitting">
                            <div class="row">

                                <div class="form-group col-md-6">
                                    <label class="center-block">Name</label>
                                    <input formControlName="name" class="form-control">
                                </div>
                                <div class="form-group col-md-6">
                                    <label class="center-block">Cities</label>
                                    <input formControlName="cities" class="form-control">
                                </div>
                            </div>

                            <div class="row">

                                <div class="form-group col-md-6">
                                    <label>Lot status</label>
                                    <select class="form-control" formControlName="status_id">
                                        <option value="0">Active</option>
                                        <option value="1">Batch Freezed</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label>Lot type</label>
                                    <select class="form-control" formControlName="type">
                                        <option value="blue-collar">Blue</option>
                                        <option value="white-collar">White</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6" *ngIf="allClients">
                                    <label class="center-block">Clients</label>

                                    <app-multi-select [options]="allClients" [placeholder]="'Select Clients'"
                                        [controlName]="'clients'" [formGName]="editFormGroup"></app-multi-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6">
                                    <label class="center-block">Editors</label>

                                    <app-multi-select [options]="users" [placeholder]="'Select Editors'"
                                        [controlName]="'editors'" [formGName]="editFormGroup"></app-multi-select>
                                </div>

                                <div class="form-group col-md-12">
                                    <fieldset class="form-group">
                                        <label>Lot Instructions </label> <span class="pull-right"></span>

                                        <ckeditor formControlName="instructions"></ckeditor>
                                    </fieldset>
                                </div>
                            </div>


                            <button class="btn btn-lg btn-primary btn-block" type="submit"
                                [disabled]="!verificationValiadtion && editFormGroup.invalid">
                                Edit Lot
                            </button>
                        </fieldset>

                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Verifications
                    </div>
                    <div class="panel-body">
                        <div class="form-group col-md-6">

                            <fieldset class="form-group">
                                <label>Verifications </label>
                                <app-multi-select [options]="allVerifications" [placeholder]="'Select Verifications'"
                                    [controlName]="'verifications'" [formGName]="editFormGroup"
                                    (onDropDownClosed)="onVerificationDropdownClosed()"></app-multi-select>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6" formGroupName="settings">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Additional Settings
                    </div>
                    <div class="panel-body">
                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="icr_civil_status"> Icr Civil Status
                            </label>
                        </div>
                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="icr_criminal_status"> Icr Criminal Status
                            </label>
                        </div>

                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="email_visibility"> Email Visibility
                            </label>
                        </div>

                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="mobile_visibility"> Mobile Visibility
                            </label>
                        </div>

                        <div *ngIf="digitalAddressForms.length > 0">
                            <div class="form-group col-md-12">
                                <label>
                                    Select Current Digital Address Form
                                </label>
                                <select class="form-control" [formControl]="currentAddressFormId">
                                    <option *ngFor="let form of digitalAddressForms" [value]="+form.id">
                                        {{ form.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group col-md-12">
                                <label>
                                    Select Permanent Digital Address Form
                                </label>
                                <select class="form-control" [formControl]="permanentAddressFormId">
                                    <option *ngFor="let form of digitalAddressForms" [value]="+form.id">
                                        {{ form.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <label class="center-block">GST Number</label>
                            <input formControlName="gst_number" class="form-control">
                        </div>
                        <div class="form-group col-md-12">
                            <label class="center-block">Billing Name</label>
                            <input formControlName="billing_name" class="form-control">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="panel panel-default">

                    <div class="panel-heading">
                        Client Form Details
                    </div>
                    <div class="panel-body">
                        <div class="table-responsive">
                            <table class="table table-striped b-t b-light">
                                <thead>
                                    <tr>
                                        <th style="width:50%;">Form Details</th>
                                        <th style="width:20%;">Verifications</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let form of clientForms">

                                        <td>
                                            <ul class="list-unstyled">

                                                <li><a class="btn-link text-main text-semibold lots"
                                                        routerLink="/forms/{{form.form_id}}/edit">
                                                        <strong>{{ form.form_name }}</strong>
                                                    </a>
                                                </li>
                                                <li>Status: {{ form.status_name}}</li>
                                                <li>{{ form.created_at}}</li>
                                            </ul>
                                        </td>

                                        <td>
                                            <ul class="list-unstyled">
                                                <li *ngIf="form.identity_check">Identity verification</li>
                                                <li *ngIf="form.education_check">Education verification</li>
                                                <li *ngIf="form.employment_check">Employment verification</li>
                                                <li *ngIf="form.court_record_check">ICR verification</li>
                                                <li *ngIf="form.current_address_check">Current address verification</li>
                                                <li *ngIf="form.permanent_address_check">Permanent verification</li>
                                                <li *ngIf="form.reference_verification">Reference Verification</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>