<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Create an Act Type</h1>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                    <div class="col-md-6 form-group">
                    <app-errors [errors]="errors"></app-errors>
                    <form [formGroup]="createForm" (ngSubmit)="submitForm()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Name</label>
                                <input formControlName="name" class="form-control form-control-lg" />

                            </fieldset>
                            <fieldset class="form-group">
                                <label>Description</label>
                                <input formControlName="description" class="form-control form-control-lg" />
                            </fieldset>

                            <button class="btn btn-lg btn-primary btn-block" [disabled]="!createForm.valid">
                                Create
                            </button>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
        </div>
        </div>
