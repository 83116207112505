import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { WebhookService } from '../../core/services/webhook.service';
import { CommonService } from '../../core/services/common.service';

import { HttpParams } from '@angular/common/http';

@Component( {
    selector: 'app-webhook-create',
    templateUrl: './webhook-create.component.html',
    styleUrls: [ './webhook-create.component.css' ],
} )
export class WebhookCreateComponent {

    @ViewChild( 'webhookCreateModal', { static: false } )
    public webhookCreateModal!: ModalDirective;

    @Output()
    onWebhookCreated = new EventEmitter<boolean>();

    client_apis!: any[];
    url!: string;
    client_api_id!: number;
    fixed_header_key = 'Content-Type';
    fixed_header_value = 'application/json';
    numberOfHeaders: number[] = [ 1, 2, 3, 4 ];
    headers: any[] = [];
    values: any[] = [];
    type!: string;

    constructor (
        private webhookService: WebhookService,
        private commonService: CommonService,
        // public global: SimpleGlobal
    ) { }

    initForm () {
        this.client_apis = [];
        this.url = '';
        this.headers = [];
        this.values = [];
        this.type = 'company';
    }

    show () {
        this.webhookCreateModal.show();
    }

    onTypeSelected ( $event: any ) {

        let params = new HttpParams();

        params = params.set( 'type', $event.target.value );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.client_apis = response;
                console.log( 'client apis are: ' );
                console.log( this.client_apis );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }

    hide () {

        this.webhookCreateModal.hide();
    }

    createWebhook () {
        const data = {
            url: this.url,
            client_api_id: this.client_api_id,
            headers: this.headers,
            values: this.values,
        };
        this.webhookService.create( data ).subscribe(
            response => {
                this.commonService.notifications.success( 'Successful', 'Webhook Created successfully' );
                this.hide();
                this.initForm();
                this.onWebhookCreated.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.hide();

            }
        );
    }

}
