import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Document } from '../models/document.model';

@Injectable( {
    providedIn: 'root',
} )
export class DocumentsUploadService {

    constructor ( private apiService: ApiService ) { }

    public uploadDocument ( documentableId: string, documentableType: string, documentType: string, file: File ) {

        const formData: FormData = new FormData();

        formData.append( 'documentable_id', documentableId );
        formData.append( 'documentable_type', documentableType );
        formData.append( 'document_type', documentType );

        formData.append( 'file', file );

        return this.apiService.upload( 'documents', formData );
    }

    delete ( document: Document ) {

        return this.apiService.delete( `documents/${ document.id }` );

    }

}
