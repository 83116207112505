<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Client Excel</h1>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-2">
                            <label class="center-block">
                                <strong>Select Company: </strong>
                            </label>
                        </div>
                        <div class="col-md-10">
                            <select (change)="onCompanySelected()" class="form-control" formControlName="company_id">
                                <option [ngValue]="null">Select Company</option>
                                <option *ngFor="let company of companies" [value]="company.id">
                                    {{company.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-2">
                            <label class="center-block"> <strong>Select Lots: </strong></label>
                        </div>
                        <div class="col-md-10">
                            <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                                [formGName]="uploadForm">
                            </app-multi-select>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-6 form-group">
                        <div class="col-md-2 form-group">
                            <label class="center-block"> <strong>Select Access: </strong></label>
                        </div>

                        <div class="col-md-10 form-group">

                            <app-multi-select [options]="routes" [placeholder]="'Select Access'"
                                [controlName]="'access_ids'" [formGName]="uploadForm"></app-multi-select>

                        </div>
                    </div>


                </div>

                <div class="row">

                    <div class="col-md-6 form-group">
                        <div class="col-md-2 form-group">
                            <label class="center-block"> <strong>Select SPOC User: </strong></label>
                        </div>

                        <div class="col-md-10 form-group">

                            <app-multi-select [options]="users" [multipleValue]="false"
                                [placeholder]="'Select SPOC User'" [controlName]="'spoc_user_id'"
                                [formGName]="uploadForm"></app-multi-select>

                        </div>
                    </div>


                </div>

                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-2"></div>
                        <div class="col-md-10">
                            <div class="form-group">
                                <input type="file" [accept]="acceptedFileType" [disabled]="isFileDisabled"
                                    (change)="onFileChange($event)" class="form-control" />
                            </div>

                            <div>
                                <span class="text-danger pull-left">{{errorMessage}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="row">
                <div class="col-md-5 form-group">
                    <button class="btn btn-primary">
                        <a (click)="downloadSampleExcel()">Download Sample Excel</a>
                    </button>
                </div>

                <div class="col-md-6 form-group">
                    <button type="button" [disabled]="uploadForm.invalid || isSubmitting" class="btn btn-primary"
                        (click)="onSubmit()">
                        Submit
                    </button>
                </div>


            </div>
        </div>
    </div>
</div>


<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-body">
            <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                <fieldset [disabled]="isSubmitting">
                    <div style="max-width: 100%;overflow-x: scroll">
                        <table class="table table-bordered" formArrayName="clients">
                            <tr>
                                <th style="padding-right: 30px;padding-left: 20px;">EMAIL</th>
                                <th style="padding-right: 30px">USER NAME</th>
                                <th style="padding-right: 30px">DESIGNATION</th>
                                <th style="padding-right: 30px">MOBILE</th>
                            </tr>
                            <tr *ngFor="let row of clients.controls;let i=index" [formGroupName]="i">

                                <td style="padding-right: 30px;padding-left: 20px;">
                                    <input formControlName="email" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('email')?.invalid">
                                        Invalid email address
                                    </span><br>
                                </td>

                                <td style="padding-right: 30px">
                                    <input formControlName="user_name" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('user_name')?.invalid">
                                        Invalid user name
                                    </span><br>
                                </td>

                                <td style="padding-right: 30px">
                                    <input formControlName="designation" class="form-control">
                                    <span class="text-danger">

                                    </span><br>
                                </td>

                                <td style="padding-right: 30px">
                                    <input formControlName="mobile" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('mobile')?.invalid">
                                        Invalid mobile number
                                    </span><br>
                                </td>
                            </tr>
                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>