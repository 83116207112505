import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { Workbook } from 'exceljs';

interface Row {
    [ key: string ]: any;
}

export abstract class ExportExcelComponent {

    columnsNotToConvertToUpperCase: string[] = [
        'PHOTO',
        'CLIENT EMPLOYEE ID',
        'FRONT URL',
        'BACK URL',
        'PROOF_URL',
        'ATTACHMENTS',
        'PARIVAHAN APPLICATION SCREENSHOT LINK',
        'PARIVAHAN APPLICATION HTML LINKS',
        'PARIVAHAN SARATHI SCREENSHOT LINK',
        'PARIVAHAN SARATHI HTML LINKS',
        'PARIVAHAN SCREENSHOT LINK',
        'PARIVAHAN HTML LINKS',
        'VAHAN SCREENSHOT LINK',
        'VAHAN HTML LINKS',
        'FIRST_IMAGE_URL',
        'SECOND_IMAGE_URL',
        'FRONT_URL',
        'BACK_URL',
        'PAYMENT URL',
        'APPLICATION URL',
        'LINK',
        'LOGIN ID',
        'PASSWORD'
    ];

    headers: any;

    constructor () {

    }

    // Generate the workbook and write the data to it.
    generateExcelFile ( data: any ): any {

        const sheetName: string = 'Sheet1';

        const workBook: any = { SheetNames: [], Sheets: {} };

        const ws: any = utils.json_to_sheet( data );

        const range: any = utils.decode_range( ws[ '!ref' ] );

        // Rows range from range.s.r to range.e.r
        // Columns range from range.s.c to range.e.c
        for ( let R: number = range.s.r; R <= range.e.r; ++R ) {

            for ( let C: number = range.s.c; C <= range.e.c; ++C ) {

                const address: any = utils.encode_col( C ) + utils.encode_row( R );

                const firstRowAddress: any = utils.encode_col( C ) + utils.encode_row( 0 );
                // if worksheet cell exists
                if ( !ws[ address ] ) {
                    continue;
                }

                // if worksheet cell is empty
                if ( !ws[ address ].v || ws[ address ].v === null ) {
                    ws[ address ].v = '-';
                }

                // if worksheet cell has value
                if ( ws[ address ].v && this.columnsNotToConvertToUpperCase.indexOf( ws[ firstRowAddress ].v ) == -1 ) {
                    if ( !ws[ firstRowAddress ].v.includes( 'URL' ) ) { ws[ address ].v = ws[ address ].v.toString().toUpperCase(); }
                }

            }
        }

        workBook.SheetNames.push( sheetName );

        workBook.Sheets[ sheetName ] = ws;

        return write( workBook, { bookType: 'xlsx', type: 'binary', compression: true } );
    }

    // Add the sheet data to array buffer.
    s2ab ( s: any ): ArrayBuffer {

        const buf: ArrayBuffer = new ArrayBuffer( s.length );
        const view: Uint8Array = new Uint8Array( buf );
        for ( let i: number = 0; i !== s.length; ++i ) {
            view[ i ] = s.charCodeAt( i ) & 0xFF;
        }

        return buf;
    }

    // Download the excel
    downloadExcel ( data: any[], excelName: string ): void {

        if ( !data.length ) return;

        // saveAs( new Blob( [ this.s2ab( this.generateExcelFile( data ) ) ], { type: 'application/octet-stream' } ), excelName + '.xlsx' );
        this.newGenerateExcelFile( this.formatDataForExcel( data ), excelName );
    }

    newGenerateExcelFile ( data: any[], fileName: string ): void {

        if ( !this.headers.length ) return;

        let workbook = new Workbook();

        this.updateWorkBookInfo( workbook );

        let worksheet = workbook.addWorksheet( 'Sheet1' );

        const headers = this.headers.map( ( item: any ) => item.toString().toUpperCase() );

        worksheet.addRow( headers );

        data.forEach( ( d: any ) => {

            worksheet.addRow( Object.values( d ) );

        } );

        var currentDate = new Date();
        var formattedDate = currentDate.toLocaleDateString( 'en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        } ).replace( /[/]/g, '-' ).replace( /[,]/g, '_' );

        const fileNameWithTime = fileName + '_' + formattedDate + '.xlsx';

        workbook.xlsx.writeBuffer().then( ( buffer: any ) => {
            const blob = new Blob( [ buffer ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } );
            saveAs( blob, fileNameWithTime );
        } );
    }

    formatDataForExcel ( rows: Row[] ) {

        this.getHeaders( rows );

        return rows.map( ( row: Row ) => {

            const res: Row = {};

            // this loop ensure all rows have same column count
            for ( let i = 0; i < this.headers.length; i++ ) {

                const column = this.headers[ i ];

                let value = row[ column ];

                if ( value === null || value == '' || value === undefined ) {
                    value = "-";
                }

                if ( typeof ( value ) === 'string' && !column.toUpperCase().includes( "URL" ) && !this.columnsNotToConvertToUpperCase.includes( column ) ) {
                    value = value?.toString().toUpperCase();
                }

                res[ column ] = value;
            }

            return res;

        } );

    }

    getHeaders ( rows: any ): void {

        let max = -Infinity;
        for ( let i = 0; i < rows.length; i++ ) {
            const itemLenght = Object.keys( rows[ i ] ).length
            if ( itemLenght > max ) {
                max = itemLenght
                this.headers = Object.keys( rows[ i ] );
            }
        }

    }

    updateWorkBookInfo ( workbook: Workbook ) {

        workbook.creator = "Instaveritas";
        workbook.lastModifiedBy = "Instaveritas";
        workbook.created = new Date();
        workbook.modified = new Date();

    }

}
