<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>

        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">

                    <div class="col-md-2 form-group">
                        <label class="center-block"> Candidate ID</label>
                        <input class="form-control" formControlName="employee_id" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Track ID</label>
                        <input class="form-control" formControlName="track_id" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Candidate Name</label>
                        <input class="form-control" formControlName="employee_name" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Mobile Number</label>
                        <input class="form-control" formControlName="mobile_number" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Personal Email</label>
                        <input class="form-control" formControlName="email" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Official Email</label>
                        <input class="form-control" formControlName="official_email" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block">Completed</label>
                        <select class="form-control" formControlName="completed">
                            <option [value]="'all'">All</option>
                            <option [value]="1">Yes</option>
                            <option [value]="0">No</option>
                        </select>
                    </div>
                    <div *ngIf="companies" class="form-group col-md-2">
                        <label class="center-block"> Company</label>
                        <app-multi-select [options]="companies" [placeholder]="'Select Company'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"
                            (onDropDownClosed)="onCompanyDropDownClosed()"></app-multi-select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Employee Final Status</label>
                        <app-multi-select [options]="statuses['employee']" [placeholder]="'Select status'"
                            [controlName]="'employee_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Identity Verification Status</label>
                        <app-multi-select [options]="statuses['identity']" [placeholder]="'Select status'"
                            [controlName]="'identity_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Current Address Verification Status</label>
                        <app-multi-select [options]="statuses['address']" [placeholder]="'Select status'"
                            [controlName]="'current_address_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                </div>
                <div class="row">
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Permanent Address Verification Status</label>
                        <app-multi-select [options]="statuses['address']" [placeholder]="'Select status'"
                            [controlName]="'permanent_address_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">ICR Verification Status</label>
                        <app-multi-select [options]="statuses['icr']" [placeholder]="'Select status'"
                            [controlName]="'icr_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Education Verification Status</label>
                        <app-multi-select [options]="statuses['education']" [placeholder]="'Select status'"
                            [controlName]="'education_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Employment Verification Status</label>
                        <app-multi-select [options]="statuses['employment']" [placeholder]="'Select status'"
                            [controlName]="'employment_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>

                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Reference Verification Status</label>
                        <app-multi-select [options]="statuses['reference']" [placeholder]="'Select status'"
                            [controlName]="'reference_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">PCC Verification Status</label>
                        <app-multi-select [options]="statuses['pcc']" [placeholder]="'Select status'"
                            [controlName]="'pcc_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                </div>
                <div class="row">
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Global Database Verification Status</label>
                        <app-multi-select [options]="statuses['globaldb']" [placeholder]="'Select status'"
                            [controlName]="'global_database_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group">
                        <label class="center-block">Document Verification Status</label>
                        <app-multi-select [options]="statuses['document']" [placeholder]="'Select status'"
                            [controlName]="'document_status_id'" [formGName]="filterFormGroup"></app-multi-select>

                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Creation Date</label>
                        <input class="form-control" type="date" formControlName="created_after"/>
                        <input class="form-control" type="date" formControlName="created_before"/>
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Completed Before</label>
                        <input class="form-control" type="date" formControlName="completed_before"/>
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Completed After</label>
                        <input class="form-control" type="date" formControlName="completed_after"/>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <!-- <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Download Excel
                        </button>
                    </div> -->
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
