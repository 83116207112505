import { Injectable } from '@angular/core';


export const VerificationDocumentTypeArray = [
    {
        verification: 'identity',
        key: 'front',
        value: 'front',
    },
    {
        verification: 'identity',
        key: 'back',
        value: 'back',
    },
    {
        verification: 'identity',
        key: 'proof',
        value: 'Proof',
    },
    {
        verification: 'current_address',
        key: 'attachment',
        value: 'Attachment',
    },
    {
        verification: 'current_address',
        key: 'proof',
        value: 'Proof',
    },
    {
        verification: 'permanent_address',
        key: 'attachment',
        value: 'Attachment',
    },
    {
        verification: 'permanent_address',
        key: 'proof',
        value: 'Proof',
    },
    {
        verification: 'icr',
        key: 'proof',
        value: 'Proof',
    },
    {
        verification: 'icr',
        key: 'ecourt',
        value: 'Ecourt',
    },
    {
        verification: 'icr',
        key: 'fir',
        value: 'FIR',
    },
    {
        verification: 'icr.case',
        key: 'proof',
        value: 'Proof',
    },
    {
        verification: 'icr.case',
        key: 'ecourt',
        value: 'Ecourt',
    },
    {
        verification: 'icr.case',
        key: 'fir',
        value: 'FIR',
    },
    {
        verification: 'pcc',
        key: 'payment_screenshot',
        value: 'Payment screenshot',
    },
    {
        verification: 'pcc',
        key: 'application_screenshot',
        value: 'Application screenshot',
    },
    {
        verification: 'pcc',
        key: 'pcc_report',
        value: 'Pcc Report',
    },
    {
        verification: 'pcc',
        key: 'attachment',
        value: 'Attachment',
    },
    {
        verification: 'pcc',
        key: 'signature',
        value: 'Signature',
    },
    {
        verification: 'pcc',
        key: 'photo',
        value: 'Photo',
    },
    {
        verification: 'education',
        key: 'degree',
        value: 'Degree',
    },
    {
        verification: 'education',
        key: 'marksheet',
        value: 'Marksheet',
    },
    {
        verification: 'education',
        key: 'payment',
        value: 'Payment',
    },
    {
        verification: 'education',
        key: 'proof',
        value: 'Proof',
    },
    {
        verification: 'employment',
        key: 'payment',
        value: 'Payment',
    },
    {
        verification: 'employment',
        key: 'proof_url',
        value: 'Proof Url',
    },
    {
        verification: 'employment',
        key: 'document',
        value: 'Document',
    },
    {
        verification: 'employment',
        key: 'attachment',
        value: 'Attachment',
    },
    {
        verification: 'employment',
        key: 'screenshot',
        value: 'Screenshot',
    },
    {
        verification: 'reference',
        key: 'attachment',
        value: 'Attachment',
    },
    {
        verification: 'reference',
        key: 'proof',
        value: 'Proof',
    },
    {
        verification: 'document',
        key: 'document',
        value: 'Document',
    },
    {
        verification: 'document',
        key: 'proof',
        value: 'Proof',
    },
];

@Injectable( {
    providedIn: 'root'
} )
export class BuildFilesUploadService {

    getAllVerificationDocumentsArray (): Promise<any[]> {
        return Promise.resolve( VerificationDocumentTypeArray );
    }
}
