import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Institute } from 'src/app/core/models/institute.model';
import { EmploymentCompanyService } from 'src/app/core/services/employment-company.service';
import { FilterConfigService } from 'src/app/core/services/filter-config.service';
import { InstituteService } from 'src/app/core/services/institute.service';

import { CommonService } from '../../core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { Company } from 'src/app/core/models/company.model';

@Component( {
    selector: 'app-verification-filters',
    templateUrl: './verification-filters.component.html',
    styles: [ `
      .rightt-align {
        float: right
      }
    `],
} )
export class VerificationFiltersComponent implements OnInit, OnChanges {

    // Which verification is in use here
    @Input()
    verification!: any;
    // Comma-separated list of filters that need to be applied

    @Input() filterBy: any;
    @Input()
    filterParams!: any;
    @Input() sendRequest: boolean = true;

    // image url, image_type, verification id
    // Event emitter to communicate with other components
    @Output() filtered: EventEmitter<string> = new EventEmitter();
    @Output() filtersChanged: EventEmitter<any> = new EventEmitter();

    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    // Array of filters that need to applied
    // eg [company,state,district]
    filters: any[] = [];
    addFilters: any[] = [];
    // statuses: any[] = [];
    lots: any[] = [];
    types: any[] = [];
    updatedFilters: string[] = [];


    @Input()
    vendors!: any[];

    constructor (
        private router: Router,
        public globalService: GlobalService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private filterConfigService: FilterConfigService,
        private instituteService: InstituteService,
        private employmentCompanyService: EmploymentCompanyService
    ) {
        this.initForm();
    }

    get institutes (): Institute[] {
        return this.instituteService.institutes;
    }

    get employmentCompanies () {
        return this.employmentCompanyService.companies;
    }

    get companies (): Company[] {
        return this.globalService.fetchCompanies();
    }

    get states () {
        return this.globalService.fetchStates();
    }

    get users () {
        return this.globalService.fetchUsers();
    }

    get districts () {
        return this.globalService.fetchDistricts();
    }

    get methodology () {
        return this.globalService.fettchMethodology();
    }

    get statuses () { return this.globalService.fetchStatuses() }

    get workTypes () { return this.globalService.fetchWorkTypes(); }

    get identityTypes () { return this.globalService.fetchIdentityDocumentTypes(); }

    get documentTypes () { return this.globalService.fetchDocumentTypes(); }

    ngOnInit (): void {

        if ( this.verification === 'employment' ) {
            this.employmentCompanyService.populateCache();
        }

        if ( this.verification === 'education' ) {

            this.instituteService.populateCache();
        }

        //    Split the filterBy sting into any array
        //  eg 'identity,address' into ['identity', 'address']
        this.updatedFilters = this.filters = this.filterBy.split( ',' );

        const storedFilters: Array<{}> = localStorage.getItem( 'filters' )
            ? this.filterConfigService.getFilters( JSON.parse( localStorage.getItem( 'filters' )! ), this.verification )
            : null;

        if ( storedFilters !== null ) {
            this.filters = storedFilters;
        }

        this.setDefault();

    }


    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            company_id: 'all',
            lot_id: [ [] ],
            completed: 'all',
            name: 'all',
            state_id: 'all',
            state_name: '',
            district_id: 'all',
            district_name: '',
            status_id: [ [] ],
            pincode: 'all',
            created_before: 'all',
            created_after: 'all',
            submitted_after: 'all',
            submitted_before: 'all',
            sent_to_vendor_after: 'all',
            sent_to_vendor_before: 'all',
            email_sent_after: 'all',
            email_sent_before: 'all',
            initiated_after: 'all',
            initiated_before: 'all',
            completion_date_from: 'all',
            completion_date_to: 'all',
            type: 'all',
            assigned_to: 'all',
            type_of_doc: 'all',
            created_time_before: 'all',
            created_time_after: 'all',
            icr_verification_status: [ [] ],
            address_verification_status: [ [] ],
            address_verification_sent_to_vendor_after: [ [] ],
            address_verification_sent_to_vendor_before: [ [] ],
            vendor_id: [ [] ],
            vendor_name: '',
            employment_company_id: 'all',
            employment_company_name: '',
            institute_id: 'all',
            institute_name: '',
            employment_type_not_in: '',
            india_post_id: [ [] ],
            employee_name: 'all',
            proof_url: 'all',
            method_id: [ [] ],
            method_name: '',
            type_of_address: 'all',
            employee_id: 'all',
            track_id: 'all',
            email: 'all',
            reference_type: 'all',
            dov_present: 'all',
            sent_to_vendor_date_present: 'all',
            applied_date_present: 'all',
            qa_present: 'all',
            qa_required: 'all',
            qa_done: 'all',
            cnr_details: 'all',
            work_completed: 'all',
            assigned_after: 'all',
            assigned_before: 'all',
            attachments: 'all',
            fir_judgement_order: 'all',
            work_type: 'all',
            assignment_count: 'all',
            applied_at_before: 'all',
            applied_at_after: 'all',
            consent_present: 'all',
            has_contact: 'all',
            initiated_present: 'all',
            submission_done: 'all',
            verification_mode: 'all',
            property_type: 'all',
            type_of_document: 'all',
        } );
    }

    filterResults (): void {
        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };
        // Get the current url string & split it into 2 part by '?'
        // Get only the first part which is the path
        // eg. 'identity?xyx=abc' in url will return only 'identity'
        const currentUrlPath: string = this.router.url.split( '?' )[ 0 ];
        // Navigate to current path along with our set query string
        // This will add query params to the urls
        if ( this.sendRequest ) this.router.navigate( [ currentUrlPath ], navigationExtras );

        this.filtersChanged.emit( navigationExtras );

    }

    ngOnChanges (): void {
        this.filterFormGroup.controls[ 'company_id' ].valueChanges.subscribe( () => {
            this.onCompanySelected();
        } );
    }

    onCompanySelected (): void {
        const companyId: number = this.filterFormGroup.controls[ 'company_id' ].value;

        if ( companyId == null ) {
            this.filterFormGroup.get( 'lot_id' )?.reset();
            this.lots = [];
            return;
        };
        // Get the lots for the selected company in dropdown
        this.commonService.getLotsByCompanyId( companyId ).subscribe(
            response => {
                response.push( {
                    id: 'all',
                    name: 'All',
                } );
                this.lots = response;
            }
        );
        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        this.filterFormGroup.controls[ 'lot_id' ].setValue( 'all' );
    }

    onLotSelected (): void {
    }


    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    // Set the value of the form control state_id
    OnStateSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'state_id' ].setValue( event.item.id );
        // Setting the default value on any change is selection of state.
        // Setting the default value on any change is selection of state.
        this.filterFormGroup.controls[ 'district_name' ].setValue( '' );
    }

    // Set the value of the form control district_id
    OnDistrictSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'district_id' ].setValue( event.item.id );
    }

    // Set the value of the form control vendor_id
    OnVendorSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'vendor_id' ].setValue( event.item.id );
    }

    // Set the value of the form control employment_company_id
    OnEmploymentCompanySelect ( event: any ): void {

        this.filterFormGroup.controls[ 'employment_company_id' ].setValue( event.item.id );
    }

    // Set the value of the form control district_id
    OnInstituteSelect ( event: any ): void {

        this.filterFormGroup.controls[ 'institute_id' ].setValue( event.item.id );
    }

    // Set the value of the form control district_id
    OnMethodologySelect ( event: any ): void {

        this.filterFormGroup.controls[ 'method_id' ].setValue( event.item.id );
    }

    filterUpdate ( event: string[] ): void {
        if ( event.length > 0 ) {
            this.filters = event;
        } else {
            this.filters = this.updatedFilters;
        }
    }

    private setDefault (): void {

        this.filterParams.keys().forEach( ( key: string ) => {

            const value: any = this.filterParams.get( key );

            const multipleValueFilter = [ 'status_id', 'lot_id', 'vendor_id' ];

            if ( value !== '' && value !== 'all' ) {

                if ( key === 'company_id' ) {
                    this.filterFormGroup.get( key )?.setValue( parseInt( value ) );
                } else if ( multipleValueFilter.includes( key ) ) {

                    this.filterFormGroup.get( key )?.setValue( value.split( ',' ).map( ( x: string | number ) => +x ) );

                }
                else if ( key === 'page' ) {
                    this.filterFormGroup.patchValue( { key: value } );
                } else {
                    this.filterFormGroup.get( key )?.setValue( value );
                }
            }
        } );
    }
}
