import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PermitService } from '../../core/services/permit.service';
import { VahanPermitStats } from 'src/app/core/interfaces/permit-logs/vahan-stats';

@Component( {
    selector: 'app-permit-stats',
    templateUrl: './permit-stats.component.html',
    styleUrls: [ './permit-stats.component.css' ],
} )
export class PermitStatsComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    smsStats!: any[];
    vahanStats!: VahanPermitStats;
    stateStats!: any[];
    parivahanStats!: any[];

    constructor ( private route: ActivatedRoute,
        private permitService: PermitService ) {
    }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterStats();
        } );
    }

    filterStats (): void {
        if ( this.filterParams.keys().length ) {
            this.permitService.getStats( this.filterParams ).subscribe(
                response => {
                    this.smsStats = response[ 'sms' ];
                    this.vahanStats = response[ 'vahan' ];
                    this.stateStats = response[ 'state' ];
                    this.parivahanStats = response[ 'parivahan' ];
                }
            );
        }
    }


}
