import { BsModalService } from 'ngx-bootstrap/modal';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';
import { IcrVerification } from '../models/icr-verification.model';
import { Pagination } from '../models/pagination.model';
import { IcrAct } from '../models/icr-act.model';
import { Employee } from '../models/employee.model';
import { HttpParams } from '@angular/common/http';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';
import { Params } from '@angular/router';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';

@Injectable( {
    providedIn: 'root'
} )
export class IcrService {

    constructor (
        private api: ApiService,
        private modalService: BsModalService
    ) { }

    submitToVcourt ( body: Object | undefined ): Observable<any[]> {
        return this.api.post( 'icr/submit-to-vcourt', body );
    }

    getAll (): Observable<Pagination> {
        return this.api.get( 'icr/' );
    }

    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'icr', params );
    }

    getEmployees ( params: HttpParams = new HttpParams() ): Observable<Pagination> {
        return this.api.get( 'icr/employees', params );
    }

    find ( id: number ): Observable<IcrVerification> {
        return this.api.get( 'icr/' + id );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'icr/' + id );
    }

    create ( id: number ): Observable<Employee> {
        return this.api.get( 'icr/' + id + '/add' );
    }

    update ( id: string | number, data: Object | undefined, filters: string ): Observable<IcrVerification> {
        return this.api.put( 'icr/' + id + '?' + filters, data );
    }

    getAllActs (): Observable<any[]> {
        return this.api.get( 'icr/all-acts' );
    }

    getAllSections (): Observable<any[]> {
        return this.api.get( 'icr/all-sections' );
    }

    createAct ( data: string | Object | undefined ): Observable<IcrAct> {
        return this.api.post( 'icr/act', data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'icr/' + id );
    }

    getAssignedWorkList ( filterParams: HttpParams ): Observable<any[]> {
        return this.api.get( 'icr/assigned-work', filterParams );
    }

    getDataForOlaExcel ( filterParams: HttpParams ): void {

        const initialState: any = {
            filterParams: filterParams,
            paramsString: filterParams.toString(),
            route: 'icr/ola-excel',
            fileName: 'CRC-Excel',
            method: 'get',
            postParams: null,
        };

        this.modalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } );
    }

    searchCnr ( filterParams: HttpParams ) {
        return this.api.get( 'icr/cnr-search', filterParams );
    }

    getVcourtCaseResultsDetails ( vcourt_case_id: string | number ) {
        return this.api.get( 'vcourt-results/' + vcourt_case_id + '/status' );
    }

    getCnrDetails ( cnr_number: string ) {
        return this.api.getCnrDetails( 'cnr/' + cnr_number );
    }

    parseEcourt () {
        return this.api.get( 'icr/parse-ecourt' );
    }

    createCaseDetails ( params: Object | undefined ) {
        return this.api.post( 'icr/cases', params );
    }

    updateCase ( caseId: any, params: Object | undefined ) {
        return this.api.put( `icr/cases/${ caseId }`, params );
    }

    deleteCaseDetails ( caseDetailId: number ) {
        return this.api.delete( `icr/cases/${ caseDetailId }` );
    }
}
