import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiRequestLogsListComponent } from './api-request-logs-list/api-request-logs-list.component';
import { RequestModalComponent } from './request-modal/request-modal.component';
import { ResponseModalComponent } from './response-modal/response-modal.component';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { ClientApiService } from 'src/app/core/services/client-api.service';
import { SharedModule } from 'src/app/core/shared.module';

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
    ],
    declarations: [
        ApiRequestLogsListComponent,
        RequestModalComponent,
        ResponseModalComponent,
    ],
    providers: [ ClientApiService, BsModalRef ],
    entryComponents: [
        ResponseModalComponent,
        RequestModalComponent,
    ],
} )

export class ApiRequestLogsListModule { }
