<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h1">Remarks Logs</h1>
    </div>
</div>

<app-remarks-filter [filterParams]="filterParams"></app-remarks-filter>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 20%">ID</th>
                        <th style="width: 10%">Track ID</th>
                        <th style="width: 10%">Remark</th>
                        <th style="width: 10%">User Name</th>
                        <th style="width: 40%">Timeline</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let log of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    }
            ">
                        <td>
                            {{log.id}}
                        </td>

                        <td>
                            {{log.track_id}}
                        </td>

                        <td>
                            {{log.text}}
                        </td>

                        <td>
                            {{log.name}}
                        </td>

                        <td>
                            Created At: {{log.created_at}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>