import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-aadhaar-response-modal',
    templateUrl: './aadhaar-response-modal.component.html',
    styleUrls: [ './aadhaar-response-modal.component.css' ],
} )
export class AadhaarResponseModalComponent implements OnInit {

    @ViewChild( 'aadhaarResponseModal', { static: false } )
    public aadhaarResponseModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();

    public response: any;
    public source!: string;
    constructor () {
        //
    }

    ngOnInit () {
        //
    }

    show ( source: string, response: string ): void {
        this.source = source;
        this.setResponse( response );
        this.aadhaarResponseModal.show();
    }

    setResponse ( response: string ): void {
        try {
            this.response = JSON.parse( response );
        } catch ( error ) {
            this.response = response;
        }
    }

    hide (): void {
        this.aadhaarResponseModal.hide();
    }

}
