<div bsModal #instituteCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Create Institute</h4>

            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Institute Name
                            <input [(ngModel)]="name" class="form-control" required>
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Address
                            <input [(ngModel)]="address" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label> Select State</label>
                        <select class="form-control" [(ngModel)]="state_id">
                            <option *ngFor="let state of states " [value]="state.id">
                                {{state.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 form-group">
                        <label> Select District</label>
                        <select class="form-control" [(ngModel)]="district_id">
                            <option *ngFor="let district of districts |filterByStateId:state_id" [value]="district.id">
                                {{district.name}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-3 form-group">
                        <label class="center-block"> Pincode
                            <input [(ngModel)]="pincode" class="form-control">
                        </label>
                    </div>

                    <div class="col-md-3 form-group">
                        <label class="center-block"> Beneficiary Name
                            <input [(ngModel)]="beneficiary_name" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Recipient Name
                            <input [(ngModel)]="recipient_name" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Contact Number
                            <input [(ngModel)]="number" class="form-control">
                        </label>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-3 form-group">
                        <label class="center-block">Email
                            <input [(ngModel)]="email" class="form-control" email>
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Website
                            <input [(ngModel)]="website" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Remarks
                            <textarea [(ngModel)]="remarks" class="form-control" rows="1"></textarea>
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Instructions
                            <textarea [(ngModel)]="instructions" class="form-control" rows="1"></textarea>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Create Contact</h4>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Name Of Person
                            <input [(ngModel)]="contact_name" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Designation
                            <input [(ngModel)]="contact_designation" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Primary Mail
                            <input [(ngModel)]="contact_primary_email" class="form-control" email>
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Secondary Mail
                            <input [(ngModel)]="contact_secondary_email" class="form-control" email>
                        </label>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-3 form-group">
                        <label class="center-block"> Number
                            <input [(ngModel)]="contact_number" class="form-control">
                        </label>
                    </div>

                    <div class="col-md-3 form-group">
                        <label class="center-block"> Location
                            <input [(ngModel)]="contact_location" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Division
                            <input [(ngModel)]="contact_division" class="form-control">
                        </label>
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block"> Reference Name
                            <input [(ngModel)]="contact_reference_name" class="form-control">
                        </label>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-3 form-group">
                        <label class="center-block"> Remarks
                            <textarea [(ngModel)]="contact_remarks" class="form-control" rows="1"></textarea>
                        </label>
                    </div>


                    <div class="col-md-3 form-group">
                        <label class="center-block"> Active
                            <input type="checkbox" [(ngModel)]="active">
                        </label>
                    </div>

                    <div class="col-md-3 form-group">
                        <label class="center-block"> Helpful
                            <input type="checkbox" [(ngModel)]="helpful">
                        </label>
                    </div>
                </div>

            </div>

            <div class="modal-footer">
                <div class="pull-right">
                    <a class="btn btn-default btn-primary" (click)="createInstitute()"> Create</a>
                </div>
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #subInstitute>

    <div class="modal-header">
        <h4 class="modal-title pull-left">Create Sub Institute</h4>
        <a type="button" class="close pull-right" aria-label="Close" (click)="hideSubInstitute()">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body">

        <div class="row">

            <div class="col-md-4 form-group">
                <label class="center-block">Select Institute</label>
                <select class="form-control" [(ngModel)]="institute_id">
                    <option *ngFor="let institute of institutes " [value]="institute?.id">
                        {{institute?.name}}
                    </option>
                </select>
            </div>

            <div class="col-md-4 form-group">
                <label class="center-block"> Institute Name
                    <input [(ngModel)]="name" class="form-control" required>
                </label>
            </div>
            <div class="col-md-8 form-group">
                <label class="center-block"> Address
                    <input [(ngModel)]="address" class="form-control">
                </label>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 form-group">
                <label> Select State</label>
                <select class="form-control" [(ngModel)]="state_id">
                    <option *ngFor="let state of states " [value]="state.id">
                        {{state.name}}
                    </option>
                </select>
            </div>
            <div class="col-md-4 form-group">
                <label> Select District</label>
                <select class="form-control" [(ngModel)]="district_id">
                    <option *ngFor="let district of districts |filterByStateId:state_id" [value]="district.id">
                        {{district.name}}
                    </option>
                </select>
            </div>
            <div class="col-md-4 form-group">
                <label class="center-block"> Pincode
                    <input [(ngModel)]="pincode" class="form-control">
                </label>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 form-group">
                <label class="center-block"> Beneficiary Name
                    <input [(ngModel)]="beneficiary_name" class="form-control">
                </label>
            </div>
            <div class="col-md-4 form-group">
                <label class="center-block"> Recipient Name
                    <input [(ngModel)]="recipient_name" class="form-control">
                </label>
            </div>
            <div class="col-md-4 form-group">
                <label class="center-block"> Contact Number
                    <input [(ngModel)]="number" class="form-control">
                </label>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 form-group">
                <label class="center-block">Email
                    <input [(ngModel)]="email" class="form-control">
                </label>
            </div>
            <div class="col-md-4 form-group">
                <label class="center-block">Type
                    <input [(ngModel)]="type" class="form-control">
                </label>
            </div>
            <div class="col-md-4 form-group">
                <label class="center-block"> Website
                    <input [(ngModel)]="website" class="form-control">
                </label>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-group">
                <label class="center-block"> Remarks
                    <textarea [(ngModel)]="remarks" class="form-control" rows="2"></textarea>
                </label>
            </div>
            <div class="col-md-6 form-group">
                <label class="center-block"> Instructions
                    <textarea [(ngModel)]="instructions" class="form-control" rows="2"></textarea>
                </label>
            </div>
        </div>

    </div>

    <div class="modal-header">
        <h4 class="modal-title pull-left">Create Contact</h4>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md-3 form-group">
                <label class="center-block"> Name Of Person
                    <input [(ngModel)]="contact_name" class="form-control">
                </label>
            </div>
            <div class="col-md-3 form-group">
                <label class="center-block"> Designation
                    <input [(ngModel)]="contact_designation" class="form-control">
                </label>
            </div>
            <div class="col-md-3 form-group">
                <label class="center-block"> Primary Mail
                    <input [(ngModel)]="contact_primary_email" class="form-control">
                </label>
            </div>
            <div class="col-md-3 form-group">
                <label class="center-block"> Secondary Mail
                    <input [(ngModel)]="contact_secondary_email" class="form-control">
                </label>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3 form-group">
                <label class="center-block"> Number
                    <input [(ngModel)]="contact_number" class="form-control">
                </label>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block"> Location
                    <input [(ngModel)]="contact_location" class="form-control">
                </label>
            </div>
            <div class="col-md-3 form-group">
                <label class="center-block"> Division
                    <input [(ngModel)]="contact_division" class="form-control">
                </label>
            </div>
            <div class="col-md-3 form-group">
                <label class="center-block"> Reference Name
                    <input [(ngModel)]="contact_reference_name" class="form-control">
                </label>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3 form-group">
                <label class="center-block"> Remarks
                    <textarea [(ngModel)]="contact_remarks" class="form-control" rows="1"></textarea>
                </label>
            </div>

            <div class="col-md-3 form-group">
                <input type="checkbox" [ngModel]="active"> Active
            </div>
        </div>

    </div>


    <div class="modal-footer">
        <div class="pull-right">
            <a class="btn btn-default btn-primary" (click)="createSubInstitute()"> Create SubInstitute</a>
        </div>
        <div class="pull-left">
            <a class="btn btn-default btn-danger" (click)="hideSubInstitute()"> Cancel</a>
        </div>
    </div>
</ng-template>