import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { ReferenceList, Reference } from '../client-forms-v2.enum'

@Component( {
    selector: 'app-reference-form',
    templateUrl: './reference-form.component.html',
} )
export class ReferenceFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    selectedOption: string[] = [];

    isSectionChecked: boolean = false;

    referenceList = ReferenceList;

    maxLimit: number = 5;

    referenceForm = this.fb.group( {
        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] ),
    } )

    get referenceArray (): FormArray | any {
        return this.referenceForm.get( 'verifications' ) as FormArray;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.referenceArray.length > 0;
    }

    constructor ( private fb: FormBuilder, private commonService: CommonService ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'reference', this.referenceForm );
    }

    formSettingItem (): FormGroup {

        return this.fb.group( {
            type: this.fb.control( 'any', [ Validators.required ] ),
            required: this.fb.control( true, [ Validators.required ] ),
        } );

    }

    addReference (): void {
        if ( this.referenceArray.length >= this.maxLimit ) {
            this.commonService.notifications.error( 'Reference', `Reference max limit is ${ this.maxLimit }` );
        } else {
            this.referenceArray.push( this.formSettingItem() );
        }
    }

    sectionToggle ( $event: any ): void {
        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {
            this.referenceArray.clear();
        }
    }

    skipSelectedItem ( index: any, $event: any ): void {
        this.selectedOption[ index ] = $event.target.value;
    }

}
