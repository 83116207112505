import { Component, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthorityService } from '../../core/services/authority.service';
import { CommonService } from '../../core/services/common.service';
import { Authority } from './../../core/models/authority.model';

@Component( {
    selector: 'app-authority-create-contact',
    templateUrl: './authority-create-contact.component.html',
    styleUrls: [ './authority-create-contact.component.css' ],
} )
export class AuthorityCreateContactComponent implements OnInit {

    data!: {};
    id: number = 0;
    name: string = '';
    designation: string = '';
    email: string = '';
    phone_number: string = '';
    remarks: string = '';
    contactForm!: FormGroup;

    @ViewChild( 'authorityCreateContactModal', { static: false } )
    public authorityCreateContactModal!: ModalDirective;

    @Output()
    onContactCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private authorityService: AuthorityService,
        private commonService: CommonService,
        private fb: FormBuilder,
    ) { }

    ngOnInit (): void {

        this.initForm();
    }


    initForm (): void {

        this.contactForm = this.fb.group( {
            id: [ '0' ],
            name: [ '' ],
            designation: [ '' ],
            email: [ '' ],
            phone_number: [ '' ],
            remarks: [ '' ],
        } );
    }

    show ( authority: Authority ): void {

        this.id = authority.id;

        this.contactForm.get( 'id' )?.setValue( this.id );

        this.authorityCreateContactModal.show();
    }

    hide (): void {
        this.initForm();
        this.authorityCreateContactModal.hide();
    }

    createContact (): void {

        this.authorityService.createAuthorityContact( this.id, this.contactForm.value ).subscribe(
            response => {

                this.commonService.notifications.success( 'Success', 'Contact Created' );
                this.hide();
                this.onContactCreated.emit( true );
            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to create Contact' );
                this.hide();
            }
        );
    }
}
