<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Address Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <!-- <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button> -->
        </div>
    </div>
</div>

<app-verification-filters verification="address" [sendRequest]="false" [filterParams]="filterParams" [vendors]="vendors"
    (filtersChanged)="onFiltersChanged($event)"
    filterBy="company,lot,verification_status,state,district,pincode,employee_name,created_after,created_before,completion_date_from,completion_date_to,assigned_to,vendor,sent_to_vendor,india_post_id,type_of_address,sent_to_vendor_date_present,work_completed,work_assignment,proof_url,attachments,qa_required,qa_done">
</app-verification-filters>