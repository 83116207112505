import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICandidateDocument, IDocumentAction } from '../../core/models/candidate-document.modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { IEmployeeDocumentResponse } from 'src/app/core/models/employee-document-attachment.modal';
import { Employee } from 'src/app/core/models/employee.model';
import { CandidateDocumentTransformService } from 'src/app/core/services/candidate-document-transform.service';
import { CandidateDocumentService } from 'src/app/core/services/candidate-document.service';
import { CommonService } from 'src/app/core/services/common.service';
import { EmployeeService } from 'src/app/core/services/employee.service';


@Component( {
    selector: 'app-candidate-documents-page',
    templateUrl: './candidate-documents-page.component.html',
    styleUrls: [ './candidate-documents-page.component.css' ],
    providers: [ CandidateDocumentService, CandidateDocumentTransformService ],
} )
export class CandidateDocumentsPageComponent implements OnInit {

    @ViewChild( 'documentActionModal', { static: false } ) documentActionModal!: ModalDirective;

    acceptFileOnly: string = '.png,.jpg,.jpeg,.pdf,.psd';

    completedFiles: number = 0;

    base64Images: string[] = [];

    selectedFiles!: FileList | any;

    maxFileLimit: number = 10;

    pdfFileSrc: string[] = [];

    employeeId!: number | string;

    employee!: Employee;

    documents: Document[] = [];

    documentAction!: IDocumentAction;

    constructor (
        private commonService: CommonService,
        private route: ActivatedRoute,
        private employeeService: EmployeeService,
        private documentService: CandidateDocumentService,
        private documentTransformService: CandidateDocumentTransformService,
    ) { }

    get documentFormGroup (): FormGroup {

        return this.documentService.documentFormGroup;

    }

    get documentFormArray (): FormArray {

        return this.documentFormGroup.get( 'documentFormArray' ) as FormArray;

    }

    get verificationList (): ICandidateDocument[] {

        return this.documentService.documents;

    }

    isExceedMaxFileLimit ( files: FileList ): boolean {

        if ( files.length > this.maxFileLimit ) {

            return true;
        }

        return false;
    }

    isValidFileExtention ( files: FileList ): boolean {

        for ( let fileIndex = 0; fileIndex < files.length; fileIndex++ ) {

            const file = files[ fileIndex ] as File;

            const fileName = file.name;

            const extension = fileName.substring( fileName.lastIndexOf( '.' ) + 1 );

            if ( this.acceptFileOnly.indexOf( extension ) > -1 ) {

                return false;

            }
        }

        return true;
    }

    handleReaderLoaded ( e: any ): void {

        this.documentService.addEmptyDocument( e.target.result, null, 'Not Uploaded' );

        this.base64Images.push( e.target.result );
    }

    imageToBase64 ( file: File ): void {

        const reader = new FileReader();

        reader.readAsDataURL( file );

        reader.onload = this.handleReaderLoaded.bind( this );

        reader.onerror = function ( error ): void {

        };

    }

    convertFileToBase64 ( files: FileList ): void {

        for ( let fileIndex = 0; fileIndex < files.length; fileIndex++ ) {

            const file = files[ fileIndex ] as File;

            const fileName = files[ fileIndex ].name;

            const extension = fileName.substring( fileName.lastIndexOf( '.' ) + 1 ).toLowerCase();

            switch ( extension ) {
                case 'png':
                case 'jpeg':
                case 'jpg':
                    this.imageToBase64( file );
                    break;
                case 'pdf':
                    this.pdfToBase64( file );
                    break;
            }

            this.completedFiles++;
        }
    }

    pdfToBase64 ( file: File ): void {

        const reader = new FileReader();

        reader.onload = ( e: any ): void => {
            this.pdfFileSrc.push( e.target.result );
        };

        reader.readAsArrayBuffer( file );

        reader.onerror = function ( error ): void {

        };

    }

    base64PngFromPage ( pdfDocument: PDFDocumentProxy, pageNumber: number, fileCount: number ): void {

        pdfDocument.getPage( pageNumber ).then( ( page: any ) => {

            const scale = 2.0;

            const viewport = page.getViewport( { scale } );

            const canvas = document.createElement( 'canvas' );

            const context = canvas.getContext( '2d' );

            canvas.width = viewport.width;

            canvas.height = viewport.height;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            page.render( renderContext ).promise.then( () => {

                const imageDataUrl = canvas.toDataURL( 'image/png' );

                const imageDetail = ( fileCount + 1 ) + '/' + ( page._pageIndex + 1 );

                this.documentService.addEmptyDocument( imageDataUrl, null, imageDetail );

                this.base64Images.push( imageDataUrl );

            } );

        } );
    }

    onLoadCompleted ( pdfDocument: PDFDocumentProxy, fileCount: number ): void {

        pdfDocument.getData().then( () => {

            for ( let page = 1; page <= pdfDocument.numPages; page++ ) {
                this.base64PngFromPage( pdfDocument, page, fileCount );
            }

            setTimeout( () => this.base64Images, 3000 );
        } )
    }

    uploadFile ( event: Event ): boolean {

        this.completedFiles = 0;

        this.base64Images = [];

        const imputElement = event.target as HTMLInputElement;

        this.selectedFiles = imputElement.files as FileList;

        if ( this.isExceedMaxFileLimit( this.selectedFiles ) ) {

            this.commonService.notifications.error( 'Error', 'Maximum 10 files are allowed.' );

            return false;
        }

        if ( this.isValidFileExtention( this.selectedFiles ) ) {

            this.commonService.notifications.error( 'Error', 'Only accept ' + this.acceptFileOnly );

            return false;
        }

        this.convertFileToBase64( this.selectedFiles );

        return true;

    }

    transformDocuments ( employeeDocumentResponse: IEmployeeDocumentResponse ): void {

        this.documentTransformService.employee( employeeDocumentResponse, this.employeeId );

        employeeDocumentResponse.verifications.identity_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.identityVerification( verification, verification.id, index );

        } );

        employeeDocumentResponse.verifications.address_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.addressVerification( verification, verification.id, index );

        } );

        employeeDocumentResponse.verifications.icr_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.icrVerification( verification, verification.id, index );

        } );

        employeeDocumentResponse.verifications.education_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.educationVerification( verification, verification.id, index );

        } );

        employeeDocumentResponse.verifications.employment_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.employmentVerification( verification, verification.id, index );

        } );

        employeeDocumentResponse.verifications.pcc_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.pccVerification( verification, verification.id, index );

        } );

        employeeDocumentResponse.verifications.document_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.documentVerification( verification, verification.id, index );

        } );

        employeeDocumentResponse.verifications.global_db_verifications.forEach( ( verification, index ) => {

            this.documentTransformService.globalVerification( verification, verification.id, index );

        } );

    }

    fetchDocuments (): void {

        this.employeeService.getAllDocuments( this.employee.id ).subscribe( ( employeeDocumentResponse: IEmployeeDocumentResponse ) => {

            this.transformDocuments( employeeDocumentResponse );

        } )

    }

    fetchEmployee (): void {

        if ( this.employeeId ) {

            this.employeeService.getDetails( this.employeeId ).subscribe( ( employee: Employee ) => {

                this.employee = employee;

                this.fetchDocuments();

            } );
        }

    }

    ngOnInit (): void {

        this.employeeId = Number( this.route?.snapshot?.paramMap?.get( 'id' ) );

        this.fetchEmployee();

    }

    onActionClick ( documentAction: IDocumentAction ): void {

        this.documentActionModal.show();

        this.documentAction = documentAction;

    }
}
