import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';

import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { EducationService } from '../../core/services/education.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-education-create-institute',
    templateUrl: './education-create-institute.component.html',
    styleUrls: [ './education-create-institute.component.css' ],
} )
export class EducationCreateInstituteComponent {

    // TODO: make a formgroup for contact and institute
    data!: {};
    name: string = '';
    address: string = '';
    state_id: number = 0;
    district_id: number = 0;
    pincode: string = '';
    instructions: string = '';
    remarks: string = '';
    website: string = '';
    type: string = '';
    email: string = '';
    recipient_name: string = '';
    number: string = '';
    beneficiary_name: string = '';
    contact_name: string = '';
    contact_designation: string = '';
    contact_primary_email: string = '';
    contact_secondary_email: string = '';
    contact_number: string = '';
    contact_location: string = '';
    contact_division: string = '';
    contact_reference_name: string = '';
    contact_remarks: string = '';
    institutes!: any[];
    institute_id: any = 0;
    active!: boolean;
    helpful!: boolean;


    @ViewChild( 'instituteCreateModal', { static: false } ) instituteCreateModal!: ModalDirective;

    @Output()
    onInstituteCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    subInstituteModal!: BsModalRef | any;

    constructor (
        private educationService: EducationService,
        private commonService: CommonService,
        public global: GlobalService,
        private modalService: BsModalService
    ) { }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    show (): void {
        this.instituteCreateModal.show();
    }

    hide (): void {
        this.initForm();
        this.instituteCreateModal.hide();
    }

    showSubInstitute ( template: TemplateRef<BsModalRef> ): void {

        this.commonService.getAllInstitutes().subscribe(
            response => this.institutes = response,
            () => this.commonService.notifications.error( 'Error occurred while getting Institutes' )
        );

        this.subInstituteModal = this.modalService.show( template );
    }

    hideSubInstitute (): void {
        this.initForm();
        this.subInstituteModal.hide();
        this.subInstituteModal = null;
    }

    /**
     * Common function to assign work to the selected user.
     */
    createInstitute (): void {

        this.data = this.getData();

        this.educationService.createInstitute( this.data ).subscribe(
            () => {

                this.commonService.notifications.success( 'Success', 'Institute Created' );

                this.initForm();

                // this.showSubInstitute(template);

                this.onInstituteCreated.emit( true );

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error', 'Unable to create Institute' );
                this.hide();
            }
        );
    }

    initForm (): void {
        this.name = '';
        this.address = '';
        this.state_id = 0;
        this.district_id = 0;
        this.pincode = '';
        this.instructions = '';
        this.remarks = '';
        this.website = '';
        this.type = '';
        this.email = '';
        this.recipient_name = '';
        this.number = '';
        this.beneficiary_name = '';
        this.contact_name = '';
        this.contact_designation = '';
        this.contact_primary_email = '';
        this.contact_secondary_email = '';
        this.contact_number = '';
        this.contact_location = '';
        this.contact_division = '';
        this.contact_reference_name = '';
        this.contact_remarks = '';
        this.active = false;
        this.helpful = false;
        this.institute_id = null;
    }

    createSubInstitute (): void {

        this.data = this.getData();

        this.educationService.createSubInstitute( this.data ).subscribe(
            () => {

                this.commonService.notifications.success( 'Success', 'Sub Institute Created' );

                this.initForm();

                this.hideSubInstitute();

                this.hide();

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error', 'Unable to create Sub Institute' );
                this.hide();
            }
        );
    }

    getData (): any {

        return {
            name: this.name,
            address: this.address,
            state_id: this.state_id,
            district_id: this.district_id,
            pincode: this.pincode,
            beneficiary_name: this.beneficiary_name,
            number: this.number,
            recipient_name: this.recipient_name,
            website: this.website,
            email: this.email,
            type: this.type,
            remarks: this.remarks,
            instruction: this.instructions,
            contact_name: this.contact_name,
            contact_designation: this.contact_designation,
            contact_primary_email: this.contact_primary_email,
            contact_secondary_email: this.contact_secondary_email,
            contact_number: this.contact_number,
            contact_location: this.contact_location,
            contact_division: this.contact_division,
            contact_reference_name: this.contact_reference_name,
            contact_remarks: this.contact_remarks,
            institute_id: this.institute_id,
            active: this.active,
            helpful: this.helpful,
        };
    }

}
