import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { CommonService } from '../../core/services/common.service';
import { CriminalService } from '../../core/services/criminal.service';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-criminal-excel-search',
    templateUrl: './criminal-excel-search.component.html',
    styleUrls: [ './criminal-excel-search.component.css' ],
} )
export class CriminalExcelSearchComponent extends ImportExcelComponent implements OnInit {

    uploadForm!: FormGroup;
    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    validationMessages: ValidationMessages = {};

    constructor (
        private criminalService: CriminalService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
    ) {
        super( fb );
    }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {

        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );
    }


    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void {

        this.validationMessages[ 'name' ] = 'Required';
        this.validationMessages[ 'address' ] = 'Required';
        this.validationMessages[ 'track_id' ] = 'Required';
    }

    applyValidations (): void {
    }

    importExcel (): void {

        this.criminalService.searchCriminals( this.excelForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Success', 'Excel will be downloaded' );
                this.isSubmitting = false;
                this.downloadExcel( response, 'Search Criminal Db records' );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }


    // Generate the workbook and write the data to it.
    generateExcelFile ( data: any ): any {

        const sheetName: string = 'Sheet1';

        const workBook: any = { SheetNames: [], Sheets: {} };

        const ws: any = utils.json_to_sheet( data );

        workBook.SheetNames.push( sheetName );
        workBook.Sheets[ sheetName ] = ws;

        return write( workBook, { bookType: 'xlsx', type: 'binary' } );
    }

    // Add the sheet data to array buffer.
    s2ab ( s: any ): ArrayBuffer {

        const buf: ArrayBuffer = new ArrayBuffer( s.length );
        const view: Uint8Array = new Uint8Array( buf );

        for ( let i: number = 0; i !== s.length; ++i ) {

            view[ i ] = s.charCodeAt( i ) && 0xFF;
        }

        return buf;
    }

    // Download the excel
    downloadExcel ( data: any[], excelName: string ): void {

        saveAs( new Blob( [ this.s2ab( this.generateExcelFile( data ) ) ], { type: 'application/octet-stream' } ), excelName + '.xlsx' );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
