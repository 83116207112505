<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Voter Id Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-voter-stats></app-voter-stats>

<app-voter-filters [filterParams]="filterParams"></app-voter-filters>

<app-voter-response #voterResponseModal></app-voter-response>

<app-voter-details-modal #voterDetailsModal></app-voter-details-modal>

<app-voter-search-modal #voterSearchModal (onSearch)="onSearch()"></app-voter-search-modal>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="col-md-3" style="width: 25%">Voter ID Number</th>
                        <th style="width: 25%">Status</th>
                        <th style="width: 25%">Timeline</th>
                        <th style="width: 25%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong><a
                                            (click)="voterDetailsModal.show(log.id)">{{log.voter_id_number}}</a></strong>
                                </li>
                                <li>
                                    {{log.company_name}}<br>
                                    {{log.id}}
                                </li>
                                <li>
                                    <a class="btn-link text-main text-semibold"
                                        href="/#/employee/{{log.employee_id}}/edit" target="_blank">{{ log.track_id }}
                                    </a>
                                </li>


                            </ul>
                        </td>

                        <td>
                            <br>
                            <div *ngIf="log.success; else failure">
                                <div *ngIf="log.has_details; else notFound">
                                    <div *ngIf="log.has_details == 1; else invalidCaptcha">
                                        <a (click)="voterResponseModal.show(log.response)"
                                            class="btn label-success">Found</a>
                                    </div>
                                </div>
                            </div>

                            <ng-template #notFound>
                                <a class="btn label-danger" (click)="voterResponseModal.show(log.response)">Not
                                    Found</a>
                            </ng-template>

                            <ng-template #invalidCaptcha>
                                <a class="btn label-warning" (click)="voterResponseModal.show(log.response)">Invalid
                                    Captcha</a>
                            </ng-template>

                            <ng-template #failure>
                                <a class="btn label-danger" (click)="voterResponseModal.show(log.response)">Failure</a>
                            </ng-template>


                        </td>

                        <td>
                            <br>
                            <ul class="list-unstyled">
                                <li>Created At: {{log.created_at}}</li>
                                <li>Updated At: {{log.updated_at}}</li>

                            </ul>
                        </td>

                        <td>
                            <br>
                            <a class="btn btn-primary btn-md" (click)="resend(log)">
                                <i class="fa fa-refresh"></i>
                                Resend
                            </a>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>