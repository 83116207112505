<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Insurance Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="refresh()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>
<app-loading-component [fetchingInProgress]="fetchingInProgress"></app-loading-component>

<app-insurance-stats></app-insurance-stats>

<app-insurance-filters [filterParams]="filterParams"></app-insurance-filters>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>

<app-insurance-details #insuranceDetails></app-insurance-details>
<app-insurance-html-response-modal #vehicleHtmlResponse></app-insurance-html-response-modal>
<app-insurance-response-modal #insuranceApiResponse></app-insurance-response-modal>


<div class="row" style="padding: 0px 0px 0px 35px">
    <a class="btn btn-primary btn-addon" (click)="resendAll('vahan')">
        <i class="fa fa-send"></i>
        Resend Vahan
    </a>
    <a class="btn btn-primary btn-addon" (click)="exportLogsExcel()">
        <i class="fa fa-download"></i>
        Download Logs
    </a>
    <a class="btn btn-primary btn-addon" routerLink="import">
        <i class="fa fa-download"></i>
        Import Excel
    </a>
</div>

<br>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 10%">Details</th>
                        <th style="width: 4%">Vahan</th>
                        <th style="width: 30%">Timeline</th>
                        <th style="width: 16%">Actions</th>
                        <th style="width: 16%">Webhook</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li><a class="btn-link text-main"
                                        (click)="insuranceDetails.show(log.rc_number)"><strong>{{log.rc_number}}</strong></a>
                                </li>
                                <li>{{log.client}}</li>
                                <li>{{log.id}}</li>
                            </ul>
                        </td>


                        <td>
                            <div *ngIf="log.vahan_log && log.vahan_received; else vahanNotReceived">
                                <div *ngIf="log.vahan_details; else vahanDetailsNotFound">
                                    <a class="btn btn-block label-success"
                                        (click)="vehicleHtmlResponse.show(log,'vahan')">Found</a>
                                </div>
                            </div>

                            <ng-template #vahanNotReceived>
                                <div *ngIf="log.vahan_log; else vahanLogNotCreated">
                                    <a class="btn btn-block label-danger"
                                        (click)="vehicleHtmlResponse.show(log,'vahan')">Failure</a>
                                </div>
                                <ul class="list-unstyled">
                                    <li>{{log.vahan_failure_reason}}</li>
                                </ul>
                            </ng-template>
                            <ng-template #vahanDetailsNotFound>
                                <a class="btn btn-block label-danger"
                                    (click)="vehicleHtmlResponse.show(log,'vahan')">Not
                                    Found</a>
                            </ng-template>

                            <ng-template #vahanLogNotCreated>
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </ng-template>
                        </td>



                        <td>
                            <ul class="list-unstyled">
                                <li>Received At:- {{log.created_at}}</li>
                                <li>Last Updated:- {{log.updated_at}}</li>

                            </ul>
                        </td>

                        <td>

                            <br>
                            <a class="btn-link blue-text" (click)="resend('vahan',log)"> Resend Vahan</a>

                        </td>
                        <td>

                            <a class="btn-link blue-text" (click)="insuranceApiResponse.show(log)">View Api Response</a>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>