import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-epfo-response-modal',
    templateUrl: './epfo-response-modal.component.html',
    styleUrls: [ './epfo-response-modal.component.css' ]
} )
export class EpfoResponseModalComponent implements OnInit {

    @ViewChild( 'epfoResponseModal', { static: false } )
    public epfoResponseModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();

    public response!: any;
    public source!: any;
    constructor () { }

    ngOnInit () {
    }

    show ( source: string, response: string ): void {
        this.source = source;
        this.setResponse( response );
        this.epfoResponseModal.show();
    }

    setResponse ( response: string ): void {
        this.response = JSON.parse( response );
    }

    hide (): void {
        this.epfoResponseModal.hide();
    }

}
