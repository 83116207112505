<form [formGroup]="editForm" (ngSubmit)="updateEmployee()">
    <div class="panel panel-default">
        <div class="panel-heading">
            Employee Basic Details
        </div>

        <div class="panel-body">
            <div class="row">
                <div class="form-group col-md-12">
                    <label class="center-block">Employee Name
                        <input class="form-control" ngModel="{{employee.name}}" formControlName="name">
                    </label>
                </div>
                <div class="form-group col-md-12">
                    <label class="center-block">Father Name
                        <input class="form-control" ngModel="{{employee.father_name}}" formControlName="father_name">
                    </label>
                </div>
                <div class="form-group col-md-12">
                    <label class="center-block">Mobile Number
                        <input class="form-control" ngModel="{{employee.mobile_number}}"
                            formControlName="mobile_number">
                    </label>
                </div>
                <div class="form-group col-md-12">
                    <label class="center-block">DOB
                        <input class="form-control" ngModel="{{employee.dob}}" formControlName="dob">
                    </label>
                </div>
                <div class="form-group col-md-12">
                    <label class="center-block">Client Employee ID
                        <input class="form-control" ngModel="{{employee.client_employee_id}}"
                            formControlName="client_employee_id">
                    </label>
                </div>
            </div>
            <button [disabled]="!editForm.valid" class="btn btn-block btn-primary">
                <i class="fa fa-refresh"></i>Update
            </button>
        </div>
    </div>
</form>