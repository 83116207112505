import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Errors } from 'src/app/core/models/errors.module';
import Status from 'src/app/core/models/status.model';
import { Verification } from 'src/app/core/models/verification.model';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { VerificationStatusService } from 'src/app/core/services/verification-status.service';
import { VerificationService } from 'src/app/core/services/verification.service';


@Component( {
    selector: 'app-verification-status-edit',
    templateUrl: './verification-status-edit.component.html',
    styleUrls: [ './verification-status-edit.component.css' ],
} )
export class VerificationStatusEditComponent implements OnInit {


    editStatusForm!: FormGroup;
    isSubmitting = false;
    errors: Errors = new Errors();
    verifications: Verification[] = [];
    allStatuses!: any[];
    @ViewChild( 'editStatus', { static: false } )
    public editModal!: ModalDirective;
    status!: Status;


    constructor (
        private verificationStatusService: VerificationStatusService,
        private verificationService: VerificationService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private router: Router,
        public global: GlobalService
    ) {
        this.initForm();
    }

    initForm () {
        this.editStatusForm = this.fb.group( {
            title: [ '', [ Validators.required ] ],
            type: [ '', [ Validators.required ] ],
            description: [ '', [ Validators.required ] ],
            client_title: [ '', [ Validators.required ] ],
            client_description: [ '', [ Validators.required ] ],
            filter_verification_status: [ '' ],
            verification: [ '' ],
            is_completed: [],
            insuff: [ '' ],
        } );
    }

    ngOnInit () {

        // Populate the list of all verifications.
        this.verificationService.getAllVerifications().then( verifications => this.verifications = verifications );

    }

    show ( status: Status ): void {

        this.status = status;

        this.editStatusForm.patchValue( status );

        this.editStatusForm.get( 'is_completed' )?.setValue( status.is_completed ? 'yes' : 'no' );

        this.editStatusForm.get( 'insuff' )?.setValue( status.insuff ? 'yes' : 'no' );

        this.editModal.show();

    }

    hide (): void {

        this.editModal.hide();

    }

    onVerificationTypeChanged (): void {

        this.allStatuses = [];

        let verification = this.editStatusForm.controls[ 'verification' ].value;

        if ( verification === 'current_address' || verification === 'permanent_address' ) {

            verification = 'address';

        }

        this.allStatuses = this.global.fetchStatuses()[ verification ];
    }

    submit () {

        this.isSubmitting = true;

        const data = this.editStatusForm.value;

        this.verificationStatusService.update( data, this.status ).subscribe( response => {

            if ( response[ 'success' ] ) {

                this.commonService.notifications.success( 'Successful', response[ 'message' ] );

            } else {

                this.commonService.notifications.error( 'Error', response[ 'message' ] );

            }

            this.hide();

        }, error => {

            this.commonService.displayValidationErrors( error.errors );

            this.isSubmitting = false;

        }, () => {

            this.isSubmitting = false;

        } );
    }


}
