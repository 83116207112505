import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { Employee } from '../models/employee.model';
import { EmploymentVerification } from '../models/employment-verification.model';
import { HttpParams } from '@angular/common/http';
import { EmploymentCompany } from '../models/employment-company.model';
import { ModalService } from './modal.service';
import { EmploymentFollowUpMailComponent } from 'src/app/employment/employment-follow-up-mail/employment-follow-up-mail.component';
import { EmploymentSendMailComponent } from 'src/app/employment/employment-send-mail/employment-send-mail.component';
import { EmploymentEmailModalInitialState } from '../models/employment-email-modal-initial-state';


interface ContactDetails {
    name: string;
    primary_email: string;
    secondary_email: string;
    designation: string;
    phone_number: string;
    active: string;
    helpful: string;
}

@Injectable( {
    providedIn: 'root'
} )
export class EmploymentService {


    constructor (
        private api: ApiService,
        private modalService: ModalService
    ) {
    }


    getAll (): Observable<Pagination> {
        return this.api.get( 'employment/' );
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'employment', params );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'employment/' + id );
    }

    find ( id: number ): Observable<EmploymentVerification> {
        return this.api.get( 'employment/' + id );
    }

    create ( id: number ): Observable<Employee> {
        return this.api.get( 'employment/' + id + '/add' );
    }

    update ( id: string | number, data: Object | undefined, params: string ): Observable<EmploymentVerification> {
        return this.api.put( 'employment/' + id + '?' + params, data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'employment/' + id );
    }

    getAllCompanies (): Observable<any[]> {
        return this.api.get( 'employment/all-companies' );
    }

    getPaginatedCompaniesList ( filterParams: Params ): Observable<Pagination> {
        return this.api.get( 'employment/paginated-companies', filterParams );
    }

    createCompany ( data: {} ): Observable<any[]> {
        return this.api.post( 'employment/company', data );
    }

    updateCompany ( id: string, data: string ): Observable<EmploymentCompany> {
        return this.api.put( 'employment/' + id + '?' + data );
    }

    getAssignedWorkList ( filterParams: Params ): Observable<any[]> {
        return this.api.get( 'employment/assigned-work', filterParams );
    }

    getContacts ( verificationId: number, id: number ): Observable<any[]> {
        return this.api.get( 'employment/company/' + id + '/contacts?verification_id=' + verificationId );
    }

    getHtml ( verificationId: number ): Observable<string> {
        return this.api.get( 'employment/' + verificationId + '/mailHtml' );
    }

    getFollowUpMailHtml ( verificationId: number ): Observable<string> {
        return this.api.get( 'employment/' + verificationId + '/follow-up-html' );
    }

    sendMail ( data: {} ): Observable<any[]> {
        return this.api.post( 'employment/sendMail', data );
    }

    sendFollowUpMail ( data: {} ): Observable<any[]> {
        return this.api.post( 'employment/send-follow-up-mail', data );
    }

    updateEmploymentCompany ( id: string, data: Object | undefined ): Observable<EmploymentCompany> {
        return this.api.put( 'employment/company/' + id, data );
    }

    sendMultipleFollowUpMail ( ids: number[] ): Observable<any[]> {
        return this.api.post( 'employment/send-multiple-follow-up-mails', ids )
    }

    getRevertFormDetails ( verificationId: string ): Observable<any> {
        return this.api.get( 'employment/' + verificationId + '/revert-form' );
    }

    updateRevertFormDetails ( id: string | number, data: Object | undefined ): Observable<any> {
        return this.api.put( 'employment/' + id + '/revert-form', data );
    }

    getEmailLogs ( id: number ): Observable<any[]> {
        return this.api.get( 'employment/email-logs/' + id );
    }

    upload ( verificationId: number, data: FormData ): Observable<any> {

        return this.api.upload( 'employment/' + verificationId + '/upload', data );
    }

    sendMultipleConsentMail ( ids: string[] ): Observable<{ failed_track_ids: string[], success: boolean }> {
        return this.api.post( 'employment/send-multiple-consent-mails', { ids: ids } )
    }

    sendConsentMail ( employeeId: number ): Observable<{ success: boolean }> {
        return this.api.post( 'employment/send-consent-mails', { employee_id: employeeId } )
    }

    getContactDetails ( employmentCompany: number ): Observable<ContactDetails[]> {
        return this.api.get( 'common/employment-company/' + employmentCompany + '/contacts' );
    }

    showSendEmailModal ( initialState: EmploymentEmailModalInitialState ) {

        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg'
        };

        return this.modalService.showModal( EmploymentSendMailComponent, config );
    }

    showSendFollowupEmailModal ( initialState: EmploymentEmailModalInitialState ) {

        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
            class: 'modal-lg'
        };

        return this.modalService.showModal( EmploymentFollowUpMailComponent, config );
    }
}

