import { ValidationService } from '../../core/services/validation.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';
import { JwtService } from 'src/app/core/services/jwt.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component( {
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
} )
export class ChangePasswordComponent implements OnInit {

    changePasswordForm: FormGroup = new FormGroup( {} );
    isSubmitting: boolean = false;
    userId!: number;

    get password () { return this.changePasswordForm.get( 'password' ) as FormControl }

    get confirmPassword () { return this.changePasswordForm.get( 'confirm_password' ) as FormControl }

    constructor (
        private commonService: CommonService,
        private userService: UserService,
        private fb: FormBuilder,
        private jwtService: JwtService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit (): void {

        this.userId = this.route.snapshot.queryParams[ 'user_id' ];

        this.initForm()
    }

    initForm () {
        this.changePasswordForm = this.fb.group( {
            password: [ null, [ Validators.required, ValidationService.hasLowercase, ValidationService.hasNumber, ValidationService.hasSpecialCharacter, ValidationService.hasUppercase, Validators.minLength( 6 ) ] ],
            confirm_password: [ null, [ Validators.required, this.comparePassword( 'password', 'confirm_password' ) ] ]
        } );
    }

    updatePassword () {
        this.userService.resetPassword( this.userId, this.changePasswordForm.value ).subscribe( {
            next: ( response: any ) => {

                this.commonService.notifications.success( 'Password Updated Successfully', response.message );

                this.jwtService.destroyToken();

                this.router.navigateByUrl( 'login' );

                this.changePasswordForm.reset();
            },
            error: ( error: any ) => {

            }
        } );
    }

    comparePassword ( fieldFirst: string, fieldSecond: string ): ValidatorFn {

        return ( control: AbstractControl ): ValidationErrors | null => {

            const isNotSame = this.changePasswordForm?.get( fieldFirst )?.value != this.changePasswordForm?.get( fieldSecond )?.value;

            return isNotSame ? { confirmPassword: { value: control.value } } : null;

        };

    }
}
