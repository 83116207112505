
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-country-typeahead',
    templateUrl: './country-typeahead.component.html'

} )
export class CountryTypeaheadComponent implements OnInit {

    @Input() formGroup!: FormGroup;
    @Input() control: string = 'country';

    @Input() labelName: string = 'Country';

    @Output() changed: EventEmitter<number> = new EventEmitter<number>();

    get controlId (): string { return this.control + '_id'; }

    get controlName (): string { return this.control + '_name'; }

    get countryId (): FormControl { return this.formGroup.get( this.controlId ) as FormControl; }

    get countryName (): FormControl { return this.formGroup.get( this.controlName ) as FormControl }

    get countryIdValue (): number { return this.formGroup.get( this.controlId )?.value; }

    get countryNameValue (): string { return this.formGroup.get( this.controlName )?.value; }


    get options () { return this.global.fetchCountries(); }

    constructor (
        public global: GlobalService,
        public common: CommonService,
    ) {
    }

    ngOnInit (): void {
    }

    onSelect ( $event: TypeaheadMatch ): void {

        this.formGroup.get( this.controlId )?.setValue( $event.item.id );
        this.formGroup.get( this.controlName )?.setValue( $event.item.name );

        this.changed.emit( this.countryIdValue );

    }

    onNoResults (): void {

        if ( this.countryNameValue == "" || this.countryNameValue == " " ) {

            this.formGroup.get( this.controlId )?.setValue( null );
            this.formGroup.get( this.controlName )?.setValue( null );

            this.changed.emit( this.countryIdValue );

        }
    }


}
