import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { Education, Educations } from '../client-forms-v2.enum'

@Component( {
    selector: 'app-education-form',
    templateUrl: './education-form.component.html',
} )
export class EducationFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    selectedOption: string[] = [];

    isSectionChecked: boolean = false;

    educationList = Educations;

    maxLimit: number = 5;

    educationForm = this.fb.group( {
        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] ),
    } )

    get educationVerificationArray (): FormArray | any {
        return this.educationForm.get( 'verifications' ) as FormArray;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.educationVerificationArray.length > 0;
    }

    constructor ( private fb: FormBuilder, private commonService: CommonService ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'education', this.educationForm );
    }

    formSettingItem (): FormGroup {

        return this.fb.group( {
            type: this.fb.control( 'any', [ Validators.required ] ),
            required: this.fb.control( true, [ Validators.required ] ),
        } );

    }

    addEducation (): void {
        if ( this.educationVerificationArray.length >= this.maxLimit ) {
            this.commonService.notifications.error( 'Education', `Education max limit is ${ this.maxLimit }` );
        } else {
            this.educationVerificationArray.push( this.formSettingItem() );
        }
    }

    sectionToggle ( $event: any ): void {
        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {
            this.educationVerificationArray.clear();
        }
    }

    skipSelectedItem ( index: any, $event: any ): void {
        this.selectedOption[ index ] = $event.target.value;
    }

}
