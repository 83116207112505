import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../core/services/user.service';


@Injectable( {
    providedIn: 'root'
} )
export class NoAuthGuard implements CanActivate {
    constructor ( private router: Router,
        private userService: UserService ) {
    }

    canActivate ( route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot ): boolean {

        // return this.userService.isAuthenticated.take(1).map(bool => !bool);
        return true;

    }
}
