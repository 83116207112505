import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { UserLevel } from 'src/app/core/models/user.model';

/**
 * Check if email is valid or not.
 *
 * @returns {ValidatorFn}
 */
export function validateUserLevelId ( blank = false ): ValidatorFn {

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        // for bulk update
        if ( blank || control.value == 'blank' ) return {};

        if ( !control.value ) return {};

        if ( Object.values( UserLevel ).includes( +control.value ) ) return {}


        return { 'validateUserLevelId': { value: control.value } };
    };

}

@Directive( {
    selector: '[validateUserLevelId]',
    providers: [ { provide: NG_VALIDATORS, useExisting: UserLevelIdValidatorDirective, multi: true } ],
} )
export class UserLevelIdValidatorDirective implements Validator {

    @Input() length!: string;

    validate ( control: AbstractControl ): { [ key: string ]: any } {

        return validateUserLevelId()( control ) || {};
    }
}
