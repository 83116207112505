import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { WebhookListComponent } from './webhook-list/webhook-list.component';
import { WebhookService } from '../core/services/webhook.service';
import { WebhookCreateComponent } from './webhook-create/webhook-create.component';
import { WebhookEditComponent } from './webhook-edit/webhook-edit.component';
import { WebhookLogsListComponent } from './webhook-logs-list/webhook-logs-list.component';
import { WebhookSettingsComponent } from './webhook-settings/webhook-settings.component';
import { IncomingWebhookLogsListComponent } from './incoming-webhook-logs-list/incoming-webhook-logs-list.component';
import { CreateSubUrlModalComponent } from './create-sub-url-modal/create-sub-url-modal.component';
import { WebhookSubUrlListComponent } from './webhook-sub-url-list/webhook-sub-url-list.component';
import { WebhookLogPayloadComponent } from './webhook-log-payload/webhook-log-payload.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgxPaginationModule,
        TrimValueAccessorModule,
        TooltipModule.forRoot(),
    ],
    declarations: [
        WebhookListComponent,
        WebhookCreateComponent,
        WebhookEditComponent,
        WebhookLogsListComponent,
        WebhookSettingsComponent,
        IncomingWebhookLogsListComponent,
        CreateSubUrlModalComponent,
        WebhookSubUrlListComponent,
        WebhookLogPayloadComponent,
    ],
    exports: [],
    providers: [ WebhookService ],
} )
export class WebhookModule {
}
