import { Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { DownloadExcelService } from './../../core/services/download-excel.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component( {
    selector: 'app-download-modal',
    templateUrl: './download-modal.component.html',
    styleUrls: [ './download-modal.component.css' ],
} )
export class DownloadModalComponent implements OnInit, OnDestroy {

    percentage!: number;
    estimatedTime!: number | null;
    maxCount!: number | null;
    downloadedCount!: number | null;
    isSubmitting: boolean = false;
    downloadFormGroup!: FormGroup;
    downloadExcelSubscription!: Subscription;
    totalTime: number = 0;
    interval: string | number | undefined | any;

    @Input()
    filterParams!: Params | undefined | any;
    @Input()
    route!: string;
    @Input()
    paramsString!: string;
    @Input()
    fileName!: string;
    @Input()
    method!: string;
    @Input()
    postParams!: object;


    constructor (
        public bsModalRef: BsModalRef,
        private fb: FormBuilder,
        private downloadExcelService: DownloadExcelService,

    ) { }

    ngOnInit () {
        this.initForm();
        this.subscribeToDownloadStats();
    }


    get hasDownloadStarted (): boolean {
        return this.isSubmitting;
    }

    get isDownloadStatsPresent (): boolean {
        return this.downloadedCount ? true : false;
    }

    get isEstimatedTimePresent (): boolean {
        return this.estimatedTime ? true : false;
    }

    get defaultPurpose (): any {
        return window.localStorage.getItem( 'purpose' );
    }

    set defaultPurpose ( purpose: string ) {
        window.localStorage.setItem( 'purpose', purpose );
    }


    subscribeToDownloadStats (): void {
        this.downloadExcelSubscription = this.downloadExcelService.downloadStats.subscribe(
            stats => {
                this.percentage = stats.percentage;
                this.estimatedTime = Math.ceil( stats.estimatedTime );
                this.downloadedCount = stats.downloadedCount;
                this.maxCount = stats.maxCount;
            }
        );
    }

    initForm (): void {
        this.downloadFormGroup = this.fb.group( {
            'purpose': [ this.defaultPurpose, Validators.required ],
        } );
    }

    onClose (): void {

        this.bsModalRef.hide();
        this.resetAndCancelDownload();

    }

    submit (): void {

        this.startTimer();

        this.isSubmitting = true;
        this.defaultPurpose = this.downloadFormGroup.controls[ 'purpose' ].value;

        this.downloadExcelService.download(
            this.route,
            this.filterParams,
            this.paramsString,
            this.downloadFormGroup.get( 'purpose' )?.value,
            this.fileName,
            this.method,
            this.postParams,
            this.bsModalRef
        );
    }

    cancellProcess ( event: any ): void {

        event.preventDefault();
        this.resetAndCancelDownload();

    }

    resetAndCancelDownload (): void {

        this.resetStats();
        this.downloadExcelService.cancel();

    }

    ngOnDestroy (): void {

        this.downloadExcelSubscription.unsubscribe();
        this.resetTimer();

    }

    resetStats (): void {

        this.percentage = 0;
        this.maxCount = null;
        this.downloadedCount = null;
        this.estimatedTime = null;
        this.resetTimer();

    }

    startTimer (): void {

        this.interval = setInterval( () => {
            this.totalTime++;
        }, 1000 );

    }

    resetTimer (): void {
        clearInterval( this.interval );
    }

}
