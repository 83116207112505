<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">All Deleted Roles</h1>
</div>

<div class="wrapper-md">
    <div class="btn-group btn-breadcrumb">
        <a class="btn btn-default" routerLink="/company-roles/list">All Role</a>
        <a class="btn btn-default" routerLink="/company-roles/deleted/list">Deleted Role</a>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <!-- <div class="spinner" [ngClass]="{ hidden: !loading }"></div> -->
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 20%">Role name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let role of pagination.data | paginate
          : {
              id: 'server',
              itemsPerPage: pagination.per_page,
              currentPage: pagination.current_page,
              totalItems: pagination.total
            }">
                        <td>
                            {{role.name}}
                        </td>
                        <td width="1%">
                            <button (click)="onRestoreRoleButtonClicked(role.id)" class="btn btn-info btn-xs"><i
                                    class="fa fa-undo"></i> Restore
                            </button>
                        </td>
                        <td width="1%">
                            <button (click)="onDeleteRoleButtonClicked(role.id)" class="btn btn-danger btn-xs"><i
                                    class="fa fa-trash"></i> Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <!-- <div class="spinner" [ngClass]="{ hidden: !loading }"></div> -->
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>