<form [formGroup]="verificationFormGroup" (ngSubmit)="updateVerification('identity')"
    (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">

        <!--Top Section Of the Form-->
        <div *ngIf="employee && employee.id" class="row bg-light lter b-b wrapper-md">

            <app-verification-edit-header [verification]="'identity'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>

            <!-- old deprecated -->
            <!-- <app-verification-header verification="identity" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" [showNavigation]="showNavigation" [showAutoButtons]="showAutoButtons"
                (done)="onDone($event)" (exit)="onExit($event)" (back)="onUpdateAndBack($event)"
                (refresh)="onUpdateAndRefresh($event)" (next)="onUpdateAndNext($event)"></app-verification-header> -->
        </div>

        <div class="wrapper-md">
            <div class="panel">
                <div class="panel-body">
                    <div class="row" *ngIf="employee">
                        <div class="form-group col-md-2">
                            <label class="center-block">Employee Name</label>
                            <input formControlName="name" ngModel="{{employee.name}}" class="form-control" readonly>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Client Employee Id</label>
                            <input formControlName="client_employee_id" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Mobile Number</label>
                            <input formControlName="mobile_number" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Father Name</label>
                            <input formControlName="father_name" ngModel="{{employee.father_name}}" class="form-control"
                                readonly>
                        </div>
                        <div class="form-group col-md-2">
                            <label class="center-block">Date Of Birth</label>
                            <input formControlName="dob" class="form-control" ngModel="{{employee.dob}}" readonly>
                        </div>

                        <div class="form-group col-md-2">
                            <label class="center-block">Gender</label>

                            <select formControlName="gender" class="form-control">
                                <option [ngValue]="null">Select Gender</option>
                                <option value="m">Male</option>
                                <option value="f">Female</option>
                            </select>
                        </div>

                        <div class="form-group col-md-2">
                            <label class="center-block">Country</label>
                            <input [value]="employee.country.name" class="form-control" readonly>
                        </div>

                        <div class="form-group col-md-2" [appCan]="'moderator'">
                            <label class="center-block">Click here to</label>
                            <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createIdentity()">
                                <i class="fa fa-plus"></i>
                                Add Identity Verification
                            </a>
                        </div>

                    </div>
                </div>
            </div>

            <div formArrayName="formArray">
                <div *ngFor="let verification of formArray.controls; let i=index" [formGroupName]="i">

                    <app-image-crop #cropper [verification]="verification" (saved)="imageSaved()"
                        [verification_type]="'identity'"></app-image-crop>

                    <div class="row">
                        <div class="col-md-8">
                            <!--Front Url Panel-->
                            <div class="panel" *ngIf="verification.get('front_url')?.value" style="overflow: hidden;">
                                <div class="row">
                                    <div class="col-md-12" style="position: inherit">
                                        <button type="button"
                                            (click)="zoomInImage('img_front_url_'+verification.value.id)"
                                            class="btn btn-primary"> +
                                        </button>
                                        <button type="button"
                                            (click)="zoomOutImage('img_front_url_'+verification.value.id)"
                                            class="btn btn-primary"> -
                                        </button>
                                        <button type="button" (click)="cropper.show('front_url')"
                                            class="btn btn-primary">
                                            Crop
                                        </button>
                                    </div>

                                    <div [style.max-height]="getMaxHeight(verification)"
                                        class="col-md-12 identity-edit-image-frame"
                                        id="div_front_url_{{verification.value.id}}">
                                        <img imageZoom style="width: 100%;height: 60%"
                                            [src]="verification.get('front_url')?.value"
                                            id="img_front_url_{{verification.value.id}}" />
                                    </div>
                                </div>
                            </div>

                            <!--Proof Url Panel-->
                            <div>
                                <div class="panel" *ngIf="verification.get('proof_url')?.value"
                                    style="overflow: hidden;">
                                    <div class="row">
                                        <div class="col-md-12" style="position: inherit">
                                            <button type="button"
                                                (click)="zoomInImage('img_proof_url_'+verification.value.id)"
                                                class="btn btn-primary"> +
                                            </button>
                                            <button type="button"
                                                (click)="zoomOutImage('img_proof_url_'+verification.value.id)"
                                                class="btn btn-primary"> -
                                            </button>
                                            <button type="button" (click)="cropper.show('proof_url')"
                                                class="btn btn-primary"> Crop
                                            </button>
                                        </div>

                                        <div [style.max-height]="getMaxHeight(verification)"
                                            class="col-md-12 identity-edit-image-frame"
                                            id="div_proof_url_{{verification.value.id}}"
                                            *ngIf="verification.get('proof_url')?.value">
                                            <img imageZoom style="width: 100%;height: 60%"
                                                [src]="verification.get('proof_url')?.value"
                                                id="img_proof_url_{{verification.value.id}}" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Back Url Panel-->
                            <div>
                                <div class="panel" *ngIf="verification.get('back_url')?.value"
                                    style="overflow: hidden;">
                                    <div class="row">
                                        <div class="col-md-12" style="position: inherit">
                                            <button type="button"
                                                (click)="zoomInImage('img_back_url_'+verification.value.id)"
                                                class="btn btn-primary"> +
                                            </button>
                                            <button type="button"
                                                (click)="zoomOutImage('img_back_url_'+verification.value.id)"
                                                class="btn btn-primary"> -
                                            </button>
                                            <button type="button" (click)="cropper.show('back_url')"
                                                class="btn btn-primary"> Crop
                                            </button>
                                        </div>

                                        <div [style.max-height]="getMaxHeight(verification)"
                                            class="col-md-12 identity-edit-image-frame"
                                            id="div_back_url_{{verification.value.id}}"
                                            *ngIf="verification.get('back_url')?.value">
                                            <img imageZoom style="width: 100%;height: 60%"
                                                [src]="verification.get('back_url')?.value"
                                                id="img_back_url_{{verification.value.id}}" />
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div class="col-md-4">
                            <div class="panel panel-default">
                                <div class="panel-body">
                                    <div class="row">

                                        <div class="col-md-6 form-group">
                                            <label>Upload Front</label>
                                            <input type="file" id="front_url_{{verification.value.id}}" ng2FileSelect
                                                [uploader]="uploader" class="form-control"
                                                (change)="upload(verification,'front_url')" [disabled]="editable[i]" />

                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label>Upload Back</label>
                                            <input type="file" id="back_url_{{verification.value.id}}" ng2FileSelect
                                                [uploader]="uploader" class="form-control"
                                                (change)="upload(verification,'back_url')" [disabled]="editable[i]" />
                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label>Upload Proof</label>
                                            <input type="file" id="proof_url_{{verification.value.id}}" ng2FileSelect
                                                [uploader]="uploader" class="form-control"
                                                (change)="upload(verification,'proof_url')" [disabled]="editable[i]" />
                                        </div>
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <app-identity-validation-modal #identityValidationModal
                                                        [verification]="verification.value">
                                                    </app-identity-validation-modal>
                                                    <label> click for</label> <br>
                                                    <a class="btn btn-primary pull-left"
                                                        (click)="identityValidationModal.show()">
                                                        Validation</a>
                                                </div>
                                                <div class="col-md-6">
                                                    <app-identity-validation-modal #identityValidationModal
                                                        [verification]="verification.value">
                                                    </app-identity-validation-modal>
                                                    <label> click here to</label> <br>
                                                    <a class="btn btn-primary pull-left"
                                                        (click)="onSwapFrontAndBackButtonClicked(verification)">
                                                        Swap F/B</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">

                                        <div class="col-md-6 form-group">
                                            <label class="center-block">QA Required
                                                <select class="form-control" formControlName="qa_required"
                                                    [required]="verification.get('qa_required')?.value | inArray:[36,37]">
                                                    <option [value]="0">
                                                        NO
                                                    </option>
                                                    <option [value]="1">
                                                        YES
                                                    </option>
                                                </select>
                                            </label>
                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label class="center-block">QA done
                                                <select class="form-control" formControlName="qa_done"
                                                    [required]="verification.get('qa_done')?.value | inArray:[0,1]">
                                                    <option [value]="0">
                                                        NO
                                                    </option>
                                                    <option [value]="1">
                                                        YES
                                                    </option>
                                                </select>
                                            </label>
                                            <small class="text-danger" *ngIf="verification.get('qa_done')?.errors">
                                                Required
                                            </small>
                                        </div>

                                        <div class="col-md-6 form-group">

                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'type'" [labelName]="'Type'"
                                                [options]="identityTypes">
                                            </app-typeahead-dropdown>
                                        </div>
                                        <div *ngIf="statuses" class="col-md-6 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'status'" [labelName]="'Status'"
                                                [options]="statuses['identity']">
                                            </app-typeahead-dropdown>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Completed At</label>
                                            <small class="text-danger" *ngIf="verification.get('completed_at')?.errors">
                                                Required
                                            </small>
                                            <input type="date" class="form-control" formControlName="completed_at"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Doc Number</label>
                                            <small class="text-danger" *ngIf="verification.get('number')?.errors">
                                                {{doc_number_error_message}}
                                            </small>
                                            <input class="form-control" formControlName="number">
                                        </div>

                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==5">
                                            <label class="center-block">File Number</label>
                                            <input class="form-control" formControlName="file_number">
                                        </div>

                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Name</label>
                                            <small class="text-danger" *ngIf="verification.get('name')?.errors">
                                                Only alphabets allowed
                                            </small>
                                            <input class="form-control" formControlName="name" [readonly]="false">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Father Name</label>
                                            <small class="text-danger" *ngIf="verification.get('father_name')?.errors">
                                                Only alphabets allowed
                                            </small>
                                            <input class="form-control" formControlName="father_name">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">DOB (DD-MM-YYYY)</label>
                                            <small class="text-danger" *ngIf="verification.get('dob')?.errors">
                                                Invalid Dob Format
                                            </small>
                                            <input class="form-control" formControlName="dob">
                                        </div>
                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Address</label>
                                            <textarea class="form-control" formControlName="address"></textarea>
                                        </div>
                                        <div class="col-md-6 form-group">

                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'state'" [labelName]="'State'"
                                                [options]="states">

                                            </app-typeahead-dropdown>
                                        </div>
                                        <div class="col-md-6 form-group">

                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'district'" [labelName]="'District'"
                                                [options]="districts | filterByStateId:verification.get('state_id')?.value">

                                            </app-typeahead-dropdown>

                                            <small class="text-danger" *ngIf="verification.get('district_id')?.errors">
                                                Required
                                            </small>

                                        </div>
                                        <div class="col-md-12 form-group">
                                            <label class="center-block">Pincode</label>
                                            <small class="text-danger" *ngIf="verification.get('pincode')?.errors">
                                                Must contain 6 digits only
                                            </small>
                                            <input class="form-control" formControlName="pincode"
                                                onkeydown="scrollTo(0,0)">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Date of Issue</label>
                                            <input type="date" class="form-control" formControlName="date_of_issue">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Date Of Expiry</label>
                                            <input type="date" class="form-control" formControlName="date_of_expiry">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'vendor'" [labelName]="'Vendor'"
                                                [options]="vendors">
                                            </app-typeahead-dropdown>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Sent to Vendor on</label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('sent_to_vendor_on')?.errors">
                                                Invalid
                                            </small>
                                            <input type="date" class="form-control" formControlName="sent_to_vendor_on"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}">
                                        </div>


                                        <div class="col-md-12 form-group" *ngIf="employee.company_name != 'NotUber'">
                                            <label class="center-block">Client Remarks:
                                                <textarea rows=2 class="form-control" formControlName="client_remarks">
                                                </textarea>
                                            </label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('client_remarks')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="employee.company_name == 'NotUber'">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'client_remark'" [labelName]="'Client Remarks'"
                                                [options]="verificationClientRemarks['identity']">
                                            </app-typeahead-dropdown>
                                            <small class="text-danger"
                                                *ngIf="verification.get('client_remark_name')?.errors">
                                                Required
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="!verification.get('client_remark_name')?.errors && verification.get('client_remark_id')?.errors">
                                                Please Select From Available Remarks
                                            </small>
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==4"
                                            id="lmv_first_issue_date_{{verification.value.id}}">
                                            <label class="center-block">NT Issue Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="lmv_first_issue_date">
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==4"
                                            id="lmv_last_renewal_date_{{verification.value.id}}">
                                            <label class="center-block">NT EXPIRY Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="lmv_last_renewal_date">
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==4"
                                            id="tr_first_issue_date_{{verification.value.id}}">
                                            <label class="center-block">TR Issue Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="tr_first_issue_date">
                                        </div>
                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==4"
                                            id="tr_last_renewal_date_{{verification.value.id}}">
                                            <label class="center-block">TR Expiry Date</label>
                                            <input type="date" class="form-control"
                                                formControlName="tr_last_renewal_date">
                                        </div>

                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==4">
                                            <label class="center-block">India Post Id</label>
                                            <input class="form-control" formControlName="india_post_id">
                                        </div>

                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==4">
                                            <label class="center-block">Payment Receipt No.</label>
                                            <input class="form-control" formControlName="payment_reference_number">
                                        </div>

                                        <div class="col-md-6 form-group" *ngIf="verification.get('type_id')?.value==4">

                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'registered_authority'"
                                                [labelName]="'Authority'"
                                                [options]="authorities | filterByDistrictId:verification.get('district_id')?.value">

                                            </app-typeahead-dropdown>

                                            <app-authority-create-modal #authorityCreateModal>
                                            </app-authority-create-modal>
                                            <a class="btn m-b-xs btn-sm" (click)="authorityCreateModal.show()">
                                                <i class="fa fa-plus"></i>
                                                Create Authority
                                            </a>

                                        </div>

                                        <div class=" col-md-6 form-group
                                            " *ngIf="verification.get('type_id')?.value==4">
                                            <label>Upload Payment Receipt</label>
                                            <input type="file" id="payment_url_{{verification.value.id}}" ng2FileSelect
                                                [uploader]="uploader" class="form-control"
                                                (change)="upload(verification,'payment_url')" />

                                        </div>


                                        <div class="col-md-6" *ngIf="attachments[i] && attachments[i]['payment_urls']">
                                            <div class="row" *ngFor="let attachment of attachments[i]['payment_urls']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">

                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Insufficiency Raised On</label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('insuff_raised_at')?.errors">
                                                Invalid
                                            </small>
                                            <input readonly type="date" class="form-control"
                                                formControlName="insuff_raised_at"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}">
                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label class="center-block">Insufficiency Cleared On</label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('insuff_cleared_at')?.errors">
                                                Invalid
                                            </small>
                                            <input readonly type="date" class="form-control"
                                                formControlName="insuff_cleared_at"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}">
                                        </div>

                                    </div>
                                </div>
                                <div class="panel-footer text-right" [appCan]="'admin'">
                                    <div class="row">
                                        <div class="col-sm-offset-7 col-sm-4">
                                            <a class="btn btn-danger"
                                                (click)="onDeleteVerificationButtonClicked(verification.get('id')?.value,i)">
                                                Delete Verification
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>