<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">{{ lot.name }}</h1>
    <ul class="breadcrumb insta-breadcrumb">
        <li><a routerLink="/home">Home</a></li>
        <li><a routerLink="/companies">All Companies</a></li>
        <li><a routerLink="/companies/{{lot.company_id}}">{{lot.company_name}}</a></li>
        <li class="active">{{lot.name}}</li>
        <li class="pull-right"><a routerLink="/lots/{{lot.id}}/history"> History </a></li>
    </ul>
</div>

<div class="wrapper-md">
    <div class="row">
        <div *ngFor="let verification of verifications" class="col-md-3">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="waves-effect stats-white">
                        {{ verification }}
                    </mat-expansion-panel-header>
                    <div class="stats-collapse-panel">
                        <ul class="stats-list list-unstyled">
                            <li class="list-group-item" *ngFor="let status of verificationStats[verification]">
                                <span class="pull-right">{{ status.count }}</span>
                                {{ status.status_name }}
                            </li>
                        </ul>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="row">
        <div class="col-md-12">
            <app-employee-list [lot]="lot"></app-employee-list>
        </div>
    </div>
</div>