import { Injectable } from '@angular/core';
import { Pagination } from '../models/pagination.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';


import { Authority } from '../models/authority.model';
import { AuthorityContact } from '../models/authority-contact.model';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class AuthorityService {

    constructor (
        private api: ApiService
    ) {
    }

    createAuthority ( data: {} ): Observable<any[]> {
        return this.api.post( 'identity/authorities', data );
    }

    createAuthorityContact ( id: number, data: {} ): Observable<any[]> {
        return this.api.post( 'identity/authorities/' + id + '/contact', data );
    }

    getAllAuthorities ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'identity/authorities', params );
    }

    findAuthority ( id: number ): Observable<Authority> {
        return this.api.get( 'identity/authorities/' + id );
    }

    findAuthorityContact ( id: number ): Observable<AuthorityContact> {
        return this.api.get( 'identity/authorities/contact/' + id );
    }

    updateAuthority ( id: number, values: Object ) {
        return this.api.put( 'identity/authorities/' + id, values );
    }

    updateAuthorityContact ( id: number, values: Object ) {
        return this.api.put( 'identity/authorities/contact/' + id, values );
    }

    get all (): Array<{ id: string, name: string }> {
        return [
            {
                id: 'State Transport Authority',
                name: 'State Transport Authority',
            },
            {
                id: 'District Transport Officer',
                name: 'District Transport Officer',
            },
            {
                id: 'Regional Transport Officer',
                name: 'Regional Transport Officer',
            },
            {
                id: 'Additional Regional Transport Officer',
                name: 'Additional Regional Transport Officer',
            },
            {
                id: 'Regional Transport Authority',
                name: 'Regional Transport Authority',
            },
            {
                id: 'Sub Divisional Magistrate cum Licencing Authority',
                name: 'Sub Divisional Magistrate cum Licencing Authority',
            },
            {
                id: 'Sub Divisional Magistrate',
                name: 'Sub Divisional Magistrate',
            },
            {
                id: 'Commissioner of Police',
                name: 'Commissioner of Police',
            },
            {
                id: 'Joint Commissioner of Police',
                name: 'Joint Commissioner of Police',
            },
            {
                id: 'Deputy Commissioner of Police',
                name: 'Deputy Commissioner of Police',
            },
            {
                id: 'District Magistrate',
                name: 'District Magistrate',
            },
            {
                id: 'District Police',
                name: 'District Police',
            },
            {
                id: 'Special Branch',
                name: 'Special Branch',
            },
            {
                id: 'Superintendent of Police',
                name: 'Superintendent of Police',
            },
            {
                id: 'Deputy Superintendent of Police',
                name: 'Deputy Superintendent of Police',
            },
            {
                id: 'Senior Superintendent of Police',
                name: 'Senior Superintendent of Police',
            },

        ];
    }

    get editableAuthorities (): Array<{ id: string, name: string }> {
        return [
            {
                id: 'State Transport Authority',
                name: 'State Transport Authority',
            },
            {
                id: 'District Transport Officer',
                name: 'District Transport Officer',
            },
            {
                id: 'Regional Transport Officer',
                name: 'Regional Transport Officer',
            },
            {
                id: 'Additional Regional Transport Officer',
                name: 'Additional Regional Transport Officer',
            },
            {
                id: 'Regional Transport Authority',
                name: 'Regional Transport Authority',
            },
            {
                id: 'Sub Divisional Magistrate cum Licencing Authority',
                name: 'Sub Divisional Magistrate cum Licencing Authority',
            },
            {
                id: 'Sub Divisional Magistrate',
                name: 'Sub Divisional Magistrate',
            },
        ];
    }

    get types (): Array<{ id: string, name: string }> {
        return [
            {
                id: 'licence',
                name: 'Licence',
            },
            {
                id: 'pcc',
                name: 'PCC',
            },
        ];
    }
}
