import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../services/permission.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfirmationModalService, ConfirmationModalOptions } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-permission-deleted',
    templateUrl: './permission-deleted.component.html',
    styleUrls: [ './permission-deleted.component.css' ],
} )
export class PermissionDeletedComponent implements OnInit {

    permissions!: any[];

    constructor (
        private permissionService: PermissionService,
        private common: CommonService,
        private confirmationModalService: ConfirmationModalService

    ) { }

    ngOnInit () {
        this.getAllDeletedPermissions();
    }

    getAllDeletedPermissions () {
        this.permissionService.getAllDeleted().subscribe(
            response => this.permissions = response
        );
    }

    restorePermission ( id: number ) {
        this.permissionService.restore( id ).subscribe(
            {
                next: ( response: any ) => {
                    this.common.notifications.success( response[ 'message' ] );
                    this.getAllDeletedPermissions();
                },
                error: ( err: any ) => {
                    this.common.notifications.error( err );
                },
            }
        );
    }

    onRestorePermissionButtonClicked ( id: number ) {

        const handleEvent = ( event: { preventDefault: () => any; } ) => event && event.preventDefault();

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.restorePermission( id ) );

    }


    deletePermission ( id: number ): void {

        this.permissionService.permanentDelete( id ).subscribe( {
            next: ( response: any ) => {

                this.common.notifications.success( response[ 'message' ] );

                this.getAllDeletedPermissions();
            },
            error: ( err: any ) => {

                this.common.notifications.error( err );
            },
        } );

    }

    onDeletePermissionButtonClicked ( id: number ) {

        const handleEvent = ( event: { preventDefault: () => any; } ) => event && event.preventDefault();

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.deletePermission( id ) );

    }

}
