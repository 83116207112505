import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { PanService } from 'src/app/core/services/pan.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PanSources } from '../../core/enums/pan-sources.enum';
import { PanDataTypes } from '../../core/enums/pan-data-types.enum';

@Component( {
    selector: 'app-pan-search-modal',
    templateUrl: './pan-search-modal.component.html',
    styleUrls: [ './pan-search-modal.component.css' ],
} )
export class PanSearchModalComponent {

    @ViewChild( 'panSearchModal', { static: false } )
    public panSearchModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();


    isSubmitting = false;
    panSearchForm!: FormGroup;
    PanSources = PanSources;
    PanDataTypes = PanDataTypes;

    constructor (
        private fb: FormBuilder,
        private panService: PanService,
        private commonService: CommonService
    ) {

        this.initForm();
    }

    initForm () {
        this.panSearchForm = this.fb.group( {
            pan_number: [ '', [ Validators.required, Validators.pattern( '^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$' ) ] ],
            driver: [ '', [ Validators.required ] ],
            data_type: [ '', [ Validators.required ] ],
        } );
    }

    show () {
        this.panSearchModal.show();
    }

    hide () {
        this.initForm();
        this.panSearchModal.hide();
    }

    search (): void {
        this.isSubmitting = true;

        this.panService.search( this.panSearchForm.value ).subscribe( response => {
            this.commonService.notifications.success( 'Request Sent Successfully' );
            this.isSubmitting = false;
            this.hide();
            this.onSearch.emit( true );


        },
            ( error ) => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            } );
    }
}
