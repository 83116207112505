import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { validateDate } from '../../common/validators/date-validator.directive';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { CommonService } from '../../core/services/common.service';
import { PccService } from '../../core/services/pcc.service';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-pcc-import-excel',
    templateUrl: './pcc-import-excel.component.html',
    styleUrls: [ './pcc-import-excel.component.css' ],
} )
export class PccImportExcelComponent extends ImportExcelComponent implements OnInit {

    override isSubmitting = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    uploadForm: FormGroup;
    lotId!: number;
    validationMessages: ValidationMessages = {};

    constructor (
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
        private pccService: PccService
    ) {
        super( fb );
        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            'excel': [ '', Validators.required ],
        } );

    }

    applyValidations () {
        this.excelFormArray.controls.forEach( ( form: any ) => {
            form.get( 'track_id' ).setValidators( [ Validators.required ] );
            form.get( 'track_id' ).updateValueAndValidity();

            if ( form.get( 'applied_at' ) ) {
                form.get( 'applied_at' ).setValidators( [ validateDate( 'dd-mm-yyyy' ) ] );
                form.get( 'applied_at' ).updateValueAndValidity();
            }

            if ( form.get( 'completed_at' ) ) {
                form.get( 'completed_at' ).setValidators( [ validateDate( 'dd-mm-yyyy' ) ] );
                form.get( 'completed_at' ).updateValueAndValidity();
            }

            if ( form.get( 'email_address' ) ) {
                form.get( 'email_address' ).setValidators( [ validateEmail() ] );
                form.get( 'email_address' ).updateValueAndValidity();
            }

            if ( form.get( 'pincode' ) ) {
                form.get( 'pincode' ).setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
                form.get( 'pincode' ).updateValueAndValidity();
            }

            this.setValidationMessages();
        } );
    }

    initExcelForm () {
        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages () {
        this.validationMessages[ 'track_id' ] = 'Required" ';
        this.validationMessages[ 'applied_at' ] = 'Allowed: "dd-mm-yyyy" ';
        this.validationMessages[ 'completed_at' ] = 'Allowed: "dd-mm-yyyy" ';
        this.validationMessages[ 'email_address' ] = 'Invalid Mail Address ';
        this.validationMessages[ 'pincode' ] = '6 digits allowed';
    }


    ngOnInit () {
    }

    importPcc () {
        this.pccService.importExcel( this.excelForm.value ).subscribe(
            ( response: any ) => {

                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Success', response[ 'message' ] );
                    this.isSubmitting = false;
                    this.router.navigate( [ 'pcc' ] );
                } else {
                    this.commonService.displayValidationErrors( response[ 'errors' ] );
                    this.isSubmitting = false;
                }

            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Error Occured' );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );

    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
