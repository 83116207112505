import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IcrVerification } from '../../core/models/icr-verification.model';
import { CommonService } from '../../core/services/common.service';
import { IcrService } from '../../core/services/icr.service';
import { UserService } from '../../core/services/user.service';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { VerificationList } from 'src/app/core/components/verification-list.component';
import { GlobalService } from 'src/app/core/services/global.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-icr-list',
    templateUrl: './icr-list.component.html',
    styleUrls: [ './icr-list.component.css' ],
    providers: [ IcrService ],
} )
export class IcrListComponent extends VerificationList implements OnInit {
    loading!: boolean;
    override verifications: IcrVerification[] = [];
    isSubmitting: boolean = false;
    attachments: any[] = [];
    user: any;
    statsVisible: boolean = false;

    get users () {
        return this.global.fetchUsers();
    }

    constructor (
        protected override verificationService: IcrService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        public global: GlobalService,
        protected override fb: FormBuilder,
        protected override commonService: CommonService,
        protected modalService: BsModalService,
        protected override viewContainerRef: ViewContainerRef,
        protected userService: UserService,
        private bsModalService: BsModalService,
        public override confirmationModalService: ConfirmationModalService
    ) {
        super( confirmationModalService );
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {
        this.verification = 'icr';
        this.subscribeToQueryChanges();
        this.user = this.userService.getCurrentUser();
    }

    showIcr ( icr: IcrVerification ): void {
        this.router.navigate(
            [ 'icr', icr.employee_id, 'edit' ],
            this.navigationExtras
        );
    }

    filterResults (): void {
        this.verificationService.filter( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.verifications = response.data;
            this.populateExtraDetails( response.data );
        } );
    }

    populateExtraDetails ( icrVerifications: IcrVerification[] ): void {

        for ( const index in icrVerifications ) {
            const verification: IcrVerification = icrVerifications[ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    // Open Vcourt Cases Page
    linkToVcourt ( vcourtId: number ): void {
        // To add the navigation_from filter param dynamically.
        const updatedQueryParams: Params = { navigation_from: 'icr' };

        const navigationExtras: NavigationExtras = {
            queryParams: updatedQueryParams,
            queryParamsHandling: 'merge',
        };

        this.router.navigate( [ 'vcourt', vcourtId ], navigationExtras );
    }

    // Ola Excel.
    exportCrcExcel (): void {

        this.verificationService.getDataForOlaExcel( this.filterParams );
    }

    searchCnr (): void {

        this.verificationService.searchCnr( this.filterParams ).subscribe( {
            next: ( response: any ) => {

                this.filterResults();

                this.commonService.notifications.success( 'Successfully Submitted' );
            },
            error: ( errors: any ) => {

                this.commonService.notifications.error( 'Error', 'Something went wrong!' );

                this.commonService.displayValidationErrors( errors );
            }
        } );
    }

    onSearchCnrButtonClicked (): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.searchCnr() );

    }

    parseEcourt (): void {

        this.verificationService.parseEcourt().subscribe( ( response ) => {
            this.filterResults();
            this.commonService.notifications.success( 'Successfully Submitted' );

        } );
    }

    showStats (): void {

        this.statsVisible = !this.statsVisible;
    }

    // overriding super class method
    override exportExcel () {

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'common/icr/downloadExcel',
            fileName: 'Icr Excel',
            method: 'get',
            postParams: null,
        };

        this.bsModalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )


        this.commonService.getDataForExcel( this.verification, this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, this.verification );
            } );
    }
}
