<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">All Companies</h1>
</div>

<div class="wrapper-md" [appCan]="'admin'">
    <div class="row">
        <div class="col-lg-3">
            <a class="btn btn-primary btn-addon" routerLink="/companies/create">
                <i class="fa fa-plus"></i>
                Create New Company
            </a>
        </div>
        <div class="col-lg-3">
            <a class="btn btn-primary btn-addon" routerLink="/clients">
                <i class="fa fa-info"></i>
                Clients Account Details
            </a>
        </div>
    </div>
</div>

<app-loading-component [fetchingInProgress]="fetchingInProgress"></app-loading-component>
<app-company-filters [filterParams]="filterParams"></app-company-filters>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 20%">Company name</th>
                        <th style="width: 20%">Managers</th>
                        <th style="width: 20%">Open Batches</th>
                        <th style="width: 10%">Created</th>
                        <th style="width: 10%">Updated</th>
                        <th style="width: 10%;">Last Employee Created At</th>
                        <th style="width: 5%">Status</th>
                        <th style="width: 5%" [appCan]="'superadmin'">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let company of pagination.data | paginate
          : {
              id: 'server',
              itemsPerPage: pagination.per_page,
              currentPage: pagination.current_page,
              totalItems: pagination.total
            }">
                    <tr *ngFor="let company of pagination.data" app-company-list-item [company]="company"> </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>