<div [formGroup]="formGroup" class="panel panel-default">
    <div class="panel-heading">Verification #{{ i + 1 }}</div>

    <div class="panel-body">

        <div class="row">
            <ul>
                <li *ngFor="let work of workAssigned">
                    <strong>{{work.title}} is assigned to {{work.assigned_to}}</strong>
                </li>
            </ul>
        </div>



        <div class="row">

            <div *ngIf="(statusId | inArray: validStatusesForWorkCompleted);then taskCompletedDiv;"></div>

            <ng-template #taskCompletedDiv>

                <div class="col-md-3 form-group">

                    <label class="center-block">Task Completed

                        <select class="form-control" formControlName="work_completed">
                            <option [value]="1">YES</option>
                            <option [value]="0">NO</option>
                        </select>

                    </label>

                    <small class="text-danger" *ngIf="workCompleted?.errors">Required</small>
                </div>

            </ng-template>


            <div class="col-md-3 form-group">
                <app-task-completed [verification]="'address'" [workAssigned]="workAssigned">
                </app-task-completed>
            </div>

        </div>

        <div class="row">
            <div class="form-group col-md-3">
                <label class="center-block">Qa Required: </label>
                <select class="form-control" formControlName="qa_required">
                    <option [value]="0"> NO </option>
                    <option [value]="1"> YES </option>
                </select>
            </div>
            <div class="form-group col-md-3">
                <label class="center-block">Qa Done: </label>
                <select class="form-control" formControlName="qa_done">
                    <option [value]="0"> NO </option>
                    <option [value]="1"> YES </option>
                </select>
            </div>
        </div>
        <div class="row">

            <div *ngIf="statuses" class="col-md-3 form-group">
                <app-verification-status-typeahead (changed)="onStatusChanged($event)" [formGroup]="formGroup"
                    control="status" verification="reference">
                </app-verification-status-typeahead>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">Type
                    <select class="form-control" formControlName="type" (change)="onTypeChanged($event)">
                        <option [value]="'physical'">In-Person Verification
                        </option>
                        <option [value]="'phone'">Phone-call Verification
                        </option>
                        <option [value]="'employment'">Employment Verification
                        </option>
                        <option [value]="'tenant'">Tenant Verification
                        </option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('type')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">Name
                    <input class="form-control" formControlName="name" [required]="nameNumberValidations" />
                </label>
                <small class="text-danger" *ngIf="verification.get('name')?.errors">
                    Name is required
                </small>
            </div>
            <div class="col-md-3 form-group" *ngIf="isTypeEmployment">
                <label class="center-block">Email
                    <input class="form-control" formControlName="email" type="email" />
                </label>
                <small class="text-danger" *ngIf="verification.get('email')?.errors">
                    Invalid format
                </small>
            </div>
            <div class="col-md-3 form-group">
                <label class="center-block">Number
                    <input class="form-control" formControlName="number" [required]="nameNumberValidations" />
                </label>
                <small class="text-danger" *ngIf="verification.get('number')?.errors">
                    Only numeric allowed
                </small>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeInPerson || isTypePhone">
                <label class="center-block">Father Name
                    <input class="form-control" formControlName="father_name" />
                </label>
                <small class="text-danger" *ngIf="verification.get('father_name')?.errors">
                    Invalid format
                </small>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 form-group" *ngIf="isTypeInPerson || isTypePhone">
                <label class="center-block">Address
                    <textarea rows="6" class="form-control" formControlName="address"></textarea>
                </label>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeInPerson || isTypePhone">
                <app-state-typeahead [formGroup]="verification">
                </app-state-typeahead>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeInPerson || isTypePhone">
                <app-typeahead-dropdown [fGroup]="verification" [mainFormGroup]="verificationFormGroup"
                    [formArray]="formArray" [formGName]="i" [name]="'district'" [labelName]="'District'" [options]="districts
				| filterByStateId
				  : verification.get('state_id')?.value
			">
                </app-typeahead-dropdown>
            </div>

            <div class="form-group col-md-3" *ngIf="isTypeInPerson || isTypePhone">
                <label class="center-block">LandMark:
                    <input class="form-control" formControlName="landmark" />
                </label>
            </div>

            <div class="form-group col-md-3" *ngIf="isTypeInPerson || isTypePhone">
                <label class="center-block">Pincode:
                    <input class="form-control" formControlName="pincode" />
                </label>
                <small class="text-danger" *ngIf="verification.get('pincode')?.errors?.['validateLength']">
                    Pincode length should be 6 digits
                </small>
                <small class="text-danger" *ngIf="verification.get('pincode')?.errors?.['validateNumericCharacters']">
                    Only numbers are allowed
                </small>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3 form-group" *ngIf="isTypeEmployment">
                <label class="center-block">Company Name
                    <input class="form-control" formControlName="reference_company_name" />
                </label>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeEmployment || isTypePhone || isTypeInPerson">
                <label class="center-block">Association With Reference / Designation
                    <input class="form-control" formControlName="relation" />
                </label>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">Known Since
                    <input [required]="commanFieldValidations" class="form-control" formControlName="known_since" />
                </label>
                <small class="text-danger" *ngIf="verification.get('known_since')?.errors">
                    Required
                </small>
            </div>
        </div>

        <div class="row" *ngIf="isTypeEmployment">

            <div class="col-md-3 form-group">
                <label class="center-block">Work Performance
                    <select [required]="commanFieldValidations" class="form-control" formControlName="work_performance">
                        <option *ngFor="let rating of ratings" [value]="rating.key">{{ rating.label }}
                        </option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('work_performance')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">
                    Professionalism
                    <select [required]="commanFieldValidations" class="form-control" formControlName="professionalism">
                        <option *ngFor="let rating of ratings" [value]="rating.key">{{ rating.label }}
                        </option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('professionalism')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">
                    Teamwork
                    <select [required]="commanFieldValidations" class="form-control" formControlName="teamwork">
                        <option *ngFor="let rating of ratings" [value]="rating.key">{{ rating.label }}
                        </option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('teamwork')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">
                    Specific Achievements
                    <input class="form-control" formControlName="achievements" />
                </label>
                <small class="text-danger" *ngIf="verification.get('achievements')?.errors">
                    Required
                </small>
            </div>

        </div>

        <div class="row" *ngIf="isTypeEmployment">
            <div class="col-md-3 form-group">
                <label class="center-block">Potential Red Flags
                    <select class="form-control" formControlName="potential_red_flags"
                        (change)="onRedFlagChanged($event)" [required]="isStatusRedOrGreen">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('potential_red_flags')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">
                    Potential Red Flag Remarks
                    <input class="form-control" formControlName="potential_red_flags_remarks" />
                </label>
                <small class="text-danger" *ngIf="verification.get('potential_red_flags_remarks')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">Integrity Issues
                    <select class="form-control" formControlName="integrity_issues"
                        (change)="onIntegrityIssuesChanged($event)" [required]="isStatusRedOrGreen">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('integrity_issues')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">
                    Integrity Issue Remarks
                    <input class="form-control" formControlName="integrity_issues_remarks" />
                </label>
                <small class="text-danger" *ngIf="verification.get('integrity_issues_remarks')?.errors">
                    Required
                </small>
            </div>

        </div>

        <div class="row">
            <div class="col-md-3 form-group" *ngIf="isTypeEmployment">
                <label class="center-block">
                    Reason For Leaving
                    <input [required]="commanFieldValidations" class="form-control"
                        formControlName="reason_for_leaving" />
                </label>
                <small class="text-danger" *ngIf="verification.get('reason_for_leaving')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">Date of Verification
                    <input type="date" class="form-control" min="{{ employee.created_at.slice(0, 10) }}"
                        max="{{ current_date }}" formControlName="completed_at" />
                </label>
                <small class="text-danger" *ngIf="verification.get('completed_at')?.errors">
                    Required
                </small>
            </div>
            <div class="form-group col-md-3" *ngIf="isTypeInPerson">
                <app-vendor-typeahead [formGroup]="verification" [verification]="'reference'"></app-vendor-typeahead>
            </div>
            <div class="form-group col-md-3" *ngIf="isTypeInPerson">
                <label class="center-block">Sent On
                    <input type="date" class="form-control" formControlName="sent_to_vendor_on"
                        min="{{ employee.created_at.slice(0, 10) }}" max="{{ current_date }}" />
                </label>
                <small class="text-danger" *ngIf="verification.get('sent_to_vendor_on')?.errors">
                    Invalid
                </small>
            </div>
        </div>

        <div class="row">

            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Tenancy Start Date
                    <input type="date" class="form-control" formControlName="tenancy_start_date" />
                </label>
                <small class="text-danger" *ngIf="verification.get('tenancy_start_date')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Tenancy End Date
                    <input type="date" class="form-control" formControlName="tenancy_end_date" />
                </label>
                <small class="text-danger" *ngIf="verification.get('tenancy_end_date')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Rent Received On Time
                    <select class="form-control" formControlName="rent_received"
                        (change)="onRentReceviedOnTimeChange($event)">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('rent_received')?.errors">
                    Required
                </small>
            </div>


            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Rent Received Remarks
                    <input type="text" class="form-control" formControlName="rent_received_remarks" />
                </label>
                <small class="text-danger" *ngIf="verification.get('rent_received_remarks')?.errors">
                    Required
                </small>
            </div>

        </div>

        <div class="row">

            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Any Damage Caused
                    <select class="form-control" formControlName="damage_caused"
                        (change)="onAnyDamageCausedChange($event)">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('damage_caused')?.errors">
                    Required
                </small>
            </div>


            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Damage Caused Remarks
                    <input type="text" class="form-control" formControlName="damage_caused_remarks" />
                </label>
                <small class="text-danger" *ngIf="verification.get('damage_caused_remarks')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Any Issues
                    <select class="form-control" formControlName="issues" (change)="onAnyIssuesChange($event)">
                        <option [value]="0">No</option>
                        <option [value]="1">Yes</option>
                    </select>
                </label>
                <small class="text-danger" *ngIf="verification.get('issues')?.errors">
                    Required
                </small>
            </div>

            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Issues Remarks
                    <input type="text" class="form-control" formControlName="issue_remarks" />
                </label>
                <small class="text-danger" *ngIf="verification.get('issue_remarks')?.errors">
                    Required
                </small>
            </div>

        </div>

        <div class="row">
            <div class="col-md-3 form-group" *ngIf="isTypeTenant">
                <label class="center-block">
                    Additonal Remarks
                    <input type="text" class="form-control" formControlName="additional_remarks" />
                </label>
                <small class="text-danger" *ngIf="verification.get('additional_remarks')?.errors">
                    Required
                </small>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-md-6">
                <label class="center-block">Client Remarks:
                    <textarea class="form-control" rows="1" formControlName="client_remarks"
                        [required]="clientRemarkValidations">
				</textarea>
                    <small class="text-danger" *ngIf="verification.get('client_remarks')?.errors">
                        Required
                    </small>
                </label>
            </div>
            <div class="form-group col-md-6">
                <label class="center-block">Comments
                    <textarea class="form-control" rows="1" formControlName="comments">
			</textarea>
                </label>
            </div>
        </div>
        <div class="row">

            <div class="col-md-3 form-group">
                <label class="center-block">Insufficiency Raised On</label>
                <small class="text-danger" *ngIf="verification.get('insuff_raised_at')?.errors">
                    Invalid
                </small>
                <input readonly type="date" class="form-control" formControlName="insuff_raised_at"
                    max="{{current_date}}">
            </div>

            <div class="col-md-3 form-group">
                <label class="center-block">Insufficiency Cleared On</label>
                <small class="text-danger" *ngIf="verification.get('insuff_cleared_at')?.errors">
                    Invalid
                </small>
                <input readonly type="date" class="form-control" formControlName="insuff_cleared_at"
                    max="{{current_date}}">
            </div>
        </div>

        <div class="row" *ngIf="isTypeInPerson">
            <div class="col-md-3">
                <label>Upload Proof
                    <input type="file" id="proof_url_{{ verification.value.id }}" class="form-control"
                        (change)="upload(verification, 'proof_url')" />
                </label>
            </div>

            <div class="col-md-3">
                <label>Attachments
                    <input type="file" id="attachment_{{ verification.value.id }}" class="form-control" multiple
                        (change)="upload(verification, 'attachment')" />
                </label>
            </div>
        </div>

        <div class="row" *ngIf="attachments[i]">
            <div class="col-md-3" *ngIf="verification.get('proof_url')?.value; else elseDiv">
                <div class="row" *ngIf="isTypeInPerson">
                    <div class="col-md-12">
                        <a href="{{ verification.get('proof_url')?.value }}" class="btn-link">
                            Proof Url
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-md-3" *ngIf="attachments[i]['attachments']; else elseDiv">
                <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                    <div class="col-md-12" *ngIf="isTypeInPerson">
                        <a href="{{ attachment['full_path'] }}" class="btn-link">
                            {{ attachment["file_name"] }}
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #elseDiv>
            <div class="col-md-3">
                No File Attached
            </div>
        </ng-template>
    </div>
    <div class="panel-footer text-right" [appCan]="'admin'">
        <div class="row">
            <div class="col-sm-offset-8 col-sm-4">
                <a class="btn btn-danger" (click)="onDeleteVerificationButtonClicked()">
                    Delete Verification
                </a>
            </div>
        </div>
    </div>
</div>