import { EmailLogStatusTimeLineModalInitialState } from './../../core/models/email-log-status-time-line-modal-initial-state';
import { Component, Input, ViewChild } from '@angular/core';
import { EmailLogStatusTimeLineComponent } from '../../common/email-log-status-time-line/email-log-status-time-line.component';
import { EmailLog } from 'src/app/core/models/email-log';
import { EmailLogService } from 'src/app/core/services/email-log.service';

@Component( {
    selector: 'app-invite-email-list-item',
    templateUrl: './invite-email-list-item.component.html',
} )
export class InviteEmailListItemComponent {

    @Input() lastEmail!: EmailLog;

    get deliveryStatusLabel (): string {
        return this.emailLogService.getStatusLabel( this.lastEmail.delivery_status )
    }

    get statusColor (): string {
        return this.emailLogService.getStatusColor( this.lastEmail.delivery_status )
    }

    constructor ( private emailLogService: EmailLogService, ) { }

    showEmailLogStatusTimeLineModal (): void {

        const initialState: EmailLogStatusTimeLineModalInitialState = {
            statusLogs: this.lastEmail.status
        }

        this.emailLogService.showEmailStatusTimeLineModal( initialState );
    }
}
