import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { VehicleService } from '../../core/services/vehicle.service';

@Component( {
    selector: 'app-vehicle-html-response-modal',
    templateUrl: './vehicle-html-response-modal.component.html',
    styleUrls: [ './vehicle-html-response-modal.component.css' ],
} )
export class VehicleHtmlResponseModalComponent {

    htmlResponse!: string;
    attachments: any = [];

    @ViewChild( 'vehicleHtmlResponse', { static: false } )
    public vehicleHtmlResponse!: ModalDirective;

    constructor (
        private vehicleService: VehicleService,
        private commonService: CommonService,
    ) { }

    show ( log: any, type: string ) {

        this.vehicleService.getHtmlResponse( log.id, type ).subscribe(
            response => {
                this.attachments = response[ 'attachments' ];
                this.htmlResponse = response[ 'response' ];
                this.vehicleHtmlResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    hide () {
        this.vehicleHtmlResponse.hide();
    }

}
