import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterService } from 'src/app/core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-client-filter',
    templateUrl: './client-filter.component.html',
    styleUrls: [ './client-filter.component.css' ]
} )
export class ClientFilterComponent implements OnInit {

    @Input() filterBy!: any;

    filterFormGroup!: FormGroup<any>;
    filtersSubscription: any;
    filters: any[] = [];

    constructor ( private fb: FormBuilder,
        private global: GlobalService,
        private filterService: FilterService ) { }

    ngOnInit (): void {

        this.initForm();
        this.setDefault();
        this.filters = this.filterBy?.split( ',' );
    }

    get companies () { return this.global.fetchCompanies(); }

    initForm (): void {

        this.filterFormGroup = this.fb.group( {
            company_id: 'all',
            name: '',
            email: '',
            status: '',
            page: 1
        } );
    }

    filterQueriedResults () {
        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    setDefault (): void {
        this.filterService.updateFormFilters( this.filterFormGroup );
    }

    resetForm (): void {

        this.initForm();
        this.filterQueriedResults();
    }
}
