<form [formGroup]="verificationFormGroup" (ngSubmit)="updateVerification('icr')"
    (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">

        <!-- header section -->
        <div *ngIf="employee && employee.id" class="row bg-light lter b-b wrapper-md">

            <app-verification-edit-header [verification]="'icr'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>

            <div class="col-md-6">
                <a class="btn m-b-xs btn-sm btn-primary btn-addon" [routerLink]="['/icr/create/act']">
                    <i class="fa fa-plus"></i>Create Act Type</a>

                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createIcr()">
                    <i class="fa fa-plus"></i>
                    Add ICR Verification
                </a>
            </div>

        </div>

        <div class="wrapper-md">

            <div class="row">

                <!-- left section, remarks, basic details, id -->
                <div class="col-md-3">

                    <!--Remarks-->
                    <app-employee-remarks *ngIf="employee" [remarks]="employee.remarks"></app-employee-remarks>

                    <!--Employee Details-->
                    <app-employee-basic-details [employee]="employee" [submitting]="isSubmitting">
                    </app-employee-basic-details>

                    <!--Identity Verification-->
                    <div *ngIf="employee">
                        <div class="panel panel-default"
                            *ngFor="let identity of employee.identity_verifications; let i=index">
                            <div class="panel-heading">
                                ID Verification #{{i+1}}
                            </div>

                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <label> Status : {{identity.status_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Name : {{identity.name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Father Name : {{identity.father_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Date of Birth : {{identity.dob}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Type Of Document : {{identity.type_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Address : {{identity.address}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> State : {{identity.state_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> District : {{identity.district_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Pincode : {{identity.pincode}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Address Verification Details-->
                    <div *ngIf="employee">
                        <div class="panel panel-default"
                            *ngFor="let address of employee.address_verifications; let i=index">
                            <div class="panel-heading">
                                {{address.type}}
                            </div>

                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <label> Status : {{address.status_name}}</label>
                                    </div>

                                    <div class="col-lg-12">
                                        <label> Full Address : {{address.full_address}}</label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- main-right section for icr -->
                <div class="col-md-9">
                    <div formArrayName="formArray">
                        <div *ngFor="let verification of formArray.controls; let i=index" [formGroupName]="i">
                            <div class="panel panel-default">
                                <div class="panel-heading">
                                    <div class="row">
                                        <div class="col-md-10">
                                            ICR Verification #{{i+1}}
                                            <a *ngIf="verification.value.vcourt_id"
                                                class="btn btn-link text-main text-semibold"
                                                [routerLink]="['/vcourt', verification.value.vcourt_id]">
                                                Link to Vcourt
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-body">
                                    <div class="row" *ngIf="verification.value.id_type">
                                        <div class="form-group col-md-4">
                                            <label class="center-block">ID Type: {{verification.value.id_type}}:
                                            </label>
                                        </div>
                                        <div class="form-group col-md-4">
                                            <a href="{{verification.value.front_url}}">Front Url</a>
                                        </div>
                                        <div class="form-group col-md-4" *ngIf="verification.value.back_url">
                                            <a href="{{verification.value.back_url}}">Back Url</a>
                                        </div>
                                        <br>

                                    </div>

                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label class="center-block">Address:
                                                <input class="form-control" formControlName="address">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'state'" [labelName]="'State'"
                                                [options]="states">

                                            </app-typeahead-dropdown>

                                        </div>

                                        <div class="col-md-3 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'district'" [labelName]="'District'"
                                                [options]="districts | filterByStateId:verification.get('state_id')?.value">

                                            </app-typeahead-dropdown>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="center-block">LandMark:
                                                <input class="form-control" formControlName="landmark">
                                            </label>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label class="center-block">Pincode:
                                                <input class="form-control" formControlName="pincode">
                                            </label>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 form-group">
                                            <label class="center-block">Completed At
                                                <input type="date" class="form-control" formControlName="completed_at"
                                                    min="{{current_date}}" max="{{current_date}}"
                                                    [required]="verification.get('status_id')?.value | inArray:[1,2,3,12,13,15,16,17]">
                                            </label>
                                            <small class="text-danger" *ngIf="verification.get('completed_at')?.errors">
                                                Required
                                            </small>
                                        </div>
                                        <div *ngIf="statuses" class="col-md-3 form-group">
                                            <app-typeahead-dropdown [fGroup]="verification"
                                                [mainFormGroup]="verificationFormGroup" [formArray]="formArray"
                                                [formGName]="i" [name]="'status'" [labelName]="'Status'"
                                                [options]="statuses['icr']">
                                            </app-typeahead-dropdown>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class="center-block">QA Required
                                                <select class="form-control" formControlName="qa_required"
                                                    [required]="verification.get('status_id')?.value | inArray:[2,3,12,13,15,16,17]">
                                                    <option [value]="0">
                                                        NO
                                                    </option>
                                                    <option [value]="1">
                                                        YES
                                                    </option>
                                                </select>
                                            </label>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class="center-block">QA done
                                                <select class="form-control" formControlName="qa_done">
                                                    <option [value]="0">
                                                        NO
                                                    </option>
                                                    <option [value]="1">
                                                        YES
                                                    </option>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div *ngIf="statuses" class="row">
                                        <div *ngIf="verification.get('show_criminal_status')?.value"
                                            class="col-md-3 form-group">
                                            <label class="center-block">Criminal Status</label>
                                            <select class="form-control" formControlName="criminal_status_id">
                                                <option *ngFor="let status of statuses['icr']" value="{{status.id}}">
                                                    {{status.title}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="verification.get('show_civil_status')?.value"
                                            class="col-md-3 form-group">
                                            <label class="center-block">Civil Status</label>
                                            <select class="form-control" formControlName="civil_status_id">
                                                <option *ngFor="let status of statuses['icr']" value="{{status.id}}">
                                                    {{status.title}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col-md-12">
                                            <label class="center-block">Client Remarks:
                                                <textarea rows=5 class="form-control"
                                                    formControlName="client_remarks"></textarea>
                                            </label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('client_remarks')?.errors">
                                                Required
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 form-group">
                                            <label class="center-block">Insufficiency Raised On</label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('insuff_raised_at')?.errors">
                                                Invalid
                                            </small>
                                            <input type="date" class="form-control" formControlName="insuff_raised_at"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}">
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label class="center-block">Insufficiency Cleared On</label>
                                            <small class="text-danger"
                                                *ngIf="verification.get('insuff_cleared_at')?.errors">
                                                Invalid
                                            </small>
                                            <input type="date" class="form-control" formControlName="insuff_cleared_at"
                                                min="{{employee.created_at.slice(0,10)}}" max="{{current_date}}">
                                        </div>
                                    </div>

                                    <!-- to display icr attachments uploaded -->
                                    <div class="row" *ngIf="attachments &&  attachments[i]">
                                        <div class="col-md-3" *ngIf="attachments[i]['screenshots']; else elseDiv">
                                            <div class="row" *ngFor="let attachment of attachments[i]['screenshots']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3" *ngIf="attachments[i]['ecourt_proofs']; else elseDiv">
                                            <div class="row" *ngFor="let attachment of attachments[i]['ecourt_proofs']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3" *ngIf="attachments[i]['firs']; else elseDiv">
                                            <div class="row" *ngFor="let attachment of attachments[i]['firs']">
                                                <div class="col-md-12">
                                                    <a href="{{attachment['full_path']}}" class="btn-link">
                                                        {{attachment['file_name']}}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-template #elseDiv>
                                            <div class="col-md-3">
                                                No File Attached
                                            </div>
                                        </ng-template>
                                    </div>

                                    <!-- <div class="row">
                                        <div class="col-md-3">
                                            <label>Upload Proofs (Screenshot)
                                                <input appUploadAttachment type="file" multiple class="form-control"
                                                    attachableId="2" attachableType="icr" attachmentType="screenshot" />
                                            </label>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row" *ngIf="attachments[i]">

                    <div class="col-md-3" *ngIf="attachments[i]['screenshots']; else elseDiv">
                      <div class="row" *ngFor="let attachment of attachments[i]['screenshots']">
                        <div class="col-md-12">
                          <a href="{{attachment['full_path']}}" class="btn-link">
                            {{attachment['file_name']}}
                          </a>
                        </div>
                      </div>
                    </div>

                    <ng-template #elseDiv>
                      <div class="col-md-3">
                        No File Attached
                      </div>
                    </ng-template>

                  </div> -->
                                    <!-- case list -->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <app-cases-list [icrVerificationId]="verifications[i].id" [acts]="acts"
                                                [sections]="sections" [allSections]="allSections"
                                                (caseCreated)="onCaseCreated($event, i)"
                                                (caseUpdated)="onCaseUpdated($event, i)"
                                                (caseDeleted)="onCaseDeleted($event, i)"></app-cases-list>
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-footer text-right" [appCan]="'admin'">
                                    <div class="row">
                                        <div class="col-sm-offset-8 col-sm-4">
                                            <a class="btn btn-danger"
                                                (click)="onDeleteVerificationButtonClicked(verification.get('id')?.value,i)">
                                                Delete Verification
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
