import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-client-crm-verification-setting-modal',
    templateUrl: './client-crm-verification-setting-modal.component.html',
} )
export class ClientCrmVerificationSettingModalComponent {

    @ViewChild( 'clientCrmVerificationSettingModal', { static: false } )

    public clientCrmVerificationSettingModal!: ModalDirective;

    public verificationSetting: any;

    show ( verificationSetting: any ): void {
        this.verificationSetting = verificationSetting;
        this.clientCrmVerificationSettingModal.show();
    }


    hide (): void {
        this.clientCrmVerificationSettingModal.hide();
    }

}
