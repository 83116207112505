export class SubInstitute {
    id!: number;
    institute_id!: number;
    name!: string;
    website!: string;
    phone_number!: string;
    address!: string;
    beneficiary_name!: string;
    email!: string;
    recipient_name!: string;
    remarks!: string;
    instruction!: string;
    state_id!: number;
    state_name!: string;
    district_id!: number;
    district_name!: string;
    pincode!: string;
    type!: string;
    contacts!: any[];
}
