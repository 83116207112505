import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { FormBuilder } from '@angular/forms';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-send-to-vendor',
    templateUrl: './send-to-vendor.component.html',
    styleUrls: [ './send-to-vendor.component.css' ],
} )
export class SendToVendorComponent implements OnInit {

    @ViewChild( 'sendToVendorModal', { static: false } )
    public sendToVendorModal!: ModalDirective;

    @Input()
    checkedIds!: any[];

    @Input()
    verification!: string;

    @Output()
    onSentToVendor = new EventEmitter<boolean>();


    isSubmitting = false;


    constructor (
        private commonService: CommonService,
        private fb: FormBuilder,
        private confirmationModalService: ConfirmationModalService
    ) {
        this.initForm();
    }

    ngOnInit () {
    }


    initForm () {

    }

    show () {

        this.sendToVendorModal.show();

    }

    hide () {
        this.sendToVendorModal.hide();
    }

    onWithdrawFromVendorConfirm (): void {
        this.commonService.withDrawCaseFromVendor( this.verification, this.checkedIds ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( 'Successfully Withdrawn ', response[ 'message' ] );
                this.hide();
                this.onSentToVendor.emit( true );
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( error );
                this.hide();
            }
        }
        );
    }

    withdrawFromVendor () {

        const options: ConfirmationModalOptions = { text: 'You want to withdraw cases from the vendor:' };

        if ( this.checkedIds.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.onWithdrawFromVendorConfirm() );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }


    sendToVendor (): void {

        this.commonService.sendRequestToVendor( this.verification, this.checkedIds ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( 'Successfully Sent ', response[ 'message' ] );
                this.hide();
                this.onSentToVendor.emit( true );
            },
            error: ( error ) => {
                this.commonService.notifications.error( error );
                this.hide();
            }
        }
        );
    }

    onSendToVendorButtonClicked () {

        const options: ConfirmationModalOptions = { text: 'You want to send the selected requests to vendor:' };

        if ( this.checkedIds.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.sendToVendor() );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }

}
