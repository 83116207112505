import { User } from './user.model';

export class Company {
    id!: number;
    name!: string;
    prefix!: string;
    city!: string;
    open_batches!: string;
    official_name!: string;
    logo!: string;
    status!: number;
    metafields: MetaField[] = [];
    users: User[] = [];
    created_at!: string;
    updated_at!: string;
    employee_created_at!: string;
}

export class MetaField {
    id!: number;
    name!: string;
    created_by?: User;
    updated_by?: User;
    created_at!: string;
    updated_at!: string;
}
