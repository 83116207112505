<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8" *ngIf="company">
        <h1 class="m-n font-thin h3">{{ company.name }}</h1>
        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li><a routerLink="/companies">All Companies</a></li>
            <li><a routerLink="/companies/{{company.id}}">{{ company.name }}</a></li>
            <li><a routerLink="/companies/{{company.id}}/history">History</a></li>
        </ul>
    </div>
</div>

<div class="wrapper-md">
    <div class="col-md-6 margin-bottom">

        <div class="history">
            <table class="table table-condensed body-color">

                <thead>
                    <tr>
                        <th class="col-md-3">Updated By</th>
                        <th class="col-md-9">What was changed?</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let history of company?.histories">
                        <td>
                            <b>{{history.user}}</b> on

                            <br /> {{history.updated_at}}
                        </td>
                        <td>
                            <p *ngFor="let item of history.updated_item" style="margin: 0 0 5px;">
                                <b>{{item.key}}</b> : {{item.value}} <br />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>