<div bsModal #vehicleSearch="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="searchVehicleForm" (ngSubmit)="search()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Search Vehicle Details</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">RC / Chassis / Engine Number
                                        <input formControlName="number" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Type
                                        <select class="form-control" formControlName="type">
                                            <option value="rc_number">Rc Number</option>
                                            <option value="chassis_number">Chassis</option>
                                            <option value="engine_number">Engine</option>
                                        </select>
                                    </label>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <!-- <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="vahan"
                                            (change)="onCheckboxChange($event,'vahan')">
                                        Via Vahan
                                    </label>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="parivahan"
                                            (change)="onCheckboxChange($event,'parivahan')">
                                        Via Parivahan
                                    </label>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="state"
                                            (change)="onCheckboxChange($event,'state')">
                                        Via State
                                    </label>
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="sms" (change)="onCheckboxChange($event,'sms')">
                                        Via Sms
                                    </label>
                                </fieldset>
                            </div>

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="sms" (change)="onCheckboxChange($event,'karza')">
                                        Via Karza
                                    </label>
                                </fieldset>
                            </div> -->

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="signzy"
                                            (change)="onCheckboxChange($event,'signzy')">
                                        Via Signzy
                                    </label>
                                </fieldset>
                            </div>

                        </div>

                        <!-- <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="checkbox-inline">
                                        <input type="checkbox" value="vahan_citizen"
                                            (change)="onCheckboxChange($event,'vahan_citizen')">
                                        Via Vahan Citizen
                                    </label>
                                </fieldset>
                            </div>

                        </div> -->
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!searchVehicleForm.valid">
                            Search
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>