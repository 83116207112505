import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IcrEditComponent } from './icr-edit/icr-edit.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { IcrCreateActTypeComponent } from './icr-create-act-type/icr-create-act-type.component';
import { IcrListComponent } from './icr-list/icr-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { IcrService } from '../core/services/icr.service';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { IcrAssignedWorkListComponent } from './icr-assigned-work-list/icr-assigned-work-list.component';
import { SubmitToVcourtComponent } from './submit-to-vcourt/submit-to-vcourt.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CnrDetailsComponent } from './cnr-details/cnr-details.component';
import { CasesCreateComponent } from './cases-create/cases-create.component';
import { CasesEditComponent } from './cases-edit/cases-edit.component';
import { CasesListComponent } from './cases-list/cases-list.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
        VirtualScrollerModule,
    ],
    declarations: [
        IcrEditComponent,
        IcrListComponent,
        IcrCreateActTypeComponent,
        IcrAssignedWorkListComponent,
        SubmitToVcourtComponent,
        CnrDetailsComponent,
        CasesCreateComponent,
        CasesEditComponent,
        CasesListComponent,
    ],
    entryComponents: [
        CasesEditComponent,
        CasesCreateComponent,
    ],
    providers: [ IcrService ],
} )
export class IcrModule {
}
