import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';

import { Subscription } from 'rxjs';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { Pagination } from '../../core/models/pagination.model';
import { Verification } from '../../core/models/verification.model';
import { CommonService } from '../../core/services/common.service';
import { HistoryService } from '../../core/services/history.service';
import { VerificationService } from '../../core/services/verification.service';
import { FilterService } from './../../core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-history-list',
    templateUrl: './history-list.component.html',
    styleUrls: [ './history-list.component.css' ],
} )
export class HistoryListComponent extends ExportExcelComponent implements OnInit {


    // filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    historyStats!: any[];
    loading!: boolean;
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    verifications: Verification[] = [];
    allStatuses!: any[];
    // user_id: number;
    user: any;
    filtersSubscription!: Subscription;

    constructor (
        private historyService: HistoryService,
        private fb: FormBuilder,
        private verificationService: VerificationService,
        public global: GlobalService,
        private commonService: CommonService,
        private filterService: FilterService,
    ) {
        super();
    }

    get users () {
        return this.global.fetchUsers();
    }
    ngOnInit (): void {

        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filterResults( this.filterFormGroup.value );

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );

        this.getAllVerifications();
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            updated_after: '',
            updated_before: '',
            verification_type: 'all',
            work_query: '',
            user_id: 'all',
            filter_verification_status: [],
        } );
    }

    getAllVerifications (): void {

        // Populate the list of all verifications.
        this.verificationService.getAllVerifications().then( verifications => this.verifications = verifications );
    }

    filterResults ( params: Params ): void {

        // Getting the history for user Id.
        this.historyService.all( params ).subscribe( response => {
            this.pagination = response;
        } );

    }

    // Pagination next page.
    showPage ( page: number ): void {

        this.filterService.filterByPage( page );
    }

    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();

        // this.filterParams = new HttpParams();

        this.filterResults( this.filterFormGroup.value );

    }

    onVerificationTypeChanged (): void {
        this.allStatuses = [];
        let verification: string = this.filterFormGroup.controls[ 'verification_type' ].value;
        if ( verification === 'current_address' || verification === 'permanent_address' ) {
            verification = 'address';
        }

        this.allStatuses = this.global.fetchStatuses()[ verification ];

    }

    generateExcel (): void {
        this.historyService.getDataForExcel( this.filterFormGroup.value ).subscribe(
            response => {
                this.downloadExcel( response, 'User History Excel' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );
    }


}
