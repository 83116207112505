import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { Pagination } from 'src/app/core/models/pagination.model';

import { Subscription } from 'rxjs';
import { CommonService } from '../../../core/services/common.service';
import { CompanyRoleService } from '../../../core/services/company-role.service';
import { FilterService } from '../../../core/services/filter.service';


@Component( {
    selector: 'app-role-list',
    templateUrl: './role-list.component.html'
} )
export class RoleListComponent implements OnInit, OnDestroy {

    filter: Subscription = new Subscription;
    pagination: Pagination = new Pagination();
    filtersSubscription: Subscription = new Subscription;

    constructor (
        private companyRoleService: CompanyRoleService,
        private confirmationModalService: ConfirmationModalService,
        public filterService: FilterService,
        private commonService: CommonService,
    ) {
    }

    ngOnInit (): void {
        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );

        this.fetchAll();
    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

    filterResults ( params: Params ): void {

        const updatedParams: Params = this.updateParams( params );

        this.filter = this.companyRoleService.filter( updatedParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );
    }

    showPage ( page: number ): void {
        this.filterService.filterByPage( page );
    }


    fetchAll (): void {
        this.companyRoleService.getPaginated().subscribe( ( data ) => {
            this.pagination = data;
        } );
    }

    updateParams ( params: Params ): Params {

        params = Object.assign( {}, params );

        Object.keys( params ).forEach( key => {
            if ( key === 'company_id' ) {
                params[ key ] = params[ key ].toString();
            }
        } );

        return params;
    }

    onModalUpdated (): void {
        this.fetchAll();
    }

    deleteRole ( roleId: number ): void {
        this.companyRoleService.delete( roleId ).subscribe( ( response ) => {

            if ( response ) {

                this.fetchAll();

                this.commonService.notifications.success( 'Role Deleted Successfully!' );
            }
        } );
    }

    onDeleteRoleButtonClicked ( roleId: number ) {

        const options: ConfirmationModalOptions = { text: 'Role will be delete!' };

        this.confirmationModalService.confirm( options, () => this.deleteRole( roleId ) );
    }

}
