import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';


@Component( {
    selector: 'app-basic-form',
    templateUrl: './basic-form.component.html',
} )
export class BasicFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    parentForm!: FormGroup;

    fileTypes: any[] = [
        {
            id: 'image/*',
            name: 'Image',
        },
        {
            id: '.pdf',
            name: 'pdf',
        },
        {
            id: '.doc',
            name: 'doc',
        },
        {
            id: '.docx',
            name: 'docx',
        },
    ];

    constructor ( private fb: FormBuilder,
    ) {
    }

    basicForm = this.fb.group( {
        instructions: this.fb.control( null ),
        resume: this.fb.group( {
            visible: this.fb.control( false ),
            required: this.fb.control( false ),
        } ),
        client_employee_id: this.fb.group( {
            visible: this.fb.control( false ),
            required: this.fb.control( false ),
        } ),
        expected_joining_date: this.fb.group( {
            visible: this.fb.control( false ),
            required: this.fb.control( false ),
        } ),
    } )

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'basic', this.basicForm );
        this.parentForm = this.settingFormGroup.parent as FormGroup;
    }

    get fieldsArray (): FormArray {
        return this.parentForm.get( 'fields' ) as FormArray;
    }

    addNewRow (): void {
        this.fieldsArray.push( this.initItemRows() );
    }

    initItemRows (): FormGroup | any {
        return this.fb.group( {
            label: [ '', [ validateAllowedCharacters( '' ), Validators.required ] ],
            placeholder: [ '', [ validateAllowedCharacters( '' ) ] ],
            required: [ '', Validators.required ],
            type: [ '', Validators.required ],
            file_types: [ [] ],
            select_option: [ '', validateAllowedCharacters( ',' ) ],
        } );
    }

    get isClientEmployeeIdChecked (): boolean | any {
        return this.basicForm.get( 'client_employee_id.visible' )?.value;
    }

    get isJoiningDateChecked (): boolean | any {
        return this.basicForm.get( 'expected_joining_date.visible' )?.value;
    }

    get isResumeChecked (): boolean | any {
        return this.basicForm.get( 'resume.visible' )?.value;
    }

    onTypeSelect ( form: FormGroup | any ): void {

        const type: string = form.get( 'type' )?.value;

        if ( type === 'text' ) {

            form.get( 'placeholder' )?.setValidators( [ validateAllowedCharacters( '' ), Validators.required ] );
            form.get( 'select_option' )?.setValidators( [ validateAllowedCharacters( ',' ) ] );
            form.get( 'file_types' )?.setValidators( [] );
        } else if ( type === 'radio' || type === 'drop_down' ) {

            form.get( 'placeholder' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
            form.get( 'select_option' )?.setValidators( [ validateAllowedCharacters( ',' ), Validators.required ] );
            form.get( 'file_types' )?.setValidators( [] );
        } else if ( type === 'file' ) {

            form.get( 'placeholder' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
            form.get( 'select_option' )?.setValidators( [ validateAllowedCharacters( ',' ) ] );
            form.get( 'file_types' )?.setValidators( [ Validators.required ] );
        } else {

            form.get( 'placeholder' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
            form.get( 'select_option' )?.setValidators( [ validateAllowedCharacters( ',' ) ] );
            form.get( 'file_types' )?.setValidators( [] );
        }

        this.updateFormValueAndValidity( form );
    }

    updateFormValueAndValidity ( form: FormGroup ): void {

        form.get( 'file_types' )?.updateValueAndValidity();
        form.get( 'placeholder' )?.updateValueAndValidity();
        form.get( 'select_option' )?.updateValueAndValidity();
    }

    deleteRow ( index: number ): void {
        // control refers to your form array
        const control: FormArray = this.fieldsArray;
        // remove the chosen row
        control.removeAt( index );
    }

}
