import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EpfoService } from 'src/app/core/services/epfo.service';

@Component( {
    selector: 'app-epfo-stats',
    templateUrl: './epfo-stats.component.html',
    styleUrls: [ './epfo-stats.component.css' ]
} )
export class EpfoStatsComponent implements OnInit {
    @Input() filterParams!: HttpParams;
    stats!: any[];

    constructor (
        private route: ActivatedRoute,
        private epfoService: EpfoService
    ) { }

    ngOnInit () {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterStats();
        } );
    }

    filterStats (): void {
        if ( this.filterParams ) {
            this.epfoService.getStats( this.filterParams ).subscribe(
                response => {
                    this.stats = response;
                }
            );
        }
    }

}
