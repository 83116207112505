<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-8">
        <h1 class="m-n font-thin h3">GST Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="refresh()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-gst-stats></app-gst-stats>

<app-gst-details-modal #gstDetailsModal></app-gst-details-modal>
<app-gst-response-modal #gstResponse></app-gst-response-modal>
<app-gst-filters [filterParams]="filterParams"></app-gst-filters>
<app-gst-search-modal #gstSearch (onSearch)="onSearch()"></app-gst-search-modal>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 20%">Gst Number</th>
                        <th style="width: 20%">Gov Gst</th>
                        <th style="width: 20%">Eway Bill</th>
                        <th style="width: 20%">Timeline</th>
                        <th style="width: 20%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <strong><a (click)="gstDetailsModal.show(log.id)">{{log.number}}</a></strong>
                                </li>
                                <li>{{log.client_name}}</li>
                                <li>{{log.id}}</li>
                            </ul>

                        </td>
                        <td>
                            <div *ngIf="log.gov_success && log.gov_has_details; else govGstDetailsNotReceived">
                                <a class="btn label-success" (click)="gstResponse.show(log, 'gov_gst')">
                                    Found</a>
                            </div>
                            <ng-template #govGstDetailsNotReceived>
                                <div
                                    *ngIf="log.gov_success && log.gov_has_details==0 && log.gov_failure_reason==3; else govGstInvalidCaptchaError">
                                    <a (click)="gstResponse.show(log, 'gov_gst')" class="btn label-danger">InValid GST
                                        Number</a>
                                </div>
                            </ng-template>
                            <ng-template #govGstInvalidCaptchaError>
                                <div
                                    *ngIf="log.gov_success && log.gov_has_details==0 && log.gov_failure_reason==2; else govGstDetailsNotFound">
                                    <a (click)="gstResponse.show(log, 'gov_gst')" class="btn label-warning">InValid
                                        Captcha</a>
                                </div>
                            </ng-template>
                            <ng-template #govGstDetailsNotFound>
                                <div
                                    *ngIf="log.gov_success && log.gov_has_details==0 && log.gov_failure_reason==null; else govGstFailure">
                                    <a (click)="gstResponse.show(log, 'gov_gst')" class="btn label-danger"> Not
                                        Found</a>
                                </div>
                            </ng-template>

                            <ng-template #govGstFailure>
                                <div *ngIf="log.gov_gst; else govGstNotCreated">
                                    <a (click)="gstResponse.show(log, 'gov_gst')" class="btn label-danger">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #govGstNotCreated>
                                <div>
                                    <a class="btn label-warning">Not
                                        Initiated</a>
                                </div>
                            </ng-template>
                        </td>

                        <td>
                            <div *ngIf="log.eway_success && log.eway_has_details; else ewayBillDetailsNotReceived">
                                <a class="btn label-success" (click)="gstResponse.show(log, 'eway_bill')">
                                    Found</a>
                            </div>
                            <ng-template #ewayBillDetailsNotReceived>
                                <div
                                    *ngIf="log.eway_success && log.eway_has_details==0 && log.eway_failure_reason==3; else ewayBillInvalidCaptchaError">
                                    <a (click)="gstResponse.show(log, 'eway_bill')" class="btn label-danger">InValid GST
                                        Number</a>
                                </div>
                            </ng-template>
                            <ng-template #ewayBillInvalidCaptchaError>
                                <div
                                    *ngIf="log.eway_success && log.eway_has_details==0 && log.eway_failure_reason==2; else ewayBillDetailsNotFound">
                                    <a (click)="gstResponse.show(log, 'eway_bill')" class="btn label-warning">InValid
                                        Captcha</a>
                                </div>
                            </ng-template>
                            <ng-template #ewayBillDetailsNotFound>
                                <div
                                    *ngIf="log.eway_success && log.eway_has_details==0 && log.eway_failure_reason==null; else ewayBillFailure">
                                    <a (click)="gstResponse.show(log, 'eway_bill')" class="btn label-danger"> Not
                                        Found</a>
                                </div>
                            </ng-template>

                            <ng-template #ewayBillFailure>
                                <div *ngIf="log.eway_bill; else ewayBillNotCreated">
                                    <a (click)="gstResponse.show(log, 'eway_bill')" class="btn label-danger">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #ewayBillNotCreated>
                                <div>
                                    <a class="btn label-warning">Not
                                        Initiated</a>
                                </div>
                            </ng-template>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>Created at: {{log.created_at}}</li>
                                <li>Updated at: {{log.updated_at}}</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">

                                <a (click)="resend(log, 'gov_gst')">
                                    <i class="fa fa-refresh"></i>
                                    Resend Gov Gst
                                </a>
                                <br>
                                <a (click)="resend(log, 'eway_bill')">
                                    <i class="fa fa-refresh"></i>
                                    Resend Eway Bill
                                </a>
                            </ul>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>