<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
<div bsModal #copyAddressFromSelectedVerification="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left"></h4>
                <button type="button" class="close pull-right" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="getAddressFormGroup" (ngSubmit)="submit()">
                    <div class="modal-body">


                        <div class="row">

                            <div class="col-md-2">
                                <div cla dropdown>
                                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                                        Get Address of <span class="caret"></span>
                                    </button>
                                    <ul *dropdownMenu class="dropdown-menu" role="menu"
                                        style="max-height: 300px; overflow-y: scroll">
                                        <li role="menuitem" *ngFor="let verification of allVerifications"
                                            (click)="getAddress(verification)">
                                            <a class="dropdown-item">{{verification.title}}</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div class="col-md-10" *ngIf="addressDetails">
                                <label>
                                    <strong>{{selectedVerification.title}}</strong>
                                </label>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                Select
                                            </th>
                                            <th>
                                                Address
                                            </th>
                                            <th>
                                                State
                                            </th>
                                            <th>
                                                District
                                            </th>
                                            <th>
                                                Landmark
                                            </th>
                                            <th>
                                                Pincode
                                            </th>
                                            <th>
                                                Police Station
                                            </th>
                                            <th>
                                                Duration Of Stay
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let address of addressDetails; let i=index;">
                                            <td>
                                                <input class="" type="radio" formControlName="selected_address"
                                                    value="{{i}}">
                                            </td>
                                            <td>
                                                {{address.address}}
                                            </td>
                                            <td>
                                                {{address.state_name}}
                                            </td>
                                            <td>
                                                {{address.district_name}}
                                            </td>
                                            <td>
                                                {{address.landmark}}
                                            </td>
                                            <td>
                                                {{address.pincode}}
                                            </td>
                                            <td>
                                                {{address.police_station}}
                                            </td>
                                            <td>
                                                {{address.duration_of_stay}}
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </div>


                    <div class="modal-footer">
                        <div class="pull-right">
                            <button class="btn btn-primary" [disabled]="!getAddressFormGroup.valid">
                                Continue To Copy
                            </button>
                        </div>
                        <div class="pull-left">
                            <a class="btn btn-default" (click)="hide()"> Cancel</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>