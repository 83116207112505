import { ClientFormType } from 'src/app/core/enums/client-form-type.enum';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { Addresses, idDocuments } from '../client-forms-v2.enum'

@Component( {
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
} )
export class AddressFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;
    @Input() formType!: AbstractControl;

    addressList = Addresses;
    identityList = idDocuments;

    selectedAddress: string[] = [];
    selectedDocument: string[] = [];
    isSectionChecked: boolean = false;
    readonly addressMaxLimit: number = 2;
    readonly documentsMaxLimit: number = 5;

    addressForm = this.fb.group( {
        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] ),
    } );

    get addressVerificationArray (): FormArray | any {
        return this.addressForm.get( 'verifications' ) as FormArray;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.addressVerificationArray.length > 0;
    }

    get isDigitalForm (): boolean {
        return this.formType.value == ClientFormType.DigitalAddress
    }

    constructor ( private fb: FormBuilder, private commonService: CommonService ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'address', this.addressForm );
    }

    addAddress (): void {

        const addressItem = this.fb.group( {
            type: this.fb.control( 'any', [ Validators.required ] ),
            required: [ true, Validators.required ],
            instructions: [ null ],
            is_digital: [ this.isDigitalForm, Validators.required ],
            documents: this.fb.array( [] )
        } );

        if ( this.addressVerificationArray.length >= this.addressMaxLimit ) {

            this.commonService.notifications.error( `Address max limit is ${ this.addressMaxLimit }`, "Address" );

        } else {

            this.addressVerificationArray.push( addressItem );

        }
    }

    addDocument ( verificationIndex: number ) {

        const documentsFormArray = this.addressVerificationArray.at( verificationIndex ).get( 'documents' ) as FormArray;

        const documentFormGroup = this.initDocument();

        if ( documentsFormArray.length >= this.documentsMaxLimit ) {

            this.commonService.notifications.error( 'Document', `Document max limit is ${ this.documentsMaxLimit }` );

        } else {

            documentsFormArray.push( documentFormGroup );

        }
    }

    initDocument () {
        return this.fb.group( {
            type: [ 'any', [ Validators.required ] ],
            front_required: [ false, [ Validators.required ] ],
            back_required: [ false, [ Validators.required ] ],
        } );
    }

    onRequiredChecked ( $event: any, addressForm: any ) {

        if ( $event.target.checked ) return;

        const isDigital = addressForm?.get( 'is_digital' )?.value ?? false;

        if ( this.isDigitalForm || isDigital ) {

            const required = addressForm.get( 'required' ) as FormControl

            required.patchValue( true );

            this.commonService.notifications.error( "Address has to be required if it is digital", "Digital Address" );
        }
    }

    onDigitalChecked ( $event: any, addressForm: any ) {

        if ( !$event.target.checked ) {

            this.onDigitalAddressUncheckedForInviteForm( addressForm )

        }

        if ( $event.target.checked ) {

            this.onDigitalAddressCheckedForInviteForm( addressForm )

        }

        if ( this.addressVerificationArray.length <= 1 ) return;

        const isFirstAddressDigital = this.addressVerificationArray.at( 0 ).get( 'is_digital' )?.value
        const isSecondAddressDigital = this.addressVerificationArray.at( 1 ).get( 'is_digital' )?.value

        if ( isFirstAddressDigital && isSecondAddressDigital ) {

            addressForm.get( 'is_digital' )?.patchValue( false );

            const required = addressForm.get( 'required' )

            required.removeValidators( Validators.requiredTrue );

            required.addValidators( Validators.required );

            addressForm.updateValueAndValidity();

            addressForm.get( 'documents' )?.clear();

            this.commonService.notifications.error( "Only 1 address can be digital not both", "Digital Address" );
        }
    }

    onDigitalAddressCheckedForInviteForm ( addressForm: any ) {

        const required = addressForm.get( 'required' ) as FormControl

        required.patchValue( true );

        required.removeValidators( Validators.required );

        required.addValidators( Validators.requiredTrue )

        required.updateValueAndValidity();
    }

    onDigitalAddressUncheckedForInviteForm ( addressForm: any ) {

        addressForm.get( 'documents' ).clear();

        const required = addressForm.get( 'required' ) as FormControl

        required.removeValidators( Validators.requiredTrue );

        required.addValidators( Validators.required );

        addressForm.updateValueAndValidity();
    }

    onDocumentTypeDeleted ( addressFormGroup: any, index: number ): void {

        const documentFormArray = addressFormGroup.get( 'documents' ) as FormArray;

        const selectedDocumentTypeValue: string = documentFormArray.at( index ).get( 'type' )?.value;

        this.selectedDocument = this.selectedDocument.filter( ( documentTypeValue: string ) => documentTypeValue != selectedDocumentTypeValue );

        documentFormArray.removeAt( index );
    }

    sectionToggle ( $event: any ): void {
        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {

            this.addressVerificationArray.clear();

            return;
        }

        if ( this.isDigitalForm ) this.addAddress()
    }

    skipSelectedAddress ( index: number, $event: any ): void {
        this.selectedAddress[ index ] = $event.target.value;
    }

    skipSelectedDocument ( index: any, $event: any ): void {
        this.selectedDocument[ index ] = $event.target.value;
    }

    onAddressTypeDeleted ( index: number ): void {

        const selectedAddressTypeValue: string = this.addressVerificationArray.at( index ).get( 'type' )?.value;

        this.selectedAddress = this.selectedAddress.filter( ( addressTypeValue: string ) => addressTypeValue != selectedAddressTypeValue );

        this.addressVerificationArray.removeAt( index );
    }
}
