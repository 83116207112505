<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Licence Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>
<app-licence-stats></app-licence-stats>
<app-licence-filters [filterParams]="filterParams"></app-licence-filters>
<app-licence-html-response-modal #licenceHtmlResponse></app-licence-html-response-modal>
<app-licence-response-modal #licenceResponse></app-licence-response-modal>
<app-licence-details #licenceDetails></app-licence-details>
<app-licence-search-modal #licenceSearch (onSearch)="onSearch()"></app-licence-search-modal>
<app-loading-component [fetchingInProgress]="fetchingInProgress"></app-loading-component>


<div class="row" style="padding: 0px 0px 15px 35px">

    <a class="btn btn-primary btn-addon" (click)="resendAll('sarathi')">
        <i class="fa fa-send"></i>
        Resend Sarathi
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('sarathi_simple')">
        <i class="fa fa-send"></i>
        Resend Sarathi Simple
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('parivahan_sarathi')">
        <i class="fa fa-send"></i>
        Resend Parivahan Sarathi
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('parivahan_application')">
        <i class="fa fa-send"></i>
        Resend Parivahan App
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('state')">
        <i class="fa fa-send"></i>
        Resend State
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('parivahan_simple')">
        <i class="fa fa-send"></i>
        Resend Parivahan Simple
    </a>

</div>
<div class="row" style="padding: 0px 0px 0px 35px">
    <a class="btn btn-primary btn-addon" routerLink="import">
        <i class="fa fa-upload"></i>
        Import Excel
    </a>

    <a class="btn btn-primary btn-addon" (click)="exportLogsExcel()">
        <i class="fa fa-download"></i>
        Download Logs
    </a>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 15%">Details</th>
                        <th style="width: 10%">Sarathi</th>
                        <th style="width: 12%">Parivahan Sarathi</th>
                        <th style="width: 12%">Parivahan App</th>
                        <th style="width: 12%">State</th>
                        <th style="width: 12%">Parivahan Simple</th>
                        <th style="width: 12%">Sarathi Simple</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li><a class="btn-link text-main"
                                        (click)="licenceDetails.show(log.requested_licence_number,log.clean_dob)"><strong>{{log.requested_licence_number}}</strong></a>
                                </li>
                                <li>{{log.client_name}}</li>
                                <li>{{log.id}}-{{log.via}}</li>
                                <li>{{log.created_at}}</li>
                                <li>
                                    <a class="btn-link blue-text" (click)="licenceResponse.show(log)">View Response</a>
                                </li>

                            </ul>
                        </td>

                        <!-- Sarathi log-->
                        <td>
                            <div *ngIf="log.sarathi_log && log.sarathi_received; else sarathiNotRecevied">
                                <div *ngIf="log.sarathi_details; else sarathiDetaislNotFound">
                                    <a class="btn btn-block label-success"
                                        (click)="licenceHtmlResponse.show(log,'sarathi')">Found</a>
                                </div>
                            </div>

                            <ng-template #sarathiDetaislNotFound>
                                <a class="btn btn-block label-danger"
                                    (click)="licenceHtmlResponse.show(log,'sarathi')">Not
                                    Found</a>
                            </ng-template>

                            <ng-template #sarathiNotRecevied>
                                <div *ngIf="log.sarathi_log; else sarathiLogNotCreated">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'sarathi')">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #sarathiLogNotCreated>
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </ng-template>
                        </td>

                        <!--Parivahan Sarathi-->
                        <td>
                            <div
                                *ngIf="log.parivahan_sarathi_log && log.parivahan_sarathi_received; else parivahanSarathiNotRecevied">
                                <div *ngIf="log.parivahan_sarathi_details==1; else parivahanSarathiPrimaryRecevied">
                                    <a class="btn btn-block label-success"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_sarathi')">Found</a>
                                </div>
                            </div>

                            <ng-template #parivahanSarathiPrimaryRecevied>
                                <div *ngIf="log.parivahan_sarathi_details==2; else parivahanSarathiDetaislNotFound">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_sarathi')">Secondary Not
                                        Found</a>
                                </div>
                            </ng-template>

                            <ng-template #parivahanSarathiDetaislNotFound>
                                <a class="btn btn-block label-danger"
                                    (click)="licenceHtmlResponse.show(log,'parivahan_sarathi')">Not
                                    Found</a>
                                <div *ngIf="log.parivahan_sarathi_failure_reason==1">
                                    <small> Invalid DOB</small>
                                </div>
                            </ng-template>

                            <ng-template #parivahanSarathiNotRecevied>
                                <div *ngIf="log.parivahan_sarathi_log; else parivahanSarathiLogNotCreated">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_sarathi')">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #parivahanSarathiLogNotCreated>
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </ng-template>
                        </td>

                        <!--Parivahan Application-->
                        <td>
                            <div
                                *ngIf="log.parivahan_app_log && log.parivahan_app_received ; else parivahanAppNotRecevied">
                                <div *ngIf="log.parivahan_app_details==1; else parivahanAppPrimaryRecevied">
                                    <a class="btn btn-block label-success"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_application')">Found</a>
                                </div>
                            </div>

                            <ng-template #parivahanAppPrimaryRecevied>
                                <div *ngIf="log.parivahan_app_details==2; else parivahanAppDetaislNotFound">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_application')">Secondary Not
                                        Found</a>
                                </div>
                            </ng-template>

                            <ng-template #parivahanAppDetaislNotFound>
                                <a class="btn btn-block label-danger"
                                    (click)="licenceHtmlResponse.show(log,'parivahan_application')">Not
                                    Found</a>
                                <div *ngIf="log.parivahan_app_failure_reason==1">
                                    <small> Invalid DOB</small>
                                </div>
                            </ng-template>

                            <ng-template #parivahanAppNotRecevied>
                                <div *ngIf="log.parivahan_app_log; else parivahanAppLogNotCreated">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_application')">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #parivahanAppLogNotCreated>
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </ng-template>
                        </td>

                        <!--State-->
                        <td>
                            <div *ngIf="log.state_log && log.state_received ; else stateNotRecevied">
                                <div *ngIf="log.state_details==1; else statePrimaryRecevied">
                                    <a class="btn btn-block label-success"
                                        (click)="licenceHtmlResponse.show(log,'state')">Found</a>
                                </div>
                            </div>
                            <ng-template #statePrimaryRecevied>
                                <div *ngIf="log.state_details==2; else stateDetaislNotFound">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'state')">Secondary Not
                                        Found</a>
                                </div>
                            </ng-template>

                            <ng-template #stateNotRecevied>
                                <div *ngIf="log.state_log; else stateLogNotCreated">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'state')">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #stateDetaislNotFound>
                                <a class="btn btn-block label-danger"
                                    (click)="licenceHtmlResponse.show(log,'state')">Not
                                    Found</a>
                            </ng-template>

                            <ng-template #stateLogNotCreated>
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </ng-template>
                        </td>

                        <!--Parivahan Simple-->
                        <td>
                            <div
                                *ngIf="log.parivahan_simple_log && log.parivahan_simple_received; else parivahanSimpleNotRecevied">
                                <div *ngIf="log.parivahan_simple_details; else parivahanSimpleDetaislNotFound">
                                    <a class="btn btn-block label-success"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_simple')">Found</a>
                                </div>
                            </div>

                            <ng-template #parivahanSimpleDetaislNotFound>
                                <a class="btn btn-block label-danger"
                                    (click)="licenceHtmlResponse.show(log,'parivahan_simple')">Not
                                    Found</a>
                            </ng-template>

                            <ng-template #parivahanSimpleNotRecevied>
                                <div *ngIf="log.parivahan_simple_log; else parivahanSimpleLogNotCreated">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'parivahan_simple')">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #parivahanSimpleLogNotCreated>
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </ng-template>
                        </td>


                        <td>
                            <div
                                *ngIf="log.sarathi_simple_log && log.sarathi_simple_received; else sarathiSimpleNotRecevied">
                                <div *ngIf="log.sarathi_simple_details; else sarathiSimpleDetailsNotFound">
                                    <a class="btn btn-block label-success"
                                        (click)="licenceHtmlResponse.show(log,'sarathi_simple')">Found</a>
                                </div>
                            </div>

                            <ng-template #sarathiSimpleDetailsNotFound>
                                <a class="btn btn-block label-danger"
                                    (click)="licenceHtmlResponse.show(log,'sarathi_simple')">Not
                                    Found</a>
                            </ng-template>

                            <ng-template #sarathiSimpleNotRecevied>
                                <div *ngIf="log.sarathi_simple_log; else sarathiSimpleLogNotCreated">
                                    <a class="btn btn-block label-danger"
                                        (click)="licenceHtmlResponse.show(log,'sarathi_simple')">Failure</a>
                                </div>
                            </ng-template>

                            <ng-template #sarathiSimpleLogNotCreated>
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </ng-template>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>