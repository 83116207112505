import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { CommonService } from '../services/common.service';
import { Pagination } from '../models/pagination.model';
import { WorkAssignModalComponent } from '../../common/work-assign-modal/work-assign-modal.component';
import { Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { ConfirmationModalOptions, ConfirmationModalService } from '../services/confirmation-modal.service';

@Injectable( {
    providedIn: 'root'
} )

export abstract class VerificationList extends ExportExcelComponent {
    verification!: string;
    verifications!: any[];
    vendors!: any[];
    pagination: Pagination = new Pagination();
    ids: any = [];
    workTypes!: any[];
    data!: {};
    userToAssignWork!: number;
    buttonClicked = false;
    protected route!: ActivatedRoute;
    protected router!: Router;
    protected fb!: FormBuilder;
    // protected global!: SimpleGlobal;
    public filterParams: HttpParams = new HttpParams();
    protected commonService!: CommonService;
    protected verificationService: any;
    protected viewContainerRef!: ViewContainerRef;
    button: any;

    @ViewChild( 'workAssignModal', { static: false } )
    workAssignModal!: WorkAssignModalComponent;

    abstract filterResults (): void;

    constructor (
        public confirmationModalService: ConfirmationModalService
    ) {
        super();
    }

    subscribeToQueryChanges (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    showPage ( page: any ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        this.router.navigate( [ this.currentUrlPath ], navigationExtras );
    }

    get navigationExtras (): NavigationExtras {
        // Set our navigation extras object
        // that contains our global query params and fragment

        // TODO:: optimise this
        return {
            fragment: this.filterParams.toString(),
            queryParamsHandling: 'merge',
        };
    }

    get currentUrlPath (): string {
        return this.router.url.split( '?' )[ 0 ];
    }

    deleteAll ( verification: string ): void {

        this.commonService.deleteMultiple( this.ids, verification ).subscribe(
            {
                next: ( response: any ) => {

                    this.filterResults();

                    this.commonService.notifications.success( 'Successfully Deleted', response[ 'message' ] );

                    this.ids = [];
                },
                error: ( errors: any ) => {

                    this.commonService.notifications.error( errors );
                }
            }
        );
    }


    /**
     * delete multiple verifications
     * @param {string} verification
     */
    onDeleteAllButtonClicked ( verification: string ) {

        const options: ConfirmationModalOptions = {};

        if ( this.ids.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.deleteAll( verification ) );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }

    changeStatus ( statusId: number, statusTitle: string ): void {

        this.commonService.changestatus( this.ids, this.verification, statusId ).subscribe(
            {
                next: ( response: any ) => {
                    this.filterResults();
                    this.commonService.notifications.success( 'Verification Updated', response[ 'message' ] );

                    this.ids = [];
                },
                error: ( errors: any ) => {

                    this.commonService.notifications.error( errors );
                }
            }
        );
    }

    /**
     * change the status of multiple verifications
     * @param {number} statusId
     * @param {string} statusTitle
     */
    onChangeStatusButtonClicked ( statusId: number, statusTitle: string ) {

        const options: ConfirmationModalOptions = {};

        if ( this.ids.length > 0 ) {


            this.confirmationModalService.confirm( options, () => this.changeStatus( statusId, statusTitle ) );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );

        }
    }


    changeQaRequired ( value: number, valueTitle: string ): void {

        this.commonService.changeQaRequired( this.ids, this.verification, value ).subscribe(
            {
                next: ( response: any ) => {

                    this.filterResults();
                    this.commonService.notifications.success( 'Verification Updated', response[ 'message' ] );

                    this.ids = [];
                },
                error: ( errors: any ) => {

                    this.commonService.notifications.error( errors );
                }
            }
        );
    }


    /**
     * change the status of multiple verifications
     * @param {number} statusId
     * @param {string} statusTitle
     */
    onChangeQaRequiredButtonClicked ( value: number, valueTitle: string ) {

        const options: ConfirmationModalOptions = {}

        if ( this.ids.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.changeQaRequired( value, valueTitle ) );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }

    changeQaDone ( value: number, valueTitle: string ): void {

        this.commonService.changeQaDone( this.ids, this.verification, value ).subscribe(
            {
                next: ( response: any ) => {
                    this.filterResults();
                    this.commonService.notifications.success( 'Verification Updated', response[ 'message' ] );

                    this.ids = [];
                },
                error: ( errors: any ) => {
                    this.commonService.notifications.error( errors );

                }
            }
        );
    }

    /**
     * change the status of multiple verifications
     * @param {number} statusId
     * @param {string} statusTitle
     */
    onChangeQaDoneButtonClicked ( value: number, valueTitle: string ) {

        const options: ConfirmationModalOptions = {};

        if ( this.ids.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.changeQaDone( value, valueTitle ) );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }


    checkAll ( ev: any ) {
        if ( ev.target.checked ) {
            // TODO: Can be improved
            for ( let i = 0; i < this.pagination.data.length; i++ ) {
                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all' )[ i ] ).checked = true;
                this.ids.push( this.pagination.data[ i ].id );
            }
        } else {
            for ( let i = 0; i < this.pagination.data.length; i++ ) {
                ( <HTMLInputElement> document.getElementsByClassName( 'checkbox-all' )[ i ] ).checked = false;
                this.ids = [];
            }
        }
    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( option: any, data: any ) {
        if ( data.target.checked ) {
            this.ids.push( option )
        } else {
            const index = this.ids.indexOf( option );
            if ( index !== -1 ) {
                this.ids.splice( index, 1 );
            }
        }
    }

    deleteSingleVerification ( verificationId: number, verificationArrayKey: number ): void {

        this.verificationService.deleteVerification( verificationId ).subscribe(
            ( response: any ) => {

                // Remove the deleted verification from array and hide the row from table
                this.verifications.splice( verificationArrayKey, 1 );

                this.commonService.notifications.success( 'Verification Deleted', 'Verification Deleted Successfully' );

                this.ids = [];
            },
            ( error: any ) => {
                this.commonService.notifications.error( 'Error Occurred', 'Unable to delete Verification' );
            }
        );

    }

    /**
     *
     * @param verificationId
     * @param verificationArrayKey | key of the verifications array
     */
    onDeleteSingleVerificationButtonClicked ( verificationId: number, verificationArrayKey: number ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () =>
            this.deleteSingleVerification( verificationId, verificationArrayKey )
        );
    }

    getVendorsList () {

        this.commonService.getVendors( this.verification ).subscribe(
            response => {
                this.vendors = response;
            }
        );
    }


    exportExcel () {
        this.commonService.getDataForExcel( this.verification, this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, this.verification );
            } );
    }

    changeVendor ( vendorId: number ): void {

        this.commonService.changeVendorId( this.verification, this.ids, vendorId ).subscribe(
            {
                next: ( response: any ) => {
                    this.commonService.notifications.success( 'Vendor Updated Updated', response[ 'message' ] );
                    this.filterResults();

                    this.ids = [];
                },
                error: ( errors: any ) => {
                    this.commonService.notifications.error( errors );
                }
            }
        );
    }

    /**
     * change the Vendor Name of multiple verifications
     * @param {number} vendorId
     */
    onChangeVendorButtonClicked ( vendorId: number ) {

        const options: ConfirmationModalOptions = {};

        if ( this.ids.length > 0 ) {

            this.confirmationModalService.confirm( options, () => this.changeVendor( vendorId ) );

        } else {

            options.text = "please select any ";

            this.confirmationModalService.simpleConfirm( options );
        }
    }

    deleteDocument ( verificationId: any, documentId: any, id: string ): void {

        const data = {
            'verification': this.verification,
            'verification_id': verificationId,
            'document_id': documentId,
        };

        const button = <HTMLInputElement> document.getElementById( id );
        button.disabled = true;

        this.commonService.deleteVerificationDocument( data ).subscribe( {
            next: ( response: any ) => {
                this.filterResults();

                this.commonService.notifications.success( 'Document Deleted' );
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( 'Error Occurred', 'Unable to delete document' );
            }
        } );

    }

    onDeleteDocumentButtonClicked ( verificationId: any, documentId: any, id: string ): void {

        const button = <HTMLInputElement> document.getElementById( id );

        button.disabled = true;

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () =>
            this.deleteDocument( verificationId, documentId, id )
        );
    }

    deleteAttachment ( verificationId: any, attachmentId: any, id: string ): void {

        const data = {
            'verification': this.verification,
            'verification_id': verificationId,
            'attachment_id': attachmentId,
        };

        this.commonService.deleteVerificationAttachment( data ).subscribe( {
            next: ( response: any ) => {

                this.filterResults();

                this.commonService.notifications.success( 'Attachment Deleted' );
            },
            error: ( error: any ) => {
                this.commonService.notifications.error( 'Error Occurred', 'Unable to delete Attachment' );
            }
        } );

    }

    onDeleteAttachmentButtonClicked ( verificationId: any, attachmentId: any, id: string ): void {

        const button = <HTMLInputElement> document.getElementById( id );

        button.disabled = true;

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () =>
            this.deleteAttachment( verificationId, attachmentId, id )
        );

    }

    deleteFile ( verificationId: any, fileType: any, id: string ) {

        const data = {
            'verification': this.verification,
            'verification_id': verificationId,
            'file_type': fileType,
        };

        const button = <HTMLInputElement> document.getElementById( id );

        button.disabled = true;

        this.commonService.deleteFile( data ).subscribe(
            response => {

                this.filterResults();

                this.commonService.notifications.success( 'File Deleted' );
            },
            error => {
                this.commonService.notifications.error( 'Error Occurred', 'Unable to delete' );
            }
        );

    }

    onDeleteFileButtonClicked ( verificationId: any, fileType: any, id: string ) {

        const button = <HTMLInputElement> document.getElementById( id );

        button.disabled = true;

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () =>
            this.deleteFile( verificationId, fileType, id )
        );
    }

    // Call back from the modal to update details
    onModalUpdated () {
        this.ids = [];
        this.filterResults();
    }
}

