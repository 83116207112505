<div class="wrapper-md">

    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Edit Company
                </div>
                <div class="panel-body">
                    <form [formGroup]="editForm" (ngSubmit)="updateCompany()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <input formControlName="company_id" class="form-control form-control-lg" type="hidden"
                                    ngModel="{{company.id}}" />
                                <label>Company name</label>
                                <input formControlName="company_name" class="form-control form-control-lg" type="text"
                                    ngModel="{{company.name}}" />

                            </fieldset>
                            <fieldset class="form-group">
                                <label>Company official name </label>
                                <input formControlName="company_official_name" class="form-control form-control-lg"
                                    type="text" ngModel="{{company.official_name}}" />

                            </fieldset>
                            <fieldset class="form-group">
                                <label>Company city </label>
                                <input formControlName="company_city" class="form-control form-control-lg" type="text"
                                    ngModel="{{company.city}}" />

                            </fieldset>
                            <fieldset *ngIf="company.logo">
                                <label>
                                    <a href="{{company.logo}}" class="btn-link" target="_blank">Company Logo</a>
                                </label>
                            </fieldset>
                            <fieldset>

                                <label>Upload Logo
                                    <input type="file" class="form-control" id="company-logo"
                                        (change)="onUploadCompanyLogo($event)" />
                                </label>
                            </fieldset>

                            <fieldset class="form-group mt-2">
                                <label>Company Status </label>
                                <select formControlName="status" ngModel="{{company.status}}"
                                    class="form-control form-control-lg">
                                    <option [selected]="true" [disabled]="true">Select Company Status</option>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>
                                </select>
                            </fieldset>

                            <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!editForm.valid">
                                Update
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>