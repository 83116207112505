import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-download-params',
    templateUrl: './download-params.component.html'
} )
export class DownloadParamsComponent implements OnInit {

    @Input() params: any;

    constructor ( public bsModalRef: BsModalRef ) { }

    ngOnInit (): void {
    }

    onClose (): void {
        this.bsModalRef.hide();
    }
}
