import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-vendor-dropdown',
    templateUrl: './vendor-dropdown.component.html',
    styleUrls: [ './vendor-dropdown.component.css' ],
} )
export class VendorDropdownComponent implements OnInit {

    // Id of the verification
    // will be used for updating status & other actions
    @Input() verificationId!: number;

    // Which verification is in use here
    @Input() verification!: string;

    @Input() vendorId!: number;

    @Input() verificationDate!: string;

    @Input() vendorList!: any[];

    @Input() sentToVendorDate!: string;

    @Output() onVerificationUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();


    // Variable to enable/Disable Drop Down
    disabled!: boolean;

    constructor (

        public common: CommonService,
    ) { }

    ngOnInit (): void {

        if ( this.verificationDate != null && this.common.userLevelId() < 4 ) {
            this.disabled = true;
        } else {
            this.disabled = false;
        }
    }

    onStatusSelected ( selectedVendorId: number ): void {

        this.common.changeVendorId( this.verification, [ this.verificationId ], selectedVendorId ).subscribe(
            ( response: any ) => {
                this.onVerificationUpdated.emit( true );
                this.common.notifications.success( 'Vendor Changed', response[ 'message' ] );
            }
        );
    }

    onDateChanged ( sentToVendorDate: string ): void {

        this.common.updateSentToVendorOn( this.verification, [ this.verificationId ], sentToVendorDate ).subscribe(
            ( response: any ) => {
                this.onVerificationUpdated.emit( true );
                this.common.notifications.success( 'Vendor Date updated', response[ 'message' ] );
            }
        );

    }

}
