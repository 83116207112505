<div class="panel panel-default" [formGroup]="documentVerificationForm">
    <div class=" panel-heading">
        Document Verification
        <h4 class="panel-title pull-right">

            <div class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">
                <span class="glyphicon">
                    <input type="checkbox" (click)="sectionToggle($event)" [checked]="showSection"></span>

            </div>
        </h4>
    </div>
    <div id="collapse-document" [className]="showSection ? 'panel-collapse collapse in' : 'panel-collapse collapse'">
        <div class="panel-body">
            <div class="col-md-12" style="margin-bottom:5px ;">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"></textarea>
            </div>

            <div class="col-md-5 margin-bottom-10">
                <button type="button" (click)="addDocument()" class="btn btn-primary">Add</button>
            </div>

            <div class="col-md-7 margin-bottom-10 text text-danger" *ngIf="documentVerificationArray.length==0">
                Please select atleast one verification
            </div>

            <div class="col-md-12 margin-bottom-10"
                *ngFor="let idForm of documentVerificationArray.controls;let i=index;">
                <div class="row" [formGroup]="idForm">

                    <div class="col-md-5">
                        <select formControlName="sub_type" class="form-control" (change)="skipSelectedItem(i,$event)">
                            <option [disabled]="selectedOption.includes(document.value)"
                                *ngFor="let document of documentVerificationList" [value]="document.value">
                                {{document.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <input type="checkbox" formControlName="front_required"> Is Front Required
                    </div>
                    <div class="col-md-3">
                        <input type="checkbox" formControlName="back_required"> Is Back Required
                    </div>
                    <div class="col-md-1">
                        <button type="button" (click)="onDocumentTypeDeleted(i)" class="btn"><i
                                class="fa fa-trash text text-danger" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>




        </div>
    </div>
</div>