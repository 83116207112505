import { ErrorMessages } from '../../models/error-message.model';

export const ErrorMessage: ErrorMessages = {
    required: 'Required',
    onlySpaceNotAllow: 'Spaces are not allowed',
    alphaWithSpace: 'Only alphabets are allowed',
    mobile: '10 Digit valid mobile allowed',
    email: 'Invalid email address',
    invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
    minlength: function ( length = 2 ) {
        return `It should contain minimum ${ length } characters`;
    },
    maxlength: function ( length = 60 ) {
        return `It should contain maximum ${ length } characters`;
    },
    companyName: 'Only alpha-numeric characters, dots and space is allowed',
    pincode: 'Pincode must be 6 digits',
    year: 'Invalid year format',
    date: 'Invalid date format hint:YYYY-MM-DD',
    numberOnly: 'Only numeric value allowed',
    endDateAfterJoiningDate: 'Date should be greater than joining date',
    dateOfBirth: 'Date should not be greater than today date',
    confirmPassword: 'Both password must be same',
    password: 'Password should be a minimum of 6 characters and should include atleast 1 digit, 1 uppercase & 1 lowercase letter and 1 symbol',
    lowercase: "It should contain lowercase character",
    uppercase: "It should contain uppercase character",
    specialCharacter: "It should contain special character",
    number: "It should contain number",
}
