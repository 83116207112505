import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class RemarkService {

    constructor ( private api: ApiService ) {
    }

    filter ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'remarks', params );
    }

    getDataForExcel ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'remarks/download', params );
    }
}
