<form [formGroup]="downloadFormGroup" (ngSubmit)="submit()" (keydown.enter)="$event.preventDefault()">


    <div class="modal-header">
        <h4 class="modal-title pull-left">Download</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>


    <div class="modal-body">

        <fieldset *ngIf="!hasDownloadStarted">

            <fieldset class="form-group">
                <label class="center-block"><b>Purpose Of Download</b> (Why you are downloading this data? )</label>
                <input formControlName="purpose" class="form-control form-control-lg" />
            </fieldset>
        </fieldset>

        <div class="row" *ngIf="hasDownloadStarted">

            <div class="col-md-12">
                Timer: {{totalTime}} Seconds
            </div>

            <div class="col-md-12">
                <progressbar [value]="percentage" type="success" [striped]="true">{{ percentage }}%</progressbar>
            </div>
            <div class="col-md-12" *ngIf="!isDownloadStatsPresent">
                Preparing For Download...
            </div>
            <div class="col-md-12" *ngIf="isEstimatedTimePresent">
                Estimated Time: {{ estimatedTime }} seconds
            </div>
            <div class="col-md-12" *ngIf="isDownloadStatsPresent">
                Downloaded: {{ downloadedCount }} of {{ maxCount }}
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <div class="pull-right">
            <button class="btn btn-default btn-primary" [disabled]="!downloadFormGroup.valid" *ngIf="!isSubmitting">
                Download
            </button>
        </div>
        <button type="button" class="btn btn-default" (click)="onClose()" *ngIf="!isSubmitting">Close</button>

        <button class="btn btn-default btn-danger" (click)="cancellProcess($event)" *ngIf="isSubmitting">
            Cancel Download
        </button>
    </div>
</form>