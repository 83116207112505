import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { AadhaarService } from '../../core/services/aadhaar.service';
import { CommonService } from '../../core/services/common.service';


@Component( {
    selector: 'app-uidai-filters',
    templateUrl: './uidai-filters.component.html',
    styleUrls: [ './uidai-filters.component.css' ],
} )
export class UidaiFiltersComponent extends ExportExcelComponent implements OnInit {

    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    currentUrl!: string;
    clients!: any[];
    errors!: any[];
    data: any[] = [];
    fetchingInProgress: boolean = false;

    constructor (
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService,
        private aadhaarService: AadhaarService,
    ) {
        super();
    }

    ngOnInit (): void {
        this.currentUrl = this.router.url.split( '?' )[ 0 ];

        this.initForm();

        this.setDefault();

        let params: HttpParams = new HttpParams();
        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

        this.aadhaarService.getErrorMessages().subscribe(
            response => {
                this.errors = response;
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );

        this.filterResults();
    }

    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            aadhaar_number: 'all',
            client_api_id: 'all',
            via: 'all',
            created_after: 'all',
            created_before: 'all',
            errors: [ [] ],
            uidai_status: 'all',
            verifying_source: 'uidai',
            page: 1,
        } );
    }

    filterResults (): void {

        this.filterFormGroup.get( 'page' )?.setValue( 1 );

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };
        // Get the current url string & split it into 2 part by '?'
        // Get only the first part which is the path
        // eg. 'identity?xyx=abc' in url will return only 'identity'
        const currentUrlPath: string = this.router.url.split( '?' )[ 0 ];
        // Navigate to current path along with our set query string
        // This will add query params to the urls
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    exportLogsExcel (): void {

        this.fetchingInProgress = true;
        this.aadhaarService.getDataForExcel( this.filterParams ).subscribe(
            response => {

                this.data = this.data.concat( response.data );

                if ( response[ 'next_page_url' ] ) {

                    this.filterParams = this.filterParams.set( 'page', response[ 'next_page_url' ].split( '=' )[ 1 ] );

                    this.exportLogsExcel();
                } else {
                    this.commonService.notifications.success( 'All Data Fetched' );
                    this.downloadExcel( this.data, 'Aadhaar Logs Excel' );
                    this.fetchingInProgress = false;
                    this.data = [];
                }
            } );
    }

    onSearch (): void {
        this.filterResults();
    }

    resendAll (): void {
        this.aadhaarService.resendAll( this.filterParams ).subscribe( response => {
            this.commonService.notifications.success( ' Request Resent' );
        },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    private setDefault (): void {

        this.filterParams.keys().forEach( key => {

            const value: any = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {

                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }


}
