import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { Errors } from 'src/app/core/models/errors.module';
import { CommonService } from 'src/app/core/services/common.service';
import { WorkService } from 'src/app/core/services/work.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-work-type-list-edit',
    templateUrl: './work-type-edit.component.html',
    styleUrls: [ './work-type-edit.component.css' ],
} )
export class WorkTypeEditComponent implements OnInit {
    @ViewChild( 'workTypeEdit', { static: false } )
    public workTypeEdit!: ModalDirective;

    workTypeEditFormGroup!: FormGroup;
    submitted!: boolean;
    errors!: Errors;
    completion_statuses: number[] = [];
    work_types: string[] = [];
    verifications!: string;
    work_type: string = '';
    work_type_id!: number;

    @Output() onWorkTypeEdit = new EventEmitter<boolean>();

    constructor (
        protected route: ActivatedRoute,
        protected router: Router,
        protected fb: FormBuilder,
        protected commonService: CommonService,
        protected http: HttpClient,
        private workService: WorkService,
        public global: GlobalService
    ) { }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit () {
        this.initForm();
    }

    initForm (): void {
        this.workTypeEditFormGroup = this.fb.group( {
            work_types: [
                { value: this.work_type, disabled: true },
                Validators.required,
            ],
            title: [ '', Validators.required ],
            description: [ '' ],
            completion_statuses: [] = [],
        } );
        this.getWorkType();
    }

    show ( workTypeValue: any ) {
        this.work_type_id = +workTypeValue.id;
        this.work_type = workTypeValue.work_type;
        this.verifications = workTypeValue.work_type;
        this.initForm();
        const statuses = workTypeValue.completion_statuses;
        const data = [];
        for ( const key in statuses ) {
            data.push( statuses[ key ].workable_status );
        }
        this.workTypeEditFormGroup.setValue( {
            work_types: workTypeValue.work_type,
            title: workTypeValue.title,
            description: workTypeValue.description,
            completion_statuses: data,
        } );
        this.workTypeEdit.show();
    }

    hide () {
        this.workTypeEdit.hide();
    }

    updateForm (): void {
        this.errors = new Errors();

        this.workTypeEditFormGroup.value.work_type = this.workTypeEditFormGroup.getRawValue().work_types;

        this.UpdateWorkType( this.workTypeEditFormGroup.value, this.work_type_id );
    }

    UpdateWorkType ( params: any, work_type_id: number ): void {
        this.workService.updateWorkType( params, work_type_id ).subscribe(
            ( response ) => {
                if ( response.success === true ) {
                    this.submitted = false;
                    this.hide();
                    this.onWorkTypeEdit.emit( true );
                    this.commonService.notifications.success(
                        'Success',
                        'Edited Successfully'
                    );
                }
            },
            ( error ) => {
                this.commonService.notifications.error( 'Error', 'Unable to Edit' );
                this.errors = error;
            }
        );
    }

    getWorkType (): void {
        this.workService.getWorkType().subscribe(
            ( response ) => {
                this.work_types = response;
            },
            ( error ) => {
                this.errors = error;
            }
        );
    }
}
