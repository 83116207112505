import { Params, Router } from '@angular/router';

import { catchError, finalize, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { JwtService } from './jwt.service';
import { ToastrService } from 'ngx-toastr';

@Injectable( {
    providedIn: 'root'
} )
export class ApiService {

    constructor (
        private http: HttpClient,
        private jwtService: JwtService,
        private envService: EnvironmentService,
        private router: Router,
        private notifications: ToastrService
    ) {
    }

    get apiUrl (): string { return this.envService.apiUrl; }

    get headers (): HttpHeaders {

        const headersConfig: {} = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem( 'token' ),
        };

        return new HttpHeaders( headersConfig );
    }

    private downloadExcelHeader ( token: string ): HttpHeaders {
        const headersConfig: {} = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem( 'token' ),
            'Download-Token': token,
        };

        return new HttpHeaders( headersConfig );
    }

    get uploadHeaders (): HttpHeaders {

        const headersConfig: {} = {
            'Authorization': 'Bearer ' + localStorage.getItem( 'token' ),
        };

        return new HttpHeaders( headersConfig );
    }

    get ( path: string, params: Params = new HttpParams() ): Observable<any> {
        return this.http.get<any>( `${ this.apiUrl }${ path }`, { headers: this.headers, params } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ),
            finalize( () => {
                this.onRequestEnded();
            } ) );
    }

    put ( path: string, body: Object = {}, ): Observable<any> {
        return this.http.put<any>( `${ this.apiUrl }${ path }`, JSON.stringify( body ), { headers: this.headers } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ),
            finalize( () => {
                this.onRequestEnded();
            } ) );
    }

    post ( path: string, body: Object = {}, params: HttpParams = new HttpParams() ): Observable<any> {
        return this.http.post<any>( `${ this.apiUrl }${ path }`, JSON.stringify( body ), { headers: this.headers, params } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ) );
    }

    delete ( path: string, params: HttpParams = new HttpParams() ): Observable<any> {
        return this.http.delete<any>( `${ this.apiUrl }${ path }`, { headers: this.headers, params } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ) );
    }

    // // get the paginated data
    public getData ( page: number ): any {
        return this.http.get<any>( `${ this.apiUrl }${ page }?token=${ this.jwtService.getToken() }` ).pipe(
            map( ( res: HttpResponse<any> ) => res ) );
    }

    onRequestStarted (): void {
        // console.log( 'this.onRequestStarted()' );
    }

    onRequestSuccess (): void {
        // console.log( 'this.onRequestSuccess()' );
    }

    onRequestFailed (): void {
        // console.log( 'this.onRequestFailed()' );
    }

    onRequestEnded (): void {
        // console.log( 'this.onRequestEnded()' );
    }

    getCnrDetails ( path: string, params: HttpParams = new HttpParams() ): Observable<any> {
        return this.http.get<any>( `${ this.apiUrl }${ path }`, { headers: this.headers, params } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ),
            finalize( () => {
                this.onRequestEnded();
            } ) );
    }

    upload ( path: string, body: FormData ): Observable<any> {

        return this.http.post<any>( `${ this.apiUrl }${ path }`, body, { headers: this.uploadHeaders } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ) );
    }

    private formatErrors ( exception: any ): any {

        if ( exception instanceof HttpErrorResponse ) {

            // unauthorized error
            if ( exception.status === 401 ) {
                this.jwtService.destroyToken();
                this.router.navigate( [ '/login' ] );
            }

            // validation error
            if ( exception.status === 422 ) {

                // main error message
                this.notifications.error( exception?.error?.error, exception?.error?.message, { timeOut: 10000 } );

                // loop for each validation error
                if ( exception.error?.errors ) {

                    Object.keys( exception.error.errors ).forEach( key => {
                        this.notifications.error( key, exception.error.errors[ key ][ 0 ], { timeOut: 10000 } );
                    } );
                }
            }
        }

        //TODO: update it or find better solution
        return observableThrowError( exception );
    }

    getExcel ( token: string, path: string, params: Params = new HttpParams() ): Observable<any> {
        return this.http.get<any>( `${ this.apiUrl }${ path }`, { headers: this.downloadExcelHeader( token ), params } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ),
            finalize( () => {
                this.onRequestEnded();
            } ) );
    }

    postExcel ( token: string, path: string, body: Object = {}, params: Params = new HttpParams() ): Observable<any> {
        return this.http.post<any>( `${ this.apiUrl }${ path }`, JSON.stringify( body ), { headers: this.downloadExcelHeader( token ), params } ).pipe(
            catchError( ( error ) => this.formatErrors( error ) ),
            map( ( res: HttpResponse<any> ) => res ),
            finalize( () => {
                this.onRequestEnded();
            } ) );
    }

}
