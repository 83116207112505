import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NavigationExtras, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VerificationStatusEditComponent } from '../verification-status-edit/verification-status-edit.component';
import { Pagination } from 'src/app/core/models/pagination.model';
import Status from 'src/app/core/models/status.model';
import { FilterService } from 'src/app/core/services/filter.service';
import { VerificationStatusService } from 'src/app/core/services/verification-status.service';


@Component( {
    selector: 'app-verification-status-list',
    templateUrl: './verification-status-list.component.html',
    styleUrls: [ './verification-status-list.component.css' ],
} )
export class VerificationStatusListComponent implements OnInit, OnDestroy {

    statuses!: Status[];
    pagination: Pagination = new Pagination();
    loading = true;
    filtersSubscription!: Subscription;
    filterParams: HttpParams = new HttpParams();
    filterFormGroup!: FormGroup;
    verifications: string[] = [
        'address',
        'document',
        'education',
        'employment',
        'icr',
        'identity',
        'globaldb',
        'pcc',
        'reference',
    ];

    @ViewChild( VerificationStatusEditComponent, { static: false } ) VerificationStatusEdit!: VerificationStatusEditComponent;


    constructor (
        private verifcationStatusService: VerificationStatusService,
        private router: Router,
        private filterService: FilterService,
        private fb: FormBuilder ) {

        this.initForm();

    }

    ngOnInit (): void {

        this.initForm();

        this.updateFormFilter();

        this.filterResults();

        this.filterParams = this.filterService.httpParams;

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterByParams( new HttpParams( { fromObject: params } ) );

        } );


    }


    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            verification: [ 'all' ],
        } );
    }

    filterByParams ( params: any ): void {

        this.verifcationStatusService.getStatuses( params ).subscribe( response => {

            this.loading = false;
            this.pagination = response;

        } );

    }

    showPage ( page: any ): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras ).then( () => this.ngOnInit() );
    }

    showEditModal ( status: Status ): void {
        this.VerificationStatusEdit.show( status );
    }


    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }

    filterResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();

        this.filterResults();

    }

    ngOnDestroy (): void {
        this.filtersSubscription.unsubscribe();
    }

}
