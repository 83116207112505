import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaField } from 'src/app/core/models/company.model';
import { MetafieldService } from 'src/app/core/services/metafield.service';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { validateDate } from '../../common/validators/date-validator.directive';
import { validateLength } from '../../common/validators/length-validator.directive';
import { validateNumericCharacters } from '../../common/validators/numeric-validator.directive';
import { ImportExcelComponent } from '../../core/components/import-excel/import-excel.component';
import { CommonService } from '../../core/services/common.service';
import { LotService } from '../../core/services/lot.service';
import { validateEmail } from 'src/app/common/validators/email-validator.directive';
import { ValidationMessages } from 'src/app/core/interfaces/validation-messages';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-employee-import',
    templateUrl: './employee-import.component.html',
    styleUrls: [ './employee-import.component.css' ],
    providers: [ LotService, CommonService ],
} )
export class EmployeeImportComponent extends ImportExcelComponent implements OnInit {

    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;
    override rawInput: boolean = true;
    uploadForm!: FormGroup;
    lotId!: number;
    validationMessages: ValidationMessages = {};
    metaFields: MetaField[] = [];

    // for returning unformatted text while parsing excel sheet

    constructor (
        private lotService: LotService,
        protected route: ActivatedRoute,
        protected commonService: CommonService,
        protected override fb: FormBuilder,
        protected router: Router,
        protected metafieldService: MetafieldService
    ) {
        super( fb );
        this.initForm();
    }

    ngOnInit (): void {

        this.route.params.subscribe( params => {
            this.lotId = +params[ 'id' ];
        } );

        this.lotService.find( this.lotId ).subscribe( lot => {

            this.metafieldService.getCompanyMetafields( lot.company_id ).subscribe( fields => {

                this.metaFields = fields;

            } );

        } );

    }

    initForm (): void {

        // initialize the upload excel form.
        this.uploadForm = this.fb.group( {
            excel: [ '', Validators.required ],
        } );
    }

    // Initialize the excelForm.
    initExcelForm (): void {

        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void {
        this.validationMessages[ 'name' ] = 'Required';
        this.validationMessages[ 'father_name' ] = 'No special characters allowed.';
        this.validationMessages[ 'ca_pincode' ] = '6 digits allowed';
        this.validationMessages[ 'pa_pincode' ] = '6 digits allowed';
        this.validationMessages[ 'id_pincode' ] = '6 digits allowed';
        this.validationMessages[ 'icr_pincode' ] = '6 digits allowed';
        this.validationMessages[ 'icr_pincode_2' ] = '6 digits allowed';
        this.validationMessages[ 'reference_pincode' ] = '6 digits allowed';
        this.validationMessages[ 'reference_number' ] = '10 digits allowed';
        this.validationMessages[ 'dob' ] = 'Allowed: "dd-mm-yyyy, yyyy" ';
        this.validationMessages[ 'recruiter_name' ] = "Only alphabets are allowed"
        this.validationMessages[ 'recruiter_email' ] = "Invalid email address";
        this.validationMessages[ 'recruiter_number' ] = "Invalid mobile number";
    }

    applyValidations (): void {

        this.excelFormArray.controls.forEach( form => {


            if ( form.get( 'name' ) ) {
                form.get( 'name' )?.setValidators( [ Validators.required, validateAllowedCharacters( '' ) ] );
                form.get( 'name' )?.updateValueAndValidity();
            }
            if ( form.get( 'father_name' ) ) {
                form.get( 'father_name' )?.setValidators( [ validateAllowedCharacters( '()/,' ) ] );
                form.get( 'father_name' )?.updateValueAndValidity();
            }
            if ( form.get( 'ca_pincode' ) ) {
                form.get( 'ca_pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
                form.get( 'ca_pincode' )?.updateValueAndValidity();
            }
            if ( form.get( 'pa_pincode' ) ) {
                form.get( 'pa_pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
                form.get( 'pa_pincode' )?.updateValueAndValidity();
            }
            if ( form.get( 'id_pincode' ) ) {
                form.get( 'id_pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
                form.get( 'id_pincode' )?.updateValueAndValidity();
            }
            if ( form.get( 'reference_pincode' ) ) {
                form.get( 'reference_pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
                form.get( 'reference_pincode' )?.updateValueAndValidity();
            }
            if ( form.get( 'icr_pincode' ) ) {
                form.get( 'icr_pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
                form.get( 'icr_pincode' )?.updateValueAndValidity();
            }
            if ( form.get( 'reference_number' ) ) {
                form.get( 'reference_number' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '10' ) ] );
                form.get( 'reference_number' )?.updateValueAndValidity();
            }
            if ( form.get( 'dob' ) ) {
                form.get( 'dob' )?.setValidators( [ validateDate( 'dd-mm-yyyy,yyyy' ) ] );
                form.get( 'dob' )?.updateValueAndValidity();
            }

            if ( form.get( 'icr_pincode_2' ) ) {
                form.get( 'icr_pincode_2' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
                form.get( 'icr_pincode_2' )?.updateValueAndValidity();
            }

            if ( form.get( 'recruiter_name' ) ) {
                form.get( 'recruiter_name' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
                form.get( 'recruiter_name' )?.updateValueAndValidity();
            }

            if ( form.get( 'recruiter_email' ) ) {
                form.get( 'recruiter_email' )?.setValidators( [ validateEmail() ] );
                form.get( 'recruiter_email' )?.updateValueAndValidity();
            }

            if ( form.get( 'recruiter_number' ) ) {

                form.get( 'recruiter_number' )?.setValidators( [
                    Validators.pattern( /^(\+|\d)[0-9 ,+-]*$/ )
                ] );

                form.get( 'recruiter_number' )?.updateValueAndValidity();
            }

            this.setValidationMessages();
        } );
    }

    importEmployees (): void {

        this.addMetaFieldsInForm( this.excelFormArray );

        this.lotService.importEmployees( this.lotId, this.excelForm.value ).subscribe(

            ( response: any ) => {

                if ( response[ 'success' ] ) {

                    this.commonService.notifications.success( 'Success', response[ 'message' ] );

                    this.isSubmitting = false;

                    this.router.navigate( [ 'lots/' + this.lotId ] );

                } else {

                    this.commonService.displayValidationErrors( response[ 'errors' ] );

                    this.isSubmitting = false;

                }

            },

            error => {

                this.commonService.displayValidationErrors( error );

                this.commonService.notifications.error( 'Error Occured' );

                this.isSubmitting = false;

            },

            () => {

                this.isSubmitting = false;
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
