export const UsersStub = [
    {
        "Name": "Rajesh Kumar",
        "Email": "rajesh.kumar@instaveritas.com",
        "Level_Id": "1",
        "Designation": "Software Engineer",
        "DOB": "1990-08-15",
        "Mobile_Number": "9876543210"
    },
    {
        "Name": "Priya Sharma",
        "Email": "priya.sharma@instaveritas.com",
        "Level_Id": "2",
        "Designation": "Senior Software Engineer",
        "DOB": "1985-11-22",
        "Mobile_Number": "9876543211"
    },
    {
        "Name": "Anil Mehta",
        "Email": "anil.mehta@instaveritas.com",
        "Level_Id": "3",
        "Designation": "Project Manager",
        "DOB": "1982-06-30",
        "Mobile_Number": "9876543212"
    },
    {
        "Name": "Sunita Patil",
        "Email": "sunita.patil@instaveritas.com",
        "Level_Id": "4",
        "Designation": "Team Lead",
        "DOB": "1988-01-12",
        "Mobile_Number": "9876543213"
    },
    {
        "Name": "Vikram Singh",
        "Email": "vikram.singh@instaveritas.com",
        "Level_Id": "5",
        "Designation": "Product Manager",
        "DOB": "1980-04-05",
        "Mobile_Number": "9876543214"
    }
];
