import { Directive, ElementRef, Input } from '@angular/core';
import { PermissionsService } from '../../core/services/permissions.service';
import { AuthGroup } from 'src/app/core/models/authorization.types';
import { JwtService } from 'src/app/core/services/jwt.service';

@Directive( {
    selector: '[appHide]',
} )
export class HideDirective {

    @Input( 'appHide' )
    permission!: AuthGroup | any; // Required permission passed in

    constructor ( private el: ElementRef, private jwtService: JwtService ) { }

    ngOnInit () {

        if ( this.jwtService.getToken() != null ) {
            const payload = this.payload();

            if ( payload.level_id === 5 ) {
                return true;
            }

            if ( payload.permissions.find( ( x: string ) => {
                return x.trim().toUpperCase() === this.permission.trim().toUpperCase();
            } ) ) {
                return true;
            }
            else {
                this.el.nativeElement.remove();
            }


        }

        return true;

    }


    payload () {
        const payload = localStorage?.getItem( 'token' )?.split( '.' )[ 1 ];

        return this.decode( payload );
    }

    decode ( payload: any ) {
        return JSON.parse( atob( payload ) );
    }


}

