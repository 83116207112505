<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">
        Client CRM List
    </h1>
</div>

<div class="wrapper-md" [appCan]="'admin'">
    <div class="row">
        <div class="col-lg-12">
            <a class="btn btn-primary btn-addon" [routerLink]="['/client-crm/create']"
                [queryParams]="{companyId:companyId}">
                <i class="fa fa-plus"></i>
                Create Client Crm
            </a>
        </div>
    </div>
</div>

<app-client-crm-headers-modal #clientCrmHeadersModal></app-client-crm-headers-modal>
<app-client-crm-params-modal #clientCrmParamsModal></app-client-crm-params-modal>
<app-client-crm-verification-setting-modal
    #clientCrmVerificationSettingModal></app-client-crm-verification-setting-modal>

<app-client-crm-filters></app-client-crm-filters>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} lots out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <!-- <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div> -->
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive mt-3">

            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Id </th>
                        <th>Company Name </th>
                        <th>Lot Name</th>
                        <th>Type </th>
                        <th>Url </th>
                        <th>Headers </th>
                        <th>Params </th>
                        <th>Verification Settings </th>
                        <th [appCan]="'admin'"> Action </th>
                    </tr>
                </thead>
                <tbody *ngIf="pagination">
                    <tr *ngFor="let response of pagination.data">
                        <td>
                            {{response.id}}
                        </td>
                        <td>
                            <a class="btn-link text-main text-semibold"
                                [routerLink]="['/companies', response.company_id]">
                                {{response.company_name}}
                            </a>
                        </td>
                        <td>
                            <a class="btn-link text-main text-semibold" [routerLink]="['/lots', response.lot_id]">
                                {{ response.lot_name }}
                            </a>
                        </td>
                        <td>
                            {{response.type | titlecase}}
                        </td>
                        <td>
                            {{response.url}}
                        </td>
                        <td>
                            <a class="btn btn-primary btn-addon btn-xs"
                                (click)="clientCrmHeadersModal.show(response.headers)">
                                View detailed Headers
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-primary btn-addon btn-xs"
                                (click)="clientCrmParamsModal.show(response.params)">
                                View detailed Params
                            </a>
                        </td>
                        <td>
                            <a class="btn btn-primary btn-addon btn-xs"
                                (click)="clientCrmVerificationSettingModal.show(response.verifications)">
                                View Verifications
                            </a>
                        </td>
                        <td [appCan]="'admin'">
                            <div class="row">
                                <div class="col-6">
                                    <a class="btn btn-sm btn-default"
                                        routerLink="/client-crm/{{response.id}}/edit">Edit</a>
                                </div>
                                <div class="col-6">
                                    <a class="btn btn-sm btn-default"
                                        (click)="onDeleteCrmButtonClicked(response.id)">Delete</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination && pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <!-- <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div> -->
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>