<div class="row bg-light lter b-b wrapper-md">

    <div class="col-md-6">
        <h3 class="m-n h3">Incoming Webhook Logs</h3>

        <ul class="breadcrumb insta-breadcrumb">
            <li><a routerLink="/home">Home</a></li>
            <li>Incoming Webhook Logs</li>
        </ul>

    </div>
</div>

<div class="wrapper-md" [appCan]="'superadmin'">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Select Created After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Select Created After</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block"> Select by Track Id</label>
                        <input class="form-control" formControlName="track_id" />
                    </div>
                    <div class="col-md-2 form-group">
                        <label class="center-block">Filter by Vendor</label>
                        <select class="form-control" formControlName="vendor_id">
                            <option *ngFor="let client_api of clients" [value]="client_api.id">
                                {{ client_api.owner_name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>

                        <th>Webhook Details</th>
                        <th>Vendor</th>
                        <th>Verification Details</th>
                        <th>Payload Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li><b>ID:</b> {{log.id}}</li>
                                <li><b>Created At:</b> {{log.created_at}}</li>
                            </ul>
                        </td>
                        <td>


                            <ul class="list-unstyled">
                                <li><b>Vendor:</b> {{log.vendor_name}}</li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li><b>Track ID:</b> {{log.track_id}}</li>
                                <li>
                                    <a routerLink="{{log.employee_verification_link}}">{{log.object_type}} Link</a>
                                </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <a target="_blank" [href]="log.payload_link" class="btn-link">
                                    Payload Link
                                </a>
                            </ul>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>