import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { Employee } from 'src/app/core/models/employee.model';
import { CommonService } from 'src/app/core/services/common.service';
import { EmployeeService } from 'src/app/core/services/employee.service';


@Component( {
    selector: 'app-employee-basic-details',
    templateUrl: './employee-basic-details.component.html',
} )
export class EmployeeBasicDetailsComponent implements OnInit, OnChanges {

    @Input() employee!: Employee;
    @Input() submitting!: boolean | any;

    fieldsDisabled = true;

    formGroup!: FormGroup;
    // submitting!: boolean;

    constructor (
        private fb: FormBuilder,
        private employeeService: EmployeeService,
        private notifications: ToastrService,
        private common: CommonService,
    ) { }

    get hasEmployeeDetails () {
        return this.employee?.id != null;
    }

    ngOnInit () {

        this.initForm();

    }

    // this is callad when @Input values are changed
    ngOnChanges ( changes: SimpleChanges ): void {

        // if employee input is changed, we want to
        // reinitiate the form
        if ( changes[ 'employee' ] ) this.initForm();

    }

    initForm () {

        this.fieldsDisabled = true;

        this.formGroup = this.fb.group( {
            name: [ this.employee?.name, [ validateAllowedCharacters( '.()/,' ) ] ],
            mobile_number: [ this.employee?.mobile_number ],
            father_name: [ this.employee?.father_name, [ validateAllowedCharacters( '.()/,' ) ] ],
            dob: [ this.employee?.dob ],
            client_employee_id: [ this.employee?.client_employee_id ],
        } );
    }

    onSubmit (): void {

        // console.log( 'do nothing' );

    }

    onUpdate (): void {

        // if employee id not present then rteurn
        if ( this.employee?.id == null ) return;

        // if the fields are disabled for editing then return
        if ( this.fieldsDisabled ) return;

        // if the form is not changed then return
        if ( this.formGroup.pristine ) return;

        this.submitting = true;

        this.employeeService.updateBasicDetails( this.employee?.id, this.formGroup.value, '' ).subscribe( response => {

            this.submitting = false;
            this.fieldsDisabled = true;
            this.notifications.success( 'Employee updated' );

        }, error => {

            this.submitting = false;
            this.fieldsDisabled = true;
            this.common.displayValidationErrors( error );

        }, () => {

            this.submitting = false;
            this.fieldsDisabled = true;

        } );

    }

    allowEditing (): void {

        this.fieldsDisabled = false;

    }

}
