<div class="wrapper-md">
    <div class="row">

        <div class="col-sm-3" *ngIf="faceRecognitionStats">
            <div class="panel panel-default">
                <div class="panel-heading">Face Recognition Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Face Recognition Details Found
                        <span class="pull-right text-bold"> {{ faceRecognitionStats['has_details'] }} </span>
                    </p>
                    <p class="mar-no">
                        Face Recognition Details Not Found
                        <span class="pull-right text-bold"> {{ faceRecognitionStats['does_not_has_details'] }} </span>
                    </p>
                    <p class="mar-no">
                        Face Recognition Failure
                        <span class="pull-right text-bold"> {{ faceRecognitionStats['failure'] }} </span>
                    </p>
                    <p class="mar-no">
                        Face Recognition Not Initiated
                        <span class="pull-right text-bold">{{ faceRecognitionStats['not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>