import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, Output, EventEmitter, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { VerificationEditComponent } from '../../core/components/verification-edit/verification-edit.component';
import { Employee } from '../../core/models/employee.model';
import { CommonService } from '../../core/services/common.service';
import { ShortcutService } from '../../core/services/shortcut.service';
import { environment } from '../../../environments/environment';
import { DownloadService } from 'src/app/core/services/download.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-verification-edit-header',
    templateUrl: './verification-edit-header.component.html',
    styleUrls: [ './verification-edit-header.component.css' ],
} )

export class VerificationEditHeaderComponent extends VerificationEditComponent implements OnInit, OnDestroy {
    @ViewChild( 'pdfViewerModal', { static: false } ) pdfViewerModal!: ModalDirective;
    @Input() override verification!: string;
    @Input() override verificationFormGroup!: FormGroup;
    @Input() override employee!: Employee | any;
    @Output() verificationUpdated: EventEmitter<string> = new EventEmitter();

    private changeSub!: Subscription;
    reportPdfUrl: any;

    constructor (
        protected override route: ActivatedRoute,
        protected override router: Router,
        protected override fb: FormBuilder,
        protected override common: CommonService,

        protected override http: HttpClient,
        protected override el: ElementRef,
        protected shortcutService: ShortcutService,
        protected cdr: ChangeDetectorRef,
        private downloadService: DownloadService,
        protected override confirmationModalService: ConfirmationModalService
    ) {
        super( route, router, fb, common, confirmationModalService, http, el, shortcutService );
    }

    override ngOnInit (): void {

        this.changeSub = this.verificationFormGroup.valueChanges.subscribe( () => {

            this.cdr.detectChanges();

        } );

        this.next = this.shortcuts.nextPressed.subscribe( () => {


            if ( this.verificationFormGroup.valid ) {

                this.onUpdateAndNext( this.verification );

            }

        } );

        this.back = this.shortcuts.backPressed.subscribe( () => {

            if ( this.verificationFormGroup.valid ) {

                this.onUpdateAndBack( this.verification );

            }
        } );

    }

    onUpdateAndBack ( verification: any ): void {

        this.verificationFormGroup.patchValue( {
            navigation: 'previous',
        } );
        this.editable = [];
        this.updateVerification( verification );
    }

    onUpdateAndRefresh ( verification: any ): void {

        this.verificationFormGroup.patchValue( {
            navigation: 'refresh',
        } );
        this.refreshEmployee.next( true ); // triggers employee block update event
        this.updateVerification( verification );
    }

    onUpdateAndNext ( verification: any ): void {

        this.verificationFormGroup.patchValue( {
            navigation: 'next',
        } );

        this.editable = [];
        this.updateVerification( verification );
    }

    updateVerification ( verification: any ): void {

        this.verificationUpdated.emit( this.verification );

    }

    override ngOnDestroy (): void {

        if ( this.next ) this.next.unsubscribe();

        if ( this.back ) this.back.unsubscribe();

        if ( this.changeSub ) this.changeSub.unsubscribe();

    }

    get reportLink (): string {
        return environment.api_url + 'employees/' + this.employee.id + '/download';
    }

    viewReport (): void {

        const url = environment.api_url + 'employees/' + this.employee.id + '/download';

        const fileType = 'pdf';

        this.downloadService.downloadFile( url, fileType, null ).subscribe(
            ( blob: Blob ) => {
                this.reportPdfUrl = this.downloadService.getFileUrl( blob );
                this.pdfViewerModal.show();
            },
            ( error: any ) => {
                this.common.notifications.error( 'Some Error Occured', error );
            }
        );
    }

    onReportReload (): void {
        this.viewReport();
    }

}
