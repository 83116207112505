import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-global-db-log-response-modal',
  templateUrl: './global-db-log-response-modal.component.html',
  styleUrls: ['./global-db-log-response-modal.component.css']
})
export class GlobalDbLogResponseModalComponent implements OnInit {

    @ViewChild( 'globalDbLogResponseModal', { static: false } )
    public globalDbLogResponseModal!: ModalDirective;

    @Output()
    onSearch = new EventEmitter<boolean>();

    public response!: any;
    public source!: any;
    constructor () { }

    ngOnInit () {
    }

    show ( source: string, response: string ): void {
        this.source = source;
        this.setResponse( response );
        this.globalDbLogResponseModal.show();
    }

    setResponse ( response: string ): void {
        this.response = JSON.parse( response );
    }

    hide (): void {
        this.globalDbLogResponseModal.hide();
    }

}
