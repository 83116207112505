import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';


import { Pagination } from '../models/pagination.model';
import { Client } from '../models/client.model';
import { Params } from '@angular/router';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { ModalService } from './modal.service';


@Injectable(
    { providedIn: 'root' }
)
export class ClientService {

    constructor ( private api: ApiService,
        private modalService: ModalService
    ) {
    }

    getAll ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'clients', params );
    }

    find ( id: number ): Observable<Client> {
        return this.api.get( 'clients/' + id );
    }

    update ( id: number, values: Object ) {
        return this.api.put( 'clients/' + id, values );
    }

    create ( values: Object ) {
        return this.api.post( 'clients', values );
    }

    bulkCreate ( values: Object ) {
        return this.api.post( 'clients/bulk-create', values );
    }

    toggleStatus ( id: number ): Observable<Client> {
        return this.api.get( 'clients/' + id + '/toggle-status' );
    }

    resetPassword ( id: number ) {
        return this.api.get( 'clients/' + id + '/reset-password' );
    }

    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }

    getHistory ( clientId: number ) {
        return this.api.get( 'clients/' + clientId + '/history' );
    }
}
