<div bsModal #userCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="userCreateForm" (ngSubmit)="createUser()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Contact</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label class="center-block"> Name</label>
                                    <input formControlName="name" class="form-control form-control-lg" />
                                </fieldset>
                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Designation </label>
                                    <input formControlName="designation" class="form-control form-control-lg" />
                                </fieldset>
                            </div>
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label>Email </label>
                                    <input formControlName="email" class="form-control form-control-lg" />
                                </fieldset>

                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Birthday </label>
                                    <input type="date" formControlName="dob" class="form-control form-control-lg" />
                                </fieldset>

                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Mobile Number </label>
                                    <input formControlName="mobile_number" class="form-control form-control-lg" />
                                </fieldset>
                            </div>

                            <div class="col-md-3">
                                <fieldset class="form-group" *ngIf="userLevels">
                                    <label>Level Id </label>
                                    <select class="form-control form-control-lg" formControlName="level_id">
                                        <option *ngFor="let level of userLevels" value={{level.id}}>
                                            {{level.name}}</option>
                                    </select>
                                </fieldset>
                            </div>
                        </div>

                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!userCreateForm.valid">
                            Create
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>