import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';


@Component( {
    selector: 'app-authority-typeahead',
    templateUrl: './authority-typeahead.component.html',
    styleUrls: [ './authority-typeahead.component.css' ],
} )
export class AuthorityTypeaheadComponent implements OnInit {

    @Input() formGroup!: FormGroup | any;
    @Input() districtId!: number;
    @Input() control: string = 'registered_authority';
    @Input() labelName: string = 'Authority';

    get controlId (): string { return this.control + '_id'; }

    get controlName (): string { return this.control + '_name'; }

    get authorityId (): FormControl { return this.formGroup.get( this.controlId ) as FormControl; }

    get authorityName (): FormControl { return this.formGroup.get( this.controlName ) as FormControl; }

    get authorityIdValue (): number { return this.formGroup.get( this.controlId )?.value; }

    get authorityNameValue (): string { return this.formGroup.get( this.controlName )?.value; }

    get authorities (): any[] { return this.global.fetchAuthorities(); }

    constructor ( public global: GlobalService,
        public common: CommonService, ) { }

    ngOnInit () {
    }

    onSelect ( $event: TypeaheadMatch ) {

        this.formGroup.get( this.controlId )?.setValue( $event.item.id );
        this.formGroup.get( this.controlName )?.setValue( $event.item.name );
    }

    onNoResults (): void {

        if ( this.authorityName.value == '' || this.authorityName.value == ' ' ) {

            this.formGroup.get( this.controlId )?.setValue( null );
            this.formGroup.get( this.controlName )?.setValue( null );

        }
    }

}
