<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Identity Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
        </div>
    </div>
</div>


<app-auto-assign-filters verification="identity" [sendRequest]="false" [filterParams]="filterParams" [vendors]="vendors"
    (filtersChanged)="onFiltersChanged($event)"
    filterBy="company,lot,verification_status,created_after,created_before,type_of_doc,qa_required,qa_done,assigned,work_types">
</app-auto-assign-filters>