<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)="$event.preventDefault()">
                <div class="row">

                    <div *ngIf="companies" class="form-group col-md-2">
                        <label class="center-block">Company</label>
                        <app-multi-select [options]="companies" [placeholder]="'Select Company'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"
                            (onDropDownClosed)="onCompanyDropDownClosed()"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block"> Email</label>
                        <input class="form-control" formControlName="email_address" />
                    </div>

                    <div class="form-group col-md-2" *ngIf="inviteStatuses">
                        <label class="center-block"> Invite Status</label>
                        <app-multi-select [options]="inviteStatuses" [placeholder]="'Select Lots'"
                            [controlName]="'invite_status'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2" *ngIf="emailStatuses">
                        <label class="center-block">Email Status</label>
                        <app-multi-select [options]="emailStatuses" [placeholder]="'Select Email Status'"
                            [controlName]="'email_status'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block"> Is Filled</label>
                        <select class="form-control" formControlName="is_filled">
                            <option [value]=""></option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Sent After</label>
                        <input class="form-control" type="date" formControlName="sent_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Sent Before</label>
                        <input class="form-control" type="date" formControlName="sent_before" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Invite Sent Count</label>
                        <select class="form-control" formControlName="email_count">
                            <option *ngFor="let count of followCountOptions" [value]="count.value">{{count.lable}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Candidate Name</label>
                        <input class="form-control" type="text" formControlName="name" placeholder="Candidate Name" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Candidate Mobile Number</label>
                        <input class="form-control" type="text" formControlName="phone_number"
                            placeholder="Candidate Mobile Number" />
                    </div>

                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
