import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

/**
 * Check if only space or multiple space not allowed
 *
 * @returns {ValidatorFn}
 */
export function validateOnlySpaceNotAllow (): ValidatorFn {

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        if ( control.value && control.value.trim().length === 0 ) {
            return { 'validateOnlySpaceNotAllow': { value: control.value } };
        }
        else if ( /\s{2,}/.test( control.value ) ) {
            return { 'validateOnlySpaceNotAllow': { value: control.value } }; // Validation failed: Multiple consecutive spaces
        }

        return {};
    };

}
@Directive( {
    selector: '[validateOnlySpaceNotAllow]',
    providers: [ { provide: NG_VALIDATORS, useExisting: NotAllowOnlySpaceValidatorDirective, multi: true } ],
} )
export class NotAllowOnlySpaceValidatorDirective implements Validator {

    validate ( control: AbstractControl ): { [ key: string ]: any } {
        return validateOnlySpaceNotAllow()( control ) || {};
    }
}


