<div bsModal #aadhaarSearch="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="newRequestForm" (ngSubmit)="search()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Verify Aadhaar Number</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Aadhaar Number
                                        <input type="number" formControlName="aadhaar_number"
                                            class="form-control form-control-lg" />
                                    </label>
                                    <span
                                        *ngIf="newRequestForm.controls['aadhaar_number'].invalid && newRequestForm.controls['aadhaar_number'].touched"
                                        class="text-danger">
                                        Aadhaar Number should be 12 digits
                                    </span>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Sources</label>
                                    <div *ngFor="let source of AadhaarSources | keyvalue" class="display-inline">
                                        <input type="radio" formControlName="driver" id={{source.value}}
                                            value={{source.value}} />
                                        <label for="{{source.value}}" class="radios">{{source.key}}</label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!newRequestForm.valid">
                            Submit
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>