<div class="panel panel-default" *ngIf="pagination.data">
    <div class="panel-heading">
        <div class="row">
            <div class="col-md-6 panel-title" *ngIf="pagination.data.length>0">
                Showing {{ pagination.data.length }} out of total {{ pagination.total }}
            </div>
            <div class="col-md-6 text-right text-center-xs">

                <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
            </div>

        </div>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th style="width:16%;">Name</th>
                    <th style="width:40%;">Address</th>
                    <th style="width:17%;">State & District</th>
                    <th style="width:19%;">Case Details</th>
                    <th style="width:11%;">Type/Category</th>
                    <th style="width:8%;">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let result of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }"
                    [ngClass]="{ 'bg-danger' : result.exact_match }">

                    <td>
                        <ul class="list-unstyled">
                            <li innerHTML=" {{result.name}}"></li>
                        </ul>

                        <a href="#" class="text-info btn-link" [popover]="popTemplate" triggers="mouseenter:mouseleave"
                            container="body">More Details</a>

                        <ng-template #popTemplate>
                            <ul class="list-unstyled">
                                <li> {{result.court_name}}</li>
                                <li> Status: {{result.case_status}}</li>
                                <li> Score: {{result.score}}</li>
                            </ul>
                        </ng-template>
                    </td>
                    <td innerHTML=" {{result.address}}"></td>
                    <td>
                        <ul class="list-unstyled">
                            <li innerHTML=" State: {{result.state}}"></li>
                            <li innerHTML=" District: {{result.district}}"></li>
                        </ul>
                    </td>

                    <td>
                        <ul class="list-unstyled">
                            <li>Year: {{result.case_year}}</li>
                            <li>Acts: {{result.under_acts}}</li>
                            <li>Sections: {{result.under_sections}}</li>
                        </ul>
                    </td>
                    <td>
                        <a href="{{result.link}}" target="_blank" class="text-info btn-link">Link</a>
                        <ul class="list-unstyled">
                            <li>
                                Type: {{result.case_type}}
                            </li>
                            <li>
                                Category: {{result.case_category}}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <div class="btn-group-vertical">
                            <button class="btn btn-default" (click)="markCaseStatusAs('rf',result)">
                                <i class="fa fa-check" *ngIf="result.exact_match==='RF'"> </i>
                                RF
                            </button>
                            <button class="btn btn-default btn-xs" (click)="markCaseStatusAs('prf',result)">
                                <i class="fa fa-check" *ngIf="result.exact_match==='PRF'"> </i>
                                PRF
                            </button>
                            <button class="btn btn-default btn-xs" (click)="markCaseStatusAs('offender',result)">
                                <i class="fa fa-check" *ngIf="result.exact_match==='Offender'"> </i>
                                Offender
                            </button>
                            <button *ngIf="result.exact_match" class="btn btn-default btn-xs"
                                (click)="markCaseStatusAs('none',result)">
                                Remove Status
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>