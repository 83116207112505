import { Component, OnInit, Input, OnChanges, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IdentityService } from 'src/app/core/services/identity.service';
import { CommonService } from 'src/app/core/services/common.service';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { validateDate } from 'src/app/common/validators/date-validator.directive';
import { validateLength } from 'src/app/common/validators/length-validator.directive';
import { validateNumericCharacters } from 'src/app/common/validators/numeric-validator.directive';
import { IdentityDataEntryData } from 'src/app/core/interfaces/identity-data-entry-data';
import { IWorkTypeComponent } from 'src/app/core/interfaces/work-type-interface';
import { FilterService } from 'src/app/core/services/filter.service';
import { IdentityVerificationStatusType } from '../../core/enums/identity-verification-status-type.enum';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-identity-work-data-entry',
    templateUrl: './identity-work-data-entry.component.html',
    styleUrls: [ './identity-work-data-entry.component.css' ],
} )
export class IdentityWorkDataEntryComponent implements IWorkTypeComponent, OnInit {

    verification!: IdentityDataEntryData | any;

    form!: FormGroup;

    loading: boolean = false;

    filterParams: HttpParams = new HttpParams();

    verificationId!: number;

    statuses: any = [
        {
            id: IdentityVerificationStatusType.DataEntryDone,
            name: 'Data Entry Done',
        },
        {
            id: IdentityVerificationStatusType.DocumentRequired,
            name: 'Document Required',
        },
    ];

    constructor (
        private fb: FormBuilder,
        public global: GlobalService,
        private identityService: IdentityService,
        private commonService: CommonService,
        private route: ActivatedRoute,
        private filterService: FilterService,
        private router: Router ) {
    }

    get documentTypeId (): FormControl { return this.form.get( 'document_type_id' ) as FormControl; }

    get statusId (): FormControl { return this.form.get( 'status_id' ) as FormControl; }

    get states () { return this.global.fetchStates(); }

    get documentTypeList () { return this.global.fetchIdentityDocumentTypes(); }

    get districts () { return this.global.fetchDistricts(); }

    ngOnInit (): void {

        this.verificationId = this.route.snapshot.params[ 'id' ];

        const params = this.route.snapshot.queryParamMap;

        params.keys.forEach( key => {

            if ( key !== 'params' && ( params.get( key ) !== '' && params.get( key ) !== 'all' ) ) {

                this.filterParams = this.filterParams.set( key, params.get( key )! );

            }

        } );

        this.getWork( this.verificationId, this.filterParams );


    }

    getWork ( verificationId: any, filters: HttpParams ) {

        this.initForm();

        this.loading = true;

        this.identityService.getDataEntryWork( filters, verificationId ).subscribe( ( response ) => {

            this.loading = false;

            this.verification = response;

            this.form.patchValue( response );

            this.updateValidationsByStatus( this.statusId.value );

        }, error => {

            this.loading = false;

            console.log( error );

        } );

    }

    initForm (): void {
        this.form = this.fb.group( {
            name: [ null, [ validateAllowedCharacters( '' ) ] ],
            client_employee_id: [],
            mobile_number: [],
            father_name: [],
            dob: [],
            age: [],
            document_type_id: [],
            status_id: [ null, [ Validators.required ] ],
            number: [],
            file_number: [],
            name_as_per_id: [],
            father_name_as_per_id: [],
            dob_as_per_id: [ '', [ validateDate( 'dd-mm-yyyy,yyyy' ) ] ],
            address_as_per_id: [],
            state_id: [],
            state_name: [],
            district_id: [],
            district_name: [],
            pincode: [],
            date_of_issue: [],
            date_of_expiry: [],
            lmv_first_issue_date: [],
            lmv_last_renewal_date: [],
            tr_first_issue_date: [],
            tr_last_renewal_date: [],
            client_remarks: [],
        } );
    }

    onDoneClicked (): void {

        this.loading = true;

        const data = {
            data: this.form.value,
        }

        this.identityService.postDataEntryWork( this.filterParams, this.verificationId, data ).subscribe( response => {

            this.loading = false;

            const navigationExtras: NavigationExtras = {
                queryParams: this.route.snapshot.queryParamMap,
                queryParamsHandling: 'merge',
            };

            if ( this.verificationId == response.id ) alert( 'same id' );

            this.verificationId = response.id;

            this.getWork( response.id, this.filterParams );

            this.router.navigate( [ 'work/identity/data-entry', response.id ], navigationExtras );

        }, ( error ) => {

            this.loading = false;

            if ( error.status == 404 ) {

                this.commonService.notifications.warning( 'No Case Found' );

                this.router.navigate( [ 'work/identity' ] );

            } else if ( error.error.code == 422 ) {

                this.commonService.notifications.error( error.error );

            } else {

                this.commonService.notifications.error( error.error );

                this.router.navigate( [ 'work/identity' ] );
            }
        } );

    }

    onExitClicked (): void {

        if ( confirm( 'Are you sure?' ) ) {

            this.loading = true;

            this.identityService.cancelDataEntryWork( this.filterParams, this.verificationId ).subscribe( () => {

                this.router.navigate( [ 'work/identity' ] );

            } );

        }
    }

    /**
     * Zoom In Button
     *
     * @param imageId
     */
    zoomInImage ( imageId: string ): void {

        const element: any = document.getElementById( imageId );
        if ( element ) {

            const currentWidth: number = parseInt( ( element.style.width.replace( '%', '' ) ), 10 );
            const currentHeight: number = parseInt( ( element.style.height.replace( '%', '' ) ), 10 );

            const newWidth: number = currentWidth + currentWidth / 5;
            const newHeight: number = currentHeight + currentHeight / 5;

            if ( newWidth < 250 ) {
                element.style.width = newWidth.toString() + '%';
                element.style.height = newHeight.toString() + '%';
            }
        }
    }

    /**
     * Zoom Out Button
     *
     * @param imageId
     */
    zoomOutImage ( imageId: string ): void {

        const element = document.getElementById( imageId );

        if ( element ) {

            const currentWidth = parseInt( ( element.style.width.replace( '%', '' ) ), 10 );
            const currentHeight = parseInt( ( element.style.height.replace( '%', '' ) ), 10 );

            const newWidth = currentWidth - currentWidth / 5;
            const newHeight = currentHeight - currentHeight / 5;

            if ( newWidth > 50 ) {
                element.style.width = newWidth.toString() + '%';
                element.style.height = newHeight.toString() + '%';
            }
        }
    }

    /**
     * Get the max Height for image panel
     *
     * @param verification
     * @returns {any}
     */
    getMaxHeight ( verification: { front_url: string; back_url: string; } ): string {

        // Set max height on page Load

        const front_url: string = verification.front_url;
        const back_url: string = verification.back_url;

        if ( front_url && !back_url || !front_url && back_url ) {

            return '700px';
        } else {
            return '360px';
        }

    }

    OnStateSelect ( event: { item: { id: any; }; } ): void {

        this.form.controls[ 'state_id' ].setValue( event.item.id );
        // Setting the default value on any change is selection of state.
        // Setting the default value on any change is selection of state.
        this.form.controls[ 'district_name' ].setValue( '' );
    }

    OnDistrictSelect ( event: { item: { id: any; }; } ): void {
        this.form.controls[ 'district_id' ].setValue( event.item.id );
    }

    onDocumentTypeChanged ( $event: any ) {

        const documentTypeId = $event.target.value;

        // this.verificationFormGroup.controls.documentTypeId.setValue( event.target.value );

        this.updateValidationsByDocumentType( documentTypeId );
    }

    onStatusChanged ( $event: any ) {

        const statusId = $event.target.value;

        this.updateValidationsByStatus( statusId );
    }

    updateValidationsByDocumentType ( documentTypeId: number ) {

        this.form.get( 'file_number' )?.setValidators( [] );
        this.form.get( 'file_number' )?.updateValueAndValidity();

        this.form.get( 'date_of_issue' )?.setValidators( [] );
        this.form.get( 'date_of_issue' )?.updateValueAndValidity();

        this.form.get( 'date_of_expiry' )?.setValidators( [] );
        this.form.get( 'date_of_expiry' )?.updateValueAndValidity();

        if ( documentTypeId == 0 && this.statusId.value == 20 ) {			// no document

            this.form.get( 'number' )?.setValidators( [] );
            this.form.get( 'number' )?.updateValueAndValidity();

        } else if ( documentTypeId == 1 && this.statusId.value == 20 ) {	// voter

            this.form.get( 'number' )?.setValidators( [ Validators.required ] );
            this.form.get( 'number' )?.updateValueAndValidity();

            this.updateAddressAsRequired();

        } else if ( documentTypeId == 2 && this.statusId.value == 20 ) {	// pan

            this.form.get( 'number' )?.setValidators( [ Validators.required, Validators.pattern( '^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$' ) ] );
            this.form.get( 'number' )?.updateValueAndValidity();

            this.removeAddressAsRequired();

        } else if ( documentTypeId == 3 && this.statusId.value == 20 ) {	// aadhaar

            this.form.get( 'number' )?.setValidators( [ Validators.required, validateLength( '12' ) ] );
            this.form.get( 'number' )?.updateValueAndValidity();

            this.form.get( 'dob_as_per_id' )?.setValidators( [ validateDate( 'dd-mm-yyyy,yyyy' ) ] );
            this.form.get( 'dob_as_per_id' )?.updateValueAndValidity();

            this.updateAddressAsRequired();

        } else if ( documentTypeId == 5 && this.statusId.value == 20 ) {	// passport

            // this.form.get( 'number' ).setValidators( [ Validators.required ] );
            // this.form.get( 'number' ).updateValueAndValidity();

            // this.form.get( 'file_number' ).setValidators( [ Validators.required ] );
            // this.form.get( 'file_number' ).updateValueAndValidity();

            // this.form.get( 'date_of_issue' ).setValidators( [ Validators.required ] );
            // this.form.get( 'date_of_issue' ).updateValueAndValidity();

            // this.form.get( 'date_of_expiry' ).setValidators( [ Validators.required ] );
            // this.form.get( 'date_of_expiry' ).updateValueAndValidity();

            this.form.get( 'dob_as_per_id' )?.setValidators( [ Validators.required, validateDate( 'dd-mm-yyyy' ) ] );
            this.form.get( 'dob_as_per_id' )?.updateValueAndValidity();

            this.updateAddressAsRequired();

        }

        if ( documentTypeId == 4 && this.statusId.value == 20 ) {	// licence

            this.form.get( 'number' )?.setValidators( [ Validators.required ] );
            this.form.get( 'number' )?.updateValueAndValidity();

            this.form.get( 'dob_as_per_id' )?.setValidators( [ Validators.required, validateDate( 'dd-mm-yyyy' ) ] );
            this.form.get( 'dob_as_per_id' )?.updateValueAndValidity();

            // this.form.get( 'date_of_issue' ).setValidators( [ Validators.required ] );
            // this.form.get( 'date_of_issue' ).updateValueAndValidity();

            // this.form.get( 'date_of_expiry' ).setValidators( [ Validators.required ] );
            // this.form.get( 'date_of_expiry' ).updateValueAndValidity();

            // this.form.get( 'lmv_first_issue_date' ).setValidators( [ Validators.required ] );
            // this.form.get( 'lmv_first_issue_date' ).updateValueAndValidity();

            // this.form.get( 'lmv_last_renewal_date' ).setValidators( [ Validators.required ] );
            // this.form.get( 'lmv_last_renewal_date' ).updateValueAndValidity();

            // this.form.get( 'tr_first_issue_date' ).setValidators( [ Validators.required ] );
            // this.form.get( 'tr_first_issue_date' ).updateValueAndValidity();

            // this.form.get( 'tr_last_renewal_date' ).setValidators( [ Validators.required ] );
            // this.form.get( 'tr_last_renewal_date' ).updateValueAndValidity();

            this.updateAddressAsRequired();

        } else if ( documentTypeId != 4 ) {

            // if not licence then remove all validations from licence specific dates

            this.form.get( 'lmv_first_issue_date' )?.setValidators( [] );
            this.form.get( 'lmv_first_issue_date' )?.updateValueAndValidity();

            this.form.get( 'lmv_last_renewal_date' )?.setValidators( [] );
            this.form.get( 'lmv_last_renewal_date' )?.updateValueAndValidity();

            this.form.get( 'tr_first_issue_date' )?.setValidators( [] );
            this.form.get( 'tr_first_issue_date' )?.updateValueAndValidity();

            this.form.get( 'tr_last_renewal_date' )?.setValidators( [] );
            this.form.get( 'tr_last_renewal_date' )?.updateValueAndValidity();

        }
    }

    updateAddressAsRequired () {

        // this.form.get( 'address_as_per_id' ).setValidators( [ Validators.required ] );
        // this.form.get( 'address_as_per_id' ).updateValueAndValidity();

        // this.form.get( 'state_id' ).setValidators( [ Validators.required ] );
        // this.form.get( 'state_id' ).updateValueAndValidity();

        // this.form.get( 'state_name' ).setValidators( [ Validators.required ] );
        // this.form.get( 'state_name' ).updateValueAndValidity();

        // this.form.get( 'district_id' ).setValidators( [ Validators.required ] );
        // this.form.get( 'district_id' ).updateValueAndValidity();

        // this.form.get( 'district_name' ).setValidators( [ Validators.required ] );
        // this.form.get( 'district_name' ).updateValueAndValidity();

        this.form.get( 'pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
        this.form.get( 'pincode' )?.updateValueAndValidity();

    }

    removeAddressAsRequired () {

        this.form.get( 'address_as_per_id' )?.setValidators( [] );
        this.form.get( 'address_as_per_id' )?.updateValueAndValidity();

        this.form.get( 'state_id' )?.setValidators( [] );
        this.form.get( 'state_id' )?.updateValueAndValidity();

        this.form.get( 'state_name' )?.setValidators( [] );
        this.form.get( 'state_name' )?.updateValueAndValidity();

        this.form.get( 'district_id' )?.setValidators( [] );
        this.form.get( 'district_id' )?.updateValueAndValidity();

        this.form.get( 'district_name' )?.setValidators( [] );
        this.form.get( 'district_name' )?.updateValueAndValidity();

        this.form.get( 'pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
        this.form.get( 'pincode' )?.updateValueAndValidity();

    }

    updateValidationsByStatus ( statusId: number ) {

        if ( statusId == 20 ) {

            // data entry done
            this.form.get( 'number' )?.setValidators( [ Validators.required ] );
            this.form.get( 'number' )?.updateValueAndValidity();

            this.form.get( 'name_as_per_id' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
            this.form.get( 'name_as_per_id' )?.updateValueAndValidity();

            this.form.get( 'father_name_as_per_id' )?.setValidators( [ validateAllowedCharacters( '()/' ) ] );
            this.form.get( 'father_name_as_per_id' )?.updateValueAndValidity();

            this.form.get( 'dob_as_per_id' )?.setValidators( [ validateDate( 'dd-mm-yyyy' ) ] );
            this.form.get( 'dob_as_per_id' )?.updateValueAndValidity();

            this.form.get( 'client_remarks' )?.setValidators( [] );
            this.form.get( 'client_remarks' )?.updateValueAndValidity();

            this.updateValidationsByDocumentType( this.documentTypeId.value );

        } else if ( statusId == 5 ) {

            // document required

            // in case of document required remove all validations from the fields
            this.form.get( 'number' )?.setValidators( [] );
            this.form.get( 'number' )?.updateValueAndValidity();

            this.form.get( 'name_as_per_id' )?.setValidators( [ validateAllowedCharacters( '' ) ] );
            this.form.get( 'name_as_per_id' )?.updateValueAndValidity();

            this.form.get( 'father_name_as_per_id' )?.setValidators( [ validateAllowedCharacters( '()/' ) ] );
            this.form.get( 'father_name_as_per_id' )?.updateValueAndValidity();

            this.form.get( 'dob_as_per_id' )?.setValidators( [ validateDate( 'dd-mm-yyyy,yyyy' ) ] );
            this.form.get( 'dob_as_per_id' )?.updateValueAndValidity();

            this.form.get( 'state_id' )?.setValidators( [] );
            this.form.get( 'state_id' )?.updateValueAndValidity();

            this.form.get( 'state_name' )?.setValidators( [] );
            this.form.get( 'state_name' )?.updateValueAndValidity();

            this.form.get( 'district_id' )?.setValidators( [] );
            this.form.get( 'district_id' )?.updateValueAndValidity();

            this.form.get( 'district_name' )?.setValidators( [] );
            this.form.get( 'district_name' )?.updateValueAndValidity();

            this.form.get( 'pincode' )?.setValidators( [ validateNumericCharacters( '' ), validateLength( '6' ) ] );
            this.form.get( 'pincode' )?.updateValueAndValidity();

            this.form.get( 'client_remarks' )?.setValidators( [ Validators.required ] );
            this.form.get( 'client_remarks' )?.updateValueAndValidity();

            this.form.get( 'file_number' )?.setValidators( [] );
            this.form.get( 'file_number' )?.updateValueAndValidity();

            this.form.get( 'date_of_issue' )?.setValidators( [] );
            this.form.get( 'date_of_issue' )?.updateValueAndValidity();

            this.form.get( 'date_of_expiry' )?.setValidators( [] );
            this.form.get( 'date_of_expiry' )?.updateValueAndValidity();

            this.removeAddressAsRequired();

            this.form.get( 'lmv_first_issue_date' )?.setValidators( [] );
            this.form.get( 'lmv_first_issue_date' )?.updateValueAndValidity();

            this.form.get( 'lmv_last_renewal_date' )?.setValidators( [] );
            this.form.get( 'lmv_last_renewal_date' )?.updateValueAndValidity();

            this.form.get( 'tr_first_issue_date' )?.setValidators( [] );
            this.form.get( 'tr_first_issue_date' )?.updateValueAndValidity();

            this.form.get( 'tr_last_renewal_date' )?.setValidators( [] );
            this.form.get( 'tr_last_renewal_date' )?.updateValueAndValidity();


        } else if ( statusId === 0 ) {

            // under process

            this.statusId.setValue( null );

        }


    }

}
