import { Component, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PermissionService } from '../services/permission.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-permission-create',
    templateUrl: './permission-create.component.html',
    styleUrls: [ './permission-create.component.css' ],
} )
export class PermissionCreateComponent implements OnInit {

    isSubmitting = false;
    permissionCreateForm!: FormGroup;
    error: any = [];

    @ViewChild( 'permissionCreateModal', { static: false } )
    public permissionCreateModal!: ModalDirective;

    @Output()
    onPermissionCreated = new EventEmitter<boolean>();

    // Getters for permissionCreateForm
    get subject () {
        return this.permissionCreateForm.get( 'subject' );
    }

    get action () {
        return this.permissionCreateForm.get( 'action' );
    }

    get description () {
        return this.permissionCreateForm.get( 'description' );
    }

    constructor ( private permissionService: PermissionService,
        private common: CommonService,
        private fb: FormBuilder,
        private router: Router ) { }

    ngOnInit () {
        this.initForm();
    }

    initForm () {
        this.permissionCreateForm = this.fb.group( {
            'subject': [ '', [ Validators.required, Validators.pattern( '[a-z]+' ) ] ],
            'action': [ '', [ Validators.required, Validators.pattern( '[.a-z]+' ) ] ],
            'description': [ '', Validators.required ],
        } );
    }

    show () {
        this.error = [];
        this.permissionCreateModal.show();
    }

    hide () {
        this.initForm();
        this.permissionCreateModal.hide();
    }

    createPermission () {
        this.isSubmitting = true;
        this.permissionService.create( this.permissionCreateForm.value ).subscribe(
            data => {
                this.common.notifications.success( 'Permission Created Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onPermissionCreated.emit( true );
            },
            error => {
                this.isSubmitting = false;
                this.error = error.errors;
                this.common.notifications.error( error.message );
            } )
    }

}
