import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { Employee } from '../../core/models/employee.model';
import { CommonService } from '../../core/services/common.service';
import { EmployeeService } from '../../core/services/employee.service';


@Component( {
    selector: 'app-employee-side-block',
    templateUrl: './employee-side-block.component.html',
    styleUrls: [ './employee-side-block.component.css' ],
} )
/**
 * @deprecated use EmployeeBasicDetailsComponent
 */
export class EmployeeSideBlockComponent implements OnInit {

    editForm!: FormGroup;
    employeeId!: number;
    employee: Employee = new Employee();

    @Input() refreshEmployee!: Subject<any>;

    filterParams: URLSearchParams = new URLSearchParams();
    isSubmitting: boolean = false;

    constructor (
        private fb: FormBuilder,
        protected route: ActivatedRoute,
        protected router: Router,
        protected common: CommonService,
        private employeeService: EmployeeService,
    ) {
    }

    ngOnInit (): void {

        this.initForm();

        // When page loads for the first time
        this.route.params.subscribe( params => {
            // (+) converts string 'id' to a number
            this.employeeId = +params[ 'id' ];
            this.getEmployeeData( this.employeeId );
        } );

        // Listen for the refresh Event
        this.refreshEmployee.subscribe( event => {
            this.employee = new Employee();
            this.getEmployeeData( this.employeeId );
        } );

    }


    initForm (): void {
        this.editForm = this.fb.group( {
            name: [ '', [ validateAllowedCharacters( '.()/,' ) ] ],
            mobile_number: [ '' ],
            father_name: [ '', [ validateAllowedCharacters( '.()/,' ) ] ],
            dob: [ '' ],
            client_employee_id: [ '' ],

        } );
    }


    // fetch basic employee details
    getEmployeeData ( employeeId: number ): void {

        this.employeeService.getBasicDetails( employeeId ).subscribe(
            employee => {
                this.employee = employee;
            }
        );
    }


    updateEmployee (): void {
        this.employeeService.updateBasicDetails( this.employee.id, this.editForm.value, this.filterParams.toString() ).subscribe(
            response => {
                this.common.notifications.success( 'Success', 'Employee Updated' );
                this.isSubmitting = false;
            },
            error => {
                this.common.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            }
        );
    }

}
