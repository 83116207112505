<div class="panel-heading font-bold">
    Filters
</div>
<div class="panel panel-default">
    <div class="panel-body">
        <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">
            <div class="row">

                <div class="col-md-2 form-group">
                    <label class="center-block">Creation After</label>
                    <input class="form-control" type="date" formControlName="employee_created_after" />
                </div>

                <div class="col-md-2 form-group">
                    <label class="center-block">Creation Before</label>
                    <input class="form-control" type="date" formControlName="employee_created_before" />
                </div>


            </div>
            <div class="row">
                <div class="form-group col-md-2">
                    <label class="center-block">Click here to</label>
                    <button class="btn btn-primary btn-block">
                        Apply Filters
                    </button>
                </div>
                <div class="form-group col-md-2">
                    <label class="center-block">Click here to</label>
                    <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                        Reset
                    </button>
                </div>

            </div>
        </form>
    </div>
</div>



<div class="row" style="padding: 0px 0px 0px 20px">

    <a class="btn btn-primary btn-addon" [routerLink]="['/lots', lot.id,'import']">
        <i class="fa fa-upload" *ngIf="pagination.data"></i>
        Import Employees
    </a>


    <a class="btn btn-primary btn-addon" (click)="exportExcel()">
        <i class="fa fa-download"></i>
        Download Excel
    </a>

    <a class="btn btn-primary btn-addon" (click)="exportVendorExcel()">
        <i class="fa fa-download"></i>
        Download Vendor Excel
    </a>

    <a class="btn btn-primary btn-addon" [routerLink]="['/employees/create']" [queryParams]="{lot:lot.id}"
        *ngIf="lot.id">
        <i class="fa fa-edit"></i>
        Create Employee
    </a>

    <a class="btn btn-primary btn-addon" *ngIf="lot.id" (click)="addMissingVerificationsModal.show()">
        <i class="fa fa-edit"></i>
        Add Missing Verifications
    </a>

    <a class="btn btn-primary btn-addon" [routerLink]="['/lots', lot.id,'uber']">
        <i class="fa fa-upload" *ngIf="pagination.data"></i>
        Import Uber Excel
    </a>


</div>

<br>
<app-lot-add-missing-verification [lot]="lot" #addMissingVerificationsModal></app-lot-add-missing-verification>

<app-employment-edit-modal #editEmploymentModal (onVerificationUpdated)="modalUpdated()"></app-employment-edit-modal>
<app-education-edit-modal #editEducationModal (onVerificationUpdated)="modalUpdated()"></app-education-edit-modal>
<app-address-edit-modal #editAddressModal (onVerificationUpdated)="modalUpdated()"></app-address-edit-modal>

<div class="panel panel-default">
    <div class="panel-heading">
        <div class="row" *ngIf="pagination.data">
            <div class="col-md-6 panel-title">
                Showing {{ pagination.data.length }} candidates out of {{ pagination.total }}
            </div>
            <div class="col-md-6 text-right text-center-xs">
                <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
            </div>
        </div>
    </div>

    <div style="overflow-x: auto;overflow-y: auto;max-width: 100%">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th class="col-md-2">Name</th>
                    <th class="" *ngFor="let verification of lot.unique_verifications">
                        <a href="/#/{{verification.key}}?company_id={{lot.company_id}}&lot_id={{lotId}}"
                            target="_blank">{{
                            verification.title}}</a>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let employee of pagination.data | paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total };let i = index">
                    <td>
                        <ul class="list-unstyled">
                            <li>
                                <a class="btn-link text-main text-semibold"
                                    routerLink="/employees/{{employee.id}}/edit"><strong>Name:
                                        {{employee.name}}</strong></a>
                            </li>
                            <li>
                                <a class="btn-link text-main text-semibold"
                                    routerLink="/employees/{{employee.id}}/edit"><strong>TrackID:
                                        {{employee.track_id}}</strong></a>
                            </li>
                            <li>Email: {{ employee.email }}</li>
                            <li>Official Email: {{ employee.official_email }}</li>
                            <li>ClientEmployeeId: {{ employee.client_employee_id }}</li>
                            <li>FathersName: {{ employee.father_name }}</li>
                            <li>DOB: {{ employee.dob }}</li>
                            <li>Phone: {{ employee.mobile_number }}</li>
                            <li [appCan]="'admin'">
                                <button class="btn btn-danger btn-xs" (click)="deleteEmployee(employee.id,i)">
                                    Delete
                                </button>
                            </li>
                        </ul>


                        <br>
                        <p class="text-muted">Created On: {{employee.created_at}}</p>

                        <ul class="list-unstyled text-muted" style="font-size: 13px">
                            <li *ngFor="let remark of employee.remarks">
                                {{ remark.created_at }}
                                - {{ remark.text }}
                                - {{ remark.name }}
                            </li>
                        </ul>

                    </td>
                    <td *ngIf="lot.identity_verification">
                        <table *ngFor="let identity of employee.identity_verifications">

                            <tr>
                                <a class="btn-link" target="_blank" href="/#/identity/{{employee.id}}/edit">{{
                                    identity.status_name }}</a>
                            </tr>
                            <tr>
                                <app-verification-status verification="identity" [className]="identity.color"
                                    [verificationId]="identity.id" [statusId]="identity.status_id"
                                    [verificationDate]="identity.completed_at">
                                </app-verification-status>
                            </tr>
                            <tr>{{identity.type_name}}</tr>
                            <tr>{{identity.number}}</tr>
                        </table>
                    </td>

                    <td *ngIf="lot.current_address_verification || lot.permanent_address_verification">
                        <table class="table {{address.color}}" *ngFor="let address of employee.address_verifications">

                            <tr>
                                <a class="btn-link" (click)="editAddressModal.show(employee.id,address.id)">{{
                                    address.status_name }}</a>
                            </tr>

                            <tr>
                                <app-verification-status verification="address" [className]="address.color"
                                    [verificationId]="address.id" [statusId]="address.status_id"
                                    [verificationDate]="address.completed_at">
                                </app-verification-status>
                            </tr>
                            <tr>{{address.type}}</tr>
                            <tr>
                                <p style="max-width: 200px">
                                    {{address.address}}<br>
                                    {{address.state_name}}<br>
                                    {{address.district_name}} {{address.pincode}}</p>
                            </tr>
                            <tr>
                                IndiaPost: {{ address.india_post_id }}
                            </tr>
                        </table>
                    </td>

                    <td *ngIf="lot.icr_verification">
                        <table *ngFor="let icr of employee.icr_verifications" class="table {{icr.color}}">

                            <tr>
                                <a class="btn-link" target="_blank" href="/#/icr/{{employee.id}}/edit">{{
                                    icr.status_name
                                    }}</a>
                            </tr>

                            <tr>
                                <app-verification-status verification="icr" [className]="icr.color"
                                    [verificationId]="icr.id" [statusId]="icr.status_id"
                                    [verificationDate]="icr.completed_at">
                                </app-verification-status>
                            </tr>
                        </table>
                    </td>

                    <td *ngIf="lot.reference_verification">
                        <table *ngFor="let reference of employee.reference_verifications"
                            class="table {{reference.color}}">

                            <tr>
                                <a class="btn-link" target="_blank" href="/#/reference/{{employee.id}}/edit">
                                    {{reference.status_name}}</a>
                            </tr>

                            <tr>
                                <app-verification-status verification="reference" [className]="reference.color"
                                    [verificationId]="reference.id" [statusId]="reference.status_id"
                                    [verificationDate]="reference.completed_at">
                                </app-verification-status>
                            </tr>

                            <tr *ngIf="reference.type">{{reference.type}}</tr>

                        </table>
                    </td>

                    <td *ngIf="lot.education_verification">
                        <table *ngFor="let education of employee.education_verifications"
                            class="table {{education.color}}">

                            <tr>
                                <a class="btn-link" (click)="editEducationModal.show(employee.id,education.id)">{{
                                    education.status_name }}</a>
                            </tr>
                            <tr>
                                <app-verification-status verification="education" [className]="education.color"
                                    [verificationId]="education.id" [statusId]="education.status_id"
                                    [verificationDate]="education.completed_at">
                                </app-verification-status>

                            </tr>

                            <tr *ngIf="education.college_name">
                                <p style="max-width: 200px">College:
                                    {{education.college_name}}</p>
                            </tr>

                            <tr *ngIf="education.institute_name">
                                <p style="max-width: 200px">University:
                                    {{education.institute_name}}</p>
                            </tr>
                            <tr *ngIf="education.sub_institute_name">
                                <p style="max-width: 200px">Sub Institute:
                                    {{education.sub_institute_name}}</p>
                            </tr>
                        </table>
                    </td>

                    <td *ngIf="lot.employment_verification">

                        <table *ngFor="let employment of employee.employment_verifications"
                            class="table {{employment.color}}">

                            <tr>
                                <a class="btn-link" (click)="editEmploymentModal.show(employee.id,employment.id)">
                                    {{employment.status_name}}</a>
                            </tr>

                            <tr>
                                <app-verification-status verification="employment" [verificationId]="employment.id"
                                    [className]="employment.color" [statusId]="employment.status_id"
                                    [verificationDate]="employment.completed_at">
                                </app-verification-status>

                            </tr>

                            <tr *ngIf="employment.employment_company_name">Company :
                                {{employment.employment_company_name}}
                            </tr>
                        </table>
                    </td>

                    <td *ngIf="lot.pcc_verification">
                        <table *ngFor="let pcc of employee.pcc_verifications" class="table {{pcc.color}}">

                            <tr>
                                <a class="btn-link" target="_blank" href="/#/pcc/{{employee.id}}/edit">
                                    {{ pcc.status }}</a>

                            </tr>

                            <tr>
                                <app-verification-status verification="pcc" [className]="pcc.color"
                                    [verificationId]="pcc.id" [statusId]="pcc.status_id"
                                    [verificationDate]="pcc.completed_at">
                                </app-verification-status>
                            </tr>
                        </table>
                    </td>


                    <td *ngIf="lot.document_verification">
                        <table *ngFor="let document of employee.document_verifications"
                            class="table {{document.color}}">

                            <tr>
                                <a class="btn-link" target="_blank" href="/#/document/{{employee.id}}/edit">{{
                                    document.status }}</a>
                            </tr>

                            <tr>
                                <app-verification-status verification="document" [verificationId]="document.id"
                                    [className]="document.color" [statusId]="document.status_id"
                                    [verificationDate]="document.completed_at">
                                </app-verification-status>
                            </tr>
                        </table>
                    </td>

                    <td *ngIf="lot.globaldb_verification">
                        <table *ngFor="let globaldb of employee.global_db_verifications"
                            class="table {{globaldb.color}}">

                            <tr>
                                <a class="btn-link" target="_blank" href="/#/global-db/{{employee.id}}/edit">{{
                                    globaldb.status_name }}</a>
                            </tr>

                            <tr>
                                <app-verification-status verification="globaldb" [verificationId]="globaldb.id"
                                    [className]="globaldb.color" [statusId]="globaldb.status_id"
                                    [verificationDate]="globaldb.completed_at">
                                </app-verification-status>
                            </tr>
                        </table>
                    </td>

                </tr>
            </tbody>
        </table>

    </div>

    <footer class="panel-footer">
        <div class="row" *ngIf="pagination.data">
            <div class="col-sm-12 text-right text-center-xs">
                <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
            </div>
        </div>
    </footer>
</div>
