import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Errors } from '../../core/models/errors.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientApiService } from '../../core/services/client-api.service';
import { CommonService } from '../../core/services/common.service';
import { Lot } from '../../core/models/lot.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ClientApi } from 'src/app/core/models/client-api.model';

@Component( {
    selector: 'app-client-api-edit',
    templateUrl: './client-api-edit.component.html',
    styleUrls: [ './client-api-edit.component.css' ],
} )
export class ClientApiEditComponent implements OnInit {
    @ViewChild( 'clientsApiEdit', { static: false } )
    public clientsApiEdit!: ModalDirective;

    @Output()
    onClientApiUpdated = new EventEmitter<boolean>();

    title: string = '';
    errors: Errors = new Errors();
    isSubmitting: boolean = false;
    editForm!: FormGroup;
    lots: Lot[] = [];
    clientApiId!: number;
    type: any;
    clientApi: ClientApi = new ClientApi();

    constructor (
        private fb: FormBuilder,
        private clientApiService: ClientApiService,

        private commonService: CommonService
    ) { }

    initForm (): void {

        this.editForm = this.fb.group( {
            type: [ '', [ Validators.required ] ],
            lot_id: [ '', [] ],
            api_status: [ '1', [ Validators.required ] ],
            limits: this.fb.group( {
                ocr: [],
                face: [],
                vehicle: [],
                licence: [],
                gst: [],
                voter: [],
                aadhaar: [],
                pan: [],
            } )
        } );

    }

    ngOnInit () {

        this.initForm();

    }

    show ( api: ClientApi ) {

        this.clientApiId = api.id;

        this.getOwnerType( api );

        this.getClientApiDetails( api.id );

        this.clientsApiEdit.show();

    }

    hide () {

        this.initForm();

        this.clientsApiEdit.hide();

    }

    submitForm () {

        this.isSubmitting = true;

        this.errors = new Errors();

        const data = this.editForm.value;

        this.clientApiService.update( data, this.clientApiId ).subscribe(

            response => {

                this.commonService.notifications.success( 'Successful', response[ 'message' ] );

                this.isSubmitting = false;

                this.hide();

                this.onClientApiUpdated.emit( true );

            },
            err => {

                this.errors = err;

                this.isSubmitting = false;

                this.commonService.notifications.error( 'Some Error Occurred' );

            }

        )

    }

    getOwnerType ( api: ClientApi ): void {

        this.type = api.owner_type;

        if ( api.owner_type == 'Company' ) {

            this.editForm.patchValue( { type: 0 } );
            this.editForm.patchValue( api );

            this.getLotsOfCompany( api.owner_id );

        } else {

            this.editForm.patchValue( { type: 1 } );

        }

    }

    getLotsOfCompany ( companyId: number ): void {

        this.editForm.controls[ 'lot_id' ].setValue( 'all' );

        this.commonService.getLotsByCompanyId( companyId ).subscribe(

            response => {

                this.lots = response;

            }

        );

    }

    getClientApiDetails ( id: number ) {

        this.clientApiService.getClientApiDetails( id ).subscribe(

            response => {

                this.clientApi = response;

                this.editForm.patchValue( {

                    api_status: response.api_status,

                    lot_id: response.lot_id,

                } );

            }

        )

    }

}
