
import { switchMap, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Employee } from '../core/models/employee.model';
import { EmployeeService } from '../core/services/employee.service';
import { SearchFormService } from '../core/services/search-form.service';
import { EmployeeListComponent } from '../employee/employee-list/employee-list.component';

@Component( {
    selector: 'app-search-list',
    templateUrl: './search-list.component.html',
    styleUrls: [ './search-list.component.css' ],
    providers: [ SearchFormService, EmployeeListComponent, EmployeeService ],
} )
export class SearchListComponent implements OnInit {

    employees: Employee[] = [];
    query!: string;

    constructor ( private router: Router,
        private searchFormService: SearchFormService,
        private route: ActivatedRoute,
        private employeeListComponent: EmployeeListComponent ) {
    }

    ngOnInit (): void {
        this.route.queryParams.pipe( filter( ( params: any ) => params.query ),
            switchMap( ( params: any ) => this.searchFormService.searchEmployee( params[ 'query' ] ) ), )
            .subscribe( employees => this.employees = employees );
        this.route.queryParams.pipe(
            filter( params => params[ 'query' ] ) )
            .subscribe( params => {
                this.query = params[ 'query' ];
            } );
    }

    showEmployee ( employee: any ) {
        this.employeeListComponent.showEmployee( employee )
    }


}
