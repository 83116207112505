import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class AadhaarService {

    constructor (
        private api: ApiService
    ) {
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'aadhaar', params );
    }

    getErrorMessages (): Observable<any[]> {
        return this.api.get( 'aadhaar/errors' );
    }

    create ( data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'aadhaar/create', data );
    }

    importExcel ( values: Object ): Observable<any> {
        return this.api.post( 'aadhaar/import-excel', values );
    }

    getDataForExcel ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'aadhaar/export-excel', params );
    }

    resendAll ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'aadhaar/resend-all', params );
    }

    resend ( id: string ) {
        return this.api.get( 'aadhaar/' + id + '/resend' );
    }

    show ( params: string | number ): Observable<any[]> {
        return this.api.get( 'aadhaar/' + params )
    }


    getStats ( params: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'aadhaar/stats', params );
    }

    gethtmlResponse ( param: string | number ): Observable<any[]> {
        return this.api.get( 'aadhaar/' + param + '/html-response' );
    }


}
