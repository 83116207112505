<div bsModal #sendToVendorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Send Request to vendor</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <button class="btn btn-default btn-primary" (click)="onSendToVendorButtonClicked()"> Send Cases</button>
                <button class="btn btn-default btn-danger" (click)="withdrawFromVendor()">Withdraw Cases </button>
            </div>

            <div class="modal-footer">
                <div class="pull-right">

                </div>
                <div class="pull-left">
                    <button class="btn btn-default" (click)="hide()"> Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>