import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkListComponent } from './work-list/work-list.component';
import { WorkHistoryComponent } from './work-history/work-history.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { WorkBulkAssignmentComponent } from './work-bulk-assignment/work-bulk-assignment.component';
import { WorkTypeListComponent } from './work-type-list/work-type-list.component';
import { WorkTypeEditComponent } from './work-type-edit/work-type-edit.component';
import { WorkTypeCreateComponent } from './work-type-create/work-type-create.component';

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        NgxPaginationModule,
        FormsModule,
    ],
    declarations: [
        WorkListComponent,
        WorkHistoryComponent,
        WorkBulkAssignmentComponent,
        WorkTypeListComponent,
        WorkTypeEditComponent,
        WorkTypeCreateComponent,
    ],
} )
export class WorkModule {

}
