<div bsModal #webhookSubUrlListModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">SubURLs</h4>

            </div>
            <div class="modal-body">
                <div class="table-responsive">
                    <table class="table b-t b-light">
                        <thead>
                            <tr>
                                <th style="width: 10%;">
                                    ID
                                </th>
                                <th style="width: 20%;">
                                    Sub URL
                                </th>
                                <th style="width: 10%;">
                                    Method
                                </th>
                                <th style="width: 20%;">
                                    Type
                                </th>
                                <th style="width: 40%;">
                                    TimeLines
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let log of subUrls">
                                <td>
                                    {{log.id}}
                                </td>
                                <td>
                                    {{log.sub_url}}
                                </td>
                                <td>
                                    {{log.method}}
                                </td>
                                <td>
                                    {{log.type}}
                                </td>
                                <td>
                                    <ul class="list-unstyled">
                                        <li>
                                            <b>Created At: </b>{{log.created_at}}
                                        </li>
                                        <li>
                                            <b>Updated At: </b>{{log.updated_at}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>