import { Component, Input, OnInit } from '@angular/core';

@Component( {
    selector: 'app-employee-invite-form-response',
    templateUrl: './employee-invite-form-response.component.html',
    styleUrls: [ './employee-invite-form-response.component.css' ],
} )
export class EmployeeInviteFormResponseComponent implements OnInit {

    @Input() inviteResponse: any;

    get customFields (): any {

        if ( Array.isArray( this.inviteResponse ) && this.inviteResponse.length > 0 ) {

            return this.inviteResponse;

        } else if ( this.inviteResponse && !Array.isArray( this.inviteResponse ) && Object.keys( this.inviteResponse ).length > 0 ) {

            return Object.keys( this.inviteResponse ).map( key => {
                return { 'key': key, 'value': this.inviteResponse[ key ] };
            } );

        } else {

            return [];

        }
    }

    constructor () { }

    ngOnInit (): void { }

}
