import { Component, OnInit } from '@angular/core';
import { Pagination } from '../../core/models/pagination.model';
import { UserService } from '../../core/services/user.service';
import { CommonService } from '../../core/services/common.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';
import { HttpParams } from '@angular/common/http';
import { User } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';

@Component( {
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: [ './user-list.component.css' ],
} )
export class UserListComponent implements OnInit {

    pagination: Pagination = new Pagination();
    protected total!: number;
    loading!: boolean;
    filtersSubscription: Subscription = new Subscription();
    filterParams: HttpParams = new HttpParams();

    constructor (
        private userService: UserService,
        public filterService: FilterService,
        private router: Router,
        private commonService: CommonService,
        private confirmationModalService: ConfirmationModalService
    ) {
    }

    ngOnInit () {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: HttpParams ) => {
            this.filterParams = params;
            this.getAllUsers();
        } );
    }

    getAllUsers () {
        this.userService.getAllUsers( this.filterParams ).subscribe( response => {
            this.pagination = response;
            this.total = this.pagination.total;
        } );
    }

    // Load Next Page when using Pagination
    showPage ( page: string | any ): void {

        this.filterService.filterByPage( page );
    }


    onModalUpdated () {
        this.getAllUsers();
    }

    toggleStatus ( user: { id: number; } ) {

        this.userService.toggleStatus( user.id ).subscribe( {
            next: ( response: any ) => {
                this.commonService.notifications.success( 'Success', response[ 'message' ] );
                this.getAllUsers();

            },
            error: ( error ) => {
                this.commonService.notifications.error( error );
            }
        }
        );
    }

    deleteUser ( id: number, key: number ): void {

        this.userService.deleteUser( id ).subscribe( {
            next: ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] );

                this.getAllUsers();

                this.pagination.data.splice( key, 1 );

                this.total = this.pagination.total - 1;
            },
            error: ( error: any ) => {

                this.commonService.notifications.error( error );
            }
        } );
    }

    onDeleteUserButtonClicked ( id: any, key: number ) {

        const options: ConfirmationModalOptions = { text: 'You want Log out all Users?' };

        this.confirmationModalService.confirm( options, () => this.deleteUser( id, key ) );

    }

    logoutAll (): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => {

            this.userService.logoutAll().subscribe( () => {
                alert( 'All users logged out' );
            } )

        } );

    }

    resetAll (): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => {
            this.userService.resetAllPasswords().subscribe( () => {
                alert( 'All passwords reset' );
            } )
        } );
    }

    exportExcel (): void {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.filterService.httpParams,
            paramsString: this.filterService.httpParams.toString(),
            route: 'users/excel',
            fileName: 'User List ',
            method: 'get',
            postParams: null,
        };

        this.userService.showDownloadModal( initialState );
    }

    getActivationStatusLabel ( user: User ) {
        return user.active === 1 ? 'Deactivate' : 'Activate';
    }
}


