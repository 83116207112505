import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmploymentVerification } from '../../core/models/employment-verification.model';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';

@Component( {
    selector: 'app-employment-revert-form-modal',
    templateUrl: './employment-revert-form-modal.component.html',
    styleUrls: [ './employment-revert-form-modal.component.css' ],
} )
export class EmploymentRevertFormModalComponent {

    isSubmitting: boolean = false;
    @ViewChild( 'revertForm', { static: false } ) revertForm!: ModalDirective;

    details: any = {};
    verification!: EmploymentVerification;
    revertEditForm: FormGroup;

    constructor (
        private employmentService: EmploymentService,
        private commonService: CommonService,
        protected fb: FormBuilder,
    ) {
        this.revertEditForm = this.fb.group( {
            previous_employee_id: [ '', [ Validators.required ] ],
            joining_date: [ '', [ Validators.required ] ],
            end_date: [ '', [ Validators.required ] ],
            designation: [ '', [ Validators.required ] ],
            salary: [ '', [ Validators.required ] ],
            department: [ '', [ Validators.required ] ],
            is_employee_id_correct: [ '' ],
            employee_id_remarks: [ '' ],
            is_date_of_joining_correct: [ '' ],
            date_of_joining_remarks: [ '' ],
            is_date_of_relieving_correct: [ '' ],
            date_of_relieving_remarks: [ '' ],
            is_designation_correct: [ '' ],
            designation_remarks: [ '' ],
            is_salary_correct: [ '' ],
            salary_remarks: [ '' ],
            is_department_correct: [ '' ],
            department_remarks: [ '' ],
            exit_formalities_completed: [ '' ],
            exit_formalities_remarks: [ '' ],
            rehire_eligibility: [ '' ],
            rehire_eligibility_remarks: [ '' ],
            reason_for_leaving: [ '' ],
            verifying_authority_name: [ '' ],
            verifying_authority_email: [ '' ],
            documents_genuine: [ '' ],
            documents_genuine_remarks: [ '' ],
        } );
    }

    show ( verification: any ): void {

        this.revertEditForm.disable();

        this.verification = verification;
        this.employmentService.getRevertFormDetails( verification.id ).subscribe(
            response => {
                this.details = response;
                this.revertEditForm.patchValue( this.details );
                this.revertForm.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );

    }

    updateRevertForm (): void {
        this.isSubmitting = true;
        this.employmentService.updateRevertFormDetails( this.verification.id, this.revertEditForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Revert Form Updated' );
                this.isSubmitting = false;
                this.hide();
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.commonService.notifications.error( 'Unable to update Revert Form' );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }


    hide (): void {
        this.revertForm.hide();
    }
}
