import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { VehicleService } from '../core/services/vehicle.service';
import { UnclaimedVehicleExcelSearchComponent } from './unclaimed-vehicle-excel-search/unclaimed-vehicle-excel-search.component';

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [ UnclaimedVehicleExcelSearchComponent ],
    providers: [ VehicleService ],
} )
export class UnclaimedVehicleModule {
}
