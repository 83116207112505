import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Errors } from '../../core/models/errors.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientApiService } from '../../core/services/client-api.service';
import { CommonService } from '../../core/services/common.service';
import { Lot } from '../../core/models/lot.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalService } from 'src/app/core/services/global.service';
import { Company } from 'src/app/core/models/company.model';

const COMPANIES = 'company';
const VENDORS = 'vendor';

@Component( {
    selector: 'app-client-api-create',
    templateUrl: './client-api-create.component.html',
    styleUrls: [ './client-api-create.component.css' ],
} )
export class ClientApiCreateComponent implements OnInit {


    @ViewChild( 'clientsApiCreate', { static: false } )
    public clientsApiCreate!: ModalDirective;

    @Output() onClientApiCreated = new EventEmitter<boolean>();

    title: string = '';
    isSubmitting: boolean = false;
    createForm!: FormGroup;
    lots: Lot[] = [];
    vendors: any[] = [];

    constructor (
        private fb: FormBuilder,
        private clientApiService: ClientApiService,
        public global: GlobalService,
        private commonService: CommonService
    ) { }

    get type (): string { return this.createForm.get( 'type' )?.value }

    get showVendors (): boolean { return this.type === VENDORS; }

    get showCompanies (): boolean { return this.type === COMPANIES; }

    get companies (): Company[] { return this.global.fetchCompanies(); }

    initForm (): void {

        this.createForm = this.fb.group( {
            company_id: [ '', [] ],
            lot_id: [ '', [] ],
            api_status: [ 1, [ Validators.required ] ],
            type: [ 0, [ Validators.required ] ],
            vendor_id: [ '', [] ],
            ips: [ '', [] ],
            limits: this.fb.group( {
                ocr: [ 100 ],
                face: [ 100 ],
                vehicle: [ 100 ],
                licence: [ 100 ],
                gst: [ 100 ],
                voter: [ 100 ],
                aadhaar: [ 100 ],
                pan: [ 100 ],
            } ),
        } );

    }

    ngOnInit () {

        this.initForm();

        this.commonService.getAllVendors().subscribe(

            response => {

                this.vendors = response;

            }

        );

    }

    show () {

        this.initForm();

        this.clientsApiCreate.show();

    }

    hide () {

        this.clientsApiCreate.hide();

    }

    submitForm () {

        this.isSubmitting = true;

        const data = this.createForm.value;

        this.clientApiService.create( data ).subscribe(

            response => {

                this.isSubmitting = false;

                this.commonService.notifications.success( 'Successful', response[ 'message' ] );

                // call this to reset the form
                this.initForm();

                this.hide();

                this.onClientApiCreated.emit( true );

            },
            err => {

                this.isSubmitting = false;

                this.commonService.notifications.error( 'Some Error Occurred' );

            }

        )

    }

    onCompanySelected (): void {

        this.createForm.controls[ 'lot_id' ].setValue( 'all' );

        const companyId: number = this.createForm.controls[ 'company_id' ].value;

        this.commonService.getLotsByCompanyId( companyId ).subscribe(

            response => {

                if ( !response.length ) {

                    this.createForm.get( 'lot_id' )?.setErrors( { 'incorrect': true } );

                }

                this.lots = response;

            }

        );

    }

    onTypeChanged (): void {

        if ( this.showCompanies ) {

            this.createForm.get( 'company_id' )?.setValidators( [ Validators.required ] );
            this.createForm.get( 'lot_id' )?.setValidators( [ Validators.required ] );
            this.createForm.get( 'vendor_id' )?.setValidators( [] );

        } else if ( this.showVendors ) {

            this.createForm.controls[ 'lot_id' ].setValue( 'all' );

            this.createForm.get( 'company_id' )?.setValidators( [] );
            this.createForm.get( 'lot_id' )?.setValidators( [] );
            this.createForm.get( 'vendor_id' )?.setValidators( [ Validators.required ] );

        }

        this.createForm.get( 'company_id' )?.updateValueAndValidity();
        this.createForm.get( 'lot_id' )?.updateValueAndValidity();
        this.createForm.get( 'vendor_id' )?.updateValueAndValidity();

    }

}
