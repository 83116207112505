import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'vcourtResultsFilter',
} )
export class FilterPipe implements PipeTransform {


    transform ( items: any[], nameSearch: string, addressSearch: string, districtSearch: string ): any {

        if ( items && items.length ) {
            return items.filter( item => {

                if ( nameSearch && !applyRegexPattern( nameSearch ).test( item.name ) ) {
                    return false;
                }

                if ( addressSearch && !applyRegexPattern( addressSearch ).test( item.address ) ) {
                    return false;
                }
                if ( districtSearch && !applyRegexPattern( districtSearch ).test( item.district ) ) {
                    return false;
                }

                return true;
            } )
        }
        else {
            return items;
        }
    }
}


// Highlight the filtered text.
@Pipe( { name: 'highlight' } )

export class HighLightPipe implements PipeTransform {

    transform ( field: string, search: string ): any {
        if ( search && field ) {
            const matchingString = applyRegexPattern( search ).exec( field );

            if ( matchingString ) {
                return matchingString[ 'input' ].replace( matchingString[ 0 ], '<mark class=\'highlight-text\'>' + matchingString[ 0 ] + '</mark>' );
            }


        }

        return field;
    }
}

// Create a regex pattern for the input field.
function applyRegexPattern ( searchValue: string ): RegExp {

    const specialCharacters = /([\\+?[^\]$(){}=!<>|:-])/g;

    return new RegExp( searchValue.replace( specialCharacters, '\\$1' ).replace( /\*/g, '.*' ).replace( /\_/g, '[a-z]' ), 'i' );
}
