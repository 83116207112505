import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { PccListComponent } from './pcc-list/pcc-list.component';
import { PccService } from '../core/services/pcc.service';
import { PccEditComponent } from './pcc-edit/pcc-edit.component';
import { PccAssignedWorkListComponent } from './pcc-assigned-work-list/pcc-assigned-work-list.component';
import { PccImportExcelComponent } from './pcc-import-excel/pcc-import-excel.component';
import { PccLetterheadComponent } from './pcc-letterhead/pcc-letterhead.component';
import { PccChallanComponent } from './pcc-challan/pcc-challan.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        PccListComponent,
        PccEditComponent,
        PccAssignedWorkListComponent,
        PccImportExcelComponent,
        PccLetterheadComponent,
        PccChallanComponent,
    ],
    providers: [ PccService ],
} )
export class PccModule {
}
