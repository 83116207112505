<div bsModal #editModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">

    Hello
    <form [formGroup]="editVendorFormGroup" (ngSubmit)="update()" (keydown.enter)="$event.preventDefault()">
        <div *ngIf="response" class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">{{editVendorFormGroup.get('company_name')?.value}}</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-3 form-group">
                            <label class="center-block">Vendor Name</label>
                            <input formControlName="company_name" class="form-control" [disabled]="true">
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Places Covered</label>
                            <input formControlName="places_covered" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Contact Person</label>
                            <input formControlName="contact_person" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Contact Number</label>
                            <input formControlName="contact_number" class="form-control">
                        </div>

                        <div class="col-md-3 form-group">
                            <label class="center-block">Contact Email</label>
                            <input formControlName="contact_email" class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Employment Verification</label>
                            <input type="checkbox" *ngIf="!response.employment_verification"
                                formControlName="employment_verification" class="form-control">
                            <input type="checkbox" *ngIf="response.employment_verification" checked disabled
                                class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Education Verification</label>
                            <input type="checkbox" *ngIf="!response.education_verification"
                                formControlName="education_verification" class="form-control">
                            <input type="checkbox" *ngIf="response.education_verification" checked disabled
                                class="form-control">

                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Identity Verification</label>
                            <input type="checkbox" *ngIf="!response.identity_verification"
                                formControlName="identity_verification" class="form-control">
                            <input type="checkbox" *ngIf="response.identity_verification" checked disabled
                                class="form-control">
                        </div>
                        <div class="col-md-3 form-group">
                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Address Verification</label>
                            <input type="checkbox" *ngIf="!response.address_verification"
                                formControlName="address_verification" class="form-control">
                            <input type="checkbox" *ngIf="response.address_verification" checked disabled
                                class="form-control">

                        </div>
                        <div class="col-md-3 form-group">
                            <label class="center-block">Global DB Verification</label>
                            <input type="checkbox" *ngIf="!response.global_db_verification"
                                formControlName="global_db_verification" class="form-control">
                            <input type="checkbox" *ngIf="response.global_db_verification" checked disabled
                                class="form-control">
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editVendorFormGroup.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>