<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">

                    <div class="col-md-2 form-group">
                        <label class="center-block">Filter by Client</label>
                        <select class="form-control" formControlName="client_api_id">
                            <option *ngFor="let client of clients" [value]="client.id">
                                {{ client.owner_name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Select Source</label>
                        <select class="form-control" formControlName="via">
                            <option value="all">All</option>
                            <option value="crm">CRM</option>
                            <option value="api">API</option>
                            <option value="excel">Excel</option>
                            <option value="excel">Dexter</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Signzy Status</label>
                        <select class="form-control" formControlName="signzy">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Total Kyc Status</label>
                        <select class="form-control" formControlName="totalkyc">
                            <option value="all">All</option>
                            <option value="0">Details Found</option>
                            <option value="1">Details Not Found</option>
                            <option value="2">Failures</option>
                            <option value="3">Not Initiated</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Id</label>
                        <input class="form-control" type="text" formControlName="id" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Document Type</label>
                        <select class="form-control" formControlName="document_type">
                            <option value="all">All</option>
                            <option value="driving_license">Driving License</option>
                            <option value="pan_card">Pan</option>
                            <option value="voter_id_card">Voter Id</option>
                            <option value="aadhaar_card">Aadhaar Card</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>