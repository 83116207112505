import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';


import { UserService } from './user.service';
import { Observable } from 'rxjs';

@Injectable( {
    providedIn: 'root'
} )
export class AuthGuard implements CanActivate {
    constructor (
        private router: Router,
        private userService: UserService
    ) { }

    canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
        if ( localStorage.getItem( 'token' ) ) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page
        this.router.navigate( [ '/login' ], { queryParams: { redirectUrl: state.url } } );

        return false;
    }

    // canActivate(
    //     route: ActivatedRouteSnapshot,
    //     state: RouterStateSnapshot
    // ): Observable<boolean> {

    //     return this.userService.isAuthenticated.take(1);

    // }
}
