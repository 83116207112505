import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

import { HttpParams } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Pagination } from 'src/app/core/models/pagination.model';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { OcrService } from 'src/app/core/services/ocr.service';


@Component( {
    selector: 'app-ocr-list',
    templateUrl: './ocr-list.component.html',
} )
export class OcrListComponent extends ExportExcelComponent implements OnInit {
    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress = false;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients!: any[];

    constructor (
        private ocrService: OcrService,
        private route: ActivatedRoute,
        private router: Router,

        private commonService: CommonService,
        private filterService: FilterService,
        private bsModalService: BsModalService,
    ) {
        super();
    }

    ngOnInit () {
        this.route.queryParams.subscribe( ( params ) => {

            this.filterParams = this.filterService.httpParams;

            this.filterResults();
        } );
    }

    filterResults (): void {
        this.ocrService.filter( this.filterParams ).subscribe( ( response ) => {
            this.pagination = response;
        } );
    }

    showPage ( page: number ): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    exportLogsExcel (): void {

        this.filterParams = this.filterParams.set( 'unique', 'no' );

        const initialState: any = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'ocr/download',
            fileName: 'ocr ' + Date.now(),
            method: 'get',
            postParams: null,
        };

        this.bsModalService.show( DownloadModalComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        } )

    }

    getData (): void {
        this.fetchingInProgress = true;
        this.ocrService.getDataForExcel( this.filterParams ).subscribe(
            ( response ) => {
                this.downloadExcel( response, 'Ocr Logs Excel' );
                this.fetchingInProgress = false;
            },
            ( error ) => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }

    onSearch (): void {
        this.filterResults();
    }

    keys ( response: { data: {}; } ): Array<string> {
        return Object.keys( response.data );
    }

    resendAll ( source: string ): void {

        this.ocrService.resendAll( source, this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }
}
