import { Company } from './company.model';


export class EmploymentVerification {
    employee_id!: number;
    id!: number;
    company_name!: string;
    company_id!: string;
    completed_at!: string;
    employee_name!: string;
    tat!: string;
    is_checked!: boolean;
    created_at!: string;
    updated_at!: string;
    track_id!: string;
    lot_name!: string;
    status_name!: string;
    status_id!: string;
    color!: string;
    work_assigned!: any[];
    designation!: string;
    department!: string;
    joining_date!: string;
    end_date!: string;
    employment_type_name!: string; // can be (Past/Current/No Employment)
    employment_type_id!: number; // can be (Past/Current/No Employment)
    employment_company_name!: string;
    employment_company_id!: number;
    country_name!: string;
    sent_to_vendor_on!: string;
    vendor_name!: string;
    method!: string;
    client_remarks!: string;
    payment_reference_number!: number;
    salary!: string;
    hr_name!: string;
    hr_email!: string;
    hr_number!: string;
    reference_title!: string;
    reference_name!: string;
    reference_number!: string;
    previous_employee_id!: string;
    mobile_number!: string;
    father_name!: string;
    attachments!: any[];
    proof_url!: string;
    revert_form_id!: number;
    agency_name!: string;
    agency_address!: string;
    agency_contact!: string;
    insuff_raised_at!: string;
    insuff_cleared_at!: string;
    company!: Company;
}
