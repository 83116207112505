<div class="app-aside hidden-xs bg-black">

    <div class="aside-wrap">

        <div class="navi-wrap">

            <!-- nav -->
            <!-- ngInclude: 'tpl/blocks/nav.html' -->

            <nav *ngIf="user" ui-nav="" class="navi clearfix">
                <!-- list -->
                <ul class="nav">
                    <li class="hidden-folded padder m-t m-b-sm text-muted text-xs">
                        <span translate="aside.nav.HEADER">Navigation</span>
                    </li>
                    <li>
                        <a routerLink="/companies" class="auto">
                            <i class="fa fa-building" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Dashboard</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/employees" class="auto">
                            <i class="fa fa-users" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Candidates</span>
                        </a>
                    </li>
                    <li ui-sref-active="active">
                        <a routerLink="/identity" class="auto">
                            <i class="fa fa-id-card-o" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Identity</span>
                        </a>
                    </li>
                    <li ui-sref-active="active">
                        <a routerLink="/icr" class="auto">
                            <i class="fa fa-table" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Icr Verification</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/vcourt" class="auto">
                            <i class="fa fa-gavel" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Vcourt</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/identity/submit-to-icr" class="auto">
                            <i class="fa fa-share-square-o" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Submit To ICR</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/bulk-upload-files" class="auto">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Bulk Upload files</span>
                        </a>
                    </li>
                    <li [appCan]="'superadmin'">
                        <a routerLink="/employees/bulk-update" class="auto">
                            <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Bulk Add/Update Employees</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/email-logs" class="auto">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <span class="font-bold " translate="aside.nav.DASHBOARD">Email Logs</span>
                        </a>
                    </li>
                </ul>
                <!-- / list -->
            </nav>
            <!-- nav -->
            <!-- / aside footer -->
        </div>
    </div>
</div>