<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">My Work History</h1>
    <h4 class="m-n font-thin h4" *ngIf="user">Name: {{user.name}}</h4>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Updated After</label>
                        <input class="form-control" type="date" formControlName="updated_after">

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Updated Before</label>
                        <input class="form-control" type="date" formControlName="updated_before">

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Verification</label>
                        <select (change)="onVerificationTypeChanged()" class="form-control"
                            formControlName="verification_type">
                            <option *ngFor="let verification of verifications" [value]="verification.shortTitle">
                                {{verification.title}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Status</label>


                        <app-multi-select [options]="allStatuses" [placeholder]="'Select Status'"
                            [controlName]="'filter_verification_status'"
                            [formGName]="filterFormGroup"></app-multi-select>

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Free Text Search</label>
                        <input class="form-control" formControlName="work_query">
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button [appCan]="'editor'" (click)="generateExcel()" class="btn btn-primary btn-block">
                            Download Excel
                        </button>
                    </div>
                </div>



            </form>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-3" *ngFor="let verification of historyStats; let name = index">
            <div class="panel panel-default">
                <div class="panel-heading">
                    {{verification.type}}
                </div>
                <div class="panel-body">
                    <p class="mar-no" *ngFor="let data of verification.details"> {{data.day}}
                        <span class="pull-right text-bold">{{data.count}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-6 panel-title" *ngIf="pagination.data">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Done At</th>
                        <th>Verification</th>
                        <th>Employee</th>
                        <th>Work</th>

                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let work of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">
                        <td>{{work.updated_at}}</td>
                        <td>
                            <a class="btn-link text-info" routerLink="{{work.workable_type_link}}">
                                Show {{work.verification_type | uppercase}}
                            </a>
                        </td>
                        <td>
                            <a class="btn-link text-info" routerLink="/employees/{{work.employee_id}}/edit">
                                {{work.track_id}}
                            </a>
                        </td>
                        <td>
                            <ul [appCan]="'editor'" class="list-unstyled">
                                <li *ngFor="let field of work.fields">
                                    {{field.key}} : {{field.value}}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>