import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { AadhaarLogsComponent } from './aadhaar-logs/aadhaar-logs.component';
import { AadhaarService } from '../core/services/aadhaar.service';
import { AadhaarFiltersComponent } from './aadhaar-filters/aadhaar-filters.component';
import { AadhaarNewRequestComponent } from './aadhaar-new-request/aadhaar-new-request.component';
import { AadhaarExcelImportComponent } from './aadhaar-excel-import/aadhaar-excel-import.component';
import { AadhaarStatsComponent } from './aadhaar-stats/aadhaar-stats.component';
import { UidaiDetailsModalComponent } from './uidai-details-modal/uidai-details-modal.component';
import { UidaiExcelImportComponent } from './uidai-excel-import/uidai-excel-import.component';
import { UidaiFiltersComponent } from './uidai-filters/uidai-filters.component';
import { UidaiLogsComponent } from './uidai-logs/uidai-logs.component';
import { UidaiNewRequestComponent } from './uidai-new-request/uidai-new-request.component';
import { UidaiHtmlResponseModalComponent } from './uidai-html-response-modal/uidai-html-response-modal.component';
import { AadhaarResponseModalComponent } from './aadhaar-response-modal/aadhaar-response-modal.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        AadhaarLogsComponent,
        AadhaarFiltersComponent,
        AadhaarNewRequestComponent,
        AadhaarExcelImportComponent,
        AadhaarStatsComponent,
        UidaiDetailsModalComponent,
        UidaiExcelImportComponent,
        UidaiFiltersComponent,
        UidaiLogsComponent,
        UidaiNewRequestComponent,
        UidaiHtmlResponseModalComponent,
        AadhaarResponseModalComponent,
    ],
    providers: [ AadhaarService ],
} )
export class AadhaarModule {
}
