import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class VoterService {

    constructor ( private api: ApiService ) {
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'voter', params );
    }

    show ( params: string ): Observable<any[]> {
        return this.api.get( 'voter/' + params )
    }

    resend ( id: string ) {
        return this.api.get( 'voter/' + id + '/resend' );
    }

    getStats ( params: Params | HttpParams | undefined ): Observable<any[]> {
        return this.api.get( 'voter/stats', params );
    }

    search ( params: Object | undefined ): Observable<any[]> {
        return this.api.post( 'voter/search', params );
    }

    importExcel ( values: Object ): Observable<any> {
        return this.api.post( 'voter/import-excel', values );
    }

    getDataForExcel ( params: string | HttpParams ): Observable<Pagination> {
        return this.api.get( 'voter/export-excel?' + params );
    }

    resendAll ( params: Params | HttpParams | undefined ) {
        return this.api.get( 'voter/resend-all', params );
    }

}
