import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthorityService } from '../../core/services/authority.service';
import { CommonService } from '../../core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-authority-create-modal',
    templateUrl: './authority-create-modal.component.html',
    styleUrls: [ './authority-create-modal.component.css' ],
} )
export class AuthorityCreateModalComponent implements OnInit {

    authorityCreateForm!: FormGroup;
    isSubmitting: boolean = false;
    types!: any[];
    authorities!: any[];
    paymentModes!: any[];


    @ViewChild( 'authorityCreateModal', { static: false } )
    public authorityCreateModal!: ModalDirective;

    @Output()
    onAuthorityCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private authorityService: AuthorityService,
        private commonService: CommonService,
        public global: GlobalService,
        private fb: FormBuilder
    ) { }

    get states () { return this.global.fetchStates(); }

    get districts () { return this.global.fetchDistricts(); }

    ngOnInit (): void {

        this.initForm();

        this.authorities = this.authorityService.all;

        this.types = this.authorityService.types;
    }

    initForm (): void {
        this.authorityCreateForm = this.fb.group( {
            name: [ '', [ Validators.required ] ],
            address: [ '' ],
            state_id: [ 0 ],
            state_name: [ '' ],
            district_id: [ '' ],
            district_name: [ '' ],
            pincode: [ '' ],
            remarks: [ '' ],
            payment_mode_id: [ '' ],
            payment_mode_name: [ '' ],
            processing_fee: [ '' ],
            type: [ '', [ Validators.required ] ],
        } );

    }

    show (): void {

        this.getPaymentModes();

        this.authorityCreateModal.show();
    }

    getPaymentModes (): void {

        this.commonService.getPaymentModes().subscribe(
            response => {
                this.paymentModes = response;
            }
        );
    }

    hide (): void {
        this.initForm();
        this.authorityCreateModal.hide();
    }

    createAuthority (): void {

        this.authorityService.createAuthority( this.authorityCreateForm.value ).subscribe(
            response => {

                this.commonService.notifications.success( 'Success', 'Authority Created' );
                this.hide();
                this.onAuthorityCreated.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.hide();
            }
        );
    }

    // Set the value of the form control district_id
    OnTypeheadSelect ( event: any, name: string ): void {

        const controlId: string = name + '_id';

        if ( name === 'state' ) {
            this.authorityCreateForm.get( 'district_name' )?.setValue( '' );
        }
        this.authorityCreateForm.get( controlId )?.setValue( event.item.id );
    }
}
