<div class="bg-light lter b-b wrapper-md">

    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Icr Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>


</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="icr"></app-verification-stats>
</ng-container>

<app-verification-filters verification="icr" [filterParams]="filterParams"
    filterBy="company,lot,verification_status,state,district,created_after,created_before,submitted_after,submitted_before,completion_date_from,completion_date_to,assigned_to,qa_required,qa_done,cnr_details,fir_judgement_order,time,work_assignment,work_types,track_id,dov_present,work_completed,submission_done">
</app-verification-filters>

<app-work-assign-modal #workAssignModal [verification]="'icr'" [checkedIds]="ids" (onWorkAssigment)="onModalUpdated()">
</app-work-assign-modal>

<div class="row" style="padding: 0px 0px 0px 37px">
    <button routerLink="/icr/submit-to-vcourt" class="btn btn-primary">
        Submit To Vcourt
    </button>
    <a [appCan]="'editor'" class="btn btn-primary btn-addon" (click)="exportExcel()" [appHide]="'icr,download.excel'">
        <i class="fa fa-download"></i>
        Download Excel
    </a>
    <a [appCan]="'editor'" class="btn btn-primary btn-addon" (click)="exportCrcExcel()" [appHide]="'icr,crc.excel'">
        <i class="fa fa-download"></i>
        Download Crc Excel
    </a>

    <a *ngIf="user.level_id | inArray: [4, 5]" class="btn btn-primary btn-addon" (click)="onSearchCnrButtonClicked()">
        <i class="fa fa-download"></i>
        Search CNR
    </a>

    <a *ngIf="user.level_id | inArray: [4, 5]" class="btn btn-primary btn-addon" (click)="parseEcourt()">
        <i class="fa fa-download"></i>
        Parse Under Review Cases
    </a>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="row wrapper">
            <div class="col-sm-8" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses['icr']"
                            (click)="onChangeStatusButtonClicked(status.id, status.title)">
                            <a class="dropdown-item">{{ status.title }}</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa required <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaRequiredButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Qa Done <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(0, 'NO')">
                            <a class="dropdown-item">NO</a>
                        </li>
                        <li role="menuitem" (click)="onChangeQaDoneButtonClicked(1, 'YES')">
                            <a class="dropdown-item">YES</a>
                        </li>
                    </ul>
                </div>

                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('icr')" class="btn btn-danger btn-addon">
                    <i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{ user.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)" />
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:50%;">Details</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let verification of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    };
              let i = index
            ">
                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{ verification.id }}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id, $event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <a class="btn-link text-main text-semibold" (click)="showIcr(verification)">{{
                                verification.employee_name }}</a>
                            <br />
                            <a class="btn-link text-main" (click)="showIcr(verification)">{{
                                verification.track_id
                                }}</a>
                            <br />
                            <a class="btn-link" routerLink="/lots/{{ verification.lot_id }}">
                                {{ verification.lot_name }}
                            </a>
                            <br />
                            <a class="btn-link" routerLink="/companies/{{ verification.company_id }}">
                                {{ verification.company_name }}
                            </a>
                        </td>

                        <td class="{{ verification.color }}">
                            <app-verification-status verification="icr" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at">
                            </app-verification-status>

                            <a *ngIf="verification.vcourt_id" class="btn btn-link text-main text-semibold"
                                (click)="linkToVcourt(verification.vcourt_id)">
                                Link to Vcourt
                            </a>

                            <div *ngIf="verification.cnr_details_found">
                                <p>Cnr Details Fetched</p>
                            </div>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Fathers name: {{ verification.father_name }}</li>
                                <li>Date Of Birth: {{ verification.dob }}</li>
                                <li>Address: {{ verification.full_address }}</li>
                                <li>District Court: {{ verification.district_court }}</li>
                                <li>Category : {{ verification.category }}</li>
                                <li>Nature Of Case : {{ verification.nature_of_case }}</li>
                                <li *ngFor="let work of verification.work_assigned[0]">
                                    <strong>{{ work.title }} is assigned to {{ work.assigned_to }}</strong>
                                </li>
                            </ul>
                        </td>

                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id, i)">
                                Delete
                            </button>

                            <div class="row" *ngIf="attachments[i]">
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['screenshots']">
                                    <button (click)="
                      onDeleteAttachmentButtonClicked(
                        verification.id,
                        attachment['id'],
                        'delete_screenshot_' + attachment['id']
                      )
                    " class="btn btn-danger btn-xs" id="delete_screenshot_{{ attachment['id'] }}">
                                        Delete {{ attachment["file_name"] }}
                                    </button>
                                </div>
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['ecourt_proofs']">
                                    <button (click)="
                      onDeleteAttachmentButtonClicked(
                        verification.id,
                        attachment['id'],
                        'delete_ecourt_proof_' + attachment['id']
                      )
                    " class="btn btn-danger btn-xs" id="delete_ecourt_proof_{{ attachment['id'] }}">
                                        Delete {{ attachment["file_name"] }}
                                    </button>
                                </div>
                                <div class="col-md-12" *ngFor="let attachment of attachments[i]['firs']">
                                    <button (click)="
                      onDeleteAttachmentButtonClicked(
                        verification.id,
                        attachment['id'],
                        'delete_fir_' + attachment['id']
                      )
                    " class="btn btn-danger btn-xs" id="delete_fir_{{ attachment['id'] }}">
                                        Delete {{ attachment["file_name"] }}
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>