import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FilterService } from '../services/filter.service';



export abstract class WorkAssignedListComponent {

    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    protected assignedWorkList!: any[];
    protected route!: ActivatedRoute;
    protected router!: Router;
    protected fb!: FormBuilder;
    protected filterParams: HttpParams = new HttpParams();
    protected filterService!: FilterService;

    constructor (

    ) {

    }

    abstract filterResults ( params?: Params ): void;

    abstract initForm (): void;


    filterQueriedResults (): void {

        this.filterService.filterByParams( this.filterFormGroup.value );

    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();

        this.filterParams = this.filterParams.set( 'created_after', '' );

        this.filterParams = this.filterParams.set( 'created_before', '' );

        this.filterQueriedResults();

    }

    // return one month before date
    getPreviousMonthDate (): string {

        const myDate: Date = new Date();

        const previousMonth: Date = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );

        previousMonth.setDate( myDate.getDate() + 1 );

        return previousMonth.toJSON().slice( 0, 10 );

    }

    // set the filterFormGroup as per url

    // should be replaced with updateFormValues in filterService
    setDefault ( filterParams: any ): void {

        filterParams.keys().forEach( ( key: any ) => {

            const value: any = filterParams.get( key );

            this.filterFormGroup.get( key )?.setValue( value );

        } );
    }

}
