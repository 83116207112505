import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'verificationDocumentType',
} )
export class VerificationDocumentTypePipe implements PipeTransform {


    transform ( verificationsDocumentsArray: any = [], verification: string ): any[] {

        return verificationsDocumentsArray.filter( ( item: { verification: string; } ) => {

            if ( item.verification === verification ) {

                return true;
            }

            return false;
        } );

    }
}
