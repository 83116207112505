<div bsModal #creditReportSearchModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <form [formGroup]="creditReportSearchForm" (ngSubmit)="search()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Search for a Credit Report</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Name
                                        <input formControlName="name" class="form-control form-control-lg" />
                                    </label>
                                    <span *ngIf="name?.errors?.['pattern']" class="text-danger">
                                        Name is required
                                    </span>
                                </fieldset>
                            </div>

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Mobile Number
                                        <input formControlName="mobile_number" class="form-control form-control-lg"
                                            type="number" />
                                    </label>
                                    <span *ngIf="mobile_number?.errors?.['pattern']" class="text-danger">
                                        Mobile Number should be 10 digit
                                    </span>
                                </fieldset>
                            </div>

                        </div>
                        <div class="row" style="margin-top: 25px;">

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Pan Number
                                        <input formControlName="pan_number" style="text-transform: uppercase;" class="form-control form-control-lg" />
                                    </label>
                                    <span *ngIf="pan_number?.errors?.['pattern']" class="text-danger">
                                        Please enter a valid PAN number
                                    </span>
                                </fieldset>
                            </div>

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Date of Birth
                                        <input type="date" formControlName="date_of_birth" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>

                        </div>

                        <div class="row" style="margin-top: 25px;">

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Address
                                        <input formControlName="address" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>

                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Pin Code
                                        <input type="number" formControlName="pincode" class="form-control form-control-lg" />
                                    </label>
                                </fieldset>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <fieldset class="form-group">
                                    <label class="center-block">Sources</label>
                                    <div *ngFor="let source of creditReportSources | keyvalue" class="display-inline">
                                        <input type="radio" formControlName="driver" id={{source.value}}
                                            value={{source.value}} />
                                        <label for="{{source.value}}" class="radios">{{source.key}}</label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!creditReportSearchForm.valid">
                            Search
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
