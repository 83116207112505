import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors } from '../core/models/errors.module';
import { AuthService } from '../core/services/auth.service';
import { CommonService } from '../core/services/common.service';
import { GlobalService } from '../core/services/global.service';

@Component( {
    selector: 'app-login',
    templateUrl: './login.component.html',
} )
export class LoginComponent implements OnInit {
    title: string = '';
    errors: Errors = new Errors();
    isSubmitting = false;
    authForm: FormGroup;
    redirectUrl!: string;

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private globalService: GlobalService,
    ) {
        this.authForm = this.fb.group( {
            'email': [ '', Validators.required ],
            'password': [ '', Validators.required ],
        } );

    }

    ngOnInit () {

        this.redirectUrl = this.route.snapshot.queryParamMap.get( 'redirectUrl' ) ?? '/home';

        if ( localStorage.getItem( 'token' ) ) {

            this.router.navigateByUrl( this.redirectUrl );
        }

        this.route.url.subscribe( {
            next: ( data ) => this.title = 'Sign in',
        } );
    }

    submitForm () {
        // this.isSubmitting = true;
        this.errors = new Errors();

        const email = this.authForm.controls[ 'email' ].value;
        const password = this.authForm.controls[ 'password' ].value;

        this.authService
            .login( this.authForm.value )
            .subscribe( {
                next: ( response ) => {

                    // login successful if there's a jwt token in the response
                    const token = response.token;

                    if ( token ) {

                        // store username and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem( 'token', token );
                        // this.commonService.populateGlobalVariables();
                        this.globalService.populateGlobalVariables();

                        this.router.navigateByUrl( this.redirectUrl );
                    }


                    this.authService.userLoggedIn.emit( true );
                },
                error: ( errors ) => {
                    this.router.navigate( [ '/login' ], { queryParams: { redirectUrl: this.redirectUrl } } );
                    this.errors = errors;
                    this.isSubmitting = false;
                    this.commonService.notifications.error( errors.error.message );
                }
            } )
    }

}
