<div class="bg-light lter b-b wrapper-md">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Reference Verification</h1>
        </div>
        <div class="col-md-1" colSpan="3">
            <button id="stats" type="button" class="btn btn-primary" (click)="showStats()">
                <i class="fa fa-retweet" aria-hidden="true"></i> View Stats
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="statsVisible">
    <app-verification-stats verification="reference"></app-verification-stats>
</ng-container>

<app-verification-filters verification="reference" [filterParams]="filterParams" [vendors]="vendors"
    filterBy="company,lot,verification_status,state,district,created_after,created_before,completion_date_from,completion_date_to,assigned_to,vendor,sent_to_vendor,reference_type,sent_to_vendor_date_present,track_id,dov_present,work_completed,work_assignment,qa_required,qa_done">
</app-verification-filters>

<app-work-assign-modal #workAssignModal [verification]="'reference'" [checkedIds]="ids"
    (onWorkAssigment)="onModalUpdated()"></app-work-assign-modal>

<div class="row" style="padding: 0px 0px 0px 35px">
    <a [appCan]="'editor'" class="btn btn-primary btn-addon" (click)="exportExcel()">
        <i class="fa fa-edit"></i>
        Download Excel
    </a>
    <a class="btn btn-primary btn-addon" routerLink="/reference/import">
        <i class="fa fa-search"></i>
        Import
    </a>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="row wrapper">
            <div class="col-sm-8" *ngIf="ids.length > 0">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Status <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                        <li role="menuitem" *ngFor="let status of statuses['reference']"
                            (click)="onChangeStatusButtonClicked(status.id, status.title)">
                            <a class="dropdown-item">{{ status.title }}</a>
                        </li>
                    </ul>
                </div>

                <div class="btn-group" dropdown [appCan]="'admin'">
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Change Vendor <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let vendor of vendors"
                            (click)="onChangeVendorButtonClicked(vendor.id)">
                            <a class="dropdown-item">{{ vendor.name }}</a>
                        </li>
                    </ul>
                </div>

                <button [appCan]="'admin'" (click)="onDeleteAllButtonClicked('reference')"
                    class="btn btn-danger btn-addon">
                    <i class="fa fa-trash"></i>Delete
                </button>
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle">
                        Assign Work To <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu" role="menu" style="max-height: 300px; overflow-y: scroll">
                        <li role="menuitem" *ngFor="let user of users" (click)="workAssignModal.show(user)">
                            <a class="dropdown-item">{{ user.name }}</a>
                        </li>
                    </ul>
                </div>

                <!-- old way -->
                <!-- <a class="btn btn-primary btn-addon" target="_blank" [href]="[crmUrl + 'reference/letter?id=' + ids]">
                    Print Letters
                </a> -->
                <!-- <a class="btn btn-primary btn-addon" target="_blank"
                    [href]="[crmUrl + 'reference/envelopes?id=' + ids]">
                    Print Envelopes
                </a> -->

                <!-- new way model -->
                <a downloadDocument documentName="Letters" documentType="pdf" class="btn btn-primary btn-addon"
                    [href]="[reportLink]"> Print Letters </a>
                <a downloadDocument documentName="Envelopes" documentType="pdf" class="btn btn-primary btn-addon"
                    [href]="[envelopeLink]">Print Envelopes</a>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" (change)="checkAll($event)" />
                                <i></i>
                            </label>
                        </th>
                        <th style="width:20%;">Employee</th>
                        <th style="width:20%;">Status</th>
                        <th style="width:30%;">Details</th>
                        <th style="width:20%;">Other Details</th>
                        <th style="width:10%;" [appCan]="'admin'">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let verification of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    };
              let i = index
            ">
                        <td>
                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{ verification.id }}"
                                    [(ngModel)]="verification.is_checked"
                                    (change)="getValues(verification.id, $event)" />
                                <i></i>
                            </label>
                        </td>
                        <td>
                            <a class="btn-link text-bold" (click)="showReference(verification)"><strong>{{
                                    verification.track_id }}</strong></a>
                            <br />
                            <a class="btn-link text-main text-semibold" (click)="showReference(verification)">{{
                                verification.employee_name }}</a>
                            <br />
                            <a class="btn-link" routerLink="/lots/{{ verification.lot_id }}">
                                {{ verification.lot_name }}
                            </a>
                            <br />
                            <a class="btn-link" routerLink="/companies/{{ verification.company_id }}">
                                {{ verification.company_name }}
                            </a>
                            <p class="text-muted">{{ verification.created_at }}</p>
                        </td>

                        <td class="{{ verification.color }}">
                            <app-verification-status verification="reference" [verificationId]="verification.id"
                                [statusId]="verification.status_id" [verificationDate]="verification.completed_at">
                            </app-verification-status>

                            <div class="row">
                                <div class="col-md-12" *ngIf="verification.proof_url">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <a href="{{ verification.proof_url }}" class="btn-link">
                                                Proof Url
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" *ngIf="attachments && attachments[i]['attachments']">
                                    <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                                        <div class="col-md-12">
                                            <a href="{{ attachment['full_path'] }}" class="btn-link">
                                                {{ attachment["file_name"] }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>Verification Type: {{ verification.type | uppercase }}</li>
                                <li *ngIf="verification.sub_institute_id">
                                    Phone number: {{ verification.number }}
                                </li>
                                <li>Name: {{ verification.name }}</li>
                                <li>Father's Name: {{ verification.father_name }}</li>
                                <li>Relation: {{ verification.relation }}</li>
                                <li>Address: {{ verification.full_address }}</li>
                                <li>Sent Via: {{ verification.vendor_name }}</li>
                                <li>Sent On: {{ verification.sent_to_vendor_on }}</li>
                                <li *ngFor="let work of verification.work_assigned[0]">
                                    <strong>{{ work.title }} is assigned to
                                        {{ work.assigned_to }}</strong>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <app-vendor-dropdown verification="reference" [verificationId]="verification.id"
                                    [vendorId]="verification.vendor_id" [verificationDate]="verification.completed_at"
                                    [sentToVendorDate]="verification.sent_to_vendor_on" [vendorList]="vendors">
                                </app-vendor-dropdown>
                                <li *ngIf="verification.completed_at">
                                    Completion Date: {{ verification.completed_at }}
                                </li>
                            </ul>
                        </td>
                        <td [appCan]="'admin'">
                            <button type="button" class="btn btn-danger btn-xs"
                                (click)="onDeleteSingleVerificationButtonClicked(verification.id, i)">
                                Delete
                            </button>

                            <div class="row">
                                <div class="col-md-12" *ngIf="verification.proof_url">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <button (click)="
                          onDeleteFileButtonClicked(
                            verification.id,
                            'proof_url',
                            'delete_proof_url_' + i
                          )
                        " class="btn btn-danger btn-xs" id="delete_proof_url_{{ i }}">
                                                Delete Proof
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" *ngIf="attachments">
                                    <div class="row" *ngFor="let attachment of attachments[i]['attachments']">
                                        <div class="col-md-12">
                                            <button (click)="
                          onDeleteAttachmentButtonClicked(
                            verification.id,
                            attachment['id'],
                            'delete_attachment_' + attachment['id']
                          )
                        " class="btn btn-danger btn-xs" id="delete_attachment_{{ attachment['id'] }}">
                                                Delete {{ attachment["file_name"] }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>