<div bsModal #cropper="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left"></h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="showHide">
                    <div class="col-md-12">
                        <div class="panel">
                            <div class="row">
                                <div *ngIf="data" class="col-md-6">

                                    <div>
                                        <button type="button" (click)="rotateLeft()">Rotate left</button>
                                        <button type="button" (click)="rotateRight()">Rotate right</button>
                                        <button type="button" (click)="flipHorizontal()">Flip horizontal</button>
                                        <button type="button" (click)="flipVertical()">Flip vertical</button>
                                    </div>
                                    <div class="form-group">
                                        <image-cropper #imageCropperComponent [imageBase64]="data"
                                            [maintainAspectRatio]="false" [transform]="transform"
                                            [canvasRotation]="canvasRotation" [aspectRatio]="4 / 3" [imageQuality]="75"
                                            format="jpeg" (imageCropped)="imageCropped($event)"
                                            (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
                                            (loadImageFailed)="loadImageFailed()"></image-cropper>
                                    </div>

                                    <div class="form-group" *ngIf="data">
                                        <button type="button" (click)="saveCroppedImage()" class="btn btn-success">Save
                                            Image
                                        </button>
                                        <button type="button" (click)="hide()" class="btn btn-danger">Cancel</button>

                                        <button type="button" class="pull-right" (click)="zoomOut()">-</button>
                                        <button type="button" class="pull-right" (click)="zoomIn()">+</button>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <img id="cropped-image" [src]="croppedPreview" />
                                    </div>

                                </div>

                            </div>


                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</div>