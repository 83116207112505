import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';
import { UserService } from 'src/app/core/services/user.service';

@Component( {
    selector: 'app-pending-jobs',
    templateUrl: './pending-jobs.component.html',
    styleUrls: [ './pending-jobs.component.css' ],
} )
export class PendingJobsComponent implements OnInit {

    data!: any[];

    get isUserAdmin () {
        return this.userService.isAdmin;
    }

    get isUserSuperAdmin () {
        return this.userService.isSuperAdmin;
    }

    constructor (
        private commonService: CommonService,
        private userService: UserService,
        private confirmationModalService: ConfirmationModalService
    ) { }

    ngOnInit (): void {

        this.commonService.getPendingJobs().subscribe( response => {
            this.data = response;
        } );
    }

    refresh (): void {
        this.ngOnInit();
    }

    clearQueue ( name: string ): void {

        this.commonService.clearQueueByName( name ).subscribe( {
            next: ( response: any ) => {

                this.commonService.notifications.success( response[ 'message' ] );

            },
            error: ( error: any ) => {

                this.commonService.notifications.error( error[ 'statusText' ] );
            }
        } );
    }

    onclearQueueButtonClicked ( name: string ): void {
        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.clearQueue( name ) );

    }

}
