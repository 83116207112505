import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { CompanyRole } from 'src/app/core/models/company-role.model';
import { User } from 'src/app/core/models/user.model';
import { CommonService } from 'src/app/core/services/common.service';
import { CompanyRoleService } from 'src/app/core/services/company-role.service';

@Component( {
    selector: 'app-company-assign-role',
    templateUrl: './company-assign-role-modal.component.html'
} )
export class CompanyAssignRoleModalComponent implements OnInit {

    isSubmitting = false;
    assignCompanyRoleForm!: FormGroup;
    error: any = [];

    companyId!: number;

    roles!: CompanyRole[];
    users!: User[];

    @ViewChild( 'companyAssignRoleModal', { static: false } )
    public companyAssignRoleModal!: ModalDirective;

    @Output()
    onRoleAssign = new EventEmitter<boolean>();


    constructor (
        private roleService: CompanyRoleService,

        private commonService: CommonService,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute
    ) { }

    ngOnInit () {
        this.companyId = +this.activatedRoute.snapshot.params[ 'id' ];
        this.initForm();
        this.fetchAllCompanyRole();
        this.fetchAllUsers();

    }

    initForm () {
        this.assignCompanyRoleForm = this.fb.group( {
            'company_id': [ this.companyId ],
            'role_id': [ '', Validators.required ],
            'user_id': [ '', Validators.required ]
        } );
    }

    show () {
        this.error = [];
        this.companyAssignRoleModal.show();
    }

    hide () {

        this.companyAssignRoleModal.hide();
    }

    assignRole () {

        this.roleService.assignRole( this.assignCompanyRoleForm.value ).subscribe( ( response: any ) => {
            if ( response ) {
                this.commonService.notifications.success( 'Role Successfully Assigned' );
                this.hide();
                this.onRoleAssign.emit( true );
                this.initForm();
            }
        } );
    }

    fetchAllCompanyRole () {

        this.roleService.getAll().subscribe( ( response: CompanyRole[] ) => {
            this.roles = response;
        } );
    }

    fetchAllUsers () {
        this.commonService.getUsers().subscribe( ( response: User[] ) => {
            this.users = response;
        } );
    }

}
