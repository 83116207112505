import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CasesCreateComponent } from '../cases-create/cases-create.component';
import { CasesEditComponent } from '../cases-edit/cases-edit.component';
import { IcrCase } from 'src/app/core/models/icr-case.model';
import { DialogService } from 'src/app/core/services/dialog.service';
import { IcrCaseService } from 'src/app/core/services/icr-case.service';
import { ToastrService } from 'ngx-toastr';

@Component( {
    selector: 'app-cases-list',
    templateUrl: './cases-list.component.html',
    styleUrls: [ './cases-list.component.css' ],
} )
export class CasesListComponent implements OnInit {

    @Output() caseCreated: EventEmitter<any> = new EventEmitter<any>();
    @Output() caseUpdated: EventEmitter<any> = new EventEmitter<any>();
    @Output() caseDeleted: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild( CasesCreateComponent, { static: false } ) casesCreateComponent!: CasesCreateComponent;
    @ViewChild( CasesEditComponent, { static: false } ) casesEditComponent!: CasesEditComponent;

    @Input() icrVerificationId!: number;

    @Input() acts!: any[];
    @Input() sections!: any[];
    @Input() allSections!: any[];

    cases: IcrCase[] = [];

    constructor (
        private icrCaseService: IcrCaseService,
        private dialogService: DialogService,
        private notificationService: ToastrService
    ) { }

    ngOnInit (): void {

        this.fetchCases( this.icrVerificationId );

    }

    fetchCases ( id: number ): void {

        this.cases = [];

        this.icrCaseService.findByIcrVerificationId( id ).subscribe( res => {

            this.cases = res;

        } );

    }

    onCaseCreated ( $case: IcrCase ): void {

        // console.log( 'created', $case );

        this.caseCreated.emit( $case );

        this.fetchCases( this.icrVerificationId );

    }

    onCaseUpdated ( $case: IcrCase ): void {

        // console.log( 'updated', $case );

        this.caseUpdated.emit( $case );

        this.fetchCases( this.icrVerificationId );

    }

    showCreateModal (): void {

        this.casesCreateComponent.show( this.icrVerificationId, this.acts, this.sections, this.allSections );

    }

    addCase (): void {

        this.icrCaseService.addCase( this.icrVerificationId ).subscribe( ( cases: IcrCase[] ) => {

            this.notificationService.success( 'Case Added' );

            this.cases = [];
            this.cases = cases;

            // emit the last case added
            this.caseCreated.emit( cases[ cases.length - 1 ] );

        } );

    }

    showEditModal ( icrCase: IcrCase ): void {

        this.casesEditComponent.show( icrCase, this.acts, this.sections, this.allSections );

        // this.modalService.show( this.casesEditComponent );

        // const modal = this.modalService.show( CasesEditComponent );

        // modal.content.case = icrCase;
        // modal.content.acts = this.acts;
        // modal.content.sections = this.sections;
        // modal.content.allSections = this.allSections;

        // modal.content.show();

    }

    deleteCase ( icrCase: IcrCase ): void {

        // confirm befo
        this.dialogService.confirm().then( () => {

            this.icrCaseService.deleteCase( icrCase.id ).subscribe( ( response ) => {

                this.notificationService.success( 'Case Deleted' );

                this.fetchCases( this.icrVerificationId );

            } );

        }, () => {

            // user cancelled delete confirmation

        } );


    }


}
