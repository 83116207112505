import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-voter-response',
    templateUrl: './voter-response.component.html',
    styleUrls: [ './voter-response.component.css' ],
} )
export class VoterResponseComponent implements OnInit {

    response: any;
    @ViewChild( 'voterResponseModal', { static: false } )
    public voterResponseModal!: ModalDirective;

    constructor () { }

    ngOnInit () {
    }

    show ( response: any ) {
        this.response = response;

        this.voterResponseModal.show();
    }

    hide () {
        this.voterResponseModal.hide();
    }

}
