<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)="$event.preventDefault()">
                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block"> Company</label>
                        <app-multi-select [options]="companies" [placeholder]="'Select Company'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block"> Is Active</label>
                        <select class="form-control" formControlName="status">
                            <option value="all">All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Created After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Created Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block"> SPOC </label>
                        <app-multi-select [options]="spocsUsers" [placeholder]="'Select SPOC'" [controlName]="'user'"
                            [formGName]="filterFormGroup" [multipleValue]="true"></app-multi-select>
                    </div>
                    <!-- <div class="form-group col-md-2">
                        <label class="center-block"> Smart Multi Select </label>
                        <app-smart-multi-selector [placeholder]="'Select Company'" [controlName]="'test_company_id'"
                            [formGName]="filterFormGroup"></app-smart-multi-selector>
                    </div> working  feature need complete -->
                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
