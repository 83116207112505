
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { validateLength } from 'src/app/common/validators/length-validator.directive';
import { Attachment } from 'src/app/core/models/attachment.model';
import { categories, natureOfCases, booleanValues } from 'src/app/core/models/common.model';
import { IcrCase } from 'src/app/core/models/icr-case.model';
import { AttachmentService } from 'src/app/core/services/attachment.service';
import { CommonService } from 'src/app/core/services/common.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { IcrCaseService } from 'src/app/core/services/icr-case.service';
import { IcrService } from 'src/app/core/services/icr.service';


@Component( {
    selector: 'app-cases-edit',
    templateUrl: './cases-edit.component.html',
    styleUrls: [ './cases-edit.component.css' ],
} )
export class CasesEditComponent implements OnInit {

    @Output() updated: EventEmitter<IcrCase> = new EventEmitter<IcrCase>();

    case!: IcrCase | any;

    editForm!: FormGroup;

    // mySettings: IMultiSelectSettings = {
    //     enableSearch: true,
    //     checkedStyle: 'fontawesome',
    //     buttonClasses: 'multiselect-items btn btn-default',
    //     dynamicTitleMaxItems: 3,
    //     displayAllSelectedText: false,
    //     maxHeight: '300px',
    //     selectionLimit: 1,
    //     autoUnselect: true,
    //     closeOnSelect: true,
    // };

    acts!: any;
    sections: any;
    allSections: any;

    categories = categories;
    natureOfCases = natureOfCases;
    booleanValues = booleanValues;
    caseId!: number;
    showCnrDetails = false;

    @ViewChild( 'editCaseModal', { static: false } )
    public editModal!: ModalDirective;

    constructor (
        public bsModalRef: BsModalRef,
        private fb: FormBuilder,
        public global: GlobalService,
        private verificationService: IcrService,
        private common: CommonService,
        private icrCaseService: IcrCaseService,
        private dialogService: DialogService,
        private attachmentService: AttachmentService,
        private notificationService: ToastrService
    ) {
        this.initForm();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {

        this.ngOnChanges();

    }

    initForm (): void {

        this.editForm = this.fb.group( {
            case_status: [ '' ],
            type: [ '' ],
            filling_number: [ '' ],
            filling_year: [ '', Validators.compose( [ validateAllowedCharacters( '[0-9]{4}' ), validateLength( '4' ) ] ) ],
            registration_year: [ '', Validators.compose( [ validateAllowedCharacters( '[0-9]{4}' ), validateLength( '4' ) ] ) ],
            registration_number: [ '' ],
            cnr_number: [ '', Validators.compose( [ validateAllowedCharacters( '[0-9]*' ), validateLength( '16' ) ] ) ],
            stage: [ '' ],
            police_station: [ '' ],
            fir_number: [ '' ],
            fir_year: [ '', Validators.compose( [ validateAllowedCharacters( '[0-9]{4}' ), validateLength( '4' ) ] ) ],
            nature_of_disposal: [ '' ],
            district_court: [ '', Validators.required ],
            candidate_category: [ '', Validators.required ],
            fir_available: [ '' ],
            judgement_available: [ '' ],
            cnr_details_found: [ '' ],
            act_ids: [ [], Validators.required ],
            act_names: [ [] ],
            section_ids: [ [] ],
            section_names: [ [] ],
            nature_of_case: [ '', Validators.required ],

            court_search_result_id: [ '' ],
            risk_level: [ '' ],
            status_id: [ '', Validators.required ],
            client_remarks: [ '' ],

        } );
    }

    get screenshots (): Attachment[] {
        return this.case.attachments.filter( ( item: { name: string; } ) => item.name === 'screenshot' );
    }

    get ecourtProofs (): Attachment[] {
        return this.case.attachments.filter( ( item: { name: string; } ) => item.name === 'ecourt_proof' );
    }

    get firs (): Attachment[] {
        return this.case.attachments.filter( ( item: { name: string; } ) => item.name === 'fir' );
    }

    ngOnChanges (): void {

        this.editForm.get( 'act_ids' )?.valueChanges.subscribe( statusId => {
            const actNames = this.getSelectedActs( statusId );

            this.editForm.get( 'act_names' )?.setValue( actNames );
        } );

        this.editForm.get( 'section_ids' )?.valueChanges.subscribe( statusId => {
            const sectionNames = this.getSelectedSections( statusId );

            this.editForm.get( 'section_names' )?.setValue( sectionNames );
        } );

    }

    update (): void {

        // console.log( this.editForm.value );

        this.verificationService.updateCase( this.caseId, this.editForm.value ).subscribe( response => {

            this.common.notifications.success( 'Case Updated' );

            this.updated.emit( response );

            this.cancel();

        } );
    }

    cancel (): void {

        this.editModal.hide();

    }

    show ( params: IcrCase, acts: any, sections: any, allSections: any ): void {

        // console.log( this );

        // console.log( params );

        this.caseId = params.id;

        this.editForm.patchValue( this.case );

        this.acts = acts;

        this.sections = sections;

        this.allSections = allSections;

        // this.editForm.patchValue( params.case );

        this.editModal.show();

        this.fetchCase( this.caseId );

        // this.case = params.case;

    }

    fetchCase ( icrCaseId: number ) {

        this.icrCaseService.findById( icrCaseId ).subscribe( ( icrCase: IcrCase ) => {

            this.case = icrCase;

            this.editForm.patchValue( icrCase );

            this.editModal.show();

            // console.log( this.case );

        } );

    }

    onFileUploaded ( $e: any ): void {

        this.icrCaseService.findById( this.caseId ).subscribe( ( icrCase: IcrCase ) => {

            // referesh attachments
            this.case.attachments = icrCase.attachments;

            // fire case updated event when attachment is upoaded
            this.updated.emit( this.case );

        } );

    }

    getSelectedSections ( statusId: any ): string[] {
        const names: any[] = [];

        statusId.forEach( ( value: any ) => {
            this.allSections.filter( ( item: { id: any; name: any; } ) => {
                if ( item.id == value ) {
                    names.push( item.name );
                }
            } );
        } );

        return names;
    }

    getSelectedActs ( statusId: any ): string[] {
        const names: any[] = [];

        statusId.forEach( ( value: any ) => {
            this.acts.filter( ( item: { id: any; name: any; } ) => {
                if ( item.id == value ) {
                    names.push( item.name );
                }
            } );
        } );

        return names;
    }

    toggleCnrDetails (): void {

        this.showCnrDetails = !this.showCnrDetails;

    }

    updateCaseDetails ( data: { [ key: string ]: any; } ): void {

        this.editForm.patchValue( data );

    }

    deleteCaseAttachment ( attachment: Attachment ): void {

        const title: string = `Are you sure you want to delete ${ attachment.filename }?`;

        this.dialogService.confirm( 'Are you sure?', title ).then( () => {

            this.attachmentService.delete( attachment ).subscribe( () => {

                this.notificationService.success( 'File deleted' );

                // filter and remove the deleted attachment from list
                this.case.attachments = this.case.attachments.filter( ( item: any ) => item.id !== attachment.id );

                // fire case updated event when attachment is deleted
                this.updated.emit( this.case );

            } );

        }, () => {

            // do nothing in case of no

        } );

    }

}
