import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IdentityListComponent } from './identity-list/identity-list.component';
import { IdentityEditComponent } from './identity-edit/identity-edit.component';
import { IdentityService } from '../core/services/identity.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../core/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { SubmitToIcrComponent } from './submit-to-icr/submit-to-icr.component';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { WorkService } from '../core/services/work.service';
import { IdentityAssignedWorkListComponent } from './identity-assigned-work-list/identity-assigned-work-list.component';
import { AutoVerifyModalComponent } from './auto-verify-modal/auto-verify-modal.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { IdentityValidationModalComponent } from './identity-validation-modal/identity-validation-modal.component';

@NgModule( {
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
        VirtualScrollerModule,

    ],
    declarations: [
        IdentityListComponent,
        IdentityEditComponent,
        SubmitToIcrComponent,
        IdentityAssignedWorkListComponent,
        AutoVerifyModalComponent,
        IdentityValidationModalComponent,
    ],
    providers: [ IdentityService, WorkService ],
    exports: [ IdentityEditComponent ],
} )
export class IdentityModule {
}
