<div class="panel panel-default">

    <div class="panel-heading">
        Employee
    </div>

    <div class="panel-body" *ngIf="hasEmployeeDetails && fieldsDisabled">
        <div class="row">
            <div class="form-group col-md-12">
                <label class="center-block">Name: {{ employee.name }}</label>
                <label class="center-block">Father: {{ employee.father_name }}</label>
                <label class="center-block">Number: {{ employee.mobile_number }}</label>
                <label class="center-block">DOB: {{ employee.dob }}</label>
                <label class="center-block">Client Employee Id: {{ employee.client_employee_id }}</label>
                <label class="center-block">Country: {{ employee.country.name }}</label>
            </div>
        </div>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="hasEmployeeDetails && !fieldsDisabled">
        <fieldset [disabled]="submitting || fieldsDisabled">
            <div class="panel-body">
                <div class="row">
                    <div class="form-group col-md-12">
                        <label class="center-block">Name
                            <input class="form-control" formControlName="name">
                        </label>
                    </div>
                    <div class="form-group col-md-12">
                        <label class="center-block">Father Name
                            <input class="form-control" formControlName="father_name">
                        </label>
                    </div>
                    <div class="form-group col-md-12">
                        <label class="center-block">Mobile Number
                            <input class="form-control" formControlName="mobile_number">
                        </label>
                    </div>
                    <div class="form-group col-md-12">
                        <label class="center-block">DOB
                            <input class="form-control" formControlName="dob">
                        </label>
                    </div>
                    <div class="form-group col-md-12">
                        <label class="center-block">Client Employee ID
                            <input class="form-control" formControlName="client_employee_id">
                        </label>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
    <button *ngIf="!fieldsDisabled" type="button" [disabled]="!formGroup.valid || formGroup.pristine"
        class="btn btn-block btn-primary" (click)="onUpdate()">Update Employee</button>
    <button *ngIf="fieldsDisabled" type="button" class="btn btn-block btn-primary" (click)="allowEditing()">
        Edit Employee
    </button>
</div>