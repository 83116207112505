<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Companies</h1>
</div>

<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">


                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Name</label>
                        <input class="form-control" formControlName="name">

                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <a class="btn btn-primary btn-addon btn-block" (click)="companyCreateModal.show()">
                            <i class="fa fa-plus"></i>Create Company</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<app-employment-create-company #companyCreateModal (onCompanyCreated)="onModalUpdated()">
</app-employment-create-company>

<app-employment-edit-company #companyEditModal (onCompanyEdited)="onModalUpdated()">
</app-employment-edit-company>

<app-contact-edit #contactEditModal (onContactEdited)="onModalUpdated()"></app-contact-edit>

<app-contact-create #contactCreateModal (onContactCreated)="onModalUpdated()"></app-contact-create>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>

                        <th style="width: 20%">Name</th>
                        <th style="width: 30%">Details</th>
                        <th style="width: 30%">Contacts</th>
                        <th style="width: 20%">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let company of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    <a class="btn-link" (click)="companyEditModal.show(company)">
                                        {{company.name}}</a>
                                </li>
                                <li>
                                    <a class="btn btn-xs btn-primary "
                                        (click)="contactCreateModal.show('employment',company.id)">Create New
                                        Contact</a>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">

                                <li><i class="fa fa-home"></i> {{company.full_address}}</li>

                                <li>
                                    <i class="fa fa-external-link"></i>
                                    <a target="_blank" href="{{company.website}}" class="btn-link">
                                        {{company.website}}</a>
                                </li>
                                <li>
                                    <i class="fa fa-envelope"></i> {{company.email}}
                                </li>
                                <li>
                                    <i class="fa fa-phone"></i> {{company.contact_number}}
                                </li>
                            </ul>
                        </td>

                        <td>

                            <ul class="list-unstyled" *ngFor="let contact of company.contacts">

                                <li>
                                    <a (click)="contactEditModal.show(contact)">
                                        <i class="fa fa-edit"></i>
                                        <strong>{{ contact.name }}</strong>
                                    </a>

                                </li>
                                <li>Location : {{ contact.location }}</li>
                                <li>Designation : {{ contact.designation }}</li>
                                <li>
                                    <i class="fa fa-phone"></i> Contact : {{ contact.phone_number }} &nbsp;
                                </li>
                                <li>
                                    <i class="fa fa-envelope"></i> Primary : {{ contact.primary_email }}
                                </li>
                                <li>
                                    <i class="fa fa-envelope"></i> Secondary : {{ contact.secondary_email }}
                                </li>
                                <li>
                                    <strong *ngIf="contact.is_centralised">Centralised</strong>&nbsp;
                                    <strong>{{ contact.isActive }}</strong>&nbsp;
                                    <strong>{{ contact.isHelpful }}</strong>
                                </li>
                                <li [appCan]="'admin'">
                                    <button class="btn btn-danger btn-xs"
                                        (click)="onDestroyContactButtonClicked(contact.id)">
                                        Delete
                                    </button>
                                </li>

                            </ul>
                        </td>
                        <td>


                            {{company.remarks}}
                        </td>


                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>