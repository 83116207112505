import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import { SharedModule } from '../core/shared.module';
import { LoginComponent } from './login.component';
import { NoAuthGuard } from './no-auth-guard.service';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        LoginComponent,
    ],
    providers: [ NoAuthGuard, AuthService ],
} )
export class LoginModule {
}
