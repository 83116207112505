import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Verification } from '../../core/models/verification.model';
import { CommonService } from '../../core/services/common.service';
import { VerificationService } from '../../core/services/verification.service';

@Component( {
    selector: 'app-copy-verification-address-details',
    templateUrl: './copy-verification-address-details.component.html',
    styleUrls: [ './copy-verification-address-details.component.css' ],
} )
export class CopyVerificationAddressDetailsComponent implements OnInit {

    allVerifications: Array<{ title: string, verificationRelation: string, shortTitle: string }> = [];
    index!: number;
    getAddressFormGroup!: FormGroup;
    filterParams: HttpParams = new HttpParams();
    addressDetails: any;


    @ViewChild( 'copyAddressFromSelectedVerification', { static: false } )
    public copyAddressFromSelectedVerification!: ModalDirective;

    @Input() verificationId!: number;
    @Input() verificationShortTitle!: string;
    @Input() mainFormGroup!: FormGroup;
    @Input() verificationIndex!: number;
    @Input() duration_of_stay: any;
    @Input() police_station: any;

    selectedVerification!: Verification;

    selectedAddress: any;

    constructor (
        private commonService: CommonService,
        private fb: FormBuilder
    ) { }

    ngOnInit (): void {

        this.initForm();
    }

    initForm (): void {

        this.addressDetails = null;
        this.getAddressFormGroup = this.fb.group( {
            selected_address: [ null, [ Validators.required ] ],
        } );
    }

    getAddress ( selectedVerification: Verification | any ): void {

        this.selectedVerification = selectedVerification;
        this.filterParams = this.filterParams.set( 'selected_verification_relation', selectedVerification.verificationRelation );
        this.filterParams = this.filterParams.set( 'verification', this.verificationShortTitle );
        this.commonService.getSelectedVerificationAddress( this.verificationId, this.filterParams ).subscribe(
            addressDetails => {

                this.addressDetails = addressDetails;

            }
        );
    }

    show (): void {


        this.allVerifications = [
            {
                title: 'Identity Verification',
                verificationRelation: 'identity_verifications',
                shortTitle: 'identity',

            },
            {
                title: 'Current Address Verification',
                verificationRelation: 'current_address_verifications',
                shortTitle: 'current_address',
            },
            {
                title: 'Permanent Address Verification',
                verificationRelation: 'permanent_address_verifications',
                shortTitle: 'permanent_address',
            },
            {
                title: 'ICR Verification',
                verificationRelation: 'icr_verifications',
                shortTitle: 'icr',

            },
            {
                title: 'Reference Verification',
                verificationRelation: 'reference_verifications',
                shortTitle: 'reference',
            },
            {
                title: 'PCC Verification',
                verificationRelation: 'pcc_verifications',
                shortTitle: 'pcc',
            },
        ];

        this.copyAddressFromSelectedVerification.show();

    }

    submit (): void {

        this.selectedAddress = this.addressDetails[ this.getAddressFormGroup.get( 'selected_address' )?.value ];

        if ( this.selectedAddress ) {
            const formArray: FormArray = this.mainFormGroup.get( 'formArray' ) as FormArray;
            const formGroup: FormGroup = formArray.controls[ this.verificationIndex ] as FormGroup;

            const durationOfStay: string = ( this.selectedAddress[ 'duration_of_stay' ] === '' )
                ? this.duration_of_stay
                : this.selectedAddress[ 'duration_of_stay' ];

            const policeStation: string = ( this.selectedAddress[ 'police_station' ] === '' )
                ? this.police_station
                : this.selectedAddress[ 'police_station' ];

            formGroup.controls[ 'address' ].setValue( this.selectedAddress[ 'address' ] );
            formGroup.controls[ 'state_id' ].setValue( this.selectedAddress[ 'state_id' ] );
            formGroup.controls[ 'state_name' ].setValue( this.selectedAddress[ 'state_name' ] );
            formGroup.controls[ 'district_id' ].setValue( this.selectedAddress[ 'district_id' ] );
            formGroup.controls[ 'district_name' ].setValue( this.selectedAddress[ 'district_name' ] );
            formGroup.controls[ 'pincode' ].setValue( this.selectedAddress[ 'pincode' ] );
            formGroup.controls[ 'landmark' ].setValue( this.selectedAddress[ 'landmark' ] );
            formGroup.controls[ 'police_station' ].setValue( policeStation );
            formGroup.controls[ 'duration_of_stay' ].setValue( durationOfStay );
            this.hide();
            this.commonService.notifications.success( 'Success', 'Address Copied Successfully' );
        }
    }

    hide (): void {
        this.initForm();
        this.copyAddressFromSelectedVerification.hide();
    }


}
