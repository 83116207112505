import { Component, Input } from '@angular/core';
import { Company } from 'src/app/core/models/company.model';

@Component( {
    selector: '[app-company-list-item]',
    templateUrl: './company-list-item.component.html'
} )
export class CompanyListItemComponent {

    @Input() company!: Company;

    get companyStatus (): string {
        return this.company.status ? 'Active' : 'Inactive';
    }
}
