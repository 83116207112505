<div class="modal-content" style="max-height: 100%; overflow-y: auto;">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Sms Timeline</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th> Recipient </th>
                    <th> Status </th>
                    <th> Message</th>
                    <th style="min-width: 15rem;"> Date and Time </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let statusLog of logs">
                    <td>{{statusLog.to}}</td>
                    <td>{{statusLog.status}}</td>
                    <td>
                        {{statusLog?.message}}
                    </td>
                    <td>{{statusLog.created_at}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>