<div *ngIf="attachments">
    <div *ngFor="let attachment of attachments">
        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="attachment.url">
            {{attachment.name}}
        </a>
    </div>
</div>

<div *ngIf="documents">
    <div *ngFor="let document of documents">
        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="document.url">
            {{document.name }}
        </a>
    </div>
</div>