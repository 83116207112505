import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable( {
    providedIn: 'root',
} )
export class IcrCaseService {

    constructor ( private api: ApiService ) { }

    findById ( id: number ) {

        return this.api.get( `icr/cases/${ id }` );

    }

    findByIcrVerificationId ( icrId: number ) {

        return this.api.get( `icr/${ icrId }/cases` );

    }

    createCase ( params: Object | undefined ) {

        return this.api.post( 'icr/cases', params );

    }

    addCase ( icrId: any, params = {} ) {

        return this.api.post( `icr/${ icrId }/add-case`, params );

    }

    deleteCase ( id: number ) {

        return this.api.delete( `icr/cases/${ id }` );

    }

}
