<div bsModal #authorityEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="editForm" (ngSubmit)="updateAuthority()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Authority</h4>

                </div>
                <div class="panel-body">
                    <fieldset [disabled]="isSubmitting">

                        <div class="row">
                            <div class="col-md-6 form-group">
                                <input formControlName="id" class="form-control" type="hidden"
                                    ngModel="{{authority.id}}" />
                                <label class="center-block"> Authority Name</label>

                                <select class="form-control" formControlName="name" [(ngModel)]="authority.name">
                                    <option *ngFor="let authority of authorities " [value]="authority.id">
                                        {{authority.name}}
                                    </option>
                                </select>

                            </div>


                            <div class="col-md-6 form-group">
                                <label>Address </label>
                                <input formControlName="address" class="form-control" ngModel="{{authority.address}}" />

                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-3 form-group">
                                <label> Select State
                                    <select class="form-control" formControlName="state_id"
                                        [(ngModel)]="authority.state_id">
                                        <option *ngFor="let state of states " [value]="state.id">
                                            {{state.name}}
                                        </option>
                                    </select>
                                </label>
                            </div>

                            <div class="col-md-3 form-group">
                                <label> Select District
                                    <select class="form-control" formControlName="district_id"
                                        [(ngModel)]="authority.district_id">
                                        <option *ngFor="let district of districts |filterByStateId:authority.state_id"
                                            [value]="district.id">
                                            {{district.name}}
                                        </option>
                                    </select>
                                </label>
                            </div>

                            <div class="col-md-3 form-group">
                                <label>Pincode </label>
                                <input formControlName="pincode" class="form-control" ngModel="{{authority.pincode}}" />

                            </div>
                            <div class="col-md-3 form-group">
                                <label>Remarks </label>
                                <input formControlName="remarks" class="form-control" ngModel="{{authority.remarks}}" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 form-group">
                                <label> Payment Mode
                                    <select class="form-control" formControlName="payment_mode_id">
                                        <option *ngFor="let paymentmode of payment_modes " [value]="paymentmode.id">
                                            {{paymentmode.name}}
                                        </option>
                                    </select>
                                </label>
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="center-block"> Processing Fee
                                    <input formControlName="processing_fee" class="form-control">
                                </label>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>