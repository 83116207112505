import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExportExcelComponent } from '../../../common/export-excel/export-excel.component';
import { Institute } from '../../../core/models/institute.model';
import { Pagination } from '../../../core/models/pagination.model';
import { EducationService } from '../../../core/services/education.service';
import { FilterService } from './../../../core/services/filter.service';

@Component( {
    selector: 'app-education-institutes-list',
    templateUrl: './education-institutes-list.component.html',
    styleUrls: [ './education-institutes-list.component.css' ],
} )
export class EducationInstitutesListComponent extends ExportExcelComponent implements OnInit, OnDestroy {

    loading!: boolean;
    institutes!: Institute[];
    isSubmitting: boolean = false;
    pagination: Pagination = new Pagination();
    filterParams: HttpParams = new HttpParams();
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    filtersSubscription!: Subscription;

    constructor (
        private educationService: EducationService,
        private fb: FormBuilder,
        private filterService: FilterService,
    ) {
        super();
    }

    ngOnInit (): void {

        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filterResults( this.filterFormGroup.value );

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );
    }


    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            name: [ '' ],
        } );
    }

    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    filterResults ( params: Params ): void {
        this.educationService.getInstitutes( params ).subscribe(
            response => this.pagination = response
        );
    }


    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    onModalUpdated (): void {

        this.filterResults( this.filterFormGroup.value );
    }

    exportExcel (): void {

        this.educationService.getInstituteDataForExcel( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Education Institutes' );
            } );
    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }
}
