import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive( { selector: '[imageZoom]' } )
export class ImageZoomDirective {

    /**
     * Variable to check if mouse is clicked or not
     */
    mouseClicked!: boolean;

    constructor ( private el: ElementRef ) { }

    /**
     * When Mouse wheel is scrolled in Chrome
     *
     * @param event
     */
    @HostListener( 'mousewheel', [ '$event' ] )
    onMouseWheelChrome ( event: any ) {
        this.mouseWheelFunc( event );
    }

    /**
     * When Mouse is clicked
     *
     * @param event
     */
    @HostListener( 'mousedown', [ '$event' ] )
    onMouseDown ( event: any ) {
        this.mouseClicked = true;
    }

    /**
     * When Mouse leaves the component
     *
     * @param event
     */
    @HostListener( 'mouseleave', [ '$event' ] )
    onMouseLeave ( event: any ) {
        this.mouseClicked = false;
    }

    /**
     * When mouse is un-clicked
     *
     * @param event
     */
    @HostListener( 'mouseup', [ '$event' ] )
    onMouseUp ( event: any ) {
        this.mouseClicked = false;
    }

    /**
     * When Mouse is Moved
     *
     * @param event
     */
    @HostListener( 'mousemove', [ '$event' ] )
    onMouseMove ( event: any ) {
        this.mouseMovementFunc( event );
    }

    /**
     * When Mouse Wheel is scrolled in Firefox
     *
     * @param event
     */
    @HostListener( 'DOMMouseScroll', [ '$event' ] )
    onMouseWheelFirefox ( event: any ) {
        this.mouseWheelFunc( event );
    }

    /**
     * When Mouse Wheel is scrolled in IE
     *
     * @param event
     */
    @HostListener( 'onmousewheel', [ '$event' ] )
    onMouseWheelIE ( event: any ) {
        this.mouseWheelFunc( event );
    }

    /**
     * Zoom in and out the Image when mouse wheel is moved
     *
     * @param event
     */
    mouseWheelFunc ( event: any ) {

        event = window.event || event; // old IE support

        // Find the direction in which mouse is moved
        const delta = Math.max( -1, Math.min( 1, ( event.wheelDelta || -event.detail ) ) );

        if ( delta > 0 ) {

            this.zoomOut();

        } else if ( delta < 0 ) {

            this.zoomIn();

        }

        // for Chrome and Firefox
        if ( event.preventDefault ) {
            event.preventDefault();
        }
    }

    /**
     * Zoom out when mouse wheel is moved in Upwards Direction
     *
     */
    zoomOut () {

        const currentWidth = parseFloat( this.el.nativeElement.style.width );
        const currentHeight = parseFloat( this.el.nativeElement.style.height );

        const newWidth = currentWidth - currentWidth / 5;
        const newHeight = currentHeight - currentHeight / 5;

        if ( newWidth > 50 ) {
            this.el.nativeElement.style.width = newWidth.toString() + '%';
            this.el.nativeElement.style.height = newHeight.toString() + '%';
        } else {
            console.log( 'no zoom out' );
        }
    }

    /**
     * Zoom in when mouse wheel is moved in Downwards Direction
     *
     */
    zoomIn () {

        const currentWidth = parseFloat( this.el.nativeElement.style.width );
        const currentHeight = parseFloat( this.el.nativeElement.style.height );

        const newWidth = currentWidth + currentWidth / 5;
        const newHeight = currentHeight + currentHeight / 5;

        if ( newWidth < 250 ) {
            this.el.nativeElement.style.width = newWidth.toString() + '%';
            this.el.nativeElement.style.height = newHeight.toString() + '%';
        } else {
            console.log( 'no zoom in' );
        }
    }

    /**
     * Scroll left or right when mouse is clicked and moved
     *
     * @param event
     */
    mouseMovementFunc ( event: { target: { offsetParent: { id: any; }; }; movementY: number; movementX: number; preventDefault: () => void; } ) {

        if ( this.mouseClicked ) {

            // const divId = event.path[ 1 ].id;

            const divId = event.target.offsetParent.id;

            const div = document.getElementById( divId )!;

            // Moving Top
            if ( event.movementY > 0 ) {
                div.scrollTop = div.scrollTop + 10;
            }

            // Moving Down
            if ( event.movementY < 0 ) {
                div.scrollTop = div.scrollTop - 10;
            }

            // Moving Right
            if ( event.movementX > 0 ) {
                div.scrollLeft = div.scrollLeft + 10;
            }

            // Moving Left
            if ( event.movementX < 0 ) {
                div.scrollLeft = div.scrollLeft - 10;
            }
        }

        // for Chrome and Firefox
        if ( event.preventDefault ) {
            event.preventDefault();
        }
    }

}
