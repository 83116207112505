import { Component, OnInit, EventEmitter, ElementRef } from '@angular/core';
import { IdentityManualValidationData } from 'src/app/core/interfaces/identity-manual-validation-data';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { FileUploader } from 'ng2-file-upload';
import { Subscription } from 'rxjs';
import { IdentityService } from 'src/app/core/services/identity.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { IdentityVerificationStatusType } from '../../core/enums/identity-verification-status-type.enum';
@Component( {
    selector: 'app-identity-work-verification',
    templateUrl: './identity-work-verification.component.html',
    styleUrls: [ './identity-work-verification.component.css' ],
} )
export class IdentityWorkVerificationComponent implements OnInit {


    statuses: any[] = [

        {
            id: IdentityVerificationStatusType.ProofFound,
            name: 'Proof Found',
        },
        {
            id: IdentityVerificationStatusType.ProofNotFound,
            name: 'Proof Not Found',
        },
        {
            id: IdentityVerificationStatusType.Timeout,
            name: 'Timeout',
        },
    ];

    verification!: IdentityManualValidationData;

    filterParams: HttpParams = new HttpParams();

    form!: FormGroup;

    // uploader: FileUploader = new FileUploader( { url: '' } );

    showHide!: boolean;

    verificationId!: number | any;

    croppedFrontUrl: string | null = null;

    croppedBackUrl: string | null = null;

    formSubmitting!: boolean;

    filtered: EventEmitter<string> = new EventEmitter();

    filtersSubscription!: Subscription;

    validStatusesForClientRemarks: number[] = [ 2, 3, 5, 9, 11, 12, 14, 17, 18, 19, 21, 23, 24 ];

    maxFileSize: number = 300;

    maxFileError: boolean = false;

    constructor (
        private identityService: IdentityService,
        private route: ActivatedRoute,
        private fb: FormBuilder,

        private router: Router,
        private filterService: FilterService,
        protected el: ElementRef,
        private http: HttpClient,
        private commonService: CommonService,
        private apiService: ApiService
    ) {
    }

    ngOnInit (): void {


        this.route.queryParams.subscribe( ( params: any ) => {

            this.formSubmitting = false;
            this.maxFileError = false;
            this.initForm();

            this.verificationId = this.route.snapshot.params[ 'id' ];

            this.subscribeToQueryParams( params );

            this.identityService.getVerificationWork( this.filterParams, this.verificationId ).subscribe( ( response: any ) => {
                this.verification = response;
                this.form.patchValue( response );
            } );

        } );


        this.ngOnChanges();

    }

    subscribeToQueryParams ( params: any ): void {

        this.filterParams = new HttpParams();

        Object.keys( params ).forEach( key => {
            if ( key !== 'params' && ( params[ key ] !== '' && params[ key ] !== 'all' ) ) {
                this.filterParams = this.filterParams.set( key, params[ key ] );
            }
        } );

    }

    ngOnChanges (): void {

        this.form.get( 'verification_status' )?.valueChanges.subscribe( ( statusId ) => {

            if ( this.validStatusesForClientRemarks.includes( parseInt( statusId ) ) ) {

                this.form.get( 'client_remarks' )?.setValidators( [ Validators.required ] );

            } else {
                this.form.get( 'client_remarks' )?.setValidators( [] );
            }

            this.form.get( 'client_remarks' )?.updateValueAndValidity();

        } );
    }


    initForm (): void {

        this.form = this.fb.group( {
            verification_status: [ '', Validators.required ],
            client_remarks: [ '' ],
            number: [],
            name_as_per_id: [],
            father_name_as_per_id: [],
            dob_as_per_id: [],
            address_as_per_id: [],
            status_id: [],
        } );

    }

    onDoneClicked (): void {
        this.formSubmitting = true;

        const data: any = {
            data: this.form.value,
        };

        this.identityService.postVerificationWork( this.filterParams, this.verificationId, data ).subscribe( ( response: any ) => {


            const params: HttpParams = this.filterParams;
            // Set our navigation extras object
            // that contains our global query params and fragment

            const navigationExtras: NavigationExtras = {
                queryParams: {
                    params,
                },
                queryParamsHandling: 'merge',
            };

            this.router.navigate( [ 'work/identity/manual-validation', response.id ], navigationExtras );

        }, ( error: any ) => {

            this.formSubmitting = false;

            if ( error.status == 404 ) {

                this.commonService.notifications.warning( 'No Case Found' );

                const navigationExtras: NavigationExtras = {
                    queryParams: this.filterParams,
                    queryParamsHandling: 'merge',
                };

                this.router.navigate( [ 'work/identity' ], navigationExtras );

            } else if ( error.error.code == 422 ) {
                this.commonService.notifications.error( error.error );
            } else {

                this.commonService.notifications.error( error.error );

                const navigationExtras: NavigationExtras = {
                    queryParams: this.filterParams,
                    queryParamsHandling: 'merge',
                };

                this.router.navigate( [ 'work/identity' ], navigationExtras );
            }
        } );
    }

    onExitClicked (): void {

        this.formSubmitting = true;

        this.identityService.cancelVerificationWork( this.filterParams, this.verificationId ).subscribe( () => {

            this.router.navigate( [ 'work/identity' ] );

        } );


    }

    upload ( type_of_file: string ): void {


        // Locate the file element meant for the file upload.
        const inputEl: HTMLInputElement | any = this.el.nativeElement.querySelector( '#proof_url' );

        // Get the total amount of files attached to the file input.
        const fileCount: number = inputEl.files.length;

        this.checkFileSize( inputEl, fileCount );

        if ( !this.maxFileError ) {

            // Create a new form data instance
            const formData: FormData = new FormData();

            // Check if the file count is greater than zero, to be sure a file was selected.
            if ( fileCount > 0 ) { // a file was selected

                const files: any = inputEl.files;

                for ( let i = 0; i < fileCount; i++ ) {

                    formData.append( 'file_' + i, inputEl.files.item( i ) );
                }

                formData.append( 'type', type_of_file + '_' + this.verificationId );

                const url: string = 'identity' + '/' + this.verificationId + '/upload';

                const headers: HttpHeaders = new HttpHeaders( { Authorization: 'Basic ' + localStorage.getItem( 'token' ) } );

                const options: any = { headers };

                // TODO:: move http to service

                this.apiService.upload( url, formData ).subscribe(
                    success => {
                        this.commonService.notifications.success( 'File uploaded Successfully' );
                        this.ngOnInit();
                    },
                    error => this.commonService.notifications.error( 'Unable to upload File' )
                );
            }
        }
        this.maxFileError = false;
    }


    checkFileSize ( inputEl: HTMLInputElement | any, fileCount: number ): void {

        let fileSize: number = 0;

        for ( let i = 0; i <= fileCount; i++ ) {

            if ( inputEl.files[ i ] ) {

                fileSize = inputEl.files[ i ].size / 1024;

                if ( fileSize > this.maxFileSize ) {
                    this.maxFileError = true;
                    this.commonService.notifications.error( 'Unable to upload!! File size must be less than 300KB' );
                    alert( 'Unable to upload!! File size must be less than 300KB' );
                }

            }

        }
    }
}
