import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-language-typeahead',
    templateUrl: './language-typeahead.component.html',
    styleUrls: [ './language-typeahead.component.css' ],
} )
export class LanguageTypeaheadComponent implements OnInit {

    public static languageCache: any = [];

    @Input() formGroup!: FormGroup;
    @Input() control: string = 'language';
    @Input() labelName: string = 'Language';
    @Input() required: boolean = false;

    languages!: any[];

    get languageId (): string { return this.control + '_id'; }

    get languageName (): string { return this.control + '_name'; }

    get languageIdValue (): string { return this.formGroup.get( this.languageId )?.value; }

    get languageNameValue (): string { return this.formGroup.get( this.languageName )?.value; }

    get options (): any[] { return this.languages; }

    constructor ( public global: GlobalService,
        public common: CommonService, ) { }

    ngOnInit () {
        this.fetchlanguages()
    }

    fetchlanguages (): void {

        if ( LanguageTypeaheadComponent.languageCache[ this.control ] ) {

            this.languages = LanguageTypeaheadComponent.languageCache[ this.control ];

        } else {

            this.common.getLanguages().subscribe(
                response => {

                    this.languages = LanguageTypeaheadComponent.languageCache[ this.control ] = response;

                }
            );
        }

    }


    onSelect ( $event: TypeaheadMatch ): void {

        this.formGroup.get( this.languageId )?.setValue( $event.item.id );
        this.formGroup.get( this.languageName )?.setValue( $event.item.name );


    }

    onNoResults ( $event: boolean ): void {

        if ( $event === true ) {

            this.formGroup.get( this.languageId )?.setValue( null );
            this.formGroup.get( this.languageName )?.setValue( null );

        }
    }
}
