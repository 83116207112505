import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Pagination } from '../../core/models/pagination.model';
import { VcourtJob } from '../../core/models/vcourt-job.model';
import { VcourtResult } from '../../core/models/vcourt-result.model';
import { CommonService } from '../../core/services/common.service';
import { VcourtService } from '../../core/services/vcourt.service';
import { UserService } from './../../core/services/user.service';
import { isNull } from 'src/app/core/unit';

@Component( {
    selector: 'app-vcourt-job-show',
    templateUrl: './vcourt-job-show.component.html',
} )
export class VcourtJobShowComponent implements OnInit {
    jobId!: number;
    results: VcourtResult[] = [];
    pagination: Pagination = new Pagination();
    vcourtJob: VcourtJob = new VcourtJob();
    allow: boolean = false;
    count: number = 0;
    stateSearch!: string;
    nameSearch!: string;
    addressSearch!: string;
    districtSearch!: string;
    all: string = 'all';
    filterParams: HttpParams = new HttpParams();
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    element!: HTMLElement;
    incompleteWorkList: string[] = [];

    constructor (
        private route: ActivatedRoute,
        protected router: Router,
        private vcourtService: VcourtService,
        private commonService: CommonService,
        private fb: FormBuilder,
        private user: UserService,
    ) { }

    ngOnInit (): void {
        this.allow = false;

        this.initForm();

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {
            // (+) converts string 'id' to a number
            this.jobId = +params[ 'id' ];

            this.getVcourtJobDetails();
            this.subscribeToQueryChanges();
            this.getCasesByJobId();
        } );
    }

    initForm (): void {
        // If exact_match_status value is present in query set it otherwise set it as all
        this.filterFormGroup = this.fb.group( {
            exact_match_status: this.filterParams.get( 'exact_match_status' )
                ? this.filterParams.get( 'exact_match_status' )
                : 'all',
        } );

        this.initializePipeParams();
    }

    subscribeToQueryChanges (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
        } );
    }

    // show the results as per the filters.
    filterQueriedResults (): void {
        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath: string = this.router.url.split( '?' )[ 0 ];

        const currentUrl: string = currentUrlPath.replace( '#', '' );

        this.router.navigate( [ currentUrl ], navigationExtras ).then( () => {
            this.getCasesByJobId();
        } );
    }

    /**
     * Get the basic details of the submitted job.
     */
    getVcourtJobDetails (): void {
        this.vcourtService.getVcourtJob( this.jobId ).subscribe( vcourtJob => {
            this.vcourtJob = vcourtJob;

            const loggedInUserId: number = this.user.getCurrentUser()[ 'id' ];

            // show the button if work is Red Case L1 and is incomplete and assigned user is loggedIn User
            if ( vcourtJob.work_assigned ) {
                for ( const work of vcourtJob.work_assigned[ 0 ] ) {

                    if ( loggedInUserId === work.assigned_to_id && !work.is_completed ) {

                        this.incompleteWorkList.push( work.title );
                    }
                }
            }
        } );
    }

    /**
     * Navigate to next/previous job.
     *
     * @param navigationType
     */
    navigate ( navigationType: string ): void {
        // make the results blank/empty
        this.pagination = new Pagination();
        this.vcourtJob = new VcourtJob();

        this.filterParams = this.filterParams.set( 'navigation_type', navigationType );

        this.vcourtService
            .navigate( this.jobId, this.filterParams )
            .subscribe( response => {
                this.handleResponse( response );
            } );
    }

    /**
     * Show the cases of the navigated job id
     *
     * @param id
     */
    showJob ( id: number ): void {
        this.initializePipeParams();


        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'merge',
            // TODO:: optimise this
            fragment: this.filterParams.toString(),
        };

        const link: any[] = [ 'vcourt', id ];

        this.router.navigate( link, navigationExtras );
    }

    /**
     * Handle the response when navigation buttons are clicked.
     *
     * @param response
     */
    handleResponse ( response: any ): void {
        if ( response.navigation_id === 'none' ) {
            this.commonService.notifications.warning(
                'WARNING',
                'NO CANDIDATE IS AVAILABLE TO NAVIGATE'
            );
        } else {
            this.showJob( response.navigation_id );
        }
    }

    // Mark the job status.
    markJobStatusAs ( status: string ): void {
        this.vcourtService.markJobStatusAs( [ this.jobId, status ] ).subscribe( data => {
            this.checkResponse( data, status );
        } );
    }

    // Check the response when the status and updated and give the alert accordingly.
    checkResponse ( response: any, status: string ): void {
        if ( response[ 'id' ] === 200 ) {
            this.commonService.notifications.success(
                'Successfully',
                response[ 'message' ]
            );
            if ( !isNull( status ) ) {
                this.vcourtJob.icr_status = status;
            }
        } else {
            this.commonService.notifications.error( 'Error', response[ 'message' ] );
        }
    }

    // Mark the Case status.
    markCaseStatusAs ( status: string, result: any ): void {
        this.vcourtService
            .markCaseStatusAs( [ result.id, status ] )
            .subscribe( response => {
                // this.checkResponse( response, null );
                this.element = document.getElementById( result.id ) as HTMLElement;

                if ( this.element ) {

                    if ( status !== 'status_removed' ) {
                        this.element.className = 'bg-danger';
                        result.exact_match = status.toUpperCase();
                    } else {
                        this.element.className = '';
                        result.exact_match = 0;
                    }
                }
            } );
    }

    // Get Cases by Job Id.
    getCasesByJobId (): void {
        this.vcourtService
            .getCasesByJobId( this.jobId, this.filterParams )
            .subscribe( results => {
                if ( this.vcourtJob.type === 'company' ) {
                    this.pagination = results;
                } else {
                    this.results = results;
                }
            } );
    }

    // Fetch the cases again, if not loaded on page reload.
    fetchCasesAgain ( event: any ): void {
        this.vcourtJob = new VcourtJob();
        this.results = [];
        event.target.innerHTML = 'In Progress';

        this.vcourtService.fetchCases( this.jobId ).subscribe( response => {
            this.getVcourtJobDetails();
            this.results = response.cases;

            if ( response.success === true ) {
                this.commonService.notifications.success(
                    'Cases Fetched',
                    response[ 'message' ]
                );
                event.target.className = 'btn btn-success btn-addon';
                event.target.innerHTML = 'Cases Fetched';
            } else {
                this.commonService.notifications.error(
                    'Cases Not Fetched',
                    response[ 'message' ]
                );
                event.target.className = 'btn btn-danger btn-addon';
                event.target.innerHTML = 'Fetching Failed';
            }

            // Change the text again to the original text.
            setTimeout( () => {
                event.target.className = 'btn btn-default btn-addon';
                event.target.innerHTML = 'Fetch Results Again';
            }, 4000 );
        } );
    }

    // Make the link to the icr/{employeeId}/edit for the job.

    icrEmployeeEditLink ( employeeId: number ): void {
        const link: any[] = [ 'icr', employeeId, 'edit' ];

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };

        this.router.navigate( link, navigationExtras );
    }

    /**
     * Remove all filters.
     */
    removeFilters (): void {
        // Set Exact Match Status value to all
        this.initializePipeParams();
        this.initializeFormParams();

        if ( this.vcourtJob.type === 'company' ) {
            this.getCasesByJobId();
        }
    }

    initializeFormParams (): void {
        this.filterFormGroup.setValue( { exact_match_status: 'all' } );
    }

    /**
     * Initialize Pipe Params
     */
    initializePipeParams (): void {
        this.stateSearch = '';
        this.nameSearch = '';
        this.addressSearch = '';
        this.districtSearch = '';
    }

    /**
     * Refresh the components on the page, instead of loading the complete page.
     */
    refreshPage (): void {
        this.results = [];
        this.vcourtJob = new VcourtJob();
        this.removeFilters();
        this.getVcourtJobDetails();
        this.getCasesByJobId();
    }

    markWorkComplete ( workType: string ): void {

        const data: any = { jobId: this.jobId, workType };

        this.vcourtService.markWorkComplete( data ).subscribe( ( response: any ) => {
            if ( response[ 'status' ] === 'success' ) {
                this.commonService.notifications.success( response[ 'message' ] );


                this.incompleteWorkList.splice( this.incompleteWorkList.indexOf( workType ), 1 );

                this.ngOnInit();
            } else {
                this.commonService.notifications.error( 'Error', response[ 'message' ] );
            }
        },
            error => {
                this.commonService.notifications.error( 'Error', 'something went wrong' );
            } );
    }
}
