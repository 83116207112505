import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { WebhookService } from '../../core/services/webhook.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-create-sub-url-modal',
    templateUrl: './create-sub-url-modal.component.html',
    styleUrls: [ './create-sub-url-modal.component.css' ],
} )
export class CreateSubUrlModalComponent implements OnInit {

    private webhookUrl!: any;

    public createSubUrlForm: FormGroup;

    @ViewChild( 'webhookSubUrlCreateModal', { static: false } )
    public webhookSubUrlCreateModal!: ModalDirective;

    @Output()
    onSubmit = new EventEmitter<boolean>();

    constructor (
        private webhookService: WebhookService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) {
        this.createSubUrlForm = this.fb.group( {
            url_id: [ '' ],
            sub_url: [ '', Validators.required ],
            method_type: [ '', Validators.required ],
            type: [ '', Validators.required ],
            query_params: [ '' ],
        } );
    }

    ngOnInit () {
    }

    show ( webhookUrl: any ) {
        this.webhookUrl = webhookUrl;
        this.createSubUrlForm.get( 'url_id' )?.setValue( this.webhookUrl.id );
        console.log( this.webhookUrl );
        this.webhookSubUrlCreateModal.show();
    }

    hide () {
        this.webhookSubUrlCreateModal.hide();
    }

    createSubUrl () {

        const data = this.createSubUrlForm.value;
        this.webhookService.createSubUrl( data ).subscribe( response => {
            this.checkResponse( response )
        },
            error => {
                this.commonService.displayValidationErrors( error );

            } );
    }

    // Check the response when the status and updated and give the alert accordingly.
    checkResponse ( response: any ) {

        if ( response[ 'success' ] ) {

            this.commonService.notifications.success( 'Successfully', response[ 'message' ] );
            this.hide();
            this.onSubmit.emit( true );
        }
        else {
            this.commonService.notifications.error( 'Error', response[ 'message' ] );
        }
    }
}
