import { FilterService } from './../../core/services/filter.service';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { Employee } from '../../core/models/employee.model';
import { Lot } from '../../core/models/lot.model';
import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { EmployeeService } from '../../core/services/employee.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';


@Component( {
    selector: 'app-employee-list',
    templateUrl: './employee-list.component.html',
    styleUrls: [ './employee-list.component.css' ],
} )
export class EmployeeListComponent extends ExportExcelComponent implements OnInit {

    // Which lot is in use here
    @Input()
    lot!: Lot;

    employees: Employee[] = [];
    data!: Pagination;
    lotId!: number;

    filterParams: HttpParams = new HttpParams();
    loading!: boolean;
    pagination: Pagination = new Pagination();
    filterFormGroup!: FormGroup;
    filtersSubscription!: Subscription;
    showEmployee: any;

    constructor (
        private employeeService: EmployeeService,
        private route: ActivatedRoute,
        private router: Router,
        private commonService: CommonService,
        private fb: FormBuilder,
        private filterService: FilterService,
        private confirmationModalService: ConfirmationModalService
    ) {
        super();
    }


    ngOnInit (): void {

        this.route.params.subscribe( params => {

            // (+) converts string 'id' to a number
            this.lotId = +params[ 'id' ];
        } );

        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filterResults( this.filterFormGroup.value );

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );
    }


    initForm (): void {

        const myDate: Date = new Date();

        const previousMonth: Date = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );

        previousMonth.setDate( myDate.getDate() + 1 );

        this.filterFormGroup = this.fb.group( {
            employee_created_after: previousMonth.toJSON().slice( 0, 10 ),
            employee_created_before: 'all',
        } );
    }

    // Get Filtered Employees of the Lot
    filterResults ( params: Params ): void {

        this.employeeService.getByLot( this.lotId, params ).subscribe(
            pagination => this.pagination = pagination );
    }

    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }


    // Load Next Page when using Pagination
    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }

    // Open Verification Edit Page
    verificationLink ( verificationType: string, employeeId: number ): void {

        const link: any[] = [ verificationType, employeeId, 'edit' ];

        this.router.navigate( link );
    }


    // Export the employees list on the current page.
    exportExcel (): void {

        this.employeeService.getDataForExcel( this.lotId, this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'employees' );
            } );

    }

    onDeleteEmployeeConfirm ( id: number, key: number ): void {

        this.employeeService.deleteEmployee( id ).subscribe(
            response => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] );

                this.pagination.data.splice( key, 1 );
            }
        );
    }

    deleteEmployee ( id: number, key: number ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.onDeleteEmployeeConfirm( id, key ) );

    }

    exportVendorExcel (): void {
        this.employeeService.getDataForVendorExcel( this.lotId, this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Vendor Excel' );
            } );
    }

    // TODO:: didn't find any useage of this method
    redirectToVerification ( verification: string ): void {

        // To add the navigation_from filter param dynamically.
        const updatedQueryParams: Params = { company_id: this.lot.company_id, lot_id: this.lotId };

        const navigationExtras: NavigationExtras = {
            queryParams: updatedQueryParams,
            queryParamsHandling: 'merge',
        };

        this.router.navigate( [ verification ], navigationExtras );
    }

    modalUpdated (): void {

        this.filterResults( this.filterFormGroup.value );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }
}

