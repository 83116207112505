import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Verification } from 'src/app/core/models/verification.model';
import { CommonService } from 'src/app/core/services/common.service';
import { VerificationService } from 'src/app/core/services/verification.service';

@Component( {
    selector: 'app-verification-client-remark-create',
    templateUrl: './verification-client-remark-create.component.html',
    styleUrls: [ './verification-client-remark-create.component.css' ],
} )
export class VerificationClientRemarkCreateComponent implements OnInit {

    verifications: Verification[] = [];
    addClientRemarkFormGroup!: FormGroup;

    constructor (
        private verificationService: VerificationService,
        private commonService: CommonService,
        private fb: FormBuilder,
    ) { }


    ngOnInit (): void {

        this.initForm();

        this.verificationService.getAllVerifications().then( ( verifications: Verification[] ) => this.verifications = verifications );
    }


    initForm (): void {

        this.addClientRemarkFormGroup = this.fb.group( {
            verification: [ '', Validators.required ],
            remark: [ '', Validators.required ],
        } );
    }


    submit (): void {

        this.verificationService.createClientRemark( this.addClientRemarkFormGroup.value ).subscribe(
            ( response: any ) => {
                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Successful', response[ 'message' ] );
                    this.initForm();
                } else {
                    this.commonService.notifications.error( 'Error', response[ 'message' ] );
                }
            },
            ( error: any ) => {
                this.commonService.displayValidationErrors( error );
            } );
    }
}
