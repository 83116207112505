<div bsModal #userResetPasswordModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Change Password for {{user.name}}</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset class="form-group">
                                    <label class="text-mute" [popover]="popTemplate" triggers="mouseenter:mouseleave"
                                        container="body">New Password
                                    </label>
                                    <input type="password" formControlName="password"
                                        class="form-control form-control-lg" />
                                </fieldset>

                                <small class="text-danger"
                                    [hidden]="resetPasswordForm.get('password')?.valid || resetPasswordForm.get('password')?.pristine">
                                    Wrong format
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset class="form-group">

                                    <label class="text-mute" [popover]="popTemplate" triggers="mouseenter:mouseleave"
                                        container="body">Confirm Password
                                    </label>
                                    <input type="password" formControlName="confirm_password"
                                        class="form-control form-control-lg" />

                                    <ng-template #popTemplate>
                                        <p>Minimum 6 character,Atleast one small character,Atleast one upper case
                                            character,Atleast one number,Atleast one special character</p>
                                    </ng-template>

                                </fieldset>
                                <small class="text-danger"
                                    [hidden]="resetPasswordForm.get('confirm_password')?.valid || resetPasswordForm.get('confirm_password')?.pristine">
                                    Wrong format
                                </small>


                            </div>

                        </div>


                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!resetPasswordForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>