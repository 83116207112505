<div bsModal #clientsApiCredentials="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Send Client Credential to Client</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <form [formGroup]="sendCredentialFormGroup" (ngSubmit)="submitForm()"
                        (keydown.enter)="$event.preventDefault()">
                        <fieldset [disabled]="submitted">
                            <fieldset class="form-group">
                                <label class="center-block"> Select Client Email Address</label>
                                <app-multi-select [options]="clients" [placeholder]="'Select Client Email Address'"
                                    [controlName]="'clients'" [formGName]="sendCredentialFormGroup"></app-multi-select>

                            </fieldset>
                            <fieldset class="form-group">
                                <label class="center-block">Filter by type</label>
                                <select class="form-control" formControlName="environment">
                                    <option value="production">Production</option>
                                    <option value="development">Development</option>
                                </select>
                            </fieldset>
                            <button class="btn btn-primary btn-block" [disabled]="!sendCredentialFormGroup.valid">
                                Send Api Credential
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>