import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkAssignedListComponent } from '../../core/components/assigned-work-list.component';
import { IcrService } from '../../core/services/icr.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/core/services/filter.service';

@Component( {
    selector: 'app-icr-assigned-work-list',
    templateUrl: './icr-assigned-work-list.component.html',
    styleUrls: [ './icr-assigned-work-list.component.css' ],
} )
export class IcrAssignedWorkListComponent extends WorkAssignedListComponent implements OnInit, OnDestroy {

    // Form group associated with the filters
    override filterFormGroup!: FormGroup;
    override filterParams: HttpParams = new HttpParams();
    override assignedWorkList!: any[];
    filtersSubscription!: Subscription;

    constructor ( protected verificationService: IcrService,
        protected override route: ActivatedRoute,
        protected override router: Router,
        // public override global: SimpleGlobal,
        protected override fb: FormBuilder,
        protected override filterService: FilterService ) {
        super();
        this.initForm();
    }

    // Initialize the filters.
    initForm (): void {

        this.filterFormGroup = this.fb.group( {
            created_after: this.getPreviousMonthDate(),
            created_before: '',
            verification_type: 'icr',
        } );

    }

    ngOnInit (): void {

        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: any ) => {

            this.filterResults( params );
        } );

        this.filterResults( this.filterFormGroup.value );

    }


    filterResults ( params: any ): void {

        this.verificationService.getAssignedWorkList( params ).subscribe( response => {

            this.assignedWorkList = response;
        } );

    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }

}
