<div class="wrapper-md">

    <form [formGroup]="lotCreateFormGroup" (ngSubmit)="onSubmit()">

        <div class="row">

            <div class="col-md-6">

                <div class="panel panel-default">
                    <div class="panel-heading">
                        Create Lot
                    </div>
                    <div class="panel-body">

                        <app-errors [errors]="errors"></app-errors>

                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="center-block">Name</label>
                                <input formControlName="lot_name" class="form-control">
                            </div>
                            <div class="form-group col-md-6">
                                <label class="center-block">Cities</label>
                                <input formControlName="cities" class="form-control">
                            </div>
                        </div>

                        <div class="row">

                            <div class="form-group col-md-6">
                                <label>Lot status</label>
                                <select class="form-control" formControlName="lot_status">
                                    <option value="0">Active</option>
                                    <option value="1">In Active</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label>Lot type</label>
                                <select class="form-control" formControlName="type">
                                    <option value="blue-collar">Blue</option>
                                    <option value="white-collar">White</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6" *ngIf="clients">
                                <label class="center-block">Clients</label>
                                <app-multi-select [options]="clients" [placeholder]="'Select Clients'"
                                    [controlName]="'clients'" [formGName]="lotCreateFormGroup"></app-multi-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="center-block">Editors</label>
                                <app-multi-select [options]="users" [placeholder]="'Select Editors'"
                                    [controlName]="'editors'" [formGName]="lotCreateFormGroup"></app-multi-select>
                            </div>
                            <div class="form-group col-md-12">
                                <fieldset class="form-group">
                                    <label>Lot Instructions </label> <span class="pull-right"></span>

                                    <ckeditor formControlName="instructions"></ckeditor>
                                </fieldset>
                            </div>
                        </div>


                        <button class="btn btn-primary btn-block" [disabled]="!lotCreateFormGroup.valid">
                            Create Lot
                        </button>

                    </div>
                </div>

            </div>

            <div class="col-md-6">
                <div class="panel panel-default">

                    <div class="panel-heading">
                        Verifications
                    </div>
                    <div class="panel-body">
                        <div class="form-group col-md-12">

                            <div *ngFor="let verification of verifications; let i = index">
                                <label class="checkbox-inline">
                                    <input type="checkbox" value="{{verification}}"
                                        (change)="onCheckboxChange($event,i,verification)">
                                    {{verification.title}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6" formGroupName="settings">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        Additional Settings
                    </div>
                    <div class="panel-body">
                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="icr_civil_status"> Icr Civil Status
                            </label>
                        </div>
                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="icr_criminal_status"> Icr Criminal Status
                            </label>
                        </div>

                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="email_visibility"> Email Visibility
                            </label>
                        </div>

                        <div class="form-group col-md-12">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="mobile_visibility"> Mobile Visibility
                            </label>
                        </div>

                        <div class="form-group col-md-12">
                            <label class="center-block">GST Number</label>
                            <input formControlName="gst_number" class="form-control">
                        </div>
                        <div class="form-group col-md-12">
                            <label class="center-block">Billing Name</label>
                            <input formControlName="billing_name" class="form-control">
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </form>

</div>