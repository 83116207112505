<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Bulk Upload</h1>
</div>

<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading">
            Select the Files to upload in the format:
        </div>
        <div class="panel-body">

            <div class="form-group col-md-3">
                <label class="center-block">Verification</label>
                <select class="form-control" [(ngModel)]="verification" required>
                    <option *ngFor="let verification of verifications" [value]="verification.shortTitle">
                        {{verification.title}}
                    </option>
                </select>
            </div>
            <div class="form-group col-md-3">
                <label class="center-block">Document Type</label>
                <select class="form-control" [(ngModel)]="document" required>
                    <option
                        *ngFor="let document of verificationDocumentTypeArray |verificationDocumentType:verification"
                        [value]="document.key">
                        {{document.value}}
                    </option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <label class="center-block">Upload</label>
                <input type="file" ng2FileSelect [uploader]="uploader" multiple /><br />
            </div>
        </div>

    </div>
</div>

<div class="wrapper-md" style="margin-bottom: 40px">
    <div class="panel panel-default">
        <div class="panel-heading">
            Upload queue
        </div>
        <div class="panel-body">
            <p>Queue length: {{ uploader.queue.length }}</p>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th width="50%">Name</th>
                            <th>Size</th>
                            <th>Progress</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of uploader.queue">
                            <td><strong>{{ item?.file?.name }}</strong></td>
                            <td nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                            <td>
                                <div class="progress" style="margin-bottom: 0;">
                                    <div class="progress-bar" role="progressbar"
                                        [ngStyle]="{ 'width': item.progress + '%' }"></div>
                                </div>
                            </td>
                            <td class="text-center">
                                <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
                                <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
                                <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
                            </td>
                            <td nowrap>
                                <button type="button" class="btn btn-success btn-xs" (click)="item.upload()"
                                    [disabled]="item.isReady || item.isUploading || item.isSuccess">
                                    <span class="glyphicon glyphicon-upload"></span> Upload
                                </button>
                                <button type="button" class="btn btn-warning btn-xs" (click)="item.cancel()"
                                    [disabled]="!item.isUploading">
                                    <span class="glyphicon glyphicon-ban-circle"></span> Cancel
                                </button>
                                <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                                    <span class="glyphicon glyphicon-trash"></span> Remove
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <div>
                    Queue progress:
                    <div class="progress" style="">
                        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }">
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-success btn-s" (click)="uploadAll()"
                    [disabled]="!uploader.getNotUploadedItems().length">
                    <span class="glyphicon glyphicon-upload"></span> Upload all
                </button>
                <button type="button" class="btn btn-warning btn-s" (click)="uploader.cancelAll()"
                    [disabled]="!uploader.isUploading">
                    <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
                </button>
                <button type="button" class="btn btn-danger btn-s" (click)="uploader.clearQueue()"
                    [disabled]="!uploader.queue.length">
                    <span class="glyphicon glyphicon-trash"></span> Remove all
                </button>
            </div>

        </div>

    </div>
</div>