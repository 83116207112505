import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PccService } from '../../core/services/pcc.service';
import { environment } from '../../../environments/environment';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { DownloadService } from 'src/app/core/services/download.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';

@Component( {
    selector: 'app-pcc-letterhead-excel',
    templateUrl: './pcc-letterhead.component.html',
} )
export class PccLetterheadComponent extends ImportExcelComponent implements OnInit {

    sources!: string[];
    override isSubmitting: boolean = false;
    override excelForm!: FormGroup;
    actionUrl!: string;
    token!: string;
    uploadForm!: FormGroup;
    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor (
        private pccService: PccService,
        protected override fb: FormBuilder,
        private downloadService: DownloadService,
        protected commonService: CommonService,
    ) { super( fb ); }

    ngOnInit (): void {

        this.actionUrl = `${ environment.api_url }${ 'pcc/letterhead/download' }`;

        this.getLetterHeadSource();

        this.uploadForm = this.fb.group( {
            via: [ '', [ Validators.required ] ],
            excel_data: this.fb.array( [] ),
        } );

    }

    getLetterHeadSource (): void {
        this.pccService.getLetterheadSource().subscribe(
            response => {
                this.sources = response.sources;
            }
        )
    }

    get excelData (): FormArray {
        return this.uploadForm.get( 'excel_data' ) as FormArray;
    }

    addExcelData ( formGroup: FormGroup | any ): void {
        this.excelData.push( this.fb.group( {
            track_id: [ formGroup.get( 'track_id' ).value, [ Validators.required ] ],
            state_name: [ formGroup.get( 'state_name' ).value, [ Validators.required, ] ],
            police_station: [ formGroup.get( 'police_station' ).value, [ Validators.required, ] ],
            name: [ formGroup.get( 'name' ).value, [ Validators.required, ] ],
            mobile_number: [ formGroup.get( 'mobile_number' ).value, [ Validators.required, ] ],
            identity_proof: [ formGroup.get( 'identity_proof' ).value, [ Validators.required, ] ],
            fathers_name: [ formGroup.get( 'fathers_name' ).value, [ Validators.required, ] ],
            district_name: [ formGroup.get( 'district_name' ).value, [ Validators.required, ] ],
            authority_name: [ formGroup.get( 'authority_name' ).value, [ Validators.required, ] ],
            address: [ formGroup.get( 'address' ).value, [ Validators.required, ] ],
        } ) );
    }

    applyValidations (): void {
        this.excelData.clear();
        this.excelFormArray.controls.forEach( formGroup => {
            this.addExcelData( formGroup );
        } );
    }

    initExcelForm (): void {
        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void { }

    onSubmit (): void {

        const fileType = 'zip';

        const documentName = 'Pcc Letterhead';

        this.downloadService.downloadFile( this.actionUrl, fileType, this.uploadForm.value ).subscribe(
            ( blob: Blob ) => {
                this.downloadService.getFile( blob, documentName );
            },
            ( error ) => {
                this.commonService.notifications.error( 'Some Error Occured', error );
            }
        );
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
