import { Component, OnDestroy, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { Pagination } from '../../core/models/pagination.model';
import { AadhaarService } from '../../core/services/aadhaar.service';
import { CommonService } from '../../core/services/common.service';
import { HttpParams } from '@angular/common/http';

@Component( {
    selector: 'app-aadhaar-logs',
    templateUrl: './aadhaar-logs.component.html',
    styleUrls: [ './aadhaar-logs.component.css' ],
} )
export class AadhaarLogsComponent extends ExportExcelComponent implements OnInit, OnDestroy {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress: boolean = false;

    filtersSubscription!: Subscription;

    constructor (
        private aadhaarService: AadhaarService,
        private commonService: CommonService,
        private filterService: FilterService
    ) {
        super();
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ](
            {
                next: ( params: HttpParams ) => {

                    this.filterParams = params;

                    this.filterByParams();

                }
            }
        );

    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();

    }

    filterByParams (): void {

        this.aadhaarService.filter( this.filterParams ).subscribe( response => {

            this.pagination = response;

        } );

    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );

    }

    resend ( log: { id: string; } ): void {
        this.aadhaarService.resend( log.id ).subscribe( () => {
            this.commonService.notifications.success( 'Success', 'Aadhaar Verification Sent successfully' );
        } );
    }

}
