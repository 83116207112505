import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { VoterService } from '../../core/services/voter.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-voter-details-modal',
    templateUrl: './voter-details-modal.component.html',
    styleUrls: [ './voter-details-modal.component.css' ],
} )
export class VoterDetailsModalComponent {

    logId!: number;
    details: any;
    receieved!: boolean;

    @ViewChild( 'voterDetailsModal', { static: false } )
    public voterDetailsModal!: ModalDirective;

    constructor (
        private voterService: VoterService,
        private commonService: CommonService
    ) {
    }


    show ( logId: any ) {

        this.logId = logId;

        this.voterService.show( logId ).subscribe( response => {
            this.details = response;
            this.voterDetailsModal.show();

        },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );

    }

    hide () {
        this.voterDetailsModal.hide();
    }

}
