<div class="panel panel-default">

    <div class="panel-heading">
        Invited Employee Response
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="form-group col-md-12" *ngFor="let response of customFields">
                <label class="center-block">{{response.key}}</label>
                <input class="form-control" type="text" value="{{response.value}}" disabled>
            </div>
        </div>
    </div>
</div>