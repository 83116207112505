import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../core/shared.module';
import { CreditReportListComponent } from './credit-report-list/credit-report-list.component';
import { CreditReportExcelImportComponent } from './credit-report-excel-import/credit-report-excel-import.component';
import { CreditReportFiltersComponent } from './credit-report-filters/credit-report-filters.component';
import { CreditReportSearchModalComponent } from './credit-report-search-modal/credit-report-search-modal.component';
import { CreditReportResponseModalComponent } from './credit-report-response-modal/credit-report-response-modal.component';
import { CreditReportStatsComponent } from './credit-report-stats/credit-report-stats.component';
import { CreditReportService } from '../core/services/credit-report.service';
@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        CreditReportListComponent,
        CreditReportExcelImportComponent,
        CreditReportFiltersComponent,
        CreditReportSearchModalComponent,
        CreditReportResponseModalComponent,
        CreditReportStatsComponent ],
    providers: [ CreditReportService ],
} )
export class CreditReportModule {
}
