<div bsModal #contactCreateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="contactCreateForm" (ngSubmit)="create()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Create Contact</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label class="center-block"> Name of Person</label>
                                    <input formControlName="name" class="form-control form-control-lg" />
                                </fieldset>
                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Designation </label>
                                    <input formControlName="designation" class="form-control form-control-lg" />
                                </fieldset>
                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Primary Email </label>
                                    <input formControlName="primary_email" class="form-control form-control-lg" />
                                </fieldset>
                                <small class="text-danger"
                                    [hidden]="contactCreateForm.get('primary_email')?.valid || contactCreateForm.get('primary_email')?.pristine">
                                    Wrong format
                                </small>

                            </div>

                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Secondary Email </label>
                                    <input formControlName="secondary_email" class="form-control form-control-lg" />
                                </fieldset>
                                <small class="text-danger"
                                    [hidden]="contactCreateForm.get('secondary_email')?.valid || contactCreateForm.get('secondary_email')?.pristine">
                                    Wrong format
                                </small>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Phone Number </label>
                                    <input formControlName="phone_number" class="form-control form-control-lg" />
                                </fieldset>
                            </div>

                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Location </label>
                                    <input formControlName="location" class="form-control form-control-lg" />
                                </fieldset>
                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Division </label>
                                    <input formControlName="division" class="form-control form-control-lg" />

                                </fieldset>

                            </div>
                            <div class="col-md-3">
                                <fieldset class="form-group">
                                    <label>Reference Name </label>
                                    <input formControlName="reference_name" class="form-control form-control-lg" />
                                </fieldset>
                            </div>
                        </div>

                        <div class="row">

                            <fieldset class="form-group col-md-6">
                                <label>Remarks </label>
                                <textarea rows="1" formControlName="remarks" class="form-control"></textarea>
                            </fieldset>

                            <fieldset class="form-group col-md-3">
                                <label class="center-block"> Active
                                    <input type="checkbox" formControlName="active">
                                </label>
                            </fieldset>

                            <fieldset class="form-group col-md-3">
                                <label class="center-block"> Helpful
                                    <input type="checkbox" formControlName="helpful">
                                </label>
                            </fieldset>

                            <fieldset class="form-group col-md-3">
                                <label class="center-block"> Centralised
                                    <input type="checkbox" formControlName="centralised">
                                </label>
                            </fieldset>
                        </div>

                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!contactCreateForm.valid">
                            Create
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>