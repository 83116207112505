import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// import { UserLevel } from '../models/user.model';
import { UserService } from './user.service';

@Injectable( {
    providedIn: 'root'
} )
export class AdminGuard implements CanActivate {

    constructor (
        private router: Router,
        private userService: UserService,
    ) { }

    canActivate (): boolean {

        if ( this.userService.isAdmin ) return true;

        this.router.navigate( [ '/home' ] );

        return false;
    }
}
