import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { JwtService } from './jwt.service';


import { ApiService } from './api.service';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class HistoryService {

    constructor ( private http: HttpClient,
        private jwtService: JwtService,
        private apiService: ApiService ) {
    }

    private setHeaders (): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + localStorage.getItem( 'token' ),
        };

        return new HttpHeaders( headersConfig );
    }

    /**
     *
     * @param path
     * @returns
     * @deprecated dont use this method
     */
    getHistory ( path: string ) {
        return this.apiService.get( path );
    }

    all ( params: Params | undefined ) {
        return this.apiService.get( 'history', params );
    }

    getDataForExcel ( params: Params | undefined ) {
        return this.apiService.get( 'history/download', params );
    }
}
