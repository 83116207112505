<div class="wrapper-md">

    <app-voter-search-modal #voterSearchModal (onSearch)="onSearch()"></app-voter-search-modal>

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div class="col-md-2 form-group">
                        <label class="center-block">Voter ID Number</label>
                        <input class="form-control" formControlName="voter_id_number" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Filter by Client</label>
                        <select class="form-control" formControlName="client_api_id">
                            <option value="all">All</option>
                            <option *ngFor="let client_api of clients" [value]="client_api.id">
                                {{ client_api.owner_name }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Status</label>
                        <select class="form-control" formControlName="status">
                            <option value="all">All</option>
                            <option value="1">Details Found</option>
                            <option value="0">Details Not found</option>
                            <option value="2">Invalid Captcha</option>
                            <option value="-1">Failure</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Source</label>
                        <select class="form-control" formControlName="source">
                            <option value="all">All</option>
                            <option value="api">API</option>
                            <option value="crm">CRM</option>
                            <option value="dexter">Dexter</option>
                            <option value="excel">Excel</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="voterSearchModal.show()" class="btn btn-primary btn-addon btn-block">
                            <i class="fa fa-search"></i>
                            Search
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button routerLink="/voter/import" class="btn btn-primary btn-addon btn-block">
                            <i class="fa fa-upload"></i>
                            Import Excel
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="exportLogsExcel()" class="btn btn-primary btn-addon btn-block">
                            <i class="fa fa-download"></i>
                            Download Excel
                        </button>
                    </div>
                    <div class="form-group col-md-2">

                        <label class="center-block">Click here to</label>
                        <button (click)="resendAll()" class="btn btn-primary btn-addon btn-block">
                            <i class="fa fa-send"></i>
                            Resend All
                        </button>

                    </div>


                </div>
            </form>
        </div>
    </div>
</div>