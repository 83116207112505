import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from '../core/shared.module';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientService } from '../core/services/client.service';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientFilterComponent } from './client-filter/client-filter.component';
import { AllClientsListComponent } from './all-clients-list/all-clients-list.component';
import { CompanyClientsListComponent } from './company-clients-list/company-clients-list.component';
import { ClientUploadComponent } from './client-upload/client-upload.component';
import { ClientHistoryComponent } from './client-history/client-history.component';

@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPaginationModule,
        SharedModule,
        TrimValueAccessorModule,

    ],
    declarations: [
        ClientListComponent,
        ClientEditComponent,
        ClientCreateComponent,
        ClientFilterComponent,
        AllClientsListComponent,
        CompanyClientsListComponent,
        ClientUploadComponent,
        ClientHistoryComponent
    ],
    providers: [ ClientService ],
} )
export class ClientModule {
}
