import {
    IdentityType,
    AddressType,
    EducationType,
    EmploymentType,
    GlobalType,
    DocumentVerificationType,
    ReferenceType,
    EmploymentVerificationMethod
} from '../../client-forms-v2/client-forms-v2.enum';
import { ClientFormType } from '../enums/client-form-type.enum';

/**
 * @deprecated old model
 */
export class ClientForms {
    form_id!: number;
    form_name!: string;
    company_id!: number;
    lot_id!: number;
    education_check!: boolean;
    identity_check!: boolean;
    court_record_check!: boolean;
    employment_check!: boolean;
    current_address_check!: boolean;
    permanent_address_check!: boolean;
    created_at!: string;
    form_status!: string;
    email_subject!: string;
    email_body!: string;
    link!: string;
    fields!: any[];
}

export class FormV2 {
    permanent_address_check!: boolean;
    form_status!: string;
    employment_check!: boolean;
    identity_check!: boolean;
    form_id!: number;
    form_name!: string;
    company_id!: number;
    company_name!: string;
    consent_form_body!: string;
    court_record_check!: boolean;
    created_at!: string;
    current_address_check!: boolean;
    education_check!: boolean;
    email_body!: string;
    email_subject!: string;
    id!: number;
    name!: string;
    status!: string;
    lot!: Lot;
    fields!: Fields[];
    settings!: Settings;
    link!: string;
    histories!: Object;
    type!: ClientFormType
}

export interface Lot {
    lot_id: number;
}

export interface CreateFormRequest {
    name: string
    status: string
    email_subject: string
    email_body: string
    consent_form_body: string
    company_id: string
    lot_id: string
    fields: Fields[]
    settings: Settings
}

export interface Fields {
    key: string;
    label: string;
    placeholder: string;
    required: string;
    type: string;
    select_option: string;
    file_types: any[];
}

export interface Settings {
    consent: Consent;
    basic: Basic;
    identity: Identities;
    address: Address;
    education: Education;
    employment: Employment;
    icr: Icr;
    global_db: GlobalDb;
    document: Documents;
    reference: Reference;
    pcc: Pcc;

}

export interface Consent {
    instructions: string;
    required: boolean;
    signature_required: boolean;
}

export interface Basic {
    instructions: string;
    required: boolean;
    signature_required: boolean;
    resume: { visible: boolean, required: boolean };
    client_employee_id: { visible: boolean, required: boolean };
    expected_joining_date: { visible: boolean, required: boolean };
}

export interface Identities {
    instructions: string;
    required: boolean;
    verifications: Verifications[];
}

export interface Verifications {
    type: IdentityType;
    required: boolean;
}

export interface Address {
    instructions: string;
    required: boolean;
    verifications: AddressVerifications[];
}

export interface AddressVerifications {
    type: AddressType;
    required: boolean;
    instructions: string;
    is_digital: boolean;
    documents: AddressDigitalDocument[]
}

export interface AddressDigitalDocument {
    type: IdentityType;
    front_required: boolean;
    back_required: boolean;
}

export interface Pcc {
    instructions: string;
    verifications: PccVerifications[];
}

export interface PccVerifications {
    address_type: AddressType;
    address_required: boolean;
    documents: Verifications[];
    required: boolean;
}

export interface Education {
    instructions: string;
    required: boolean;
    verifications: EducationVerifications[];
}

export interface EducationVerifications {
    type: EducationType;
    required: boolean;
}

export interface Employment {
    instructions: string;
    required: boolean;
    via: EmploymentVerificationMethod;
    value: number;
    verifications: EmploymentVerifications[];
}

export interface EmploymentVerifications {
    type: EmploymentType;
    required: boolean;
}

export interface Icr {
    instructions: string;
    required: boolean;
    verifications: Verifications[];
}

export interface GlobalDb {
    instructions: string;
    required: boolean;
    verifications: GlobalDbVerifications[];
}

export interface GlobalDbVerifications {
    type: GlobalType;
    required: boolean;
}

export interface Documents {
    instructions: string;
    required: boolean;
    verifications: DocumentVerifications[];
}

export interface DocumentVerifications {
    type: DocumentVerificationType;
    required: boolean;
}

export interface Reference {
    instructions: string;
    required: boolean;
    verifications: ReferenceVerifications[];
}

export interface ReferenceVerifications {
    type: ReferenceType;
    required: boolean;
}

export interface ClientFormsList {
    id: number
    name: string
    company: FormV2Company
    created_at: string
    email_body: string
    email_subject: string
    link: string
    fields: Fields[]
    lot: FormV2Lot
    status: FormV2StatusType;
    status_name: string;
    not_filled_invites_count: number
    settings: Settings
}

export enum FormV2StatusType {
    DISABLED = '0',
    ACTIVE = '1'
}

export interface FormV2Company {
    company_city: string
    company_id: number
    company_logo: string
    company_name: string
    company_official_name: string
    company_prefix: string
    created_at: string
    updated_at: string
    status: number
}

export interface FormV2Lot {
    lot_id: number;
    company_id: number;
    identity_check: boolean;
    id_count: number;
    education_check: boolean;
    court_record_check: boolean;
    employment_check: boolean;
    lot_name: string;
    current_address_check: boolean;
    permanent_address_check: boolean;
    client_id: number;
    lot_status: string;
    type: string;
    created_by: string | null;
    closed_by: string | null;
    closed_on: string | null;
    lot_form_support: number;
    police_check: number;
    document_check: boolean;
    reference_verification: boolean;
    global_db_verification: number;
    settings: FormV2CompanySettings
    instructions: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    company: FormV2Company
}

export interface FormV2CompanySettings {
    icr_civil_status: boolean;
    icr_criminal_status: boolean;
    gst_number: string;
    billing_name: string;
    email_visibility: boolean;
    mobile_visibility: boolean;
}
