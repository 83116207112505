import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterService } from '../../../core/services/filter.service'
import { CommonService } from '../../../core/services/common.service';
import { HttpParams } from '@angular/common/http';
import { ClientCrms } from '../../../core/enums/client-crms.enum';
import { Lot } from 'src/app/core/models/lot.model';
import { Company } from 'src/app/core/models/company.model';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-client-crm-filters',
    templateUrl: './client-crm-filters.component.html',
} )
export class ClientCrmFiltersComponent implements OnInit {
    filterFormGroup!: FormGroup;

    lots: Lot[] = [];
    ClientCrms = ClientCrms;
    companies: Company[] = [];

    constructor (
        private fb: FormBuilder,
        private filterService: FilterService,
        private commonService: CommonService,
        private globalService: GlobalService
    ) { }

    ngOnInit (): void {

        this.initForm();

        this.updateFormFilter();

        this.filterResults();

        this.companies = this.globalService.fetchCompanies()

    }

    initForm (): void {

        const currentDate: Date = new Date();

        const previousMonth: Date = new Date( currentDate );

        previousMonth.setDate( currentDate.getDate() - 30 );

        this.filterFormGroup = this.fb.group( {
            company_id: [],
            lot_id: [],
            type: [],
            page: 1,
        } );
    }

    onCompanyDropownClosed () {

        const params = new HttpParams();

        const companyIds: [] = this.filterFormGroup.get( 'company_id' )?.value;

        // remove all current lots
        this.lots.splice( 0, this.lots.length );

        const combinedLots: any = [];

        if ( !companyIds || !companyIds.toString() ) return;

        const request = params.set( 'company_id', companyIds.toString() );

        // fetch the lots for each company id selected
        this.commonService.companiesLots( request ).subscribe( lots => {

            lots.forEach( lot => combinedLots.push( lot ) );

            this.lots = combinedLots;

        } );

    }


    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }

    filterResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    resetForm (): void {

        this.initForm();

        this.filterResults();
    }
}
