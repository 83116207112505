export class Verification {

    // title: string;
    // shortTitle: string;
    // verificationRelation: string;
    // lotColumnName: string;
    // name: string;
    // id: string;

    constructor (
        public id: string,
        public name: string,
        public title: string,
        public shortTitle: string,
        public verificationRelation: string,
        public lotColumnName: string
    ) {
    }
}
