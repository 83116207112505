<div class="panel panel-default" [formGroup]="identityForm">
    <div class=" panel-heading">
        Identity Verification
        <h4 class="panel-title pull-right">

            <div class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">
                <span class="glyphicon">
                    <input type="checkbox" (click)="sectionToggle($event)" [checked]="showSection"></span>

            </div>
        </h4>
    </div>
    <div id="collapse-identity" [className]="showSection ? 'panel-collapse collapse in' : 'panel-collapse collapse'">
        <div class="panel-body">
            <div class="col-md-12" style="margin-bottom:5px ;">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"></textarea>
            </div>

            <div class="col-md-5 margin-bottom-10">
                <button type="button" (click)="addIdentity()" class="btn btn-primary">Add</button>
            </div>

            <div class="col-md-7 margin-bottom-10 text text-danger" *ngIf="identityVerificationArray.length==0">
                Please select atleast one verification
            </div>

            <div class="col-md-12 margin-bottom-10"
                *ngFor="let idForm of identityVerificationArray.controls;let i=index;">
                <div class="row" [formGroup]="idForm">
                    <div class="col-md-2">
                        <input type="checkbox" formControlName="required"> Is Required
                    </div>
                    <div class="col-md-9">
                        <select formControlName="type" class="form-control" (change)="skipSelectedIdentity(i,$event)">
                            <option [disabled]="selectedOption.includes(identity.value)"
                                *ngFor="let identity of identityList" [value]="identity.value">
                                {{identity.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-1">
                        <button type="button" (click)="onIdentityTypeDeleted(i)" class="btn"><i
                                class="fa fa-trash text text-danger" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>