import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Company } from '../../core/models/company.model';
import { CommonService } from '../../core/services/common.service';
import { CompanyService } from '../../core/services/company.service';
import { EnvironmentService } from './../../core/services/environment.service';


@Component( {
    selector: 'app-company-show',
    templateUrl: './company-edit.component.html',
    styleUrls: [ './company-edit.component.css' ],
} )
export class CompanyEditComponent implements OnInit {
    company: Company = new Company();
    editForm!: FormGroup;
    isSubmitting: boolean = false;
    companyLogo!: File;

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private companyService: CompanyService,
        private fb: FormBuilder,
        protected el: ElementRef,
        protected http: HttpClient,
        protected common: CommonService,
        private environmentService: EnvironmentService,
    ) { }

    ngOnInit (): void {

        this.initForm();

        this.route.paramMap.pipe(
            switchMap( ( params: ParamMap ) => this.companyService.find( +params?.get( 'id' )! ) ) )
            .subscribe( company => this.company = company );
        this.editForm.patchValue( this.company );
    }

    initForm (): void {

        this.editForm = this.fb.group( {
            company_name: [ '', Validators.required ], company_official_name: [ '', Validators.required ],
            company_city: [ '', Validators.required ], company_id: [ '', Validators.required ],
            status: [ '', Validators.required ]
        } );
    }


    updateCompany (): void {
        this.isSubmitting = true;
        this.companyService.update( this.editForm.value.company_id, this.editForm.value ).subscribe(
            // response => {
            //     // this.router.navigate( [ '/companies/', response.company_id ] );
            // }
            {
                next: ( response ) => {
                    this.router.navigate( [ '/companies/', response.company_id ] );
                },
                error: ( errors ) => {

                }
            }
        );
    }

    onUploadCompanyLogo ( event: any ): void {

        if ( !event.target.files || event.target.files.length === 0 ) return;

        this.companyLogo = event.target.files[ 0 ];

        const companyRequest = new FormData();

        companyRequest.append( 'company_logo', this.companyLogo );

        this.companyService.uploadCompanyLogo( this.company.id, companyRequest ).subscribe( {

            next: ( success: { success: boolean } ) => {

                this.companyService.find( this.company.id ).subscribe( response => {
                    this.company = response;
                } );

                this.common.notifications.success( 'File uploaded Successfully' );
            },
            error: ( error ) => {
                this.common.notifications.error( 'Unable to upload File' );
            }

        } );
    }

}
