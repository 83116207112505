import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICandidateDocument, ICandidateDocumentLabel } from '../models/candidate-document.modal';
import { IVerificationDocument } from '../models/employee-document-attachment.modal';

@Injectable( {
    providedIn: 'root',
} )
export class CandidateDocumentService {

    documents: ICandidateDocument[] = [];

    documentFormGroup = new FormGroup( {
        documentFormArray: new FormArray( [] ),
    } );

    addEmptyDocument ( imageUrl: string, index: any = 0, pageDetails: string = '' ): void {

        if ( !imageUrl || imageUrl == '' ) return;

        const item = new FormGroup( {
            imageUrl: new FormControl( imageUrl, [ Validators.required ] ),
            verificationLabel: new FormControl( null, [ Validators.required ] ),
            verificationId: new FormControl( null, [ Validators.required ] ),
            typeLabel: new FormControl( null, [ Validators.required ] ),
            typeId: new FormControl( null, [ Validators.required ] ),
            forceUpload: new FormControl( false ),
            uploaded: new FormControl( false ),
            documentLabel: new FormControl( null, [ Validators.required ] ),
            documentType: new FormControl( null, [ Validators.required ] ),
            table: new FormControl( null, [ Validators.required ] ),
            documentId: new FormControl( null, [ Validators.required ] ),
            page: new FormControl( pageDetails ),
        } );

        if ( index ) {

            this.documentFormArray().insert( index + 1, item );

        } else {

            this.documentFormArray().push( item );

        }

    }

    addVerificationField ( documentLabel: string, documentType: string, table: string, documentId: number | any, imageUrl: string | any, labels: ICandidateDocumentLabel ): void {

        const document: ICandidateDocument = {
            imageUrl: imageUrl,
            verificationLabel: labels.verificationLabel,
            verificationId: labels.verificationId,
            typeLabel: labels.typeLabel,
            typeId: labels.typeId,
            uploaded: true,
            forceUpload: false,
            documentLabel: documentLabel,
            documentType: documentType,
            table: table,
            documentId: documentId,
        }

        this.documents.push( document );

        this.addItem( document );

    }

    addItem ( verification: ICandidateDocument ): void {

        if ( !verification.imageUrl || verification.imageUrl == '' ) return;

        const item = new FormGroup( {
            imageUrl: new FormControl( verification.imageUrl, [ Validators.required ] ),
            verificationLabel: new FormControl( verification.verificationLabel, [ Validators.required ] ),
            verificationId: new FormControl( verification.verificationId, [ Validators.required ] ),
            typeLabel: new FormControl( verification.typeLabel, [ Validators.required ] ),
            typeId: new FormControl( verification.typeId, [ Validators.required ] ),
            forceUpload: new FormControl( verification.forceUpload ),
            uploaded: new FormControl( verification.uploaded ),
            documentLabel: new FormControl( verification.documentLabel, [ Validators.required ] ),
            documentType: new FormControl( verification.documentType, [ Validators.required ] ),
            table: new FormControl( verification.table, [ Validators.required ] ),
            documentId: new FormControl( verification.documentId, [ Validators.required ] ),
            page: new FormControl( null ),
        } );

        if ( verification.uploaded ) {
            item.disable();
        }

        this.documentFormArray().push( item );
    }

    addExtraDocuments ( verification: string, documents: IVerificationDocument[], labels: ICandidateDocumentLabel ): void {

        documents.forEach( ( document, index ) => {

            const documentName = 'Document ' + ( index + 1 ) + ' - ' + document.name;

            this.addVerificationField( documentName, 'document', 'documentables', document.id, document.url, labels );

        } );
    }

    addExtraAttachment ( verification: string, attachments: IVerificationDocument[], labels: ICandidateDocumentLabel ): void {

        attachments.forEach( ( attachment, index ) => {

            const attachmentName = 'Attachment ' + ( index + 1 ) + ' - ' + attachment.name;

            this.addVerificationField( attachmentName, 'attachment', 'attachables', attachment.id, attachment.url, labels );

        } );
    }

    documentFormArray (): FormArray {

        return this.documentFormGroup.get( 'documentFormArray' ) as FormArray;
    }

    getTypeLabelList ( verificationId: string ): ICandidateDocument[] {

        const verificationList = this.documents?.filter(
            verifications => verifications.verificationId == verificationId
        );

        return verificationList;
    }

    getVerificationDocuments ( verificationId: string, typeId: number, documentType: string, documentId: number ): ICandidateDocument[] {

        const documentList = this.documents?.filter(
            verifications =>
                verifications.verificationId == verificationId && verifications.typeId == typeId
                && verifications.documentType == documentType && verifications.documentId == documentId
        );

        return documentList;
    }

    documentList ( verificationId: string, typeId: number ): ICandidateDocument[] {

        const documentList = this.documents?.filter(
            verifications =>
                verifications.verificationId == verificationId
                && verifications.typeId == typeId
                && verifications.documentId == typeId
        );

        return documentList;
    }

    documentItemList ( verificationId: string, typeId: number ): ICandidateDocument[] {

        const documentList = this.documentFormArray().value?.filter(
            ( verifications: any ) =>
                verifications.verificationId == verificationId
                && verifications.typeId == typeId
                && verifications.documentId == typeId
        );

        return documentList;
    }

    removeDocument ( index: number ): void {

        this.documentFormArray().removeAt( index );

    }

    swapDocument ( index: number, secondField: ICandidateDocument ): void {

        const firstItem = this.documentFormArray().at( index );

        this.documentFormArray().controls.forEach( items => {
            if (
                items.value.verificationId == secondField.verificationId && items.value.typeId == secondField.typeId &&
                items.value.documentType == secondField.documentType && items.value.documentId == secondField.documentId
            ) {
                items.get( 'imageUrl' )?.patchValue( firstItem.get( 'imageUrl' )?.value );
            }
        } )

        firstItem.get( 'imageUrl' )?.patchValue( secondField.imageUrl );
    }
}
