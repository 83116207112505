import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/core/services/common.service';
import { DocumentVerificationList } from '../client-forms-v2.enum'

@Component( {
    selector: 'app-document-form',
    templateUrl: './document-form.component.html',
} )
export class DocumentFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    selectedOption: string[] = [];

    isSectionChecked: boolean = false;

    documentVerificationList = DocumentVerificationList;

    maxLimit: number = 3;

    documentVerificationForm = this.fb.group( {
        instructions: this.fb.control( null ),
        verifications: this.fb.array( [] ),
    } )

    get documentVerificationArray (): FormArray | any {
        return this.documentVerificationForm.get( 'verifications' ) as FormArray;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.documentVerificationArray.length > 0;
    }

    constructor ( private fb: FormBuilder, private commonService: CommonService ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'document', this.documentVerificationForm );
    }

    addDocument (): void {

        const documentItem = this.fb.group( {
            sub_type: this.fb.control( 'income', [ Validators.required ] ),
            front_required: this.fb.control( false, [ Validators.required ] ),
            back_required: this.fb.control( false, [ Validators.required ] ),
        } );

        if ( this.documentVerificationArray.length >= this.maxLimit ) {
            this.commonService.notifications.error( 'Document', `Document max limit is ${ this.maxLimit }` );
        } else {
            this.documentVerificationArray.push( documentItem );
        }
    }

    sectionToggle ( $event: any ): void {
        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {
            this.documentVerificationArray.clear();
        }
    }

    skipSelectedItem ( index: any, $event: any ): void {
        this.selectedOption[ index ] = $event.target.value;
    }

    onDocumentTypeDeleted ( index: number ): void {
        const selectedDocumentTypeValue: string = this.documentVerificationArray.at( index ).get( 'sub_type' )?.value;

        this.selectedOption = this.selectedOption.filter( ( documentTypeValue: string ) => documentTypeValue != selectedDocumentTypeValue );

        this.documentVerificationArray.removeAt( index );
    }
}
