<div bsModal #webhookEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="webhookEditForm" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Company</h4>

                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">
                        <div class="row">
                            <div class="col-md-8">
                                <fieldset class="form-group">
                                    <input formControlName="id" class="form-control form-control-lg" type="hidden" />
                                    <label class="center-block"> URL</label>
                                    <input formControlName="url" class="form-control form-control-lg" />

                                </fieldset>
                            </div>
                            <div class="col-md-4 form-group">
                                <label> Status</label>
                                <select class="form-control" formControlName="status_id" required>
                                    <option value="1">Active</option>
                                    <option value="0">InActive</option>
                                </select>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-offset-8 col-md-4 text-right">
                                <label class="center-block"> Header </label>
                                <a (click)="addNewRow()" class="btn btn-sm btn-addon">
                                    <i class="fa fa-plus"></i>
                                </a>
                            </div>
                        </div>
                        <div formArrayName="headers">
                            <div class="row" *ngFor="let item of formArray.controls; let i=index" [formGroupName]="i">
                                <div class="col-md-6 form-group">
                                    <label class="center-block"> Header </label>
                                    <input class="form-control" formControlName="header">

                                </div>
                                <div class="col-md-6 form-group">
                                    <label class="center-block">
                                        Value
                                    </label>
                                    <input class="form-control" formControlName="value">
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" (click)="updateWebhook()"
                            [disabled]="!webhookEditForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>