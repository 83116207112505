import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GstListComponent } from './gst-list/gst-list.component';
import { GstSearchModalComponent } from './gst-search-modal/gst-search-modal.component';
import { GstImportExcelComponent } from './gst-import-excel/gst-import-excel.component';
import { GstService } from '../core/services/gst.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { GstResponseModalComponent } from './gst-response-modal/gst-response-modal.component';
import { SharedModule } from '../core/shared.module';
import { GstFiltersComponent } from './gst-filters/gst-filters.component';
import { GstStatsComponent } from './gst-stats/gst-stats.component';
import { GstDetailsModalComponent } from './gst-details-modal/gst-details-modal.component';

@NgModule( {
    imports: [
        CommonModule,
        NgxPaginationModule,
        SharedModule,
    ],
    declarations: [
        GstListComponent,
        GstSearchModalComponent,
        GstImportExcelComponent,
        GstResponseModalComponent,
        GstFiltersComponent,
        GstStatsComponent,
        GstDetailsModalComponent,
    ],
    providers: [ GstService ],
} )
export class GstModule { }
