import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Lot } from '../../core/models/lot.model';
import { CommonService } from '../../core/services/common.service';
import { LotService } from '../../core/services/lot.service';

@Component( {
    selector: 'app-lot-add-missing-verification',
    templateUrl: './lot-add-missing-verification.component.html',
    styleUrls: [ './lot-add-missing-verification.component.css' ],
} )
export class LotAddMissingVerificationComponent {

    verifications: any[] = [];
    selected_verification!: string;

    @Input()
    lot!: Lot;

    @ViewChild( 'addMissingVerificationsModal', { static: false } )
    public addMissingVerificationsModal!: ModalDirective;

    constructor (
        private lotService: LotService,
        private commonService: CommonService
    ) {
        this.initForm();
    }

    show () {
        this.addMissingVerificationsModal.show();

    }

    hide () {
        this.initForm();
        this.addMissingVerificationsModal.hide();
    }

    addMissingVerifications () {
        this.lotService.addMissingVerification( this.lot.id, this.selected_verification ).subscribe(
            response => {
                this.commonService.notifications.success( 'Success', response[ 'message' ] );
                this.hide();
            },
            error => {
                this.commonService.notifications.error( 'Error', 'Something went wrong' );
                this.hide();
            }
        );
    }


    private initForm () {
        this.verifications = [];
    }
}
