import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class OcrService {
    constructor ( private api: ApiService ) {
    }

    filter ( params: Params | undefined ) {
        return this.api.get( 'ocr', params );
    }

    getDataForExcel ( params: Params | undefined ) {
        return this.api.get( 'ocr/download', params );
    }

    getStats ( params: Params | undefined ) {
        return this.api.get( 'ocr/stats', params );
    }

    getApiResponse ( id: string ) {
        return this.api.get( 'ocr/  ' + id );
    }

    importExcel ( data: any ): Observable<any> {
        return this.api.post( 'ocr/excel', data );
    }

    resendAll ( source: string, params: Params | undefined ): Observable<any> {
        return this.api.get( 'ocr/resendAll?type=' + source, params );
    }

    getHtmlResponse ( logId: number ): Observable<{ name: string, link: string }[]> {
        return this.api.get( 'ocr/' + logId + '/html-response' );
    }
}
