import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FilterService } from 'src/app/core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-user-filters',
    templateUrl: './user-filters.component.html',
    styleUrls: [ './user-filters.component.css' ]
} )
export class UserFiltersComponent implements OnInit {

    @Input() filterParams: HttpParams = new HttpParams;

    filterFormGroup!: FormGroup;

    get userLevels () { return this.globalService.fetchUserLevels(); }

    constructor (
        private fb: FormBuilder,
        private filterService: FilterService,
        private globalService: GlobalService,
    ) {
    }

    ngOnInit (): void {
        this.initForm();
        this.setDefault();
        this.filterResults();
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            name: [],
            email: [],
            mobile_number: [],
            level: [],
            status: [],
            page: 1,
        } );
    }

    setDefault (): void {
        this.filterService.updateFormFilters( this.filterFormGroup );
    }

    filterResults (): void {

        if ( this.filterFormGroup.get( 'level' )?.value !== null && Array.isArray( this.filterFormGroup.get( 'level' )?.value ) ) {
            this.filterFormGroup.get( 'level' )?.setValue( this.filterFormGroup.get( 'level' )?.value.map( ( x: string | number ) => +x ) );
        }

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    resetForm (): void {
        this.initForm();
        this.filterResults();
    }
}
