<div class="wrapper-md">
    <div class="row">

        <div class="col-sm-3" *ngIf="signzyStats">
            <div class="panel panel-default">
                <div class="panel-heading">Signzy Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Signzy Details Found
                        <span class="pull-right text-bold"> {{ signzyStats['signzy_has_details'] }} </span>
                    </p>
                    <p class="mar-no">
                        Signzy Details Not Found
                        <span class="pull-right text-bold"> {{ signzyStats['signzy_does_not_has_details'] }} </span>
                    </p>
                    <p class="mar-no">
                        Signzy Failure
                        <span class="pull-right text-bold"> {{ signzyStats['signzy_failure'] }} </span>
                    </p>
                    <p class="mar-no">
                        Signzy Not Initiated
                        <span class="pull-right text-bold">{{ signzyStats['signzy_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>


        <div class="col-sm-3" *ngIf="totalkycStats">
            <div class="panel panel-default">
                <div class="panel-heading">Signzy Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Totalkyc Details Found
                        <span class="pull-right text-bold"> {{ totalkycStats['totalkyc_has_details'] }} </span>
                    </p>
                    <p class="mar-no">
                        Totalkyc Details Not Found
                        <span class="pull-right text-bold"> {{ totalkycStats['totalkyc_does_not_has_details'] }} </span>
                    </p>
                    <p class="mar-no">
                        Totalkyc Failure
                        <span class="pull-right text-bold"> {{ totalkycStats['totalkyc_failure'] }} </span>
                    </p>
                    <p class="mar-no">
                        Totalkyc Not Initiated
                        <span class="pull-right text-bold">{{ totalkycStats['totalkyc_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        --
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>