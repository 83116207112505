import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Employees, EmploymentVerificationMethod } from '../client-forms-v2.enum'

@Component( {
    selector: 'app-employment-form',
    templateUrl: './employment-form.component.html',
} )
export class EmploymentFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    employmentTypeList = Employees;

    selectedOption: string[] = [];

    isSectionChecked: boolean = false;

    employmentVerificationMethod = EmploymentVerificationMethod;

    employmentForm = this.fb.group( {
        instructions: this.fb.control( null ),
        via: this.fb.control( null, [ this.customRequiredValidator() ] ),
        value: this.fb.control( null, [ this.customRequiredValidator(), Validators.min( 1 ), Validators.max( 100 ) ] ),
        verifications: this.fb.array( [], [ this.customRequiredValidator() ] ),
    } );

    get employmentVerifications (): FormArray | any {
        return this.employmentForm.get( 'verifications' ) as FormArray;
    }

    get employmentVia (): FormControl {
        return this.employmentForm.get( 'via' ) as FormControl;
    }

    get employmentCount (): FormControl {
        return this.employmentForm.get( 'value' ) as FormControl;
    }

    get showYearCountField (): boolean {
        return this.employmentVia.value === this.employmentVerificationMethod.years;
    }

    get showSection (): boolean {
        return this.isSectionChecked || this.employmentVerifications.length > 0;
    }

    constructor ( private fb: FormBuilder ) { }

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'employment', this.employmentForm );
    }

    formSettingItem (): FormGroup {

        return this.fb.group( {
            type: this.fb.control( 'any', [ Validators.required ] ),
            required: this.fb.control( true, [ Validators.required ] ),
        } );
    }

    addEmployment (): void {
        this.employmentVerifications.push( this.formSettingItem() );
        this.updateEmploymentCount();
    }

    removeEmployment ( index: number ): void {
        this.employmentVerifications.removeAt( index );
        this.updateEmploymentCount();
    }

    sectionToggle ( $event: any ): void {

        this.isSectionChecked = $event.target.checked;

        if ( !this.isSectionChecked ) {

            this.employmentVerifications.clear();

            this.employmentForm.reset();

        }

        this.employmentVia.updateValueAndValidity();

        this.employmentCount.updateValueAndValidity();

        this.employmentVerifications.updateValueAndValidity();

    }

    skipSelectedItem ( index: any, $event: any ): void {
        this.selectedOption[ index ] = $event.target.value;
    }

    onSelectionChanged (): void {
        this.updateEmploymentCount();
    }

    updateEmploymentCount (): void {

        if ( this.employmentVia.value === this.employmentVerificationMethod.count ) {
            this.employmentCount.setValue( this.employmentVerifications.length )
        }
    }

    customRequiredValidator (): ValidatorFn {

        return ( control: AbstractControl ): ValidationErrors | null => {

            if ( typeof control.value === 'string' && this.isSectionChecked && ( control.value === '' || control.value === null ) ) {

                return { required: { value: control.value } };

            } else if ( Array.isArray( control.value ) && this.isSectionChecked && control.value.length === 0 ) {

                return { required: { value: control.value } };

            } else if ( this.isSectionChecked && ( control.value === '' || control.value === null ) ) {

                return { required: { value: control.value } };

            } else {

                return null;

            }

        };
    }

}
