import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Pagination } from 'src/app/core/models/pagination.model';
import { BlacklistedService } from 'src/app/core/services/blacklisted.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { Subscription } from 'rxjs';


@Component( {
    selector: 'app-blacklisted-list',
    templateUrl: './blacklisted-list.component.html',
    styleUrls: [ './blacklisted-list.component.css' ],
} )
export class BlacklistedListComponent extends ExportExcelComponent implements OnInit, OnDestroy {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress: boolean = false;
    filtersSubscription!: Subscription;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients!: any[];

    constructor (
        private blacklistedService: BlacklistedService,
        private commonService: CommonService,
        private filterService: FilterService,
    ) {
        super();
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );
    }


    filterResults ( params: Params ): void {


        this.blacklistedService.filter( params ).subscribe( response => {
            this.pagination = response;
        } );

    }

    showPage ( page: number ): void {

        this.filterService.filterByParams( { page } );
    }

    resend ( source: string, log: any ): void {

        const data: { type: string, log_id: number } = {
            type: source,
            log_id: log.id,
        };

        this.blacklistedService.resend( data ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }

    resendAll ( source: string ): void {

        this.blacklistedService.resendAll( source, this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }

    filterQueriedResults (): void {

        this.filterService.filter();
    }

    exportLogsExcel (): void {
        this.filterParams = this.filterParams.set( 'unique', 'no' );
        this.getData();
    }

    exportUniqueLogsExcel (): void {
        this.filterParams = this.filterParams.set( 'unique', 'yes' );
        this.getData();
    }


    exportDailyUniqueLogsExcel (): void {
        this.filterParams = this.filterParams.set( 'unique', 'daily' );
        this.getData();
    }

    getData (): void {

        this.fetchingInProgress = true;
        this.blacklistedService.getDataForExcel( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Blacklisted Logs Excel' );
                this.fetchingInProgress = false;
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );
    }


    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }
}
