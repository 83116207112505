<app-select-filter-modal #verificationFilterModal [filters]="updatedFilters" [verification]="verification"
    (onFilterUpdate)="filterUpdate($event)"></app-select-filter-modal>
<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
            <a (click)="verificationFilterModal.show()"><i class="fa fa-cog rightt-align" aria-hidden="true"></i></a>
        </div>

        <div class="panel-body" *ngIf="filters.length">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div *ngIf="companies" class="form-group col-md-2" [hidden]="!('company' | inArray:filters)">
                        <label class="center-block"> Company</label>
                        <app-multi-select [options]="companies" [placeholder]="'Select Company'"
                            [controlName]="'company_id'" [formGName]="filterFormGroup"
                            [multipleValue]="false"></app-multi-select>
                    </div>

                    <div class="form-group col-md-2" [hidden]="!('lot' | inArray:filters)">
                        <label class="center-block"> Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('completed' | inArray:filters)">
                        <label class="center-block">Completed</label>
                        <select class="form-control" formControlName="completed">
                            <option [value]="'all'">All</option>
                            <option [value]="1">Yes</option>
                            <option [value]="0">No</option>
                        </select>
                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group"
                        [hidden]="!('verification_status' | inArray:filters)">
                        <label class="center-block">Filter by status</label>
                        <app-multi-select [options]="statuses[verification]" [placeholder]="'Select status'"
                            [controlName]="'status_id'" [formGName]="filterFormGroup"
                            [multipleValue]="true"></app-multi-select>

                    </div>
                    <div *ngIf="statuses" class="col-md-2 form-group"
                        [hidden]="!('icr_verification_status' | inArray:filters)">
                        <label class="center-block">Filter by ICR status</label>
                        <app-multi-select [options]="statuses['icr']" [placeholder]="'Select status'"
                            [controlName]="'icr_verification_status'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>
                    <!--Filter by Address status for submit to icr page-->

                    <div *ngIf="statuses" class="col-md-2 form-group"
                        [hidden]="!('address_verification_status' | inArray:filters)">
                        <label class="center-block">Filter by Address status</label>
                        <app-multi-select [options]="statuses['address']" [placeholder]="'Select status'"
                            [controlName]="'address_verification_status'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('address_verification_status' | inArray:filters)">
                        <label class="center-block">Filter by Address SentToVendor On</label>
                        <input class="form-control" type="date"
                            formControlName="address_verification_sent_to_vendor_after" />
                        <input class="form-control" type="date"
                            formControlName="address_verification_sent_to_vendor_before" />
                    </div>
                    <div *ngIf="states" class="form-group col-md-2" [hidden]="!('state' | inArray:filters)">
                        <label class="center-block"> State</label>
                        <input formControlName="state_name" [typeahead]="states" [typeaheadMinLength]="0"
                            typeaheadOptionField="name" placeholder="Select State"
                            (typeaheadOnSelect)="OnStateSelect($event)" [typeaheadItemTemplate]="customItemTemplate"
                            class="form-control">
                    </div>
                    <div>

                    </div>
                    <div *ngIf="districts" class="form-group col-md-2" [hidden]="!('district' | inArray:filters)">
                        <label class="center-block"> District</label>
                        <input formControlName="district_name"
                            [typeahead]="districts | filterByStateId:filterFormGroup.controls['state_id'].value"
                            [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select District"
                            class="form-control" [typeaheadItemTemplate]="customItemTemplate"
                            (typeaheadOnSelect)="OnDistrictSelect($event)">
                    </div>
                    <div *ngIf="methodology" class="form-group col-md-2" [hidden]="!('methodology' | inArray:filters)">
                        <label class="center-block"> Method</label>
                        <input formControlName="method_name" [typeahead]="methodology" [typeaheadMinLength]="0"
                            typeaheadOptionField="name" placeholder="Select Method" class="form-control"
                            [typeaheadItemTemplate]="customItemTemplate"
                            (typeaheadOnSelect)="OnMethodologySelect($event)">
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('pincode' | inArray:filters)">
                        <label class="center-block"> Pincode</label>
                        <input class="form-control" formControlName="pincode" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('type' | inArray:filters)">
                        <label class="center-block">Filter by type</label>
                        <select class="form-control" formControlName="type">
                            <option *ngFor="let type of types" [value]="type.id">
                                {{ type.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('type_of_document' | inArray:filters)">
                        <label class="center-block">Document Type</label>
                        <select class="form-control" formControlName="type_of_document">
                            <option *ngFor="let documentType of documentTypes" [value]="documentType.id">
                                {{documentType.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 form-group"
                        [hidden]="!('created_after' | inArray:filters) && !('created_before' | inArray:filters)">
                        <label class="center-block"> Creation Date</label>
                        <input class="form-control" type="date" formControlName="created_after"
                            *ngIf="('created_after' | inArray:filters)" />
                        <input class="form-control" type="date" formControlName="created_before"
                            *ngIf="('created_before' | inArray:filters)" />
                    </div>
                    <div class="col-md-2 form-group"
                        [hidden]="!('completion_date_from' | inArray:filters) && !('completion_date_to' | inArray:filters)">
                        <label class="center-block"> Verification Date</label>
                        <input class="form-control" type="date" formControlName="completion_date_from"
                            *ngIf="('completion_date_from' | inArray:filters)" />
                        <input class="form-control" type="date" formControlName="completion_date_to"
                            *ngIf="('completion_date_to' | inArray:filters)" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('submission_done' | inArray:filters)">
                        <label class="center-block">Submission Done</label>
                        <select class="form-control" formControlName="submission_done">
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group"
                        [hidden]="!('submitted_after' | inArray:filters) && !('submitted_before' | inArray:filters)">
                        <label class="center-block"> Submission Date</label>
                        <input class="form-control" type="date" formControlName="submitted_after"
                            *ngIf="('submitted_after' | inArray:filters)" />
                        <input class="form-control" type="date" formControlName="submitted_before"
                            *ngIf="('submitted_before' | inArray:filters)" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('email_sent_at' | inArray:filters)">
                        <label class="center-block"> Email Sent Date</label>
                        <input class="form-control" type="date" formControlName="email_sent_after" />
                        <input class="form-control" type="date" formControlName="email_sent_before" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('initiated_at' | inArray:filters)">
                        <label class="center-block"> CE Initiation Date </label>
                        <input class="form-control" type="date" formControlName="initiated_after" />
                        <input class="form-control" type="date" formControlName="initiated_before" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('sent_to_vendor' | inArray:filters)">
                        <label class="center-block"> Sent To Vendor Date</label>
                        <input class="form-control" type="date" formControlName="sent_to_vendor_after" />
                        <input class="form-control" type="date" formControlName="sent_to_vendor_before" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('time' | inArray:filters)">
                        <label class="control-label">Time</label>
                        <input class="form-control" type="time" formControlName="created_time_after">
                        <input class="form-control" type="time" formControlName="created_time_before">
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('india_post_id' | inArray:filters)">
                        <label class="center-block">Filter by India Post Id</label>
                        <select class="form-control" formControlName="india_post_id">
                            <option [value]="0">Blank</option>
                            <option [value]="1">Present</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('assigned_to' | inArray:filters)">
                        <label class="center-block">Assigned To</label>
                        <select class="form-control" formControlName="assigned_to">
                            <option [value]="0">None</option>
                            <option *ngFor="let user of users" [value]="user.id">
                                {{user.name}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('proof_url' | inArray:filters)">
                        <label class="center-block">Proof Present</label>
                        <select class="form-control" formControlName="proof_url">
                            <option [value]="0">No</option>
                            <option [value]="1">Yes</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('type_of_doc' | inArray:filters)">
                        <label class="center-block">Doc Type</label>
                        <select class="form-control" formControlName="type_of_doc">
                            <option *ngFor="let doc of identityTypes" [value]="doc.id">
                                {{doc.name}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('employment_company' | inArray:filters)">
                        <label class="center-block">Employment Company</label>
                        <input formControlName="employment_company_name" [typeahead]="employmentCompanies"
                            [typeaheadMinLength]="0" typeaheadOptionField="name" placeholder="Select Company"
                            [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                            (typeaheadOnSelect)="OnEmploymentCompanySelect($event)">
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('institute' | inArray:filters)">
                        <label class="center-block">Institute</label>
                        <input formControlName="institute_name" [typeahead]="institutes" [typeaheadMinLength]="0"
                            typeaheadOptionField="name" placeholder="Select Institute"
                            [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                            (typeaheadOnSelect)="OnInstituteSelect($event)">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-2 form-group" [hidden]="!('employee_name' | inArray:filters)">
                        <label class="center-block"> Employee Name</label>
                        <input class="form-control" formControlName="employee_name" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('type_of_address' | inArray:filters)">
                        <label class="center-block">Filter by Address Type</label>
                        <select class="form-control" formControlName="type_of_address">
                            <option [value]="1">Current</option>
                            <option [value]="2">Permanent</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('reference_type' | inArray:filters)">
                        <label class="center-block"> Reference Type</label>
                        <select class="form-control" formControlName="reference_type">
                            <option [value]="0">Phone</option>
                            <option [value]="1">Physical</option>
                            <option [value]="2">Employment</option>
                            <option [value]="3">Tenant</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('employee_id' | inArray:filters)">
                        <label class="center-block"> Candidate ID</label>
                        <input class="form-control" formControlName="employee_id" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('track_id' | inArray:filters)">
                        <label class="center-block"> Track ID</label>
                        <input class="form-control" formControlName="track_id" />
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('email' | inArray:filters)">
                        <label class="center-block"> Employee Email</label>
                        <input class="form-control" formControlName="email" />
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('attachments' | inArray:filters)">
                        <label class="center-block">Attachments Present</label>
                        <select class="form-control" formControlName="attachments">
                            <option [value]="0">No</option>
                            <option [value]="1">Yes</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('cnr_details' | inArray:filters)">
                        <label class="center-block">CNR Details</label>
                        <select class="form-control" formControlName="cnr_details">
                            <option value=all>All</option>
                            <option [value]="1">Found</option>
                            <option [value]="0">Not Found</option>
                            <option [value]="2">Not Fetched</option>
                            <option [value]="3">Error</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('dov_present' | inArray:filters)">
                        <label class="center-block">Filter By DOV Present</label>
                        <select class="form-control" formControlName="dov_present">
                            <option [value]="0">Missing</option>
                            <option [value]="1">Present</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('sent_to_vendor_date_present' | inArray:filters)">
                        <label class="center-block">Sent to Vendor Date Present</label>
                        <select class="form-control" formControlName="sent_to_vendor_date_present">
                            <option [value]="0">Missing</option>
                            <option [value]="1">Present</option>
                        </select>
                    </div>
                    <div class="col-md-2 form-group" [hidden]="!('applied_date_present' | inArray:filters)">
                        <label class="center-block">Filter By Applied at Present</label>
                        <select class="form-control" formControlName="applied_date_present">
                            <option [value]="0">Missing</option>
                            <option [value]="1">Present</option>
                        </select>
                    </div>


                    <div class="col-md-2 form-group" [hidden]="!('applied_at' | inArray:filters)">
                        <label class="center-block"> Applied At Date</label>
                        <input class="form-control" type="date" formControlName="applied_at_after" />
                        <input class="form-control" type="date" formControlName="applied_at_before" />
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('work_completed' | inArray:filters)">
                        <label class="center-block">Work Completed</label>
                        <select class="form-control" formControlName="work_completed">
                            <option [value]="0">No</option>
                            <option [value]="1">Yes</option>

                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('initiated_present' | inArray:filters)">
                        <label class="center-block"> CE Initiation Date Present</label>
                        <select class="form-control" formControlName="initiated_present">
                            <option [value]="0">No</option>
                            <option [value]="1">Yes</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('work_assignment' | inArray:filters)">
                        <label class="center-block"> Work Assignment Date</label>
                        <input class="form-control" type="date" formControlName="assigned_after" />
                        <input class="form-control" type="date" formControlName="assigned_before" />
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('vendor' | inArray:filters)">
                        <label class="center-block">Vendor</label>
                        <app-multi-select [options]="vendors" [placeholder]="'Select Vendors'"
                            [controlName]="'vendor_id'" [formGName]="filterFormGroup"></app-multi-select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('fir_judgement_order' | inArray:filters)">
                        <label class="center-block">Judgements/FIR/Orders Present</label>
                        <select class="form-control" formControlName="fir_judgement_order">
                            <option [value]="0">No</option>
                            <option [value]="1">Yes</option>
                        </select>
                    </div>
                </div>
                <!-- Button Row -->
                <div class="row">

                    <div class="col-md-2 form-group" [hidden]="!('qa_present' | inArray:filters)">
                        <label class="center-block">QA Present</label>
                        <select class="form-control" formControlName="qa_present">
                            <option value="all">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <small class="text-danger" *ngIf="filterFormGroup.controls['qa_present'].errors">
                            Required
                        </small>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('qa_required' | inArray:filters)">
                        <label class="center-block">QA Required</label>
                        <select class="form-control" formControlName="qa_required">
                            <option value="all">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                        <small class="text-danger" *ngIf="filterFormGroup.controls['qa_required'].errors">
                            Required
                        </small>
                    </div>


                    <div class="col-md-2 form-group" [hidden]="!('qa_done' | inArray:filters)">
                        <label class="center-block">QA Done</label>
                        <select class="form-control" formControlName="qa_done">
                            <option value="all">All</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>
                    </div>

                    <div *ngIf="workTypes" class="col-md-2 form-group" [hidden]="!('work_types' | inArray:filters)">
                        <label class="center-block">Filter by Work Type</label>
                        <select class="form-control" formControlName="work_type">
                            <option *ngFor="let option of workTypes[verification]" [value]="option.id">
                                {{option.name}}</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('assigned' | inArray:filters)">
                        <label class="control-label">Assigned Before</label>
                        <input class="form-control" type="time" formControlName="assigned_before">
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('assigned' | inArray:filters)">
                        <label class="control-label">Assignment Count</label>
                        <input class="form-control" type="number" formControlName="assignment_count" value="3">
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('consent_present' | inArray:filters)">
                        <label class="center-block">Consent Present</label>
                        <select class="form-control" formControlName="consent_present">
                            <option [value]="0">No</option>
                            <option [value]="1">Yes</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('has_contact' | inArray:filters)">
                        <label class="center-block">Has Contact</label>
                        <select class="form-control" formControlName="has_contact">
                            <option [value]="0">No</option>
                            <option [value]="1">Yes</option>
                        </select>
                    </div>

                    <div class="col-md-2 form-group" [hidden]="!('verification_mode' | inArray:filters)">
                        <label class="center-block">Mode Of Verification</label>
                        <select class="form-control" formControlName="verification_mode">
                            <option [value]="0">Physical</option>
                            <option [value]="1">Digital</option>
                            <option [value]="2">Postal</option>
                        </select>
                    </div>

                </div>

                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
