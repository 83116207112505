import { Injectable } from '@angular/core';


import { Verification } from '../models/verification.model';
import { ApiService } from './api.service';
import { Employee } from '../models/employee.model';
import { verificationTypes } from '../enums/verification-types.enum';
import { emit } from 'process';

export const VERIFICATIONS: Verification[] = [
    {
        title: 'Identity Verification',
        name: 'Identity Verification',
        verificationRelation: 'identity_verifications',
        shortTitle: 'identity',
        lotColumnName: 'identity_check',
        id: 'identity_check',

    },
    {
        title: 'Current Address Verification',
        name: 'Current Address Verification',
        verificationRelation: 'current_address_verifications',
        shortTitle: 'current_address',
        lotColumnName: 'current_address_check',
        id: 'current_address_check',
    },
    {
        title: 'Permanent Address Verification',
        name: 'Permanent Address Verification',
        verificationRelation: 'permanent_address_verifications',
        shortTitle: 'permanent_address',
        lotColumnName: 'permanent_address_check',
        id: 'permanent_address_check',
    },
    {
        title: 'ICR Verification',
        name: 'ICR Verification',
        verificationRelation: 'icr_verifications',
        shortTitle: 'icr',
        lotColumnName: 'court_record_check',
        id: 'court_record_check',

    },
    {
        title: 'Reference Verification',
        name: 'Reference Verification',
        verificationRelation: 'reference_verifications',
        shortTitle: 'reference',
        lotColumnName: 'reference_verification',
        id: 'reference_verification',
    },
    {
        title: 'Education Verification',
        name: 'Education Verification',
        verificationRelation: 'education_verifications',
        shortTitle: 'education',
        lotColumnName: 'education_check',
        id: 'education_check',
    },
    {
        title: 'Employment Verification',
        name: 'Employment Verification',
        verificationRelation: 'employment_verifications',
        shortTitle: 'employment',
        lotColumnName: 'employment_check',
        id: 'employment_check',
    },
    {
        title: 'PCC Verification',
        name: 'PCC Verification',
        verificationRelation: 'pcc_verifications',
        shortTitle: 'pcc',
        lotColumnName: 'police_check',
        id: 'police_check',
    },
    {
        title: 'Document Verification',
        name: 'Document Verification',
        verificationRelation: 'document_verifications',
        shortTitle: 'document',
        lotColumnName: 'document_check',
        id: 'document_check',

    },
    {
        title: 'Global DB Verification',
        name: 'Global DB Verification',
        verificationRelation: 'global_db_verifications',
        shortTitle: 'globaldb',
        lotColumnName: 'global_db_verification',
        id: 'global_db_verification',

    },

];


@Injectable( {
    providedIn: 'root'
} )
export class VerificationService {

    verificationTypes = verificationTypes;

    constructor ( private api: ApiService ) {
    }

    getAllVerifications (): Promise<Verification[]> {
        return Promise.resolve( VERIFICATIONS );
    }


    createVendor ( values: Object ) {
        return this.api.post( 'common/add-vendor', values );
    }

    createDistrict ( values: Object ) {
        return this.api.post( 'common/add-district', values );
    }

    getAllVendors () {
        return this.api.get( 'common/get-all-vendors' );
    }

    getVendorById ( vendorId: string | number ) {
        return this.api.get( 'common/get-vendor/' + vendorId );
    }

    updateVendor ( values: Object | undefined ) {
        return this.api.post( 'common/update-vendor', values );
    }
    getAllVendorNames () {
        return this.api.get( 'common/get-all-vendor-names' );
    }

    createClientRemark ( values: object ) {
        return this.api.post( 'common/add-verification-client-remark', values );
    }

    getEmployeeVerificationLabels ( employee: Employee ): { verificationId: string, verificationLabel: string }[] {

        return employee?.verifications.map( ( verification: any ) => {

            return {
                'verificationId': verification,
                'verificationLabel': verificationTypes[ verification as keyof typeof verificationTypes ],
            }

        } );

    }

}
