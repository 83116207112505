import { Injectable } from '@angular/core';

declare const swal: any;

@Injectable( {
    providedIn: 'root',
} )
export class DialogService {

    constructor () { }

    /**
     * Example usage
     *
     * this.dialogService.confirm().then(
     * () => {
     *    handle user
     * },
     * () => {
     *    handle no
     * })
     *
     * @param title
     * @param text
     */
    public confirm ( title = 'Are you sure', text = 'Are you sure' ) {

        return swal( {
            title: title,
            text: text,
            type: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        } );
    }

}
