import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class BlacklistedService {
    constructor ( private api: ApiService ) {
    }

    filter ( params: Params | undefined ) {
        return this.api.get( 'blacklisted', params );
    }

    resend ( data: Object | undefined ) {
        return this.api.post( 'blacklisted/resend', data );
    }

    resendAll ( source: string, params: Params | undefined ) {
        return this.api.get( 'blacklisted/resendAll?type=' + source, params );
    }

    getDataForExcel ( params: Params | undefined ) {
        return this.api.get( 'blacklisted/download', params );
    }

    getStats ( params: Params | undefined ) {
        return this.api.get( 'blacklisted/stats', params );
    }

    getDetails ( rcNumber: string ) {
        return this.api.get( 'blacklisted/details?rc_number=' + rcNumber );
    }

    getApiResponse ( id: string ) {
        return this.api.get( 'blacklisted/' + id );
    }

    search ( data: Object | undefined ) {
        return this.api.post( 'blacklisted/search', data );
    }

    importExcel ( data: Object | undefined ) {
        return this.api.post( 'blacklisted/import', data );
    }
}
