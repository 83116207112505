import { Component, Input, OnInit } from '@angular/core';
import { Employee } from 'src/app/core/models/employee.model';
import { environment } from '../../../environments/environment';

@Component( {
    selector: 'app-employee-file-list',
    templateUrl: './employee-file-list.component.html',
    styleUrls: [ './employee-file-list.component.css' ]
} )
export class EmployeeFileListComponent implements OnInit {

    @Input() employeeDocuments: any;
    @Input() employee!: Employee;


    constructor () { }

    ngOnInit () {

    }

    formatType ( type: string ): string {

        return type.replace( /_/g, ' ' );
    }

    get documentLink (): string {
        return environment.api_url + 'employees/' + this.employee?.id + '/document/zip-download';
    }

}
