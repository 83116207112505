<div bsModal #identityValidationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">

        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Identity Validation</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body text-justify">
                <div class="row">

                    <div class="col-md-4">
                        <div class="row">
                            <div *ngIf="verification?.front_url" class="col-md-12 identity-edit-image-frame"
                                style="max-height: 360px;">
                                <img imageZoom style="width:100%; height: 45%" [src]="verification.front_url" />
                            </div>
                            <div *ngIf="verification?.back_url" class="col-md-12 identity-edit-image-frame"
                                style="max-height: 360px;">
                                <img imageZoom style="width:100%; height: 45%" [src]="verification.back_url" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <img class="img-responsive" src="{{verification.proof_url}}" alt="">
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>