import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

export function validateVendorName ( vendors: any[] ) {

    vendors = vendors.map( ( v: string ) => v.toLowerCase() );

    return ( control: AbstractControl ): { [ key: string ]: any } => {

        if ( vendors.indexOf( control.value.toLowerCase() ) == -1 ) {
            return {};
        }


        return { 'validateVendorName': { value: control.value } };
    };
}

@Directive( {
    selector: '[uniqueVendorNameValidator]',
    providers: [ { provide: NG_VALIDATORS, useExisting: UniqueVendorNameValidatorDirective, multi: true } ],
} )
export class UniqueVendorNameValidatorDirective {

    @Input()
    vendors!: any[];

    validate ( control: AbstractControl ): { [ key: string ]: any } {

        return validateVendorName( this.vendors )( control );
    }

}
