import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../../core/services/common.service';
import { HistoryService } from '../../core/services/history.service';


@Component( {
    selector: 'app-history-show',
    templateUrl: './history-show.component.html',
    styleUrls: [ './history-show.component.css' ],
} )
export class HistoryShowComponent implements OnInit {

    id!: number;
    employee: any;
    path!: string;
    flag: boolean = false;

    constructor (
        private activatedRoute: ActivatedRoute,
        private historyService: HistoryService,
        public common: CommonService,
        // public global: SimpleGlobal
    ) {
        this.activatedRoute.params.subscribe( ( params: any ) => this.id = params.id );
    }

    ngOnInit (): void {
        this.flag = false;
        this.getEmployeeHistory();
    }

    getEmployeeHistory (): void {
        this.path = 'employees/' + this.id + '/history';
        this.historyService.getHistory( this.path ).subscribe( ( response: any ) => {
            this.employee = response;
            this.flag = true;
        } );
    }

    isArray ( value: any ) {
        return Array.isArray( value )
    }


}
