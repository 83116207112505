import { Component, OnInit, ViewChild, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { booleanValues, categories, natureOfCases } from '../../core/models/common.model';

import { IcrCase } from 'src/app/core/models/icr-case.model';
import { CommonService } from 'src/app/core/services/common.service';
import { IcrCaseService } from 'src/app/core/services/icr-case.service';
import { IcrService } from 'src/app/core/services/icr.service';
import { GlobalService } from 'src/app/core/services/global.service';


@Component( {
    selector: 'app-cases-create',
    templateUrl: './cases-create.component.html',
    styleUrls: [ './cases-create.component.css' ],
} )
export class CasesCreateComponent implements OnInit, OnChanges {

    @Output() created = new EventEmitter<IcrCase>();

    createForm!: FormGroup;
    categories = categories;
    natureOfCases = natureOfCases;
    booleanValues = booleanValues;
    acts!: any[];
    sections: any[] = [];
    allSections: any[] = [];
    verificationId!: number;

    @ViewChild( 'createCaseModal', { static: false } )
    public createModal!: ModalDirective;

    constructor (
        private fb: FormBuilder,
        public global: GlobalService,
        private verificationService: IcrService,
        private common: CommonService,
        private icrCaseService: IcrCaseService
    ) {
        this.initForm();
    }

    get statuses () { return this.global.fetchStatuses(); }

    ngOnInit (): void {

    }

    initForm () {

        this.createForm = this.fb.group( {
            case_status: [ '' ],
            type: [ '' ],
            filling_number: [ '' ],
            filling_year: [ '' ],
            registration_year: [ '' ],
            registration_number: [ '' ],
            cnr_number: [ '' ],
            stage: [ '' ],
            police_station: [ '' ],
            fir_number: [ '' ],
            fir_year: [ '' ],
            nature_of_disposal: [ '' ],
            district_court: [ '', Validators.required ],
            candidate_category: [ '', Validators.required ],
            fir_available: [ '' ],
            judgement_available: [ '' ],
            cnr_details_found: [ '' ],
            act_ids: [ [], Validators.required ],
            section_ids: [ [] ],
            section_names: [ [] ],
            nature_of_case: [ '', Validators.required ],

            court_search_result_id: [ '' ],
            risk_level: [ '', Validators.required ],
            status_id: [ '', Validators.required ],
            client_remarks: [ '' ],

        } );
    }

    ngOnChanges (): void {
        this.createForm.get( 'sections' )?.valueChanges.subscribe( statusId => {
            const sectionNames = this.getSelectedSections( statusId );

            this.createForm.get( 'section_names' )?.setValue( sectionNames );
        } );


    }

    create (): void {

        const params = {
            ...this.createForm.value,
            icr_verification_id: this.verificationId,
        };

        this.icrCaseService.createCase( params ).subscribe( response => {

            this.common.notifications.success( 'Case Created' );

            this.createModal.hide();

            this.created.emit( response );

        } );
    }

    hide (): void { this.createModal.hide(); }

    show ( verificationId: number, acts: any, sections: any, allSections: any ): void {

        this.initForm();

        this.verificationId = verificationId;

        this.acts = acts;

        this.sections = sections;

        this.allSections = allSections;

        this.createModal.show();

    }

    getSelectedSections ( statusId: any ): string[] {
        const names: any[] = [];

        statusId.forEach( ( value: any ) => {
            this.allSections.filter( item => {
                if ( item.id == value ) {
                    names.push( item.name );
                }
            } );
        } );

        return names;
    }

}
