<div class="wrapper-md">
    <div class="row">
        <div class="col-sm-3" *ngIf="stats">
            <div class="panel panel-default">
                <div class="panel-heading">Epfo Stats</div>
                <div class="panel-body">
                    <p class="mar-no" *ngFor="let data of stats">
                        <span *ngIf="data.count">
                            <span *ngIf="data.status!=null ;else nonTemplate">
                                {{data.status}}
                            </span>
                            <span class="pull-right text-bold"> {{ data.count }}</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #nonTemplate>
    NONE
</ng-template>