import { SmsLogStatusTimeLineComponent } from './../common/sms-log-status-time-line/sms-log-status-time-line.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { NgxPaginationModule } from 'ngx-pagination';
import { SmsService } from '../core/services/sms.service';
import { SharedModule } from '../core/shared.module';
import { SmsFiltersComponent } from './sms-filters/sms-filters.component';
import { SmsListComponent } from './sms-list/sms-list.component';
import { SmsStatsComponent } from './sms-stats/sms-stats.component';


@NgModule( {
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        SmsListComponent,
        SmsStatsComponent,
        SmsFiltersComponent,
        SmsLogStatusTimeLineComponent
    ],
    providers: [ SmsService ],
} )
export class SmsModule {
}
