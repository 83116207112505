import { CompanyRole } from './company-role.model';

export class User {
    id!: number;
    email!: string;
    token!: string;
    name!: string;
    level_id!: UserLevel;
    ip!: string;
    designation!: string;
    mobile_number!: number;
    level_name!: string;
    dob!: string;
    status!: number | null;
    created_at!: any;
    updated_at!: any;
    roles: CompanyRole[] = [];
    active?: number | null;
}

export enum UserLevel {
    Default = 1,
    Editor = 2,
    Moderator = 3,
    Admin = 4,
    SuperAdmin = 5,
}
