<div bsModal #roleEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="roleEditForm" (ngSubmit)="updateRole()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Role</h4>
                </div>
                <div class="panel-body">

                    <fieldset [disabled]="isSubmitting">

                        <fieldset class="form-group">
                            <label class="center-block"><b>Role Name</b></label>
                            <input formControlName="name" class="form-control form-control-lg" placeholder="Role Name"
                                id="role" />
                        </fieldset>

                        <small class="text-danger" *ngIf="name?.errors?.['required']">
                            Role Name is required
                        </small>

                        <small class="text-danger" *ngIf="name?.errors?.['pattern']">
                            Numbers or Special Characters is not allowed
                        </small>

                    </fieldset>

                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" id="update_button" [disabled]="!roleEditForm.valid">
                            Update
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>