import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Clients } from '../../core/models/client-api-credentials';
import { Errors } from '../../core/models/errors.module';
import { ClientApiService } from 'src/app/core/services/client-api.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component( {
    selector: 'app-client-api-credentials',
    templateUrl: './client-api-credentials.component.html',
    styleUrls: [ './client-api-credentials.component.css' ],
} )

export class ClientApiCredentialsComponent {

    @ViewChild( 'clientsApiCredentials', { static: false } )
    public clientsApiCredentials!: ModalDirective;

    submitted = false;
    errors: Errors = new Errors();
    clients: Clients[] = [];
    company_id!: number;
    client_api_id!: number;
    sendCredentialFormGroup!: FormGroup;

    constructor ( private route: ActivatedRoute,
        private fb: FormBuilder,
        protected commonService: CommonService,
        private clientApiService: ClientApiService,
    ) { }

    ngOnInit () {
        this.initForm();
    }

    initForm (): void {
        this.sendCredentialFormGroup = this.fb.group( {
            clients: [],
            environment: [ '', Validators.required ],
        } );
    }

    show ( data: any ) {
        this.initForm();
        this.submitted = false;
        this.company_id = data.owner_id;
        this.client_api_id = data.id;
        this.getClients( this.company_id );
        this.clientsApiCredentials.show();
    }

    hide () {
        this.clientsApiCredentials.hide();
    }

    submitForm (): void {
        this.submitted = true;
        const params = {
            client_ids: this.sendCredentialFormGroup.value.clients,
            environment: this.sendCredentialFormGroup.value.environment,
        };
        this.sendEmails( params, this.client_api_id, this.company_id );
    }

    getClients ( companyId: number ): void {
        this.clientApiService.getClients( companyId )
            .subscribe( ( client: Clients[] ) => {
                this.clients = client;
            } );
    }

    sendEmails ( params: object, clientApiId: number, companyId: number ): void {
        this.clientApiService.sendEmails( params, clientApiId, companyId )
            .subscribe( ( status: any ) => {
                if ( status.success === true ) {
                    this.submitted = false;
                    this.initForm();
                    this.hide();
                    this.commonService.notifications.success( 'Success', status[ 'message' ] );
                }
            },
                error => {
                    this.submitted = false;
                    this.commonService.displayValidationErrors( error );
                    this.commonService.notifications.error( 'Error', 'Unable to send Email mail' );
                } );
    }
}
