import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { URLSearchParams } from '@angular/http';
import { Params } from '@angular/router';

import { Subscription } from 'rxjs';
import { Pagination } from '../../core/models/pagination.model';
import { CriminalService } from '../../core/services/criminal.service';
import { FilterService } from './../../core/services/filter.service';

@Component( {
    selector: 'app-criminal-search',
    templateUrl: './criminal-search.component.html',
    styleUrls: [ './criminal-search.component.css' ],
} )
export class CriminalSearchComponent implements OnInit, OnDestroy {

    loading!: boolean;
    pagination: Pagination = new Pagination();

    filterParams: URLSearchParams = new URLSearchParams();
    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    filtersSubscription!: Subscription;

    constructor (
        private criminalService: CriminalService,
        private fb: FormBuilder,

        private filterService: FilterService
    ) {
        this.initForm();

        this.filterService.updateFormFilters( this.filterFormGroup );

        this.filterResults( this.filterFormGroup.value );
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            page: '1',
            name: '',
            address: '',
            state: '',
            district: '',
        } );
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );
        } );
    }

    filterResults ( params: Params ): void {

        this.criminalService.filter( params ).subscribe( response => {
            this.pagination = response;
        } );
    }

    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {

        this.initForm();
        this.filterQueriedResults();
    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }
}
