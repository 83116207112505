import { LicenceService } from './../../core/services/licence.service';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { DownloadExcelInitialState } from 'src/app/core/models/download-excel-initial-state.model';

@Component( {
    selector: 'app-licence-list',
    templateUrl: './licence-list.component.html',
    styleUrls: [ './licence-list.component.css' ],
} )
export class LicenceListComponent extends ExportExcelComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress = false;
    filterFormGroup!: FormGroup;
    clients!: any[];

    constructor (
        protected service: LicenceService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected fb: FormBuilder,
        protected commonService: CommonService,
        protected viewContainerRef: ViewContainerRef
    ) {
        super();
    }

    ngOnInit () {

        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } )
    }

    filterResults (): void {
        if ( this.filterParams.keys().length ) {
            this.service.filter( this.filterParams ).subscribe( response => {
                this.pagination = response;
            } );
        }
    }

    showPage ( page: any ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    resendAll ( source: any ) {

        this.service.resendAll( source, this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }

    onSearch () {
        this.filterResults();
    }

    exportLogsExcel () {

        const initialState: DownloadExcelInitialState = {
            filterParams: this.filterParams,
            paramsString: this.filterParams.toString(),
            route: 'licence/export-excel',
            fileName: 'Licence',
            method: 'get',
            postParams: null,
        };

        this.service.showDownloadModal( initialState );

    }
}
