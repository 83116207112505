import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Errors } from '../../core/models/errors.module';
import { IcrService } from '../../core/services/icr.service';

@Component( {
    selector: 'app-icr-create-act-type',
    templateUrl: './icr-create-act-type.component.html',
    styleUrls: [ './icr-create-act-type.component.css' ],
    providers: [ IcrService ],
} )
export class IcrCreateActTypeComponent implements OnInit {

    errors: Errors = new Errors();
    isSubmitting: boolean = false;
    createForm: FormGroup;

    constructor ( private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private icrService: IcrService ) {
        this.createForm = this.fb.group( {
            name: [ '', Validators.required ],
            description: [ '' ],
        } );

    }


    ngOnInit (): void {

    }

    submitForm (): void {
        this.isSubmitting = true;
        this.errors = new Errors();
        const data: string = this.createForm.value;
        this.icrService
            .createAct( data )
            .subscribe(
                data => this.router.navigateByUrl( '/icr/create/act' ),
                err => {
                    this.errors = err;
                    this.isSubmitting = false;
                }
            );

    }

}
