import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MetaField } from '../models/company.model';
import { ApiService } from './api.service';

@Injectable( {
    providedIn: 'root',
} )
export class MetafieldService {

    constructor ( private api: ApiService ) { }

    getUniqueMetafields (): Observable<MetaField[]> {
        return this.api.get( 'metafields' );
    }

    getCompanyMetafields ( companyId: number ): Observable<MetaField[]> {
        return this.api.get( `companies/${ companyId }/metafields` );
    }

    addField ( companyId: number, name: string ): Observable<MetaField> {
        return this.api.post( `companies/${ companyId }/metafields`, { name } );
    }

    updateField ( companyId: number, metafieldId: number, name: string ): Observable<MetaField> {
        return this.api.post( `companies/${ companyId }/metafields/${ metafieldId }`, { name } );
    }

    deleteField ( companyId: number, metafieldId: number ): Observable<any> {
        return this.api.delete( `companies/${ companyId }/metafields/${ metafieldId }` );
    }
}
