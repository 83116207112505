import { Injectable } from '@angular/core';

declare let gtag: Function; // Declare ga as a function

@Injectable( {
    providedIn: 'root'
} )
export class GoogleAnalyticsService {

    constructor () { }


    // create our event emitter to send our data to Google Analytics
    public eventEmitter ( eventCategory: string,
        eventAction: string,
        eventLabel: string | null = null,
        eventValue: number | null = null ) {
        gtag( 'send', 'event', {
            eventCategory: eventCategory,
            eventLabel: eventLabel,
            eventAction: eventAction,
            eventValue: eventValue,
        } );

    }

}
