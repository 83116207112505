import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';

@Injectable( {
    providedIn: 'root'
} )
export class WorkService {
    constructor ( private api: ApiService ) { }

    types ( verification: string ): Observable<any[]> {
        return this.api.get( 'users/work/' + verification + '/types' );
    }

    assign ( data: {} ): Observable<any[]> {
        return this.api.post( 'users/work/assign', data );
    }

    filter ( params: Params | HttpParams | undefined ): Observable<Pagination> {
        return this.api.get( 'users/work', params );
    }

    stats ( params: Params | HttpParams | undefined, user_id: string | number ): Observable<any[]> {
        return this.api.get( 'users/stats' + '?user_id=' + user_id, params );
    }

    userHistory ( params: Params | HttpParams | undefined, user_id: string | number ): Observable<Pagination> {
        return this.api.get( 'users/history' + '?user_id=' + user_id, params );
    }

    getDataForExcel ( params: Params | HttpParams | undefined, user_id: string | number ): Observable<any[]> {
        return this.api.get( 'users/export-excel' + '?user_id=' + user_id, params );
    }

    bulkAssignExcel ( data: {} ): Observable<any[]> {
        return this.api.post( 'work/bulk-assign', data );
    }

    allTypes () {
        return this.api.get( 'work/types' );
    }

    getAllWorkTypeList (): Observable<any> {
        return this.api.get( 'work/work-type/list' );
    }

    getWorkType (): Observable<any> {
        return this.api.get( 'work/work-type' );
    }

    getVerificationStatuses ( param: any ): Observable<any> {
        return this.api.get( param + '/stats' );
    }

    createWorkType ( params: any ): Observable<any> {
        return this.api.post( 'work/work-type', params );
    }

    updateWorkType ( params: any, work_type_id: number ): Observable<any> {
        return this.api.put( 'work/work-type/' + work_type_id, params );
    }

    markWorkAsCompleted ( params: Params ): Observable<any> {
        return this.api.post( 'users/work/mark-completed', params );
    }
}
