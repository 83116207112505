import { Component, OnInit } from '@angular/core';
import { WebhookService } from '../../core/services/webhook.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Pagination } from '../../core/models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-incoming-webhook-logs-list',
    templateUrl: './incoming-webhook-logs-list.component.html',
    styleUrls: [ './incoming-webhook-logs-list.component.css' ],
} )
export class IncomingWebhookLogsListComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    filterFormGroup!: FormGroup;
    loading!: boolean;
    clients: any;

    constructor (
        private webhookService: WebhookService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private commonService: CommonService
    ) { }

    ngOnInit () {

        const params = new HttpParams();
        params.set( 'type', 'vendor' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
        this.initForm();
        this.subscribeToQueryChanges();
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            created_after: 'all',
            created_before: 'all',
            track_id: 'all',
            vendor_id: 'all',
            page: '',
        } );
    }

    subscribeToQueryChanges (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    filterResults (): void {

        // Get the employeeId from the url and fetch details against it
        this.route.params.subscribe( params => {

            this.webhookService.incominglogs( this.filterParams ).subscribe( response => {
                this.pagination = response;
            } );

        } );


    }

    filterQueriedResults (): void {

        this.filterParams = new HttpParams();

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };


        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );

    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    showPage ( $event: any ) {
        this.filterFormGroup.get( 'page' )?.setValue( $event );
        this.filterQueriedResults();
    }
}
