<div class="wrapper-md">
    <div class="panel panel-default">

        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">

            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterResults()"
                (keydown.enter)=" $event.preventDefault()">

                <div class="row">

                    <div class="col-md-2 form-group">
                        <label class="center-block"> Track ID</label>
                        <input class="form-control" formControlName="track_id" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">User</label>
                        <select class="form-control" formControlName="user_id">
                            <option *ngFor="let user of users" [value]="user.id">
                                {{user.name}}
                            </option>
                        </select>
                    </div>


                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation After</label>
                        <input class="form-control" type="date" formControlName="created_after" />
                    </div>

                    <div class="col-md-2 form-group">
                        <label class="center-block">Creation Before</label>
                        <input class="form-control" type="date" formControlName="created_before" />
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button type="submit" class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>


                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="generateExcel()" class="btn btn-primary btn-block">
                            Download Excel
                        </button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>