import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';

import { validateEmail } from 'src/app/common/validators/email-validator.directive';
import { validateOnlySpaceNotAllow } from 'src/app/common/validators/only-space-not-allow-validator.directive';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';
import { CommonService } from 'src/app/core/services/common.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { InviteService } from 'src/app/core/services/invite.service';


@Component( {
    selector: 'app-invite-upload',
    templateUrl: './invite-upload.component.html',
} )
export class InviteUploadComponent extends ImportExcelComponent implements OnInit {
    uploadForm!: FormGroup;
    forms!: any[];
    override isSubmitting = false;
    override acceptedFileType = AcceptedFileType.EXCEL;

    constructor (
        protected override fb: FormBuilder,
        private commonService: CommonService,
        public global: GlobalService,
        private inviteService: InviteService,
    ) {
        super( fb );
    }

    ngOnInit (): void {
        this.uploadForm = this.fb.group( {
            company_id: [ null, [ Validators.required ] ],
            form_id: [ '', [ Validators.required ] ],
            form_version: [ null, [ Validators.required ] ],
            message: [ '' ],
            candidates: this.fb.array( [] ),
        } );
    }

    onCompanySelected ( event: any ) {
        this.getFormsList( event.target.value );
    }

    getFormsList ( id: number ) {
        const data = { 'companyId': id };

        this.inviteService.getFormsList( data ).subscribe(
            response => {
                this.forms = response;
            }
        );
    }

    get companyList () {
        return this.global.fetchCompanies();
    }

    get candidates () {
        return this.uploadForm.get( 'candidates' ) as FormArray;
    }
    // file upload field disabled till company,form are invalid
    get isFileDisabled () {
        if ( this.uploadForm.get( 'company_id' )?.valid && this.uploadForm.get( 'form_id' )?.valid ) {
            return false;
        }

        return true;
    }

    addCandidate ( formGroup: AbstractControl<any, any> ) {
        this.candidates.push( this.fb.group( {
            name: [ formGroup.get( 'name' )?.value, [ Validators.required, validateAllowedCharacters( '' ) ] ],
            email: [ formGroup.get( 'email' )?.value, [ Validators.required, validateEmail( true ) ] ],
            phone_number: [ formGroup.get( 'phone_number' )?.value, [ Validators.pattern( /^(\s*\d{10}\s*,\s*)*\s*\d{10}\s*$/ ) ] ],
            client_employee_id: [ formGroup.get( 'client_employee_id' )?.value, [ validateOnlySpaceNotAllow() ] ],
        } ) );
    }

    nameValidate ( i: any ) {
        return this.candidates.controls[ i ].get( 'name' )?.invalid;
    }

    emailValidate ( i: any ) {
        return this.candidates.controls[ i ].get( 'email' )?.invalid;
    }

    mobileValidate ( i: any ) {
        return this.candidates.controls[ i ].get( 'phone_number' )?.invalid;
    }

    clientEmployeeIdValidate ( i: any ) {
        return this.candidates.controls[ i ].get( 'client_employee_id' )?.invalid;
    }

    applyValidations (): void {
        this.candidates.clear();
        this.excelFormArray.controls.forEach( formGroup => {
            this.addCandidate( formGroup );
        } );
    }

    initExcelForm (): void {
        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): void {
    }

    inviteSend () {
        this.isSubmitting = true;
        this.inviteService.sendMail( this.uploadForm.value ).subscribe(
            ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] );
                this.isSubmitting = false;
                // this.onInviteCreate.emit( true );
                // this.hide();

            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to send mail' );
                this.isSubmitting = false;
                // this.hide();
            }
        );

    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
