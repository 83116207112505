<div bsModal #clientsApiEditIp="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Edit Ip Addresses</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <form [formGroup]="editForm" (ngSubmit)="submitForm()" (keydown.enter)="$event.preventDefault()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <div class="input-group">
                                    <label class="center-block"><b>IP</b> (Should be in Ip format. e.g.
                                        192.168.0.1)</label>
                                    <span class="input-group-btn">
                                        <button type="button" class="btn btn-primary btn-block" (click)="addIp()"><i
                                                class="fa fa-plus"></i> Add Row</button>
                                    </span>
                                </div>
                                <br>
                                <div class="input-group" formArrayName="ips"
                                    *ngFor="let ip of ips.controls; let i=index">
                                    <input type="text" [formControlName]="i" class="form-control form-control-lg" />
                                    <span class="input-group-btn">
                                        <button type="button" (click)="deleteRow(i)" class="btn btn-danger"><i
                                                class="fa fa-minus-circle"></i></button>
                                    </span>
                                </div>
                            </fieldset>

                            <button class="btn btn-primary btn-block" [disabled]="!editForm.valid">
                                Update
                            </button>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>