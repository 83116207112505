<div class="row bg-light lter b-b wrapper-md">
    <div class="col-md-8">
        <h1 class="m-n font-thin h3">Ocr Logs</h1>
    </div>

    <div class="col-md-4 text-right text-center-xs">
        <a class="btn btn-primary btn-addon" (click)="filterResults()">
            <i class="fa fa-refresh"></i>
            Refresh
        </a>
    </div>
</div>

<app-loading-component [fetchingInProgress]="fetchingInProgress"></app-loading-component>
<app-ocr-stats></app-ocr-stats>
<app-ocr-filters [filterParams]="filterParams"></app-ocr-filters>
<app-ocr-response-modal #ocrApiResponse></app-ocr-response-modal>
<app-html-response-modal #ocrResponseModal></app-html-response-modal>

<div class="row" style="padding: 0px 0px 0px 35px">
    <a appCan="editor" class="btn btn-primary btn-addon" (click)="exportLogsExcel()" appHide="ocr,download.excel">
        <i class="fa fa-download"></i>
        Download Logs
    </a>

    <a class="btn btn-primary btn-addon" [routerLink]="['import']">
        Import Excel
    </a>


    <a class="btn btn-primary btn-addon" (click)="resendAll('signzy')">
        <i class="fa fa-send"></i>
        Resend Signzy
    </a>

    <a class="btn btn-primary btn-addon" (click)="resendAll('totalkyc')">
        <i class="fa fa-send"></i>
        Resend TotalKyc
    </a>


</div>

<br />

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total
                    {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 10%">Details</th>
                        <th style="width: 20%">Signzy</th>
                        <th style="width: 20%">Total Kyc</th>
                        <th style="width: 10%">Images</th>
                        <th style="width: 15%">Timeline</th>
                        <th style="width: 10%">Api Response</th>
                        <th style="width: 35%">Fields</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="
              let log of pagination.data
                | paginate
                  : {
                      id: 'server',
                      itemsPerPage: pagination.per_page,
                      currentPage: pagination.current_page,
                      totalItems: pagination.total
                    }
            ">
                        <td>
                            <ul class="list-unstyled">
                                <li>{{ log.id }}</li>
                                <ul class="list-unstyled">
                                    <li *ngIf="log.client_name">{{ log.client_name }}</li>
                                </ul>
                            </ul>
                        </td>
                        <td>
                            <div *ngIf="log.signzy == 'success'">
                                <a class="btn btn-block label-success" (click)="ocrResponseModal.show(log.id)">Found</a>
                            </div>

                            <div *ngIf="log.signzy == 'not-found'">
                                <a class="btn btn-block label-danger">Not Found</a>
                            </div>

                            <div *ngIf="log.signzy == 'not-initiated'">
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </div>

                            <div *ngIf="log.signzy == 'failed'">
                                <a class="btn btn-block label-danger">Failure</a>
                            </div>
                        </td>
                        <td>
                            <div *ngIf="log.totalkyc == 'success'">
                                <a class="btn btn-block label-success" (click)="ocrResponseModal.show(log.id)">Found</a>
                            </div>

                            <div *ngIf="log.totalkyc == 'not-found'">
                                <a class="btn btn-block label-danger">Not Found</a>
                            </div>

                            <div *ngIf="log.totalkyc == 'not-initiated'">
                                <a class="btn btn-block label-warning">Not Initiated</a>
                            </div>

                            <div *ngIf="log.totalkyc == 'failed'">
                                <a class="btn btn-block label-danger">Failure</a>
                            </div>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li *ngFor="let image of log.images"> <a target="blank" href="{{image}}">Show Image</a>
                                </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li>Received At:- {{ log.created_at }}</li>
                                <li>Last Updated:- {{ log.updated_at }}</li>
                            </ul>
                        </td>

                        <td>
                            <br>
                            <a class="btn-link blue-text" (click)="ocrApiResponse.show(log.response)">
                                Api Response
                            </a>
                        </td>

                        <td>
                            <ul *ngIf="log.response" class="list-unstyled">
                                <li *ngFor="let key of keys(log.response);">
                                    {{ key }} : {{ log.response.data[key] | json }}
                                </li>
                            </ul>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ hidden: !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>