import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { Employee } from '../models/employee.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination, NewPagination } from '../models/pagination.model';
import { HttpParams } from '@angular/common/http';
import { Attachment } from '../models/attachment.model';
import { ICandidateDocumentResponse } from '../models/candidate-document.modal';
import { IEmployeeDocumentResponse } from '../models/employee-document-attachment.modal';

@Injectable( {
    providedIn: 'root'
} )
export class EmployeeService {

    constructor ( private api: ApiService ) {
    }

    getAll ( params: Params ): Observable<NewPagination<Employee>> {
        return this.api.get( 'employees', params );
    }

    getByLot ( lotId: number, params: Params ): Observable<Pagination> {
        return this.api.get( 'lots/' + lotId + '/employees?paginate=yes', params );
    }

    getDetails ( employeeId: number | string ): Observable<Employee> {
        return this.api.get( 'employees/' + employeeId );
    }

    getBasicDetails ( employeeId: number ): Observable<Employee> {
        return this.api.get( 'employees/' + employeeId + '/get-basic-details' );
    }


    create ( data: Object | undefined ): Observable<Employee> {
        return this.api.post( 'employees', data );
    }

    update ( id: string | number, data: Object | undefined, filters: string | HttpParams ): Observable<any[]> {
        return this.api.put( 'employees/' + id + '?' + filters, data );
    }

    insertRemarks ( id: string | number, data: string | Object | undefined ): Observable<Employee> {
        return this.api.post( 'employees/' + id + '/remarks', data );
    }

    getDataForExcel ( lotId: number, params: string | HttpParams ): Observable<Employee[]> {
        return this.api.get( 'lots/' + lotId + '/export-excel?' + params );
    }

    getDataForVendorExcel ( lotId: number, filterParams: HttpParams ) {
        return this.api.get( 'lots/' + lotId + '/download-vendor-excel', filterParams );
    }

    deleteEmployee ( employeeId: number ) {
        return this.api.delete( 'employees/' + employeeId );
    }

    updateBasicDetails ( id: string | number, data: Object | undefined, filters: string ): Observable<any[]> {
        return this.api.post( 'employees/' + id + '/update-basic-details/' + '?' + filters, data );
    }

    bulkUpdateEmployeesFromExcel ( data: Object | undefined ) {
        return this.api.post( 'employees/bulk-update', data );
    }

    getAttachments ( id: number ): Observable<Attachment[]> {
        return this.api.get( 'employees/' + id + '/attachments' );
    }

    getDocuments ( id: string | number ) {
        return this.api.get( 'employees/' + id + '/documents' );
    }

    sendInsuffMail ( employeeId: number, emailData: object ) {
        return this.api.post( 'employees/' + employeeId + '/insuff-mail', emailData );
    }

    uploadResume ( employeeId: number, formData: FormData ): Observable<any> {
        return this.api.upload( 'employees/' + employeeId + '/upload-resume', formData );
    }

    deleteResume ( employeeId: number ): Observable<any> {
        return this.api.delete( 'employees/' + employeeId + '/delete-resume' );
    }

    /**
     *
     * @param documentId
     * @returns {
     * 	"status": "success",
     * 	"message": "Document deleted successfully"
     * }
     */
    deleteDocument ( documentId: number ): Observable<any> {
        return this.api.delete( 'employees/documents/' + documentId );
    }

    getAllDocuments ( employeeId: number ) {
        return this.api.get( 'employees/' + employeeId + '/documents-attachments' );
    }

    documentDuplicate ( employeeId: number, contents: string[] ): Observable<ICandidateDocumentResponse> {
        return this.api.post( 'employees/' + employeeId + '/document/duplicate', contents );
    }

    documentDelete ( employeeId: number, contents: HttpParams ): Observable<any> {
        return this.api.delete( 'employees/' + employeeId + '/document/delete', contents );
    }

    documentMove ( employeeId: number, contents: string[] ): Observable<ICandidateDocumentResponse> {
        return this.api.post( 'employees/' + employeeId + '/document/move', contents );
    }

    documentSwap ( employeeId: number, contents: string[] ): Observable<ICandidateDocumentResponse> {
        return this.api.post( 'employees/' + employeeId + '/document/swap', contents );
    }

    documentUpload ( employeeId: number, body: FormData ): Observable<ICandidateDocumentResponse> {
        return this.api.post( 'employees/' + employeeId + '/document/upload', body );
    }

    cropDocumentUpload ( employeeId: number, body: FormData ): Observable<ICandidateDocumentResponse> {
        return this.api.post( 'employees/' + employeeId + '/document/crop-upload', body );
    }
}
