<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import User Excel</h1>
</div>


<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <div class="col-md-10">
                            <div class="form-group">
                                <input type="file" [accept]="acceptedFileType" [disabled]="isFileDisabled"
                                    (change)="onFileChange($event)" class="form-control" />
                                <div class="text-danger pull-left" style="text-align: left;">
                                    {{errorMessage}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="row">
                <div class="col-md-5 form-group">
                    <button class="btn btn-primary">
                        <a (click)="downloadSampleExcel()">Download Sample Excel</a>
                    </button>
                </div>

                <div class="col-md-6 form-group">
                    <button type="button" [disabled]="uploadForm.invalid || isSubmitting" class="btn btn-primary"
                        (click)="onSubmit()">
                        Submit
                    </button>
                </div>


            </div>
        </div>
    </div>
</div>


<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-body">
            <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                <fieldset [disabled]="isSubmitting">
                    <div style="max-width: 100%;overflow-x: scroll">
                        <table class="table table-bordered" formArrayName="users">
                            <tr>
                                <th style="padding-right: 30px;padding-left: 20px;">Name</th>
                                <th style="padding-right: 30px;padding-left: 20px;">Email</th>
                                <th style="padding-right: 30px;padding-left: 20px;">Mobile Number</th>
                                <th style="padding-right: 30px;padding-left: 20px;">Birthday </th>
                                <th style="padding-right: 30px;padding-left: 20px;">Designation </th>
                                <th style="padding-right: 30px;padding-left: 20px;">Level Id </th>

                            </tr>
                            <tr *ngFor="let row of users.controls;let i=index" [formGroupName]="i">

                                <td style="padding-right: 30px;padding-left: 20px;">
                                    <input formControlName="name" type="text" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('name')?.invalid">
                                        Invalid Name
                                    </span><br>
                                </td>

                                <td style="padding-right: 30px;padding-left: 20px;">
                                    <input formControlName="email" type="email" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('email')?.invalid">
                                        Invalid Email address
                                    </span><br>
                                </td>

                                <td style="padding-right: 30px;padding-left: 20px;">
                                    <input formControlName="mobile_number" type="text" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('mobile_number')?.invalid">
                                        Invalid Mobile Number
                                    </span><br>
                                </td>

                                <td style="padding-right: 30px;padding-left: 20px;">
                                    <input formControlName="dob" type="text" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('dob')?.invalid">
                                        Invalid BirthDay
                                    </span><br>
                                </td>
                                <td style="padding-right: 30px;padding-left: 20px;">
                                    <input formControlName="designation" type="text" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('designation')?.invalid">
                                        Invalid Designation
                                    </span><br>
                                </td>

                                <td style="padding-right: 30px;padding-left: 20px;">
                                    <input formControlName="level_id" type="text" class="form-control">
                                    <span class="text-danger" *ngIf="row.get('level_id')?.invalid">
                                        Invalid Level Id
                                    </span><br>
                                </td>
                            </tr>
                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>