import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { Pagination } from '../../core/models/pagination.model';
import { CommonService } from '../../core/services/common.service';
import { SmsService } from '../../core/services/sms.service';

@Component( {
    selector: 'app-sms-list',
    templateUrl: './sms-list.component.html',
    styleUrls: [ './sms-list.component.css' ],
} )
export class SmsListComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    clients!: any[];

    constructor (
        private smsService: SmsService,
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,

        private commonService: CommonService
    ) {
    }

    ngOnInit () {

        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }


    filterResults (): void {

        if ( this.filterParams.keys().length ) {
            this.smsService.filter( this.filterParams ).subscribe( response => {
                this.pagination = response;
            } );
        }
    }

    showPage ( page: any ) {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    resend ( log: any ) {
        this.smsService.resend( log.id ).subscribe( response => {
            this.commonService.notifications.success( 'Success', 'Sms Sent successfully' );
        } );
    }
}
