<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                        <div class="form-group">
                            <input type="file" (change)="onFileChange($event)" [accept]="acceptedFileType"
                                class="form-control" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="panel">

        <div class="panel-heading">
            <h3 class="panel-title">Excel Heading </h3>
        </div>
        <div class="panel-body" style="display:block;width: 1120px; overflow-x: auto;">
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th>Name *</th>
                        <th>Mobile Number *</th>
                        <th>Pan Number *</th>
                        <th>Verification ID</th>
                        <th>Date of Birth</th>
                        <th>Address</th>
                        <th>Pin Code</th>
                    </tr>
                </thead>
            </table>
        </div>
    </div>
</div>
<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-10 panel-title">
                    Imported Rows are as follows
                </div>
                <div class="col-md-2 text-right text-center-xs">
                    <button type="button" (click)="importExcel()" [disabled]="!excelForm.valid" class="btn btn-primary">
                        Import
                        Excel
                    </button>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <form [formGroup]="excelForm" (keydown.enter)="$event.preventDefault()">

                <div class="col-md-2 form-group">
                    <label class="center-block">Select Client</label>
                    <select class="form-control" formControlName="client_api_id" required>
                        <option *ngFor="let client_api of clients" [value]="client_api.id">
                            {{ client_api.owner_name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-2 form-group">
                    <label class="center-block">Select Source</label>
                    <select class="form-control" formControlName="driver" required>
                        <option *ngFor="let source of creditReportSources | keyvalue" [value]="source.value">
                            {{ source.key }}
                        </option>
                    </select>
                </div>

                <div class="row">

                    <div class="col-md-12">

                        <fieldset [disabled]="isSubmitting">
                            <div style="max-width: 100%;overflow-x: scroll">
                                <table class="table table-bordered" formArrayName="excelFormArray">
                                    <tr>
                                        <th *ngFor="let title of originalHeaders">
                                            {{title}}
                                        </th>
                                    </tr>
                                    <tr *ngFor="let row of excelFormArray.controls;let i=index" [formGroupName]="i">
                                        <td *ngFor="let header of headerRaw;let k=index">
                                            <div>
                                                <input formControlName="{{header}}"><br>
                                                <small class="text-danger" [hidden]="row.get(header)?.valid">
                                                    {{validationMessages[header]}}
                                                </small>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>