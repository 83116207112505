import { Injectable } from '@angular/core';
import { Employee } from '../models/employee.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/catch';

export const NumberArray = [
    {
        id: 0,
        name: '0',
    },
    {
        id: 1,
        name: '1',
    },
    {
        id: 2,
        name: '2',
    },
    {
        id: 3,
        name: '>2',
    },
];

export const ReferenceSourceArray = [
    {
        id: 0,
        name: 'Advertisement',
    },
    {
        id: 1,
        name: 'Radio',
    },
    {
        id: 2,
        name: 'Cold Call',
    },
    {
        id: 3,
        name: 'Activity',
    },
    {
        id: 4,
        name: 'Ola-Driver',
    },
    {
        id: 5,
        name: 'Others',
    },
];

export const ResidingSinceArray = [
    {
        id: 0,
        name: 'less than 6 months',
    },
    {
        id: 1,
        name: '1-6 months',
    },
    {
        id: 2,
        name: '6-12 months',
    },
    {
        id: 3,
        name: '>12 months',
    },
];

export const HouseStatusArray = [
    {
        id: 0,
        name: 'Owned',
    },
    {
        id: 1,
        name: 'Owned By Relative',
    },
    {
        id: 2,
        name: 'Rented with Family',
    },
    {
        id: 3,
        name: 'Rented with Bachelor',
    },
    {
        id: 4,
        name: 'Rented Other',
    },
    {
        id: 5,
        name: 'Vacant',
    },
];

export const ReferenceRelationArray = [
    {
        id: 0,
        name: 'Relative',
    },
    {
        id: 1,
        name: 'Friend',
    },
    {
        id: 2,
        name: 'Ola Driver',
    },
];

export const EducationArray = [
    {
        id: 0,
        name: 'Below 8th',
    },
    {
        id: 1,
        name: '8th-9th',
    },
    {
        id: 2,
        name: '10th Passed',
    },
    {
        id: 3,
        name: '12th Passed',
    },
    {
        id: 4,
        name: 'Graduate',
    },
];

export const DepositArray = [
    {
        id: 0,
        name: 'Savings',
    },
    {
        id: 1,
        name: 'Borrowed - Finance',
    },
    {
        id: 2,
        name: 'Borrowed - Relative',
    },
    {
        id: 3,
        name: 'Others',
    },
];

export const MartialStatusArray = [
    {
        id: 0,
        name: 'Un-Married',
    },
    {
        id: 1,
        name: 'Married',
    },
    {
        id: 2,
        name: 'Separated',
    },
];

export const CurrentWorkArray = [
    {
        id: 0,
        name: 'Auto Rickshaw Driver - Owned',
    },
    {
        id: 1,
        name: 'Auto Rickshaw Driver - Rented',
    },
    {
        id: 2,
        name: 'Call Center Driver',
    },
    {
        id: 3,
        name: 'Car Rental Driver',
    },
    {
        id: 4,
        name: 'Grameen Seva driver',
    },
    {
        id: 5,
        name: 'Kali Pili Taxi Driver',
    },
    {
        id: 6,
        name: 'Operators drivers',
    },
    {
        id: 7,
        name: 'Others',
    },
    {
        id: 8,
        name: 'Others-Unemployed',
    },
];

@Injectable( {
    providedIn: 'root'
} )
export class EmployeeAdditionalDataService {

    constructor ( private api: ApiService ) {
    }

    findAdditionalDetails ( employeeId: number ): Observable<Employee> {
        return this.api.get( 'employees/' + employeeId + '/additional-details' );
    }

    updateAdditionalDetails ( id: string | number, data: Object | undefined ): Observable<any[]> {
        return this.api.post( 'employees/' + id + '/additional-details', data );
    }

    importExcel ( values: Object ): Observable<any> {
        return this.api.post( 'employees/additional-details/import-excel', values );
    }

    getNumberArray (): Promise<any[]> {
        return Promise.resolve( NumberArray );
    }

    getReferenceSourceArray (): Promise<any[]> {
        return Promise.resolve( ReferenceSourceArray );
    }

    getResidingSinceArray (): Promise<any[]> {
        return Promise.resolve( ResidingSinceArray );
    }

    getHouseStatusArray (): Promise<any[]> {
        return Promise.resolve( HouseStatusArray );
    }

    getReferenceRelationArray (): Promise<any[]> {
        return Promise.resolve( ReferenceRelationArray );
    }

    getEducationArray (): Promise<any[]> {
        return Promise.resolve( EducationArray );
    }

    getDepositArray (): Promise<any[]> {
        return Promise.resolve( DepositArray );
    }

    getMartialStatusArray (): Promise<any[]> {
        return Promise.resolve( MartialStatusArray );
    }

    getCurrentWorkArray (): Promise<any[]> {
        return Promise.resolve( CurrentWorkArray );
    }

}
