import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable( {
    providedIn: 'root'
} )
export class DocumentCropperService {

    private imageCroppedEvent: Subject<any> = new Subject<string>();

    constructor () { }

    imageCroppedSubscriber$ = this.imageCroppedEvent.asObservable();


    imageCropped ( data: any ): void {
        this.imageCroppedEvent.next( data );
    }


}
