<div class="wrapper-md">
    <div class="row">
        <div class="col-md-offset-3 col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Add New Remark
                </div>

                <form [formGroup]="addClientRemarkFormGroup" (ngSubmit)="submit()">
                    <div class="panel-body">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label class="center-block">Verification</label>
                                <select class="form-control" formControlName="verification">
                                    <option *ngFor="let verification of verifications"
                                        [value]="verification.shortTitle">
                                        {{verification.title}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="center-block">Remark</label>
                                <input formControlName="remark" class="form-control">
                            </div>
                        </div>
                    </div>

                    <div class="panel-footer text-right">
                        <div class="row">
                            <div class="col-sm-offset-8 col-sm-4">
                                <button class="btn btn-primary">
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>
