import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientApiService } from '../core/services/client-api.service';
import { CommonService } from '../core/services/common.service';
import { FilterService } from '../core/services/filter.service';
import { Subscription } from 'rxjs';

@Component( {
    selector: 'app-api-request-logs',
    templateUrl: './api-request-logs.component.html',
    styleUrls: [ './api-request-logs.component.css' ],
} )
export class ApiRequestLogsComponent implements OnInit, OnDestroy {
    filterParams: HttpParams = new HttpParams();
    data: any;
    filterFormGroup!: FormGroup;
    clients: any;
    filtersSubscription!: Subscription;

    constructor (
        private route: ActivatedRoute,
        private clientApiService: ClientApiService,
        private fb: FormBuilder,
        private router: Router,
        private commonService: CommonService,
        private filterService: FilterService,
    ) {
        this.initForm();

        this.updateFormFilter();

        this.filterResults( this.filterFormGroup.value );
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.filterResults( params );

        } );

        this.getAllClients();

    }

    initForm (): void {
        const myDate: Date = new Date();

        const previousMonth: Date = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );

        previousMonth.setDate( myDate.getDate() + 1 );

        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            created_before: 'all',
            created_after: previousMonth.toJSON().slice( 0, 10 ),
            client: '2',
            via: 'all',
        } );
    }


    getAllClients (): void {

        let params: HttpParams = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }


    filterResults ( params: Params ): void {
        this.clientApiService.filter( params ).subscribe( response => {
            this.data = response;
        } );
    }


    filterQueriedResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );

    }

    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }

    resetForm (): void {
        this.initForm();
        this.filterQueriedResults();
    }

    ngOnDestroy (): void {

        this.filtersSubscription.unsubscribe();
    }


}
