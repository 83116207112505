import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VcourtJobListComponent } from './vcourt-job-list/vcourt-job-list.component';
import { VcourtJobShowComponent } from './vcourt-job-show/vcourt-job-show.component';
import { SharedModule } from '../core/shared.module';
import { VcourtJobCreateComponent } from './vcourt-job-create/vcourt-job-create.component';
import { FilterPipe, HighLightPipe } from './filter.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { VcourtJobShowCompanySearchResultsComponent } from './vcourt-job-show-company-search-results/vcourt-job-show-company-search-results.component';
import { VcourtService } from '../core/services/vcourt.service';
import { VcourtUpdateCasesComponent } from './vcourt-update-cases/vcourt-update-cases.component'

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgxPaginationModule,
        TrimValueAccessorModule,
    ],
    declarations: [
        VcourtJobListComponent,
        VcourtJobShowComponent,
        VcourtJobCreateComponent,
        FilterPipe,
        HighLightPipe,
        VcourtJobShowCompanySearchResultsComponent,
        VcourtUpdateCasesComponent,
    ],
    exports: [
        FilterPipe,
        HighLightPipe,
    ],
    providers: [ VcourtService ],
} )
export class VcourtModule {
}
