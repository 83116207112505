import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
// import { environment } from 'environments/environment';


import { Employee } from '../models/employee.model';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { AddressVerification } from '../models/address-verification.model';

@Injectable( {
    providedIn: 'root'
} )
export class AddressService {

    constructor (
        private api: ApiService,
        protected http: HttpClient,

    ) {
    }

    getEmployees ( params: HttpParams = new HttpParams() ): Observable<Pagination> {
        return this.api.get( 'address/employees', params );
    }

    getAll (): Observable<Pagination> {
        return this.api.get( 'address/' );
    }

    filter ( params: HttpParams | Params | undefined ): Observable<Pagination> {
        return this.api.get( 'address', params );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'address/' + id );
    }

    find ( id: number ): Observable<AddressVerification> {
        return this.api.get( 'address/' + id );
    }

    create ( id: number, typeOfAddress: string ): Observable<Employee> {
        return this.api.get( 'address/' + id + '/add?type=' + typeOfAddress );
    }

    update ( id: string | number, data: Object | undefined, params: string ): Observable<AddressVerification> {
        return this.api.put( 'address/' + id + '?' + params, data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'address/' + id );
    }

    getAssignedWorkList ( filterParams: Params | undefined ): Observable<any[]> {
        return this.api.get( 'address/assigned-work', filterParams );
    }


    sendToAddressApp ( data: Object | undefined ) {
        return this.api.post( 'address/submit-to-app', data );
    }

    upload ( verificationId: number, data: FormData ): Observable<any> {

        return this.api.upload( 'address/' + verificationId + '/upload', data );
    }

}

