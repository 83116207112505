import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DigitalAddressService } from 'src/app/core/services/digital-address.service';

@Component( {
    selector: 'app-digital-address-follow-up-modal',
    templateUrl: './digital-address-follow-up-modal.component.html'
} )
export class DigitalAddressFollowUpModalComponent {
    followUpDetails: any;
    name: any;

    @ViewChild( 'followUpDetailsModal', { static: false } )
    public followUpDetailsModal!: ModalDirective;

    constructor ( private digitalAddressService: DigitalAddressService ) { }

    show ( id: string, name: any ) {
        this.digitalAddressService.getFollowUpDetails( id ).subscribe(
            response => {
                this.followUpDetails = response.data;
                this.followUpDetailsModal.show();
            }
        );
        this.name = name;
    }

    hide () {
        this.followUpDetailsModal.hide();
    }

}
