<ng-template #customItemTemplate let-model="item" let-index="index">
    {{model.name}}
</ng-template>

<app-contact-create #contactCreateModal></app-contact-create>

<div *ngIf="verificationFormGroup;then editMultiForm else editForm">
</div>

<!-- Edit page -->
<ng-template #editMultiForm>
    <div [formGroup]="verificationFormGroup">
        <div formArrayName="formArray">
            <div [formGroupName]="formGName">
                <div class="panel-body">
                    <fieldset [disabled]="isSubmitting">
                        <div class="row">

                            <div class="col-md-3 form-group">
                                <label class="center-block">QA Required
                                    <select class="form-control" formControlName="qa_required"
                                        [required]="qaValidations">
                                        <option [value]="0">
                                            NO
                                        </option>
                                        <option [value]="1">
                                            YES
                                        </option>
                                    </select>
                                </label>
                            </div>

                            <div class="col-md-3 form-group"
                                *ngIf="editFormGroup.get('qa_required')?.value| inArray:[0,1]">
                                <label class="center-block">QA done
                                    <select class="form-control" formControlName="qa_done"
                                        [required]="editFormGroup.get('qa_required')?.value | inArray:[0,1]">
                                        <option [value]="0">
                                            NO
                                        </option>
                                        <option [value]="1">
                                            YES
                                        </option>
                                    </select>
                                </label>
                                <small class="text-danger" *ngIf="editFormGroup.get('qa_done')?.errors">
                                    Required
                                </small>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 form-group" *ngIf="statuses">
                                <label class="center-block"> Status </label>

                                <input formControlName="status_name" [typeahead]="statuses" [typeaheadMinLength]="0"
                                    typeaheadOptionField="name" placeholder="Select Status"
                                    [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                                    (typeaheadOnSelect)="onStatusChanged($event)">

                            </div>
                            <div class="col-md-3 form-group">

                                <label class="center-block">Employment type </label>
                                <select class="form-control" formControlName="employment_type_id"
                                    (change)="onEmploymentTypeChanged($event)">
                                    <option *ngFor="let type of employmentTypes" [value]="type.id">
                                        {{type.name}}
                                    </option>
                                </select>

                                <small class="text-danger"
                                    *ngIf="editFormGroup.get('employment_type_id')?.errors?.required">
                                    Required
                                </small>

                                <small class="text-danger"
                                    *ngIf="editFormGroup.get('employment_type_id')?.errors?.forbidden">
                                    Should be current
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Methodology </label>
                                <select class="form-control" formControlName="method" [required]="isStatusRedOrGreen">
                                    <option *ngFor="let method of methodology" [value]="method.id">
                                        {{method.name}}
                                    </option>
                                </select>
                                <small class="text-danger" *ngIf="editFormGroup.get('method')?.errors">
                                    Required
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <app-country-typeahead [formGroup]="editFormGroup"></app-country-typeahead>

                            </div>
                        </div>

                        <div class="row">
                            <ng-template #contactTemplate>

                                <div class="text-center" *ngIf="!companyContacts">
                                    <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                                </div>

                                <div style="max-height: 500px;overflow-y: scroll;" *ngIf="companyContacts">
                                    <div *ngFor="let contact of companyContacts">

                                        <ul class="list-unstyled">
                                            <li> <b>Name: </b> {{ contact.name }}</li>
                                            <li> <b>Primary Email: </b> {{ contact.primary_email }}</li>
                                            <li> <b>Secondary Email: </b> {{ contact.secondary_email }}</li>
                                            <li> <b>Designation: </b> {{ contact.designation }}</li>
                                            <li> <b>Phone Number: </b> {{ contact.phone_number }}</li>
                                            <li> <b>Helpful: </b> {{ contact.is_helpful ? 'Yes' : 'No' }}</li>
                                            <li> <b>Active: </b> {{ contact.is_active ? 'Yes' : 'No' }}</li>
                                            <li> <b>Centralised: </b> {{ contact.is_centralised ? 'Yes' : 'No' }}</li>
                                        </ul>

                                        <hr>

                                        <hr>
                                    </div>
                                </div>

                            </ng-template>
                            <div class="form-group col-md-3">

                                <app-employment-company-typeahead [formGroup]="editFormGroup">
                                </app-employment-company-typeahead>

                                <a class="btn-link"
                                    (click)="contactCreateModal.show('employment',editFormGroup.get('employment_company_id')?.value)">
                                    <i class="fa fa-plus"></i>Create contact</a>

                                <a class="btn-link" style="float: right;" [outsideClick]="true"
                                    [popover]="contactTemplate" popoverTitle="Company Contact Details"
                                    (click)="getContacts(editFormGroup.get('employment_company_id')?.value)"> <i
                                        class="fa fa-bars"></i> {{ editFormGroup.get('company_contacts_count')?.value }}
                                    contacts</a>

                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Employee ID</label>
                                <input class="form-control" formControlName="previous_employee_id">
                            </div>


                            <div class="form-group col-md-3">
                                <label class="center-block">Joining Date</label>
                                <input type="date" class="form-control" formControlName="joining_date">
                                <small class="text-danger" *ngIf="editFormGroup.get('joining_date')?.errors">
                                    Required
                                </small>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Last Date/ Expected Last Date </label>
                                <input type="date" class="form-control" formControlName="end_date">
                                <small class="text-danger" *ngIf="editFormGroup.get('end_date')?.errors">
                                    Required
                                </small>
                            </div>

                        </div>

                        <div class="row">


                            <div class="col-md-3 form-group">
                                <label class="center-block"> Designation</label>
                                <input formControlName="designation" class="form-control form-control-lg" />

                                <small class="text-danger" *ngIf="editFormGroup.get('designation')?.errors">
                                    Required
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Department</label>
                                <input class="form-control" formControlName="department">
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Salary</label>
                                <input class="form-control" formControlName="salary">
                                <small class="text-danger" *ngIf="editFormGroup.get('salary')?.errors">
                                    Required
                                </small>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Supervisor/Manager Title</label>
                                <input class="form-control" formControlName="reference_title">
                                <small class="text-danger" *ngIf="editFormGroup.get('reference_title')?.errors">
                                    Required
                                </small>
                            </div>



                        </div>
                        <div class="row">

                            <div class="form-group col-md-3">
                                <label class="center-block">Supervisor/Manager Name</label>
                                <input class="form-control" formControlName="reference_name">
                                <small class="text-danger" *ngIf="editFormGroup.get('reference_name')?.errors">
                                    Required
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Supervisor/Manager Number</label>
                                <input class="form-control" formControlName="reference_number">
                                <small class="text-danger" *ngIf="editFormGroup.get('reference_number')?.errors">
                                    Required
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Supervisor/ Manager’s Email Address</label>
                                <input class="form-control" formControlName="reference_email">
                                <small class="text-danger"
                                    *ngIf="editFormGroup.get('reference_email')?.errors?.['validateEmail']">
                                    Invalid Email
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">HR Name</label>
                                <input class="form-control" formControlName="hr_name">
                            </div>

                        </div>
                        <div class="row">

                            <div class="form-group col-md-3">
                                <label class="center-block">HR Number</label>
                                <input class="form-control" formControlName="hr_number">
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">HR Email</label>
                                <input class="form-control" formControlName="hr_email">
                                <small class="text-danger"
                                    *ngIf="editFormGroup.get('hr_email')?.errors?.['validateEmail']">
                                    Invalid Email
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Client Remarks </label>
                                <input class="form-control" formControlName="client_remarks"
                                    [required]="clientRemarkValidations">
                                <small class="text-danger" *ngIf="editFormGroup.get('client_remarks')?.errors">
                                    Required
                                </small>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Payment Reference Number: </label>
                                <input class="form-control" formControlName="payment_reference_number">
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Date Of Verification</label>
                                <input type="date" class="form-control" min="{{created_at}}" max="{{current_date}}"
                                    formControlName="completed_at" [required]="dateOfVerificationValidations">
                                <small class="text-danger" *ngIf="editFormGroup.get('completed_at')?.errors">
                                    Required
                                </small>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-3 form-group">
                                <label class="center-block">Insufficiency Raised On</label>
                                <small class="text-danger" *ngIf="editFormGroup.get('insuff_raised_at')?.errors">
                                    Invalid
                                </small>
                                <input readonly type="date" class="form-control" formControlName="insuff_raised_at"
                                    max="{{current_date}}">
                            </div>

                            <div class="col-md-3 form-group">
                                <label class="center-block">Insufficiency Cleared On</label>
                                <small class="text-danger" *ngIf="editFormGroup.get('insuff_cleared_at')?.errors">
                                    Invalid
                                </small>
                                <input readonly type="date" class="form-control" formControlName="insuff_cleared_at"
                                    max="{{current_date}}">
                            </div>

                            <div class="col-md-3 form-group" *ngIf="employmentTypeCurrent">
                                <label class="center-block">CE Initiation Date</label>
                                <small class="text-danger" *ngIf="editFormGroup.get('initiated_at')?.errors">
                                    Invalid
                                </small>
                                <input type="date" class="form-control" formControlName="initiated_at"
                                    min="{{created_at}}" max="{{current_date}}" [required]="inititationDateValidations">
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-3">
                                <label>Payment Reference Proof</label>
                                <input appUploadAttachment (uploaded)="onFileUploaded($event, verificationId)"
                                    type="file" class="form-control" [attachableId]="verificationId"
                                    attachableType="employment" attachmentType="payment_url" />

                            </div>


                            <div class="col-md-3">
                                <label>Screenshots</label>
                                <input appUploadAttachment (uploaded)="onFileUploaded($event, verificationId)"
                                    type="file" multiple class="form-control" [attachableId]="verificationId"
                                    attachableType="employment" attachmentType="screenshot" />

                            </div>

                            <div class="col-md-3">
                                <label>Proof</label>
                                <input type="file" id="proof_url_{{editFormGroup.get('id')?.value}}" ng2FileSelect
                                    accept="image/*" [uploader]="uploader" class="form-control"
                                    (change)="upload('proof_url')" />

                            </div>

                        </div>

                        <div class="row" *ngIf="attachments">

                            <div class="col-md-3">
                                <div *ngFor="let url of paymentUrls; index as i">
                                    <a href="{{ url.full_path }}" class="btn-link">
                                        {{ i + 1 }}. {{ url.file_name }}
                                    </a>
                                    <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                        (click)="deleteEmploymentAttachment(url, verificationId)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div *ngFor="let screenshot of screenshots; index as i">
                                    <a href="{{ screenshot.full_path }}" class="btn-link">
                                        {{ i + 1 }}. {{ screenshot.file_name }}
                                    </a>
                                    <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                        (click)="deleteEmploymentAttachment(screenshot, verificationId)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-3" *ngIf="editFormGroup.get('proof_url')?.value; else elseDiv">
                                <div class="row">

                                    <div class="col-md-12">
                                        <a target="_blank" href="{{editFormGroup.get('proof_url')?.value}}"
                                            class="btn-link">
                                            Proof Url
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <ng-template #elseDiv>
                                <div class="col-md-3">
                                    No File Attached
                                </div>
                            </ng-template>

                        </div>

                        <br>

                        <div class="row">

                            <div class="col-md-3">
                                <label>Appointment Letter
                                    <input appUploadDocument (uploaded)="onFileUploaded($event, verificationId)"
                                        type="file" multiple class="form-control" [documentableId]="verificationId"
                                        documentableType="employment" documentType="offer" />
                                </label>
                            </div>

                            <div class="col-md-3">
                                <label>Latest Payslips
                                    <input appUploadDocument (uploaded)="onFileUploaded($event, verificationId)"
                                        type="file" multiple class="form-control" [documentableId]="verificationId"
                                        documentableType="employment" documentType="payslip" />
                                </label>
                            </div>

                            <div class="col-md-3">
                                <label>Resignation/Relieving Letter
                                    <input appUploadDocument (uploaded)="onFileUploaded($event, verificationId)"
                                        type="file" multiple class="form-control" [documentableId]="verificationId"
                                        documentableType="employment" documentType="relieving" />
                                </label>
                            </div>

                            <div class="col-md-3">
                                <label>Resignation/Relieving Acceptances Email /Letter
                                    <input appUploadDocument (uploaded)="onFileUploaded($event, verificationId)"
                                        type="file" multiple class="form-control" [documentableId]="verificationId"
                                        documentableType="employment" documentType="document" />
                                </label>
                            </div>

                        </div>

                        <div class="row" *ngIf="attachments">

                            <div class="col-md-3">
                                <div *ngFor="let letter of offerLetters; index as i">
                                    <a href="{{ letter.full_path }}" class="btn-link">
                                        {{ i + 1 }}. {{ letter.file_name }}
                                    </a>
                                    <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                        (click)="deleteEmploymentDocument(letter, verificationId)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div *ngFor="let payslip of payslips; index as i">
                                    <a href="{{ payslip.full_path }}" class="btn-link">
                                        {{ i + 1 }}. {{ payslip.file_name }}
                                    </a>
                                    <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                        (click)="deleteEmploymentDocument(payslip, verificationId)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div *ngFor="let letter of resignationLetters; index as i">
                                    <a href="{{ letter.full_path }}" class="btn-link">
                                        {{ i + 1 }}. {{ letter.file_name }}
                                    </a>
                                    <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                        (click)="deleteEmploymentDocument(letter, verificationId)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div *ngFor="let document of otherDocuments; index as i">
                                    <a href="{{ document.full_path }}" class="btn-link">
                                        {{ i + 1 }}. {{ document.file_name }}
                                    </a>
                                    <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                        (click)="deleteEmploymentDocument(document, verificationId)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </fieldset>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Modal Edit -->
<ng-template #editForm>
    <div [formGroup]="editFormGroup">
        <div class="panel-body">
            <fieldset [disabled]="isSubmitting">
                <div class="row">
                    <div class="col-md-3 form-group" *ngIf="statuses">
                        <label class="center-block"> Status </label>

                        <input formControlName="status_name" [typeahead]="statuses" [typeaheadMinLength]="0"
                            typeaheadOptionField="name" placeholder="Select Status"
                            [typeaheadItemTemplate]="customItemTemplate" class="form-control"
                            (typeaheadOnSelect)="onStatusChanged($event)">
                    </div>
                    <div class="col-md-3 form-group">
                        <label class="center-block">Employment type </label>
                        <select class="form-control" formControlName="employment_type_id"
                            (change)="onEmploymentTypeChanged($event)">
                            <option *ngFor="let type of employmentTypes" [value]="type.id">
                                {{type.name}}
                            </option>
                        </select>

                        <small class="text-danger"
                            *ngIf="editFormGroup.get('employment_type_id')?.errors?.['required']">
                            Required
                        </small>
                        <small class="text-danger"
                            *ngIf="editFormGroup.get('employment_type_id')?.errors?.['forbidden']">
                            Should be current
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Methodology </label>
                        <select class="form-control" formControlName="method" [required]="isStatusRedOrGreen">
                            <option *ngFor="let method of methodology" [value]="method.id">
                                {{method.name}}
                            </option>
                        </select>
                        <small class="text-danger" *ngIf="editFormGroup.get('method')?.errors">
                            Required
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <app-country-typeahead [formGroup]="editFormGroup"></app-country-typeahead>
                    </div>
                </div>

                <div class="row">

                    <div class="form-group col-md-3">

                        <app-employment-company-typeahead [formGroup]="editFormGroup">
                        </app-employment-company-typeahead>

                        <a class="btn-link"
                            (click)="contactCreateModal.show('employment',editFormGroup.get('employment_company_id')?.value)">
                            <i class="fa fa-plus"></i>Create contact</a>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Employee ID</label>
                        <input class="form-control" formControlName="previous_employee_id">
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Joining Date</label>
                        <input type="date" class="form-control" formControlName="joining_date">
                    </div>
                    <div class="form-group col-md-3">
                        <label class="center-block">End Date</label>
                        <input type="date" class="form-control" formControlName="end_date">
                    </div>

                </div>

                <div class="row">

                    <div class="col-md-3 form-group">
                        <label class="center-block"> Designation</label>
                        <input formControlName="designation" class="form-control form-control-lg" />
                        <small class="text-danger" *ngIf="editFormGroup.get('designation')?.errors">
                            Required
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Department</label>
                        <input class="form-control" formControlName="department">
                    </div>
                    <div class="form-group col-md-3">
                        <label class="center-block">Salary</label>
                        <input class="form-control" formControlName="salary">
                        <small class="text-danger" *ngIf="editFormGroup.get('salary')?.errors">
                            Required
                        </small>
                    </div>
                    <div class="form-group col-md-3">
                        <label class="center-block">Supervisor/Manager Title</label>
                        <input class="form-control" formControlName="reference_title">
                        <small class="text-danger" *ngIf="editFormGroup.get('reference_title')?.errors">
                            Required
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Supervisor/Manager Name</label>
                        <input class="form-control" formControlName="reference_name">
                        <small class="text-danger" *ngIf="editFormGroup.get('reference_name')?.errors">
                            Required
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Supervisor/Manager Number</label>
                        <input class="form-control" formControlName="reference_number">
                        <small class="text-danger" *ngIf="editFormGroup.get('reference_number')?.errors">
                            Required
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Supervisor/Manager Email</label>
                        <input class="form-control" formControlName="reference_email">
                        <small class="text-danger"
                            *ngIf="editFormGroup.get('reference_email')?.errors?.['validateEmail']">
                            Invalid Email
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">HR Name</label>
                        <input class="form-control" formControlName="hr_name">
                    </div>
                    <div class="form-group col-md-3">
                        <label class="center-block">HR Number</label>
                        <input class="form-control" formControlName="hr_number">
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">HR Email</label>
                        <input class="form-control" formControlName="hr_email">
                        <small class="text-danger" *ngIf="editFormGroup.get('hr_email')?.errors?.['validateEmail']">
                            Invalid Email
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Client Remarks</label>
                        <input class="form-control" formControlName="client_remarks"
                            [required]="clientRemarkValidations">
                        <small class="text-danger" *ngIf="editFormGroup.get('client_remarks')?.errors">
                            Required
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Date Of Verification</label>
                        <input type="date" class="form-control" min="{{created_at}}" max="{{current_date}}"
                            formControlName="completed_at" [required]="dateOfVerificationValidations">
                        <small class="text-danger" *ngIf="editFormGroup.get('completed_at')?.errors">
                            Required
                        </small>
                    </div>

                    <div class="form-group col-md-3">
                        <label class="center-block">Payment Reference Number: </label>
                        <input class="form-control" formControlName="payment_reference_number">
                    </div>

                </div>

                <div class="row">

                    <div class="col-md-3">
                        <label>Payment Reference Proof</label>
                        <input appUploadAttachment (uploaded)="onFileUploaded($event, verification.id)" type="file"
                            class="form-control" [attachableId]="verification.id" attachableType="employment"
                            attachmentType="payment_url" />

                    </div>

                    <div class="col-md-3">
                        <label>Screenshots</label>
                        <input appUploadAttachment (uploaded)="onFileUploaded($event, verification.id)" type="file"
                            multiple class="form-control" [attachableId]="verification.id" attachableType="employment"
                            attachmentType="screenshot" />

                    </div>

                    <div class="col-md-3">
                        <label>Proof</label>
                        <input type="file" id="proof_url_{{verification.id}}" ng2FileSelect accept="image/*"
                            [uploader]="uploader" class="form-control" (change)="upload('proof_url')" />

                    </div>

                </div>

                <div class="row" *ngIf="verification.attachments">

                    <div class="col-md-3">
                        <div *ngFor="let url of verification.attachments['payment_urls']; index as i">
                            <a href="{{ url.full_path }}" class="btn-link">
                                {{ i + 1 }}. {{ url.file_name }}
                            </a>
                            <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                (click)="deleteEmploymentAttachment(url, verification.id)">
                                <i class="glyphicon glyphicon-trash"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div *ngFor="let screenshot of verification.attachments['screenshots']; index as i">
                            <a href="{{ screenshot.full_path }}" class="btn-link">
                                {{ i + 1 }}. {{ screenshot.file_name }}
                            </a>
                            <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                (click)="deleteEmploymentAttachment(screenshot, verification.id)">
                                <i class="glyphicon glyphicon-trash"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3" *ngIf="verification.proof_url; else elseDiv">
                        <div class="row">

                            <div class="col-md-12">
                                <a target="_blank" href="{{verification.proof_url}}" class="btn-link">
                                    Proof Url
                                </a>
                            </div>
                        </div>
                    </div>

                    <ng-template #elseDiv>
                        <div class="col-md-3">
                            No File Attached
                        </div>
                    </ng-template>

                </div>

                <br>

                <div class="row">

                    <div class="col-md-3">
                        <label>Appointment Letter
                            <input appUploadDocument (uploaded)="onFileUploaded($event, verification.id)" type="file"
                                multiple class="form-control" [documentableId]="verification.id"
                                documentableType="employment" documentType="offer" />
                        </label>
                    </div>

                    <div class="col-md-3">
                        <label>Latest Payslips
                            <input appUploadDocument (uploaded)="onFileUploaded($event, verification.id)" type="file"
                                multiple class="form-control" [documentableId]="verification.id"
                                documentableType="employment" documentType="payslip" />
                        </label>
                    </div>

                    <div class="col-md-3">
                        <label>Resignation/Relieving Letter
                            <input appUploadDocument (uploaded)="onFileUploaded($event, verification.id)" type="file"
                                multiple class="form-control" [documentableId]="verification.id"
                                documentableType="employment" documentType="relieving" />
                        </label>
                    </div>

                    <div class="col-md-3">
                        <label>Resignation/Relieving Acceptances Email /Letter
                            <input appUploadDocument (uploaded)="onFileUploaded($event, verification.id)" type="file"
                                multiple class="form-control" [documentableId]="verification.id"
                                documentableType="employment" documentType="document" />
                        </label>
                    </div>

                </div>

                <div class="row" *ngIf="verification.attachments">

                    <div class="col-md-3">
                        <div *ngFor="let letter of verification.attachments['offers']; index as i">
                            <a href="{{ letter.full_path }}" class="btn-link">
                                {{ i + 1 }}. {{ letter.file_name }}
                            </a>
                            <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                (click)="deleteEmploymentDocument(letter, verification.id)">
                                <i class="glyphicon glyphicon-trash"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div *ngFor="let payslip of verification.attachments['payslips']; index as i">
                            <a href="{{ payslip.full_path }}" class="btn-link">
                                {{ i + 1 }}. {{ payslip.file_name }}
                            </a>
                            <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                (click)="deleteEmploymentDocument(payslip, verification.id)">
                                <i class="glyphicon glyphicon-trash"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div *ngFor="let letter of verification.attachments['relievings']; index as i">
                            <a href="{{ letter.full_path }}" class="btn-link">
                                {{ i + 1 }}. {{ letter.file_name }}
                            </a>
                            <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                (click)="deleteEmploymentDocument(letter, verification.id)">
                                <i class="glyphicon glyphicon-trash"></i>
                            </a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div *ngFor="let document of verification.attachments['documents']; index as i">
                            <a href="{{ document.full_path }}" class="btn-link">
                                {{ i + 1 }}. {{ document.file_name }}
                            </a>
                            <a [appCan]="'admin'" style="float: right;margin-right: 10px;"
                                (click)="deleteEmploymentDocument(document, verification.id)">
                                <i class="glyphicon glyphicon-trash"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </fieldset>
        </div>
    </div>
</ng-template>