import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { InviteService } from 'src/app/core/services/invite.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { EmailLogService } from 'src/app/core/services/email-log.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component( {
    selector: 'app-invite-filters',
    templateUrl: './invite-filters.component.html',
} )
export class InviteFiltersComponent implements OnInit {

    filterFormGroup!: FormGroup;

    lots: any[] = [];

    emailStatuses: { id: number, name: string }[] = [];

    inviteStatuses: { id: number, name: number }[] = [];

    followCountOptions: { value: number | string, lable: string }[] = []

    constructor (
        public global: GlobalService,
        private fb: FormBuilder,
        private filterService: FilterService,
        private commonService: CommonService,
        private emailLogService: EmailLogService,
        private inviteService: InviteService
    ) { }

    get companies () { return this.global.fetchCompanies(); }

    ngOnInit (): void {

        this.emailStatuses = this.emailLogService.getStatuses();

        this.inviteStatuses = this.inviteService.getStatuses();

        this.initForm();

        this.updateFormFilter();

        this.filterResults();

        this.setFollowupCounts();

    }

    initForm (): void {

        const currentDate: Date = new Date();

        const previousMonth: Date = new Date( currentDate );

        previousMonth.setDate( currentDate.getDate() - 30 );

        this.filterFormGroup = this.fb.group( {
            email_address: '',
            is_filled: '',
            company_id: [ [] ],
            lot_id: [],
            sent_after: previousMonth.toJSON().slice( 0, 10 ),
            sent_before: '',
            invite_status: [ [] ],
            is_active: '',
            email_status: [ [] ],
            email_count: 'all',
            name: '',
            phone_number: '',
            page: 1,
        } );
    }


    onCompanyDropDownOpened ( $event: any ) {

        // console.log( $event );

        // const $companyIds: [] = this.filterFormGroup.get( 'company_id' )?.value;

        // $companyIds.forEach( $companyId => this.companyDropdown.setSelected( new Event('click'), { id: $companyId, name: '' } ) )

    }

    onCompanyDropDownClosed () {

        this.fetchLots();

    }

    fetchLots (): void {

        const params = new HttpParams();

        const $companyIds: [] = this.filterFormGroup.get( 'company_id' )?.value;

        // remove all current lots
        this.lots.splice( 0, this.lots.length );

        const combinedLots: any[] = [];


        if ( !$companyIds || !$companyIds.toString() ) {
            this.lots = [];
            return;
        }

        const request = params.set( 'company_id', $companyIds.toString() );

        // fetch the lots for each company id selected
        this.commonService.companiesLots( request ).subscribe( lots => {

            lots.forEach( lot => combinedLots.push( lot ) );

            this.lots = combinedLots;

        } );

        // Set default value of lot_id to 'all'
        // to make sure previously selected lot_id is removed
        // this.filterFormGroup.controls.lot_id.setValue( 'all' );
    }

    onCompanyChanged ( $companyIds: [] ) {

        // if ( !Array.isArray( $companyIds ) ) return;

        // // set company ids in filter form and converting them to numbers
        // this.filterFormGroup.get( 'company_id' ).setValue( $companyIds );
    }

    onLotChanged () {

        // console.log( $event, "lot changed" );

        // let lot_id = this.route.snapshot.queryParams[ 'lot_id' ];

        // if ( lot_id ) lot_id = lot_id.map( id => +id );

    }

    updateFormFilter (): void {

        this.filterService.updateFormFiltersv2( this.filterFormGroup );

    }

    filterResults (): void {

        this.fetchLots();

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    resetForm (): void {

        this.initForm();

        this.filterResults();

    }


    setFollowupCounts () {
        this.followCountOptions.push(
            { value: "all", lable: "All" },
            { value: 1, lable: "Invite Sent" }
        );

        for ( let i = 1; i <= this.inviteService.maxFollowUpallowed; i++ ) {

            this.followCountOptions.push(
                { value: i + 1, lable: `Follow Up ${ i } Sent` } );
        }
    }
}
