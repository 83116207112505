import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component( {
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html'
} )
export class MultiSelectComponent implements OnInit {

    @Input() options!: any;
    @Input() placeholder!: string;
    @Input() controlName!: string;
    @Input() formGName!: FormGroup | any;
    @Input() multipleValue: boolean = true;
    @Output() onDropDownClosed = new EventEmitter<any>();
    @Output() onDropDownValueChanged = new EventEmitter<any>();

    get selectedOptions (): FormControl { return this.formGName.get( this.controlName ) as FormControl; }

    get selectedCount () {

        if ( !this.selectedOptions.value ) return 0;

        if ( typeof this.selectedOptions.value === 'string' ) {
            return 1;
        }
        return this.selectedOptions.value?.length;
    }

    private filteredItems!: any[];

    ngOnInit (): void {

        if ( !this.multipleValue ) return;

        const value = this.selectedOptions.value;

        // Convert string to array when only one option
        // is selected for multiple value controls

        if ( typeof value == "string" && !Array.isArray( value ) ) {
            this.selectedOptions.setValue( [ value ] );
        }
    }

    selectAll (): void {

        const options = this.filteredItems?.length > 0 ? this.filteredItems : this.options;

        const newSelectedItems = options.map( ( item: { id: any; } ) => item.id ) as any[];

        const currentItems: any[] = this.selectedOptions.value as any[];

        newSelectedItems.forEach( item => {

            // dont add if item already exists in list
            if ( currentItems?.includes( item ) )
                return;

            currentItems?.push( item );

        } );

        this.selectedOptions?.setValue( currentItems );

        this.filteredItems = [];

    }

    unSelectAll (): void {

        this.selectedOptions.patchValue( [] );

        this.filteredItems = [];

    }

    onSearch ( $event: { term: string; items: any[]; } ): void {

        this.filteredItems = $event.items;

    }

    onSelected ( $event: any ) {

        // do nothing

    }

    onUnselected ( $event: any ) {

        // do nothing

    }

    onClosed ( $event: any ): void {
        this.onDropDownClosed.emit( $event );
    }

    onChange ( $event: any ) {
        this.onDropDownValueChanged.emit( $event );
    }

}
