import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { LicenceService } from '../../core/services/licence.service';

@Component( {
    selector: 'app-licence-response-modal',
    templateUrl: './licence-response-modal.component.html',
    styleUrls: [ './licence-response-modal.component.css' ],
} )
export class LicenceResponseModalComponent {
    response!: any[];

    @ViewChild( 'licenceResponse', { static: false } )
    public licenceResponse!: ModalDirective;

    constructor (
        private licenceService: LicenceService,
        private commonService: CommonService,
    ) { }

    show ( log: any ) {

        this.licenceService.getResponse( log.id ).subscribe(
            response => {
                this.response = response;
                this.licenceResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }

    hide () {
        this.licenceResponse.hide();
    }

}
