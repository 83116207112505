import { Injectable } from '@angular/core';
import { Pagination } from '../models/pagination.model';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Employee } from '../models/employee.model';
import { DocumentVerification } from '../models/document-verification.model';
import { Params } from '@angular/router';
import { DownloadModalComponent } from 'src/app/common/download-modal/download-modal.component';
import { DownloadExcelInitialState } from '../models/download-excel-initial-state.model';
import { ModalService } from './modal.service';

@Injectable( {
    providedIn: 'root'
} )
export class DocumentService {

    constructor (
        private api: ApiService,
        private modalService: ModalService,
    ) {
    }

    getEmployees ( params: HttpParams = new HttpParams() ): Observable<Pagination> {
        return this.api.get( 'document/employees', params );
    }

    filter ( params: Params | HttpParams ): Observable<Pagination> {
        return this.api.get( 'document', params );
    }

    find ( id: number ): Observable<DocumentVerification> {
        return this.api.get( 'document/' + id );
    }

    findByEmployeeId ( id: number ): Observable<Employee> {
        return this.api.get( 'document/' + id );
    }

    create ( id: number ): Observable<Employee> {
        return this.api.get( 'document/' + id + '/add' );
    }

    update ( id: number, data: any, params: Params | HttpParams | string ): Observable<DocumentVerification> {
        return this.api.put( 'document/' + id + '?' + params, data );
    }

    deleteVerification ( id: number ): Observable<any[]> {
        return this.api.delete( 'document/' + id );
    }

    getAssignedWorkList ( filterParams: Params ): Observable<any[]> {
        return this.api.get( 'document/assigned-work', filterParams );
    }


    showDownloadModal ( initialState: DownloadExcelInitialState ) {
        const config: any = {
            initialState,
            backdrop: 'static',
            keyboard: false,
        }

        return this.modalService.showModal( DownloadModalComponent, config );
    }

}
