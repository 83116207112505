import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { Pagination } from '../../core/models/pagination.model';
import { AadhaarService } from '../../core/services/aadhaar.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-uidai-logs',
    templateUrl: './uidai-logs.component.html',
    styleUrls: [ './uidai-logs.component.css' ],
} )
export class UidaiLogsComponent extends ExportExcelComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination | any = new Pagination();
    checkedIds: any[] = [];
    loading!: boolean;
    fetchingInProgress: boolean = false;


    constructor (
        private aadhaarService: AadhaarService,
        private route: ActivatedRoute,
        private router: Router,
        private commonService: CommonService
    ) {
        super();
    }

    ngOnInit (): void {
        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
            this.filterResults();
        } );
    }

    filterResults (): void {
        if ( this.filterParams.keys().length ) {
            this.aadhaarService.filter( this.filterParams ).subscribe( response => {
                this.pagination = null;
                this.pagination = response;
            } );
        }
    }

    showPage ( page: number ): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: {
                page: page,
            },
            queryParamsHandling: 'merge',
        };

        // Get the current path
        const currentUrlPath: string = this.router.url.split( '?' )[ 0 ];


        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    onSearch (): void {
        this.filterResults();
    }


    showResponse ( logId: number ): void {

        this.aadhaarService.gethtmlResponse( logId ).subscribe( ( response ) => {
            this.openResponseTab( response );
        },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );


    }


    resend ( log: any ): void {
        this.aadhaarService.resend( log.id ).subscribe( response => {
            this.commonService.notifications.success( 'Success', 'Voter ID Request Sent successfully' );
            this.filterResults();
        } );
    }

    openResponseTab ( htmlResponse: any ): void {
        let newWindow: Window | any;
        if ( !htmlResponse.html ) {
            htmlResponse.html = '<h2> wait for the response and come back after some time or try resending it</h2>';
        }
        newWindow = window.open();
        newWindow.document.write( htmlResponse.html );
    }


}
