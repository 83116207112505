import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Errors } from '../../core/models/errors.module';
import { UserService } from '../../core/services/user.service';
import { CommonService } from '../../core/services/common.service';

import { Router } from '@angular/router';
import { User } from '../../core/models/user.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { validateEqual } from '../../common/validators/equal-validtor.directive';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-user-change-password',
    templateUrl: './user-change-password.component.html',
    styleUrls: [ './user-change-password.component.css' ],
} )
export class UserChangePasswordComponent {

    isSubmitting = false;
    resetPasswordForm!: FormGroup;
    errors: Errors = new Errors();
    user: User = new User();

    constructor ( private userService: UserService,
        private commonService: CommonService,
        private fb: FormBuilder,
        private router: Router ) {
        this.initForm();
    }


    @ViewChild( 'userResetPasswordModal', { static: false } )
    public userResetPasswordModal!: ModalDirective;

    @Output()
    onUserEdited = new EventEmitter<boolean>();

    show ( user: User ) {
        this.user = user;
        console.log( this.user );
        this.resetPasswordForm.patchValue( this.user );
        this.userResetPasswordModal.show();
    }

    hide () {
        this.initForm();
        this.userResetPasswordModal.hide();
    }

    resetPassword () {
        this.isSubmitting = true;
        this.userService.resetPassword( this.user.id, this.resetPasswordForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( response[ 'message' ] );
                this.isSubmitting = false;
                this.hide();
            },
            err => {
                this.errors = err;
                this.isSubmitting = false;
            }
        )
    }


    initForm () {
        this.resetPasswordForm = this.fb.group( {
            password: [ '', [
                Validators.required,
                Validators.minLength( 6 ),
                Validators.pattern( '^(?=.*[a-z|A-Z])(?=.*[A-Z])(?=.*[0-9]).+$' ) ] ],
            confirm_password: [ '', [
                Validators.required,
                Validators.minLength( 6 ),
                Validators.pattern( '^(?=.*[a-z|A-Z])(?=.*[A-Z])(?=.*[0-9]).+$' ), validateEqual( 'password' ) ] ],
        } );
    }
}
