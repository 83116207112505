import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Errors } from '../../core/models/errors.module';
import { Lot } from '../../core/models/lot.model';
import { CommonService } from '../../core/services/common.service';
import { CompanyService } from '../../core/services/company.service';
import { LotService } from '../../core/services/lot.service';
import { HttpParams } from '@angular/common/http';
import { EmployeeService } from '../../core/services/employee.service';
import { ImportExcelComponent } from 'src/app/core/components/import-excel/import-excel.component';
import { Employee } from 'src/app/core/models/employee.model';
import { NewPagination } from 'src/app/core/models/pagination.model';
import { FilterService } from 'src/app/core/services/filter.service';
import { AcceptedFileType } from 'src/app/core/enums/file-types.enum';


@Component( {
    selector: 'app-lot-merge',
    templateUrl: './lot-merge.component.html',
    styleUrls: [ './lot-merge.component.css' ],

} )
export class LotMergeComponent extends ImportExcelComponent implements OnInit {

    errors: Errors = new Errors();
    override isSubmitting = false;
    lot: Lot = new Lot();
    allLots!: Lot[];
    employees!: NewPagination<Employee[]> | any;
    track_ids: string[] = [];
    lotMergeFormGroup!: FormGroup;
    loading!: boolean;
    filterParams: HttpParams = new HttpParams();
    lotId!: number;
    isAllChecked: boolean = false;
    isFileDisabled: boolean = false;
    isExcelFileUploaded: boolean = false;
    override acceptedFileType = AcceptedFileType.EXCEL;


    constructor ( private route: ActivatedRoute,
        private employeeService: EmployeeService,
        protected override fb: FormBuilder,
        private lotService: LotService,
        private companyService: CompanyService,
        private commonService: CommonService,
        public filterService: FilterService,
        private elRef: ElementRef ) {
        super( fb );
        this.initForm();
    }

    get trackIdFormArray (): FormArray {

        return this.lotMergeFormGroup.get( 'track_ids' ) as FormArray;
    }

    get lotIdControl (): FormControl {
        return this.lotMergeFormGroup.get( 'lot_id' ) as FormControl;
    }

    initForm (): void {

        this.lotMergeFormGroup = this.fb.group( {
            track_ids: [ [] ],
            lot_id: [ '', Validators.required ],
        } );

    }

    ngOnInit (): void {

        this.route.paramMap.subscribe( ( params: Params ) => {

            this.lotId = params[ 'params' ].id;

            this.lotService.find( this.lotId ).subscribe( ( lotResponse: Lot ) => {

                this.lot = lotResponse;

                this.companyService.getLots( this.lot.company_id ).subscribe( ( lotsResponse: Lot[] ) => {

                    this.allLots = lotsResponse;

                    this.getAllEmployees();

                } );

            } )

        } );

        this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {

            this.getAllEmployees();

        } );

    }

    getAllEmployees (): void {

        this.track_ids = [];

        this.employeeService.getByLot( this.lot.id, this.route.snapshot.queryParams ).subscribe(

            ( employees: any ) => this.employees = employees

        );
    }

    checkAll ( event: any ): void {

        this.track_ids = [];

        if ( event.target.checked ) {

            this.elRef.nativeElement.querySelectorAll( '.checkbox-all' ).forEach( ( checkbox: { checked: boolean; value: string; } ) => {

                checkbox.checked = true;

                this.track_ids.push( checkbox.value );

            } );

            this.isAllChecked = true;

        } else {

            this.elRef.nativeElement.querySelectorAll( '.checkbox-all' ).forEach( ( checkbox: { checked: boolean; } ) => checkbox.checked = false );

            this.isAllChecked = false;

        }

        this.isFileDisabled = true;

    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( option: string, data: any ): void {

        const index = this.track_ids.indexOf( option );


        if ( data.target.checked && index === -1 ) {

            this.track_ids.push( option );

        } else {

            if ( index !== -1 ) {

                this.track_ids.splice( index, 1 );
            }
        }

    }

    lotMerge (): void {

        this.isSubmitting = true;

        this.trackIdFormArray.patchValue( this.track_ids );

        this.lotService.lotMerge( this.lot.id, this.lotMergeFormGroup.value ).subscribe( {
            next: ( response ) => {

                this.commonService.notifications.success( 'Successful', response[ 'message' ] );
                this.isSubmitting = false;
                this.getAllEmployees();
                this.track_ids = [];
            },
            error: () => {
                this.commonService.notifications.error( 'Error', 'Something went wrong' );
                this.isSubmitting = false;
            },
        } );
    }

    showPage ( page: any ): void {

        this.filterService.filterByPage( page );

        this.isAllChecked = false;

        this.track_ids = [];

    }

    applyValidations (): void {

        this.track_ids = [];

        this.isExcelFileUploaded = true;



    }

    initExcelForm (): void {
        this.excelForm = this.fb.group( {
            excelFormArray: this.fb.array( [] ),
        } );
    }

    setValidationMessages (): boolean {
        return true
    }

    showInvalidFileTypeError (): void {
        this.commonService.notifications.error( "Acceptable file type should be " + this.acceptedFileType );
        this.excelFormArray.clear();
    }
}
