<ng-container *ngIf="company">

    <td>
        <a class="btn-link text-main text-semibold" routerLink="/companies/{{company.id}}">
            {{company.name}}
        </a>
        <br>
        <small class="text-muted">
            {{ company.official_name }}
        </small>
    </td>
    <td>
        <div *ngFor="let user of company.users">
            {{user.name}}
            <span *ngFor="let role of user.roles">
                , {{role.name}}
            </span>
        </div>
        <br>
    </td>
    <td>
        {{company.open_batches}}
        <br>
        <small class="text-muted">
            Prefix: {{ company.prefix }}
        </small>
    </td>

    <td>
        {{ company.created_at }}
    </td>

    <td>
        {{ company.updated_at }}
    </td>
    <td>{{company.employee_created_at }}</td>
    <td>
        {{companyStatus}}
    </td>
    <td>
        <a class="btn btn-sm btn-default" routerLink="/companies/{{company.id}}/edit" [appCan]="'admin'">Edit</a>
    </td>
</ng-container>