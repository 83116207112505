
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, ParamMap, Router, Params } from '@angular/router';
import { validateAllowedCharacters } from 'src/app/common/validators/allowed-characters-validator.directive';
import { validateEmail } from '../../common/validators/email-validator.directive';
import { Employee } from '../../core/models/employee.model';
import { CommonService } from '../../core/services/common.service';
import { EmployeeService } from '../../core/services/employee.service';
import { EnvironmentService } from './../../core/services/environment.service';
import { DownloadService } from 'src/app/core/services/download.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Attachment } from 'src/app/core/models/attachment.model';
import { StatusService } from 'src/app/core/services/status.service';
import { ConfirmationModalOptions, ConfirmationModalService } from 'src/app/core/services/confirmation-modal.service';

@Component( {
    selector: 'app-employee-edit',
    templateUrl: './employee-edit.component.html',
    styleUrls: [ './employee-edit.component.css' ],
} )
export class EmployeeEditComponent implements OnInit {
    @ViewChild( 'pdfViewerModal', { static: false } ) pdfViewerModal!: ModalDirective;
    isSubmitting: boolean = false;
    editForm!: FormGroup;
    employee!: Employee | any;
    crmUrl: string | undefined;
    filterParams: HttpParams = new HttpParams();
    reportPdfUrl: any;
    pdfBlob!: Blob | null;

    employeeDocuments: any;
    id!: number;
    attachments: Attachment[] | any = [];
    documents: Document[] | any = [];
    maxFileSize: number = 10000;
    readonly allowedMimeTypes = [ 'image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ];
    maxFileError: boolean = false;
    verification: string | undefined;
    attachmentsLoading: boolean = true;
    documentLoading: boolean = true;
    verificationInterface: { [ key: string ]: any } = {
        address_verifications: 'Address Verification',
        icr_verifications: 'Icr Verification',
        identity_verifications: 'Identity Verification',
        education_verifications: 'Education Verification',
        pcc_verifications: 'Pcc Verification',
        reference_verifications: 'Reference Verification',
        document_verifications: 'Document Verification',
        employment_verifications: 'Employment Verification',
        global_db_verifications: 'Global Database Verification',
    };

    openVerificationInterface: { [ key: string ]: any } = {
        address_verifications: 'address',
        icr_verifications: 'icr',
        identity_verifications: 'identity',
        education_verifications: 'education',
        pcc_verifications: 'pcc',
        reference_verifications: 'reference',
        document_verifications: 'document',
        employment_verifications: 'employment',
        global_db_verifications: 'global-db',
    };

    constructor (
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private employeeService: EmployeeService,
        public common: CommonService,
        private environmentService: EnvironmentService,
        protected el: ElementRef,
        private downloadService: DownloadService,
        private sanitizer: DomSanitizer,
        private statusService: StatusService,
        private confirmationModalService: ConfirmationModalService
    ) { }

    get statuses () { return this.statusService.statuses; }

    initForm (): void {
        this.editForm = this.fb.group( {
            name: [ '', [ validateAllowedCharacters( '()/,' ) ] ],
            mobile_number: [ '' ],
            father_name: [ '', [ validateAllowedCharacters( '()/,' ) ] ],
            dob: [ '' ],
            client_employee_id: [ '' ],
            age: [ '' ],
            email: [ '', validateEmail() ],
            interim_report_date: [ '' ],
            expected_joining_date: [ '' ],
            completion_date: [ '' ],
            navigation: '',
            verification_status: [ '' ],
            employment_type: [ null ],
            official_email: [ null, validateEmail() ],
            settings: this.fb.group( {
                email_visibility: [],
                mobile_visibility: [],
            } ),
        } );
    }

    get metafields () {
        return this.employee.metadata;
    }

    ngOnInit (): void {

        this.initForm();

        this.crmUrl = this.environmentService.oldCrmUrl;

        this.id = +this.route.snapshot.params[ 'id' ];

        this.route.paramMap.subscribe( ( paramMap: ParamMap ) => {

            this.id = +this.route.snapshot.params[ 'id' ];

            this.fetchEmployee( +paramMap.get( 'id' )! );

            this.fetchAttachments( +paramMap.get( 'id' )! );

            this.fetchDocuments( +paramMap.get( 'id' )! );

            this.fetchAllDocumets( +paramMap.get( 'id' )! );
        } );

        this.subscribeToQueryChanges();


    }

    fetchEmployee ( id: number ): void {

        this.employeeService.getDetails( id ).subscribe( ( employee: Employee ) => {

            this.editForm.patchValue( employee );

            this.employee = employee;

        } );

    }

    fetchAttachments ( id: number ): void {

        this.employeeService.getAttachments( id ).subscribe( ( attachments: Attachment[] ) => {

            this.attachments = attachments;

            this.attachmentsLoading = false;

        } );
    }

    fetchDocuments ( id: number ): void {

        this.documentLoading = true;

        this.employeeService.getDocuments( id ).subscribe( ( documents: Document[] ) => {

            this.documents = documents;

            this.documentLoading = false;

        } );
    }

    subscribeToQueryChanges (): void {

        // Listens to only params in the route
        // eg ?company_id=12&lot_id=1
        this.route.queryParams.subscribe( params => {
            Object.keys( params ).forEach( key => {
                if ( params[ key ] !== '' ) {
                    this.filterParams = this.filterParams.set( key, params[ key ] );
                }
            } );
        } );
    }

    submitForm (): void {

        const data: Params = this.editForm.value;

        this.employeeService.update( this.employee.id, data, this.filterParams ).subscribe(
            response => {
                this.handleUpdateResponse( response );
                this.common.notifications.success( 'Employee Updated', 'Employee Updated' );
            },
            error => {
                this.common.notifications.error( 'Unable to update' );
            },
        );

    }

    openVerification ( type: string ): void {

        const link: any[] = [ type, this.employee.id, 'edit' ];

        this.router.navigate( link );
    }

    updateAndBack (): void {

        this.editForm.value.navigation = 'previous';

        this.submitForm();
    }

    updateAndRefresh (): void {

        this.editForm.value.navigation = 'refresh';

        this.submitForm();
    }

    updateAndNext (): void {

        this.editForm.value.navigation = 'next';

        this.submitForm();
    }

    handleUpdateResponse ( response: any ): void {

        if ( response.employee === 'none' ) {

            this.common.notifications.warning( 'WARNING', 'NO CANDIDATE IS AVAILABLE TO NAVIGATE' );

        } else {

            this.showEmployee( response.employee );
        }
    }

    showEmployee ( employee: Employee ): void {

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'merge',
        };

        const link: any[] = [ 'employees', employee.id, 'edit' ];

        this.router.navigate( link, navigationExtras );
    }

    areFilesUploadable ( inputEl: HTMLInputElement | null, fileCount: number ): boolean {

        let fileSize: number = 0;

        for ( let i: number = 0; i < fileCount; i++ ) {

            if ( inputEl?.files == null ) return false;

            if ( !inputEl.files[ i ] ) continue;

            fileSize = inputEl.files[ i ].size / 1024;

            if ( fileSize > this.maxFileSize ) {

                this.common.notifications.error( 'Unable to upload!! file size must be less than 10 MB' );

                return false;
            }

            if ( !this.allowedMimeTypes.includes( inputEl.files[ i ].type ) ) {

                this.common.notifications.error( "Images, documents and pdfs are allowed" );

                inputEl.value = '';  // Remove the filename from input element in HTML

                return false;
            }
        }

        return true;
    }

    uploadResume (): void {

        // Locate the file element meant for the file upload.
        const inputEl: HTMLInputElement | any = this.el.nativeElement.querySelector( '#' + 'resume_url' );


        // Get the total amount of files attached to the file input.
        const fileCount: number = inputEl.files.length;

        if ( this.areFilesUploadable( inputEl, fileCount ) ) {

            // Create a new form data instance
            const formData: FormData = new FormData();

            if ( fileCount > 0 ) { // a file was selected

                for ( let i: number = 0; i < fileCount; i++ ) {

                    formData.append( 'resume_url_' + i, inputEl.files.item( i ) );

                }

                this.employeeService.uploadResume( this.employee.id, formData ).subscribe(
                    ( success ) => {
                        this.employee.resume_url = success.file_path;
                        this.common.notifications.success( 'File uploaded successfully' );
                    },
                    ( error ) => {
                        inputEl.value = null;
                        this.common.notifications.error( 'Unable to upload File' )
                    }

                );
            }

        }

    }

    onDocumentUploaded ( event: any, employeeId: number ): void {

        this.fetchDocuments( employeeId );

    }

    getAttachments ( employee: Employee | any ): void {

        this.employee = employee;

        const relationName: string = this.verification + '_verifications';

        for ( const index in employee[ relationName ] ) {

            const verification: any = employee[ relationName ][ index ];

            this.attachments[ index ] = verification.attachments;
        }
    }

    delete ( documentId: number | null = null ): void {

        if ( documentId != null ) {

            this.employeeService.deleteDocument( documentId ).subscribe( {
                next: ( success: any ) => {

                    this.common.notifications.success( 'File Deleted successfully' );

                    this.fetchDocuments( this.id );
                },
                error: ( error: any ) => {

                    this.common.notifications.error( 'Unable to delete File' )
                }
            } );
        }
        else {

            this.employeeService.deleteResume( this.employee.id ).subscribe( {
                next: ( success: any ) => {
                    this.employee.resume_url = null;
                    this.common.notifications.success( 'File Deleted successfully' );
                },
                error: ( error: any ) => {

                    this.common.notifications.error( 'Unable to delete File' )
                }
            } );
        }
    }

    onDeleteButtonClicked ( documentId: number | null = null ): void {

        const options: ConfirmationModalOptions = {};

        this.confirmationModalService.confirm( options, () => this.delete( documentId ) );
    }

    fetchAllDocumets ( employeeId: number ): void {
        this.employeeService.getAllDocuments( employeeId ).subscribe( ( response ) => {
            this.employeeDocuments = response;
        } )
    }

    get downloadReportLink (): string {

        return this.environmentService.apiUrl + 'employees/' + this.employee.id + '/download?download=yes';

    }

    viewReport (): void {

        const url = this.environmentService.apiUrl + 'employees/' + this.employee.id + '/download';

        const fileType = 'pdf';

        this.downloadService.downloadFile( url, fileType, null ).subscribe(
            ( blob: Blob ) => {
                this.reportPdfUrl = this.downloadService.getFileUrl( blob );
                this.pdfViewerModal.show();
            },
            ( error: any ) => {
                this.common.notifications.error( 'Some Error Occured', error );
            }
        );
    }

    onReportReload (): void {
        this.viewReport();
    }
}
