<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()">

                <div class="row">

                    <div *ngIf="companies" class="form-group col-md-2" [hidden]="!('company' | inArray:filters)">
                        <label class="center-block">Filter By Company</label>
                        <select class="form-control" formControlName="company_id">
                            <option value=""> Select Company</option>
                            <option *ngFor="let company of companies" [value]="company.id">
                                {{company.name}}
                            </option>
                        </select>
                    </div>

                    <div class="form-group col-md-2" [hidden]="!('name' | inArray:filters)">
                        <label class="center-block">Filter By Name</label>
                        <input class="form-control" formControlName="name">

                    </div>

                    <div class="form-group col-md-2" [hidden]="!('email' | inArray:filters)">
                        <label class="center-block">Filter By Email</label>
                        <input class="form-control" formControlName="email">

                    </div>

                    <div class="form-group col-md-2" [hidden]="!('status' | inArray:filters)">
                        <label class="center-block">Filter By Status</label>
                        <select class="form-control" formControlName="status">
                            <option value=""> Select Status</option>
                            <option value="1">Active</option>
                            <option value="0"> Inactive</option>
                        </select>
                    </div>

                </div>
                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>