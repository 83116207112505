import { HttpParams } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';

import { ExportExcelComponent } from '../../common/export-excel/export-excel.component';
import { CommonService } from '../../core/services/common.service';
import { RemarkService } from '../../core/services/remark.service';
import { GlobalService } from 'src/app/core/services/global.service';
import { User } from 'src/app/core/models/user.model';


@Component( {
    selector: 'app-remarks-filter',
    templateUrl: './remarks-filter.component.html',
    styleUrls: [ './remarks-filter.component.css' ],
} )
export class RemarksFilterComponent extends ExportExcelComponent implements OnInit {

    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;


    constructor ( private router: Router,
        public global: GlobalService,
        private fb: FormBuilder,
        private remarkService: RemarkService,
        private commonService: CommonService ) {

        super();

        this.initForm();
    }

    get users (): User[] { return this.global.fetchUsers(); }

    ngOnInit () {


        this.setDefault();

        this.filterResults();

    }

    private setDefault () {

        this.filterParams.keys().forEach( key => {

            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    initForm (): void {
        const myDate = new Date();

        const previousMonth = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {

            created_after: previousMonth.toJSON().slice( 0, 10 ),
            created_before: 'all',
            page: 1,
            track_id: 'all',
            user_id: 'all',

        } );
    }

    filterResults (): void {

        this.filterFormGroup.get( 'page' )?.setValue( 1 );

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };
        // Get the current url string & split it into 2 part by '?'
        // Get only the first part which is the path
        // eg. 'identity?xyx=abc' in url will return only 'identity'
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];

        // Navigate to current path along with our set query string
        // This will add query params to the urls
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    generateExcel () {
        this.remarkService.getDataForExcel( this.filterParams ).subscribe(
            response => {
                this.downloadExcel( response, 'Remarks Excel' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            } );
    }


}
