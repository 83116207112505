import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ErrorMessage } from '../components/validation/error-messages';

@Injectable( {
    providedIn: 'root'
} )
export class ValidationService {

    static getValidatorErrorMessage ( validatorName: string, validatorValue?: any ): string {

        if ( validatorName == 'minlength' ) {

            return ErrorMessage.minlength( validatorValue.requiredLength );

        }

        if ( validatorName == 'maxlength' ) {

            return ErrorMessage.maxlength( validatorValue.requiredLength );

        }

        return ErrorMessage[ validatorName ] as string;
    }

    static hasLowercase ( control: FormControl ) {

        if ( !control.value ) return null;

        return control.value.match( /[a-z]/ ) ? null : { 'lowercase': true }
    }

    // validate password includes uppercase characters
    static hasUppercase ( control: FormControl ) {

        if ( !control.value ) return null;

        return control.value.match( /[A-Z]/ ) ? null : { 'uppercase': true }
    }

    // validate password includes special characters
    static hasSpecialCharacter ( control: FormControl ) {

        if ( !control.value ) return null;

        return control.value.match( /[!@#$%^&*()\-_=+{};:,<.>]/ ) ? null : { 'specialCharacter': true }
    }

    // validate password includes number
    static hasNumber ( control: FormControl ) {

        if ( !control.value ) return null;

        return control.value.match( /[0-9]/ ) ? null : { 'number': true }
    }
}
