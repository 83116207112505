<div bsModal #detailsModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">


    <ng-template #customItemTemplate let-model="item" let-index="index">
        {{model.name}}
    </ng-template>

    <form [formGroup]="editForm" (ngSubmit)="update()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Additional Details - {{employee.name}}</h4>
                </div>

                <div class="modal-body">
                    <fieldset [disabled]="isSubmitting">
                        <div class="row">

                            <div class="form-group col-md-3">
                                <label class="center-block">Zone</label>
                                <input class="form-control" formControlName="zone">
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">City</label>
                                <input class="form-control" formControlName="city">
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Current Work</label>
                                <input formControlName="current_work_name" [typeahead]="currentWorkArray"
                                    [typeaheadMinLength]="0" typeaheadOptionField="name"
                                    placeholder="Select Current Work" [typeaheadItemTemplate]="customItemTemplate"
                                    class="form-control" (typeaheadOnSelect)="OnTypeheadSelect($event,'current_work')">

                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Current Work Description</label>
                                <input class="form-control" formControlName="current_work_description">
                            </div>
                        </div>

                        <div class="row">

                            <div class="form-group col-md-3">
                                <label class="center-block">Driving Experience</label>
                                <input class="form-control" formControlName="driving_experience">
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Monthly Income</label>
                                <input class="form-control" formControlName="monthly_income">
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Martial Status</label>
                                <select formControlName="martial_status" class="form-control">
                                    <option *ngFor="let value of martialStatusArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Number of Children</label>
                                <select formControlName="number_of_children" class="form-control">
                                    <option *ngFor="let value of numberArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row">

                            <div class="form-group col-md-3">
                                <label class="center-block">Number of Dependents</label>
                                <select formControlName="number_of_dependents" class="form-control">
                                    <option *ngFor="let value of numberArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Referred By</label>
                                <select formControlName="referred_by" class="form-control">
                                    <option *ngFor="let value of referenceSourceArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Current Address Status</label>
                                <select formControlName="current_address_status" class="form-control">
                                    <option *ngFor="let value of houseStatusArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Residing Since</label>
                                <select formControlName="residing_since" class="form-control">
                                    <option *ngFor="let value of residingSinceArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row">

                            <div class="form-group col-md-3">
                                <label class="center-block">Permanent Address Status</label>
                                <select formControlName="permanent_address_status" class="form-control">
                                    <option *ngFor="let value of houseStatusArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Reference Realtion</label>
                                <select formControlName="reference_relation" class="form-control">
                                    <option *ngFor="let value of referenceRelationArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Education Qualification</label>
                                <select formControlName="education_qualification" class="form-control">
                                    <option *ngFor="let value of educationArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group col-md-3">
                                <label class="center-block">Source of Deposit</label>
                                <select formControlName="source_of_deposit" class="form-control">
                                    <option *ngFor="let value of depositArray" value="{{value.id}}">
                                        {{value.name}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-3">
                                <label class="center-block">Commercial Licence Date</label>
                                <input class="form-control" formControlName="commercial_licence_issue_date">
                            </div>
                            <div class="form-group col-md-3">
                                <label class="center-block">Age</label>
                                <input class="form-control" formControlName="age">
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary" [disabled]="!editForm.valid">
                            Update
                        </button>
                    </div>

                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>