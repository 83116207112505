import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { GstService } from '../../core/services/gst.service';

@Component( {
    selector: 'app-gst-response-modal',
    templateUrl: './gst-response-modal.component.html',
    styleUrls: [ './gst-response-modal.component.css' ],
} )
export class GstResponseModalComponent {

    attachments!: any[];

    @ViewChild( 'gstResponse', { static: false } ) gstResponse!: ModalDirective;

    constructor (
        private gstService: GstService,
        private commonService: CommonService,
    ) {
    }

    show ( log: any, source: any ): void {

        this.gstService.getResponse( log.id, source ).subscribe(
            response => {
                this.attachments = response[ 'attachments' ];
                this.gstResponse.show();
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );
    }

    hide (): void {
        this.gstResponse.hide();
    }

}
