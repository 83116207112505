import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';


@Component( {
    selector: 'candidate-document-crop',
    templateUrl: './candidate-document-crop.component.html',
    styleUrls: [ './candidate-document-crop.component.css' ],
} )

export class CandidateDocumentCropComponent {

    @ViewChild( ImageCropperComponent, { static: false } ) imageCropper!: ImageCropperComponent;

    @ViewChild( 'imageCropModal', { static: false } ) imageCropModal!: ModalDirective;

    @Input() imagesUrl!: string;

    @Input() uploaded!: boolean;

    @Output() croppedUrl: EventEmitter<any> = new EventEmitter();

    rotation: number = 0;

    scale: number = 1;

    croppedImage: string | any = null;

    selectedImageUrl: string | any = null;

    transform: ImageTransform = {};

    imageChangedEvent: any = null;

    constructor (
        protected http: HttpClient
    ) { }

    show (): void {

        this.croppedImage = null;

        this.selectedImageUrl = null;

        if ( this.uploaded ) {

            this.urlToBase64();

        } else {
            this.selectedImageUrl = this.imagesUrl;

            this.imageCropModal.show();
        }
    }

    closeModel (): void {

        this.imageCropModal.hide();

    }

    fileChangeEvent ( event: any ): void {

        this.imageChangedEvent = event;

    }

    imageCropped ( event: ImageCroppedEvent ): void {

        this.croppedImage = event.objectUrl;

    }

    updateRotation ( stepSize: number ): void {

        let newRotation = this.rotation + stepSize;

        if ( newRotation < -360 ) {

            newRotation = -360;

        } else if ( newRotation > 360 ) {

            newRotation = 360;
        }

        this.rotation = newRotation;

        this.transform = {
            ...this.transform,
            rotate: this.rotation,
        };
    }

    resetImage (): void {

        this.scale = 1;

        this.rotation = 0;

        this.transform = {};
    }

    changeZoom ( stepSize: number ): void {

        let newScale = this.scale + stepSize;

        if ( newScale < 0 ) {

            newScale = 0;

        }

        this.scale = newScale;

        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    onCropCompleted (): void {

        this.croppedUrl.emit( { base64: this.croppedImage } );

        this.imageCropModal.hide();

    }

    private setHeaders (): HttpHeaders {

        const headersConfig = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        return new HttpHeaders( headersConfig );
    }

    urlToBase64 (): void {

        const imageUrl = this.imagesUrl;

        const headers = this.setHeaders();

        const image = new Image();

        const that = this;

        this.http.get( imageUrl, {
            responseType: 'blob', headers,
        } ).subscribe( data => {

            const reader = new FileReader();

            const blob = new Blob( [ data ], { type: 'image/' } );

            reader.onloadend = function ( loadEvent: any ): void {

                image.src = loadEvent.target.result;

                that.selectedImageUrl = image.src;

                that.imageCropModal.show();
            };

            reader.readAsDataURL( blob );

        }, error => {

        } );
    }
}
