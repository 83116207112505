import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Company } from 'src/app/core/models/company.model';
import { User } from 'src/app/core/models/user.model';
import { CompanyRoleService } from 'src/app/core/services/company-role.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-company-filters',
    templateUrl: './company-filters.component.html',
} )
export class CompanyFiltersComponent implements OnInit {
    @Input()
    filterParams: HttpParams = new HttpParams;

    filterFormGroup!: FormGroup;
    spocsUsers: User[] = [];

    constructor (
        private fb: FormBuilder,
        private filterService: FilterService,
        private companyService: CompanyService,
        private globalService: GlobalService,
        private companyRoleService: CompanyRoleService
    ) {
    }

    ngOnInit (): void {
        this.initForm();
        this.setDefault();
        this.filterResults();
        // this.globalService.setUsers();
        this.fetchSpocsUsers()
    }

    get companies (): Company[] {
        return this.globalService.fetchCompanies();
    }

    get users () {
        return this.globalService.fetchUsers();
    }

    initForm (): void {
        this.filterFormGroup = this.fb.group( {
            status: [],
            company_id: [ [] ],
            created_after: [],
            created_before: [],
            page: 1,
            user: [],
            test_company_id: [],
        } );
    }

    setDefault (): void {
        this.filterService.updateFormFilters( this.filterFormGroup );
    }

    filterResults (): void {

        if ( this.filterFormGroup.get( 'company_id' )?.value !== null && Array.isArray( this.filterFormGroup.get( 'company_id' )?.value ) ) {
            this.filterFormGroup.get( 'company_id' )?.setValue( this.filterFormGroup.get( 'company_id' )?.value.map( ( x: string | number ) => +x ) );
        }

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }

    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    fetchSpocsUsers () {
        // role Id for Account Manager
        const roleId: number = 1;

        this.companyRoleService.getUsersByCompanyRole( roleId ).subscribe( {
            next: ( response: User[] ) => {
                this.spocsUsers = response;
            },
        } )
    }
}
