import { Component, OnInit } from '@angular/core';
import { VerificationService } from '../../core/services/verification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Errors } from '../../core/models/errors.module';
import { CommonService } from '../../core/services/common.service';
import { validateAllowedCharacters } from '../../common/validators/allowed-characters-validator.directive';
import { Verification } from '../../core/models/verification.model';

import { Router } from '@angular/router';
import { VerificationStatusService } from 'src/app/core/services/verification-status.service';
import { GlobalService } from 'src/app/core/services/global.service';


@Component( {
    selector: 'app-status-create',
    templateUrl: './status-create.component.html',
    styleUrls: [ './status-create.component.css' ],
} )
export class StatusCreateComponent implements OnInit {

    addStatusFormGroup!: FormGroup;
    isSubmitting: boolean = false;
    errors: Errors = new Errors();
    verifications: Verification[] = [];
    allStatuses!: any[];

    constructor (
        private verificationService: VerificationService,
        private verificationStatusService: VerificationStatusService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private router: Router,
        public global: GlobalService
    ) { }


    ngOnInit (): void {

        this.initForm();

        // Populate the list of all verifications.
        this.verificationService.getAllVerifications().then( verifications => this.verifications = verifications );

    }

    initForm (): void {

        this.addStatusFormGroup = this.fb.group( {
            title: [ '', [ Validators.required, validateAllowedCharacters( '-' ) ] ],
            type: [ '', [ Validators.required ] ],
            description: [ '', [ Validators.required, validateAllowedCharacters( '-' ) ] ],
            client_title: [ '', [ Validators.required, validateAllowedCharacters( '-' ) ] ],
            client_description: [ '', [ Validators.required, validateAllowedCharacters( '-' ) ] ],
            filter_verification_status: [ '' ],
            verification: [ '' ],
            insuff: [ '', Validators.required ],
        } );
    }

    onVerificationTypeChanged (): void {

        this.allStatuses = [];

        let verification: string = this.addStatusFormGroup.controls[ 'verification' ].value;

        if ( verification === 'current_address' || verification === 'permanent_address' ) {

            verification = 'address';
        }

        this.allStatuses = this.global.fetchStatuses()[ verification ];

    }

    submit (): void {
        this.isSubmitting = true;
        const data = this.addStatusFormGroup.value;
        this.verificationStatusService.create( data ).subscribe(
            ( response: any ) => {
                if ( response[ 'success' ] ) {
                    this.commonService.notifications.success( 'Successful', response[ 'message' ] );
                    this.router.navigate( [ '/home' ] );
                } else {
                    this.commonService.notifications.error( 'Error', response[ 'message' ] );
                }
            },
            ( error: any ) => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            } );
    }

}
