<div class="panel panel-default" [formGroup]="consentForm">
    <div class="panel-heading">
        Consent Form
    </div>
    <div id="collapse-consent" class="panel-collapse collapse in">
        <div class="panel-body">
            <div class="col-md-12 margin-bottom-10">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"> </textarea>
            </div>

            <div class="col-md-12">
                <div class="form-group">
                    <label>Is candidate signature required? </label>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="signature_required" [value]="true"
                            id="radioYes">
                        <label class="form-check-label padding-left-5" for="radioYes"> Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="signature_required"
                            [value]="false" id="radioNo">
                        <label class="form-check-label padding-left-5" for="radioNo"> No</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>