import { EventEmitter, Injectable, Output } from '@angular/core';


@Injectable( {
    providedIn: 'root'
} )
export class ShortcutService {

    @Output() nextPressed = new EventEmitter();
    @Output() backPressed = new EventEmitter();
    @Output() refreshPressed = new EventEmitter();

    constructor () {
    }

}
