import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { UserLevel } from 'src/app/core/models/user.model';


@Directive( {
    selector: '[appCan]',
} )
export class CanDirective implements OnInit {

    @Input( 'appCan' ) appCan!: any;

    constructor (
        private el: ElementRef,
        private auth: AuthService,
        private renderer: Renderer2 // Use Renderer2 for safe DOM manipulation
    ) { }

    ngOnInit () {
        if ( this.hasAcesss() ) {
            // If the user has access, do nothing
        } else {
            this.remove();
        }
    }

    private hasAcesss () {
        return this.getCurrentUserLevel() >= this.getLevelFromString( this.appCan );
    }

    private remove (): void {
        this.renderer.removeChild( this.el.nativeElement.parentNode, this.el.nativeElement );
    }

    private getCurrentUserLevel (): number {
        return this.auth.user?.level_id || UserLevel.Default;
    }

    private getLevelFromString ( value: string ): number {
        switch ( value ) {
            case 'superadmin':
                return UserLevel.SuperAdmin;
            case 'admin':
                return UserLevel.Admin;
            case 'moderator':
                return UserLevel.Moderator;
            case 'editor':
                return UserLevel.Editor;
            case 'user':
                return UserLevel.Default;
            default:
                return UserLevel.Default;
        }
    }
}
