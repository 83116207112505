<div>
    <app-header [user]="getCurrentUser()"></app-header>
    <app-sidebar [user]='getCurrentUser()'></app-sidebar>
    <div class="app-content">
        <div class="app-content-body">
            <div class="">
                <app-progress-bar></app-progress-bar>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <app-footer></app-footer>

    <!-- <jaspero-confirmations></jaspero-confirmations> -->

</div>
