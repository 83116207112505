<ng-container *ngIf="verification">

    <div class="row bg-light lter b-b wrapper-md" *ngIf="verification">

        <div class="col-md-8">
            <h1 class="m-n font-thin h3">{{ verification.employee.name }} Verification</h1>
            <ul class="breadcrumb insta-breadcrumb">
                <li><a>Home</a></li>
                <li><a>{{ verification.company.name }}</a> </li>
                <li><a>{{ verification.lot.name }}</a></li>
                <li><a>{{ verification.employee.track_id }}</a> </li>
                <li>Created on : {{ verification.created_at }}</li>
            </ul>
        </div>

        <div class="col-md-4 text-right text-center-xs">
            <div class="btn-group">
                <button [disabled]="formSubmitting" (click)="onExitClicked()" class="btn btn-danger"> Exit Task
                </button>
                <button [disabled]="!form.valid || formSubmitting" (click)="onDoneClicked()"
                    class="btn btn-primary">Done</button>
            </div>
        </div>

    </div>


    <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
        <fieldset [disabled]="formSubmitting">


            <div class="wrapper-md">

                <div class="row">
                    <div class="col-md-9">
                        <!--Front Url Panel-->
                        <div class="panel" *ngIf="verification.front_url" style="overflow: hidden;">
                            <div class="row">
                                <div class="col-md-12 identity-edit-image-frame" id="div_front_url">
                                    <img imageZoom style="width: 100%;height: 60%" [src]="verification.front_url"
                                        id="img_front_url" />
                                </div>
                            </div>
                        </div>


                        <!--Back Url Panel-->
                        <div>
                            <div class="panel" *ngIf="verification.back_url" style="overflow: hidden;">
                                <div class="row">
                                    <div class="col-md-12 identity-edit-image-frame" id="div_back_url"
                                        *ngIf="verification.back_url">
                                        <img imageZoom style="width: 100%;height: 60%" [src]="verification.back_url"
                                            id="img_back_url" />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <div class="col-md-3">
                        <div class="panel panel-default">
                            <div class="panel-body">

                                <div class="row">

                                    <div class="col-md-12 form-group">
                                        <label class="center-block">Verification Status</label>
                                        <select class="form-control" formControlName="status_id">
                                            <option *ngFor="let status of statuses" [value]="status.id">{{ status.name
                                                }}</option>
                                        </select>
                                    </div>

                                    <div class="col-md-12 form-group">
                                        <label>Upload Proof</label>
                                        <input type="file" id="proof_url" ng2FileSelect [uploader]="uploader"
                                            class="form-control" [accept]="acceptedFileType"
                                            (change)="upload('proof_url')" [disabled]="formSubmitting" />
                                    </div>

                                    <div class="col-md-12 form-group">
                                        <label class="center-block">Doc Number</label>
                                        <div class="input-group">
                                            <input class="form-control" formControlName="number" readonly
                                                aria-describedby="basic-addon1">
                                            <app-copy-to-clipboard [value]="form.get('number')?.value"
                                                [labelName]="'Document Number'" class="input-group-addon"
                                                id="basic-addon1"></app-copy-to-clipboard>
                                        </div>
                                    </div>

                                    <div class="col-md-12 form-group">
                                        <label class="center-block">DOB</label>
                                        <div class="input-group">
                                            <input class="form-control" formControlName="dob_as_per_id" readonly
                                                aria-describedby="basic-addon2">
                                            <app-copy-to-clipboard [value]="form.get('dob_as_per_id')?.value"
                                                [labelName]="'DOB'" class="input-group-addon"
                                                id="basic-addon2"></app-copy-to-clipboard>
                                        </div>
                                    </div>

                                    <div class="col-md-12 form-group">
                                        <label class="center-block">Client Remarks:
                                            <textarea rows=2 class="form-control"
                                                formControlName="client_remarks"></textarea>
                                        </label>
                                        <small class="text-danger" *ngIf="form.get('client_remarks')?.errors">
                                            Required
                                        </small>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>

</ng-container>