import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';

import { Subscription } from 'rxjs';
import { FilterService } from './../../core/services/filter.service';

import { ExportExcelComponent } from 'src/app/common/export-excel/export-excel.component';
import { Pagination } from 'src/app/core/models/pagination.model';
import { CommonService } from 'src/app/core/services/common.service';
import { InsuranceService } from 'src/app/core/services/insurance.service';

@Component( {
    selector: 'app-insurance-list',
    templateUrl: './insurance-list.component.html',
    styleUrls: [ './insurance-list.component.css' ],
} )
export class InsuranceListComponent extends ExportExcelComponent implements OnInit {

    filterParams: HttpParams = new HttpParams();
    pagination: Pagination = new Pagination();
    loading!: boolean;
    data: any[] = [];
    fetchingInProgress: boolean = false;
    filtersSubscription!: Subscription;

    // Form group associated with the filters
    filterFormGroup!: FormGroup;
    clients!: any[];

    constructor (
        private insuranceService: InsuranceService,

        private commonService: CommonService,
        private filterService: FilterService,
    ) {
        super();
    }

    ngOnInit (): void {

        this.filtersSubscription = this.filterService.filtersChanged[ 'subscribe' ]( ( params: Params ) => {
            this.filterResults( params );
        } );
    }

    filterResults ( params: Params ): void {

        this.insuranceService.filter( params ).subscribe( response => {
            this.pagination = response;
        } );
    }

    showPage ( page: number ): void {

        this.filterService.filterByPage( page );
    }


    resend ( source: any, log: any ): void {

        const data: any = {
            type: source,
            log_id: log.id,
        };

        this.insuranceService.resend( data ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }

    resendAll ( source: any ): void {

        this.insuranceService.resendAll( source, this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( source + ' Request Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            }
        );
    }

    exportLogsExcel (): void {

        this.filterParams = this.filterParams.set( 'unique', 'no' );
        this.getData();
    }

    exportUniqueLogsExcel (): void {

        this.filterParams = this.filterParams.set( 'unique', 'yes' );
        this.getData();
    }


    exportDailyUniqueLogsExcel (): void {

        this.filterParams = this.filterParams.set( 'unique', 'daily' );
        this.getData();
    }

    getData (): void {

        this.fetchingInProgress = true;
        this.insuranceService.getDataForExcel( this.filterFormGroup.value ).subscribe(
            response => {
                this.downloadExcel( response, 'Insurance Logs Excel' );
                this.fetchingInProgress = false;
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' );
            } );
    }

    onSearch (): void {

        this.filterResults( this.filterFormGroup.value );
    }


    refresh (): void {
        this.filterService.filter();
    }
}
