<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form class="form" [formGroup]="filterFormGroup" (ngSubmit)="filterQueriedResults()"
                (keydown.enter)=" $event.preventDefault()">
                <div class="row">
                    <div class="form-group col-md-2">
                        <label class="center-block"> Lot</label>
                        <app-multi-select [options]="lots" [placeholder]="'Select Lots'" [controlName]="'lot_id'"
                            [formGName]="filterFormGroup"></app-multi-select>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block"> Status</label>
                        <select class="form-control" formControlName="lot_status">
                            <option value="0">Active</option>
                            <option value="1">In Active</option>
                        </select>
                    </div>

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="panel-heading">
            <div class="row" *ngIf="pagination.data">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} lots out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">

            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Lot name</th>
                        <th>Verifications</th>
                        <th>Editors</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Cities</th>
                        <th>Assigned To</th>
                        <th>Total</th>
                        <th [appCan]="'admin'">Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let lot of pagination.data |paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">
                        <td>
                            <a class="btn-link text-main text-semibold" [routerLink]="['/lots', lot.id]">
                                {{ lot.name }}
                            </a>
                            <br>
                            <small class="text-muted">
                                Created On: {{ lot.created_at | date:'medium' }}
                            </small>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li *ngIf="lot.identity_verification">Identity verification</li>
                                <li *ngIf="lot.document_verification">Document verification</li>
                                <li *ngIf="lot.education_verification">Education verification</li>
                                <li *ngIf="lot.employment_verification">Employment verification</li>
                                <li *ngIf="lot.reference_verification">Reference verification</li>
                                <li *ngIf="lot.icr_verification">ICR verification</li>
                                <li *ngIf="lot.pcc_verification">PCC verification</li>
                                <li *ngIf="lot.current_address_verification">Current Address verification</li>
                                <li *ngIf="lot.permanent_address_verification">Permanent Address verification</li>
                                <li *ngIf="lot.globaldb_verification">Global Database verification</li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li *ngFor="let user of lot.users.split(' , ')">
                                    {{user}}
                                </li>
                            </ul>
                        </td>

                        <td>{{lot.status}}</td>
                        <td>{{ lot.type }}</td>
                        <td>{{lot.cities}}</td>
                        <td>{{lot.users}}</td>
                        <td>{{lot.employee_count}}</td>
                        <td [appCan]="'admin'">
                            <a class="btn btn-sm btn-default" routerLink="/lots/{{lot.id}}/edit">Edit</a>
                            <br>
                            <a class="btn btn-sm btn-default" routerLink="/lots/{{lot.id}}/merge">Merge</a>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>