<div bsModal #selectFilterModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <form [formGroup]="filterAddForm" (ngSubmit)="updatedFilters()" (keydown.enter)="$event.preventDefault()">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Select Filters</h4>
                </div>
                <div class="panel-body">
                    <fieldset>
                        <div class="row">
                            <div class="col-md-4" formArrayName="filters"
                                *ngFor="let filter of filterControls; let i = index">
                                <fieldset class="form-group">
                                    <div class="list-group">
                                        <label class="list-group-item">
                                            <input type="checkbox" [formControlName]="i" />
                                            {{ filters[i] }}
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal-footer">
                    <div class="pull-right">
                        <button class="btn btn-default btn-primary">
                            Select Filters
                        </button>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>