import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';

import { ApiService } from './api.service';
import { SubInstitute } from '../models/sub-institute.model';
import { Institute } from '../models/institute.model';
import { Params } from '@angular/router';
import { CommonService } from './common.service';

@Injectable( {
    providedIn: 'root',
} )
export class InstituteService {

    institutes: Institute[] = [];
    institutesLoading: boolean = false;

    constructor (
        private api: ApiService,
        private common: CommonService
    ) { }

    populateCache ( force: boolean = false ): void {

        if ( this.institutesLoading ) return;

        if ( force || this.institutes.length === 0 ) {

            this.institutesLoading = true;

            this.getAll().subscribe(

                response => {
                    this.institutes = response;
                    this.institutesLoading = false;
                },
                err => {
                    this.common.notifications.error( 'Error occurred while getting all institutes' );
                    this.institutesLoading = false;
                }
            )
        }
    }

    getAll (): Observable<Institute[]> {
        return this.api.get( 'education/all-institutes' )
    }

    create ( data: {} ): Observable<any[]> {
        return this.api.post( 'education/institute', data );
    }

    getPaginated ( filterParams: Params ): Observable<Pagination> {
        return this.api.get( 'education/institute', filterParams );
    }

    find ( instituteId: number ): Observable<Institute> {
        return this.api.get( 'education/institute/' + instituteId );
    }

    update ( instituteId: number, data: Object | undefined ): Observable<Institute> {
        return this.api.post( 'education/institute/' + instituteId, data );
    }

    getDataForExcel ( data: any ): Observable<any> {
        return this.api.get( 'education/institute-excel' );
    }

}
