import { Injectable } from '@angular/core';
import { ApiService } from './api.service';


import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination.model';
import { Params } from '@angular/router';

@Injectable( {
    providedIn: 'root'
} )
export class CriminalService {

    constructor (
        private api: ApiService
    ) {
    }

    filter ( params: Params | undefined ): Observable<Pagination> {
        return this.api.get( 'criminal', params );
    }

    importExcel ( values: Object ): Observable<any> {
        return this.api.post( 'criminal/import-excel', values );
    }

    searchCriminals ( values: Object ): Observable<any> {
        return this.api.post( 'criminals/search', values );
    }
}

