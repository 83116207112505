<div class="panel panel-default">
    <div class="panel-heading">
        Candidate Documents
        <span style="margin-left: 6px;float: right;">
            <a style="text-transform: unset;" downloadDocument [employee]="employee" documentType="zip"
                [href]="documentLink">Download Zip</a>
        </span>
    </div>
    <div class="panel-body">
        <div *ngIf="employeeDocuments" class="employee-documents">
            <span style="font-weight: bold;text-transform: capitalize;margin-left: 6px;">
                Candidate
            </span>
            <div *ngIf="employeeDocuments.resume_url">
                <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="employeeDocuments.resume_url">
                    Resume
                </a>
            </div>
            <div *ngIf="employeeDocuments.photo">
                <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="employeeDocuments.photo">
                    Photo
                </a>
            </div>
            <div *ngIf="employeeDocuments.report_url">
                <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="employeeDocuments.report_url">
                    Report
                </a>
            </div>
            <app-employee-attachments-documents-list [attachments]="employeeDocuments.attachments"
                [documents]="employeeDocuments.documents"></app-employee-attachments-documents-list>

            <div *ngIf="employeeDocuments.verifications.address_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.address_verifications">
                    <a class="verification-title" [routerLink]="'address'+employee.id+'/edit'">
                        Address Verification - {{verification.type + " ( " + verification.id+" ) "}}
                    </a>
                    <div *ngIf="verification.proof_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.proof_url">
                            Proof
                        </a>
                    </div>

                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>

                </div>
            </div>
            <div *ngIf="employeeDocuments.verifications.identity_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.identity_verifications">
                    <div>
                        <a class="verification-title" [routerLink]="'/identity/'+employee.id+'/edit'">
                            Identity Verification - {{ formatType(verification.type) + " ( " + verification.id+" ) "}}
                        </a>
                    </div>
                    <div *ngIf="verification.front_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.front_url">
                            Front url
                        </a>
                    </div>
                    <div *ngIf="verification.back_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.back_url">
                            Back url
                        </a>
                    </div>
                    <div *ngIf="verification.proof_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.proof_url">
                            Proof
                        </a>
                    </div>
                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>
                </div>
            </div>

            <div *ngIf="employeeDocuments.verifications.icr_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.icr_verifications">
                    <a class="verification-title" [routerLink]="'/icr/'+employee.id+'/edit'">
                        Icr Verification - {{ formatType(verification.type) + " ( " + verification.id+" ) "}}
                    </a>
                    <div *ngIf="verification.front_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.front_url">
                            Front url
                        </a>
                    </div>
                    <div *ngIf="verification.back_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.back_url">
                            Back url
                        </a>
                    </div>

                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>

                    <div *ngIf="verification.cases">
                        <div *ngFor="let case of verification.cases">
                            <span style="font-weight: bold; text-transform: capitalize;">
                                Case {{case.id}}
                            </span>
                            <div class="cases">
                                <div *ngIf="case.attachments">

                                    <div *ngFor="let attachment of case.attachments">
                                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="attachment.url">
                                            {{attachment.name}}
                                        </a>
                                    </div>

                                </div>
                                <div *ngIf="case.documents">
                                    <div *ngFor="let document of case.documents">
                                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="document.url">
                                            {{document.name}}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="employeeDocuments.verifications.education_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.education_verifications">
                    <div>
                        <a class="verification-title" [routerLink]="'/education/'+employee.id+'/edit'">
                            Education Verification - {{verification.type + " ( " + verification.id+" ) "}}
                        </a>
                    </div>
                    <div *ngIf="verification.degree_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.degree_url">
                            Degree
                        </a>
                    </div>
                    <div *ngIf="verification.marksheet_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.marksheet_url">
                            Marksheet
                        </a>
                    </div>
                    <div *ngIf="verification.payment_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.payment_url">
                            Payment
                        </a>
                    </div>
                    <div *ngIf="verification.proof_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.proof_url">
                            Proof
                        </a>
                    </div>

                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>

                </div>
            </div>

            <div *ngIf="employeeDocuments.verifications.employment_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.employment_verifications">
                    <div>
                        <a class="verification-title" [routerLink]="'/employment/'+employee.id+'/edit'">
                            Employment Verification - {{verification.type + " ( " + verification.id+" ) "}}
                        </a>
                    </div>
                    <div *ngIf="verification.proof_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.proof_url">
                            Proof
                        </a>
                    </div>

                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>

                </div>
            </div>

            <div *ngIf="employeeDocuments.verifications.document_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.document_verifications">
                    <div>
                        <a class="verification-title" [routerLink]="'/document/'+employee.id+'/edit'">
                            Document Verification - {{ formatType( verification.type ) + " ( " + verification.id+" ) "}}
                        </a>
                    </div>
                    <div *ngIf="verification.proof_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.proof_url">
                            Proof
                        </a>
                    </div>
                    <div *ngIf="verification.document_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.document_url">
                            Document
                        </a>
                    </div>
                    <div *ngIf="verification.front_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.front_url">
                            Front url
                        </a>
                    </div>
                    <div *ngIf="verification.back_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.back_url">
                            Back url
                        </a>
                    </div>

                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>

                </div>
            </div>

            <div *ngIf="employeeDocuments.verifications.pcc_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.pcc_verifications">
                    <div>
                        <a class="verification-title" [routerLink]="'/pcc/'+employee.id+'/edit'">
                            Pcc Verification - {{verification.type + " ( " + verification.id+" ) "}}
                        </a>
                    </div>
                    <div *ngIf="verification.pcc_report">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.pcc_report">
                            Pcc Report
                        </a>
                    </div>
                    <div *ngIf="verification.payment_screenshot">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o"
                            [href]="verification.payment_screenshot">
                            Payment Screenshot
                        </a>
                    </div>
                    <div *ngIf="verification.payment_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.payment_url">
                            Payment Url
                        </a>
                    </div>
                    <div *ngIf="verification.application_screenshot_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o"
                            [href]="verification.application_screenshot_url">
                            Application Screenshot
                        </a>
                    </div>

                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>

                </div>
            </div>

            <div *ngIf="employeeDocuments.verifications.reference_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.reference_verifications">
                    <div>
                        <a class="verification-title" [routerLink]="'/reference/'+employee.id+'/edit'">
                            Reference Verification- {{verification.type + " ( " + verification.id+" ) "}}
                        </a>
                    </div>
                    <div *ngIf="verification.proof_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.proof_url">
                            Proof
                        </a>
                    </div>


                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>

                </div>
            </div>

            <div *ngIf="employeeDocuments.verifications.global_db_verifications">
                <div *ngFor="let verification of employeeDocuments.verifications.global_db_verifications">
                    <div>
                        <a class="verification-title" [routerLink]="'/global-db/'+employee.id+'/edit'">
                            Global Db Verification - {{verification.type + " ( " + verification.id+" ) "}}
                        </a>
                    </div>
                    <div *ngIf="verification.front_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.front_url">
                            Front url
                        </a>
                    </div>
                    <div *ngIf="verification.back_url">
                        <a class="btn-link btn-sm text-info fa fa-file-image-o" [href]="verification.back_url">
                            Back url
                        </a>
                    </div>
                    <app-employee-attachments-documents-list [attachments]="verification.attachments"
                        [documents]="verification.documents"></app-employee-attachments-documents-list>
                </div>
            </div>

        </div>
    </div>
</div>