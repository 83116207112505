import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { EmploymentService } from '../../core/services/employment.service';


@Component( {
    selector: 'app-employment-send-mail',
    templateUrl: './employment-send-mail.component.html',
    styleUrls: [ './employment-send-mail.component.css' ],
} )
export class EmploymentSendMailComponent implements OnInit {

    @Input() verification!: FormGroup | any;
    @Input() attachments!: any;
    @Input() employeeDocuments !: any;

    isReadOnly: boolean = false;
    allAttachments: any[] = [];

    verificationId!: number | any;
    companyId!: number | any;
    data!: FormGroup;
    contacts!: any[];
    to: string | null = null;
    cc: string | null = null;
    bcc: string | null = null;
    contents!: string | null;
    checkedAttachments: any[] = [];

    @ViewChild( 'sendMail', { static: false } ) sendMail!: ModalDirective;

    @Output()
    onEmailSent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private employmentService: EmploymentService,
        private commonService: CommonService,
        private fb: FormBuilder,
        public bsModalRef: BsModalRef,
    ) {
        this.initForm();
    }

    ngOnInit (): void {

        this.verificationId = this.verification.get( 'id' )?.value;
        this.companyId = this.verification.get( 'employment_company_id' )?.value;

        this.initForm();
        this.fetchContacts();
        this.fetchHtml();
        this.show();
    }

    // Making the field values blank.
    initForm (): void {

        this.data = this.fb.group( {
            contents: [],
            to: [],
            cc: [],
            bcc: [],
            employmentId: this.verificationId,
            email_attachments: [],
        } );

        // to make the documents attachment array empty on modal show.
        // for ( const attachment of this.allAttachments ) {
        //     attachment.is_checked = false;
        // }

    }

    show (): void {

        // TODO:: need improvement remove consent form from documents as consent is already present in employeeDocument
        this.attachments[ 'documents' ].forEach( ( element: { file_name: string; }, index: any ) => {
            if ( element.file_name === 'consent_form' ) {
                this.attachments[ 'documents' ].splice( index, 1 );
            }
        } );

        this.allAttachments = this.attachments[ 'offers' ].concat( this.attachments[ 'documents' ], this.attachments[ 'payslips' ], this.attachments[ 'relievings' ] );

    }

    fetchContacts () {

        this.employmentService.getContacts( this.verificationId, this.companyId ).subscribe(
            response => {
                this.contacts = response;
            }
        );
    }

    fetchHtml () {

        this.employmentService.getHtml( this.verificationId ).subscribe(
            ( response: any ) => {

                this.contents = response[ 'content' ];
                this.data.get( 'contents' )?.setValue( response[ 'content' ] );
            }
        );
    }


    hide (): void {
        this.bsModalRef.hide();
    }


    checkAll ( ev: any ): void {
        if ( ev.target.checked ) {
            for ( let i: number = 0; i < this.allAttachments.length; i++ ) {
                ( document.getElementsByClassName( 'checkbox-all' )[ i ] as HTMLInputElement ).checked = true;
                this.checkedAttachments.push( this.allAttachments[ i ].id );
            }
        } else {
            for ( let i = 0; i < this.allAttachments.length; i++ ) {
                ( document.getElementsByClassName( 'checkbox-all' )[ i ] as HTMLInputElement ).checked = false;
                this.checkedAttachments = [];
            }
        }
    }

    /**
     * get values of the checkboxes and push them to an array
     * @param option
     * @param data
     */
    getValues ( option: any, data: any ): void {
        if ( data.target.checked ) {
            this.checkedAttachments.push( option );
        } else {
            const index: number = this.checkedAttachments.indexOf( option );
            if ( index !== -1 ) {
                this.checkedAttachments.splice( index, 1 );
            }
        }
        this.data.get( 'email_attachments' )?.setValue( this.checkedAttachments );
    }


    sendMailRequest (): void {

        this.employmentService.sendMail( this.data.value ).subscribe(
            ( response: any ) => {

                this.commonService.notifications.success( 'Success', response[ 'message' ] );

                this.verificationId = null;
                this.companyId = null;
                this.onEmailSent.emit( true );
                this.hide();

            },
            error => {
                this.commonService.notifications.error( 'Error', 'Unable to send mail' );

                this.hide();
            }
        );
    }


}
