<div class="bg-light lter b-b wrapper-md" xmlns="http://www.w3.org/1999/html">
    <div class="row">
        <div class="col-md-10">
            <h1 class="m-n font-thin h3">Client Api List</h1>
        </div>
    </div>
</div>

<div class="wrapper-md">
    <div class="row">
        <div class="col-lg-6">
            <a class="btn btn-primary btn-addon" (click)="clientsApiCreate.show()">
                <i class="fa fa-plus"></i>
                Create Client Api
            </a>
        </div>
    </div>
</div>

<app-vehical-rc-settings #vehicalRcSettings></app-vehical-rc-settings>
<app-client-api-create #clientsApiCreate (onClientApiCreated)="onModalUpdated()"></app-client-api-create>
<app-client-api-edit #clientsApiEdit (onClientApiUpdated)="onModalUpdated()"></app-client-api-edit>
<app-client-api-edit-ip #clientsApiEditIp></app-client-api-edit-ip>
<app-client-api-credentials #clientsApiCredentials></app-client-api-credentials>



<div *ngIf="pagination.data" class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-6 panel-title">
                    Showing {{ pagination.data.length }} out of total results {{ pagination.total }}
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table b-t b-light">
                <thead>
                    <tr>
                        <th>Owner/Company Name</th>
                        <th>Quota</th>
                        <th>IP Addresses</th>
                        <th>Status</th>
                        <th>Vehicle Rc</th>
                        <th>Email</th>
                        <th>Edit Credentials</th>
                        <th>Edit IP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let api of pagination.data |paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i = index">
                        <td>
                            Id :- {{api.id}}<br>
                            Owner :- {{ api.owner_type }} <br>

							<span *ngIf="api.owner_type=='Vendor'">
								Vendor: {{ api.owner_name }} <br>
							</span>
							<span *ngIf="api.owner_type=='Company'" routerLink="/companies/{{api.owner_id}}">
								Company: {{ api.owner_name }}<br>
							</span>

                            <span *ngIf="api.owner_type=='Company'" routerLink="/lots/{{api.lot_id}}">
                                Lot: {{ api.lot_name }} <br>
                            </span>

                            Updated On: {{ api.updated_at }} <br>
                            Created On: {{ api.created_at }}
                        </td>
                        <td>
                            <span *ngFor="let limit of api.limits | keyvalue">
                                {{limit.key}}: {{limit.value}} <br>
                            </span>
                        </td>
                        <td>
                            <li *ngFor="let ip of api.ips">{{ ip }}</li>
                        </td>
                        <td>
                            <div *ngIf="api.is_active" class="alert" role="alert">Active</div>
                            <div *ngIf="!api.is_active" class="alert" role="alert">Disabled</div>
                        </td>
                        <td>
                            <button type="button" class="btn btn-default" (click)="vehicalRcSettings.show(api.id);">
                                Add Fields
                            </button>
                        </td>
                        <td>
                            <button type="button" class="btn btn-default" (click)="clientsApiCredentials.show(api);">
                                Send Email
                            </button>
                        </td>
                        <td>
                            <button type="button" class="btn btn-default" (click)="clientsApiEdit.show(api)">
                                Edit
                            </button>
                        </td>
                        <td>
                            <button type="button" class="btn btn-default" (click)="clientsApiEditIp.show(api.id)">
                                Edit IP
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>
