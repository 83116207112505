import { Pipe, PipeTransform } from '@angular/core';
import { District } from '../core/models/district.model';
import { Lot } from '../core/models/lot.model';
import { CommonService } from '../core/services/common.service';
import { Sections } from '../core/models/sections.model';
import { SubInstitute } from '../core/models/sub-institute.model';
import { Authority } from '../core/models/authority.model';

@Pipe( {
    name: 'filterByStateId',
} )
export class StateIdPipe implements PipeTransform {

    transform ( districts: District[] = [], state_id: number ): District[] {

        return districts.filter( item => {

            if ( item.state_id == state_id ) {
                return true;
            }

            return false;
        } );
    }

}

@Pipe( {
    name: 'filterByDistrictId',
} )
export class DistrictIdPipe implements PipeTransform {

    transform ( authorities: Authority[] = [], state_id: number ): Authority[] {

        return authorities.filter( item => {

            if ( item.district_id == state_id ) {
                return true;
            }

            return false;
        } );
    }

}

@Pipe( {
    name: 'filterByActId',
} )
export class FilterByActIdPipe implements PipeTransform {

    transform ( sections: Sections[] = [], act_ids: any ): any[] {

        const filteredSections: any[] = [];

        act_ids.forEach( ( act: number ) => {

            sections.filter( item => {

                if ( item.act_id == act ) {

                    filteredSections.push( item );

                    return true;
                }

                return false;
            } );

        } );

        return filteredSections;

    }

}

@Pipe( {
    name: 'filterByCompanyId',
} )
export class FilterByCompanyIdPipe implements PipeTransform {

    response: any[] = [];

    constructor ( private commonService: CommonService ) { }

    transform ( lots: Lot[] = [], company_id: number ): Lot[] {

        this.commonService.getLotsByCompanyId( company_id ).subscribe(
            response => this.response = response
        );

        return this.response;
    }
}

@Pipe( {
    name: 'inArray',
} )
export class InArrayPipe implements PipeTransform {

    transform ( needle: any, items: any[] ): boolean {

        let contains = false;

        // Return true if the value is present in the array
        for ( let i = 0, l = items.length; i < l; i++ ) {
            if ( items[ i ] == needle ) {

                contains = true;
            }
        }

        return contains;
    }

}

@Pipe( {
    name: 'filterByInstituteId',
} )
export class FilterByInstituteIdPipe implements PipeTransform {

    transform ( subInstitutes: SubInstitute[] = [], institute_id: number ): any[] {

        return subInstitutes.filter( item => {

            if ( item.institute_id == institute_id ) {

                return true;
            }

            return false;
        } );

    }

}
