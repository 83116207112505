<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Import Excel file</h1>
</div>

<div class="wrapper-md">
    <div class="panel panel-default">
        <div class="panel-body">
            <div class="row">
                <div class="col-md-4">
                    <form [formGroup]="uploadForm" (keydown.enter)="$event.preventDefault()">
                        <div class="form-group">
                            <input type="file" (change)="onFileChange($event)" [accept]="acceptedFileType"
                                class="form-control" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="wrapper-md" *ngIf="excelForm">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-10 panel-title">
                    Imported Rows are as follows
                </div>
                <div class="col-md-2 text-right text-center-xs">
                    <button type="button" (click)="importExcel()" [disabled]="!excelForm.valid"
                        class="btn btn-primary">Import
                        Excel
                    </button>
                </div>
            </div>
        </div>
        <div class="panel-body">
            <form [formGroup]="excelForm" (keydown.enter)="$event.preventDefault()">
                <fieldset [disabled]="isSubmitting">

                    <div class="row">
                        <div class="col-md-2 form-group">
                            <label class="center-block">Filter by Client</label>
                            <select class="form-control" formControlName="client_api_id">
                                <option *ngFor="let client of clients" [value]="client.id">
                                    {{ client.owner_name }}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-2 form-group">
                            <label class="center-block">Excel Type</label>
                            <select class="form-control" formControlName="excel_type">
                                <option value="rc_number">Rc Number</option>
                                <option value="chassis_number">Chassis</option>
                                <option value="engine_number">Engine</option>
                            </select>
                        </div>
                        <div class="col-md-8 form-group">
                            <div class="row">

                                <div class="col-md-6">
                                    <fieldset class="form-group">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" value="vahan"
                                                (change)="onCheckboxChange($event,'vahan')">
                                            Via Vahan
                                        </label>
                                    </fieldset>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div style="max-width: 100%;overflow-x: scroll">
                        <table class="table table-bordered" formArrayName="excelFormArray">
                            <tr>
                                <th *ngFor="let title of originalHeaders">
                                    {{title}}
                                </th>
                            </tr>
                            <tr *ngFor="let row of excelFormArray.controls;let i=index" [formGroupName]="i">
                                <td *ngFor="let header of headerRaw;let k=index">
                                    <div>
                                        <input formControlName="{{header}}"><br>
                                        <small class="text-danger" [hidden]="row.get(header)?.valid">
                                            {{validationMessages[header]}}
                                        </small>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>