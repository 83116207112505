<div class="wrapper-md">
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Forget Password
                </div>

                <div class="panel-body">
                    <form [formGroup]="forgetPasswordForm" (ngSubmit)="submitForm()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Email</label>
                                <input id="email" formControlName="email" class="form-control" type="email"
                                    placeholder="Email" />
                            </fieldset>
                            <button id="login" class="btn btn-lg btn-primary btn-block"
                                [disabled]="!forgetPasswordForm.valid" type="submit">
                                Reset Password
                            </button>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
