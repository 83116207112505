import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { Webhook } from '../../core/models/webhook.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { WebhookService } from '../../core/services/webhook.service';
import { CommonService } from '../../core/services/common.service';

@Component( {
    selector: 'app-webhook-settings',
    templateUrl: './webhook-settings.component.html',
    styleUrls: [ './webhook-settings.component.css' ],
} )
export class WebhookSettingsComponent {

    isSubmitting = false;
    webhook: Webhook = new Webhook();
    client_apis!: any[];

    webhookSettingsForm: FormGroup;

    @ViewChild( 'webhookSettingsModal', { static: false } )
    public webhookSettingsModal!: ModalDirective;

    @Output()
    onWebhookSettingsChanged = new EventEmitter<boolean>();

    constructor (
        private commonService: CommonService,
        private fb: FormBuilder,
        private webhookService: WebhookService
    ) {
        this.webhookSettingsForm = this.fb.group( {
            settings: this.fb.array( [] ),
        } );
    }

    show ( webhook: Webhook ) {
        this.webhook = webhook;
        this.populateForm();
        this.webhookSettingsModal.show();

    }

    get formArray (): FormArray {
        return this.webhookSettingsForm.get( 'settings' ) as FormArray;
    }

    populateForm () {
        this.webhookSettingsForm.patchValue( this.webhook );
        const settings = this.webhook.settings;
        const webhookFormGroup = settings.map( item => this.fb.group( item ) );
        const webhookFormGroupArray = this.fb.array( webhookFormGroup );
        this.webhookSettingsForm.setControl( 'settings', webhookFormGroupArray );
    }

    hide () {
        this.webhookSettingsModal.hide();
    }


    updateWebhookSettings () {
        this.webhookService.updateSettings( this.webhook.id, this.webhookSettingsForm.value ).subscribe(
            ( response: any ) => {
                this.commonService.notifications.success( 'Success', response[ 'message' ] );
                this.hide();
                this.onWebhookSettingsChanged.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.hide();
            }
        );
    }


    addNewRow () {
        const control = <FormArray> this.webhookSettingsForm.controls[ 'settings' ];

        control.push( this.fb.group( {
            event: [ '' ],
            webhook_url_id: [ this.webhook.id ],
        } ) );

    }

}
