import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';


import { Pagination } from '../models/pagination.model';
import { ClientForms, CreateFormRequest, Documents, Education, Employment, FormV2, FormV2StatusType, GlobalDb, Icr, Identities, Pcc, Reference, Settings } from '../models/client-forms.model';


@Injectable( {
    providedIn: 'root'
} )
export class ClientFormsService {

    constructor ( private api: ApiService ) {
    }

    getAll ( params: Params ): Observable<Pagination> {
        return this.api.get( 'forms', params );
    }

    find ( id: number ): Observable<FormV2> {
        return this.api.get( 'forms/' + id );
    }

    update ( id: number, values: FormV2 ) {
        return this.api.put( 'forms/' + id, values );
    }

    create ( data: CreateFormRequest ) {
        return this.api.post( 'forms', data );
    }

    previewInviteEamil ( params: Params ): Observable<any> {
        return this.api.get( 'forms/preview-invite-email', params );
    }

    delete ( id: number ): Observable<ClientForms> {
        return this.api.delete( 'forms/' + id );
    }

    getDeleted ( params: Params ): Observable<Pagination> {
        return this.api.get( 'forms/deleted', params );
    }

    restore ( id: number ): Observable<ClientForms> {
        return this.api.get( 'forms/' + id + '/restore' );
    }

    permanentDelete ( id: number ): Observable<ClientForms> {
        return this.api.delete( 'forms/' + id + '/permanent-delete' );
    }

    getStatuses () {

        return Object.keys( FormV2StatusType ).map( key => (
            {
                id: Number( FormV2StatusType[ key as keyof typeof FormV2StatusType ] ),
                name: key
            }
        ) );
    }

    getFormStatusLabel ( formStatus: string ): string | undefined {
        return Object.entries( FormV2StatusType ).find( item => item[ 1 ] == formStatus )?.[ 0 ];
    }

    isIdentitySelected ( identity: Identities ): boolean {
        return identity.verifications.length > 0;
    }

    isCurrentAddressSelected ( address: any ): boolean {
        return address.verifications.find( ( address_type: { type: string; } ) => address_type.type == 'current' );
    }

    isPermanentAddressSelected ( address: any ): boolean {
        return address.verifications.find( ( address_type: { type: string; } ) => address_type.type == 'permanent' );
    }

    isAnyAddressSelected ( address: any ): boolean {
        return address.verifications.find( ( address_type: { type: string; } ) => address_type.type == 'any' );
    }

    isEducationSelected ( education: Education ): boolean {
        return education.verifications.length > 0;
    }

    isEmploymentSelected ( employment: Employment ): boolean {
        return employment.verifications.length > 0;
    }

    isIcrSelected ( icr: Icr ): boolean {
        return icr.verifications.length > 0;
    }

    isPccSelected ( pcc: Pcc ): boolean {
        return pcc.verifications.length > 0;
    }

    isGlobalDbSelected ( global_db: GlobalDb ): boolean {
        return global_db.verifications.length > 0;
    }

    isReferenceSelected ( reference: Reference ): boolean {
        return reference.verifications.length > 0;
    }

    isDocumentSelected ( document: Documents ): boolean {
        return document.verifications.length > 0;
    }
}
