<div [ngSwitch]="verification">
    <div *ngSwitchCase="'identity'">
        <app-auto-assign-identity></app-auto-assign-identity>
    </div>
    <div *ngSwitchCase="'address'">
        <app-auto-assign-address></app-auto-assign-address>
    </div>
    <div *ngSwitchDefault>
        <h1>No verification selected</h1>
    </div>
</div>