<div bsModal #workAssignModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left" *ngIf="user">Assign work to {{user.name}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <select class="form-control" [(ngModel)]="type_id" required>
                    <option *ngFor="let type of types" [value]="type.id">
                        {{type.title}}
                    </option>
                </select>
            </div>

            <div class="modal-footer">
                <div class="pull-right">
                    <button class="btn btn-default" (click)="assignWork('reassign')"> Re-Assign</button>
                    <button class="btn btn-default" (click)="assignWork('assign')"> Assign</button>
                </div>
                <div class="pull-left">
                    <button class="btn btn-default" (click)="hide()"> Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>