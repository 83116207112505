<div class="panel panel-default" [formGroup]="addressForm">
    <div class="panel-heading">
        Address Verification

        <h4 class="panel-title pull-right">

            <div class="accordion-toggle" data-toggle="collapse" data-parent="#accordion">
                <span class="glyphicon">
                    <input type="checkbox" (click)="sectionToggle($event)" [checked]="showSection"></span>
            </div>
        </h4>
    </div>
    <div id="collapse-address" [className]="showSection ? 'panel-collapse collapse in' : 'panel-collapse collapse'">
        <div class="panel-body">
            <div class="col-md-12 margin-bottom-10" *ngIf="!isDigitalForm">
                <label>Instructions </label>
                <textarea formControlName="instructions" class="form-control"></textarea>
            </div>

            <div class="col-md-5">
                <button type="button" (click)="addAddress()" class="btn btn-primary">Add Address</button>
            </div>

            <div class="col-md-7 margin-bottom-10 text text-danger" *ngIf="addressVerificationArray.length==0">
                Please select atleast one verification
            </div>

            <div class="col-md-12" *ngIf="addressVerificationArray.length > 0 && !isDigitalForm">
                <hr style="border-top: dashed 1px lightgray; margin-bottom: 0px; margin-top: 16px;" />
            </div>

            <div class="col-md-12"
                *ngFor="let addressFormItem of addressVerificationArray.controls;let verificationIndex=index;">

                <ng-container [formGroup]="addressFormItem">

                    <div class="row">
                        <div class="col-md-12" style="margin-top:1rem">
                            <strong>Address {{verificationIndex + 1}}</strong>
                        </div>
                    </div>

                    <div class="margin-bottom-20" style="padding-top: 1rem;">
                        <label>Instructions </label>
                        <textarea formControlName="instructions" class="form-control"></textarea>
                    </div>

                    <div class="row">

                        <div class="col-md-2" *ngIf="!isDigitalForm">
                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="required"
                                    (change)="onRequiredChecked($event,addressFormItem)"> Is Required
                            </label>
                        </div>

                        <div class="col-md-2" *ngIf="!isDigitalForm">

                            <label class="checkbox-inline">
                                <input type="checkbox" formControlName="is_digital"
                                    (change)="onDigitalChecked($event,addressFormItem)"> Is Digital
                            </label>
                        </div>

                        <div [ngClass]="isDigitalForm ? 'col-md-12' : 'col-md-7'">
                            <select formControlName="type" class="form-control"
                                (change)="skipSelectedAddress(verificationIndex,$event)">
                                <option [disabled]="selectedAddress.includes(address.value)"
                                    *ngFor="let address of addressList" [value]="address.value">
                                    {{address.name}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-1" style="padding-left: 20px;" *ngIf="!isDigitalForm">
                            <button type="button" (click)="onAddressTypeDeleted(verificationIndex)" class="btn">
                                <i class="fa fa-trash text text-danger" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div class="col-md-12" [ngClass]="{'padding-top-0-6': isDigitalForm}" style="margin-top:6px;"
                            *ngIf="addressFormItem.get('is_digital').value">

                            <button type="button" (click)="addDocument(verificationIndex)"
                                class="btn btn-primary btn-sm">
                                Add Document
                            </button>
                        </div>

                        <div class="col-md-12 text text-danger"
                            style="margin-top:6px; margin-bottom:8px; min-height: 30px"
                            *ngIf="addressFormItem.get('is_digital').errors">
                            Please enable digital verification for digital form
                        </div>

                        <div class="col-md-12"
                            *ngFor="let documentForm of addressFormItem.get('documents').controls;let documentIndex=index;">

                            <div [formGroup]="documentForm">

                                <div class="col-md-12 padding-left-none" style="padding-bottom:8px">

                                    <div class="col-md-2" style="padding-top: 6px; padding-left: 0px !important;">
                                        <strong>Document {{documentIndex + 1}}</strong>
                                    </div>

                                    <div class="col-md-3" style="padding-top: 4px;">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" formControlName="front_required">Is Front Required
                                        </label>
                                    </div>
                                    <div class="col-md-3" style="padding-top: 4px;">
                                        <label class="checkbox-inline">
                                            <input type="checkbox" formControlName="back_required">Is Back Required
                                        </label>
                                    </div>
                                    <div class="col-md-3">
                                        <select formControlName="type" class="form-control" style="width: 20rem;"
                                            (change)="skipSelectedDocument(documentIndex,$event)">
                                            <option [disabled]="selectedDocument.includes(document.value)"
                                                *ngFor="let document of identityList" [value]="document.value">
                                                {{document.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-1" style="padding-left: 4.5rem;">
                                        <button type="button"
                                            (click)="onDocumentTypeDeleted(addressFormItem, documentIndex)"
                                            class="btn"><i class="fa fa-trash text text-danger"
                                                aria-hidden="true"></i></button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-12" *ngIf="verificationIndex == 0 && addressVerificationArray.length > 1">
                            <hr style="border-top: dashed 1px lightgray; margin-bottom: 0px; padding-top: 12px;" />
                        </div>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>