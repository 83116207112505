import { Component, OnInit, Input } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { DocumentCropperService } from 'src/app/core/services/document-cropper.service';

@Component( {
    selector: 'app-document-cropper',
    templateUrl: './document-cropper.component.html',
    styleUrls: [ './document-cropper.component.css' ],
} )
export class DocumentCropperComponent implements OnInit {

    @Input() verification!: string;

    @Input() document!: string;

    @Input() documentType!: string;

    @Input() verificationId!: number;


    transform: ImageTransform = {};


    imageCropper!: ImageCropperComponent;

    imageChangedEvent: any = '';
    croppedImage: any = '';
    scale: number = 1;
    canvasRotation: number = 0;

    fetchingInProgress: boolean = true;

    constructor ( private documentCropperService: DocumentCropperService ) {


    }

    ngOnInit () {
        this.fetchingInProgress = true;
        this.initImageTransformations();

    }

    initImageTransformations (): void {
        this.canvasRotation = 0;
        this.scale = 1;
        this.transform = {};
    }

    fileChangeEvent ( event: any ): void {
        this.imageChangedEvent = event;
    }

    imageCropped ( event: ImageCroppedEvent, imageType: any ): void {


        const data: any = {
            image: event.base64,
            type: imageType,
        };

        this.documentCropperService.imageCropped( data );

    }


    cropped (): void {

    }


    rotateLeft (): void {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight (): void {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate (): void {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH,
        };
    }


    flipHorizontal (): void {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH,
        };
    }

    flipVertical (): void {

        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV,
        };

    }

    zoomOut (): void {

        this.scale -= .1;

        this.transform = {
            ...this.transform,
            scale: this.scale,
        };

    }

    zoomIn (): void {

        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };

    }

    imageLoaded (): void {
        // show cropper
    }
    cropperReady (): void {
        this.fetchingInProgress = false;
    }
    loadImageFailed (): void {
        this.fetchingInProgress = false;
    }

}
