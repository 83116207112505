import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component( {
    selector: 'app-consent-form',
    templateUrl: './consent-form.component.html',
} )
export class ConsentFormComponent implements OnInit {

    @Input() settingFormGroup!: FormGroup;

    constructor ( private fb: FormBuilder, ) {
    }

    consentForm = this.fb.group( {
        instructions: this.fb.control( null ),
        signature_required: this.fb.control( true ),
    } )

    ngOnInit (): void {
        this.settingFormGroup.addControl( 'consent', this.consentForm );
    }

}
