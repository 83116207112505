import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';

import { CommonService } from '../../core/services/common.service';
import { SmsService } from '../../core/services/sms.service';

@Component( {
    selector: 'app-sms-filters',
    templateUrl: './sms-filters.component.html',
    styleUrls: [ './sms-filters.component.css' ],
} )
export class SmsFiltersComponent implements OnInit {

    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    clientApis!: any[];

    constructor (
        private router: Router,

        private fb: FormBuilder,
        private commonService: CommonService,
        private smsService: SmsService,
    ) {
        this.initForm();
    }

    ngOnInit () {
        this.setDefault();

        let params = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clientApis = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );

        this.filterResults();
    }

    private setDefault () {

        this.filterParams.keys().forEach( key => {

            const value = this.filterParams.get( key );

            if ( value !== '' && value !== 'all' ) {
                this.filterFormGroup.get( key )?.setValue( value );
            }
        } );
    }

    initForm (): void {
        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            query: 'all',
            client_api_id: 'all',
            via: 'all',
            created_before: 'all',
            created_after: 'all',
            sms_status: 'all',
            status: 'all',
            page: 1,
        } );
    }

    filterResults (): void {

        this.filterFormGroup.get( 'page' )?.setValue( 1 );

        // Set our navigation extras object
        // that contains our global query params and fragment
        const navigationExtras: NavigationExtras = {
            queryParams: this.filterFormGroup.value,
            queryParamsHandling: 'merge',
        };
        // Get the current url string & split it into 2 part by '?'
        // Get only the first part which is the path
        // eg. 'identity?xyx=abc' in url will return only 'identity'
        const currentUrlPath = this.router.url.split( '?' )[ 0 ];
        // Navigate to current path along with our set query string
        // This will add query params to the urls
        this.router.navigate( [ currentUrlPath ], navigationExtras );
    }

    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    resendAll () {
        this.smsService.resendAll( this.filterParams ).subscribe(
            response => {
                this.commonService.notifications.success( ' Sms Resend' );
            },
            error => {
                this.commonService.notifications.error( 'Some Error Occurred' )
            }
        );
    }

}
