import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../core/services/common.service';
import { GstService } from '../../core/services/gst.service';

@Component( {
    selector: 'app-gst-search-modal',
    templateUrl: './gst-search-modal.component.html',
    styleUrls: [ './gst-search-modal.component.css' ],
} )
export class GstSearchModalComponent {

    isSubmitting: boolean = false;
    searchGstForm!: FormGroup;

    @ViewChild( 'gstSearch', { static: false } )
    public gstSearch!: ModalDirective;

    @Output()
    onSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor (
        private gstService: GstService,
        private commonService: CommonService,
        private fb: FormBuilder
    ) {
        this.initForm();
    }

    initForm (): void {
        this.searchGstForm = this.fb.group( {
            gst_number: [ '', [ Validators.required, Validators.pattern( '[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9A-Z]{1}$' ) ] ],
        } );
    }

    show (): void {
        this.gstSearch.show();
    }

    hide (): void {
        this.initForm();
        this.gstSearch.hide();
    }

    search (): void {
        this.isSubmitting = true;

        this.gstService.search( this.searchGstForm.value ).subscribe(
            response => {
                this.commonService.notifications.success( 'Request Sent Successfully' );
                this.isSubmitting = false;
                this.hide();
                this.onSearch.emit( true );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
                this.hide();
            }
        );
    }

}
