import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { FilterService } from './../../core/services/filter.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component( {
    selector: 'app-insurance-filters',
    templateUrl: './insurance-filters.component.html',
    styleUrls: [ './insurance-filters.component.css' ],
} )
export class InsuranceFiltersComponent implements OnInit {

    @Input()
    filterParams!: HttpParams;
    all: string = 'all';
    // Form group associated with the filters
    filterFormGroup!: FormGroup;

    clients!: any[];

    constructor (

        private fb: FormBuilder,
        private commonService: CommonService,
        private filterService: FilterService,
    ) {

    }

    ngOnInit (): void {

        this.initForm();

        this.updateFormFilter();

        this.getAllClientApis();

        this.filterResults();
    }


    initForm (): void {

        const myDate: Date = new Date();

        const previousMonth: Date = new Date( myDate );

        previousMonth.setMonth( myDate.getMonth() - 1 );

        previousMonth.setDate( myDate.getDate() + 1 );

        // Set default values for form filter
        // 'all' values will return
        this.filterFormGroup = this.fb.group( {
            rc_number: 'all',
            client_api_id: 'all',
            via: 'all',
            created_before: 'all',
            created_after: previousMonth.toJSON().slice( 0, 10 ),
            sms_status: 'all',
            vahan_status: 'all',
            state_status: 'all',
            parivahan_status: 'all',
            parivahan_insurance_status: 'all',
            page: 1,
        } );
    }

    getAllClientApis (): void {

        let params: HttpParams = new HttpParams();

        params = params.set( 'type', 'company' );

        this.commonService.getAllClientApis( params ).subscribe(
            response => {
                this.clients = response;

            },
            error => {
                this.commonService.notifications.error( 'Some Error Occured' );
            }
        );
    }


    updateFormFilter (): void {

        this.filterService.updateFormFilters( this.filterFormGroup );

    }


    filterResults (): void {

        this.filterService.filterByFormGroup( this.filterFormGroup );
    }


    // Reset the form to its initial state with default value
    // And then filter results
    resetForm (): void {
        this.initForm();
        this.filterResults();
    }

    onSearch (): void {
        this.filterResults();
    }
}
