import { Component } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component( {
    selector: 'app-verification-common-edit',
    template: ' ',
} )
export class VerificationCommonEditComponent {
    maxFileSize: number = 300;
    maxFileError: boolean = false;

    constructor ( private CommonService: CommonService ) { }

    /**
     *
     * @param inputEl
     * @param fileCount
     * @deprecated Use via upload.service instead
     */
    checkFileSize ( inputEl: any, fileCount: number ): void {

        let fileSize: number = 0;

        for ( let i: number = 0; i <= fileCount; i++ ) {

            if ( inputEl.files[ i ] ) {

                fileSize = inputEl.files[ i ].size / 1024;

                if ( fileSize > this.maxFileSize ) {

                    this.maxFileError = true;

                    this.CommonService.notifications.error(
                        'Unable to upload!! File size must be less than 300KB'
                    );

                    alert( 'Unable to upload!! File size must be less than 300KB' );
                }
            }
        }
    }


}
