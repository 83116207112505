<div bsModal #getPayloadModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-xlg" style="width: 850px; margin:auto;">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title pull-left">Payload</h2>
            </div>

            <div class="panel-body">
                <pre>{{ payload?.data | json }}</pre>
            </div>
            <div class="modal-footer">
                <div class="pull-right">
                    <button class="btn btn-default btn-primary" (click)="hide()">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>