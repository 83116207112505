<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Webhooks</h1>
</div>

<app-webhook-create #createWebhookModal (onWebhookCreated)="onModalUpdated()"></app-webhook-create>
<app-webhook-edit #editWebhookModal (onWebhookEdited)="onModalUpdated()"></app-webhook-edit>
<app-webhook-settings #webookSettingsModal (onWebhookSettingsChanged)="onModalUpdated()"></app-webhook-settings>
<app-create-sub-url-modal #webhookSubUrlCreateModal (onWebhookSettingsChanged)="onModalUpdated()">
</app-create-sub-url-modal>
<app-webhook-sub-url-list #webhookSubUrlListModal></app-webhook-sub-url-list>

<div class="row" style="padding: 10px 0px 0px 37px" [appCan]="'superadmin'">

    <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createWebhookModal.show()">
        <i class="fa fa-plus"></i>Create Webhook</a>

</div>

<div class="wrapper-md" [appCan]="'superadmin'">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="row">
                <div class="col-md-6 panel-title" *ngIf="pagination.data">
                    Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
                </div>
                <div class="col-md-6 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th style="width: 20%">ID</th>
                        <th style="width: 35%">Details</th>
                        <th style="width: 20%">Status</th>
                        <th style="width: 15%">Timeline</th>
                        <th style="width: 10%">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let log of pagination.data| paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }">

                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    # {{log.id}}
                                </li>
                                <li>
                                    {{log.company_name}}
                                </li>
                                <li>
                                    <a class="btn-link" routerLink="/webhooks/{{log.id}}">
                                        <strong>
                                            Show logs
                                        </strong>
                                    </a>
                                    <br>
                                    <a class="btn-link" (click)="webhookSubUrlListModal.show(log)">
                                        <strong>See All Sub-URLs</strong>
                                    </a>
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    URL: {{log.url}}
                                </li>
                            </ul>

                            <ul class="list-unstyled" *ngFor="let item of log.headers">
                                <li>
                                    {{item.header}} : {{item.value}}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul class="list-unstyled">
                                <li>
                                    Status: {{log.status_name}}
                                </li>
                            </ul>
                            <ul class="list-unstyled" *ngFor="let setting of log.settings">
                                <li>
                                    {{setting.event}}
                                </li>
                            </ul>
                        </td>

                        <td>
                            <ul class="list-unstyled">
                                <li> {{log.created_at}}</li>
                                <li> {{log.updated_at}}</li>
                            </ul>
                        </td>
                        <td>

                            <a class="btn m-b-xs btn-sm btn-primary btn-addon btn-block"
                                (click)="editWebhookModal.show(log)">
                                Edit</a>

                            <a class="btn m-b-xs btn-sm btn-primary btn-addon btn-block"
                                (click)="webookSettingsModal.show(log)">
                                Settings</a>

                            <a class="btn m-b-xs btn-sm btn-primary btn-addon btn-block"
                                (click)="webhookSubUrlCreateModal.show(log)">
                                Add SubUrl</a>

                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                </div>
            </div>
        </footer>
    </div>
</div>