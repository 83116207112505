<div class="wrapper-md">
    <div class="row">
        <div class="col-md-6 col-md-offset-3">
            <div class="panel panel-default">
                <div class="panel-heading">
                    Reset Password
                </div>

                <div class="panel-body">
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="submitForm()">
                        <fieldset [disabled]="isSubmitting">
                            <fieldset class="form-group">
                                <label>Password </label>
                                <input formControlName="password" class="form-control form-control-lg"
                                    type="password" />
                                <div app-error-messages class="mt-2" [control]="password" [multipleErrors]="true"></div>
                            </fieldset>

                            <fieldset class="form-group">
                                <label>Confirm Password</label>
                                <input formControlName="confirm_password" class="form-control form-control-lg"
                                    type="password" />
                                <div app-error-messages class="mt-2" [control]="confirmPassword"
                                    [multipleErrors]="true"></div>
                            </fieldset>
                            <button id="login" class="btn btn-lg btn-primary btn-block"
                                [disabled]="!resetPasswordForm.valid" type="submit">
                                Update Password
                            </button>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>