<div bsModal #permitDetails="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{rc_number}} Permit Details</h4>

            </div>
            <div class="modal-body" *ngFor="let owner of vehiclerc">
                <table class="table table-condensed">
                    <thead>
                        <tr>
                            <th>
                                Permit Type
                            </th>
                            <th>
                                Permit Number
                            </th>
                            <th>
                                Issued On
                            </th>

                            <th>
                                Valid From
                            </th>

                            <th>
                                Valid Upto
                            </th>

                            <th>
                                Permit Class
                            </th>

                            <th>
                                Owners Name
                            </th>

                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>

                                {{owner.permit_type}}


                            </td>

                            <td>

                                {{owner.permit_number}}


                            </td>

                            <td>
                                {{owner.permit_issued_on}}


                            </td>

                            <td>
                                {{owner.permit_valid_from}}


                            </td>

                            <td>

                                {{owner.permit_valid_upto}}
                            </td>

                            <td>
                                {{owner.permit_class}}

                            </td>

                            <td>
                                {{owner.owners_name}}
                            </td>


                        </tr>
                    </tbody>
                </table>

            </div>
            <div class="modal-footer">
                <div class="pull-left">
                    <a class="btn btn-default btn-danger" (click)="hide()"> Cancel</a>
                </div>
            </div>
        </div>
    </div>
</div>