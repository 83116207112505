<div class="wrapper-md">
    <div class="row">
        <div class="col-sm-3" *ngIf="sarathiStats">
            <div class="panel panel-default">
                <div class="panel-heading">Sarathi Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Details Found
                        <span class="pull-right text-bold"> {{ sarathiStats['sarathi_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Details Not Found
                        <span class="pull-right text-bold"> {{ sarathiStats['sarathi_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Failure
                        <span class="pull-right text-bold">{{ sarathiStats['sarathi_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Not Initiated
                        <span class="pull-right text-bold">{{ sarathiStats['sarathi_not_initiated'] }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm-3" *ngIf="stateStats">
            <div class="panel panel-default">
                <div class="panel-heading">State Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Details Found
                        <span class="pull-right text-bold"> {{ stateStats['state_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Details Not Found
                        <span class="pull-right text-bold"> {{ stateStats['state_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Failure
                        <span class="pull-right text-bold">{{ stateStats['state_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Not Initiated
                        <span class="pull-right text-bold">{{ stateStats['state_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        Secondary Not Found
                        <span class="pull-right text-bold"> {{ stateStats['state_no_secondary_details'] }}</span>
                    </p>

                </div>
            </div>
        </div>

        <div class="col-sm-3" *ngIf="parivahanSarathiStats">
            <div class="panel panel-default">
                <div class="panel-heading">Parivahan Sarathi Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Details Found
                        <span class="pull-right text-bold">
                            {{ parivahanSarathiStats['parivahan_sarathi_has_details'] }}</span>
                    </p>

                    <p class="mar-no">
                        Details Not Found
                        <span class="pull-right text-bold">
                            {{ parivahanSarathiStats['parivahan_sarathi_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Failure
                        <span class="pull-right text-bold">{{ parivahanSarathiStats['parivahan_sarathi_failure']
                            }}</span>
                    </p>
                    <p class="mar-no">
                        Not Initiated
                        <span class="pull-right text-bold">{{ parivahanSarathiStats['parivahan_sarathi_not_initiated']
                            }}</span>
                    </p>
                    <p class="mar-no">
                        Secondary Not Found
                        <span class="pull-right text-bold">
                            {{ parivahanSarathiStats['parivahan_sarathi_no_secondary_details'] }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-sm-3" *ngIf="parivahanAppStats">
            <div class="panel panel-default">
                <div class="panel-heading">Parivahan App Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Details Found
                        <span class="pull-right text-bold"> {{ parivahanAppStats['parivahan_app_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Details Not Found
                        <span class="pull-right text-bold">
                            {{ parivahanAppStats['parivahan_app_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Failure
                        <span class="pull-right text-bold">{{ parivahanAppStats['parivahan_app_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Not Initiated
                        <span class="pull-right text-bold">{{ parivahanAppStats['parivahan_app_not_initiated'] }}</span>
                    </p>
                    <p class="mar-no">
                        Secondary Not Found
                        <span class="pull-right text-bold">
                            {{ parivahanAppStats['parivahan_app_no_secondary_details'] }}</span>
                    </p>
                </div>
            </div>
        </div>


        <div class="col-sm-3" *ngIf="parivahanSimpleStats">
            <div class="panel panel-default">
                <div class="panel-heading">Parivahan Simple Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Simple Details Found
                        <span class="pull-right text-bold">
                            {{ parivahanSimpleStats['parivahan_simple_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Simple Details Not Found
                        <span class="pull-right text-bold">
                            {{ parivahanSimpleStats['parivahan_simple_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Simple Failure
                        <span class="pull-right text-bold">{{ parivahanSimpleStats['parivahan_simple_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Simple Not Initiated
                        <span class="pull-right text-bold">{{ parivahanSimpleStats['parivahan_simple_not_initiated']
                            }}</span>
                    </p>
                    <p class="mar-no">
                        Simple Invalid Captcha
                        <span class="pull-right text-bold">{{ parivahanSimpleStats['parivahan_simple_invalid_captcha']
                            }}</span>
                    </p>

                    <p class="mar-no">
                    </p>

                </div>
            </div>
        </div>

        <div class="col-sm-3" *ngIf="sarathiSimpleStats">
            <div class="panel panel-default">
                <div class="panel-heading">Sarathi Simple Stats</div>
                <div class="panel-body">
                    <p class="mar-no">
                        Details Found
                        <span class="pull-right text-bold">
                            {{ sarathiSimpleStats['sarathi_simple_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Details Not Found
                        <span class="pull-right text-bold">
                            {{ sarathiSimpleStats['sarathi_simple_does_not_has_details'] }}</span>
                    </p>
                    <p class="mar-no">
                        Failure
                        <span class="pull-right text-bold">{{ sarathiSimpleStats['sarathi_simple_failure'] }}</span>
                    </p>

                    <p class="mar-no">
                        Not Initiated
                        <span class="pull-right text-bold">{{ sarathiSimpleStats['sarathi_simple_not_initiated']
                            }}</span>
                    </p>

                </div>
            </div>
        </div>
    </div>
</div>