import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OcrListComponent } from './ocr-list/ocr-list.component';
import { OcrFiltersComponent } from './ocr-filters/ocr-filters.component';
import { OcrStatsComponent } from './ocr-stats/ocr-stats.component';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserModule } from '@angular/platform-browser';
import { OcrResponseModalComponent } from './ocr-response-modal/ocr-response-modal.component';
import { OcrExcelImportComponent } from './ocr-excel-import/ocr-excel-import.component';
import { HtmlResponseModalComponent } from './html-response-modal/html-response-modal.component';
import { OcrService } from '../core/services/ocr.service';
import { SharedModule } from '../core/shared.module';

@NgModule( {
    imports: [
        CommonModule,
        SharedModule,
        NgxPaginationModule,
        BrowserModule,
        FormsModule,
    ],
    declarations: [
        OcrListComponent,
        OcrFiltersComponent,
        OcrStatsComponent,
        OcrResponseModalComponent,
        OcrExcelImportComponent,
        HtmlResponseModalComponent,
    ],
    providers: [
        OcrService,
    ],
} )
export class OcrModule { }
