<form [formGroup]="verificationFormGroup" (ngSubmit)="updateVerification('reference')"
    (keydown.enter)="$event.preventDefault()">
    <fieldset [disabled]="isSubmitting">
        <div *ngIf="employee &&employee.id" class="row bg-light lter b-b wrapper-md">
            <app-verification-edit-header [verification]="'reference'" [verificationFormGroup]="verificationFormGroup"
                [employee]="employee" (verificationUpdated)="updateVerification($event)"></app-verification-edit-header>
            <div class="col-md-6">
                <a class="btn m-b-xs btn-sm btn-primary btn-addon" (click)="createReference()">
                    <i class="fa fa-plus"></i>
                    Add Reference Verification
                </a>
            </div>
        </div>

        <div class="wrapper-md">
            <div class="row">
                <div class="col-md-3">
                    <!--Remarks-->
                    <app-employee-remarks *ngIf="employee" [remarks]="employee.remarks"></app-employee-remarks>

                    <!--Employee Details-->
                    <app-employee-side-block [refreshEmployee]="refreshEmployee"></app-employee-side-block>
                    <app-employee-basic-details [employee]="employee">
                    </app-employee-basic-details>
                </div>

                <div class="col-md-9">
                    <div formArrayName="formArray">
                        <div *ngFor="let formGroup of formArray.controls; let i = index" [formGroupName]="i">
                            <app-reference-edit-form *ngIf="formGroup &&!isDeleted(formGroup)" [formGroup]="formGroup"
                                [formArray]="formArray" [verificationFormGroup]="verificationFormGroup" [i]="i"
                                [employee]="employee" [vendors]="vendors" [attachments]="attachments"
                                (uploaded)="onUploaded($event)">
                            </app-reference-edit-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>

<ng-template #customItemTemplate let-model="item" let-index="index">
    {{ model.name }}
</ng-template>