import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Errors } from '../../core/models/errors.module';
import { CommonService } from '../../core/services/common.service';
import { VerificationService } from '../../core/services/verification.service';
import { validateAllowedCharacters } from '../validators/allowed-characters-validator.directive';
import { validateEmail } from '../validators/email-validator.directive';
import { validateLength } from '../validators/length-validator.directive';
import { validateNumericCharacters } from '../validators/numeric-validator.directive';
import { validateVendorName } from '../validators/unique-vendor-name-validator.directive';
import { GlobalService } from 'src/app/core/services/global.service';

@Component( {
    selector: 'app-vendor-create',
    templateUrl: './vendor-create.component.html',
    styleUrls: [ './vendor-create.component.css' ],
} )
export class VendorCreateComponent implements OnInit {

    addVendorFormGroup!: FormGroup;
    editVendorFormGroup!: FormGroup;
    isSubmitting: boolean = false;
    errors: Errors = new Errors();
    vendors: any;
    selectedVendor: any = null;

    constructor (
        private verificationService: VerificationService,
        private fb: FormBuilder,
        private commonService: CommonService,
        private router: Router,
        public global: GlobalService
    ) { }

    get options () { return this.global.fetchTypeOptions(); }

    ngOnInit (): void {

        this.initForm();

        this.getAllVendors();

        this.getAllVendorNames();
    }


    initForm (): void {

        this.addVendorFormGroup = this.fb.group( {
            company_name: [ '' ],
            type: [ '', [ Validators.required ] ],
            places_covered: [ '' ],
            contact_person: [ '', [ validateAllowedCharacters( '' ) ] ],
            contact_number: [ '', [ validateLength( '10' ), validateNumericCharacters( '' ) ] ],
            contact_email: [ '', [ validateEmail() ] ],
        } );
    }


    getAllVendors (): void {

        this.verificationService.getAllVendors().subscribe(
            response => {
                this.vendors = response;
            }
        );
    }


    getAllVendorNames (): void {

        this.verificationService.getAllVendorNames().subscribe(
            response => {

                this.applyDefaultValidations( response );
            }
        );
    }

    submit (): void {

        this.isSubmitting = true;

        const data: any = this.addVendorFormGroup.value;

        this.verificationService.createVendor( data ).subscribe(
            response => {
                this.commonService.notifications.success( 'Vendor Added' );
                this.router.navigate( [ '/home' ] );
            },
            error => {
                this.commonService.displayValidationErrors( error );
                this.isSubmitting = false;
            },
            () => {
                this.isSubmitting = false;
            } );
    }


    private applyDefaultValidations ( vendorNames: any ): void {

        this.addVendorFormGroup.get( 'company_name' )?.setValidators( [ Validators.required, validateVendorName( vendorNames ) ] );
        this.addVendorFormGroup.get( 'company_name' )?.updateValueAndValidity();
    }
}
