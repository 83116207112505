<app-verification-status-edit></app-verification-status-edit>


<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Verification Statuses</h1>
</div>


<div class="wrapper-md">
    <div class="row">
        <div class="col-lg-6">
            <a class="btn btn-primary btn-addon" routerLink="/statuses/create">
                <i class="fa fa-plus"></i>
                Create New Status
            </a>
        </div>
    </div>
</div>


<div class="wrapper-md">

    <div class="panel panel-default">
        <div class="panel-heading font-bold">
            Filters
        </div>
        <div class="panel-body">
            <form [formGroup]="filterFormGroup" (ngSubmit)="filterResults()">

                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Filter By Verifications</label>
                        <select class="form-control" formControlName="verification">
                            <option *ngFor="let verification of verifications" value="{{verification}}">{{verification}}
                            </option>
                        </select>

                    </div>


                </div>
                <div class="row">

                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button class="btn btn-primary btn-block">
                            Apply Filters
                        </button>
                    </div>
                    <div class="form-group col-md-2">
                        <label class="center-block">Click here to</label>
                        <button (click)="resetForm()" type="button" class="btn btn-danger btn-block">
                            Reset
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="wrapper-md">

    <div class="panel-heading">
        <div class="row" *ngIf="pagination.data">
            <div class="col-md-6 panel-title">
                Showing {{ pagination.data.length }} out of total {{ pagination.total }} results
            </div>
            <div class="col-md-6 text-right text-center-xs">
                <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
            </div>
        </div>
    </div>

    <div class="panel panel-default">

        <div class="table-responsive">
            <table class="table table-striped b-t b-light">
                <thead>
                    <tr>
                        <th>Verification</th>
                        <th>Title</th>
                        <th>Client Title</th>
                        <th>Description </th>
                        <th>Client Description </th>
                        <th>Is Insuff </th>
                        <th>Is Complete </th>
                        <th>Is Billable </th>
                        <th>Edit</th>

                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let status of pagination.data|paginate: { id: 'server', itemsPerPage:pagination.per_page, currentPage: pagination.current_page, totalItems: pagination.total }; let i=index">

                        <td>{{status.verification}}</td>
                        <td>{{status.title}}</td>
                        <td>{{status.client_title}}</td>
                        <td>{{status.description}}</td>
                        <td>{{status.client_description}}</td>
                        <td>{{status.insuff ? 'Yes' : 'No'}}</td>
                        <td>{{status.is_completed ? 'Yes' : 'No'}}</td>
                        <td>{{status.is_billable ? 'Yes' : 'No'}}</td>
                        <td> <button (click)="showEditModal(status)" class="btn btn-primary">Edit</button> </td>

                    </tr>
                </tbody>
            </table>
        </div>

        <footer class="panel-footer">
            <div class="row" *ngIf="pagination.data">
                <div class="col-sm-12 text-right text-center-xs">
                    <div class="has-text-centered">
                        <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                        <pagination-controls (pageChange)="showPage($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </footer>


    </div>
</div>