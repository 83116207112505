<div bsModal #sendMail="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true">

    <div class="modal-dialog modal-lg" *ngIf="data">
        <form [formGroup]="data">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Send Mail</h4>
                    <a type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span aria-hidden="true">&times;</span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <label class="center-block">Send TO: </label>
                            <app-multi-select [options]="contacts" [placeholder]="'Select To'" [controlName]="'to'"
                                [formGName]="data"></app-multi-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="center-block"> CC
                                <input formControlName="cc" class="form-control">
                            </label>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="center-block"> BCC
                                <input formControlName="bcc" class="form-control">
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 form-group">
                            <strong>Select Files to be attached : </strong>
                        </div>

                        <div class="col-md-3 form-group" *ngFor="let attachment of allAttachments; let i = index">

                            <label class="i-checks m-b-none">
                                <input type="checkbox" class="checkbox-all" value="{{attachment.full_path}}"
                                    (change)="getValues(attachment.full_path,$event)" />
                                <i></i>
                                <a href="{{attachment.full_path}}" target="_blank">{{attachment.file_name}}</a>
                            </label>
                        </div>
                    </div>

                    <div class="row" *ngIf="contents">
                        <div class="col-md-12 form-group">
                            <ckeditor formControlName="contents"></ckeditor>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="pull-right">
                        <a class="btn btn-primary" (click)="sendMailRequest()" *ngIf="data.get('to')?.value">Send</a>
                    </div>
                    <div class="pull-left">
                        <a class="btn btn-danger" (click)="hide()"> Cancel</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>