<div class="bg-light lter b-b wrapper-md">
    <h1 class="m-n font-thin h3">Vcourt</h1>
</div>

<div class="wrapper-md">

    <div class="form-group col-md-2">
        <label class="center-block">Select File</label>
        <input type="file" [accept]="acceptedFileType" (change)="onFileChange($event)" /><br />
    </div>

    <div class="form-group col-md-3">
        <button class="btn btn-primary" (click)="submitAndUpdate()" [disabled]="cases.length==0||isSubmitting">Update
            Cases</button>
    </div>

</div>

<div class="wrapper-md">

    <table class="table">
        <thead>
            <tr>
                <th scope="col">Case ID</th>
                <th scope="col">Status</th>
                <th scope="col">Is Updated</th>
                <th scope="col">Response</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let case of cases">
                <th scope="row">{{ case.case_id }}</th>
                <td>{{ case.status }}</td>
                <td [class]="case.color">{{ case.is_updated ? 'Yes' : 'No' }}</td>
                <td [class]="case.color">{{ case.response }}</td>
            </tr>
        </tbody>
    </table>

</div>